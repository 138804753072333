import {clone, isEqual} from "lodash";
import COUNTRY_MAP from "shared/constants/country_list_map";

const ALLOWED_REQUEST_PARAMS = [
  // base params
  'q',
  'page',
  'redesign',
  'sort_by',
  // search within shop
  'shop_domain',
  'search_in_shop',
  'platform',
  // filter params
  'category_id',
  'country',
  'store_country',
  'min_rating',
  'min_transparency',
  'min_authenticity',
  'currency',
  'min_price',
  'max_price',
  'reviews_count',
  'category_search',
]
export function parseFiltersFromQueries(queries, defaultFilters) {
  let {
    category_id,
    redesign,
    q,
    page,
    min_rating,
    min_price,
    max_price,
    min_transparency,
    min_authenticity,
    country,
    store_country,
    sort_by,
    shop_domain,
    platform,
    search_in_shop,
    category_search,
    reviews_count,
  } = queries;

  const obj = {...defaultFilters};

  if (queries.hasOwnProperty('page')) {
    obj.page = isNaN(parseInt(page)) ? 1 : parseInt(page)
  }
  if (queries.hasOwnProperty('min_rating') && !isNaN(parseFloat(min_rating))) {
    min_rating = parseFloat(min_rating)
    obj.min_rating = (min_rating > 5) ? 5 : (min_rating < 0 ? 0 : min_rating)
  }
  if (queries.hasOwnProperty('min_price')) {
    const minP = parseFloat(min_price)
    if (!isNaN(minP) && minP > 0) {
      obj.min_price = minP
    }
  }

  if (queries.hasOwnProperty('max_price')) {
    const maxP = parseFloat(max_price)
    if (!isNaN(maxP) && maxP > 0) {
      obj.max_price = maxP
    }
  }
  if (queries.hasOwnProperty('min_transparency')) {
    obj.min_transparency = isNaN(parseInt(min_transparency)) ? 0 : parseInt(min_transparency)
  }
  if (queries.hasOwnProperty('min_authenticity')) {
    obj.min_authenticity = isNaN(parseInt(min_authenticity)) ? 0 : parseInt(min_authenticity)
  }
  if (queries.hasOwnProperty('redesign')) {
    obj.redesign = redesign;
  }
  if (queries.hasOwnProperty('category_id') && !isNaN(parseInt(category_id))) {
    obj.category_id = parseInt(category_id);
  }
  if (queries.hasOwnProperty('reviews_count') &&!isNaN(parseInt(reviews_count))) {
    obj.reviews_count = parseInt(reviews_count);
  }
  if (queries.hasOwnProperty('sort_by')) {
    obj.sort_by = sort_by;
  }
  if (queries.hasOwnProperty('category_search')) {
    obj.category_search = category_search;
  }
  if (COUNTRY_MAP[country]) {
    obj.country = country
  }
  if (COUNTRY_MAP[store_country]) {
    obj.store_country = store_country
  }
  if (
    shop_domain
    && platform
    && search_in_shop
  ) {
    obj.shop_domain = shop_domain
    obj.platform = platform
    obj.search_in_shop = search_in_shop
  }
  obj.q = q || ''
  return obj
}


// to parse array of raw categories to hash and populate `children` prop for each category
export function arrToHashAndPopulateChildren(arr) {
  return arr.reduce(reducerArrayObjsToHashAndPopulateChildren, {})
}
// to parse array of category_ids to hash and populate `children` prop for each category (mapper is the CATEGORIES hash)
export function mapIdsToHashAndPopulateChildren(arr, map) {
  return arr.reduce((acc, id) => {
    const clone = {...map[id]}
    delete clone.children  // delete existing children in mapper and will use new children from the acquired ids
    return reducerArrayObjsToHashAndPopulateChildren(acc, clone)
  }, {})
}

// callback function for reduce
function reducerArrayObjsToHashAndPopulateChildren(acc, el) {
  if (acc[el.id]) {
    acc[el.id] = {...acc[el.id], ...el};
  } else {
    acc[el.id] = {...el};
  }

  if (el.parent_id) {
    if (acc[el.parent_id]) {
      if (acc[el.parent_id].children) {
        acc[el.parent_id].children.push(el.id)
      } else {
        acc[el.parent_id].children = [el.id];
      }
    } else {
      acc[el.parent_id] = {children: [el.id]}
    }
  }

  return acc
}

// remove params with default values when sending to Browser URL
export function sanitizeParams(params) {
  const query = {}
  ALLOWED_REQUEST_PARAMS.forEach(k => {
    const value = params[k]
    if (params.max_price && params.min_price && parseFloat(params.min_price) > parseFloat(params.max_price)) {
      const temp = params.min_price
      params.min_price = params.max_price;
      params.max_price = temp;
    }
    if (k in params) {
       if (k === 'category_id' && !value) {
      } else if (k === 'sort_by' && !value) {
      } else if (k === 'min_price' && value === null) {
      } else if (k === 'max_price' && value === null) {
      } else if (value === null) {
      } else {
        query[k] = value
      }
    }
  })
  return query
}

// remove params with default values when sending to backend
export function sanitizeParamsForRequest(params) {
  const query = {}
  ALLOWED_REQUEST_PARAMS.forEach(k => {
    const value = params[k]
    if (params.max_price && params.min_price && parseFloat(params.min_price) > parseFloat(params.max_price)) {
      const temp = params.min_price
      params.min_price = params.max_price;
      params.max_price = temp;
    }
    if (k in params && value !== undefined && value !== null) {
      query[k] = value
    }
  })
  return query
}

// remove null queries for URL (for decoration only)
export const clearNullQueries = (params) => Object.keys(params).reduce((query, key) => {
  if (params[key] != null && params[key] != undefined) {
    query[key] = params[key]
  }
  return query
}, {})

// track changes in filters (originalObj could be previous state or default state of searchParams)
export function didChange(originalObj, newObj, keysToCheck) {
  if (!keysToCheck) {
    keysToCheck = ['reviews_count', 'min_rating', 'min_transparency', 'min_authenticity', 'store_country', 'country', 'min_price', 'max_price', 'category_id']
  }
  for (let i = 0; i < keysToCheck.length; i++) {
    const key = keysToCheck[i]
    if (['min_price', 'max_price'].includes(key) && originalObj[key] != newObj[key]) {  // edgecase where these 2 fields do not exist but have default values (null)
      return true
    }
    if (originalObj.hasOwnProperty(key) && newObj.hasOwnProperty(key) && originalObj[key] != newObj[key]) {
      return true
    }
    if ( (originalObj.hasOwnProperty(key) || newObj.hasOwnProperty(key)) && originalObj[key] != newObj[key]) {
      return true
    }
  }
  return false
}

function _categoriesSortFunction(key, order = 1){
  return function(a, b) {
    let x = a[key];
    let y = b[key];
    if (typeof x === 'string') {
      x = x.toLowerCase()
    }
    if (typeof y === 'string') {
      y = y.toLowerCase()
    }
    if (x < y) return (-1 * order)
    if (x > y) return order
    return 0;
  }
}
export const categoriesSortFunctionByName = _categoriesSortFunction('name')
export const categoriesSortFunctionByBarOrder = _categoriesSortFunction('bar_order')
export const categoriesSortFunctionByPageOrder = _categoriesSortFunction('page_order')

export const isSearchInShop = (params) => params.search_in_shop && params.shop_domain && params.platform

export const isSameFilters = (newQuery, previousQuery) => {
  const tempPreviousQ = clone(previousQuery);
  const tempNewQ = clone(newQuery);
  delete tempNewQ.page;
  delete tempPreviousQ.page;
  return isEqual(tempNewQ, tempPreviousQ)
}

export const extractFolloweeIdsSearchResults = (searchResults) => {
  const productEncodedIds = searchResults.map((searchResult) => {
    return searchResult.encoded_product_id
  })
  return {'Product': productEncodedIds}
}

export const getRootCategory = (id, map) => {
  let tempId = id;
  if (!map[tempId]) {
    return undefined
  }
  while (map[tempId].parent_id) {
    tempId = map[tempId].parent_id
  }
  return tempId
}

const _cacheKey = query => JSON.stringify(query);

export class SearchCache {
  constructor(obj, useAutoFlush = false, timer = 60) {
    this.cache = obj // {<queryString>: <result>} // for caching queryObjs with responses
    this.params = {} // for caching temporary params (totally different feature vs cache)
    this.useAutoFlush = useAutoFlush
    this.timer = timer * 1000 // seconds to milliseconds, default 60s from last cache
    if (useAutoFlush) {
      this._runAutoFlush()
    }
  }

  hasResult(query) {
    return this.cache.hasOwnProperty(_cacheKey(query))
  }

  cacheResult(query, newResult) {
    this._runAutoFlush()
    this.cache[_cacheKey(query)] = newResult
  }

  getCachedResult(query) {
    this._runAutoFlush()
    return this.cache[_cacheKey(query)]
  }

  removeCache(query) {
    delete this.cache[_cacheKey(query)]
  }

  reset() {
    this._resetFlushTimeout();
    this.cache = {}
  }

  _resetFlushTimeout(){
    clearTimeout(this.autoFlushTimeout);
  }
  _runAutoFlush() {
    if (!this.useAutoFlush) return null;
    this._resetFlushTimeout(); // remove old timer and then trigger new timer
    this.autoFlushTimeout = setTimeout(this.reset.bind(this), this.timer)
  }

  cacheParam([key, value]) {
    this.params[key] = value
  }
  getCachedParam(key) {
    return this.params[key]
  }
}
