import axios from 'shared/utils/axios'
import router from 'rs/plugins/router'
import routes from 'rs/plugins/routes'
import {shopResponseTime as shopResponseTimeFunc} from 'rs/components/pages/shop/helpers'

const defaultState = {
  disputeFormData: null,
}

const state = {
  ...defaultState,
}

const getters = {
  disputeFormData: state => state.disputeFormData,
  disputeTypes: state => state.disputeFormData && state.disputeFormData.disputeTypes || [],
  outcomeTypes: state => state.disputeFormData && state.disputeFormData.outcomeTypes || [],
  orders: state => state.disputeFormData && state.disputeFormData.orders || [],
  currentReviewer: (state, getters, rootState) => rootState.SharedCurrentReviewer.currentReviewer
}

const mutations = {
  setState: (state, [name, value]) => state[name] = value, // use for simple mutated state
  setFormDataset: (state, data) => {
    state.disputeFormData = {
      orders: data.orders,
      outcomeTypes: data.outcome_types,
      disputeTypes: data.dispute_types,
    }
  }
}

const actions = {
  async initPageNewDispute(context, shopDomain) {
    try {
      const response = await axios.get(`/reviews/${shopDomain}/disputes/new`, {params: defaultParams})
      context.commit('setFormDataset', response.data)
      return null
    } catch (e) {
      return e.response.status
    }
  },
  async postDispute(context, inputs) {
    try {
      const response = await axios.post('/profile/disputes', {...defaultParams, ...newDisputeParams(inputs)})
      context.dispatch('redirectAfterDispute')
    } catch (e) {
      console.log('Failed to submit dispute', e)
    }
  },
  redirectAfterDispute(context) {
    let shopName, shopResponseTime;
    const shopData = context.rootGetters['Shop/shopData']
    if (shopData) {
      shopName = shopData.name;
      shopResponseTime = shopResponseTimeFunc(shopData.contact_info)
    }
    context.dispatch('Notification/setCreatedDisputeNotification', {shopName, shopResponseTime}, {root: true})
      .then(() => {
        router.push({
          name: routes.privateProfile.name,
          query: {...router.currentRoute.query, tab: 3},
          params: {
            slug: context.getters.currentReviewer.encoded_id,
          }
        })
      })
  }
}


const newDisputeParams = ({disputeType, description, outcomeType, outcomeDescription, sourceId, files}) => ({
  dispute_type: disputeType,
  description: description,
  desired_outcome_type: outcomeType,
  desired_outcome_description: outcomeDescription,
  source_id: sourceId,
  files: files,
})

const defaultParams = {
  format: 'json',
  redesign: true,
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
}
