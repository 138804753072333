<template>
  <div>
    <Menu v-bind="this.$attrs" @openMenu="openMenu" @closeMenu="closeMenu" @expandMenu="expandMenu">
      <slot></slot>
    </Menu>
  </div>
</template>

<script>
import Menu from 'rs/components/layouts/menus/main';

export default {
  name: 'slide',
  components: {
    Menu: Menu
  },
  methods: {
    openMenu() {
      this.$emit('openMenu');
    },
    closeMenu() {
      this.$emit('closeMenu');
    },
    expandMenu(expanded) {
      this.$emit('expandMenu', expanded);
    }
  }
};
</script>