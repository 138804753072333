<template functional>
  <svg v-on="data.listeners" :class="data.class" v-bind="data.attrs" xmlns="http://www.w3.org/2000/svg" width="16"
       height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_2030_12905)">
      <mask id="mask0_2030_12905" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#088484"/>
      </mask>
      <g mask="url(#mask0_2030_12905)">
        <path
          d="M7.35542 1.20763C7.60912 0.664188 8.39085 0.664189 8.64456 1.20763L10.2368 4.61828C10.3402 4.83976 10.5526 4.99226 10.7978 5.02098L14.5729 5.46321C15.1744 5.53368 15.4159 6.26824 14.9712 6.67457L12.1802 9.2247C11.999 9.3903 11.9178 9.63705 11.9659 9.87628L12.7068 13.5602C12.8249 14.1472 12.1924 14.6012 11.6639 14.3089L8.3467 12.4743C8.13128 12.3552 7.86869 12.3552 7.65328 12.4743L4.33609 14.3089C3.80754 14.6012 3.17511 14.1472 3.29316 13.5602L4.03403 9.87628C4.08214 9.63705 4.00099 9.3903 3.81975 9.2247L1.02874 6.67457C0.584032 6.26824 0.825601 5.53368 1.42711 5.46321L5.20218 5.02098C5.44733 4.99226 5.65977 4.83976 5.76317 4.61827L7.35542 1.20763Z"
          :fill="props.color"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2030_12905">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#088484',
    }
  }
}
</script>
