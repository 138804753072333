<template>
  <div v-if="!collectionsData"></div>
  <section v-else class="collections-section" :style="plainBackground">
    <img v-if="validBackgroundImage && backgroundImage" class="cs-background-image" :src="backgroundImage" alt="collection background" @error="validBackgroundImage = false"/>
    <div class="cs-wrapper has-max-width">
      <h1 class="cs-title">{{ collectionsData.external_title }}</h1>
      <div class="cs-cards-list">
        <Card v-for="(card,idx) in collectionsData.items" :cardData="card" :key="`collection-card-${idx}`"/>
      </div>
    </div>
  </section>
</template>
<script>
import LoadingSpinner from 'rs/components/common/loading_spinner';
import Card from 'rs/components/pages/reviews/_collection_card';

export default {
  components: {LoadingSpinner, Card},
  data(){
    return {
      validBackgroundImage: true,
    }
  },
  computed: {
    collectionsData() {
      return this.$store.getters['Home/collectionsData']
    },
    hasBackground(){
      return (this.collectionsData && this.collectionsData.background)
    },
    plainBackground(){
      return (this.hasBackground && this.collectionsData.background.startsWith('#') && {
        backgroundColor: this.collectionsData.background
      })
    },
    backgroundImage(){
      return (this.hasBackground && !this.plainBackground && this.collectionsData.background)
    },
  },
}
</script>
<style lang="scss" scoped>
@import "~PlatformStyles/abstract/mixins";

$cardSizeSmall: 202px; // 202 = 162 + 20 + 20
$cardSizeBig: 212px; // 212 = 172 + 20 + 20

.loading-spinner {
  height: 200px;
  width: 200px;
}

.collections-section {
  position: relative;
  left: calc(-50vw + 50%);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;

  @include respond-to(desktop) {
    height: 420px;
  }
}

.cs-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 40px;
  padding: 40px 0 60px 0;
  @include respond-to(desktop) {
    padding: 60px 0 90px 0;
  }
}
.cs-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  object-fit: cover;
  backdrop-filter: blur(5px);
}

.cs-title {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.cs-cards-list {
  width: 100%;
  display: flex;
  row-gap: 20px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 calc((100% - (#{$cardSizeSmall} * 2)) / 2);  // 2 col

  @include respond-to(tablet) {
    padding: 0 calc((100% - (#{$cardSizeBig} * 3)) / 2); // 3 col
  }

  @include respond-to(desktop) {
    flex-wrap: nowrap;
    padding: 0;
    justify-content: space-between;
  }
}

.cs-cards-list > * {
  width: 162px;
  height: 196px;
  max-width: 162px;
  max-height: 196px;
  flex: 0 0 $cardSizeSmall;

  @include respond-to(notmobile) {
    width: 172px;
    height: 200px;
    max-width: 172px;
    max-height: 200px;
    flex: 0 0 $cardSizeBig;
  }
}
</style>
