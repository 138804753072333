<template>
  <section class="side-container">
    <WidgetShop/>
    <WidgetContact/>
    <WidgetDispute />
    <WidgetDynamic widgetType="shipping_policy"/>
    <WidgetDynamic widgetType="refund_policy"/>
    <WidgetDynamic widgetType="payment_methods"/>
    <WidgetMedal />
    <PromiseWidget/>
  </section>
</template>
<script>
import WidgetShop from 'rs/components/pages/product/widgets/_widget_shop';
import WidgetContact from 'rs/components/pages/shop/widgets/_widget_contact';
import WidgetDynamic from 'rs/components/pages/product/widgets/_widget_dynamic';
import WidgetMedal from 'rs/components/pages/shop/widgets/_widget_medal';
import PromiseWidget from 'rs/components/common/promise_widget';
import WidgetDispute from 'rs/components/pages/shop/widgets/_widget_dispute';


export default {
  components: {PromiseWidget, WidgetShop, WidgetContact, WidgetDynamic, WidgetMedal, WidgetDispute},
}
</script>
<style lang="scss" scoped>
.side-container {
  padding: 0 0 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
