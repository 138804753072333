"use strict";
/**
 * @file vue-awesome-swiper
 * @module default-export
 * @author Surmon <https://github.com/surmon-china>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwiperSlide = exports.Swiper = exports.directive = exports.install = exports.version = void 0;
var swiper_1 = require("swiper");
var exporter_1 = require("rs/plugins/swiper/exporter");
var VueAwesomeSwiper = (0, exporter_1.default)(swiper_1.default);
exports.version = VueAwesomeSwiper.version;
exports.install = VueAwesomeSwiper.install;
exports.directive = VueAwesomeSwiper.directive;
exports.Swiper = VueAwesomeSwiper.Swiper;
exports.SwiperSlide = VueAwesomeSwiper.SwiperSlide;
exports.default = VueAwesomeSwiper;
