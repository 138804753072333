<template>
  <div v-if="categoryLinks.length > 0" class="meganav" id="bannerHeader" @mouseleave="closeMeganav" @mouseenter="clearCloseTimeout">
    <div class="meganav__header new-has-max-width">
      <div class="meganav__header-link-wrapper" v-for="link in categoryLinks" :key="link.path"
           @mouseenter="() => onMouseOverCategory(1, link.id)">
        <router-link class="meganav__header-link" :class="{'meganav__header-link--active': activeLv1Id === link.id}"
                     :to="link.path">{{ link.name }}
        </router-link>
        <div class="meganav__header-highlight"
             :class="{'meganav__header-highlight--active': activeLv1Id === link.id}"></div>
      </div>
      <div class="meganav__body" v-if="activeLv1Id">
        <div class="meganav__body-left" v-if="!isFlatView">
          <div class="meganav__level-2" :class="{'meganav__level-2--hover': category.id === activeLv2Id}"
               v-for="category in categoriesLv2" @mouseenter="() => onMouseOverCategory(2, category.id)">
            <div class="meganav__level-2-cell">
              <component :is="hasChildren(category) ? 'span' : 'router-link'" :to="categoryPath(category)">
                {{ category.name }}
              </component>
              <iconify v-if="hasChildren(category)" icon="octicon:chevron-right-16" class="brand-color" height="16"/>
            </div>
          </div>
          <div class="meganav__level-2"><!-- empty row --></div>
          <div class="meganav__level-2">
            <div class="meganav__level-2-cell">
              <router-link class='rs-weight-700' :to="categoryPath(activeLv1, true)">All {{
                  activeLv1.name
                }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="meganav__body-right">
          <NavColumn v-if="isFlatView"
                     :categories="columnCategories"
                     :checker="whichColumn(0)"
                     :categoryPath="categoryPath"
          />
          <NavColumn
            :categories="columnCategories"
            :checker="whichColumn(1)"
            :categoryPath="categoryPath" :border="!isFlatView"
          />
          <NavColumn
            :categories="columnCategories"
            :checker="whichColumn(2)"
            :categoryPath="categoryPath"
            :parentCategory="columnCategoriesParent"
          />
          <NavColumn :isImage="true">
            <template v-if="featuredCategory">
              <router-link :to="featuredCategory.path">
                <img class="meganav__picture-image" :src="featuredCategory.image_url" :alt="featuredCategory.name"/>
              </router-link>
              <div class="meganav__picture-name">{{ featuredCategory.name }}</div>
            </template>
          </NavColumn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import NavColumn from 'rs/components/layouts/meganav/_column'

export default {
  components: {NavColumn},
  data() {
    return {
      activeLv1Id: null,
      activeLv2Id: null,
      closeTimeout: null,
    }
  },
  computed: {
    ...mapGetters('Meganav', ['categoryLinks', 'categoriesTree']),
    isFlatView() {
      return this.activeLv1 && this.activeLv1.bar_flat_view
    },
    activeLv1() {
      if (!this.activeLv1Id) return null;
      return this.categoriesTree.find(e => e.id === this.activeLv1Id)
    },
    activeLv2() {
      if (!this.activeLv1Id || !this.activeLv2Id) return null
      return this.categoriesLv2.find(e => e.id === this.activeLv2Id)
    },
    categoriesLv2() {
      if (!this.activeLv1Id) return []
      return this.activeLv1 && this.activeLv1.children || []
    },
    categoriesLv3() {
      if (!this.activeLv2Id) return []
      return this.activeLv2 && this.activeLv2.children || []
    },
    columnCategories() {
      return this.isFlatView ? this.categoriesLv2 : this.categoriesLv3
    },
    columnCategoriesParent() {
      return this.isFlatView ? this.activeLv1 : this.activeLv2
    },
    featuredCategory() {
      const avatarCategory = this.isFlatView ? this.categoriesLv2[0] : this.categoriesLv3[0]
      if (avatarCategory && avatarCategory.image_url) {
        return {
          image_url: avatarCategory.image_url,
          name: avatarCategory.name,
          path: '/categories/' + avatarCategory.handle
        }
      }
      return null
    }
  },
  methods: {
    whichColumn(columnIndex) {
      const totalColumn = this.isFlatView ? 3 : 2
      return function (listIndex) {
        if (totalColumn === 2) {
          return listIndex % totalColumn === columnIndex - 1
        } else {
          return listIndex % totalColumn === columnIndex
        }
      }
    },
    clearCloseTimeout() {
      clearTimeout(this.closeTimeout)
    },
    onMouseOverCategory(level, id) {
      this.clearCloseTimeout()
      if (level === 1) {
        this.activeLv1Id = id;
        this.activeLv2Id = this.categoriesLv2[0] && this.categoriesLv2[0].id  // highlight first level 2 as default
      } else { // level 2
        const thisCategory = this.categoriesLv2.find(e => e.id === id)
        if (this.hasChildren(thisCategory)) {
          this.activeLv2Id = id;
        }
      }
    },
    closeMeganav() {
      this.clearCloseTimeout()
      this.closeTimeout = setTimeout(() => {
        this.activeLv1Id = null
        this.activeLv2Id = null
      }, 500)
    },
    hasChildren(category) {
      return category.children && category.children.length > 0;
    },
    categoryPath(category, viewAll = false) {
      if (!category) return null;
      return {
        path: '/categories/' + category.handle,
        query: {
          view_all: viewAll
        }
      }
    },
  },
  watch: {
    activeLv1Id(newVal, oldVal) {
      if (newVal) {
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open')
      }
    },
  },
  beforeDestroy() {
    document.body.classList.remove('modal-open')
  }
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.meganav {
  position: relative;
  width: 100vw;
  translate: -50%;
  margin-left: 50%;
  font-weight: 400;
  height: $marketplaceHeaderBannerHeight;
  background: linear-gradient(90deg, #088484 16.25%, #086E84 87.78%);
}

.meganav__header {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.meganav__header-link-wrapper {
  @include respond-to(desktop) {
    width: 1240px;
  }
  @media screen and (max-width: 1240px) {
    width: 1000px;
  }
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.meganav__header-highlight {
  height: 4px;
  position: absolute;
  top: 40px;
  z-index: 2;
  width: 0%;
  background: #85D1D1;
  overflow: hidden;
  -webkit-transition: width 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-in-out;
  -o-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
}

.meganav__header-highlight--active {
  width: 100%;
}

.meganav__header-link {
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.meganav__header-link--active {
  font-weight: 700;
  opacity: 1;
}

.meganav__body {
  position: absolute;
  top: 40px;
  width: 100%;
  display: flex;
  height: fit-content;
  border-radius: 0 0 10px 10px;
  background: #FFF;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: 400;
}

.meganav__body-left {
  min-width: 304px;
  max-width: 304px;
  height: 100%;
  padding: 12px 0 20px 0;
  display: flex;
  flex-direction: column;
}

.meganav__body-right {
  width: 100%;
  display: flex;
}

.meganav__level-2 {
  height: 40px;
  width: 100%;

  &--hover {
    background: #E8F8F8CC;
    font-weight: 700;
  }
}

.meganav__level-2-cell {
  padding: 0 12px 0 20px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: default;

  &:hover {
    cursor: pointer;
    opacity: 0.85;

    a {
      color: $brandColor;
    }
  }
}

.meganav__level-2-cell > a {
  color: $primaryFontColor;
  text-decoration: none;

  &:hover {
    color: $brandColor;
  }
}

.meganav__picture-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 285px;
  border-radius: 10px;
}

.meganav__picture-name {
  color: $secondaryFontColor;
  margin-top: -4px;
}
</style>
