<!--
Recursive Itemline, this component is calling itself with different variants if
  - it is in selected stack and has children.
 -->

<template>
  <div class="item-line">
    <div @click="click" class="item-line__text" :class="{'item-link__text--hidden': !shouldShow}">
      <div class="item-line__select-wrapper">
        <div v-if="isSelected" class="item-line__select-inner"></div>
      </div>
      <span :class="{'item-line__text--padded': lastLevel}">{{ item.name }}</span>
    </div>
    <div v-if="item.children">
      <ItemLine
          v-for="childID in item.children"
          :change="change"
          :item="items[childID]" :key="childID" />
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ItemLine",
  props: ['item', 'change'],
  computed: {
    ...mapState('Search', {
          items: state => state.categories,
          selectedStack: state => state.selectedStack,
          selectedID: state => state.searchParams.category_id,
          showChildrenFor: state => state.showChildrenFor
        },
    ),
    lastLevel(){
      if (!this.showChildrenFor) {
        return false
      }
      return (this.item.is_deepest && this.item.level !== 1) || this.item.parent_id === this.showChildrenFor
    },
    shouldShow(){
      if (this.showChildrenFor) {         // normal flow, when navigate
        return  !this.item.id
            || this.item.level === 1
            || this.isInStack
            || this.showChildrenFor === this.item.parent_id
      } else {                            // Not from navigate, but load from url and open category right away
        return !this.item.id
        || this.item.level === 1
      }
    },
    isInStack() {
      return this.selectedStack[this.item.level - 1] === this.item.id
    },
    isSelected() {
      if (this.item.id) {
        return this.selectedStack[this.selectedStack.length - 1] === this.item.id
      } else {
        return this.selectedStack.length === 0;
      }
    },
  },
  methods: {
    click() {
      this.change(this.item.id)
      this.$store.commit('Search/setSelectedStack', this.item.id)
      if (this.item.children) {
        this.$store.commit('Search/setShowChildrenFor', this.item.id)
      } else {
        if (this.item.parent_id) {
          this.$store.commit('Search/setShowChildrenFor', this.item.parent_id)
        } else {
          this.$store.commit('Search/setShowChildrenFor', null)
        }
      };
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.item-line__text {
  font-weight: 700;
  display: flex;
  width: 100%;
  padding: 20px 0 20px 0;
  border-bottom: solid 1px $lightGreenColor
}

.item-line__text--padded {
  margin-left: 20px;
  font-weight: 400;
}

.item-link__text--hidden {
  display: none;
}

.item-line__select-wrapper {
  height: 24px;
  width: 24px;
  border: 1px solid $newBrandColor;
  border-radius: 50%;
  margin: 0 12px 0 16px;
}

.item-line__select-inner {
  margin-left: 3px;
  margin-top: 3px;
  width: 16px;
  height: 16px;
  background-color: $newBrandColor;
  border-radius: 50%;
}


</style>
