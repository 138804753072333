<template>
  <div class="review-reaction">
    <button :disabled="(thumbSelected || hasBeenThumbed)" class="material-icons review-reaction__thumbup" @click="thumbAction(review.uuid, 'up')">thumb_up</button>
    <span class="review-reaction__counter thumb-up">{{ this.thumbUpCount }}</span>

    <button :disabled="(thumbSelected || hasBeenThumbed)" class="material-icons review-reaction__thumbdown" @click="thumbAction(review.uuid, 'down')">thumb_down</button>
    <span class="review-reaction__counter thumb-down">{{ this.thumbDownCount }}</span>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    props: ['review'],

    data() {
      return {
        thumbUpCount: this.review.thumb_up,
        thumbDownCount: this.review.thumb_down,
        hasBeenThumbed: false,
      }
    },

    computed: {
      thumbSelected() {
        if (JSON.parse(localStorage.getItem('reviewThumbStates')) && JSON.parse(localStorage.getItem('reviewThumbStates')).find(review => review.review_uuid == this.review.uuid)) {
          const thumbState = JSON.parse(localStorage.getItem('reviewThumbStates')).find(review => review.review_uuid == this.review.uuid).thumb_state
          if (thumbState == 'up' || thumbState == 'down') {
            return true
          }
        }
      },
    },

    methods: {
      thumbAction(reviewId, selection) {
        axios.post('/thumbs', { review_uuid: reviewId, thumb_type: selection })
        this.addThumbLocalStorage(reviewId, selection)
        this.updateThumbCounts(selection)
        this.hasBeenThumbed = true
      },

      updateThumbCounts(selection) {
        selection == 'up' ? this.thumbUpCount++ : this.thumbDownCount++
      },

      addThumbLocalStorage(reviewId, selection) {
        if (localStorage.getItem('reviewThumbStates')) {
          let storedReviewThumbStates = JSON.parse(localStorage.getItem("reviewThumbStates"))
          storedReviewThumbStates.push({ review_uuid: reviewId, thumb_state: selection })
          localStorage.setItem('reviewThumbStates', JSON.stringify(storedReviewThumbStates))
        } else {
          let reviewThumbStates = []
          localStorage.setItem('reviewThumbStates', JSON.stringify(reviewThumbStates))
          let storedReviewThumbStates = JSON.parse(localStorage.getItem("reviewThumbStates"))
          storedReviewThumbStates.push({ review_uuid: reviewId, thumb_state: selection })
          localStorage.setItem('reviewThumbStates', JSON.stringify(storedReviewThumbStates))
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';
  
  .review-reaction {
    margin: 12px 0 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .has-been-thumbed {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .review-reaction__thumbup,
  .review-reaction__thumbdown {
    color: $brandColor;
    cursor: pointer;
    margin: 0 10px;
    background: transparent;
    border: none;

    &:hover { opacity: $hoverOpacity; }
    &:disabled {
      opacity: 0.50;
      cursor: default;
    }
  }

  .review-reaction__counter {
    color: $secondaryFontColor;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .review-reaction__counter.thumb-up {
    margin-right: 10px;
  }
</style>
