import actions, { defaultMediaScreen } from 'rs/store/shared/actions/global_actions';

const state = {
  screen: {...defaultMediaScreen}
}

const getters = {
  screen: state => state.screen,
}

const mutations = {
  setMedia: (state, screen) => { state.screen = screen }
}

export default {
  state, mutations, actions, getters,
  namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
}
