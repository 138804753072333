<template>
  <div class="reviewer-profile__dispute-list">
    <div class="reviewer-profile__dispute-card" v-for="dispute in disputes">
      <Dispute :key="dispute.id" :dispute="dispute" :fullVersion="false">
        <template #header="{scopedDispute}">
          <div class="reviewer-dispute__title mb-5">
            <router-link class="reviewer-dispute__link" :to="fullDisputePath(scopedDispute.id)">Issue {{
                scopedDispute.id
              }}
            </router-link>
            <svg-icon class="reviewer-dispute__status-icon" :icon="iconName(scopedDispute.status_text)" color="black"/>
            <div class="reviewer-dispute__status-text">{{ scopedDispute.status_text }}</div>
            <svg-icon class='reviewer-dispute__shield-icon' icon="shield_check" color="green" v-if="scopedDispute.in_program"/>
          </div>
        </template>
      </Dispute>
    </div>
  </div>
</template>
<script>
import Dispute from 'shared/vue_components/arbitration/dispute.vue'
import {iconNameForDispute} from 'shared/vue_components/arbitration/helpers'
import actions from 'rs/components/pages/private_profile/arbitration_actions.js'

export default {
  components: {Dispute},
  props: ['disputes'],
  provide: {arbitrationContext: actions},
  methods: {
    iconName: iconNameForDispute,
    fullDisputePath(disputeId) {
      return {
        name: this.$routes.reviewerArbitrationShow.name,
        params: {disputeId},
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.reviewer-profile__dispute-list {
  font-weight: 400;
}

.reviewer-dispute__title {
  display: flex;
  gap: 4px;
  position: relative;
}
.reviewer-dispute__shield-icon {
  position: absolute;
  right: 0;
}

.reviewer-dispute__link {
  color: $brandColor;
}

.reviewer-profile__dispute-card {
  padding: 30px 28px;
  border-top: solid 1px $feedCardDividerColor;
  @include respond-to(mobile) {
    padding: 30px 12px;
  }
}
</style>
