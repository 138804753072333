<template>
  <div class="currency-dropdown">
    <div class="currency-dropdown__wrapper">
      <CurrencyFlag
          v-if="showFlag && !hasBrokenFlag && currency !== '*'"
          @error="hasBrokenFlag = true"
          class="currency-dropdown__flag" :alt="'currency icon for ' + currency"
          :currencyCode="currency"
      />
      <select
          class="currency-dropdown__select"
          :class="{'currency-dropdown__select-padding-flag': showFlag && !hasBrokenFlag && currency !== '*'}"
          :value="currency" @change="handleChange">
          <option value="*" :selected="currency === '*'">
            Store currency
          </option>
          <option v-for="currencyCode in currencies.slice(0, topCurrency.length)" :value="currencyCode" :selected="currency === currencyCode">
            {{ currencyCode }}
          </option>
        <optgroup label="_________">
          <option v-for="currencyCode in currencies.slice(topCurrency.length)" :value="currencyCode" :selected="currency === currencyCode">
            {{ currencyCode }}
          </option>
        </optgroup>
      </select>
      <span class="currency-dropdown__select-arrow material-icons">expand_more</span>
    </div>

    <div class="currency-dropdown__tips-wrapper" v-if="currency !== '*'">
      <span class='currency-dropdown__tips-header-icon material-icons'>help_outline</span>
      <span>Prices will only be shown in this currency here. Stores may use different currencies.</span>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import CurrencyFlag from 'rs/components/common/currency_flag';

export default {
  components: {CurrencyFlag},
  props: {
    showFlag: {
      type: Boolean,
      default: true
    },
    autoRefetch: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('SharedCurrentReviewer', ['currency', 'exchangeRates']),
    ...mapState('Search', ['searchParams']),
    topCurrency: () => (['AUD', 'CAD', 'EUR', 'GBP', 'INR', 'NZD', 'USD']),
    currencies() {
      return this.parseRatesHashToList()
    }
  },

  data() {
    return {
      hasBrokenFlag: false,
    }
  },
  methods: {
    async handleChange(e) {
      const sourceCurrency = this.currency;
      await this.$store.dispatch('SharedCurrentReviewer/updateCurrency', e.target.value)
      if (!this.autoRefetch) return;
      if (this.$route.name === this.$routes.search.name && (this.searchParams.min_price || this.searchParams.max_price)) {
        // convert min max prices
        await this.$store.dispatch('Search/updateFilter', {
          params: {
            min_price: Math.ceil(this.$sharedUtils.convertCurrencyValue(e.target.value, sourceCurrency, this.searchParams.min_price, this.$store.getters['SharedCurrentReviewer/exchangeRates'], 0)),
            max_price: Math.ceil(this.$sharedUtils.convertCurrencyValue(e.target.value, sourceCurrency, this.searchParams.max_price, this.$store.getters['SharedCurrentReviewer/exchangeRates'], 0)),
          }
        })
        this.$store.dispatch('Search/fetchWithFilters', {currency: e.target.value})
      } else {
        this.$emit('afterChange')
      }
    },
    parseRatesHashToList(){
      const result = [];
      const copyHash = {...this.exchangeRates}
      this.topCurrency.forEach(key => {
        result.push(key);
        delete copyHash[key]
      })
      for (const key in copyHash) {
        result.push(key)
      }
      return result
    }
  },
  watch: {
    currency(v) {
      this.hasBrokenFlag = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';

.currency-dropdown {
  height: 100%;
  width: 100%;
}
.currency-dropdown__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-grow: 1;
  height: 36px;
  width: 100%;
  padding: 0 30px 0 12px;
  border: 1px solid #CDCCCC;
  border-radius: 8px;
  &:focus-within {
    border-color: #CDCCCC;
  }
}
.currency-dropdown__flag {
  height: 40%;
}
.currency-dropdown__select {
  height: 36px;
  cursor: pointer;
  position: absolute;
  z-index: 0;
  width: 100%;
  overflow: hidden;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: $primaryFontColor;
  background-color: transparent;
  padding-right: 40px;
}
.currency-dropdown__select-padding-flag {
  padding: 0 40px 0 28px;
}
.currency-dropdown__select-arrow {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: -1;
}
.currency-dropdown__tips-wrapper {
  display: flex;
  flex-direction: row;
  gap: 4px;
  font-weight: 400;
  margin-top: 8px;
}
.currency-dropdown__tips-header-icon {
  color: $oldBrandColor;
  margin-top: 2px;
  font-size: 20px;
}
</style>
