<template>
  <div>
    <div class="message__name">{{name}}</div>
    <div>{{description}}</div>
    <DisputeFile :file="awardFile" v-if="awardFile" class="mt-2 mb-2"/>
    <a v-if="awardFile" :href="awardFile.url" target="_blank">Read Full Award</a>
  </div>
</template>
<script>
import DisputeFile from "shared/vue_components/arbitration/components/_dispute_file.vue";
import {getProposalType} from "shared/vue_components/arbitration/helpers";

export default {
  props: ['message'],
  inject: ['dispute'],
  components: {DisputeFile},
  computed: {
    resolution(){
      return this.dispute.resolution;
    },
    name() {
      return this.dispute.user_role === 'arbitrator' ? 'Your reply' : this.message.sender.name + ' - Arbitrator'
    },
    buyerDescription() {
      if (this.resolution.reviewer_resolution_type === 'reviewer_no_action') return ''
      return getProposalType(this.resolution.reviewer_resolution_type)
    },
    sellerDescription() {
      if (this.resolution.reviewer_resolution_type === 'shop_no_action') return ''
      return getProposalType(this.resolution.shop_resolution_type)
    },
    description() {
      let text = this.sellerDescription;
      if (text.length > 0 && this.buyerDescription.length > 0) {
        text += ' / '
      }
      text += this.buyerDescription;
      return `Final Arbitration Award - ${text}`
    },
    awardFile() {
      if (!this.dispute.is_awarded) return nil;
      return {
        type: 'pdf',
        url: this.dispute.award_url + '?redesign=1'
      }
    }
  }
}

</script>
