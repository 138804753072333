const defaultState = {
  // store all notification by page,
  // keys are page names (check routes.js)
  // values are notification objects, each page can have multiple notifications
  // e.g: 'PagePrivateProfile': {createdDispute: {...}},
}

const state = {
  ...defaultState
}
const getters = {
  getData: state => pageName => state[pageName],
}

const mutations = {
  setState: (state, [key, data]) => state[key] = data
}

const actions = {
  setCreatedDisputeNotification({commit, state}, shopData) {
    const copyState = {...state['PagePrivateProfile']}
    copyState.createdDispute = {
      shopName: shopData.shopName,
      shopResponseTime: shopData.shopResponseTime
    }
    commit('setState', ['PagePrivateProfile', {...copyState}])

    setTimeout(() => {
      delete copyState.createdDispute
      commit('setState', ['PagePrivateProfile', copyState])
    }, 60 * 1000) // 60 secs
  },
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
  namespaced: true,
}
