<template>
  <div class="judgeme-medals-block" :class="{ 'row-reversed': index % 2!= 0 }">
    <div class="judgeme-medals-block__content-left">
      <div class="medals-images" :data-index="index+1" :ref="'medalImage'">
        <div class="judgeme-medals-block-card__image rs-all-medals-medal__img rs-all-medals-medal__sprite" :class="'rs-all-medals-medal__img--'+selectedMedal.imgClass"></div>
      </div>
    </div>
    <div class="judgeme-medals-block__content-right">
      <h3 class="judgeme-medals-block__content--heading">{{heading}}</h3>
      <p class="judgeme-medals-block__content--paragraph" v-html="description"></p>
      <select v-model="selectedIndex" @change="handleChange">
        <option v-for="(medal, idx) in medalsInfos" :key="idx" :value="idx" :selected="idx===selectedIndex">
          {{ medal.name }}
        </option>
      </select>
      <p class="judgeme-medals-block__content--text">{{ selectedMedalText(text, selectedMedal.threshold) }}</p>
    </div>
  </div>
</template>

<script>

export default {
  props: ['heading', 'description', 'text', 'medalsInfos', 'index'],
  data() {
    return {
      selectedIndex: 0,
    }
  },
  computed: {
    selectedMedal() {
      return this.medalsInfos[this.selectedIndex]
    }
  },
  methods: {
    handleChange() {
      const distance = this.$refs.medalImage.getBoundingClientRect().top
      this.$nextTick(() => {
        this.$sharedUtils.scrollToDistanceWithHeaderOffset(distance || 0)
      })
    },
    selectedMedalText(text, threshold) {
      return text.replace('{{ threshold }}',threshold)
    }
  }
}

</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  .judgeme-medals-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 100px;
    @include respond-to(notdesktop) {
      flex-direction: column;
      gap: 30px;
    }
  }

  .judgeme-medals-block__content-left, .judgeme-medals-block__content-right {
    max-width: 500px;
    position: relative;
    @include respond-to(notdesktop) {
      text-align: center;
    }
  }

  img.judgeme-medals-block-card__image {
    background: white;
    box-shadow: 0px 3.29017px 8.22542px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    margin-top: 45px;
  }

  .medals-images:after {
    content: attr(data-index);
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 250px;
    color: #F4F4F4;
    position: absolute;
    top: -10%;
    left: 72%;
    @include respond-to(mobile) {
      left: 53%;
      top: -16%;
      font-size: 200px;
    }
  }
  select {
    border: 1px solid #DADADA;
    border-radius: 30px;
    width: 70%;
    height: 50px;
    padding: 0 20px;
    margin: 20px 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Svg Vector Icons : http://www.onlinewebfonts.com/icon --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1000 1000' enable-background='new 0 0 1000 1000' xml:space='preserve'%3E%3Cmetadata%3E Svg Vector Icons : http://www.onlinewebfonts.com/icon %3C/metadata%3E%3Cg%3E%3Cg%3E%3Cpath fill='%23088484' d='M124.8,197.6L500,572.7l375.2-375.2L990,312.4l-490,490l-490-490L124.8,197.6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 18px;
    padding-right: 2rem;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    font-weight: 400;
    background-size: 14px;
    @include respond-to(mobile) {
      width: 100%;
    }
  }

  .judgeme-medals__section__content .judgeme-medals-block.row-reversed {
    flex-direction: row-reverse;
    @include respond-to(notdesktop) {
      flex-direction: column;
      gap: 30px;
    }
  }

  h3.judgeme-medals-block__content--heading {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 20px;
    font-weight: 700;
    @include respond-to(mobile) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .judgeme-medals__section__content p {
    @include respond-to(mobile) {
      max-width: 100%;
      margin: 0 auto;
      font-size: 16px;
      line-height: 24px;
      padding: 0 16px;
    }
    font-weight: 400;
  }

  .medals-images {
    background: #F4F4F4;
    height: 293px;
    width: 293px;
    border-radius: 50%;
    text-align: center;
    margin-right: 50px;
    margin: 0 50px 0 0;
    display: flex;
    @include respond-to(mobile) {
      margin: 0 50px 0 -10%;
      height: 200px;
      width: 200px;
    }
  }

  .rs-all-medals-medal__sprite {
    background-size: 1000px;
    background-repeat: no-repeat;
    background-image: url('https://pub-images.judge.me/judgeme/marketplace/medals/new-medals');
  }

  .rs-all-medals-medal__img {

    width: 120px;
    height: 120px;

    &--tops-70 { background-position: 6px -592px; }
    &--tops-80 { background-position: -194px -592px; }
    &--tops-90 { background-position: -394px -592px; }
    &--tops-100 { background-position: 6px -392px; }
    &--tops-200 { background-position: -194px -392px; }
    &--tops-300 { background-position: -394px -392px; }
    &--tops-400 { background-position: -594px -392px; }
    &--tops-500 { background-position: -794px -392px; }

    &--tops_trend-70 { background-position: 6px -994px; }
    &--tops_trend-80 { background-position: -194px -994px; }
    &--tops_trend-90 { background-position: -394px -994px; }
    &--tops_trend-100 { background-position: 6px -794px; }
    &--tops_trend-200 { background-position: -194px -794px; }
    &--tops_trend-300 { background-position: -394px -794px; }
    &--tops_trend-400 { background-position: -594px -794px; }
    &--tops_trend-500 { background-position: -794px -794px; }

    &--auth-100 { background-position: 6px 11px }
    &--auth-200 { background-position: -194px 11px }
    &--auth-300 { background-position: -394px 11px }
    &--auth-400 { background-position: -594px 11px }
    &--auth-500 { background-position: -794px 11px }

    &--tran-100 { background-position: 6px -194px }
    &--tran-200 { background-position: -194px -194px }
    &--tran-300 { background-position: -394px -194px }
    &--tran-400 { background-position: -594px -194px }
    &--tran-500 { background-position: -794px -194px }

    &--ver_rev-100 { background-position: 6px -1196px }
    &--ver_rev-200 { background-position: -194px -1196px }
    &--ver_rev-300 { background-position: -394px -1196px }
    &--ver_rev-400 { background-position: -594px -1196px }
    &--ver_rev-500 { background-position: -794px -1196px }

    &--mon_rec-100 { background-position: 6px -1393px }
    &--mon_rec-200 { background-position: -194px -1394px }
    &--mon_rec-300 { background-position: -394px -1393px }
    &--mon_rec-400 { background-position: -594px -1393px }
    &--mon_rec-500 { background-position: -794px -1393px }
  }

  .judgeme-medals-block-card__image {
    background-color: white;
    border-radius: 50%;
    margin: auto;
    box-shadow: 0px 3.29017px 8.22542px rgba(0, 0, 0, 0.25);
    @include respond-to(desktop) { zoom: 1.2; }
  }

  .judgeme-medals-block__content--paragraph {
    font-weight: 400;
  }

  p.judgeme-medals-block__content--text {
    font-weight: 600;
  }
</style>
