import { setState, fetchRequest } from 'rs/store/store_utils/shared';
import {
  categoriesSortFunctionByBarOrder,
  arrToHashAndPopulateChildren,
} from 'rs/store/store_utils/search_utils';


const setMegaNavLv1 = categories => {
  let lv1 = categories.filter(cat => cat.level === 1)
  lv1.sort(categoriesSortFunctionByBarOrder)
  return lv1.slice(0,6)
}

function sortAndPopulateChildren(id, catMap, sortFunc) {
  const cat = catMap[id]
  if (!cat.children) return cat

  cat.children = cat.children.map(id => catMap[id])
  cat.children.sort(sortFunc)
  cat.children.forEach(child => sortAndPopulateChildren(child.id, catMap, sortFunc))
  return cat
}

const sortCategoriesTree = (catMap, l1Cats, sortFunc) => l1Cats.map(cat => sortAndPopulateChildren(cat.id, catMap, sortFunc))

const state = {
  meganavLv1: [],
  categoriesMap: {},
  categoriesTree: [],
  pageCategoriesTree: [],
}

const mutations = {
  setState: (state, [key, data]) => state[key] = data,
}

const getters = {
  categoryLinks: ({meganavLv1}) => meganavLv1.map(cat => ({
    id: cat.id,
    handle: cat.handle,
    path: `/categories/${cat.handle}`,
    name: cat.name
  })),
  categoriesMap: state => state.categoriesMap,
  categoriesTree: state => state.categoriesTree,
}


const actions = {
  async getMeganavData({commit}) {
    const res = await fetchRequest()()({requestUrl:'/all_categories'})

    const categoriesMap = arrToHashAndPopulateChildren(res)
    setState(commit, 'categoriesMap')(categoriesMap)
    const l1Cats  = setMegaNavLv1(res)
    setState(commit,'meganavLv1')(l1Cats)
    setState(commit,'categoriesTree')(sortCategoriesTree(categoriesMap, l1Cats, categoriesSortFunctionByBarOrder))
  }
}

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,

  namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
}
