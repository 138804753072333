<template>
  <CustomSlider class="shop-result-medals"
                :childComponent="Medal"
                :visibleCount='medalOptions.visibleCount'
                :hideNavigation="medalOptions.hideNavigation"
                :autoScroll = 'medalOptions.autoScroll'
                :items='medals' />
</template>

<script>
  import CustomSlider from 'rs/components/common/custom_slider'
  import Medal from 'rs/components/common/_medal'

  export default {
    props: ['medals'],
    components: { CustomSlider },
    data() {
      return {
        Medal,
        medalOptions: {},
      }
    },
    methods: {
      calcMedalOptions() {
        const cardWidth = this.$el.clientWidth
        if ( cardWidth < 200 ) {
          this.medalOptions =  { visibleCount: 1, autoScroll: false, hideNavigation: false }
        } else {
          this.medalOptions = { visibleCount: 2, autoScroll: false, hideNavigation: false }
        }
      },
    },
    mounted() {
      this.calcMedalOptions()
    }
  }
</script>

<style lang="scss" scoped>
  .shop-result-medals {
    margin: 0 4px;
    width: 100%;
    position: relative;
  }
</style>

<style lang="scss">
  .shop-result-medals {
    .custom-slider__visible-single-item {
      transition: all .1s linear;
    }
    .custom-slider__slide-enter-active {
      transform: translateX(200px);
      transition: all 0.1s linear;
    }
    .custom-slider__slideback-enter-active {
      transform: translateX(-200px);
      transition: all 0.1s linear;
    }

    .custom-slider__slide-leave-active {
      transform: translateX(-200px);
      transition: all 0.3s linear;
    }
    .custom-slider__slideback-leave-active  {
      transform: translateX(200px);
      transition: all 0.3s linear;
    }

    .custom-slider__slide-leave-to {
      transform: translateX(-200px);
      transition: all 0.1s linear;
    }
    .custom-slider__slideback-leave-to {
      transform: translateX(200px);
      transition: all 0.1s linear;
    }

    .custom-slider__navigation-button {
      width: 40px;
    }
    .custom-slider__navigation-button-backward {
      left: 4px; // idk why the material-icons arrow_left not aligned center automatically like arrow_right (with same styles)
      top: 0;
      font-size: 10px;
      justify-content: center;
    }
    .custom-slider__navigation-button-forward {
      right: 0;
      top: 0;
      justify-content: center;
    }
    .custom-slider__navigation-button-icon {
      font-size: 20px;
      color: #333333;
    }
  }
</style>
