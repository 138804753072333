<template>
  <div class="popover-card-layer" @click="close">
    <div class="popover-card" :class="{'popover-card--top': top}" ref="component" @click="$emit('click')">
      <slot></slot>
    </div>
  </div>
</template>
<script>
function adjustPosition(windowSize, elementSize, startPos, ...offsets) {
  const offset = offsets.reduce((sum, i) => sum + i, 0)
  return (windowSize < startPos + elementSize + offset) ? (windowSize - elementSize - offset) : startPos
}
export default {
  props: {
    position: { type: Object, required: true }, // {x, y}
    top: {type: Boolean, default: false }, // if true display card upward
    fixed: { type: Boolean, default: false }, // if true, always respect x,y else: adjust x,y to fit inside window
    padding: { type: Number, default: 12 } // px to pad from the edges when not fixed
  },
  methods: {
    close() {
      this.$emit('close')
    },
    setPosition() {
      const el = this.$refs.component
      const x = this.fixed ? this.position.x : adjustPosition(window.innerWidth, el.clientWidth, this.position.x, this.padding)
      el.style.left = `${x}px`
      const y = this.fixed ? this.position.y : (this.top ? this.position.y : adjustPosition(window.innerHeight, el.clientHeight, this.position.y, this.padding))
      el.style.top = `${y}px`
    }
  },
  mounted() {
    document.addEventListener('scroll', this.close)
    this.setPosition()
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.close)
  }
}
</script>
<style lang="scss" scoped>
.popover-card-layer {
  width: 100dvw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: transparent;
}
.popover-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
  width: fit-content;
  z-index: 11;
  position: absolute;
}
.popover-card--top {
  transform: translateY(-100%);
}
</style>
