<template>
  <TabWrapper>
    <MatchMedia v-slot="{mobile}">
      <WidgetShop />
      <PolicyCard class="mt-4" policyName="refund_policy"/>
      <PolicyCard class="mt-4" policyName="shipping_policy"/>
      <PaymentMethodsCard class="mt-4" />
      <WidgetContact class="mt-4"/>
      <WidgetDispute class="mt-4"/>
      <WidgetMedal class="mt-3"/>
    </MatchMedia>
  </TabWrapper>
</template>

<script>
import PolicyCard from 'rs/components/pages/shop/tabs/_card_policy'
import PaymentMethodsCard from 'rs/components/pages/shop/tabs/_card_payment_methods'
import TabWrapper from 'rs/components/pages/shop/tabs/_tab_wrapper';
import WidgetMedal from 'rs/components/pages/shop/widgets/_widget_medal';
import WidgetContact from 'rs/components/pages/shop/widgets/_widget_contact';
import WidgetDispute from 'rs/components/pages/shop/widgets/_widget_dispute';
import {MatchMedia} from 'shared/vue_media_query/MatchMedia';
import WidgetShop from 'rs/components/pages/product/widgets/_widget_shop';
export default {
  components: {
    MatchMedia, TabWrapper, PaymentMethodsCard,
    WidgetMedal,WidgetContact, PolicyCard, WidgetShop,
    WidgetDispute,
  },
}
</script>
