
export const AVAILABLE_FILE_ICONS = {
	jpg: 'jpeg',
	jpeg: 'jpeg',
	png: 'png',
	pdf: 'pdf',
	tiff: 'tiff',
	doc: 'doc',
	docx: 'doc',
}

export const SUPPORTED_FILE_TYPES = {
	jpg: "image/jpeg",
	jpeg: "image/jpeg",
	gif: "image/gif",
	png: "image/png",
	tiff: "image/tiff",
	pdf: "application/pdf",
	doc: "application/msword",
	docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
}

// keys in object are auto-message values received from backend.
// senderRole_viewerRole
export const MESSAGE_HEADINGS = {
	create_proposal: {
		icon: 'iconamoon:check',
		styleClasses: 'rs-weight-700',
		seller_seller: () => 'Resolution proposal sent.',
		seller_buyer: name => `${name} proposed a resolution!`,
		seller_arbitrator: name => `${name} proposed a resolution!`,
	},
	create_counter_proposal: {
		icon: 'iconamoon:check',
		styleClasses: 'rs-weight-700',
		buyer_buyer: () => 'You suggested another resolution!',
		buyer_seller: () => 'Buyer suggested another resolution!',
		buyer_arbitrator: () => 'Buyer suggested another resolution!',
	},
	accept_proposal: {
		icon: 'iconamoon:check',
		styleClasses: 'rs-weight-700',
		buyer_buyer: () => 'You’ve accepted the proposed resolution!',
		buyer_seller: () => 'Buyer accepted your resolution',
		seller_seller: () => 'You’ve accepted the proposed resolution!',
		seller_buyer: name => `${name} You’ve accepted the proposed resolution!`,
		buyer_arbitrator: () => 'Buyer accepted the proposed resolution!',
		seller_arbitrator: (name) => `${name} accepted the proposed resolution!`,
	},
	reject_proposal: {
		icon: 'iconamoon:close',
		styleClasses: 'rs-weight-700',
		buyer_buyer: () => 'You’ve refused the proposed resolution!',
		buyer_seller: () => 'Buyer refused your resolution',
		seller_seller: () => 'You’ve refused the proposed resolution!',
		seller_buyer: name => `${name} refused the proposed resolution!`,
		buyer_arbitrator: () => 'Buyer refused the proposed resolution!',
		seller_arbitrator: (name) => `${name} refused the proposed resolution!`,
	},
	arbitrator_assigned: {
		icon: 'iconamoon:check',
		styleClasses: 'rs-weight-400',
		arbitrator_arbitrator: () => 'You got assigned to this case!',
		arbitrator_buyer: () => 'An independent arbitrator was assigned to this issue and will be part of this thread.',
		arbitrator_seller: () => 'An independent arbitrator was assigned to this issue and will be part of this thread.',
	},
}

export const PROPOSAL_TYPES = { // map keys to text
	'shop_refund': 'Store shall refund buyer',
	'shop_new_item': 'Store shall send new item',
	// 'shop_publish_review': 'Store shall publish review',
	// 'shop_request_update_review': 'Store requests a review update',
	// 'shop_request_remove_review': 'Store requests to remove negative review',
	'shop_no_action': 'No action needed',
	'shop_other': 'Other',
	'reviewer_request_refund': 'Store shall refund buyer',
	'reviewer_request_new_item': 'Store shall send new item',
	// 'reviewer_request_publish_review': 'Store shall publish review',
	'reviewer_other': 'Other',
}

export const RESOLUTION_TYPES_FOR_REVIEWER = {
 'reviewer_no_action': 'No action needed',
 'reviewer_other': 'Other',
}
export const RESOLUTION_TYPES_FOR_SHOP = {
  'shop_refund': 'Store shall refund buyer',
  'shop_new_item': 'Store shall send new item',
  'shop_no_action': 'No action needed',
  'shop_other': 'Other',
}

export const OPPOSED_PROPOSAL_TYPES = {
	'shop_refund': 'reviewer_request_refund',
	'shop_new_item': 'reviewer_request_new_item',
	'shop_other': 'reviewer_other',
	'shop_publish_review': 'reviewer_request_publish_review'
}

export const LINKS = {
	terms: 'https://judge.me/terms',
	disputeIntro: 'https://help.judge.me/en/articles/8490574-have-you-had-an-issue-with-a-store-or-product',
	awardExplain: 'https://help.judge.me/en/articles/8571263-how-an-award-document-helps-to-resolve-complaints',
	arbitrationProgramForBuyer: 'https://help.judge.me/en/articles/8490574-have-you-had-an-issue-with-a-store-or-product#h_ae5b7f234d',
	arbitrationProgramForStore: 'https://help.judge.me/en/articles/8490614-how-complaints-work-for-merchants#h_574b99ca81',
}
