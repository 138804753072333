<template>
  <TabWrapper>
    <MatchMedia v-slot="{mobile}">
      <QuestionsWidget />
    </MatchMedia>
  </TabWrapper>
</template>
<script>
import {MatchMedia} from "shared/vue_media_query/MatchMedia";
import TabWrapper from 'rs/components/pages/shop/tabs/_tab_wrapper';
import QuestionsWidget from 'rs/components/pages/product/widgets/_widget_questions'

export default {
  components: {TabWrapper, MatchMedia, QuestionsWidget},
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

::v-deep .questions-widget__title {
  font-size: 16px;
  font-weight: 700;
}
.questions-widget {
  border: none;
  background: white;
  padding: 0;
}
</style>
