<template>
  <section class="no-reviews">
    <div class="no-reviews-background"></div>
    <div class="no-reviews-text-wrapper rs-title d-flex-center">
      <p>{{text}}</p>
    </div>
  </section>
</template>
<script>
export default {
  props: ['text'],
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

$mobilePadding: 16px;

.no-reviews {
  height: auto;
  background: white;
  overflow: hidden;
  @include respond-to(desktop) {
    border: $borderStyle;
    border-radius: 10px;
  }
  @include respond-to(notdesktop) {
    padding: 20px $mobilePadding 0 $mobilePadding;
  }
}
.no-reviews-background {
  height: 200px;
  background: url("https://pub-images.judge.me/judgeme/marketplace/public_reviewer/nopublicreviews-desktop.png");
  background-size: cover;
  @include respond-to(notdesktop) {
    height: 168px;
    background: url("https://pub-images.judge.me/judgeme/marketplace/public_reviewer/nopublicreviews_mobile.png");
    background-size: cover;
    border-radius: 10px;
  }
}
.no-reviews-text-wrapper {
  height: 124px;
  font-size: 16px;
}
</style>
