import router from 'rs/plugins/router';
import {shopParams} from 'shared/utils/request_params';
import {mapMediaFromReviews} from "rs/store/shared/gallery_utils";
import utils from 'shared/utils/utils';
import {SearchCache} from 'rs/store/store_utils/search_utils'
import { addStoreSnippet, addBreadCrumbSnippet } from 'shared/utils/json_ld_snippet'
import { applyFilter, clearFilter, removeFilter, applySort, loadReviewsOnPageChange, trackChange } from "rs/store/store_utils/filter_sort_utils";
import shippingCountries from 'shared/constants/country_list_map'
import {setState, fetchRequest} from 'rs/store/store_utils/shared'
import { reviewSources as importedReviewSources} from 'shared/constants/review_sources.js';

const resCache = new SearchCache({}, true, 120) // {[allValuesFromQueryObject]: { sha: ..., results: [] } }
const fetchWithCache = fetchRequest(resCache)()

const defaultFilterQueries = {
  filter_rating: [],
  review_source: 'all_sources',
  review_type: 'all-reviews',
  verified_only: false
}
const reviewTypes = {
  'all-reviews': 'All reviews',
  'shop-reviews': 'Store reviews',
  'product-reviews': 'Product reviews',
}
const reviewSources = importedReviewSources;

const defaultModals = {
  modalWrapper: false,
  showRecommendationModal: false,
};

const defaultState = {
  isLoadingWidgetSummary: true,
  isLoadingWidget: true,
  isLoadingResult: false,
  widgetData: null,
  widgetSummary: null,
  media: [],
  queries: {search: '', sort_by: 'pictures_first', ...defaultFilterQueries},
  previousSearch: '',
  topReviewedProducts: [],
  featuredCards: [],
  modals: {...defaultModals},
  selectedRecommendation: null,
  shopNotFound: false,
  // tab listing states
  tabIndex: 0,
  tabListingComponent: null, // use for scrolling anchor
}
const state = {...defaultState}

const getters = {
  recommendations (state, getters, rootState, rootGetters) {
    const currency = rootGetters['SharedCurrentReviewer/currency']
    const exchangeRates = rootGetters['SharedCurrentReviewer/exchangeRates']
    state.featuredCards.forEach((card) => {
      card.convertedPrice = utils.convertCurrency(currency, state.widgetData.currency, card.lowest_price, exchangeRates) || card.displayed_price
    })
    return state.featuredCards
  },
  selectedRecommendation: state => state.selectedRecommendation,
  modals: state => state.modals,
  widgetData: (state) => state.widgetData,
  shopData: (state) => state.widgetData,
  searchInShopParams: ({widgetData}) => ( widgetData && {
      search_in_shop: true,
      shop_domain: widgetData.domain,
      platform: widgetData.platform,
  }),
  external_url: ({widgetData}) => widgetData ? widgetData.external_url : '',
  medals: ({widgetData}) => {
    const keys = (widgetData.shop_medals && Object.keys(widgetData.shop_medals) || [])                  // widget return shop_medals = { [internal_shop_id]: [medals] }
    if (keys.length === 0) return [];
    return widgetData && widgetData.shop_medals && widgetData.shop_medals[keys[0]] || []
  },
  metrics: ({widgetData}) => widgetData && widgetData.shop_metrics,
  changes: trackChange(defaultFilterQueries, reviewTypes, reviewSources),
  didSearch(state) {
    return !!state.previousSearch
  },
  didFilter(state) {
    return state.queries.filter_rating.length > 0 ||
      state.queries.review_type !== defaultFilterQueries.review_type ||
      state.queries.review_source !== defaultFilterQueries.review_source
  },
  reviewTypes: () => reviewTypes,
  reviewSources: () => reviewSources,
  verifiedOnly: ({queries}) => queries.verified_only,
  countryCode: ({widgetData}) => widgetData && widgetData.country && widgetData.country.toUpperCase(),
  countryName: ({widgetData}) => widgetData && widgetData.country && shippingCountries[widgetData.country.toUpperCase()],
  shopEnabledWebReview: ({widgetData}) => widgetData && !widgetData.settings.disable_web_reviews,
  shouldShowRecommendations: ({widgetData}) => widgetData && widgetData.settings.shop_settings.review_site_show_cards_carousel,
  isLoadingResult: ({isLoadingResult}) => isLoadingResult,
  tabIndex: ({tabIndex}) => tabIndex,
  topReviewedProducts: ({topReviewedProducts}) => topReviewedProducts,
}

const mutations = {
  setState: (state, [name, value]) => state[name] = value, // use for simple mutated state
  clearPageData(state) {
    Object.keys(state).forEach(key=>{
      state[key] = defaultState[key]
    })
  },
  setPageData(state, payload) {
    state.widgetData = payload.shop_data
    state.metaData = payload.meta_data
    state.topReviewedProducts = payload.top_products || []
    state.featuredCards = payload.featured_cards || []
  },
  setWidgetData(state, widgetData) {
    state.widgetData = {...state.widgetData, ...widgetData}
    state.isLoadingResult = false;
  },
  setMedia(state, widgetDataWithMediaParams) {
    state.media = mapMediaFromReviews(widgetDataWithMediaParams.reviews, [])
  },
  setSearchQuery(state, search) {
    state.queries = {...state.queries, search}
  },
  setSortQuery(state, query) {
    state.queries = {...state.queries, ...query}
  },
  setFilterQueries(state, filterQueries) {
    state.queries = {...state.queries, ...filterQueries}
  },
  setModals: (state, [modal, bool = false]) => state.modals[modal] = bool,
  setSelectedRecommendation(state, payload) {
    state.selectedRecommendation = payload;
  },
}
const loadPageData = (dispatch, payload) => (callback) => {
  dispatch('loadPageData', payload).then(callback)
}
const setMetaData = (dispatch) => metaData => {
  dispatch('Meta/setMeta', metaData, {root: true})
}

const actions = {
  async initPage({state, dispatch}, payload) {
    // optimize speed (no need to reload data when user goback or revisit this same store consecutively)
    if (state.widgetData && state.widgetData.internal_path === router.currentRoute.path) {
      setMetaData(dispatch)(state.metaData)
    } else {
      loadPageData(dispatch, payload)(setMetaData(dispatch))
    }
  },
  loadPageData({commit, dispatch}, payload) {
    commit('clearPageData')

    return fetchWithCache({
      requestUrl: `/reviews/${payload.shop_slug}`,
      params: {format: 'json'},
    }).then(function (responseData) {
      commit('setPageData', responseData)
      dispatch('loadWidgetData', {
        platform: responseData.shop_data.platform,
        shopDomain: responseData.shop_data.domain,
        publicToken: responseData.shop_data.public_token,
        reviewType: state.queries.review_type,
        productMetrics: true,
        addHeaderCounts: true,
      })

      const followeeIdsToCheck = {Shop: [responseData.shop_data.encoded_id]}
      dispatch('SharedFollow/fetchFolloweeIds', followeeIdsToCheck, {root: true})
      return responseData
    }).catch(
      function (error) {
        setState(commit, 'setShopNotFound')(true)
      }
    )
  },
  loadReviews({state, dispatch, commit}, payload) {
    setState(commit, 'isLoadingResult')(true)
    const params = {
      shopDomain: state.widgetData.domain,
      platform: state.widgetData.platform,
      publicToken: state.widgetData.public_token,
      sort_by: state.queries.sort_by,
      sort_dir: state.queries.sort_dir,
      search: state.queries.search || null,
      filter_rating: state.queries.filter_rating,
      reviewType: state.queries.review_type,
      reviewSource: state.queries.review_source,
      page: payload.page || 1,
      ...payload,
    }

    dispatch('loadWidgetData', params);
  },
  searchReviews({dispatch, state, commit}) {
    setState(commit, 'previousSearch')(state.queries.search)
    dispatch('loadReviews', {
      page: 1,
      search: state.queries.search || null,
    })
  },
  applyFilter(context, filterQueries) {
    applyFilter(context, filterQueries)()
  },
  clearFilter(context) {
    clearFilter(context)(defaultFilterQueries)
  },
  removeFilter(context, filter){ // filter = { type: xxx, value: yyy}
    removeFilter(context, filter)(defaultFilterQueries)
  },
  applySort(context, payload) {
    applySort(context, payload)()
  },
  loadReviewsOnPageChange(context, payload) {
    loadReviewsOnPageChange(context, payload)()
  },
  loadWidgetData({commit, dispatch, state}, payload) {
    fetchWithCache({
      requestUrl: '/api/v1/widgets/all_reviews_page',
      params: shopParams(payload),
    }).then(function (responseData) {
      commit('setMedia', responseData)
      commit('setWidgetData', responseData)
      setState(commit, 'isLoadingWidget')(false)
      if (payload.addHeaderCounts) {
        const {
          number_of_questions,
          number_of_reviews,
          average_rating,
          rating_histogram_data,
          product_metrics
        } = responseData
        setState(commit, 'widgetSummary')({
          number_of_questions,
          number_of_reviews,
          average_rating,
          rating_histogram_data,
          product_metrics,
        })
        setState(commit, 'isLoadingWidgetSummary')(false)
      }
      addStoreSnippet({...state.widgetData, ...responseData})
      dispatch('getPathList').then(addBreadCrumbSnippet)
    })
  },
  getPathList({state}) {
    return [
      {
        path: state.widgetData.internal_path,
        name: state.widgetData.name
      },
    ]
  },
  toggleModal({commit, state}, [modalName, bool]) {
    if(bool === undefined || bool === null) { bool = !state.modals[modalName]}
    commit('setModals', [modalName, bool]);
    commit('setModals', ['modalWrapper', bool]);
    if(bool) {
      document.body.classList.add('should-lock-scroll');
    } else {
      document.body.classList.remove('should-lock-scroll');
    }
  },
  trackExternalClick({state, dispatch}){
    dispatch('SharedClickTracking/trackExternalClick', {
      href: state.widgetData.external_url,
      shopId: state.widgetData.encoded_id,
    }, {root: true})
  },
  changeTab({commit, dispatch, state}, payload = 0) {
    setState(commit, 'tabIndex')(payload);
    if (state.tabListingComponent) {
      utils.scrollToDistanceWithHeaderOffset(state.tabListingComponent.getBoundingClientRect().top, {
        smooth: false,
        useOffset: true
      })
    }
  },
  registerTabListingComponent({commit}, component) {
    setState(commit, 'tabListingComponent')(component)
  }
}


export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,

  namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
}
