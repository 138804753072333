<template>
  <div class="edit-profile-page">
    <LoadingSpinner v-if="!initialized" data-for="page-spinner"/>
    <template v-else>
      <section class="epp__nav">
        <div class="new-has-max-width epp__nav-container">
          <router-link class="epp__text-button" :to="goBackPath">Go Back</router-link>
        </div>
      </section>
      <div class="edit-page__container">
        <img :src="pageBanner" alt="page banner"/>
        <!-- Card 1 -->
        <div class="epp__form-section">
          <div class="epp__form-container--wide">
            <!--  Banner  upload  -->
            <div class="epp__form-header">Add banner and photo</div>
            <div class="container-size--wide">
              <div class="epp__form-input d-flex-center banner-upload" @click="clickUploadBanner">
                <template v-if="bannerProgress === 101 && banner_url">
                  <img class="banner-upload" :src="banner_url" alt="banner"/>
                  <SmallRoundButton icon="delete" class="epp__delete-button" data-for="banner"
                                    @click.stop="handleRemove"/>
                </template>
                <div v-else-if="bannerProgress < 101" class="progress-text-wrapper d-flex-center">
                  <LoadingSpinner/>
                  <div class="progress-text">
                    {{ bannerProgress }}
                  </div>
                </div>
                <div v-else class="upload-icon"></div>
              </div>
              <input
                type='file' accept="image/*" name="banner"
                class="hidden-upload-input"
                ref='banner' @change="handleUpload"/>
              <div class="epp__label">Recommended banner size: 1426px x 184px / Maximum size file: 1MB.</div>
            </div>
          </div>
          <!--  Avatar upload  -->
          <div class="epp__form-container">
            <div class="d-flex align-center gap-3">
              <div class="epp__form-input d-flex-center avatar-upload" @click="clickUploadAvatar">
                <template v-if="avatarProgress === 101 && avatar_url">
                  <img class="avatar-upload" :src="avatar_url" alt="avatar"/>
                  <SmallRoundButton icon="delete" class="epp__delete-button" data-for="avatar"
                                    @click.stop="handleRemove"/>
                </template>

                <div v-else-if="avatarProgress < 101" class="progress-text-wrapper d-flex-center">
                  <LoadingSpinner/>
                  <div class="progress-text">
                    {{ avatarProgress }}
                  </div>
                </div>
                <div v-else class="upload-icon"></div>
              </div>
              <input
                type='file' accept="image/*"
                class="hidden-upload-input"
                name="avatar"
                ref='avatar' @change="handleUpload"/>
              <div class="epp__text-button" @click="clickUploadAvatar">Upload picture</div>
            </div>

            <div class="epp__form-checkbox-row">
              <input type="checkbox" v-model="formInputs['show_avatar']"/>
              <div class="epp__label" data-for="checkbox">Show my profile picture on the reviews I make.</div>
            </div>
          </div>
        </div>
        <!-- Card 2 -->
        <div class="epp__form-section">
          <div class="epp__form-header">Personal info</div>
          <div class="epp__form-container">
            <div class="container-size" v-for="(field, index) in personalFields" :key="`personal-${index}`">
              <span></span>
              <!--  use value.prop for textarea, it's a bug for textarea in vue  -->
              <!--  https://github.com/vuejs/vue/issues/6154  -->
              <component
                v-if="!shouldHideField(field)"
                :ref="field.name" :name="field.name" :placeholder="field.placeholder" :is="field.tag"
                :disabled="field.disabled" :value="formInputs[field.name]" :value.prop="formInputs[field.name]"
                :maxlength="field.maxlength" v-on="mapListeners(field)" :class="validateFields('epp__form-input', field)">
              </component>
              <label v-if="!shouldHideField(field)"
                     :class="validateFields('epp__label', field)"
                     :for="field.name">{{ mapLabel(field) }}</label>
            </div>
          </div>
          <div class="epp__form-header">
            Your location
          </div>
          <div class="epp__form-container">
          <div
            class="container-size"
            v-for="(field, index) in locationFields"
            :key="`location-${index}`"
          >
            <span></span>
            <component
              :ref="field.name"
              :name="field.name"
              :placeholder="field.placeholder"
              :value="formInputs[field.name]"
              :is="field.tag"
              v-on="mapListeners(field)"
              :class="
                validateFields(
                  'epp__form-input',
                  field
                )
              "
            >
            </component>
            <label
              v-if="!shouldHideField(field)"
              :class="
                validateFields('epp__label', field)
              "
              :for="field.name"
              >{{ mapLabel(field) }}</label
            >
          </div>
          <country-dropdown
            :defaultCountry="country_code"
            @change="handleCountryChange"
          />
          </div>
        </div>
        <!-- Card 3 -->
        <div class="epp__form-section">
          <div class="epp__form-header">Socials (optional)</div>
          <div class="epp__form-container">
            <div class="container-size" v-for="(field, index) in optionalFields" :key="`optional-${index}`">
              <!--  use value.prop for textarea, it's a bug for textarea in vue  -->
              <component
                v-if="!shouldHideField(field)"
                :name="field.name" :placeholder="field.placeholder" :is="field.tag" :disabled="!!field.disabled"
                :value="formInputs[field.name]" :value.prop="formInputs[field.name]"
                v-on="mapListeners(field)" :class="validateFields('epp__form-input', field)">
              </component>
              <label v-if="!shouldHideField(field)"
                     :class="validateFields('epp__label', field)"
                     :for="field.name">{{ mapLabel(field) }}</label>
            </div>
            <div class="epp__form-checkbox-row">
              <input type="checkbox" v-model="formInputs['subscribed_rs']"/>
              <div class="epp__label" data-for="checkbox">Subscribe to emails and newsletter from Judge.me Reviews.</div>
            </div>
          </div>
        </div>

        <Button class="epp__button-submit" :loading="isSubmitting" theme="secondary" @click="submitForm" :disabled="!allowedToSubmit">
          Save changes
        </Button>
      </div>
    </template>
  </div>
</template>
<script>
import LoadingSpinner from 'rs/components/common/loading_spinner';
import SmallRoundButton from 'rs/components/common/small_round_button';
import TextToTags from 'rs/components/common/text_to_tags';
import Button from 'rs/components/common/button';
import CountryDropdown from 'rs/components/uncommon/private_profile/country_dropdown';

import {
  mediaUploader,
  getPresignedData,
} from 'shared/utils/upload_media_files';
import axios from 'shared/utils/axios';

const validateSlug = async slug => {
  try {
    const res = await axios.get('/profile/validate_slug?slug=' + slug)
    return !!res.data.valid
  } catch(e) {
    return false;
  }
}
const mediaFields = [
  {name: 'banner_image_key', value: ''},
  {name: 'avatar_image_key', value: ''},
  {name: 'banner_deleted', value: false},
  {name: 'avatar_deleted', value: false},
  {name: 'show_avatar', value: true},
]
const optionalFields = [
  {name: 'twitter_url', placeholder: 'Add Twitter link or handle', tag: 'input'},
  {name: 'instagram_url', placeholder: 'Add Instagram link or handle', tag: 'input'},
  {name: 'tiktok_url', placeholder: 'Add TikTok link or handle', tag: 'input'},
  {name: 'youtube_url', placeholder: 'Add Youtube link', tag: 'input'},
  {name: 'personal_url', placeholder: 'Add personal website link', tag: 'input'},
]
const personalFields = [
  {
    name: 'name',
    placeholder: 'Write your name*',
    value: '',
    label: 'This name will be used in all communications associated with this email, including the reviews and Q&A you submit.',
    tag: 'input',
    change: (self, state) => e => {
      state.formInputsValidated[self.name] = e.target.value.length > 0;
    }
  },
  {
    name: 'email',
    value: '',
    disabled: true,
    tag: 'input',
  },
  {
    name: 'custom_public_slug',
    placeholder: 'Customise your profile path on Judge.me',
    hidden: state => {
      return !state.profileData.custom_public_slug_activated
    },
    change: (self, state) => e => {
      state.formInputs[self.name] = e.target.value.replace(
        /[^a-z0-9A-Z\s]/g,
        ""
      );
      validateSlug(state.formInputs[self.name])
        .then((res) => {
          state.formInputsValidated[self.name] = res
        })
    },
    input: (self, state) => e => null,
    tag: 'input',
    label(self, state) {
      return `${window.location.origin}/${state.formInputs[self.name] ? state.formInputs[self.name] : 'your_custom_name'}.` +
        " The handle must be between 4 and 100 characters and can only contain letters(a-z), numbers(0-9)," +
        " and these 3 special characters: period(.), dash(-), underscore(_)"
    }
  },
  {
    name: 'description',
    value: '',
    placeholder: 'Write something about yourself',
    tag: 'textarea',
    maxlength: "300",
    label(self, state) {
      return `${state.formInputs[self.name] ? state.formInputs[self.name].length : 0}/300 Characters`
    },
  },
  {
    name: 'categories',
    value: '',
    placeholder: 'Add your interests',
    label: 'Add up to 10 interests. Use comma to separate interests.',
    tag: 'text-to-tags',
    change: (self, state) => arr => {
      state.formInputsValidated[self.name] = arr.length <= 10
      state.formInputs[self.name] = arr.join(',')
    },
  },
]

const extraFields = [
  {name: 'subscribed_rs', value: null}
]

const locationFields = [
  {
    name: 'rs_city',
    value: '',
    placeholder: 'City',
    tag: 'input',
  },
];

const formInputs = [...mediaFields, ...personalFields, ...optionalFields, ...extraFields, ...locationFields].reduce((acc, field) => {
  if (field.disabled) return acc;
  acc[field.name] = field.value
  return acc
}, {})
const formInputsValidated = Object.keys(formInputs).reduce((acc, field) => {
  acc[field] = true;
  return acc
}, {})

export default {
  components: {LoadingSpinner, SmallRoundButton, TextToTags, Button, CountryDropdown},
  inject: ['mediaQueries'],
  data() {
    return {
      initialized: false,
      isSubmitting: false,
      avatarProgress: 101,
      bannerProgress: 101,
      presignedData: '',
      banner_url: '',
      avatar_url: '',
      formInputs,
      formInputsValidated,
      country_code: '',
    }
  },
  computed: {
    allowedToSubmit() {
      return Object.keys(this.formInputsValidated).every(key => this.formInputsValidated[key])
    },
    currentReviewer() {
      return this.$store.getters['SharedCurrentReviewer/currentReviewer']
    },
    profileData() {
      return this.$store.getters['PrivateProfile/profileData']
    },
    personalFields: () => personalFields,
    locationFields: () => locationFields,
    optionalFields: () => optionalFields,
    goBackPath() {
      if (!this.currentReviewer) {
        return this.$routes.home.path
      }
      return {
        name: this.$routes.privateProfile.name,
        params: {
          slug: this.currentReviewer.encoded_id
        }
      }
    },
    pageBanner() {
      return this.mediaQueries.mobile
        ? 'https://pub-images.judge.me/judgeme/marketplace/edit_profile/profile-cover-mobile-1x.png'
        : 'https://pub-images.judge.me/judgeme/marketplace/edit_profile/profile-cover-desktop-1x.png'
    }
  },
  methods: {
    handleCountryChange(v) {
      this.country_code = v;
    },
    clickUploadAvatar() {
      this.$refs.avatar.click();
    },
    clickUploadBanner() {
      this.$refs.banner.click();
    },
    shouldHideField(field) {
      if (field.hasOwnProperty('hidden') && typeof field.hidden === 'function') {
        return field.hidden(this)
      }
      return field.hidden
    },
    handleRemove(e) {
      const field = e.target.dataset['for'];
      this.formInputs[field + '_image_key'] = '';
      this[field + '_url'] = '';
      this.formInputs[field + '_deleted'] = true;
    },
    setUploadResult(fieldName, result = {}) {
      this[fieldName + '_url'] = result.url
      this.formInputs[fieldName + '_image_key'] = result.key
    },
    setProgress(fieldName, progress = 0) {
      this[fieldName + 'Progress'] = progress;
    },
    updateProgress(key, progressEvent) {
      this.setProgress(key, Math.round((100 * progressEvent.loaded) / progressEvent.total));
    },
    async handleUpload(e) {
      const field = e.target.name;
      const file = await this.validateAndPresignFile(this.$refs[field]);
      if (!file) return;
      const uploader = mediaUploader(this.presignedData, this.updateProgress.bind(this, field));
      try {
        this.setProgress(field, 0);
        const result = await uploader(file);
        this.setUploadResult(field, result)
      } catch (e) {
        this.$alertError('Cannot upload image, please try a different file')
      } finally {
        this.setProgress(field, 101)
      }
    },
    async validateAndPresignFile(ref) { // component specific
      const file = ref.files.item(0);
      if (!file) return;
      if (file.size > 1024 * 1024) {
        this.$alertError('File is too large, Please use maximum 1MB for images')
        return;
      }
      if (!this.presignedData) {
        this.presignedData = await getPresignedData(file)
      }
      if (!this.presignedData) {
        this.$alertError('Cannot upload image at the moment. Please try again later.')
        return;
      }
      return file
    },
    mapListeners(field) {
      const listeners = {}
      if (field.hasOwnProperty('change')) listeners.change = field.change(field, this)
      if (field.hasOwnProperty('input')) listeners.input = field.input(field, this)
      else listeners.input = e => this.formInputs[field.name] = e.target.value
      return listeners
    },
    mapLabel(field) {
      return (typeof field.label === 'function') ? field.label(field, this) : field.label
    },
    validateFields(className, field) {
      if (!this.formInputsValidated[field.name] && !field.disabled) {
        className +=  (' ' + className + '--invalid')
      }
      return className
    },
    async submitForm() {
      if (!this.allowedToSubmit) return;
      const data = {}
      for (let key in this.formInputs) {
        data[key] = this.formInputs[key];
      }
      data['rs_country'] = this.country_code;
      try {
        this.isSubmitting = true;
        const res = await this.$store.dispatch('PrivateProfile/sendUpdateProfileRequest', data)
        this.$alertSuccess(res.data.message, { showConfirmButton: false })
        const url = this.$router.resolve(this.goBackPath).href
        window.location.replace(url + (this.$route.query.redesign ? '?redesign=1' : ''))  // reload page for all other places to receive new name, avatar
      } catch (e) {
        this.$alertError(e.message || "Unable to update profile, please try again later or contact our customer support")
      } finally {
        this.isSubmitting = false;
      }
    },
    async fetchAndUpdateProfile() {
      const profileData = await this.$store.dispatch('PrivateProfile/fetchMyProfile')
      if (profileData) {
        const {
          personal_url, twitter_url, instagram_url, tiktok_url, youtube_url,
          name, email, description, categories, show_avatar, custom_public_slug, subscribed_rs,
          rs_country, rs_city,
        } = profileData
        this.formInputs = {
          ...this.formInputs,
          name,
          email,
          description,
          categories,
          show_avatar,
          personal_url,
          twitter_url,
          instagram_url,
          tiktok_url,
          youtube_url,
          custom_public_slug,
          subscribed_rs,
          rs_city,
        }
        this.country_code = rs_country;
        this.avatar_url = profileData.avatar_image_url && (profileData.avatar_image_url.retina || profileData.avatar_image_url.normal)
        this.banner_url = profileData.banner_image_url
      }
    }
  },
  async created() {
    this.$store.dispatch('Meta/getMeta')
    await this.fetchAndUpdateProfile();
    this.$nextTick(() => {
      this.initialized = true
    })
  },
  watch: {
    initialized(bool) {
      if (bool) {
        this.$nextTick(() => {
          const descriptionRef = this.$refs.description && this.$refs.description[0]
          if (descriptionRef) {
            descriptionRef.style.height = descriptionRef.scrollHeight + 'px';
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

$iconColor: #637381;

.edit-profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: -12px 0 40px 0;
  font-size: 16px;
  font-weight: 400;
  @include respond-to(notmobile) {
    margin: -12px 0 60px 0;
  }
}

.epp__text-button {
  color: $brandColor;
  text-decoration: underline;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.epp__nav {
  position: relative;
  width: 100vw;
  background: linear-gradient(270deg, #C1E6E6 5.9%, #D1F3F3 97.43%);
  margin-bottom: 12px;
  height: 44px;
  @include respond-to(notmobile) {
    height: 48px;
    margin-bottom: 20px
  }
}

.epp__nav-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.epp__nav-container > .epp__text-button {
  @include respond-to(desktop) {
    margin-left: 12px;
  }
}

.edit-page__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 988px;
  gap: 20px;

  & > * {
    width: 100%;
  }
}

.epp__form-section {
  background: #FFFFFF;
  border: 1px solid $newBorderStyle;
  border-radius: 10px;
  padding: 32px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.container-size {
  width: 100%;
  max-width: 600px;
}

.container-size--wide {
  width: 100%;
  max-width: 714px;
}

.epp__form-container {
  @extend .container-size;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.epp__form-container--wide {
  @extend .epp__form-container;
  @extend .container-size--wide;
}

.epp__form-header {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  width: 100%;
}

.epp__label {
  font-weight: 400;
  font-size: 12px;
  margin-top: 8px;

  &[data-for='checkbox'] {
    margin-top: 0;
    font-size: 16px;
  }
}

.epp__label--invalid {
  color: red;
}

.epp__form-input {
  width: 100%;
  border: 1px solid #E0E0E0;
  position: relative;
  padding: 18px 25px;
  font-size: 16px;
  font-weight: 400;
  color: $primaryFontColor;

  &::placeholder {
    color: $secondaryFontColor;
  }

  &:focus {
    outline: 1px solid $brandColor;
    border: transparent;
  }
}
.epp__form-input--invalid {
  border: 1px solid red;
}

.epp__form-input.text-box {
  border-radius: 40px;
  min-height: 50px;
  padding: 12px 25px;
}

input.epp__form-input {
  border-radius: 40px;
  height: 50px;
}

textarea.epp__form-input {
  border-radius: 20px;
  height: 130px;
  resize: vertical;
}

.banner-size {
  height: 92px;
  width: 100%;
}

.banner-upload {
  @extend .banner-size;

  .upload-icon {
    font-size: 34px;
    color: $iconColor;
  }
}

.avatar-size {
  height: 68px;
  width: 68px;
}

.avatar-upload {
  @extend .avatar-size;
  border-radius: 50%;

  .upload-icon {
    font-size: 25px;
    color: $iconColor;
  }
}

.banner-upload, .avatar-upload {
  cursor: pointer;
  object-fit: cover;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }
}

.upload-icon {
  font-family: "JudgemeIcons";
  color: $textSecondaryColor;
  position: absolute;
  opacity: 0.5;

  &:before {
    content: "\e023";
  }
}

.hidden-upload-input {
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress-text-wrapper {
  position: relative;
}

.loading-spinner {
  @extend .avatar-size;

  &[data-for='page-spinner'] {
    @include page-spinner;
  }
}

.progress-text {
  position: absolute;
  z-index: 1;
}

input[type="checkbox"] {
  margin-left: 4px; //offset for scale
  margin-top: 4px; //offset for scale
  accent-color: $brandColor;
  -ms-transform: scale(1.4); /* IE */
  -moz-transform: scale(1.4); /* FF */
  -webkit-transform: scale(1.4); /* Safari and Chrome */
  -o-transform: scale(1.4); /* Opera */
  transform: scale(1.4);
}

.epp__delete-button {
  position: absolute;
  height: 24px;
  width: 24px;
  font-size: 16px;
  color: $iconColor;

  &[data-for='banner'] {
    top: 12px;
    right: 12px;
  }

  &[data-for='avatar'] {
    top: 0;
    right: 0;
  }
}

.epp__form-checkbox-row {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.epp__button-submit {
  margin-top: 4px;
  height: 50px;
  width: 360px;
  @include respond-to(notmobile) {
    align-self: flex-end;
  }
}
</style>
