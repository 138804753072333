<template>
  <section class="no-reviews-widget">
    <img :src="no_reviews_image_url" alt="no review image"/>
    <div class="no-reviews-widget__header">No Reviews Yet!</div>
    <div v-if='shopEnabledWebReview' class="no-reviews-widget__description">This product still has no reviews. Be the first to
      <span class="no-reviews-widget__description-link-text"
            @click="$emit('toggleReviewForm')">Leave a product review.</span>
    </div>
  </section>
</template>
<script>
const NO_REVIEWS_IMG_URL = 'https://pub-images.judge.me/judgeme/marketplace/product-page-no-reviews-image.png'
export default {
  props: ['shopEnabledWebReview'],
  computed: {
    no_reviews_image_url: () => NO_REVIEWS_IMG_URL
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.no-reviews-widget {
  background-color: transparent;
  margin-top: 20px;
  padding: 28px;
  line-height: 24px;
  font-size: 16px;
  text-align: center;
  @include respond-to(notmobile) {
    margin-bottom: 20px;
  }
}

.no-reviews-widget__header {
  font-weight: 700;
  margin-top: 16px;
}

.no-reviews-widget__description {
  font-weight: 400;
  margin-top: 16px;
}

.no-reviews-widget__description-link-text {
  cursor: pointer;
  color: $newBrandColor;
  text-decoration: underline;
}

</style>
