'use strict'

import docReady from 'rs/plugins/document_ready.js'
import axios from 'shared/utils/axios'

docReady(function() {
  if (!document.querySelector('.user-terms-modal')) return

  const reviewerNameInput = document.querySelector('.user-terms-modal__reviewer-name')
  const checkbox = document.querySelector('.user-terms-modal__accept-checkbox')
  const submitBtn = document.querySelector('.user-terms-modal__submit-btn')

  const sendAjaxRequest = function() {
    axios.post('/profile/accept_tos', { reviewer_name: reviewerNameInput.value }).then(function(__) {
      closeUserTermsModal()
    })
  }

  const closeUserTermsModal = () => {
    document.querySelector('.user-terms-modal-wrapper').remove()
  }

  const toggleClass = (element, className, shouldAdd) => {
    if (shouldAdd) {
      element.classList.add(className)
    } else {
      element.classList.remove(className)
    }
  }


  reviewerNameInput.addEventListener('input', () => {
    toggleClass(submitBtn, 'disabled', (reviewerNameInput.value == '' || !checkbox.checked))
  })

  checkbox.addEventListener('change', () => {
    toggleClass(submitBtn, 'disabled', (reviewerNameInput.value == '' || !checkbox.checked))
  })

  submitBtn.addEventListener('click', (event) => {
    if (!checkbox.checked) return
    if (reviewerNameInput.value == '') return

    toggleClass(submitBtn, 'disabled', true)
    sendAjaxRequest()
  })
})
