<template>
  <div v-if="isResolution" class="resolution-card__wrapper ">
    <div class="resolution-card__heading">{{ headingText }}</div>
    <template v-if="isAccepted">
      <div class="resolution-card__text">{{ data.cardHeader }}{{ proposalType }}</div>
      <DisputeFile :file="awardFile" v-if="awardFile"/>
      <div class="d-flex gap-4">
        <a v-if="awardFile" class="resolution-card__link" :href="awardFile.url" target="_blank">{{ data.acknowledgementText }}</a>
        <a class="resolution-card__link" :href="data.learnMoreUrl" target="_blank">{{ data.learnMoreText }}</a>
      </div>
    </template>
    <template v-else>
      <template v-if="dispute.user_role === 'buyer'">
        <p class="resolution-card__text">{{ data.buyerTextLine1 }}
          <a class="resolution-card__link" :href="data.arbitrationProgramUrl(dispute.user_role)" target="_blank">{{ data.arbitrationProgramText }}</a>.
        </p>
        <p class="resolution-card__text">{{ data.buyerTextLine2 }}</p>
        <p class="resolution-card__heading">{{ data.buyerTextLine3 }}</p>
        <button class="btn resolution-card__close-button mt-1" @click="closeDispute" :disabled="disputeIsClosed">
          {{ data.buyerButtonText(disputeIsClosed)}}
        </button>
      </template>
      <template v-else>
        <p class="resolution-card__text">
          {{ data.sellerTextLine1Prefix }}
          <a class="resolution-card__link" :href="data.arbitrationProgramUrl(dispute.user_role)" target="_blank">{{ data.arbitrationProgramText }}</a>
          {{ data.sellerTextLine1Suffix }}
        </p>
        <p class="resolution-card__heading">{{ data.sellerTextLine2 }}</p>
        <button class="btn resolution-card__close-button mt-1">{{ data.sellerButtonText }}</button>
      </template>
    </template>
  </div>
</template>
<script>
import {getProposalType, disputeIsClosedOrResolved} from 'shared/vue_components/arbitration/helpers'
import DisputeFile from 'shared/vue_components/arbitration/components/_dispute_file.vue'
import {LINKS} from 'shared/vue_components/arbitration/constants'

export default {
  components: {DisputeFile},
  props: ['message', 'proposal'],
  inject: ['dispute', 'actions'],
  computed: {
    data() {
      return {
        cardHeader: 'Final Award - ',
        acknowledgementText: 'Read Acknowledgment of Agreement',
        acknowledgementUrl: '#dodo',
        learnMoreText: 'Learn More',
        learnMoreUrl: LINKS.awardExplain,
        arbitrationProgramUrl: role => role === 'buyer' ? LINKS.arbitrationProgramForBuyer : LINKS.arbitrationProgramForStore,
        arbitrationProgramText: 'Arbitration Program',
        buyerTextLine1: 'We want to inform you that the store refused your suggested resolution and is not currently part of our ',
        buyerTextLine2: 'As a third-party app for reviews management, our ability to mediate disputes is limited when the store is not enrolled in our program. However, we strongly encourage stores to join our Arbitration Program to ensure fair and efficient conflict resolution.',
        buyerTextLine3: 'We will suggest to this store to participate in our Arbitration Program.',
        buyerButtonText: isClosed => isClosed ? 'Issue Closed' : 'Close Issue',
        sellerTextLine1Prefix: 'Although your store is currently not enrolled in our ',
        sellerTextLine1Suffix: ', we highly recommend joining it. Stores participating in the arbitration program enjoy enhanced trustworthiness and the advantage of impartial arbitrator decisions. By becoming a part of this program, you can resolve disputes with confidence, knowing that the most equitable decisions will be made, ultimately saving you time, effort and improving your customer support.',
        sellerTextLine2: 'If you want to learn more click on the button below.',
        sellerButtonText: 'Request More Info',
      }
    },
    isAccepted() {
      return this.message.text === 'accept_proposal';
    },
    isRefused() {
      return this.message.text === 'reject_proposal';
    },
    disputeIsClosed() {
      return disputeIsClosedOrResolved(this.dispute);
    },
    isResolution() {
      return this.message.is_system && this.proposal && (this.isAccepted || (this.isRefused && !this.dispute.in_program))
    },
    headingText() {
      return this.isAccepted ? 'Resolution determined' : 'Resolution not agreed!'
    },
    proposalType() {
      return getProposalType(this.proposal.proposal_type)
    },
    closeDispute() {
      return this.actions && this.actions.updateDispute.bind(null, {
        disputeId: this.dispute.id,
        new_dispute: true,
        actionType: 'close'
      })
    },
    awardFile() {
      if (!this.dispute.is_awarded) return nil;
      return {
        type: 'pdf',
        url: this.dispute.award_url + '?redesign=1'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.resolution-card__wrapper {
  width: 100%;
  background: var(white, linear-gradient(180deg, #085F84 0%, #087D84 100%));
  padding: 24px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  font-weight: 400;
}

.resolution-card__heading {
  font-weight: 700;
}
.resolution-card__link {
  color: white;
}

.resolution-card__close-button,
.resolution-card__close-button:hover,
.resolution-card__close-button:focus {
  max-width: 344px;
  border: solid 2px white;
  color: white;
  font-weight: 700;
  font-size: 16px;
  background: transparent;
}

.resolution-card__close-button[disabled] {
  background: transparent;
}
</style>
