<template>
  <div :class="['marketplace__circles--wrapper', `marketplace__circles--block${blockIndex}`]">
    <div class="marketplace__circles--background"></div>
  </div>
</template>

<script>
export default {
  props: ['blockIndex'],
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.marketplace__circles--wrapper {
  height: 0;
  z-index: -1;
  position: relative;
}

.marketplace__circles--background {
  border-radius: 50%;
  border: 1px solid #d0ecee;
  height: 66px;
  width: 66px;
  border-radius: 50%;
  border: 1px solid #d0ecee;
  box-shadow: 0px 0px 0px 15px #fff,
  0px 0px 0px 16px #d0ecec,
  0px 0px 0px 35px #fff,
  0px 0px 0px 36px #d0ecec,
  0px 0px 0px 50px #fff,
  0px 0px 0px 51px #d0ecec;
}

.marketplace__circles--wrapper.marketplace__circles--block0 {
  margin-top: -90px;
  margin-left: -9px;
  margin-bottom: 80px;
}
.marketplace__circles--wrapper.marketplace__circles--block3 {
  float: right;
  margin-top: -46px;
  margin-bottom: 80px;
  margin-right: -20px;
}
.marketplace__circles--wrapper.marketplace__circles--block5 {
  margin-top: -46px;
  margin-bottom: 80px;
  margin-left: -20px;
}
</style>
