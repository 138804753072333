<template>
  <section class="orders-section">
    <LoadingSpinner v-if="isLoading"/>
    <template v-else-if="lineItems.length === 0">
      <p class="rs-title os__no-orders">There is no products to review yet</p>
    </template>
    <template v-else>
      <p class="rs-title">{{ title }}</p>
      <p class="rs-description">{{ description }}</p>
      <div class="os__list">
          <LineItemCard v-for="(lineItem, index) in paginatedItems" :item="lineItem" :key="index"/>
          <MatchMedia v-slot="{mobile}">
              <Pagination v-if="totalPages > 1"
                          class="os__pagination" :currentPage="currentPage"
                          :minItem="mobile ? 3 : 8" :displayRange="mobile ? 1 : 3"
                          :totalPage="totalPages" @onChange="e => currentPage = e"
              />
          </MatchMedia>
      </div>
    </template>

  </section>
</template>
<script>
import LineItemCard from 'rs/components/pages/write_review/_line_item_card'
import LoadingSpinner from 'rs/components/common/loading_spinner'
import Pagination from 'rs/components/common/pagination';
import {MatchMedia} from "shared/vue_media_query/MatchMedia";

const PER_PAGE = 4;

export default {
  components: {MatchMedia, LineItemCard, LoadingSpinner, Pagination},
  data() {
    return {
      currentPage: 1,
    }
  },
  computed: {
    title: () => 'Review products I bought',
    description: () => 'Select an option below to start.',
    lineItems() { return this.$store.getters['WriteReview/unReviewedLineItems'] },
    totalPages() { return Math.ceil(this.lineItems.length/PER_PAGE) },
    isLoading() { return this.$store.getters['WriteReview/isLoading'] },
    paginatedItems() {
      return this.lineItems.slice((this.currentPage - 1) * PER_PAGE, this.currentPage * PER_PAGE)
    }
  },
  created(){
    this.$store.dispatch('WriteReview/fetchOrders')
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.loading-spinner {
  margin: 100px auto;
  width: 72px;
  height: 72px;
}
.os__no-orders {
  margin: 100px auto;
  width: max-content;
}

.os__list {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  gap: 8px;
  @include respond-to(mobile) {
    gap: 20px;
    padding: 0 4px;
  }
}
.os__pagination {
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

</style>
<style lang="scss">
@import '~PlatformStyles/abstract/variables_new';

.pagination.os__pagination {
  .pagination__page-number:not(.pagination__page-number--disabled) {
    color: $brandColor;
    font-weight: 400;
  }
}
</style>
