const VIMEO_ID_MAX_LENGTH = 16;

// for use with gallery feature, add additional fields to media object
export function enrichMediaObject(review){
  let result = []
  review.video_external_ids.forEach(el => {
    result.push({
      id: review.uuid + el,
      review_uuid: review.uuid,
      dataType: 'video_external',
      source: el.length > VIMEO_ID_MAX_LENGTH ? 'cloudflare' : 'vimeo',
      videoId: el })
  });
  review.media_platform_hosted_video_infos.forEach(el => {
    result.push({
      id: review.uuid + el.media_platform_url,
      review_uuid: review.uuid,
      dataType: 'video_platform',
      ...el })
  });
  review.pictures_urls.forEach(el => {
    result.push({
      id: review.uuid + el.huge,
      review_uuid: review.uuid,
      dataType: 'picture',
      ...el })
  });
  return result
}

export function mapMediaFromReviews(reviews, oldMedias) { // if pass [] for oldMedias, reviewsHash is not needed
  const hash = {}
  oldMedias.forEach(el => hash[el.review_uuid] = el);
  return reviews.reduce((acc, review) => {
    if (hash[review.uuid]) { // prevent duplicate in media array (usually when user open from a random review)
      return acc
    };
    const enrichedMedia = enrichMediaObject(review)
    return acc.concat(enrichedMedia)
  }, oldMedias)
}

export function getSelectedReviewUUID(media, forwardedMediaId) {
  let found_media = media.find(e => e.id === forwardedMediaId)
  if (found_media) {
    return found_media.review_uuid
  } else {
    return  null
  }
}

export function mapReviewsToHash(reviews, oldReviews = {}) {
  return reviews.reduce((acc, review) => {
    acc[review.uuid] = review
    return acc
  }, oldReviews)
}
