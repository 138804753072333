<template>
  <form class="filter-price" @submit.stop.prevent="handleSubmit">
    <!-- need this button for submit event to fire -->
    <button type="submit" hidden></button>


    <div class="filter-price__selections">
      <p class='filter-price__selections-item' :class="{'filter-price__selections-item--highlight': isHighlighted(0,ranges[0])}" @click="handleSelection(0,ranges[0])">Up to
        {{displayCurrency(ranges[0])}}</p>
      <p class='filter-price__selections-item' :class="{'filter-price__selections-item--highlight': isHighlighted(range[0], range[1])}" @click="handleSelection(range[0], range[1])" v-for="range in ranges.slice(1,ranges.length-1)">
        {{displayCurrency(range[0])}} to {{displayCurrency(range[1])}}</p>
      <p class='filter-price__selections-item' :class="{'filter-price__selections-item--highlight': isHighlighted(ranges[ranges.length -1], 0)}" @click="handleSelection(ranges[ranges.length -1], 0)">Over
        {{displayCurrency(ranges[ranges.length - 1])}}</p>
    </div>
    <div class="filter-price__inputs">
      <div class="filter-price__input-wrapper">
        <span class="prefix">{{ symbol }}</span>
        <input type="number" :value="min" class="filter-price__input" placeholder="Min" min=0
               @input="handleChangeMin"/>
      </div>
      <div class="filter-price__input-wrapper">
        <span class="prefix">{{ symbol }}</span>
        <input type="number" :value="max" class="filter-price__input" placeholder="Max" min=0
               @input="handleChangeMax"/>
      </div>
    </div>
  </form>
</template>
<script>
const defaultFirstValueInUSD = 25; // default value for USD
const nonConvertCurrencies = ['USD', 'GBP', 'EUR', 'AUD', 'CAD']
export default {
  props: ['min', 'max'],
  computed: {
    ranges(){
      let firstValue = defaultFirstValueInUSD;
      const currency = this.$store.getters['SharedCurrentReviewer/currency']
      if (currency === 'USD' || nonConvertCurrencies.findIndex(e => e === currency) !== -1) {
        return [
            this.convertCurrencyValue(firstValue),
            [this.convertCurrencyValue(firstValue), this.convertCurrencyValue(firstValue*2)],
            [this.convertCurrencyValue(firstValue*2), this.convertCurrencyValue(firstValue*4)],
            this.convertCurrencyValue(firstValue*4),
          ]
      }
      return [
        this.convertCurrencyValue(firstValue, currency),
        [this.convertCurrencyValue(firstValue, currency), this.convertCurrencyValue(firstValue*2, currency)],
        [this.convertCurrencyValue(firstValue*2, currency), this.convertCurrencyValue(firstValue*4, currency)],
        this.convertCurrencyValue(firstValue*4, currency),
      ]
    },
    symbol() {
      return this.$store.getters['SharedCurrentReviewer/currencySymbol'];
    }
  },
  methods: {
    displayCurrency(val){
      const currency = this.$store.getters['SharedCurrentReviewer/currency'] === '*' ? 'USD' : this.$store.getters['SharedCurrentReviewer/currency'];
      const currencyFormatter = new Intl.NumberFormat('en', {
        style: 'currency',
        currency,
        maximumFractionDigits: 0,
      });
      return currencyFormatter.format(val);
    },
    convertCurrencyValue(val, cur = 'USD'){
      return Math.ceil(this.$sharedUtils.convertCurrencyValue(cur, 'USD', val, this.$store.getters['SharedCurrentReviewer/exchangeRates'], 0)) || val;
    },
    handleChangeMin(e) {
      this.handleChange([parseFloat(e.target.value), this.max]);
    },
    handleChangeMax(e) {
      this.handleChange([this.min, parseFloat(e.target.value)]);
    },
    handleChange(e){
      this.$emit('change', e)
    },
    handleSubmit(){
      const mobileWidth = 767
      if (window.innerWidth > mobileWidth) {
        this.$emit('submit')
      }
    },
    handleSelection(min,max){
      this.handleChange([min,max])
      this.handleSubmit();
    },
    isHighlighted(min,max){
      const tempMin = this.min || 0;
      const tempMax = this.max || 0;
      return tempMin == min && tempMax == max
    },
  }

}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';

.filter-price {
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.filter-price__selections {
  display: flex;
  flex-direction: column;
}
.filter-price__selections-item {
  color: $newBrandColor;
  line-height: 24px;
  font-weight: 400;
  cursor: pointer;

  &--highlight {
    color: black;
    font-weight: 600;
  }
}
.filter-price__inputs {
  gap: 12px;
  margin-top: 12px;
  display: flex;
  height: 50px;
  vertical-align: middle;
  line-height: 50px;
  width: 240px;
}

.filter-price__input-wrapper {
  height: 50px;
  border-radius: 8px;
  border: 1px solid #CDCCCC;
  padding-left: 8px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;

  &:focus-within {
    border-color: #777;
  }
}

.filter-price__input {
  width: 100px;
  height: 50px;
  border: none;
  outline: none;
  padding-left: 4px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
