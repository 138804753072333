import axios from 'shared/utils/axios'
import {mediaUploader} from 'shared/utils/upload_media_files.js'


const defaultParams = {format: 'json', redesign: true}
const fetchAllDisputes = url => async (params = {}) => {
  try {
    const response = await axios.get(url(), {
      params: {...defaultParams, page: 1, ...params},
    })
    return response.data.data
  } catch (e) {
    console.log('Failed to fetch disputes', e)
    return null
  }
}

const fetchDispute = url => async disputeId => {
  try {
    const response = await axios.get(url(disputeId), {
      params: defaultParams,
    })
    return response.data.dispute
  } catch (e) {
    console.log('Failed to fetch dispute', e)
    return null
  }
}

const createChat = url => async ({disputeId, message}) => {
  try {
    const response = await axios.post(url(disputeId), {...defaultParams, message: message})
    return response.data.data
  } catch (e) {
    console.log('Failed to post chat message', e)
  }
}

const respondProposal = url => async ({disputeId, accepted, new_dispute}) => {
  try {
    const response = await axios.put(url(disputeId), {...defaultParams, accepted, new_dispute})
    return response.data.dispute
  } catch (e) {
    console.log('Failed to respond to proposal', e)
  }
}

const createProposal = url => async ({disputeId, proposalType, description, new_dispute}) => {
  try {
    const response = await axios.post(url(disputeId), {
      ...defaultParams,
      proposal_type: proposalType,
      description: description,
      new_dispute,
    })
    return response.data.dispute
  } catch (e) {
    console.log('Failed to create proposal', e)
  }
}

const updateDispute = url => async ({disputeId, actionType, new_dispute}) => {
  if (!['resolve', 'close'].includes(actionType)) {
    console.log('Wrong action type')
    return;
  }
  try {
    const response = await axios.put(url(disputeId), {...defaultParams, dispute_action: actionType, new_dispute})
    return response.data.dispute
  } catch (e) {
    console.log('Failed to create proposal', e)
  }
}

const uploadFile = async (presignData, file, callback) => {
  try {
    const uploadedFile = await mediaUploader(presignData, callback)(file)
    return {
      ...uploadedFile,
      uniqName: file.uniqName,
    }
  } catch (e) {
    console.log('Failed to upload dispute files', e)
    return null;
  }
}

const getPresignedData = url => async (disputeId = 0) => {
  try {
    if (!window.disputeFilePresignedData) {
      window.disputeFilePresignedData = {}
    }
    if (!window.disputeFilePresignedData[disputeId]) {
      const response = await axios.get(url(disputeId), {params: defaultParams})
      window.disputeFilePresignedData[disputeId] = response.data
    }
    if (!window.disputeFilePresignedData[disputeId]) {
      throw new Error('Cannot upload files at the moment. Please try again later.')
    }
    return window.disputeFilePresignedData[disputeId]
  } catch (e) {
    console.log('Failed to get presigned data', e)
    return null
  }
}

const postMessageFiles = url => async ({disputeId, files}) => {
  try {
    const keys = files.map(file => file.key)
    const response = await axios.post(url(disputeId), {...defaultParams, files: keys})
    return response.data.data
  } catch (e) {
    console.log('Failed to create proposal', e)
    return null
  }
}

const postResolution = url => async ({
  disputeId,
  reviewerResolutionType,
  shopResolutionType,
  reviewerResolutionDescription,
  shopResolutionDescription,
  new_dispute,
}) => {
  try {
    const response = await axios.post(url(disputeId), {
      ...defaultParams,
      new_dispute,
      reviewer_resolution_type: reviewerResolutionType,
      reviewer_resolution_description: reviewerResolutionDescription,
      shop_resolution_type: shopResolutionType,
      shop_resolution_description: shopResolutionDescription,
    })
    return response.data.dispute
  } catch (e) {
    console.log('Failed to post resolution', e)
  }
}

const requestArbitrator = url => async ({disputeId, new_dispute}) => {
  try {
    const response = await axios.get(url(disputeId), {params: {...defaultParams, new_dispute}})
    return response.data.dispute
  } catch (e) {
    console.log('Failed to request arbitrator', e)
  }
}

export {
  fetchAllDisputes,
  fetchDispute,
  createChat,
  respondProposal,
  createProposal,
  updateDispute,
  uploadFile,
  postMessageFiles,
  getPresignedData,
  postResolution,
  requestArbitrator,
}
