<template>
  <section class="following-page">
    <div class="following-page__title">{{pageTitle}}</div>
    <div class="following-page__description">Follow products, stores and people to see their activity on your <router-link to="/feed">feed</router-link>.</div>
    <div class="following-page__divider"></div>
    <LoadingSpinner v-if='!init'/>
    <div v-else>
      <div class="following-page__section">
        <div class="following-page__title following-page__title--sub">Products</div>
        <p class="following-page__description">{{ description('products', products.length) }}</p>
        <div class="following-page__list">
          <div v-for="(item, index) in products" :key="index">
            <FollowCard :itemClass="itemClass.product" :item="item" />
            <div v-if="index !== products.length - 1" class="following-page__card-divider"></div>
          </div>
        </div>
        <p v-if="products.length !== 0" class="following-page__description">Want to follow more products?</p>
        <router-link class="btn pf-secondary-button following-page__section-button" to="/categories">{{ productButton }}</router-link>
      </div>


      <div class="following-page__section">
        <div class="following-page__title following-page__title--sub">Stores</div>
        <p class="following-page__description">{{ description('stores', shops.length) }}</p>
        <div class="following-page__list">
          <div v-for="(item, index) in shops" :key="index">
            <FollowCard :itemClass="itemClass.shop" :item="item" />
            <div v-if="index !== shops.length - 1" class="following-page__card-divider"></div>
          </div>
        </div>
        <router-link class="btn pf-secondary-button following-page__section-button" to="/store_search">{{ storeButton }}</router-link>
      </div>

      <div class="following-page__section">
        <div class="following-page__title following-page__title--sub">Reviewers</div>
        <p class="following-page__description">{{ description('people', reviewers.length) }}</p>
        <div class="following-page__list">
          <div v-for="(item, index) in reviewers" :key="index">
            <FollowCard :itemClass="itemClass.reviewer" :item="item" />
            <div v-if="index !== reviewers.length - 1" class="following-page__card-divider"></div>
          </div>
        </div>
        <router-link class="btn pf-secondary-button following-page__section-button" to="/people">{{ reviewerButton }}</router-link>
      </div>

    </div>

  </section>
</template>
<script>
  import FollowCard from 'rs/components/pages/following/follow_card'
  import LoadingSpinner from 'rs/components/common/loading_spinner'
  import { mapState } from 'vuex'

  const ITEM_CLASS = {
    reviewer: "Reviewer",
    shop: "Shop",
    product: "Product"
  }
  const DEFAULT_BUTTON_TEXT = "Start Following"

  export default {
    components: { FollowCard, LoadingSpinner },
    computed: {
      ...mapState('Following', ['init', 'reviewers', 'shops', 'products']),
      ...mapState('SharedFollow', ['followeeIds']),
      pageTitle: () => 'Things and people you follow',
      itemClass: () => ITEM_CLASS,
      productButton(){ return this.products.length > 0 ? 'Browse By Category' : DEFAULT_BUTTON_TEXT },
      storeButton(){ return this.shops.length > 0 ? 'Follow More Stores' : DEFAULT_BUTTON_TEXT },
      reviewerButton(){ return this.reviewers.length > 0 ? 'Follow More People' : DEFAULT_BUTTON_TEXT }
    },
    methods: {
      description(word, listLength) {
        return listLength === 0
            ? (word === 'people' ? "You're not following anyone yet." : `you are following 0 ${word}.`)
            : `${word} you are following.`
      },
    },
    created(){
      this.$store.dispatch('Following/fetchFollowings')
    }
  }
</script>
<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .loading-spinner {
    margin: 24px auto;
    width: 72px;
    height: 72px;
  }
  .following-page {
    display: block;
    margin: 20px auto 76px auto;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: $primaryFontColor;
    p::first-letter {
      text-transform:capitalize;
    }
    @include respond-to(desktop) {
      margin-top: 44px;
    }
  }
  .following-page__section {
    margin-top: 30px;
  }
  .following-page__section-button {
    height: 40px;
    width: 100%;
    @include respond-to(notmobile) {
      width: 343px
    }
  }

  .following-page__title {
    display: inline-block;;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
  .following-page__title--sub {
    line-height: 30px;
  }
  .following-page__description {
    margin: 10px 0 16px 0;
  }
  .following-page__list {
    background-color: white;
    color: $secondaryFontColor;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .following-page__divider {
    border: $borderStyle;
  }
  .following-page__card-divider {
    height: 1px;
    background-color: $feedCardDividerColor;
  }
</style>
