<template>
  <div>
    <Header :categories="categories" :viewAllLink="viewAllLink"/>
    <Body :query="query"/>
  </div>
</template>
<script>
import Header from 'rs/components/pages/categories/_product_view_header'
import Body from 'rs/components/pages/categories/_product_view_body';
import {mapGetters} from "vuex";

export default {
  components: {Header, Body},
  computed: {
    ...mapGetters('Category', ['childCategories', 'sortedChildCategories', 'category']),
    query() {
      return {sort_by: 'reviews_count', ...this.$route.query, category_id: this.category.id}
    },
    categories() {
      return this.sortedChildCategories.slice(0, 6)
    },
    viewAllLink() {
      return this.childCategories.length > 6 || this.childCategories.length !== this.sortedChildCategories.length
        ? {name: this.$route.name, query: {redesign: this.$route.query.redesign, view_all: true}}
        : null
    },
  },
}

</script>
