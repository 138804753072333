<template>
  <MatchMedia v-slot="{mobile}">
    <PageNotFound v-if="shopNotFound"/>
    <div v-else class="store-page">
      <Gallery v-if="galleryIsOpen"/>
      <LoadingSpinner v-if='isLoadingWidget || isLoadingWidgetSummary'/>
      <template v-else>
        <div class="store-page__container">
          <div class="store-page__container-main">
            <div ref='shopCard' class='store-page__shop-card'>
              <ShopInfo :allProductsRoute="allProductsRoute"/>
              <Media v-if="media.length > 0" class="store-page__media-wrapper" :media="media"
                     ref="media" :visibleCount="visibleMediaCount" :openGallery="openGallery"/>
              <div class="store-page__reviews-buttons-wrapper">
                <ExternalLink @click="trackExternalClick" class="btn pf-secondary-button marketplace__visit-store-link"
                              :href="widgetData.external_url" text="Visit Store"/>
                <div class="store-page__view-reviews-button" ref="writeReviewButton"
                     @click="toggleReviewForm">
                  Write a Review
                </div>
              </div>
              <div ref="reviewForm">
                <CollapsibleItem>
                  <div v-show='showReviewForm' class="store-page__review-form">
                    <ReviewForm
                      :resource="widgetData"
                      resourceType='Shop'
                      @closeForm="toggleReviewForm"
                      @doneSubmit="navigateToReviews"
                    />
                  </div>
                </CollapsibleItem>
              </div>
            </div>

            <TabListing/>
          </div>

          <div class="store-page__container-side">
            <Sidebar @navigateToReviews="navigateToReviews"/>
          </div>
        </div>
        <RecommendationsWidget class="store-page__recommendation-widget"/>
        <RecommendationModal v-if="showRecommendationModal"/>
        <PromiseWidget v-if="mobile" class="store-page__promise-widget"/>
      </template>
    </div>
  </MatchMedia>
</template>
<script>
import LoadingSpinner from 'rs/components/common/loading_spinner';
import ExternalLink from 'rs/components/common/external_link';
import Gallery from 'rs/components/common/gallery';
import CollapsibleItem from "rs/components/common/collapsible_item";
import PromiseWidget from 'rs/components/common/promise_widget';
import PageNotFound from 'rs/components/common/page_not_found'
import ShopInfo from 'rs/components/pages/shop/sections/_section_shop_info';
import Sidebar from 'rs/components/pages/shop/_side_container';
import RecommendationsWidget from 'rs/components/pages/shop/widgets/_widget_recommendations';
import TabListing from 'rs/components/pages/shop/sections/_section_tab_listing';
import ReviewForm from 'rs/components/uncommon/reviews/review_form';
import Media from 'rs/components/common/media';
import RecommendationModal from 'rs/components/common/recommendation/recommendation_card_modal.vue'

import {mapState, mapGetters} from "vuex";
import {removeSnippets} from "shared/utils/json_ld_snippet";
import {MatchMedia} from "shared/vue_media_query/MatchMedia";
import {computed} from 'vue'

export default {
  components: {
    MatchMedia,
    Media,
    ReviewForm,
    CollapsibleItem,
    Gallery,
    ShopInfo,
    LoadingSpinner,
    Sidebar,
    PromiseWidget,
    ExternalLink,
    RecommendationsWidget,
    RecommendationModal,
    PageNotFound,
    TabListing
  },
  inject: ['mediaQueries'],
  provide() {
    return {shopData: computed(() =>this.widgetData)}
  },
  computed: {
    ...mapGetters('Shop', ['widgetData', 'medals', 'didSearch', 'didFilter', 'modals', 'shopEnabledWebReview']),
    ...mapState('Shop', [
      'media', 'queries', 'widgetSummary',
      'isLoadingWidgetSummary', 'isLoadingWidget',
      'previousSearch', 'topReviewedProducts', 'shopNotFound',
    ]),
    showRecommendationModal() {
      return this.modals.showRecommendationModal
    },
    galleryIsOpen() {
      return this.$store.getters['Gallery/isOpen']
    },
    hasReviewsOrNotFilteredYet() {
      return (this.widgetData.reviews && this.widgetData.reviews.length > 0) || this.didSearch || this.didFilter;
    },
    allProductsRoute() {
      return {
        text: 'View All Products',
        name: 'PageSearch',
        query: {
          shop_domain: this.widgetData.domain,
          platform: this.widgetData.platform,
          search_in_shop: true,
          min_transparency: 0,
        }
      }
    },
    isMobile() {
      return this.mediaQueries.mobile
    },
    visibleMediaCount() {
      return this.isMobile ? 4 : 7
    },
  },
  data: () => ({
    showReviewForm: false,
  }),
  methods: {
    toggleReviewForm() {
      this.showReviewForm = !this.showReviewForm
      // handle scroll
      let distance;
      if (this.showReviewForm) {                                     // opening form
        distance = this.$refs.reviewForm.getBoundingClientRect().top
      } else {                                                       // closing form
        distance = this.$refs.writeReviewButton.getBoundingClientRect().top;
      }
      this.$nextTick(() => {
        this.$sharedUtils.scrollToDistanceWithHeaderOffset(distance || 0, {smooth: !!this.showReviewForm})
      })
    },
    openGallery(id, review_uuid) {
      this.$store.dispatch('Gallery/openGallery', {
        forwardedMediaId: id,
        review_uuid: review_uuid,
        encodedId: this.widgetData.encoded_id,
        sourceType: 'shop'
      })
    },
    handleSmoothScroll(refPos) {
      this.$nextTick(() => {
        this.$sharedUtils.scrollToDistanceWithHeaderOffset(refPos)
      })
    },
    navigateToReviews() {
      this.$store.dispatch('Shop/changeTab')
    },
    trackExternalClick() {
      this.$store.dispatch('Shop/trackExternalClick')
    },
  },
  created() {
    this.$store.dispatch('Shop/initPage', this.$route.params);
  },
  beforeDestroy() {
    removeSnippets()
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

$mobilePadding: 16px;
$desktopPadding: 28px;
.loading-spinner {
  margin: 144px auto;
  width: 72px;
  height: 72px;
}

.store-page {
  font-weight: 400;
  font-size: 16px;
  padding: 0;
  margin: -12px -12px 0 -12px;
  @include respond-to(desktop) {
    margin: -12px 0 -20px 0;
  }
}

.store-page__container {
  display: flex;
}

.store-page__container-side {
  @include respond-to(notdesktop) {
    display: none;
  }
}

.store-page__container-main {
  width: 100%;
  @include respond-to(desktop) {
    margin-bottom: 40px;
  }
}

.store-page__shop-card {
  width: 100%;
  background-color: white;
  padding-bottom: 4px;

  @include respond-to(desktop) {
    margin: 20px 0 20px 0;
    border-radius: 12px;
    border: $newBorderStyle;
  }
}

.store-page__media-wrapper {
  width: 100%;
  padding: 0 $mobilePadding;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  @include respond-to(desktop) {
    padding: 0 $desktopPadding;
  }
}

::v-deep .profile-card-thumbnails__child {
  @include respond-to(mobile) {
    width: 64px;
    height: 64px;
  }
}

.store-page__reviews-buttons-wrapper {
  margin: $mobilePadding;
  display: flex;
  gap: $mobilePadding;
  flex-direction: column;
  align-items: center;

  @include respond-to(desktop) {
    margin: 24px $desktopPadding $desktopPadding $desktopPadding;
    flex-direction: row;
    gap: 20px;
  }
}

.store-page__view-reviews-button {
  color: $brandColor;
  text-decoration: underline;
  border: none;
  background: none;
  cursor: pointer
}

.store-page__review-form {
  height: auto !important;
  padding: 0 $mobilePadding;
  margin-top: -20px;
}

.store-page__promise-widget {
  margin: 40px 16px 16px 16px;
}

.store-page__recommendation-widget {
  @include respond-to(notdesktop) {
    margin-top: 20px;
  }
}
</style>

<style lang="scss">
.store-page__shop-card {
  .enter-active {
    overflow: hidden;
    transition: height .3s linear;
  }

  .leave-active {
    overflow: hidden;
    transition: opacity .1s linear;
  }
}
</style>
