<template>
  <div class="trust-accordion">
    <ul>
      <li>
        <div class="paragraph">
          <input type="checkbox">
          <h2>
            <span class="trust-accordion__item-title-icon svg-sprite trust-accordion__item-title-icon--stars"></span>
            Transparency
          </h2>
          <p>Transparency has to do with how many reviews a store has chosen to display. Stores that choose not to hide any reviews have a high Transparency score, as do products with no hidden reviews.</p>
        </div>
      </li>
      <li>
        <div class="paragraph">
          <input type="checkbox" checked>
          <h2>
            <span class="trust-accordion__item-title-icon svg-sprite trust-accordion__item-title-icon--check"></span>
            Authenticity
          </h2>
          <p>Authenticity refers to the number of verified reviews a store or product has. ‘Verified’ means reviews that have been submitted by customers with fulfilled orders. It also includes imported reviews with proof of authenticity. A store can still publish unverified reviews, but it hurts their Authenticity score.</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['heading', 'content', 'imageUrl', 'urlText', 'blockUrl', 'blockUrlClass']
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';

  .trust-accordion__item-title-icon {
    display: inline-block;
    margin-left: 4px;
    height: 20px;
    width: 24px;
    vertical-align: middle;
    cursor: pointer;
    zoom: 1.5;
  }
  .trust-accordion__item-title-icon--stars {
    background-position: -700px -200px;
  }
  .trust-accordion__item-title-icon--check {
    background-position: -800px -200px;
  }

  .trust-accordion h2 {
    font-size: 16px;
    line-height: 60px;
    height: 60px;
    font-weight: 600;
    letter-spacing: 1px;
    display: block;
    background-color: #053C3C;
    color: white;
    margin: 0;
    cursor: pointer;
    padding-left: 12px;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }

  .trust-accordion p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    opacity: 1;
    z-index: 2;
    max-width: calc(100% - 48px);
    margin: 0 auto;
  }

  .trust-accordion ul {
    list-style: none;
    perspective: 900;
    padding: 0;
    margin: 0;
  }

  .trust-accordion ul li input[type=checkbox] {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 50px;
    z-index: 1;
    opacity: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }

  .trust-accordion ul li input[type=checkbox]:checked ~ p {
    margin-top: 0;
    max-height: 0;
    opacity: 0;
    padding: 0;
    transition: all 0.3s ease;
  }

  .trust-accordion p {
    padding: 24px 0px;
    max-height: 500px;
    transition: all 0.3s ease;
  }
  .trust-accordion .paragraph {
    background-color: #F0F5F5;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-top: 20px;
  }
  .trust-accordion ul li input[type=checkbox]:checked ~ h2 {
    background: white;
    color: #053C3C;
  }

  .trust-accordion ul li input[type=checkbox] ~ h2:after {
    content: "-";
    font-size: 48px;
    float: right;
    margin-top: -2px;
    margin-right: 12px;
  }

  .trust-accordion ul li input[type=checkbox]:checked ~ h2:after {
    content: "+";
    font-size: 36px;
    float: right;
    margin-top: -2px;
    margin-right: 12px;
  }

</style>
