import PageHome from 'rs/components/pages/reviews'
import PageCategoryIndex from 'rs/components/pages/categories/index_page'
import PageCategoryShow from 'rs/components/pages/categories/show_page'
import PageFeed from 'rs/components/pages/feed/main'
import PageSearch from 'rs/components/pages/search/main'
import PageShopSearch from 'rs/components/pages/store_search/main'
import PagePeople from 'rs/components/pages/people/main'
import PageFollowing from 'rs/components/pages/following/main'
import PageProduct from 'rs/components/pages/product/main_redesign'
import PageShop from 'rs/components/pages/shop/main_redesign'
import PageTrust from 'rs/components/pages/trust/main'
import PageHowItWorks from 'rs/components/pages/how_it_works/main'
import PageMedals from 'rs/components/pages/medals/main'
import PageWriteReviewMain from 'rs/components/pages/write_review/main'
import PageWriteReviewIndex from 'rs/components/pages/write_review/index'
import PageWriteReviewNew from 'rs/components/pages/write_review/new'
import PagePublicProfile from 'rs/components/pages/public_profile'
import PagePrivateProfile from 'rs/components/pages/private_profile'
import PageEditProfile from 'rs/components/pages/private_profile/edit'
import PageNewDispute from 'rs/components/pages/new_dispute/main'
import PageReviewerArbitrationShow from 'rs/components/pages/reviewer_arbitration/show_page'
import PageArbitratorShow from 'rs/components/pages/arbitrator/show_page'
import PageArbitratorDispute from 'rs/components/pages/arbitrator/dispute_page'

export default {
  root: {path: '/', redirect: '/reviews'},
  feed: {
    path: '/feed',
    component: PageFeed,
    name: 'PageFeed',
  },
  home: {
    path: '/reviews',
    component: PageHome,
    name: 'PageHome',
  },
  search: {
    path: '/search',
    component: PageSearch,
    name: 'PageSearch',
  },
  shopSearch: {
    path: '/store_search',
    component: PageShopSearch,
    name: 'PageShopSearch',
  },
  people: {
    path: '/people',
    name: 'PagePeople',
    component: PagePeople,
  },
  following: {
    path: '/following',
    name: 'PageFollowing',
    component: PageFollowing,
  },
  product: {
    path: '/reviews/:shop_slug/products/:product_handle',
    name: 'PageProduct',
    component: PageProduct,
  },
  productNewDispute: {
    path: '/reviews/:shop_slug/products/:product_handle/disputes/new',
    name: 'PageProductNewDispute',
    component: PageNewDispute,
  },
  categoryIndex: {
    path: '/categories',
    name: 'PageCategoryIndex',
    component: PageCategoryIndex,
  },
  shop: {
    path: '/reviews/:shop_slug',
    name: 'PageShop',
    component: PageShop,
  },
  shopNewDispute: {
    path: '/reviews/:shop_slug/disputes/new',
    name: 'PageShopNewDispute',
    component: PageNewDispute,
  },
  categoryShow: {
    path: '/categories/:category_slug',
    name: 'PageCategoryShow',
    component: PageCategoryShow,
  },
  trust: {
    path: '/trust',
    name: 'PageTrust',
    component: PageTrust,
  },
  howItWorks: {
    path: '/how-it-works',
    name: 'PageHowItWorks',
    component: PageHowItWorks,
  },
  medals: {
    path: '/medals',
    name: 'PageMedals',
    component: PageMedals,
  },
  writeReview: {
    path: '/write-review',
    component: PageWriteReviewIndex,
    children: [
      {
        path: '',
        name: 'PageWriteReviewMain',
        component: PageWriteReviewMain,
      },
      {
        path: 'new',
        name: 'PageWriteReviewNew',
        component: PageWriteReviewNew,
      }
    ]
  },
  editProfile: {
    path: '/profile/edit',
    name: 'PageEditProfile',
    component: PageEditProfile,
  },
  reviewerArbitrationShow: {
    path: '/profile/disputes/:disputeId',
    name: 'PageReviewerArbitrationShow',
    component: PageReviewerArbitrationShow,
  },
  publicProfile: {
    path: '/profile/:slug',
    name: 'PagePublicProfile',
    component: PagePublicProfile,
  },
  arbitratorShow: {
    path: '/arbitrator',
    name: 'PageArbitratorShow',
    component: PageArbitratorShow,
  },
  arbitratorDispute: {
    path: '/arbitrator/:disputeId',
    name: 'PageArbitratorDispute',
    component: PageArbitratorDispute,
  },
  privateProfile: {
    path: '/:slug',
    name: 'PagePrivateProfile',
    component: PagePrivateProfile,
  },
}
