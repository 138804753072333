<template>
  <section class="ask-to-sign-in-card">
    <MatchMedia v-slot="{desktop}">
      <img loading="lazy" class="card-image"
           :src="desktop ? 'https://pub-images.judge.me/judgeme/write-review/signin-desktop.png' : 'https://pub-images.judge.me/judgeme/write-review/signin-mobile.png' "/>
    </MatchMedia>
    <div class="card-body">
        <p class="card-text">Sign in to review products you bought</p>
        <a href="/login" class="btn pf-primary-button card-button">Sign in</a>
    </div>
  </section>
</template>

<script>
  import { MatchMedia } from "shared/vue_media_query";

  export default {
    components: {MatchMedia},
  }
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.ask-to-sign-in-card {
  margin-top: 20px;
  display: flex;
  width: 100%;
  background-color: white;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;

  @include respond-to(desktop) {
    border-radius: 12px;
    width: 992px;
    flex-direction: row;
    height: 142px;
  }
}
.card-image {
  flex: 1 1 50%;
}
.card-body {
  flex: 1 1 50%;
  padding: 24px 28px 32px 28px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include respond-to(mobile) {
    gap: 16px;
  }
}
.card-text {
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
  color: $primaryFontColor;
}
.card-button {
  width: 180px;
  @include respond-to(notmobile) {
    width: 240px;
  }
}
</style>

