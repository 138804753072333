<template>
  <div class="medal">
    <img class="medal__img" :src="imageSrcBuilder(item)" :alt="`medal icon for ${item.title}`" :title="item.description" loading="lazy" />
    <div v-if="item.value"
         :class="['medal__value', item.tier, item.value_is_long && 'medal__value--long']">
      {{ item.value }}
    </div>
  </div>
</template>

<script>
  export default {
    props: ['item'],
    methods: {
      imageSrcBuilder(item){
        return `https://judgeme-public-images.imgix.net/judgeme/medals-v2/${item.type}/${item.tier.replace(' ','-')}.svg?auto=format`
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .bronze { color: #A36710 };
  .silver { color: #666666 };
  .gold { color: #D2920F };
  .platinum { color: #488282 };
  .diamond { color: #4C8EDA };

  .medal {
    position: relative;
    width: 62px;
    height: 62px
  }
  .medal__value {
    vertical-align: middle;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    font-size: 13px;
    font-weight: bold;
    cursor: default;
  }

  .medal__img {
    object-fit: cover;
    width: 62px;
    height: 62px
  }
</style>
