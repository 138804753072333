<template>
  <section class="pagination">
    <span class="pagination__page-number pagination__page-number--icon material-icons"
          :class="{'pagination__page-number--disabled': currentPage <= 1}"
          v-bind="linkProps().attributes" v-on="linkProps().listeners">chevron_left</span>
    <span v-if="leftDots" class="pagination__page-number pagination__page-number--disabled"> ... </span>
    <span v-for="n in totalPage" v-if="leftBound(n) && rightBound(n)"
          class="pagination__page-number" :class="{'pagination__page-number--active': currentPage === n}"
          v-bind="linkProps(n).attributes" v-on="linkProps(n).listeners">
      {{ n }}</span>
    <span v-if="rightDots" class="pagination__page-number pagination__page-number--disabled"> ... </span>
    <span class="pagination__page-number pagination__page-number--icon material-icons"
          :class="{'pagination__page-number--disabled': currentPage >= totalPage}"
          v-bind="linkProps(currentPage + 1).attributes" v-on="linkProps(currentPage + 1).listeners">chevron_right</span>
  </section>
</template>
<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPage: {
      type: Number,
      required: true,
    },
    displayRange: {
      type: Number,
      default: 3,
    },
    minItem: {
      type: Number,
      default: 8
    },
    isLink: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    tag() { return this.isLink ? 'router-link' : 'span'},
    leftDots() {
      return this.currentPage - this.displayRange > 1
    },
    rightDots() {
      return (this.currentPage + this.displayRange < this.totalPage) && (this.totalPage > this.minItem)
    },
  },
  methods: {
    linkProps(n) {
      let page = n || this.currentPage - 1

      const obj = {
        attributes: {
          is: this.tag,
        }
      }

      if (this.isLink) {
        obj.attributes.to = {
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page: page
          }
        }
      } else {
        obj.listeners = {
          click: this.handleClick.bind(null, page)
        }
      }

      return obj
    },
    handleClick(n) {
      this.$emit('onChange', n)
    },
    leftBound(n) {
      return n >= (this.currentPage - this.displayRange)
    },
    rightBound(n) {
      return n <= this.minItem || n <= (this.currentPage + this.displayRange)
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.pagination__page-number {
  width: 40px;
  height: 40px;
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  background-color: $backgroundColor;
  border-radius: 50%;
  text-decoration: none;
  &:hover {
    background-color: #DBF0F0;
  }
}

.pagination__page-number--disabled {
  color: #96C9C9;
  pointer-events: none;
}

.pagination__page-number--active {
  border: 2px solid $brandColor;
  pointer-events: none;
}

.pagination__page-number--icon {
  font-size: 22px;
}


</style>
