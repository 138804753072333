<template>
  <div class="judgeme-medals__wrapper">
    <HeadingSection :title="title" class="judgeme-medals__heading"/>
    <SplitBlock :heading="medalsInfo.heading" :content="medalsInfo.content" :blockUrl="medalsInfo.blockUrl" :urlText="medalsInfo.urlText" :blockUrlClass="medalsInfo.blockUrlClass" :imageUrl="medalsInfo.imageUrl" :urlVuePath="true" class="judgeme-medals__section__split-box"/>
    <h2 class="judgeme-medals__heading-text">Medals help you know who to trust</h2>
    <MedalsBlocks />
  </div>
</template>
<script>
import HeadingSection from 'rs/components/common/heading_section'
import SplitBlock from "rs/components/pages/trust/_trust_block"
import MedalsBlocks from 'rs/components/pages/medals/_medals_blocks_section'

export default {
  components: {HeadingSection, SplitBlock, MedalsBlocks},
  data() {
    return {
      medalsInfo:
        {
          heading: 'We encourage stores to be <span class="brandColor">Open</span>.',
          content: 'We use medals to recognise stores and products with high trust scores and to encourage all stores to be open. By ranking products and stores by transparency and authenticity, you know which ones to trust. <br /><br />Diamond transparency is our highest medal. It’s awarded to stores that display all their verified reviews.',
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/medals/01',
          urlText: 'Learn more about trust',
          blockUrl: '/trust',
          blockUrlClass: '',
        },
    }
  },
  computed: {
    title: () => "Judge.me Medals",
  },
  created(){
    this.$store.dispatch('Meta/getMeta')
  },
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';


.judgeme-medals__section {
  margin-bottom: 40px;
  @include respond-to(notmobile) {margin-bottom: 80px;}
}
.judgeme-medals__heading {
  @include respond-to(notmobile) {
    height: 220px;
  }
  height: 148px;
}

.judgeme-medals__section__split-box {
  width: 100%;
  max-width: 100vw;
  width: 100vw;
  translate: -50%;
  margin-left: 50%;
  background: linear-gradient(165.09deg, #FFFFFF 19.76%, #E0F2F2 72.26%);

  @include respond-to(desktop) {
    flex-direction: row-reverse;
    gap: 6%;
    padding: 20px;
  }
  @include respond-to(notdesktop) {
    padding: 0px 30px 60px 30px;
    gap: 0;
  }

  .trust-block__content-left {
    h2 {
      display: none !important;
    }
  }
}
.judgeme-medals__section img {
  margin: 80px;
}

h2.judgeme-medals__heading-text {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: $brandColor;
  text-align: center;
  margin: 80px auto;
  @include respond-to(mobile) {
    margin-top: 60px;
    margin-bottom: 0px;
    font-size: 24px;
    line-height: 36px;
  }
}

</style>
