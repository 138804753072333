<template>
  <div class="trust_blocks__wrapper">
    <HeadingSection :title="title" :description="description" />
    <TrustBlocks />
  </div>
</template>
<script>

import TrustBlocks from 'rs/components/pages/trust/_trust_blocks_section'
import HeadingSection from 'rs/components/common/heading_section'

export default {
  components: {HeadingSection, TrustBlocks},
  computed: {
    title: () => "Built for trust",
    description: () => "Find out how Judge.me is using reviews to put trust back into online shopping",
  },
  created(){
    this.$store.dispatch('Meta/getMeta')
  },
}
</script>
