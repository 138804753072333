<template>
  <section class='feed-card'>
    <div class='feed-card__header-btns'>
      <HeartBtn :class="this.headerRightBtnClassNames + ' feed-card__heart-btn'" :followeeClass='feedEvent.event_owner.class' :followeeEncodedId='feedEvent.event_owner.encoded_id'/>
      <div class='clearfix'></div>
    </div>
    <component :is="displayFeedCard" :feedEvent="feedEvent"></component>
  </section>
</template>

<script>
  import ShopMedalFeedCard from 'rs/components/common/feed_cards/shop_medal'
  import ShopUpdateEvent from 'rs/components/common/feed_cards/shop_update_event'
  import ReviewFeedCard from 'rs/components/common/feed_cards/review_event'
  import ReviewerFeedCard from 'rs/components/common/feed_cards/reviewer_event'
  import UnImplementFeedCard from 'rs/components/common/feed_cards/unimplement_event'
  import HeartBtn from 'rs/components/common/heart_btn'

  const SHOP_MEDAL = ['shop_update_medal', 'shop_get_new_medal']
  const SHOP_UPDATE = ['shop_finish_profile']
  const REVIEW = [
    'shop_publish_shop_review',
    'prod_have_published_review',
    'reviewer_write_product_review',
    'reviewer_write_shop_review',
  ]
  const REVIEWER = [
    'reviewer_adds_merch',
    'reviewer_update_interest',
    'reviewer_update_youtube_channel',
	  'reviewer_adds_instagram',
  ]
  export default {
    components: { ShopMedalFeedCard, ShopUpdateEvent, ReviewFeedCard, HeartBtn },

    data() {
      return {
        headerRightBtnClassNames: 'material-icons right feed-card__header-btn',
      }
    },

    props: ['feedEvent'],

    computed: {
      displayFeedCard(){
        let type = this.feedEvent.event_type
        switch(true){
          case SHOP_MEDAL.includes(type):
            return ShopMedalFeedCard
          case SHOP_UPDATE.includes(type):
            return ShopUpdateEvent
          case REVIEW.includes(type):
            return ReviewFeedCard
          case REVIEWER.includes(type):
            return ReviewerFeedCard
          default:
            return UnImplementFeedCard
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .feed-card {
    background: white;
    border-radius: 10px;
    padding: 16px;
    font-size: 16px;
  }

  .feed-card__header-btn {
    display: inline-block;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    color: $brandColor;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      opacity: $hoverOpacity;
    }
  }

  .feed-card__question-btn {
    border-radius: 50%;
    border: 1px solid $brandColor;
    font-size: 20px;
  }

  .feed-card__dismiss-btn,
  .feed-card__share-btn,
  .feed-card__heart-btn {
    margin-left: 16px;

    &:before {
      display: inline-block;
      font-size: 24px;
    }
  }

  .feed-card__dismiss-btn {
    background-color: $lightBrandColor;
    &:before { content: '\e5cd'; }
  }

  .feed-card__share-btn {
    &:before { content: '\e80d'; }
  }


  .feed-card__unimplemented {
    color: red;
  }
</style>
