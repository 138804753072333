
<template>
  <select :value="currencyCode" @change="onChange">
    <option v-for="code in currencies" :value="code" :selected="currencyCode === code">
      {{ code }}
    </option>
  </select>
</template>

<script>
export default {
  props: ['currencyCode'],
  computed: {
    currencies() {
      return this.$store.getters['SharedCurrentReviewer/allCurrencies']
    }
  },
  methods: {
    onChange(e) {
      this.$emit('change', e.target.value)
    }
  }
}
</script>
<style lang="scss" scoped>
select {
  padding-left: 8px;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center;
  -moz-appearance:none;
  -webkit-appearance: none;
  appearance: none;
  padding-right: 2rem;
}
</style>
