<template>
  <div class='social-profile-links'>
    <ExternalLink v-if="instagram_url" :href="instagram_url" target="_blank" :class="socialIconClassName('instagram')" />
    <ExternalLink v-if="tiktok_url" :href="tiktok_url" target="_blank" :class="socialIconClassName('tiktok')" />
    <ExternalLink v-if="twitter_url" :href="twitter_url" target="_blank" :class="socialIconClassName('twitter')" />
    <ExternalLink v-if="youtube_url" :href="youtube_url" target="_blank" :class="socialIconClassName('youtube')" />
    <ExternalLink v-if="direct_url && isDesktop" :href="direct_url" target="_blank" :class="socialIconClassName('personal')" />
  </div>
</template>

<script>
  import ExternalLink from 'rs/components/common/external_link'

  export default {
    props: ['twitter_url', 'instagram_url', 'tiktok_url', 'youtube_url', 'direct_url'],
    components: { ExternalLink },
    computed: {
      isDesktop: () =>  window.innerWidth > 768,
    },
    methods: {
      socialIconClassName: name =>  'asset-sprite profile-social-media__icon profile-social-media__icon--' + name
    }
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .social-profile-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 4px;
    justify-content: center;
    align-items: center;
    margin: 16px -8px 0 0;

    a {
      margin: 0 4px;
    }
  }

  .profile-social-media {
    margin: 10px 0;
  }

  .profile-social-media__icon {
    height: 20px;
    width: 30px;
    display: inline-block;
  }

  .profile-social-media__icon--twitter {
    background-position: -500px -200px;
  }

  .profile-social-media__icon--instagram {
    background-position: -300px -200px;
  }

  .profile-social-media__icon--youtube {
    background-position: -600px -200px;
  }

  .profile-social-media__icon--tiktok {
    background-position: -400px -200px;
  }

  .profile-social-media__icon--personal {
    background-position: -700px -200px;

    @include respond-to(mobile) {
      display: none;
    }
  }

</style>
