<template>
  <section class="side-container">
    <WidgetLink />
    <WidgetContact />
    <WidgetDispute />
    <WidgetSocial />
    <WidgetMedal />
    <PromiseWidget/>
  </section>
</template>
<script>
import PromiseWidget from 'rs/components/common/promise_widget';
import WidgetMedal from 'rs/components/pages/shop/widgets/_widget_medal';
import WidgetLink from 'rs/components/pages/shop/widgets/_widget_link';
import WidgetContact from 'rs/components/pages/shop/widgets/_widget_contact';
import WidgetSocial from 'rs/components/pages/shop/widgets/_widget_social';
import WidgetDispute from 'rs/components/pages/shop/widgets/_widget_dispute';

export default {
  components: {PromiseWidget, WidgetMedal, WidgetContact, WidgetLink, WidgetSocial, WidgetDispute},
}
</script>
<style lang="scss" scoped>
.side-container {
  padding: 20px 0 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
