<template>
  <section class='question'>
    <div class='question__header'>
      <UserAvatar :avatar_image_url="question.avatar_image_url" :reviewer_initial='question.asker_initial' :gravatar_hash='question.gravatar_hash' />

      <div class='question__asker-info'>
        {{ question.asker_name }}
      </div>

      <div class='question__timestamp'>
        {{ createdAt(question.created_at) }}
      </div>
    </div>

    <div v-html='question.content_html' class='question__html-content-body'>
    </div>
    <div class="question__answers">
      <div v-for="answer in question.answers.slice(0,1)" class="question__answers-card">
        <div class="question__answers-date">{{ createdAt(answer.created_at) }}</div>
        <div><span class="question__answers-answerer">{{ answer.answerer_name}}&nbsp;</span>replied:</div>
        <div v-html='answer.content_html' class="question__html-content-body"></div>
      </div>
      <Transition name="fade">
        <div v-if="showAllAnswersStatusForAllQuestions[question.uuid]">
          <div v-for="answer in question.answers.slice(1, question.answers.length)" class="question__answers-card">
            <div class="question__answers-date">{{ createdAt(answer.created_at) }}</div>
            <div><span class="question__answers-answerer">{{ answer.answerer_name}}&nbsp;</span>replied:</div>
            <div v-html='answer.content_html' class="question__html-content-body"></div>
          </div>
        </div>
      </Transition>
      <div v-if="question.answers.length > 1 && !showAllAnswersStatusForAllQuestions[question.uuid]" class="question__show-all-replies" @click="toggleReadAllReplies(question.uuid)">Read All Replies</div>
    </div>

  </section>
</template>

<script>
  import UserAvatar from 'rs/components/common/user_avatar'
  import {customFormat} from 'shared/utils/timeago';

  export default {
    components: { UserAvatar },
    props: ['question'],
    data() {
      return {
        showAllAnswersStatusForAllQuestions: {}
      }
    },
    methods: {
      createdAt(dt) {
        return customFormat(dt)()
      },
      toggleReadAllReplies(uuid) {
        this.showAllAnswersStatusForAllQuestions = {...this.showAllAnswersStatusForAllQuestions, [uuid]: true}
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .question {
    padding-bottom: 12px;
  }

  .question__asker-info {
    display: inline-block;
    vertical-align: top;
    margin-left: 8px;
    line-height: 36px;
    text-transform: capitalize;
  }

  .question__timestamp {
    float: right;
    vertical-align: middle;
    line-height: 36px;
    color: $secondaryFontColor;
    font-size: 12px;
  }

  .question__html-content-body {
    margin-top: 12px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }

  .question__answers-card {
    margin-top: 12px;
    background-color: #F1FAFA;
    border-radius: 5px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .question__answers-date {
    color: $secondaryFontColor;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .question__answers-answerer {
    font-weight: 700;
  }

  .question__show-all-replies {
    margin-top: 16px;
    font-size: 16px;
    color: $brandColor;
    text-decoration: underline;
    cursor: pointer;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 1s ease;
  }
  .fade-enter, .fade-leave-to  {
    opacity: 0;
  }

</style>
