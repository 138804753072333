<template>
  <div class="search-page__sort">
    <span class="search-page__sort-label">Sort by</span>
    <select class="sort-dropdown" :value="searchParams.sort_by" @input="handleSort">
      <option  v-for="option in sortData" :value="option.value">
        {{ option.label }}
      </option>
    </select>
    <span class="sort-dropdown-arrow material-icons">expand_more</span>
  </div>
</template>

<script>
  import {mapState} from 'vuex'

  const OPTIONS = [
    {value: null, label: "Default" },
    {value: 'reviews_count', label: "Most reviews first" },
    {value: 'newest', label: "Newest" },
    {value: 'best_selling', label: "Best Selling" },
    {value: 'price_asc', label: "Low price first"},
    {value: 'price_desc', label: "High price first" },
  ]
  export default {
    computed: {
      ...mapState('Search', ['searchParams']),
      sortData: () => OPTIONS,
    },
    methods: {
      handleSort(e){
        this.$store.dispatch('Search/navigateToSearchPageWithQuery', {sort_by: e.target.value});
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .search-page__sort {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
  }
  .search-page__sort-label {
    height: 36px;
    line-height: 36px;
    white-space: nowrap;
  }

  .sort-dropdown {
    color: #333;
    width: auto;
    font-size: 14px;
    z-index: 1;
    padding: 0 30px 0 8px;
    margin-left: 8px;
    height: 36px;
    border: 1px solid #CDCCCC;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    @include  respond-to(mobile) {
      width: 104px;
    }
  }

  .sort-dropdown-arrow {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: -1;
  }
</style>
