<template>
  <div class="category-show-page-wrapper">
    <LoadingSpinner v-if="!bDoneLoading"/>
    <component v-else v-bind="componentProps"/>
  </div>
</template>
<script>
import LoadingSpinner from 'rs/components/common/loading_spinner';
import CategoryView from 'rs/components/pages/categories/show_page/_category_view'
import ProductView from 'rs/components/pages/categories/show_page/_product_view'
import {mapGetters} from 'vuex'
import {isTrue} from 'shared/utils/checker.js'

export default {
  components: {LoadingSpinner},
  computed: {
    ...mapGetters('Category', ['bDoneLoading']),
    componentProps() {
      return {is: isTrue(this.$route.query.view_all) ? CategoryView : ProductView}
    }
  },
  created() {
    this.$store.dispatch('Category/fetchCategory', {handle: this.$route.params.category_slug})
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
.loading-spinner {
  margin: auto;
  @include page-spinner;
}
.category-show-page-wrapper {
  margin-top: -12px;
}
</style>
