<template>
  <section class="profile-metrics-widget">
    <div class="pmw__reviews-count">
      {{ totalReviewsCount }}
      <img :src="require('images/platform/green_star.svg')" alt="review star icon" class="pmw__star"/>
    </div>
    <div class="pmw__reviews-text">
      <p>Reviews written. &nbsp;</p>
      <div v-if="totalPublishedReviewsCount > 0" class="pmw__public-reviews-count" @click="onReviewClick">Read {{
          $sharedUtils.pluralize(totalPublishedReviewsCount, 'public review')
        }}
      </div>
    </div>
    <div class="pmw__thumbnails-header" slot="title">Photos & videos uploaded</div>
    <Thumbnails class="pmw__thumbnails" :media="media" :visibleCount="5" :openGallery="openGallery"/>
    <div class="pmw__item-wrapper" v-if="followersCount > 0">
      <div class="svg-sprite pf-sprite-icon pf-sprite-icon--small user-pin-icon"></div>
      <div class="rs-weight-600">{{ $sharedUtils.pluralize(followersCount, 'Follower') }}</div>
    </div>
  </section>
</template>
<script>
import Thumbnails from 'rs/components/common/profile_card_thumbnails'

export default {
  components: {Thumbnails},
  computed: {
    storeName() {
      return this.$store.getters['Profile/storeName'];
    },
    profileData() {
      return this.$store.getters[this.storeName + '/profileData'];
    },
    media() {
      return this.$store.getters[this.storeName + '/media'];
    },
    totalPublishedReviewsCount() {
      return this.$store.getters[this.storeName + '/totalPublishedReviewsCount']
    },
    totalReviewsCount() {
      return this.$store.getters[this.storeName + '/totalReviewsCount']
    },
    followersCount() {
      return (!this.profileData || !this.profileData.followers_count) ? 0 : this.profileData.followers_count
    }
  },
  methods: {
    openGallery(id, review_uuid) {
      this.$store.dispatch('Gallery/openGallery', {
        forwardedMediaId: id,
        review_uuid: review_uuid,
        encodedId: this.profileData.encoded_id,
        sourceType: this.storeName,
      })
    },
    onReviewClick() {
      this.$store.dispatch('Profile/shouldScrollToReviews', true)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.profile-metrics-widget {
  background-color: white;
  border: $newBorderStyle;
  border-radius: 12px;
  min-width: 300px;
  font-size: 14px;
  font-weight: 400;
  color: $primaryFontColor;
  line-height: 24px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.pmw__star {
  height: 50px;
}

.pmw__reviews-count {
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 8px;
}

.pmw__reviews-text {
  display: flex;
  @include respond-to(notmobile) {
    flex-direction: column;
  }
}

.pmw__public-reviews-count {
  color: $brandColor;
  cursor: pointer;
  text-decoration: underline;
}

.pmw__thumbnails-header {
  margin: 8px 0;
  color: $secondaryFontColor;
}

.pmw__item-wrapper {
  margin-top: 8px;
  display: flex;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  background-color: #F0F5F5;
  border: $newBorderStyle;
}

</style>

<style lang="scss">
.pmw__thumbnails {
  max-width: 260px; // cheat to force 3 grid per row
  div > .profile-card-thumbnails__child,
  .profile-card__transparent-layer > .profile-card-thumbnails__child {
    height: 64px;
    width: 64px;
  }
}
</style>
