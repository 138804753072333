<template>
  <MatchMedia v-slot="{desktop}">
    <div class="public-profile-page">
      <ProfilePageNav :public='true' :storeName="storeName"/>
      <div class="ppp__container">
        <Gallery v-if="isGalleryOpen"/>
        <div class="ppp__container-main">
          <div class="ppp__bio-card">
            <ProfileInfo :profileData="profileData" />
          </div>
          <MetricsWidget v-if="!desktop" class="ppp__widget"/>
          <FollowWidget  v-if="!desktop" class="ppp__widget"/>
          <LoadingSpinner v-if="!doneLoadReviews" />
          <NoReviewsSection v-else-if="!hasReviewOrRecommendation" :text="noReviewText" />
          <ReviewsSection v-else />
          <PromiseWidget v-if="!desktop" class="ppp__widget" />
        </div>
        <Sidebar :storeName="storeName" v-if="desktop" />
      </div>
    </div>
  </MatchMedia>
</template>
<script>
import Gallery from 'rs/components/common/gallery';
import Sidebar from 'rs/components/uncommon/profile/_sidebar';
import ProfileInfo from 'rs/components/pages/public_profile/_profile_info';
import ProfilePageNav from 'rs/components/uncommon/profile/_profile_page_nav';
import PromiseWidget from 'rs/components/common/promise_widget';
import LoadingSpinner from 'rs/components/common/loading_spinner';
import FollowWidget from 'rs/components/uncommon/profile/_follow_widget';
import MetricsWidget from 'rs/components/uncommon/profile/_metrics_widget';
import ReviewsSection from 'rs/components/pages/public_profile/_reviews_section';
import NoReviewsSection from 'rs/components/pages/public_profile/_no_reviews_section';
import {MatchMedia} from "shared/vue_media_query/MatchMedia";
import {setNewMeta} from 'shared/utils/router_utils';
import {mapGetters} from "vuex";

const storeName = 'PublicProfile';

export default {
  components: {
    MatchMedia, LoadingSpinner, Sidebar, ProfileInfo, ReviewsSection, NoReviewsSection,
    PromiseWidget, FollowWidget, Gallery, MetricsWidget, ProfilePageNav,
  },
  computed: {
    storeName: () => storeName,
    ...mapGetters(storeName, ['profileData','reviewsData', 'doneLoadReviews']),
    isGalleryOpen() {
      return this.$store.getters['Gallery/isOpen'];
    },
    profileData() {
      return this.$store.getters[this.storeName + '/profileData'];
    },
    doneLoadReviews() {
      return this.$store.getters[this.storeName + '/doneLoadReviews'];
    },
    hasReviewOrRecommendation() {
      return !!this.$store.getters[this.storeName + '/hasReviewOrRecommendation'];
    },
    noReviewText() {
      return this.profileData && `${this.profileData.name} doesn't have public reviews yet`
    }
  },
  created(){
    setNewMeta({page_title: 'Judge.me recommendations: Discover new products recommended by people you trust'})
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

$mobilePadding: 16px;

.loading-spinner {
  margin: 144px auto;
  width: 72px;
  height: 72px;
}


.public-profile-page {
  padding: 0;
  margin: -14px -12px 0 -12px;
}
.ppp__container {
  display: flex;
}
.ppp__container-main {
  width: 100%;
  @include respond-to(desktop) {
    margin-bottom: 40px;
  }
}

.ppp__bio-card {
  width: 100%;
  background-color: white;
  padding-bottom: 4px;

  @include respond-to(desktop) {
    margin: 20px 0 20px 0;
    border-radius: 12px;
    border: $newBorderStyle;
  }
}
.ppp__widget {
  margin: 20px $mobilePadding;
}
</style>
