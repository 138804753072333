<template>
  <section>
    <OrdersSection v-if="isLoggedIn"/>
    <OrdersSectionGuest v-else/>
  </section>
</template>
<script>
import OrdersSection from 'rs/components/pages/write_review/_orders_section'
import OrdersSectionGuest from 'rs/components/pages/write_review/_orders_section_guest'
export default {
  components: {
    OrdersSection,
    OrdersSectionGuest
  },
  computed: {
    isLoggedIn: () => window.isReviewerLoggedIn,
  },
}
</script>
