<template functional>
  <svg v-on="data.listeners" :class="data.class" v-bind="data.attrs" xmlns="http://www.w3.org/2000/svg" width="20"
       height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M4.79729 12.627L7.31662 15.1464C7.15227 15.3166 6.95566 15.4524 6.73829 15.5457C6.52091 15.6391 6.28712 15.6883 6.05054 15.6903C5.81397 15.6924 5.57935 15.6473 5.36039 15.5577C5.14142 15.4681 4.94249 15.3358 4.7752 15.1685C4.60791 15.0012 4.47561 14.8023 4.38603 14.5833C4.29644 14.3643 4.25136 14.1297 4.25342 13.8931C4.25547 13.6565 4.30462 13.4227 4.398 13.2053C4.49138 12.9879 4.62711 12.7913 4.79729 12.627ZM12.6965 17.371L12.0676 18L2 7.93189L2.62894 7.30291L3.61067 7.44278C4.28237 7.539 5.11532 7.33409 5.46097 6.98842L8.92194 3.52814C9.92637 2.54255 11.2793 1.99338 12.6864 2.00006C14.0936 2.00674 15.4412 2.56873 16.4363 3.56381C17.4313 4.5589 17.9933 5.9066 17.9999 7.31385C18.0066 8.7211 17.4575 10.0741 16.4719 11.0786L13.011 14.5397C12.6653 14.8854 12.4604 15.7184 12.5566 16.3901L12.6965 17.371Z"
      :fill="props.color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#088484',
    }
  }
}
</script>
