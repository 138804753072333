<template>
  <section class='no-search-result'>
    <img v-bind:src="require('images/no-result.svg')">
    <p class='no-search-result__header'>No results found</p>
  </section>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    computed: {
      ...mapState('Search', {
        metrics: 'homeMetrics',
      }),
      ...mapState('ShopSearch', {
        metrics: 'homeMetrics',
      })
    }
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .no-search-result {
    text-align: center;

    > img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .no-search-result__header {
    margin: 20px 0;
    line-height: 1;
    font-size: 2em;
    font-weight: 600;
    color: $primaryFontColor;
  }

  .no-search-result__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: $primaryFontColor;
  }
</style>
