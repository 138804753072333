<template>
  <modal-wrapper @close="closeModal">
    <div class="mark-resolved-modal">
      <iconify icon="iconamoon:close" width="28" class="mrm__close-icon" @click="closeModal"
               data-closable="true"/>
      <div class="mrm__inner-container">
        <div class="mrm__text--header">Mark issue as resolved</div>
        <div class="mrm__text--description">Are you sure you want to mark this issue as resolved? This action will close
          the issue for all parties.
        </div>
        <button class="btn pf-secondary-button" @click="accept">Yes, mark as resolved</button>
        <button class="btn pf-primary-button" data-closable="true" @click="closeModal">No, cancel</button>
      </div>
    </div>
  </modal-wrapper>
</template>
<script>
export default {
  methods: {
    closeModal(e) {
      e.target.dataset.closable === 'true' && this.$emit('close')
    },
    accept(e) {
      this.$emit('close', true)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';
.modal-wrapper {
  height: auto; // override default styles
}
.mark-resolved-modal {
  padding: 20px 16px 36px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mrm__close-icon {
  color: $brandColor;
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
  @include respond-to(mobile) {
    right: 16px;
  }
}
.mrm__inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-weight: 400;
  width: 420px;
  color: $primaryFontColor;
  @include respond-to(mobile) {
    width: 100%;
  }
  * {
    text-align: center;
  }
  button {
    width: 312px;
  }
}
.mrm__text--header {
  font-weight: 700;
  line-height: 24px;
  font-size: 20px;
  @include respond-to(mobile) {
    font-size: 16px;
  }
}
.mrm__text--description {
  margin: 12px 0;
  font-size: 14px;
  line-height: 20px;
}
</style>
