<template>
  <div class="trust-blocks__section">
    <div class="trust-blocks__section__content-wrapper">
      <div class="trust-blocks__section__content" v-for="(card, idx) in trustBlocks" v-bind:key="idx" :class="{ 'trust-blocks-division': idx == 1 || idx == 4 }">
        <h1 class="trust-heading" v-if="idx == 1">{{heading}}</h1>
        <TrustBlock :heading="card.heading" :content="card.content" :blockUrl="card.blockUrl" :urlText="card.urlText" :blockUrlClass="card.blockUrlClass" :imageUrl="card.imageUrl" :blockIndex="idx" :urlVuePath="card.urlVuePath"/>
      </div>
    </div>
  </div>
</template>
<script>

import TrustBlock from "rs/components/pages/trust/_trust_block"

export default {
  components: {TrustBlock},
  data() {
    return {
      heading: 'How we build trust into our apps',
      trustBlocks: [
        {
          heading: 'We put trust at the heart of everything we do.',
          content: 'We know there are a lot of fake reviews out there. That’s why we’ve created a space where you can trust what you see and shop with confidence.',
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/build_your_trust/img_01',
          urlText: 'Find out about Judge.me Medals',
          blockUrl: '/medals',
          urlVuePath: true,
          blockUrlClass: '',
        },
        {
          heading: 'Trust Scores',
          content: 'We use Trust Scores to let you know which stores and products you can rely on. You can see them in the trust box next to every store and product.',
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/build_your_trust/img_02',
        },
        {
          heading: 'How our reviews work',
          content: 'When you buy from a store, that store will send you an email asking if you’d like to leave a review. You can then write a review within the email. You can also go to the store’s page and leave your review there if you prefer. <br/><br/> Visitors can leave reviews without having bought something, but these won’t be verified. That helps other shoppers know which reviews to trust.',
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/build_your_trust/img_03',
          urlText: 'Find Out More',
          blockUrl: '/how-it-works',
          urlVuePath: true,
          blockUrlClass: 'btn',
        },
        {
          heading: 'We keep our Reviews platform safe',
          content: "We're an independent, third-party verification source. That means we can check if all the stores on the platform are playing by the rules.",
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/build_your_trust/img_04',
          urlText: 'Find products on the Judge.me Reviews platform',
          blockUrl: '/reviews',
          urlVuePath: true,
          blockUrlClass: '',
        },
        {
          heading: 'Your reviews make a big impact',
          content: "Store owners can display reviews outside of their store in different ways so that shoppers can learn more about their products. That means your feedback goes further. You might see your review on Google Shopping and the store’s Facebook Business page.",
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/build_your_trust/img_05',
          urlText: 'Find Stores',
          blockUrl: '/store_search',
          urlVuePath: true,
          blockUrlClass: 'btn',
        },
        {
          heading: 'We show you the most trusted products first',
          content: "We want to make it easy for our shoppers to find trustworthy products. That's why our search results put the products with high Transparency and Authenticity scores first. As a bonus, we only show honest reviews and we don't do sponsored posts.",
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/build_your_trust/img_06',
          urlText: 'Browse Categories',
          blockUrl: '/categories',
          urlVuePath: true,
          blockUrlClass: 'btn btn-transparent',
        },
        {
          heading: 'Complaints',
          content: `It’s really important to us that we maintain the authenticity and transparency of our apps and the Judge.me Reviews. That’s why we give our customers access to features that let them comply with the Consumer Review Fairness Act (CRFA), enforced by the Federal Trade Commission (FTC), and the Directive (EU) 2019/2161 of the European Parliament. This protects your ability to share your opinions about the products and services provided by stores using our app, and to be told where published reviews come from.`,
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/build_your_trust/img_07',
          urlText: 'Go to Authenticity',
          blockUrl: '/authenticity',
          blockUrlClass: '',
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.trust-blocks__section {
  left: calc(-50vw + 50%);
  width: 100vw;
  padding-bottom: 60px;
  min-height: 1400px;
  position: relative;
}

.trust-blocks__section__heading {
  color: $newBrandColor;
  font-weight: 700;
  text-align: center;
  font-size: 32px;
  padding-top: 120px;
}

.trust-blocks__section__content--heading, .trust-blocks__section__sub-heading  {
  color: #333333;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 21px;
  @include respond-to(mobile) {
    font-size: 20px;
    line-height: 30px;
  }
}
.trust-blocks__section__content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.trust-blocks__section__content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  margin: 0 auto;
  @include respond-to(notdesktop) {
    flex-direction: column-reverse;
    gap: 28px;
    max-width: 100%;
  }
}

.trust-blocks__section__content.trust-blocks-division {
  padding-top: 500px;
  margin-top: -450px;
  background: linear-gradient(180deg, #FFFFFF 19.76%, #E0F2F2 72.26%);
}
.trust-blocks__section-card__image {
  @include respond-to(notdesktop) {
    max-width: 300px;
    max-width: 100%;
  }
}


.trust-blocks__section__content:nth-child(2) {
  .trust-block {
    gap: 120px;
  }
}

.trust-blocks__section__content:first-child, .trust-blocks__section__content:nth-child(4),  .trust-blocks__section__content:nth-child(6) {
  .trust-block {
    @include respond-to(desktop) {
      flex-direction: row-reverse;
    }
  }
}

.trust-blocks__section__content:nth-child(6) {
  background: linear-gradient(165.09deg,#372359 19.76%, #358C90 72.26%);
  color: #fff !important;

  a {
    color: #fff !important;
  }
}

.trust-blocks__section__content-left, .trust-blocks__section__content-right {
  max-width: 500px;
  position: relative;
}
.trust-blocks__section__cards-wrapper {
  margin-top: 12px;
  overflow: hidden;
  position: relative;
  width: 100%;
  -webkit-tap-highlight-color: transparent;

  @include respond-to(notmobile) {
    margin-top: 28px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    row-gap: 16px;
  }
}

  .trust-blocks-division {
    flex-direction: column;
    padding: 60px 0px 100px 0px;
    @include respond-to(mobile) {
      padding: 30px 0;
    }
  }

  h1.trust-heading {
    color: $brandColor;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    @include respond-to(mobile) {
      font-size: 24px;
      line-height: 36px;
    }
  }

</style>
