const PRODUCT_SNIPPET_CLASS_NAME = 'rs-json-ld-product-snippet'
const STORE_SNIPPET_CLASS_NAME = 'rs-json-ld-local-business-snippet'
const BREADCRUMB_CLASS_NAME = 'rs-json-ld-breadcrumb-snippet'

export function removeSnippet(...names) {
  names.forEach(name => {
    const el = document.querySelector(`body > script.${name}`)
    if (el) el.remove()
  })
}

export function removeSnippets(){
  removeSnippet(PRODUCT_SNIPPET_CLASS_NAME, STORE_SNIPPET_CLASS_NAME, BREADCRUMB_CLASS_NAME);
}

export function addProductSnippet(productData) {
  const {
    title,
    image_url,
    description,
    external_url,
    average_rating,
    number_of_reviews,
    currency,
    raw_lowest_price,
    raw_highest_price,
  } = productData

  if (number_of_reviews <= 0 && raw_lowest_price <= 0) return;

  const el = document.createElement('script')
  el.className = PRODUCT_SNIPPET_CLASS_NAME
  el.type = 'application/ld+json'
  el.text = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Product',
    'url': external_url,
    'name': title,
    'image': image_url,
    'description': description,
    'offers': offersSnippet(raw_lowest_price, raw_highest_price, external_url, currency),
    'aggregateRating': {
      '@type': 'AggregateRating',
      'ratingValue': average_rating,
      'reviewCount': number_of_reviews,
    }
  })

  document.querySelector('body').appendChild(el)
}

export function addStoreSnippet(storeData) {
  const {
    name,
    logo,
    description,
    external_url,
    average_rating,
    number_of_reviews,
  } = storeData

  if (!storeData || number_of_reviews <= 0) return;

  const el = document.createElement('script')
  el.className = STORE_SNIPPET_CLASS_NAME
  el.type = 'application/ld+json'
  el.text = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    'url': external_url,
    'name': name,
    'image': logo,
    'description': description,
    'aggregateRating': {
      '@type': 'AggregateRating',
      'ratingValue': average_rating,
      'reviewCount': number_of_reviews,
    }
  })
  document.querySelector('body').appendChild(el)
}

export function addBreadCrumbSnippet(list) {
  const el = document.createElement('script')
  el.className = BREADCRUMB_CLASS_NAME
  el.type = 'application/ld+json'
  el.text = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': getBreadCrumbItems(list),
  })
  document.querySelector('body').appendChild(el)
}

function getBreadCrumbItems(list) {
  return list.map((item, index) => {
    return {
      '@type': 'ListItem',
      'position': index + 1,
      'name': item.name,
      'item': window.location.origin + item.path
    }
  })
}

function getOffer(price, currency, url) {
  return {
    '@type': 'Offer',
    'price': price,
    'priceCurrency': currency,
    'availability': "http://schema.org/InStock",
    'url': url
  }
}

function offersSnippet(raw_lowest_price, raw_highest_price, external_url, currency) {
  return raw_lowest_price == raw_highest_price ?
    getOffer(raw_lowest_price, currency, external_url) :
    [getOffer(raw_lowest_price, currency, external_url), getOffer(raw_highest_price, currency, external_url)]
}
