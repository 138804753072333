<template>
  <section class="locale-setting">
    <div class="locale-setting__header" @click="bShow = !bShow">
      <div class="locale-setting__header-left">
        <span class="locale-setting__header-icon material-icons">public</span>
        Country / Currency
      </div>
      <span class='locale-setting__header-icon material-icons'>{{ bShow ? 'expand_less' : 'expand_more' }}</span>
    </div>
    <div class="locale-setting__body">
      <CollapsibleItem>
        <div v-show="bShow">
          <div class="locale-setting__item-wrapper">
            <div>Store country</div>
            <div class="filter-sidebar__item-filter">
              <CountryFilter class="locale-setting__dropdown"
                             @change="e => updateFilter({store_country: e}) "
                             :defaultCountry="searchParams.store_country"/>
            </div>
          </div>

          <div class="locale-setting__item-wrapper">
            <div>Ship to</div>
            <div class="filter-sidebar__item-filter">
              <CountryFilter class="locale-setting__dropdown" showFlag="true"
                             @change="e => updateFilter({country: e}) "
                             :defaultCountry="searchParams.country"/>
            </div>
          </div>

          <div class="locale-setting__item-wrapper">
            <div>Show prices in</div>
            <div class="filter-sidebar__item-filter">
              <CurrencyDropdown showFlag
                                @afterChange="$emit('afterChange')"
                                :autoRefetch="autoRefetch"
              />
            </div>
          </div>

        </div>
      </CollapsibleItem>
    </div>
  </section>
</template>
<script>
import CollapsibleItem from "rs/components/common/collapsible_item";
import CountryFilter from 'rs/components/pages/search/_filter_dropdown_country';
import CurrencyDropdown from 'rs/components/common/currency_dropdown';
import {mapState} from 'vuex';

export default {
  components: {CollapsibleItem, CountryFilter, CurrencyDropdown},
  props: {
    autoRefetch: { // auto refetch (refetch immediately) after change.
      type: Boolean,
      default: true,
    }
  },
  data: () => ({ bShow: true, }),
  computed: {
    ...mapState('Search', {
      searchParams: state => state.searchParams,
    }),
    ...mapState('SharedCurrentReviewer', ['currency']),
  },
  methods: {
    updateFilter(params) {
      this.$store.dispatch('Search/updateFilter', {params, bRefetch: this.autoRefetch})
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.locale-setting {
  display: flex;
  flex-direction: column;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-size: 14px;
  color: $primaryFontColor;
  @include respond-to(mobile) {
    border: none;
  }
}

.locale-setting__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F7F7F7;
  height: 36px;
  font-weight: 700;
  padding: 0 8px 0 12px;
  border-radius: inherit;
  cursor: pointer;
}

.locale-setting__header-icon {
  color: $brandColor;
}

.locale-setting__header-left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.locale-setting__body {
  padding: 0 12px;
}

.locale-setting__item-wrapper {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:last-child {
    margin-bottom: 20px;
  }
}

.locale-setting__dropdown {
  height: 36px;
  font-size: 14px;
  width: 100%;
}

</style>
<style lang="scss">
.locale-setting {
  .enter-active,
  .leave-active {
    overflow: hidden;
    transition: height .3s linear;
  }
}
</style>
