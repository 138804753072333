<template>
  <div class='feed-cards-list'>
    <PromotionFeedCards v-if="!isLoggedIn" />
    <div class='feed-cards-list__wrapper' v-for="(product, index) in products" :key="index">
      <ReviewFormComponent v-if="isLoggedIn" :product="product"/>
    </div>
    <div class='feed-cards-list__content'>
      <div v-for="(feedEvent, index) in feedEvents" class='feed-cards-list__wrapper' v-bind:key="index">
        <FeedCard v-bind:feedEvent="feedEvent"/>
      </div>
      <PromotionFeedCards v-if="(isLoggedIn && !isLoadingFeedEvents && feedEvents.length <= 10)" />
    </div>

    <LoadingSpinner v-if='isLoadingFeedEvents'/>
    <div v-else-if='hasMoreFeedEvents' class='feed-cards-list__load-more btn btn--text-bg' @click='fetchMoreFeedEvents'>
      Load More
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import LoadingSpinner from 'rs/components/common/loading_spinner'
  import FeedCard from 'rs/components/common/feed_cards/_base'
  import PromotionFeedCards from 'rs/components/common/feed_cards_logged_out/promotion_feed_cards'
  import ReviewFormComponent from 'rs/components/common/feed_cards/special_feed_cards/review_form_component'

  export default {
    components: { LoadingSpinner, FeedCard, PromotionFeedCards, ReviewFormComponent },

    computed: mapState('Reviews', {
      feedEvents: 'feedEvents',
      isLoadingFeedEvents: 'isLoadingFeedEvents',
      hasMoreFeedEvents: 'hasMoreFeedEvents',
      isLoggedIn() {
        return window.isReviewerLoggedIn;
      },
      products: 'currentReviewerUnreviewedProducts',
    }),

    methods: {
      fetchMoreFeedEvents: function() {
        const lastFeedEvent = this.feedEvents[this.feedEvents.length - 1];
        this._fetchFeedEvents(lastFeedEvent.event_created_at);
      },
      clearAndFetchFeedEvents: function() {
        this.$store.commit('Reviews/clearFeedEvents')
        this._fetchFeedEvents()
      },
      _fetchFeedEvents: function(createdAt = null) {
        this.$store.dispatch('Reviews/fetchFeedEvents', { maxCreatedAt: createdAt })
      },
    },

    mounted () {
      this.clearAndFetchFeedEvents();
    },

    watch: {
      '$route.query.user_events': function() { this.clearAndFetchFeedEvents(); },
      '$route.query.store_events': function() { this.clearAndFetchFeedEvents(); },
      '$route.query.product_events': function() { this.clearAndFetchFeedEvents(); },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .loading-spinner {
    margin: 24px auto;
    width: 72px;
    height: 72px;
  }

  .feed-card {
    margin-top: 20px;
  }

  .feed-cards-list__load-more {
    width: 100%;
    margin: 20px 0;
  }
</style>
