<template>
  <section class="reviews-search">
    <form class="reviews-search__form" @submit="handleSubmit" @change="handleOnChange">
      <input type="text" ref='searchReview' name='searchReview' class="reviews-search__input" id="q" placeholder="Search reviews"/>
      <label :class="['reviews-search__label', bIsSearch && 'reviews-search__label--active', 'material-icons']" data-text="search" for="q" @click="handleLabelClick">
        {{ bIsSearch ? 'cancel' : 'search' }}
      </label>
    </form>
  </section>
</template>
<script>
export default {
  data(){
    return {
      bIsSearch: false,
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.$emit('submit', e.target.searchReview.value);
      this.bIsSearch = e.target.searchReview.value.length > 0;
    },
    handleOnChange(e) {
      this.$emit('change', e.target.value)
    },
    handleLabelClick(e){
      if (this.bIsSearch){                      // remove search value
        const emptySearch = '';
        this.$emit('change', emptySearch);
        this.$emit('submit', emptySearch);
        this.$refs.searchReview.value = emptySearch;
        this.bIsSearch = false;
      } else {                                  // focus on input
        this.$refs.searchReview.focus();
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';

.reviews-search {
  display: flex;
  justify-content: center;
}

.reviews-search__form {
  position: relative;
  width: 100%;
}

.reviews-search__input {
  height: 40px;
  width: 100%;
  padding-right: 40px;
  border-radius: 20px;
  border: 1px solid #E4E4E4;

  &:focus {
    outline: none;
  }
}

.reviews-search__label {
  position: absolute;
  z-index: 0;
  right: 0;
  margin: 8px 12px 12px 0;
  height: 24px;
  width: 24px;
  cursor: pointer;
  color: $marketplaceSecondaryIconColor;
}
.reviews-search__label--active {
  color: $brandColor;
  &:hover {
    color: $oldBrandColor;
  }
}
</style>
