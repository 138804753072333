<template>
  <div class="chat-box-wrapper">
    <textarea v-model="message" ref="chatbox"
              :maxlength="charLimit"
              class="chat-input"
              cols="30" rows="1" placeholder="Type your reply here"/>
    <div class="chat-input__count">{{ charCount }}/{{ charLimit }} characters</div>
    <div class="chat-box__buttons-wrapper" ref="popoverWrapper">
      <button class="btn pf-secondary-button chat-input__submit-button" @click="submitChat"
              :disabled="submitButtonDisabled">
        <LoadingDots v-if="isSubmitting" color="white"/>
        <template v-else>Submit</template>
      </button>
      <button class="btn pf-text-button" @click="$emit('cancel')">Cancel</button>
      <template v-if="localDisputeActions.length > 0">
        <button class="btn pf-primary-button action-button" v-if="localDisputeActions.length === 1"
                @click="() => localDisputeActions[0].action(dispute)">
          <LoadingDots v-if="isUploading"/>
          <template v-else>{{ localDisputeActions[0].name }}</template>
        </button>
        <button class="btn pf-primary-button action-button" v-else-if="localDisputeActions.length > 1"
                @click="actionButtonOnClick">
          <LoadingDots v-if="isUploading"/>
          <template v-else>Actions</template>
        </button>
        <input :id="'file_upload_'+dispute.id" type="file" multiple class="hidden" :accept="allowedMimeTypes"
               @change="onFileChange"/>
        <Popover v-if="popOverOpen" @close="closePopover" top
                 :position="positionProps"
                 v-closable="{
                      exclude: ['popoverWrapper'],
                      handler: 'closePopover'
                   }">
          <div class="popover__list-selection">
            <p class="popover__list-item" :key="idx" v-for="(action,idx) in localDisputeActions"
               @click="() => action.action(dispute)">
              {{ action.name }}
            </p>
          </div>
        </Popover>
      </template>
    </div>
  </div>
</template>
<script>
import Popover from 'rs/components/common/popover'
import {allowedMimeTypes} from 'shared/vue_components/arbitration/helpers'
import LoadingDots from 'rs/components/common/loading_dots.vue'

function autoResize(e, reset = false) {
  this.style.height = 'auto';
  if (!reset) {
    this.style.height = (this.scrollHeight) + "px";
  }
}

export default {
  components: {Popover, LoadingDots},
  data: () => ({
    isSubmitting: false,
    isUploading: false,
    message: '',
    charLimit: 1000,
    popOverOpen: false,
    positionProps: {},
  }),
  inject: ['disputeActions', 'dispute', 'actions'],
  computed: {
    allowedMimeTypes: () => allowedMimeTypes.join(','),
    charCount() {
      return this.message.length;
    },
    submitButtonDisabled() {
      return this.message.trim().length === 0;
    },
    localDisputeActions() { // check this module wrapper (_dispute.vue)
      return this.disputeActions.filter(action => !!action[this.dispute.user_role])
    },
    actionButtonText() {
      return this.localDisputeActions.length === 1 ? this.localDisputeActions[0].name : 'Actions'
    }
  },
  methods: {
    submitChat() {
      this.isSubmitting = true;
      this.actions.createChat({disputeId: this.dispute.id, message: this.message})
        .then(newMessage => {
          if (newMessage) this.dispute.messages.push(newMessage)
        })
        .finally(() => {
          this.isSubmitting = false;
          this.message = '';
          autoResize.bind(this.$refs.chatbox)(null, true)
        })
    },
    actionButtonOnClick(e) {
      const rect = e.target.getBoundingClientRect();
      this.positionProps = {
        x: rect.left - rect.width - 20,
        y: rect.top - 8,
      }
      this.popOverOpen = true;
    },
    closePopover() {
      this.popOverOpen = false
    },
    async onFileChange(e) {
      this.isUploading = true;
      let files = e.target.files || e.dataTransfer.files;
      if (files.length === 0) return;
      files = Array.from(files);
      const presignedData = await this.actions.getPresignedData(this.dispute.id);
      if (!presignedData) return;

      const requests = files.map(file => this.actions.uploadFile(presignedData, file));
      Promise.all(requests)
        .then(uploadedFiles => {
          return this.actions.postMessageFiles({
            disputeId: this.dispute.id,
            files: uploadedFiles
          })
        })
        .then(message => {
          if (message) this.dispute.messages.push(message);
        })
        .finally(() => this.isUploading = false)
    }
  },
  mounted() {
    this.$refs.chatbox.addEventListener('input', autoResize, false)
  },

}

</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.chat-input {
  width: 100%;
  border-radius: 40px;
  border: 1px solid $oldBrandColor;
  background: white;
  padding: 12px 20px;
  resize: none;
  overflow-y: hidden;

  &:hover, &:focus, {
    border: 1px solid $oldBrandColor;
    outline: 1px solid $oldBrandColor;
  }
}

.chat-input__count {
  color: $secondaryFontColor;
  font-size: 14px;
  font-weight: 400;
}

.chat-box__buttons-wrapper {
  margin-top: 20px;
  display: flex;
}

.chat-input__submit-button {
  width: 172px;
}

.action-button {
  min-width: 120px;
  position: relative;
  margin-left: auto;
}

.popover__list-selection {
  width: 200px;
}

.popover__list-item {
  cursor: pointer;

  &:hover {
    color: $oldBrandColor;
  }
}
</style>
