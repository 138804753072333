export const defaultMediaScreen = { mobile: false, desktop: false, tablet: false}

function setMedia({commit, state}, payload){
  if (state.screen[payload]) return;
  commit('setMedia', { ...defaultMediaScreen, [payload]: true} )
  window.media = { ...defaultMediaScreen, [payload]: true }
}

export default {
  setMedia
}
