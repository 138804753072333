<template>
	<section class="profile-info">
    <div class="pi__header-section">
       <div class="pi__header-background">
         <HeartBtn v-if="!isCurrentReviewer" class='pi__heart-icon' :followeeClass="'Reviewer'" :followeeEncodedId="profileData.encoded_id" />
         <SmallRoundButton v-else-if="shouldShowEditPageButton" icon="settings" class="pi__edit-icon" @click.stop="goEditPage"/>
         <img class="pi__banner" v-if="profileData.banner_image_url && !hasBrokenBanner" :src="profileData.banner_image_url" @error="hasBrokenBanner = true" alt="Profile background" />
       </div>
       <div class="pi__avatar">
         <div class="pi__avatar--link">
           <LazyImage :className="'pi__avatar-image'"
                      v-if="avatars && !hasBrokenLogo"
                      alt="Reviewer avatar"
                      @error="hasBrokenLogo = true"
                      :urls='{ normal: avatars.normal, retina: avatars.retina }' />
           <div v-else class="pi__avatar-image pi__avatar-image--default">{{profileData.name[0]}}</div>
         </div>
       </div>
    </div>

    <div class="pi__details-section">
      <div class='pi__name'>{{profileData.name}}</div>
      <div class='pi__subtext-section'>
        <div v-text="city" />
        <div v-text="country" />
        <country-flag class="pi__flag" :countryCode="profileData.rs_country"/>
        <div v-text="'|'" class="pi__subtext-section-divider" />
        <div>
          With Judge.me since {{ createdAtToYear }}
        </div>
      </div>
      <div v-if="profileData.categories.length > 0" class="pi__interests">
        <Interest v-for="interest in profileData.categories" :text="interest" :key="interest"/>
      </div>
      <div v-if="socialNetworks.length > 0" class="pi__social-network">
        <ExternalLink
          v-for="(network, index) in socialNetworks" :key="index"
          v-if="network.link" :href="network.link">
          <i :class="['asset-sprite', 'profile-social-media__icon', network.icon]"></i>
        </ExternalLink>
      </div>
      <div v-if="description && description.length > 0" class="pi__description">
        {{description}}
      </div>
    </div>
	</section>
</template>
<script>
import HeartBtn from 'rs/components/common/heart_btn'
import LazyImage from 'rs/components/common/lazy_image';
import Interest from 'rs/components/uncommon/profile/_interest'
import ExternalLink from 'rs/components/common/external_link';
import SmallRoundButton from 'rs/components/common/small_round_button';
import CountryFlag from 'rs/components/common/country_flag'
import countryMap from 'shared/constants/country_list_map';

export default {
  components: {ExternalLink, LazyImage, Interest, HeartBtn, SmallRoundButton, CountryFlag},
  props: ['profileData'],
  computed: {
    isCurrentReviewer() {
      return this.$store.getters['SharedCurrentReviewer/currentReviewer'] && this.$store.getters['SharedCurrentReviewer/currentReviewer'].encoded_id === this.profileData.encoded_id
    },
    avatars() {
      return this.profileData && this.profileData.avatar_image_url;
    },
    city() {
      if(this.profileData.rs_city) {
        return this.profileData.rs_city + ","
      } else {
        return ''
      }
    },
    country() {
      return countryMap[this.profileData.rs_country];
    },
    description() {
      return this.profileData && this.profileData.description;
    },
    createdAtToYear() {
      return new Date(this.profileData.created_at).getFullYear();
    },
    socialNetworks() {
      const {instagram_url, tiktok_url, twitter_url, youtube_url, personal_url} = this.profileData;
      if ([instagram_url, tiktok_url, twitter_url, youtube_url, personal_url].every(e => !e)) return []
     return [
       { icon: 'profile-social-media__icon--instagram', link: this.profileData.instagram_url },
       { icon: 'profile-social-media__icon--tiktok', link: this.profileData.tiktok_url },
       { icon: 'profile-social-media__icon--twitter', link: this.profileData.twitter_url },
       { icon: 'profile-social-media__icon--youtube', link: this.profileData.youtube_url },
       { icon: 'profile-social-media__icon--personal', link: this.profileData.personal_url },
     ]
    },
    shouldShowEditPageButton() {
      return this.isCurrentReviewer && this.$store.getters['Profile/storeName'] === 'PrivateProfile'
    }
  },
  data() {
    return {
      hasBrokenLogo: false,
      hasBrokenBanner: false,
    }
  },
  methods: {
    goEditPage() {
      this.$router.push(this.$routes.editProfile.path)
    },
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

$mobilePadding: 16px;
$desktopPadding: 28px;

.profile-info {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.pi__subtext-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #7B7B7B;
  font-size: 12px;
  margin-top: 0;
}

.pi__subtext-section > div:first-child {
  margin-right: 0.5%;
}

.pi__subtext-section-divider {
  color: #E7E7E7;
  margin-left: 1%;
  margin-right: 1%;
}

.pi__header-section {
  height: 100px;
  position: relative;
  @include respond-to(desktop) {
    height: 112px;
  }
}

.country-flag {
  height: 8px;
  margin-left: 1%;
  align-self: center;
}

.pi__header-background {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: url('https://pub-images.judge.me/judgeme/platform/categories/categories-page-default-background.png');
  background-size: cover;
  height: 81px;
  @include respond-to(desktop) {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  @include respond-to(notmobile) {
    height: 90px;
  }
}
.pi__banner {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.pi__heart-icon {
  position: absolute;
  top: 24px;
  right: 16px;

  @include respond-to(notmobile) {
    top: 30px;
    right: 24px;
  }
}
.pi__edit-icon {
  @extend .pi__heart-icon;
  height: 40px;
  width: 40px;
}
.pi__avatar {
  position: absolute;
  bottom: 0;
  left: $mobilePadding;
  height: 84px;
  width: 84px;
  @include respond-to(desktop) {
    left: $desktopPadding;
  }
}
.pi__avatar-image {
  height: 84px;
  width: 84px;
  border-radius: 50%;
  box-shadow: 0 0 1px 0 white inset, 0 0 1px 0 white;
  object-fit: cover;
}
.pi__avatar--link:hover {
  opacity: 1;
}

.pi__avatar-image--default {
  color: #8AC4D1;
  background-color: $lightGreenColor;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pi__details-section {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;
  padding: 4px $mobilePadding 24px $mobilePadding;
  @include respond-to(desktop) {
    padding: 4px $desktopPadding 24px $desktopPadding;
  }
}

.pi__name {
  margin-top: 8px;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-decoration: none;
  color: $primaryFontColor;
}
.pi__interests {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.pi__description {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $primaryFontColor;
  white-space: pre-line;
  margin-top: -22px; //offset preline
}

.profile-social-media__icon {
  height: 20px;
  width: 30px;
  display: inline-block;
}
.profile-social-media__icon--twitter {
  background-position: -500px -200px;
}

.profile-social-media__icon--instagram {
  background-position: -300px -200px;
}

.profile-social-media__icon--youtube {
  background-position: -600px -200px;
}

.profile-social-media__icon--tiktok {
  background-position: -400px -200px;
}

.profile-social-media__icon--personal {
  background-position: -700px -200px;
}

</style>
