<template>
  <WidgetWrapper v-if="medals && medals.length > 0">
    <template v-slot:header>
      {{ header }}
      <TooltipPopover v-bind="tooltipProps">
        <template #icon><CircleIcon icon='check' class="store-medals__check-icon"/></template>
      </TooltipPopover>
    </template>
    <template v-slot:body>
      <div class="store_medals__list">
        <div class="store-medals__list-item" :class="{active: showToolTipFor === idx}" v-for="(medal,idx) in medals"
             :key="idx"
             @click="showTooltip(idx)"
             @mouseenter="showTooltip(idx)"
             @mouseleave="closeTooltip">
          <Tooltip v-if="showToolTipFor === idx" class="store-medals__tooltip"
                   @close="closeTooltip"
                   :text="medal.description"
          />
          <Medal :item="medal"/>
        </div>
      </div>
      <router-link to="/medals" class="mt-3">Learn more</router-link>
    </template>
  </WidgetWrapper>
</template>
<script>
import WidgetWrapper from 'rs/components/common/wrappers/widget_wrapper'
import CircleIcon from 'rs/components/common/icons/custom_circle_icon'
import Medal from 'rs/components/common/_medal'
import Tooltip from 'rs/components/common/tooltip_v2';
import TooltipPopover from 'rs/components/common/tooltip_popover';
import { storeName } from "rs/store/store_utils/shared.js";

export default {
  components: {Medal, Tooltip, WidgetWrapper, CircleIcon, TooltipPopover},
  computed: {
    storeName() {
      return storeName(this.$route)
    },
    header() {
      return this.storeName === 'Product' ? 'Product review medals' : 'Store medals'
    },
    medals() {
      return this.$store.getters[this.storeName + '/medals']
    },
    tooltipProps() {
      return {
        show: true,
        name: 'storeMedal'
      }
    }
  },
  data() {
    return {
      showToolTipFor: null
    }
  },
  methods: {
    showTooltip(idx) {
      this.showToolTipFor = idx;
    },
    closeTooltip() {
      this.showToolTipFor = null;
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.store-medals__check-icon {
  margin-left: 8px;
  width: 16px;
  height: 16px;
}

.store_medals__list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: 8px;
}

.store-medals__list-item {
  flex: 0 1 49%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: $newBorderStyle;
  height: 140px;
  max-width: 140px;
  @include respond-to(desktop) {
    height: 120px;
    max-width: 120px;
  }

}
</style>
<style lang="scss">
@import '~PlatformStyles/abstract/mixins';

.store-medals__list-item {
  .medal {
    height: 100px;
    width: 100px;
    @include respond-to(desktop) {
      width: 80px;
      height: 80px;
    }

    .medal__img {
      height: 100px;
      width: 100px;
      @include respond-to(desktop) {
        width: 80px;
        height: 80px;
      }
    }

    .medal__value {
      font-size: 1.25em;
      @include respond-to(desktop) {
        font-size: 1em;
      }
    }
  }
}

.store-medals__tooltip {
  font-size: 14px;
  font-weight: 400;
  width: 162px;
  min-width: 162px;
  transform: translateY(-100%);
  top: 0;
  z-index: 1;
}

.active {
  position: relative;
}

</style>
