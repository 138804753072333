<template>
  <section class="filter-sort">
    <div class="filter-sort__head">
      <div class="filter-sort__filter-wrapper" ref="filterModal">
        <div class="filter-sort__filter-button" @click="toggleModal">Filter by</div>
        <FilterModal v-if="bShowFilter"
                     @close="closeModal"
                     :setPosition="setPosition"
                     @applyFilter="applyFilter"
                     @clearFilter="clearFilter"
                     :filterQueries="filterQueries"
                     :reviewTypes="reviewTypes"
                     :reviewSources="reviewSources"
                     :verifiedOnly="verifiedOnly"
                     v-closable="{
                   exclude: ['filterModal'],
                   handler: 'closeModal'
                   }"
        />
      </div>
      <slot></slot>
      <div class="filter-sort__sort-wrapper">
        <div class="filter-sort__sort-label">Sort by</div>
        <Dropdown>
          <select class="filter-sort__sort-dropdown" v-model="sort">
            <option v-for="option in sortData" :value="option">
              {{ option.label }}
            </option>
          </select>
        </Dropdown>
      </div>
    </div>
    <div class="filter-sort__body" v-if="changes.length > 0">
        <RemovablePill v-for="(item,idx) in changes" :key="idx" :item="item" @remove="handleRemoveFilter"/>
    </div>
  </section>
</template>
<script>
import FilterModal from 'rs/components/uncommon/reviews/reviews_filter_modal';
import RemovablePill from 'rs/components/common/removable_pill';
import Dropdown from 'rs/components/common/dropdown';
import {mapState} from 'vuex';
function setPosition(wrapperHeight){
  return function(el){
    if (wrapperHeight && el.clientHeight > wrapperHeight) {
      el.style.position = 'fixed'
      el.style.top = 'auto'
      el.style.left = 'auto'
      el.style.bottom = '20px'
    }
  }
}
const OPTIONS = [
  {sort_by: 'pictures_first', label: "Pictures First", sort_dir: ''},
  {sort_by: 'videos_first', label: 'Video First', sort_dir: ''},
  {sort_by: 'created_at', label: 'Most Recent', sort_dir: 'desc'},
  {sort_by: 'rating', label: 'Highest Rating', sort_dir: 'desc'},
  {sort_by: 'rating', label: 'Lowest Rating', sort_dir: 'asc'},
  {sort_by: 'most_helpful', label: 'Most Helpful', sort_dir: ''},
]
export default {
  props: {
    storeName: { // storeName can be Product/Shop/PrivateProfile/PublicProfile
      type: String,
      default: 'Product'
    },
  },
  components: {FilterModal, RemovablePill, Dropdown},
  data() {
    return {
      sortData: OPTIONS,
      sort: OPTIONS[0],
      bShowFilter: false,
      filterWrapperHeight: 0,
    }
  },
  computed: mapState({
    filterQueries(state) {
      return state[this.storeName].queries
    },
    reviewTypes() {
      return this.$store.getters[this.storeName + '/reviewTypes'] || {}
    },
    reviewSources() {
      return this.$store.getters[this.storeName + '/reviewSources'] || {}
    },
    verifiedOnly() {
      return this.$store.getters[this.storeName + '/verifiedOnly']
    },
    changes() {
      return this.$store.getters[this.storeName + '/changes']
    },
    setPosition() {
      return setPosition(this.filterWrapperHeight)
    },
  }),
  methods: {
    closeModal() {
      this.bShowFilter = false;
    },
    toggleModal() {
      this.bShowFilter = !this.bShowFilter
      if (this.bShowFilter) {
        const parentRect = this.$parent.$el.getBoundingClientRect()
        this.filterWrapperHeight = parentRect.height - 218
        // 218px is the spaces above this section. The section below may not have enough height to fit the dropdown
      }
    },
    applyFilter(tempFilterQueries) {
      this.$store.dispatch(this.storeName + '/applyFilter', tempFilterQueries).then(this.closeModal)
    },
    clearFilter() {
      this.$store.dispatch(this.storeName + '/clearFilter').then(this.closeModal)
    },
    handleRemoveFilter(item){
      this.$store.dispatch(this.storeName + '/removeFilter', item)
    }
  },
  watch: {
    sort(sort) {
      this.$store.dispatch(this.storeName + '/applySort', sort)
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';

.filter-sort {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.filter-sort__head {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}
.filter-sort__body {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.filter-sort__filter-wrapper {
  position: relative;
}

.filter-sort__filter-button {
  width: 88px;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 16px;
  background-color: $lightGreenColor;
  border-radius: 4px;
  cursor: pointer;
}

.filter-sort__sort-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  position: relative;
  background: transparent;
  gap: 8px;
}

.filter-sort__sort-dropdown {
  padding: 8px 30px 8px 8px;
  font-size: 14px;
  border: 1px solid #CDCCCC;
  border-radius: 4px;
  color: $primaryFontColor;
  background: transparent;

  &:hover, &:focus, {
    border: 1px solid $newBrandColor;
    outline: 1px solid $newBrandColor;
  }
}

</style>
