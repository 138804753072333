import { difference, uniq, cloneDeep, indexOf, forIn } from 'lodash'
import axios from 'shared/utils/axios'

const _mergeFolloweeIds = (followeeIdsToCheck, respondedFolloweeIds, currentFolloweeIds) => {
  currentFolloweeIds = cloneDeep(currentFolloweeIds) // Because we need to mutate `followeeIds` before calling `setFolloweeIds`
  forIn(followeeIdsToCheck, (idsToCheck, className) => {
    currentFolloweeIds[className] = currentFolloweeIds[className] || {}
    const respondedIds = respondedFolloweeIds[className] || []
    idsToCheck.forEach((id) => {
      currentFolloweeIds[className][id] = (indexOf(respondedIds, id) >= 0)
    })
  })

  return currentFolloweeIds
}

// This function mutates `followeeIdsToCheck`
const filterOutIdsAlreadyChecked = (followeeIdsToCheck, currentFolloweeIds) => {
  Object.keys(followeeIdsToCheck).forEach((className) => {
    const idsAlreadyChecked = Object.keys(currentFolloweeIds[className] || {})
    followeeIdsToCheck[className] = difference(uniq(followeeIdsToCheck[className]), idsAlreadyChecked)
    if (followeeIdsToCheck[className].length <= 0) {
      delete followeeIdsToCheck[className]
    }
  })
}

const mergeFolloweeIds = (oldObj, newObj) => {
  for (const className in newObj) {
    if (oldObj.hasOwnProperty(className)) {
      oldObj[className] = {...oldObj[className], ...newObj[className] }
    } else {
      oldObj[className] = newObj[className]
    }
  }
  return oldObj
}

const state = {
  followeeIds: {},
}

const mutations = {
  setFolloweeIds: (state, newFolloweeIds) => state.followeeIds = newFolloweeIds,
  updateFollowStatus: (state, payload) => {
    state.followeeIds[payload.class][payload.id] = payload.newStatus
    state.followeeIds = { ...state.followeeIds} // followeeIds[payload.class][payload.id] is 2-level deep and Vuex does a shallow comparison to check if the state (followeeIds) is changed. Hence return new object (objectID) to let Vuex know to re-render the component.
  },
  updateFolloweeIds: (state, newFolloweeIds) => state.followeeIds = mergeFolloweeIds(state.followeeIds, newFolloweeIds)
}

const actions = {
  // @param followeeIdsToCheck is expected to be a Hash/Object with this structure: { ModelName_1: [encoded_id_1, encoded_id_2], ModelName_2: [encoded_id_3, encoded_id_4] }
  //   Example: { Shop: ['aaaa', 'bbbb'], Product: ['xxxx', 'yyyy'], Reviewer: ['zzzzz'] }
  fetchFolloweeIds (context, followeeIdsToCheck) {
    if (!window.isReviewerLoggedIn) { return }

    filterOutIdsAlreadyChecked(followeeIdsToCheck, context.state.followeeIds)
    if (Object.keys(followeeIdsToCheck).length <= 0) { return } // Nothing to check

    axios.get('/profile/follows', {
      params: followeeIdsToCheck,
    }).then(function (response) {
      const newFolloweeIds = _mergeFolloweeIds(followeeIdsToCheck, response.data.existing_followee_ids, context.state.followeeIds)
      context.commit('setFolloweeIds', newFolloweeIds)
    })
  },

  sendRequestToHeart (context, payload) {
    if (!window.isReviewerLoggedIn) { return }

    const oldValue = context.state.followeeIds[payload.followeeClass][payload.followeeEncodedId] // In case the request fails, we need to revert back the old value
    const requestBody = { is_hearting: payload.isHearting }
    const paramKeyName = { Shop: 'shop_enc_id', Product: 'product_enc_id', 'Reviewer': 'reviewer_enc_id' }[payload.followeeClass]
    requestBody[paramKeyName] = payload.followeeEncodedId

    context.commit('updateFollowStatus', { class: payload.followeeClass, id: payload.followeeEncodedId, newStatus: payload.isHearting })
    axios.post('/profile/follows/heart', requestBody).then(function (response) {
      if (response.status >= 400) { // Revert to the old status if the request goes wrong
        context.commit('updateFollowStatus', { class: payload.followeeClass, id: payload.followeeEncodedId, newStatus: oldValue })
      }
    })
  },
}


export default {
  state: state,
  mutations: mutations,
  actions: actions,

  namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
}
