<template>
  <div>
    <div class="message__name">{{ heading }}</div>
    <div class="message__files">
      <File v-for="(file, idx) in files" :key="idx" :file="file"/>
    </div>
  </div>
</template>
<script>
import {getFileIcons} from 'shared/vue_components/arbitration/helpers'
import File from 'shared/vue_components/arbitration/components/_dispute_file.vue'

export default {
  components: {File},
  props: ['message', 'senderPoV'],
  inject: ['userParticipantId', 'dispute'],
  computed: {
    heading() {
      switch (this.senderPoV) {
        case 'first':
          return 'You added new info'
        case 'second':
          return (this.dispute.user_role === 'seller' ? 'Buyer' : this.message.sender.name) + ' added new info'
        default:
          return this.message.sender.name + ' - Arbitrator add new info'
      }
    },
    files() {
      return getFileIcons(this.message.files)
    }
  }
}
</script>
<style lang="scss" scoped>
.message__files {
  display: flex;
  gap: 4px;
  margin-top: 4px;
}
</style>
