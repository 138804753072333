<template>
  <CustomSlider
      class="reviewer-add-merch__wrapper"
      :childComponent="MerchCard"
      :items="reviewerMerch"
      :visibleCount=1
      @afterChange="handleAfterSlide"
  >
    <template v-if="caption" v-slot:caption><div class="custom-slider__caption">{{caption}}</div></template>
  </CustomSlider>
</template>

<script>
  import CustomSlider from 'rs/components/common/custom_slider'
  import MerchCard from 'rs/components/common/merch_card'

  export default {
    components: { CustomSlider },
    props: ['feedEvent'],

    data() {
      return {
        MerchCard,
        reviewerMerch: this.feedEvent.event_owner.reviewer_merches.map(m => { m.profileLink = this.createProfileLink(); return m }),
        currentSlideData: null,
      }
    },
    computed: {
      caption(){
        return this.currentSlideData && (this.currentSlideData.currentIndex + 1) + "/" + this.reviewerMerch.length
      }
    },
    methods: {
      createProfileLink(){
        return this.feedEvent.event_owner.public_slug ? ('/' + this.feedEvent.event_owner.public_slug) :  `/profile/${this.feedEvent.event_owner.encoded_id}#profile-merch-section`
      },
      handleAfterSlide(e){
        this.currentSlideData = e
      },
    },
  }
</script>

<style lang="scss" scoped>
  .reviewer-add-merch__wrapper {
    width: 100%;
    height: auto;
  }
  .custom-slider__caption {
    bottom: 0;
    z-index: 1;
    width: 100%;
    margin-top: 8px;
    text-align: center;
  }
</style>

<style lang="scss">
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .reviewer-add-merch__wrapper.custom-slider {
    .custom-slider__caption {
      color: $brandColor;
      font-size: 12px;
    }

    .custom-slider__navigation-button-forward {
      top: -15%;
      @include respond-to(notmobile) {
        right: 10%;
      }
    }

    .custom-slider__navigation-button-backward {
      top: -15%;
      @include respond-to(notmobile) {
        left: 10%;
      }
    }

    .custom-slider__navigation-button-icon {
      @include respond-to(desktop) {
        font-size: 30px;
      }
    }
  }
</style>
