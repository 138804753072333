<template>
    <section>
        <LoadingSpinner v-if="!productData"/>
        <template v-else>
            <p class="rs-title">{{ title }}</p>
            <p class="rs-description">{{ description }}</p>
            <div class="review-form__wrapper">
                <ReviewForm
                        hideFormHeading
                        :lineItemId="lineItemId"
                        :resource="productData"
                        @closeForm="redirectToMain"
                        @doneSubmit="">
                    <template v-slot:success>
                        <p class="review-form__success-text">Thank you for submitting a review. You can remove or edit
                            your review on your profile
                            page.</p>
                        <a href="/profile?tab=orders" class="btn pf-secondary-button review-form__success-button">Go
                            to Profile
                        </a>
                    </template>
                </ReviewForm>
            </div>
        </template>
    </section>
</template>
<script>
import ReviewForm from 'rs/components/uncommon/reviews/review_form';
import LoadingSpinner from 'rs/components/common/loading_spinner'

export default {
  components: {LoadingSpinner, ReviewForm},
  computed: {
    title: () => "Write a review",
    description: () => "Leave your opinion about the product you bought.",
    lineItemId() {
      return this.$store.getters['WriteReview/lineItemId']
    },
    productData() {
      return this.$store.getters['WriteReview/productData']
    }
  },
  methods: {
    redirectToMain() {
      this.$router.push({name: "PageWriteReviewMain"})
    },
  },
  created() {
    if (!this.$route.query.line_item_public_id || !window.isReviewerLoggedIn) {
      this.redirectToMain()
      return
    }
    this.$store
      .dispatch('WriteReview/prepareReviewForm', this.$route.query.line_item_public_id)
      .catch(this.redirectToMain);
  },
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.loading-spinner {
  margin: 24px auto;
  width: 72px;
  height: 72px;
}

.review-form__wrapper {
  margin: 24px 0 60px 0;
  border: $borderStyle;
  background: white;
  border-radius: 12px;

  .review-form {
    padding: 40px 16px;
  }
}

.review-form__success-text {
  font-weight: 400;
  color: $primaryFontColor;
}

.review-form__success-button {
  margin-top: 20px;
  margin-bottom: 8px;
  width: 312px;
}

</style>
