<template>
  <div class="people-card-category">
    <div class='people-card-category__list'>
      <div :class="['people-card-category__child', parentWidth]" v-for="(category,idx) in categories" v-bind:key="idx" >
        {{category}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      categories: Array
    },
    data(){
      return {
        parentWidth: '_wide'
      }
    },
    mounted(){
      if(this.$el.parentElement.clientWidth > 400){
        this.parentWidth = '_wide';
      }
      else{
        this.parentWidth = '_narrow';
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  $grid: 4px;

  .people-card-category {
    padding: $grid*3 $grid*4;

    &__list {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
      text-align: center;
      color: $brandColor;
      font-size: $grid*3;
      line-height: $grid*4;
      font-weight: 600;
      border-bottom-left-radius: $grid*3;
      border-bottom-right-radius: $grid*3;
    }

    &__child {
      width: auto;
      height: $grid*6;
      line-height: $grid*6;
      text-transform: capitalize;
      padding: 0 $grid 0 $grid;
      border: 1px solid #C1E6E6;
      border-radius: $grid*6;
      margin-bottom: -$grid;
      margin-top: $grid;
      display: inline-block;

      @include text-elipsis();
      &:not(:last-child) {
        margin-right: $grid;
      }
    }

    ._wide {
      max-width: 786px;
    }
    ._narrow {
      max-width: 120px;
    }
  }

</style>