<template>
  <div class="filter-reviews">
    <div v-for="option in options" @click="$emit('change', option)"
         :class="['filter-review__badge', activeValue === option && 'filter-review__badge--active']"
    >{{ option }} or more</div>
  </div>
</template>
<script>
const OPTIONS = [1, 10, 20, 50]
export default {
  props: ['activeValue'],
  computed: {
    options: () => OPTIONS,
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.filter-reviews {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 12px;
}
.filter-review__badge {
  border: 1px solid #C1E6E6;
  border-radius: 25px;
  padding: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: $brandColor;
  cursor: pointer;
}
.filter-review__badge--active {
  color: white;
  border: transparent;
  background-color: $brandColor;
}
</style>
