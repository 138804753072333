<template>
  <section v-if="products" class="products-slider">
    <div class="products-slider__cards">
      <div class="products-slider__heading">
        {{ title }}
      </div>
      <swiper class="products-slider__cards-wrapper"
              ref="productsSwiper"
              :options="swiperOptions"
              :mousewheel="true">
        <swiper-slide v-for="(searchResult, index) in products" :key="index">
          <ProductSearchCard class='products-slider__product-card'
                             :searchResult="searchResult"
                             :disableTracking="true"
          />
        </swiper-slide>
      </swiper>
      <div class="products-slider__pagination-wrapper">
        <router-link :to="allProductsRoute" class="btn pf-secondary-button products-sliders__cta-button">View All Products</router-link>
        <div class="d-flex gap-3">
          <div class="products-slider__swiper-button-prev"><iconify height="24" icon="charm:chevron-left" color="white"/></div>
          <div class="products-slider__swiper-button-next"><iconify height="24" icon="charm:chevron-right" color="white"/></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProductSearchCard from 'rs/components/common/product_search_card_v2'

export default {
  components: {ProductSearchCard},
  props: ['products', 'title', 'allProductsRoute'],
  inject: ['mediaQueries'],
  computed: {
    swiperOptions() {
      return {
        freeMode: this.mediaQueries.mobile,
        autoUpdate: true,
        autoDestroy: true,
        spaceBetween: 12,
        breakpoints: {
          '767': { // big screen mobile, show 2 cards with navigate buttons
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          '890': {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 20,
          },
          '1170': {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 20,
          }
        },
        slidesPerView: 2.1,
        slidesPerGroup: 1,
        deleteInstanceOnDestroy: true,
        cleanupStylesOnDestroy: true,
        navigation: {
          nextEl: ".products-slider__swiper-button-next",
          prevEl: ".products-slider__swiper-button-prev"
        },
      }
    },
    swiper() {
      return this.$refs.productsSwiper.$swiper
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.products-slider {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  background: white;
  padding-top: 40px;
  padding-bottom: 60px;
  @include respond-to(notdesktop) {
    padding-bottom: 34px;
  }
}

.products-slider__heading {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.products-slider__cards {
  width: 100%;
  max-width: $maxWidth;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
}

.products-slider__cards-wrapper {
  padding: 40px 16px;
  overflow: hidden;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  @include respond-to(notdesktop) {
    padding: 32px 16px 24px 16px;
  }
}

.products-slider__product-card {
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  background: #FFFFFF;
  text-align: center;
  min-width: 162px;
  min-height: 400px;

  @include respond-to(desktop) {
    min-height: 444px;
    min-width: 232px;
  }
}

.products-slider__pagination-wrapper {
  padding: 0 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @include respond-to(desktop) {
    justify-content: center;
  }
}

.products-sliders__cta-button {
  width: 100%;
  max-width: 260px;
  height: 40px;
  font-size: 16px;
  font-weight: 700;
  @include respond-to(notdesktop) {
    width: 172px;
    padding-left: 0;
    padding-right: 0;
  }
}
.products-slider__swiper-button-next, .products-slider__swiper-button-prev {
  @include respond-to(desktop) {
    display: none;
  }
  background: #333333;
  border-radius: 50%;
  color: white;
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
