<template>
  <div class="removable-pill">
    {{text}}{{isTooLong ? '...' : ''}}
    <span class="removable-pill__remove-icon material-icons" @click="$emit('remove', item)">
      cancel
    </span>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  computed: {
    text() { return  this.item.text.slice(0, 30) },
    isTooLong() {return this.item.text.length > 30 },
  }
}

</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';

.removable-pill {
  height: 34px;
  border-radius: 25px;
  background: $feedCardDividerColor;
  padding: 0 12px;
  gap: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: $brandColor;
  font-weight: 600;
}
.removable-pill__remove-icon {
  vertical-align: middle;
  font-size: 20px;
  color: $brandColor;
  cursor: pointer;
  &:hover {
    color: $oldBrandColor;
  }
}
</style>
