<template>
    <section class="write-review-page">
        <router-view :key="$route.fullPath" />
    </section>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
.write-review-page {
  width: 100%;
  max-width: 990px;
  margin: 24px auto 0 auto;
  @include respond-to(mobile) {
    margin: 16px auto 0 auto;
  }
}
</style>
