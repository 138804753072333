<!--https://rowanwins.github.io/vue-dropzone/docs/dist/index.html#/installation -->
<template>
  <Dropzone
    :ref="refName"
    id="dropzone"
    :options="opts"
    :useCustomSlot=true
    @vdropzone-sending="(...v) => handleEmit('sending', ...v)"
    @vdropzone-files-added="(...v) => handleEmit('filesAdded', ...v)"
    @vdropzone-success="(...v) => handleEmit('success', ...v)"
    @vdropzone-removed-file="(...v) => handleEmit('removeFile', ...v)"
    @vdropzone-thumbnail="(...v) => handleEmit('thumbnail', ...v)"
    @vdropzone-processing="processing"
  >
    <slot>
      Default Upload Text
    </slot>
  </Dropzone>
</template>
<script>
  import Dropzone from 'vue2-dropzone';
  export default {
    components: { Dropzone },
    props: ['options', 'refName'],
    data(){
      return {
        opts: this.options,
      }
    },
    methods: {
      handleEmit(emit, ...v ){
        this.$emit(emit, ...v)
      },
      processing(){ // hack to continue processQueue due to unknown reason makes queue process stop (only happens after moving to separate component)
        if (this.$refs[this.refName].getUploadingFiles().length === this.options.parallelUploads)
        this.$refs[this.refName].processQueue()
      }
    },
  }
</script>
