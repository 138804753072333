<template>
  <section class="user-avatar">
    <div v-if="avatar_image_url && Object.keys(avatar_image_url).length > 0" class="user-avatar__wrapper">
      <LazyImage :className="['user-avatar__img']" :alt="'Reviewer avatar'" :urls='{ normal: avatar_image_url.normal, retina: avatar_image_url.retina }' />
    </div>

    <div v-else class="user-avatar__wrapper" :data-gravatar-hash="gravatar_hash">
      {{ reviewer_initial }}
    </div>
    <CircleIcon v-if='verified_buyer' icon="check" class="user-avatar__verified-icon"/>
  </section>
</template>

<script>
  import LazyImage from 'rs/components/common/lazy_image'
  import CircleIcon from 'rs/components/common/icons/custom_circle_icon';

  export default {
    components: { LazyImage, CircleIcon },
    props: ['avatar_image_url', 'reviewer_initial', 'gravatar_hash', 'verified_buyer'],
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  $_size: 36px;

  .user-avatar {
    position: relative;
    display: inline-block;
    width: $_size;
    height: $_size;
    line-height: $_size;
  }

  .user-avatar__verified-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 12px;
    height: 12px;
  }

  .user-avatar__wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-align: center;
    border-radius: 50%;
    color: $primaryFontColor;
    background-color: $backgroundColor;
    text-transform: capitalize;
  }

  .user-avatar__img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
</style>
