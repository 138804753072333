<template>
  <section class="filter-sidebar-modal">
    <div class="filter-sidebar-modal__wrapper">
      <div class="filter-sidebar-modal__container">
        <div class="filter-sidebar-modal__header">
          <span v-if="screen==='home'" class='filter-sidebar-modal__header-close material-icons' @click="toggleModal()">close</span>
          <span v-else class='filter-sidebar-modal__header-close material-icons' @click="go(-1)">arrow_back_ios</span>
          <div v-if="screen==='home'" class="filter-sidebar-modal__header-text">Filter by</div>
          <div v-else class="filter-sidebar-modal__header-text">{{ $sharedUtils.capitalizeFirstChar(screen) }}</div>
          <span v-if="screen==='home' && didAnyFilterChange" class='filter-sidebar-modal__header-clear' @click="clearFilterAndClose('all')">Clear all filters</span>
        </div>

        <div v-show="screen === 'home'" class="filter-sidebar-modal__filters-body">
          <template v-if="enableCategoryFilter">
            <div class="filter-sidebar-modal__item-wrapper" @click="go('categories')">
              <div class="filter-sidebar-modal__item-title-short">
                <!--  Vue/Html auto add white space between el, we don't want to have white space in "category :" -->
                Category{{appliedCategoryName && ':'}}
                <span v-if="appliedCategoryName" class="filter-sidebar-modal__item-title-category-name">{{ appliedCategoryName }}</span>
                <span class='filter-sidebar-modal__item-more material-icons'>arrow_forward_ios</span>
              </div>
            </div>
            <div class="filter-sidebar-modal__divider" ></div>
          </template>

          <div class="filter-sidebar-modal__item-wrapper">
            <div class="filter-sidebar-modal__item-title">Overall rating</div>
            <div>
              <StarRating :defaultRating='searchParams.min_rating' @change="e=> updateFilter({min_rating: e})" class="filters-sidebar__star"/>
              {{ renderStarText }}
            </div>
          </div>
          <div class="filter-sidebar-modal__divider"></div>

          <div class="filter-sidebar-modal__item-wrapper">
            <div class="filter-sidebar-modal__item-title">
              Reviews
              <span  v-if="searchParams.reviews_count" class='filter-sidebar-modal__item-title-clear' @click="clearFilter('reviews_count')">Clear</span>
            </div>
            <ReviewsFilter @change="e=> updateFilter({reviews_count: e})" :activeValue="searchParams.reviews_count"/>
          </div>
          <div class="filter-sidebar-modal__divider"></div>

          <div class="filter-sidebar-modal__item-wrapper">
            <div class="filter-sidebar-modal__item-title">
              Price
              <span v-if="searchParams.min_price || searchParams.max_price" class='filter-sidebar-modal__item-title-clear' @click="clearFilter('price')">Clear</span>
            </div>
            <div class="filter-sidebar-modal__item-filter">
              <PriceFilter
                  @submit="applyFilterAndClose"
                  @change="value => updateFilter({ min_price: value[0], max_price: value[1]})"
                  :min="searchParams.min_price" :max="searchParams.max_price"/>
            </div>
          </div>

          <div class="filter-sidebar-modal__divider--thick"></div>
          <div class="filter-sidebar-modal__item-wrapper">
            <div class="filter-sidebar-modal__item-filter">
              <LocaleSetting @afterChange="toggleModal" :autoRefetch="false"/>
            </div>
          </div>
          <div class="filter-sidebar-modal__divider--thick"></div>

          <div class="filter-sidebar-modal__item-wrapper">
            <div class="filter-sidebar-modal__item-title">
              <span class="filter-sidebar-modal__item-title-tooltip-wrapper" @click="showTooltip('transparency')" @mouseleave="closeTooltip('transparency')">
                Transparency
                <span class="filter-sidebar-modal__item-title-icon svg-sprite filter-sidebar-modal__item-title-icon--stars">
                </span>
                <Tooltip v-if="tooltips.transparency" class="filter-sidebar-modal__item-title-tooltip"
                         @close="closeTooltip('transparency')"
                         text="Percentage of verified reviews that are published" />
              </span>
            </div>
            <div class="filter-sidebar-modal__item-filter">
              <SingleSlider
                  @change="e => updateFilter({min_transparency: e})"
                  :class="[updateSliderTooltipPosition(searchParams.min_transparency)]"
                  :defaultValue="searchParams.min_transparency" :min="0" :max="100"/>
            </div>
          </div>
          <div class="filter-sidebar-modal__divider"></div>

          <div class="filter-sidebar-modal__item-wrapper">
            <div class="filter-sidebar-modal__item-title">
              <span class="filter-sidebar-modal__item-title-tooltip-wrapper"  @click="showTooltip('authenticity')" @mouseleave="closeTooltip('authenticity')">
                Authenticity
                <span class="filter-sidebar-modal__item-title-icon svg-sprite filter-sidebar-modal__item-title-icon--check">
                </span>
                <Tooltip v-if="tooltips.authenticity" class="filter-sidebar-modal__item-title-tooltip"
                         @close="closeTooltip('authenticity')"
                         text="Percentage of published reviews that are verified" />
              </span>
            </div>
            <div class="filter-sidebar-modal__item-filter">
              <SingleSlider
                  @change="e => updateFilter({min_authenticity: e})"
                  :class="[updateSliderTooltipPosition(searchParams.min_authenticity)]"
                  :defaultValue="searchParams.min_authenticity" :min="0" :max="100"/>
            </div>
          </div>
          <div class="filter-sidebar-modal__divider"></div>

          <div class="filter-sidebar-modal__item-wrapper" v-if="false">
            <div class="filter-sidebar-modal__item-title-short">
              Stores
              <span class='filter-sidebar-modal__item-more material-icons'>arrow_forward_ios</span>
            </div>
          </div>
          <div class="filter-sidebar-modal__divider" v-if="false"></div>
        </div>

        <Categories class="filter-sidebar-modal__categories-body" v-show="screen === 'categories'" :change="updateCategoryId" />
      </div>

      <div class="filter-sidebar-modal__footer">
        <button class="filter-sidebar-modal__apply-button" @click="applyFilterAndClose" :disabled="disabled"> Apply Filters
        </button>
      </div>
    </div>
  </section>
</template>

<script>
  import StarRating from 'rs/components/common/star_rating_chooser';
  import PriceFilter from 'rs/components/pages/search/_filter_price'
  import ReviewsFilter from 'rs/components/pages/search/_filter_reviews';
  import SingleSlider from 'rs/components/pages/search/_filter_range_slider_single'
  import LocaleSetting from 'rs/components/pages/search/_locale_setting';
  import Categories from 'rs/components/pages/search/screen_categories';
  import Tooltip from 'rs/components/common/tooltip_v2';
  import {mapState} from "vuex";
  import {didChange} from 'rs/store/store_utils/search_utils';

  export default {
    components: {
      Tooltip,
      Categories,
      LocaleSetting,
      StarRating,
      PriceFilter,
      SingleSlider,
      ReviewsFilter,
    },
    props: [
      'loading',
      'clearFilter',
      'updateFilter',
      'applyFilter',
      'didAnyFilterChange',
      'enableCategoryFilter',
      'toggleModal',
      'updateSliderTooltipPosition',
    ],
    computed: {
      ...mapState('Search', ['searchParams', 'selectedStack', 'categories']),
      screen: function () {
        return this.screens[this.screens.length - 1]
      },
      appliedCategoryName() {
        return this.categories[this.searchParams.category_id] ? this.categories[this.searchParams.category_id].name : null
      },
      disabled(){
        const didChangeParams = didChange(this.searchParams, this.tempSearchParam)
        const didChangeCurrency = this.$store.getters['SharedCurrentReviewer/didChangeCurrency']
        const didChangeCategory = this.tempSearchParam.category_id != this.selectedStack[this.selectedStack.length -1] // use loose comparison for null == undefined
        // return true when no change
        return !didChangeParams && !didChangeCategory && !didChangeCurrency
      },
      renderStarText(){
        switch(this.searchParams.min_rating) {
          case 0:
            return `All ratings`
          case 5:
            return `Only ${ this.$sharedUtils.pluralize(this.searchParams.min_rating, 'star') }`
          default:
            return `At least ${ this.$sharedUtils.pluralize(this.searchParams.min_rating, 'star') }`
        }
      },
    },
    data() {
      return {
        screens: ['home'],
        tooltips: { transparency: false, authenticity: false },
        tempSearchParam: {},  // to compare with initial searchParams
      }
    },
    methods: {
      closeModalWithoutSave() {
        this.$store.commit('SharedCurrentReviewer/toggleFilterSidebar', false)
      },
      updateCategoryId(id){
        this.categoryId = id
      },
      applyFilterAndClose() {
        if (this.categoryId !== undefined) {
          this.$store.commit('Search/setSelectedCategoryID', this.categoryId)
        }
        this.applyFilter();
        this.closeModalWithoutSave();
      },
      clearFilterAndClose(v) {
        this.clearFilter(v, true);
        this.closeModalWithoutSave();
      },
      go(screenName) {
        if (screenName === -1) {
          this.screens.pop()
        } else {
          this.screens.push(screenName)
        }
      },
      showTooltip(field){
        this.tooltips[field] = true;
      },
      closeTooltip(field){
        this.tooltips[field] = false;
      },
    },
    mounted(){
      this.tempSearchParam = {...this.searchParams}
    }
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/variables_new';
  @import '~PlatformStyles/abstract/mixins';

  .filter-sidebar-modal {
    width: 100vw;
    min-height: 100%;
    max-height: 100%;
    height: fit-content;
    padding-bottom: 50px;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: white;
    overflow-y: auto;
    @include respond-to(notmobile) {
      display: none;
    }
  }


  .filter-sidebar-modal__wrapper {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 24px;
    min-height: 100vh;
  }

  .filter-sidebar-modal__container {
    flex: 1 0 auto;
  }

  .filter-sidebar-modal__header {
    position: fixed;
    z-index: 3; // some other elements are 1 and 2
    width: 100%;
    line-height: 48px;
    height: 48px;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
    vertical-align: middle;
    margin-bottom: 28px;
    background: white;
  }

  .filter-sidebar-modal__header-close {
    color: $newBrandColor;
    position: absolute;
    top: 12px;
    left: 20px;
  }

  .filter-sidebar-modal__header-clear {
    color: $newBrandColor;
    position: absolute;
    top: 0;
    font-weight: 400;
    right: 16px;
    font-size: 14px;
    text-decoration: underline;
  }

  .filter-sidebar-modal__header-text {
    font-weight: 700;
    font-size: 20px;
  }

  .filter-sidebar-modal__filters-body {
    margin-top: 80px;
  }
  .filter-sidebar-modal__categories-body {
    margin-top: 50px;
  }
  .filter-sidebar-modal__divider {
    height: 1px;
    margin: 20px 0 20px 0;
    background-color: $lightGreenColor;
  }
  .filter-sidebar-modal__divider--thick {
    height: 20px;
    margin: 20px 0 20px 0;
    background-color: #E0F2F2;
  }

  .filter-sidebar-modal__item-wrapper {
    padding: 0 16px;
  }

  .filter-sidebar-modal__item-title {
    font-weight: 700;
    color: #333333;
    margin: 0 0 12px 0;
    display: flex;
    justify-content: space-between;
  }
  .filter-sidebar-modal__item-title-clear {
    font-weight: 400;
    font-size: 14px;
    color: $newBrandColor;
    text-decoration: underline;
  }
  .filter-sidebar-modal__item-title-icon {
    display: inline-block;
    margin-left: 4px;
    height: 20px;
    width: 24px;
    vertical-align: middle;
  }

  .filter-sidebar-modal__item-title-icon--stars {
    background-position: -700px -200px;
  }
  .filter-sidebar-modal__item-title-icon--check {
    background-position: -800px -200px;
  }

  .filter-sidebar-modal__item-title-short {
    font-weight: 700;
    padding-right: 24px;
    width: 100%;
    color: black;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .filter-sidebar-modal__item-title-category-name {
    font-weight: 400;
    white-space: pre-wrap;
  }

  .filter-sidebar-modal__item-more {
    position: absolute;
    right: 0;
    top: 2px;
    color: $newBrandColor;
    font-size: 20px;
  }

  .filter-sidebar-modal__item-filter {
    color: $secondaryFontColor;
  }

  .filter-sidebar-modal__item-filter-button {
    background-color: $lightGreenColor;
    border-radius: 4px;
    border: none;
    padding: 8px 16px;
    margin-top: 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;
  }

  .filter-sidebar__slider--right,
  .filter-sidebar__slider--left {
    width: 100%;
    margin-top: 28px;
  }

  .filter-sidebar-modal__footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    flex-shrink: 0;
    padding: 0 16px 12px 16px;
    background: white;
    z-index: 2;
  }

  .filter-sidebar-modal__apply-button {
    height: 48px;
    text-align: center;
    width: 100%;
    background: #333333;
    border-radius: 25px;
    border: none;
    color: #FFFFFF;
    font-weight: 800;

    &:disabled {
      background: lightgrey;
    }
  }

  .filter-sidebar-modal__item-title-tooltip {
    font-size: 14px;
    font-weight: 400;
    width: 162px;
    top: -10px;
    right: -66px; // 162 -  30 / 2
    transform: translateY(-100%);
    z-index: 1;
  }
  .filter-sidebar-modal__item-title-tooltip-wrapper {
    position: relative;
  }
</style>

<!-- .filter-sidebar__slider--left -->
<!--slider styles are defined as global scope in filters_sidebar.vue-->
<!--If want to customize styles for this modal only, define other classes here-->
