<template>
  <div class="rs-toggle" :class="toggleClasses" @click="toggle" :style="styleObj">
    <div class="slider-dummy before"></div>
    <div class="slider"></div>
    <div class="slider-dummy after"></div>
  </div>
</template>
<script>
export default {
  props: {
    active: {type: Boolean, default: false},
    radius: {type: Number, default: 4}, // number for pixel, 99 => round
  },
  emits: ['toggle'],
  computed: {
    toggleClasses() {
      return {
        'rs-toggle--active': this.active,
      };
    },
    styleObj() {
      return {
        borderRadius: this.radius + 'px'
      }
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle', !this.active)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.rs-toggle {
  cursor: pointer;
  box-shadow: 0 0 0 1px #999;
  height: 20px;
  width: 40px;
  background: $textSecondaryColor;
  display: flex;

  .before {
    flex-grow: 0;
  }

  .after {
    flex-grow: 1;
  }
}

.rs-toggle--active {
  background: $brandColor;

  .before {
    flex-grow: 1;
  }

  .after {
    flex-grow: 0;
  }
}

.slider-dummy {
  transition: flex 0.5s;
}

.slider {
  border-radius: inherit;
  background: linear-gradient(#f5f5f5, #cfcfcf);
  box-shadow: 1px 1px 2px #888;
  width: 20px;
  height: 20px;
}
</style>
