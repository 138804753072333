<template>
  <section class="media">
    <div class="media__wrapper">
      <div class="media__title">Customer photos & videos</div>
      <Thumbnails class="media__thumbnails" :media="media" :openGallery="openGallery" :visibleCount="visibleCount"/>
    </div>
  </section>
</template>
<script>
  import Thumbnails from 'rs/components/common/profile_card_thumbnails'

  export default {
    components: { Thumbnails },
    props: ['media', 'openGallery', 'visibleCount']
  }
</script>
<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .media {
    width: 100%;
  }
  .media__wrapper {
    width: 100%;
    height: 100%;
  }
  .media__title {
    color: $secondaryFontColor;
    font-weight: 400;
    font-size: 14px;
  }
  .media__thumbnails {
    margin-top: 12px;
    width: 100%;
    height: 100%;
  }
</style>
