const ALIEXPRESS_CDN_BASE = 'alicdn.com'
export const imageFromAliCDN = url => {
  try {
    const obj = new URL(url);
    return obj && obj.hostname && obj.hostname.endsWith(ALIEXPRESS_CDN_BASE);
  } catch (error) { // if url is blank or invalid, return false
    return false
  }
}

export const replaceRefererPolicyIfAliImages = (...urls) => urls.some(imageFromAliCDN) ? { referrerpolicy: 'no-referrer'} : {}
