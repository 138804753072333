<template>
  <div class='feed-card-header'>
    <div v-if="review">
      <UserAvatar :avatar_image_url="review.avatar_image_url" :reviewer_initial='review.reviewer_initial'
                  :gravatar_hash='review.gravatar_hash' :verified_buyer='review.verified_buyer' />
      <div class='reviewer'>
        <router-link class='reviewer__name' :to="reviewerLink" target="_blank">{{ review.reviewer_name }}</router-link>
        <VerifiedBadge v-if='review.verified_purchase' />
        <div class='reviewer__location'>
          {{ review.location_country }}
          <CountryFlag v-if='review.location_country_code' class='reviewer__location-flag' :alt='review.location_country' :countryCode='review.location_country_code' />
        </div>
      </div>
    </div>

    <div v-else-if="reviewer">
      <router-link :to="reviewer.public_slug" target="_blank">
        <UserAvatar :avatar_image_url='reviewer.avatar_image_urls'
                    :reviewer_initial='reviewer.initial' :gravatar_hash='reviewer.gravatar_hash' :verified_buyer='reviewer.verified_buyer' />
      </router-link>
      <div class='reviewer center-name'>
        <router-link class='reviewer__name' :to="reviewer.public_slug" target="_blank">{{ review.reviewer_name }}</router-link>
        <div class='reviewer__timestamp'>{{ createdAtRelative }}</div>
      </div>
    </div>

    <p v-if="descriptionHtml" v-html="descriptionHtml" class='feed-card-header__description'></p>
    <p v-else class='feed-card-header__description'>
      {{ description }}
    </p>

    <div class='feed-card-header__divider'>
    </div>
  </div>
</template>

<script>
  import {customFormat} from 'shared/utils/timeago';
  import UserAvatar from 'rs/components/common/user_avatar'
  import VerifiedBadge from 'rs/components/common/verified_badge'
  import CountryFlag from 'rs/components/common/country_flag'

  export default {
    components: { UserAvatar, VerifiedBadge, CountryFlag },

    props: ['feedEvent', 'review', 'reviewer', 'description', 'descriptionHtml'],

    computed: {
      reviewerLink() {
        return this.review.reviewer_public_slug ? ('/' + this.review.reviewer_public_slug) :  '/profile/' + this.review.reviewer_public_id
      },
      createdAtRelative() {
        return customFormat(this.feedEvent.event_created_at)()
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .feed-card-header__avatar-name {
    display: flex;
    align-items: center;
  }

  .reviewer {
    display: inline-block;
    vertical-align: top;
    margin-left: 8px;
    line-height: 1;
  }

  .reviewer__name {
    vertical-align: middle;
  }

  .reviewer__location, .reviewer__timestamp {
    font-size: 12px;
    color: $secondaryFontColor;
    margin-top: 4px;
  }

  .reviewer__location-flag {
    display: inline-block;
    height: 8px;
    max-width: 12px;
    vertical-align: middle;
  }

  .feed-card-header__description {
    margin-top: 12px;
    font-size: 16px;
    color: $secondaryFontColor;
  }

  .feed-card-header__divider {
    height: 1px;
    width: calc(100% + 16px + 16px);
    margin-left: -16px; // Padding of the card
    margin-top: 20px;
    margin-bottom: 20px;
    background: $feedCardDividerColor;
  }
</style>
