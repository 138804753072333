import router from 'rs/plugins/router';
import {
  categoriesSortFunctionByName,
  categoriesSortFunctionByPageOrder,
  arrToHashAndPopulateChildren,
} from 'rs/store/store_utils/search_utils';
import {SearchCache} from 'rs/store/store_utils/search_utils'
import {setState, setMeta, fetchRequest} from 'rs/store/store_utils/shared'

const indexPath = longVersion =>  ({path: '/categories', name: longVersion ? 'Browse all categories' : 'All'})

const requestUrl = handle => `/categories/${handle}.json`


const resCache = new SearchCache({}, true, 60) // {[allValuesFromQueryObject]: { sha: ..., results: [] } }
const fetchWithCache = fetchRequest(resCache)

const state = {
  bDoneLoading: false,
  category: null,
  childCategories: [],
  stack: [],
  categoriesMap: {},  // {[id]: [category]}
  previousRouteBeforeRedirect: null,
}

const getters = {
  previousRouteBeforeRedirect: ({previousRouteBeforeRedirect}) => previousRouteBeforeRedirect,
  bDoneLoading: ({bDoneLoading}) => bDoneLoading,
  category: ({category}) => category,
  title: ({category}) => category && category.name,
  childCategories: ({childCategories}) => childCategories,
  stack: ({stack}) => (longVersion = false)  => {
    stack.unshift(indexPath(longVersion))
    return stack
  },
  categoriesMap: state => state.categoriesMap,
  rootCategories: (state, getters, rootState) => rootState.Search.rootCategories.slice(1),
  sortedLv1: (state, getters) => {
    const categories = getters.rootCategories.filter(e => !!e.page_order)
    categories.sort(categoriesSortFunctionByPageOrder)
    return categories
  },
  sortedChildCategories: (state) => {
    const categories = state.childCategories.filter(e => !!e.page_order)
    categories.sort(categoriesSortFunctionByPageOrder)
    return categories
  }
}

const mutations = {
  setState: (state, [name, value]) => state[name] = value,
  setStack: (state, {category, upperLevelCategories}) => {
    state.stack = [
      ...upperLevelCategories.map(({handle, name}) => ({path: '/categories/' + handle, name})),
      {path: '/categories/' + category.handle, name: category.name},
    ]
  },
}

const actions = {
  async fetchCategory(context, payload) {
    const { commit, dispatch } = context;
    setState(commit, 'bDoneLoading')(false)
    try {
      const setMetaFunc = setMeta(dispatch);
      const requestObj = {requestUrl: requestUrl(payload.handle)}
      const data = await fetchWithCache(setMetaFunc)(requestObj)
      if (data.category.is_deepest) { // we don't show cat page for level 4 cat, redirect to search page with search term
        if (state.previousRouteBeforeRedirect) {
          setState(commit, 'previousRouteBeforeRedirect')(null)
          router.back();
        } else {
          setState(commit, 'previousRouteBeforeRedirect')(router.currentRoute)
          router.replace({path: '/search', query: {
              q: data.category.name.toLowerCase(),
              category_id: data.category.id, sort_by: 'reviews_count', from: 'category'
            }})
        }
        return;
      }
      data.children.sort(categoriesSortFunctionByName)
      const categories = [data.category, ...data.children, ...data.grand_children, ...data.upper_level_categories]
      const categoryMap = arrToHashAndPopulateChildren(categories)

      setState(commit, 'categoriesMap')(categoryMap)
      setState(commit, 'category')(data.category)
      setState(commit, 'childCategories')(data.children)
      commit('setStack', {category: data.category, upperLevelCategories: data.upper_level_categories})
    } catch (e){
      console.log(e)
      router.replace({path: '/categories'})
    }
    setState(commit, 'bDoneLoading')(true)
  }
}


export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
}
