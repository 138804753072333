<template>
  <iframe
      :class="className"
      loading="lazy"
      v-if="dataType === 'video_platform' && mediaPlatformName === 'yt'"
      :id="'player_'+ mediaPlatformUrl " type="text/html" class="media-player"
      :src="'https://www.youtube.com/embed/'+ mediaPlatformUrl + '?enablejsapi=1&showinfo=0&controls=0&autohide=1'"
      border="0" frameborder="0"  allowfullscreen>

  </iframe>

  <iframe
      :class="className"
      loading="lazy"
      v-else-if="source === 'cloudflare'"
      :id="'player'+videoId" type="text/html"
      :src="'https://iframe.videodelivery.net/'+videoId+'?muted=false'"
      title='Review video'
      class="media-player"
      allow='fullscreen'>
  </iframe>

  <img
     v-else-if="source === 'vimeo' && vimeoPlayer && className==='media-player__thumbnail'"
     class='media-player__thumbnail'
     loading='lazy'
     alt='Play the video'
     v-bind:src="vimeoPlayer.thumbnail_url_with_play_button"
  />

  <div v-else-if="source === 'vimeo' && vimeoPlayer"
       :class="className"
       v-html="vimeoPlayer.html">
  </div>

</template>

<script>
  import axiosOriginal from 'axios'

  export default {
    props: ['className', 'dataType', 'mediaPlatformUrl', 'mediaPlatformName', 'videoId', 'options', "source"],
    data(){
      return {
        vimeoPlayer: null,
      }
    },
    async mounted(){
      if(this.source === 'vimeo'){
        this.vimeoPlayer = await this.fetchVimeo(this.videoId);
      }
    },
    methods: {
      async fetchVimeo (videoId, options = {}) {
        try {
          if (window.cachedVimeoResponses && window.cachedVimeoResponses[videoId]) {
            return window.cachedVimeoResponses[videoId]
          }
          const res = await axiosOriginal.get('https://vimeo.com/api/oembed.json', {
            params: {
              url: "https://vimeo.com/" + videoId,
              height: 380,
              title: options.title || false,
              muted: options.muted || true,
              byline: options.byline || false,
              portrait: options.portrait || false,
              responsive: true,
              transparent: false,
            }
          })
          window.cachedVimeoResponses = {
            ...window.cachedVimeoResponses,
            [videoId]: res.data
          }
          return  res.data
        } catch (e) {
          console.log('Video is processing. It will be available shortly. Please refresh the page after a few seconds.')
          return null
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
  .media-player {
    height: 100%;
    width: 100%;
    border: none;
  }
  .media-player__thumbnail {
    border-radius: 5px;
    height: 48px;
    width: 64px;
    border: none;
    pointer-events: none
  }
</style>
