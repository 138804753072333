<!-- Currently we use MatchMedia to render only 1 version of filters of the time-->
<!-- - Filter sidebar: Only ON (display) when in desktop/tablet mode-->
<!-- - Filter modal: Only ON (display) when in mobile mode-->
<template>
  <MatchMedia v-slot="{ mobile, tablet, desktop}">
    <section class='rs-search-page'>
      <FilterModal v-if="mobile"
                   v-show="filterSidebarOpen"
                   :loading="isLoadingSearchResults"
                   :didAnyFilterChange="didAnyFilterChange"
                   :enableCategoryFilter="enableCategoryFilter"
                   :clearFilter="clearFilter"
                   :updateFilter="updateFilter"
                   :applyFilter="fetchWithFilters"
                   :toggleModal="toggleFilterSidebar"
                   :updateSliderTooltipPosition="updateSliderTooltipPosition"
      />
      <div class="search-page__heading">
        <div v-if="enableCategoryFilter && previousCategory && !searchResults.category && searchResults.rounded_results_total_count != null"
             class="search-page__previous-category">
          Recent categories:
          <span class="search-page__previous-category-name" @click="searchWithPreviousCategory">
            {{ previousCategory.name }}
          </span>
        </div>
      </div>
      <div class="search-page__body">
        <FilterSidebar class="search-page__sidebar"
                       v-if="desktop || tablet"
                       :loading="isLoadingSearchResults"
                       :didAnyFilterChange="didAnyFilterChange"
                       :enableCategoryFilter="enableCategoryFilter"
                       :clearFilter="clearFilter"
                       :updateFilter="updateFilter"
                       :applyFilter="fetchWithFilters"
                       :updateSliderTooltipPosition="updateSliderTooltipPosition"
        />
        <div class="search-page__container">
          <div class='search-results__heading'>
            <div :class="{ 'search-query-empty': searchResults.rounded_results_total_count === null }"
                 class='search-results__summary'>
              <span v-if="searchResults.rounded_results_total_count == null">
                Searching {{ searchParams.q ? `for "${searchParams.q}"` : null }}
              </span>
              <div v-else class="search-results__result-heading">
                {{ renderResultText }}
                <!-- Shop name button -->
                <div v-if="searchResults.search_in_shop_name" class="search-results__summary-category-wrapper">
                  in
                  <RemovablePill class='search-results__summary-category-pill'
                                 :item="{text: searchResults.search_in_shop_name}"
                                 @remove="handleRemoveSearchInShop"/>
                </div>
                <!-- End of Shop name button -->
                <!-- category button -->
                <div v-else-if="enableCategoryFilter && searchResults.category" class="search-results__summary-category-wrapper">
                  in
                  <RemovablePill class='search-results__summary-category-pill'
                                 :item="{text: searchResults.category.name }"
                                 @remove="searchWithoutCategory"/>
                </div>
                <!-- end of category button -->
                <span>.</span>
              </div>
            </div>
            <Sort v-if="desktop || tablet"/>
          </div>
          <div v-if="!isNewSearch" class='search-results'>
            <div v-if="mobile">
              <FilterSortHeader/>
            </div>
            <div class='search-results__list'>
              <LoadingSpinner v-if='isLoadingSearchResults'/>
              <template v-else>
                <NoSearchResults
                  v-if="!currentPageResults.results"
                  :hasFilters="didAnyFilterChange"
                  :clearFilter="clearFilter"
                />
                <ProductSearchCard class='search-result-card'
                                   v-else
                                   v-for="(searchResult, index) in currentPageResults.results"
                                   :searchResult="searchResult"
                                   :doneSearchSha1="currentPageResults.done_search_sha1"
                                   :index="index"
                                   :key="index"
                />
              </template>
            </div>
          </div>
          <Pagination v-if="searchResults.total_pages > 0"
                      class="search-results__pagination" :currentPage="searchResults.current_page"
                      :minItem="mobile ? 3 : 8" :displayRange="mobile ? 1 : 3"
                      isLink :totalPage="searchResults.total_pages"
          />
        </div>
      </div>
    </section>
  </MatchMedia>
</template>

<script>
import {mapState} from 'vuex'
import {MatchMedia} from "shared/vue_media_query";
import LoadingSpinner from 'rs/components/common/loading_spinner'
import RemovablePill from 'rs/components/common/removable_pill'
import NoSearchResults from 'rs/components/common/no_search_results_v2'
import ProductSearchCard from 'rs/components/common/product_search_card_v2'
import FilterSortHeader from 'rs/components/common/filter_sort_header';
import FilterSidebar from 'rs/components/pages/search/filters_sidebar';
import FilterModal from 'rs/components/pages/search/filters_modal';
import Pagination from 'rs/components/common/pagination';
import Sort from 'rs/components/common/search_sort'

export default {
  components: {
    MatchMedia,
    FilterModal,
    FilterSidebar,
    RemovablePill,
    FilterSortHeader,
    LoadingSpinner,
    NoSearchResults,
    ProductSearchCard,
    Sort,
    Pagination
  },
  props: {
    query: {
      type: Object,
    },
    enableCategoryFilter: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('Search', [
      'searchParams',
      'defaultFilters',
      'searchResults',
      'isLoadingSearchResults',
      'isNewSearch',
      'selectedStack',
      'categories',
      'previousCategory'
    ]),
    ...mapState('SharedCurrentReviewer', ['currentCountry', 'filterSidebarOpen']),
    didAnyFilterChange() {
      return this.$store.getters['Search/didAnyFilterChange']
    },
    currentPageResults() {
      return this.$store.getters['Search/currentPageResults']
    },
    computedQueries() {
      return this.query || this.$route.query
    },
    renderResultText() {
      const formatText = this.$sharedUtils.pluralizeWithSeparator(this.searchResults.rounded_results_total_count, "result")
      const q = this.searchParams.q.length > 0 ? (`for "${this.searchParams.q}"`) : ''
      return `${formatText} found ${q}`
    }
  },
  created() {
    // fetch data using queries & store
    this.$store.dispatch('Search/initPage', {page: 1, ...this.computedQueries});
  },
  methods: {
    toggleFilterSidebar(bool) { // action toggleFilterSidebar will save/restore current filter status
      this.$store.dispatch('Search/toggleFilterSidebar', bool)
    },
    updateSliderTooltipPosition(v) {
      return v >= 50 ? 'filter-sidebar__slider--right' : 'filter-sidebar__slider--left'
    },
    fetchWithFilters() {
      this.$store.dispatch('Search/fetchWithFilters')
    },
    clearFilter(name, bRefetch) {
      this.$store.dispatch('Search/clearFilter', {name, bRefetch})
    },
    updateFilter(params, bRefetch) {
      this.$store.dispatch('Search/updateFilter', {params, bRefetch})
    },
    searchWithoutCategory() {
      this.$store.dispatch('Search/navigateToSearchPageWithQuery', {category_id: null})
    },
    searchWithPreviousCategory() {
      this.$store.dispatch('Search/navigateToSearchPageWithQuery', {category_id: this.previousCategory.id})
    },
    handleRemoveSearchInShop() {
      this.$store.dispatch('Search/navigateToSearchPageWithQuery', {
        shop_domain: null,
        search_in_shop: null,
        platform: null,
      })
    }
  },
  watch: {
    selectedStack(current, previous) {
      if (current[0] == previous[0]) return;

      if (!previous[0]) {  // starts from nothing:  from all, or first load.
        const lastItem = this.categories[current[current.length - 1]]
        if (!lastItem.is_deepest && (!lastItem.children || lastItem.children.length === 0)) {
          this.$store.dispatch('Search/fetchCategories', {
            q: this.computedQueries.q,
            category_id: current[current.length - 1]
          })
        }
        return;
      }
      if (!current[0]) {
        if (this.searchParams.category_id) { // not fetch again when clicking on < arrow in level 1. (go back to root)
          this.$store.dispatch('Search/fetchCategories', {
            q: this.computedQueries.q,
            category_id: current[current.length - 1]
          })
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.loading-spinner {
  margin: 24px auto;
  width: 72px;
  height: 72px;
}


.search-page__heading {
  margin: 4px 0 8px 0;
}

.search-page__previous-category {
  font-size: 12px;
  color: $secondaryFontColor;
  line-height: 24px;
  font-weight: 400;
}

.search-page__previous-category-name {
  background: #F0F5F5;
  font-weight: 600;
  color: $primaryFontColor;
  border-radius: 25px;
  padding: 4px 8px;
  cursor: pointer;
}

.search-page__body {
  display: flex;
  font-weight: 400;
  width: 100%;
  margin-top: 12px;
}

.search-page__sidebar {
  width: 232px;
  min-width: 232px;
  min-height: 80vh;
  margin-right: 20px;
}

.search-page__container {
  width: calc(100% - 232px);
  @include respond-to(mobile) {
    width: 100%;
  }
}

.search-results {
  display: flex;
  flex-direction: column;
}

.search-results--no-results {
  .search-results__summary {
    display: inline-block;
    margin-top: 8px;
  }
}

.search-results__summary {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  display: inline-block;
}

.search-results__result-heading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.search-results__summary-category-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 8px;
  @include respond-to(mobile) {
    margin-left: 0;
  }
}

.search-results__summary-category-pill {
  height: 34px;
  font-size: 14px;
}

.search-results__summary-category {
  background: $feedCardDividerColor;
  color: $brandColor;
  font-weight: 600;
  font-size: 14px;
  border-radius: 25px;
  padding: 6px 6px 6px 8px;
}

.search-results__heading {
  margin: 20px 0;
  margin-top: -4px;
  display: flex;
  justify-content: space-between;

  @include respond-to(mobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.search-query-empty {
  visibility: hidden;
}

.search-results__list {
  display: flex;
  flex-wrap: wrap;
}

.search-result-card {
  margin-bottom: 20px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 400;
  background-color: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;

  @include respond-to(mobile) {
    width: calc(50% - 10px);
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
  @include respond-to(desktop) {
    width: calc(25% - 15px);
    &:nth-of-type(4n) {
      margin-right: 0;
    }
  }
  @include respond-to(tablet) {
    width: calc(33.3% - 13.3px);
    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
}

.search-results__pagination {
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

</style>
