<template>
  <section class='rs-reviews-page'>
    <Gallery v-if="galleryIsOpen" />
    <h1 v-if='!isLoggedIn' class='rs-reviews-page__title'>Reviews? Judge.me</h1>
    <SearchDropdown v-if="!isLoggedIn"/>

    <p v-if='!isLoggedIn' class='rs-reviews-page__tagline'>
      Read <b>{{ metrics.total_verified_reviews.toLocaleString('en-us') }}</b> reviews from customers with proof of purchase.
      <br>
      Support <b>{{ metrics.shop_metric.toLocaleString('en-us') }}</b> independent online stores who sold <b>{{ metrics.line_item_metric.toLocaleString('en-us') }}</b> products in the last 30 days.
    </p>

    <div class='rs-reviews-page__content'>
      <FeedCardsFilters />
      <FeedCardsList />
    </div>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import FeedCardsFilters from 'rs/components/pages/feed/feed_cards_filters'
  import FeedCardsList from 'rs/components/pages/feed/feed_cards_list'
  import SearchDropdown from 'rs/components/common/search_dropdown'
  import Gallery from 'rs/components/common/gallery'

  export default {
    components: { FeedCardsFilters, FeedCardsList, Gallery, SearchDropdown },
    computed: {
      ...mapState('Reviews', {
        metrics:  state => state.homeMetrics,
      }),
      galleryIsOpen() {
        return this.$store.getters['Gallery/isOpen']
      },
      isLoggedIn() {
        return window.isReviewerLoggedIn;
      },
    },

    mounted () {
      const self = this
      this.$axios.get('/reviews.json').then(function (response) {
        self.$store.commit('Reviews/updateHomeMetrics', response.data.metrics)
      })
      this.$store.dispatch('Meta/getMeta')
    }
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .rs-reviews-page {
    display: block;
    margin:  0 auto;
    max-width: 640px;
  }

  .rs-reviews-page__title {
    text-align: center;
    line-height: 1;
    font-size: 24px;
    font-weight: 600;
    color: $brandColor;
    margin: 20px auto;

    @include respond-to(desktop) {
      margin: 32px auto 40px auto;
      font-size: 40px;
    }
    @include respond-to(tablet) {
      margin: 40px auto 20px auto;
      font-size: 40px;
    }
  }

  .rs-reviews-page__tagline {
    color: $secondaryFontColor;
    text-align: center;
    margin: 20px 0;

    @include respond-to(desktop) {
      margin-left: -40px; // To make the the element exceed the width of the parent element
      margin-right: -40px; // To make the the element exceed the width of the parent element
      min-width: calc(100% + 80px),
    }
  }
</style>
