<template>
  <div class="category-line-children-wrapper">
    <div v-for="category in categories"
         class="category-line-children"
         :key="category.id">
      <router-link
          :to="linkToParams(category)"
          class="category-line-children__name"
      >
        {{ category.name }}
      </router-link>
      <router-link v-if="!category.is_deepest"
                   :to="linkToParams(category)"
                   class="material-icons category-line-children__arrow">
        arrow_forward_ios
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ['categories', 'linkToParams'],
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.category-line-children-wrapper {
  padding: 8px 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, auto);
  grid-auto-rows: min-content;
  grid-auto-flow: dense;

  @include respond-to(mobile) {
    display: none;
  }
  @include respond-to(tablet) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, auto);
  }
}

.category-line-children {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 400;
  color: $primaryFontColor;
  padding: 0 0 20px 0;
  text-decoration: none;
}

.category-line-children__name {
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  color: $primaryFontColor;

  &:hover {
    color: $newBrandColor;
  }
}

.category-line-children__arrow {
  font-size: 12px;
  line-height: 24px;
  margin-left: 8px;
  color: $newBrandColor;
  text-decoration: none;
}

</style>