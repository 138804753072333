<template>
  <div class="country-dropdown">
    <div class="country-dropdown__wrapper">
      <select
          class="country-dropdown__select"
          :value="defaultCountry" @change="handleChange">
        <option v-for="country in countries" :value="country">
          {{ countryMap[country] }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import countryMap from 'shared/constants/country_list_map';

export default {
  props: ['defaultCountry'],
  computed: {
    countryMap() {
      return countryMap;
    },
    countries() {
      return Object.keys(countryMap)
    }
  },
  methods: {
    handleChange(e) {
      this.$emit('change', e.target.value)
    }
  },
}
</script>
<style lang="scss" scoped>
.country-dropdown {
  height: 40px;
  width: 100%;
}

.country-dropdown__wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  padding: 0 25px;
  border: 1px solid #CDCCCC;
  border-radius: 40px;

  &:focus-within {
    border-color: #CDCCCC;
  }
}

.country-dropdown__select {
  cursor: pointer;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: none;
  outline: none;
  background-color: transparent;
}

</style>
