<!--Feature:
- Use comma or Enter keys to separate tags
- Can use Delete key to remove a previous tag
- Can use option/control Delete to delete group of text, and will not remove tags
- Inputs:
  - "value" can be a string or an array of strings
- Output:
  - emit:change returns array of strings
-->
<template>
  <div class="text-box">
    <div class="tag-item-wrapper" v-for="(item,index) in list" :key="index">
      <span class="tag-item">{{ item }}</span>
      <span class="material-icons tag-remove" @click="remove(index)">cancel</span>
    </div>
    <input
      type="text"
      :placeholder="placeholder"
      class="tags-input"
      v-model="text"
      @keydown.8="deleteItem"
      @keyup.13="submit"
      @keyup.188="submit"
    />
  </div>
</template>
<script>
export default {
  props: ['value', 'placeholder'],
  computed: {
    list() {
      if (!this.value) return []
      return (typeof this.value === 'string') ? this.value.split(',') : this.value
    },
  },
  data() {
    return {
      text: ""
    }
  },
  methods: {
    deleteItem() {
      if (this.text.length === 0 && this.list.length > 0) {
        this.remove(this.list.length - 1)
      }
    },
    remove(index) {
      this.list.splice(index, 1);
      this.$emit('change', this.list);
    },
    submit(e) {
      if (!this.text) return;
      if (this.text == ",") {
        this.text = "";
        return;
      }
      this.text = this.text.replace(",", "");
      const isNotExist = this.list.every(item => {
        return item != this.text;
      });
      if (isNotExist) {
        this.list.push(this.text.replace(",", ""));
        this.text = "";
      }
      this.$emit('change', this.list);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.text-box {
  gap: 4px;
  display: flex;
  flex-wrap: wrap;
  font-weight: 600;
}

.tag-item-wrapper {
  display: flex;
  align-items: center;
  height: 24px;
  gap: 4px;
  padding: 4px 8px;
  background: $feedCardDividerColor;
  color: $brandColor;
  border-radius: 12px;
  white-space: nowrap;
  overflow: hidden;
}

.tag-item {
  display: flex;
  align-items: center;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
}

.tags-input {
  font-weight: 400;
  flex: 1;
  border: none;
  outline: none;
  padding: 0;
  font-size: 14px;
  appearance: none;
}

.tag-remove {
  cursor: pointer;
  font-size: 16px;
}

.tags-input:focus {
  outline: none;
}
</style>
