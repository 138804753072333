import * as timeago from 'timeago.js';
import {curry} from 'lodash';

const SHORT_LOCALE_CONFIG = [
  ['just now', 'right now'],
  ['just now', 'right now'],
  ['1 min ago', 'in 1 m'],
  ['%s min ago', 'in %s m'],
  ['1h ago', 'in 1 h'],
  ['%sh ago', 'in %s h'],
  ['1 day ago', 'in 1 d'],
  ['%s days ago', 'in %s d'],
  ['1w ago', 'in 1 w'],
  ['%sw ago', 'in %s w'],
  ['1m ago', 'in 1 mon'],
  ['%sm ago', 'in %s mon'],
  ['1 year ago', 'in 1 y'],
  ['%s years ago', 'in %s y'],
];

const DEFAULT_FORMAT_OPTION = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

timeago.register('en_short', function (number, index, totalSec) {
  return SHORT_LOCALE_CONFIG[index];
});

const _olderThanNDays = (timeObject, days) => timeObject > (Date.now() - days * 24 * 60 * 60 * 1000);
const _convertToDate = unsureObj => unsureObj.constructor.name === 'Date' ? unsureObj : new Date(unsureObj);

const _formatDate = (timeObject, formatOption, locale) => {
  formatOption = {...DEFAULT_FORMAT_OPTION, ...formatOption}
  return overrideShortMonth(new Intl.DateTimeFormat(locale, formatOption).format(timeObject))
}

const overrideShortMonth = dateString => {
  const parts = dateString.split(' ')
  parts[1] = parts[1].slice(0, 3);
  return parts.join(' ')
};

const _customFormatter = timeObject => ({locale, formatOption, timeAgoLocale}) => format => {
  switch (format) {
    case 'datetime': // if not provide option, use our custom logic
      return _formatDate(timeObject, formatOption, locale);
    case 'timeago': // use timeago lib
      return timeago.format(timeObject, timeAgoLocale);
    default: // use Intl.DateTimeFormat
      return _olderThanNDays(timeObject, 7) ? timeago.format(timeObject, timeAgoLocale) : _formatDate(timeObject, formatOption, locale);
  }
}

export function customFormat(time, {formatOption, locale = 'en-GB', timeAgoLocale = 'en_short'} = {}) {
  return curry(_customFormatter)(_convertToDate(time))({locale, formatOption, timeAgoLocale});
}

export default timeago;

/*
How to use:
   import timeago from 'shared/utils/timeago';
   timeago.format(time, 'en_short');

Or use our awesome `customFormat()`
  import { customFormat } from 'shared/utils/timeago';
- customFormat(time : <string or DateObject> [, options: <object>])() => our custom logic & date_format_option
  => e.g: `customFormat(stringTime, { formatOption: { month: 'long' } })()` to use long month instead of default short month
- customFormat(time : <string or DateObject> [, options: <object>])('timeago') => use timeago
  => e.g: `customFormat(stringTime, { timeAgoLocale: 'en_short'} )('timeago')`
  customFormat(time : <string or DateObject>, [date_format_option] : <Object>)('datetime') => use Intl.DateTimeFormat
  => e.g: `customFormat(stringTime, { locale: 'en-GB'} )('datetime')`
*/
