<template>
  <span class="search-autocomplete-highlight">
    <slot></slot>
  </span>
</template>
<script>
  export default {}
</script>
<style lang="scss" scoped>
  .search-autocomplete-highlight {
    background: none;
    font-weight: 400;
  }
</style>