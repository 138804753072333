import Vue from 'vue';
import { renderWrappedNodes } from "./utils";

export const MediaQueryProvider = Vue.extend({
  name: 'MediaQueryProvider',
  props: {
    queries: {
      type: Object,
      required: true,
    },
    fallback: [String, Array],
    wrapperTag: {
      type: String,
      default: 'span'
    },
    ssr: {
      type: Boolean,
    },
  },
  provide() {
    return { mediaQueries: this.mediaQueries };
  },
  data() {
    const mediaQueries = {};
    const { fallback, queries } = this;

    Object.keys(queries).forEach(key => { mediaQueries[key] = false; });

    if (fallback) {
      if (Array.isArray(fallback)) {
        fallback.forEach(key => { mediaQueries[key] = true; });
      } else {
        mediaQueries[fallback] = true;
      }
    }

    return { mediaQueries, matchers: [] };
  },
  beforeMount() {
    if (!this.ssr && !this.$nuxt) {
      this.bootstrap();
    }
  },
  mounted() {
    if (this.ssr || this.$nuxt) {
      this.bootstrap();
    }
  },
  beforeDestroy() {
    this.matchers.forEach(([matcher, listener]) => {
      matcher.removeListener(listener);
    });
  },
  methods: {
    bootstrap() {
      const { queries, mediaQueries } = this;

      for (const key in queries) {
        const query = queries[key];

        const matcher = window.matchMedia(query);
        const handler = (event) => {
          this.$emit(`change:${key}`, event);
          Vue.set(mediaQueries, key, event.matches);
        };
        // using deprecated method because of Safari's poor support for addEventListener
        matcher.addListener(handler);
        Vue.set(mediaQueries, key, matcher.matches);
        this.matchers.push([matcher, handler]);
      }
    },
  },
  render(h){
    return renderWrappedNodes(h, this.$slots.default, this.wrapperTag);
  }
});
