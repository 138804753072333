<template>
  <div class="reviewer-add-merch__merch-wrapper">
    <img v-bind:src="item.image_url" v-bind:alt="'Image for ' + item.title" class="reviewer-add-merch__merch-image">
    <div class="reviewer-add-merch__merch-text">
      <p class="reviewer-add-merch__merch-text__title">{{ item.title }}</p>
      <p class="reviewer-add-merch__merch-text__price">
        {{ parseInt(item.price).toLocaleString('en-US', { style: 'currency', currency: item.currency }) }}
        {{ item.currency }}
      </p>
    </div>
    <router-link
        v-bind:to="item.profileLink"
        class='reviewer-add-merch__view-link btn btn--brand'
        target="_blank"
    >
      View on Profile
    </router-link>
  </div>
</template>

<script>
  export default { props: ['item'] }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .reviewer-add-merch__merch-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include respond-to(notmobile) { margin: 0 110px; }
    @include respond-to(mobile) { margin: 0 40px; }
  }

  .reviewer-add-merch__merch-image {
    height: 390px;
    width: 390px;
    object-fit: cover;
    border-radius: 5px;

    @include respond-to(mobile) {
      height: 240px;
      width: 240px;
    }
  }

  .reviewer-add-merch__merch-text {
    color: black;
    text-align: left;
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .reviewer-add-merch__merch-text__title {
    font-weight: 600;
    line-height: 22px;
    font-size: 16px;
  }

  .reviewer-add-merch__merch-text__price {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
  }

  .reviewer-add-merch__view-link {
    width: 100%;
    margin-bottom: 16px;
  }
</style>
