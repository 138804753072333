<template>
  <WidgetWrapper v-if="hasAnyValue">
    <template v-slot:header>
      Socials
      <TooltipPopover v-bind="tooltipProps" v-if="confirmed">
        <template #icon><CircleIcon icon='check' class="social-card__check-icon"/></template>
      </TooltipPopover>
    </template>
    <template v-slot:body>
      <div class="social-card__row" v-if="socialData[socialName].url" v-for="socialName in Object.keys(socialData)">
        <iconify :icon="socialData[socialName].icon" class="social-card__icon"/>
        <div class="social-card__text">
          {{ socialData[socialName].followers }}
          <ExternalLink class="social-card__link" :href="socialData[socialName].url">{{ socialData[socialName].text }}</ExternalLink>
        </div>
      </div>
    </template>
  </WidgetWrapper>
</template>
<script>
import WidgetWrapper from 'rs/components/common/wrappers/widget_wrapper'
import CircleIcon from 'rs/components/common/icons/custom_circle_icon'
import TooltipPopover from 'rs/components/common/tooltip_popover';
import ExternalLink from 'rs/components/common/external_link';

export default {
  components: {WidgetWrapper, CircleIcon, TooltipPopover, ExternalLink},
  computed: {
    shopData() {
    return this.$store.getters['Shop/shopData'] || {}
    },
    socialData() {
      const social = this.shopData.social_networks || {}
      const result = {}
      for (const key in social) { // eg data: { facebook_url: xyz, facebook_followers: 123, youtube_url: abc, youtube_followers: 456 }
        const [name, suffix] = key.split('_')
        let value
        if (suffix === 'followers') {
          value = this.compactAndPluralize(social[key])
        } else {
          value = social[key]
        }
        result[name] = { // normalize to { facebook : { url: xyz, followers: 123 }, youtube: { url: abc, followers: 456 } }
          ...result[name],
          [suffix]: value,
        }
      }
      result['facebook'] = { ...result['facebook'], icon: 'ic:baseline-facebook', text: 'Facebook'}
      result['instagram'] = { ...result['instagram'], icon: 'ri:instagram-fill', text: 'Instagram'}
      result['youtube'] = { ...result['youtube'], icon: 'mdi:youtube', text: 'Youtube'}
      result['tiktok'] = { ...result['tiktok'], icon: 'ic:baseline-tiktok', text: 'TikTok'}
      result['twitter'] = { ...result['twitter'], icon: 'ant-design:twitter-circle-filled', text: 'Twitter'}
      result['pinterest'] = { ...result['pinterest'], icon: 'bi:pinterest', text: 'Pinterest'}
      return result
    },
    confirmed() {
      return this.shopData && this.shopData.confirmed_social_networks
    },
    tooltipProps() {
      return {
        show: true,
        name: 'social',
        tooltipArg: this.confirmed,
      }
    },
    hasAnyValue() {
      return Object.values(this.socialData).some(e => e && !!e.url)
    }
  },
  methods: {
    compactAndPluralize(followerCount) {
      if (followerCount) {
        return this.$sharedUtils.pluralize(this.$sharedUtils.compactNumber(followerCount), 'follower') + ' on'
      }
      return ''
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';

.social-card__row {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
}

.social-card__check-icon {
  margin-left: 8px;
  width: 16px;
  height: 16px;
}

.social-card__text {
  line-height: 26px;
}

.social-card__icon {
  color: $oldBrandColor;
  width: 18px;
  height: 18px;
}
</style>
