<template>
  <TabWrapper>
    <section class="top-products">
      <div v-if="topReviewedProducts.length > 0" class="top-products__title">Store bestsellers</div>
      <ProductCard v-for="(product, idx) in topReviewedProducts.slice(0,5)" :product="product" :key="idx"/>
      <router-link class="btn pf-primary-button btn-top-products" :to="allProductsRoute">
        {{ allProductsRoute.text || 'All Products' }}
      </router-link>
    </section>
  </TabWrapper>
</template>
<script>
import {mapState} from 'vuex'
import ProductCard from 'rs/components/pages/shop/others/_product_card';
import TabWrapper from 'rs/components/pages/shop/tabs/_tab_wrapper';

export default {
  components: {ProductCard, TabWrapper},
  computed: {
    ...mapState('Shop', ['widgetData', 'topReviewedProducts']),
    allProductsRoute() {
      return {
        text: 'View All Products',
        name: 'PageSearch',
        query: {
          shop_domain: this.widgetData.domain,
          platform: this.widgetData.platform,
          search_in_shop: true,
          min_transparency: 0,
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

$mobilePadding: 16px;
$desktopPadding: 20px;

.top-products {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.btn-top-products {
  margin-top: 4px;
  width: 290px;
  @include respond-to(mobile) {
    width: 100%;
  }
}

.top-products__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

</style>
