<template>
  <div class="profile-review-card edit-recommendation-card">
    <div class="erc__image-container" :class="{'erc__image-container--video': isVideo}">
      <img v-if="!isVideo && mainImageUrl" class="erc__recommendation-image" :src="mainImageUrl"
           :alt="`image for recommendation of product ${recommendation.product_title}`"/>
      <div v-else-if="isVideo" class="video-container">
        <iframe :src="youtubeSrc"></iframe>
      </div>
      <template v-if="!isEditingMainImage && mainImageUrl">
        <div class="erc__image-cta d-flex-center erc__image-cta--left" @click="removeMainImage"><span
          class="material-icons">delete</span></div>
        <div class="erc__image-cta d-flex-center erc__image-cta--right" @click="isEditingMainImage = true"><span
          class="material-icons">mode_edit</span></div>
      </template>
      <div v-else class="erc__image-container--edit d-flex-center">
        <div class="edit-box">
          <div class="edit-box__close material-icons" @click="isEditingMainImage = false">close</div>
          <div class="edit-box__input-wrapper">
            <input type="text" class="edit-box__input" v-model="tempMediaLink"
                   placeholder="Enter YouTube link or image URL here"/>
            <button class="btn pf-secondary-button edit-box__save" @click="saveMediaLink">
              <LoadingSpinner v-if="progressForMainImage !== 101"/>
              <template v-else>Save</template>
            </button>
          </div>
          <div class="d-flex-center">
            No URL?
            <input style="display: none" type='file' accept="image/*" ref="mainFile" @change="uploadMainImage"/>
            <span class="edit-box__upload-text" @click="onUploadMainImage">&nbsp; Upload image here</span>
          </div>
        </div>
      </div>
    </div>
    <div class="erc__left-container">
      <input type="text" v-model="formInput.title" class="erc__input-title" placeholder="Title"/>
      <textarea v-model="formInput.body"
                :maxlength="formInput.bodyMax"
                class="erc__input-body"
                name="body" id="review-body" cols="30" rows="10">
      </textarea>
      <input v-if="!recommendation.rating_count" type="text" class="erc__input-title" v-model="formInput.product_url"/>
      <div class="erc__product-wrapper">
        <div class="erc__product-wrapper--left">
          <img v-if="progressForProductImage === 101" :src="productImageUrl" class="erc__product-image"/>
          <div class="erc__product-image" v-else>
            <LoadingSpinner/>
            <div class="erc__product-image erc__product-image-progress--text d-flex-center">
              {{ progressForProductImage }}
            </div>
          </div>
          <input
            type='file' accept="image/*"
            class="erc__product-image erc__input-product-image"
            ref='productFile' @change="uploadProductImage"/>
        </div>
        <div class="erc__product-wrapper--right">
          <input v-model="formInput.product_title" class="erc__input-product-title" placeholder="Product Title"/>
          <CurrencyDropdown :currencyCode="formInput.product_currency" @change="e => formInput.product_currency = e"
                            class="erc__input-product-currency"/>
          <input v-model="formInput.product_price" class="erc__input-product-price" type="number" step="0.01" min="0"/>
          <input v-model="formInput.product_promo_code" class="erc__input-product-promo" placeholder="your coupon"/>
        </div>
      </div>
    </div>
    <div class="erc__right-container">
      <div class="erc__cta-publish" :class="{'brand-color': published}">
        <span>{{ published ? 'Published' : 'Draft' }}</span>
        <Toggle round :active="published" @toggle="onPublishToggle" :radius='99'/>
      </div>
      <button v-if="hasChanged" class="btn pf-secondary-button erc__cta-save" @click="saveRecommendation">
        <LoadingSpinner v-if="saving"/>
        <template v-else>Save</template>
      </button>
      <button class="btn pf-danger-button erc__cta-delete" @click="deleteRecommendation">Delete</button>
    </div>
  </div>
</template>
<script>
import StarRating from 'rs/components/common/star_rating'
import ReviewReaction from 'rs/components/common/review_reaction'
import SimpleRating from 'rs/components/common/simple_rating';
import LoadingSpinner from 'rs/components/common/loading_spinner';
import CurrencyDropdown from 'rs/components/common/currency_dropdown_simple';
import Toggle from 'rs/components/common/toggle';
import {mapGetters} from "vuex";
import axios from 'shared/utils/axios'
import {youtubeHelper} from 'shared/utils/youtube_helper'
import {
  isImage,
  mediaUploader,
  isOverSize,
  getPresignedData,
} from 'shared/utils/upload_media_files';

export default {
  components: {StarRating, ReviewReaction, SimpleRating, LoadingSpinner, Toggle, CurrencyDropdown},
  props: ['recommendation'],
  computed: {
    ...mapGetters('SharedCurrentReviewer', ['currency', 'exchangeRates']),
    youtubeSrc() {
      if (this.formInput.media_url.startsWith('http')) {
        return this.formInput.media_url
      } else { // it's an id
        return youtubeHelper.embedId(this.formInput.media_url)
      }
    },
    productImageUrl() {
      return this.formInput.product_image_url
    },
    mainImageUrl() {
      return this.formInput.media_url
    },
  },
  data() {
    return {
      unwatchFormInput: null,
      shouldShowAllBody: false,
      formInput: {
        id: this.recommendation.id,
        review_id: this.recommendation.review_id,
        title: this.recommendation.title,
        body: this.recommendation.body,
        media_url: this.recommendation.media_url,
        image_s3_key: null,
        product_title: this.recommendation.product_title,
        product_url: this.recommendation.product_url,
        product_image_url: this.recommendation.product_image_url,
        product_image_s3_key: null,
        product_promo_code: this.recommendation.product_promo_code,
        product_currency: this.recommendation.product_currency,
        product_price: this.recommendation.product_price,
        source: this.recommendation.source,
      },
      tempMediaLink: '',
      isVideo: this.recommendation.media_url && !this.recommendation.media_url.startsWith('http'), // assumption: backend return image_url = some youtube_id if it's a video
      published: this.recommendation.published,
      isEditingMainImage: false,
      hasChanged: false,
      saving: false,
      progressForProductImage: 101,
      progressForMainImage: 101,
      newProductImageUrl: '',
      newMediaUrl: '',
      presignedData: null,
    }
  },
  methods: {
    async saveMediaLink() {
      const embeddedYoutubeLink = youtubeHelper.embedYoutubeUrl(this.tempMediaLink)
      this.isVideo = !!embeddedYoutubeLink;
      this.formInput.media_url = embeddedYoutubeLink || this.tempMediaLink;
      this.isEditingMainImage = false;
    },
    async uploadProductImage() {
      const file = await this.validateAndPresignFile(this.$refs.productFile);
      if (!file) return;
      const uploader = mediaUploader(this.presignedData, this.updateProgressForProductImage);
      try {
        this.progressForProductImage = 0;
        const result = await uploader(file);
        this.setProductImageResult(result);
      } catch (e) {
        this.$alertError('Cannot upload image, please try a different file')
      } finally {
        this.progressForProductImage = 101
      }
    },
    async uploadMainImage() {
      const file = await this.validateAndPresignFile(this.$refs.mainFile);
      if (!file) return;
      const uploader = mediaUploader(this.presignedData, this.updateProgressForMainImage)
      try {
        this.progressForMainImage = 0;
        const result = await uploader(file);
        this.setMainImageResult(result)
      } catch (e) {
        this.$alertError('Cannot upload image, please try a different file')
      } finally {
        this.progressForMainImage = 101
        this.isEditingMainImage = false;
      }
    },
    async validateAndPresignFile(ref) { // component specific
      const file = ref.files.item(0);
      if (!isImage(file)) {
        this.$alertError('Only Image files are allowed')
        return;
      }
      if (isOverSize(file)) {
        this.$alertError('File is too large, Please use maximum 10MB for images and 100MB for videos')
        return;
      }
      if (!this.presignedData) {
        this.presignedData = await getPresignedData(file)
      }
      if (!this.presignedData) {
        this.$alertError('Cannot upload image at the moment. Please try again later.')
        return;
      }
      return file
    },
    async saveRecommendation() {
      try {
        const formData = this.buildFormAndValidate()
        this.saving = true;
        const res = await axios.post('/profile/recommendations', formData)
        this.unwatchFormInput()
        this.formInput = res.data
        await this.$store.dispatch('PrivateProfile/updateRecommendationOnSuccess', {data: res.data})
        this.$nextTick(() => { //unwatch for a swift moment otherwise `this.hasChanged = true`
          this.unwatchFormInput = this.$watch('formInput', this.formInputWatcher, {deep: true})
        })
        this.$alertSuccess('Successfully updated recommendation')
      } catch (err) {
        if (err.message === 'validation') {
          this.$alertError(err.cause)
        } else {
          if (err.response && err.response.data && err.response.data.error) {
            this.$alertError(err.response.data.error)
          } else {
            this.$alertError(err.message || 'Something went wrong. Please try again later')
          }
        }
      } finally {
        this.saving = false;
        this.hasChanged = false;
      }
    },
    updateProgressForProductImage(progressEvent) {
      this.progressForProductImage = Math.round((100 * progressEvent.loaded) / progressEvent.total);
    },
    updateProgressForMainImage(progressEvent) {
      this.progressForMainImage = Math.round((100 * progressEvent.loaded) / progressEvent.total);
    },
    async deleteRecommendation() {
      try {
        await axios.delete('/profile/recommendations/' + this.recommendation.id)
        await this.$store.dispatch('PrivateProfile/updateRecommendationOnSuccess', {
          data: this.recommendation,
          deleted: true
        })
        this.$alertSuccess('Successfully deleted recommendation')
      } catch (e) {
        this.$alertError('Cannot delete this recommendation, please try again later')
      }
    },
    setMainImageResult(result = {}) {
      this.formInput.media_url = result.url || '';
      this.formInput.image_s3_key = result.key || '';
    },
    setProductImageResult(result = {}) {
      this.formInput.product_image_url = result.url || '';
      this.formInput.product_image_s3_key = result.key || '';
    },
    removeMainImage() {
      this.setMainImageResult()
      this.isEditingMainImage = true;
    },
    onUploadMainImage() {
      this.$refs.mainFile.click()
    },
    async onPublishToggle(bool) {
      const pathParam = bool ? 'publish' : 'unpublish'
      this.published = bool // assume success request
      try {
        await axios.put(`/profile/recommendations/${this.recommendation.id}/${pathParam}`)
        await this.$store.dispatch('PrivateProfile/updateRecommendationOnSuccess', {
          data: this.formInput,
          published: bool
        })
        this.$alertSuccess('Recommendation is successfully ' + (bool ? 'published' : 'unpublished'))
      } catch {
        this.$alertError('Cannot publish this recommendation. Please try again later')
        this.published = !bool // revert when failed
      }
    },
    buildFormAndValidate() {
      const formData = new FormData();
      Object.keys(this.formInput).forEach(key => {
        formData.append(key, this.formInput[key] === null ? '' : this.formInput[key]);
        if (key === 'product_promo_code' && !!this.formInput[key] && (this.formInput[key].length < 4 || this.formInput[key].length > 255)) {
          throw new Error('validation', {cause: 'Coupon must be between 4 and 255 characters'})
        }
        if (key === 'product_price' && this.formInput[key] < 0) {
          throw new Error('validation', {cause: 'Price must be equal or greater than 0'})
        }
        if (key === 'product_title' && this.formInput[key].length < 1) {
          throw new Error('validation', {cause: 'Product title cannot be empty'})
        }
        if (key === 'body' && this.formInput[key].length < 1) {
          throw new Error('validation', {cause: 'Body cannot be empty'})
        }
      })
      return formData
    },
    formInputWatcher() {
      this.hasChanged = true
    }
  },
  mounted() {
    this.unwatchFormInput = this.$watch('formInput', this.formInputWatcher, {deep: true})
  },
}
</script>
<style lang="scss" scoped>
@import 'rs/components/pages/private_profile/_edit_recommendation_card.scss';
// file is too long to include in 1 file
</style>
