<!-- Recursive Itemline, this component is calling itself with different variants if
- it is in selected stack
- and has children.
 -->

<template>
  <div class="item-line" :class="{'item-line--not-padded': !shouldShow}">
    <div class="item-line__content" :class="{'item-line__content--selected': isSelected}"
         v-show="shouldShow">
      <span v-show="shouldShowShrinkButton" @click="shrink"
            class="item-line__shrink-icon material-icons">arrow_back_ios</span>
      <span class="item-line__text" @click="click">{{ item.name }}</span>
      <span v-show="shouldShowExpandButton"
            @click="expand"
            class="item-line__expand-icon material-icons">
        arrow_forward_ios
      </span>
    </div>
    <ItemLine
        v-for="(childID,idx) in item.children"
        :item="items[childID]" :key="childID"
        @select="e => $emit('select', e)"
    >
    </ItemLine>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ItemLine",
  props: ['item'],
  computed: {
    ...mapState('Search', {
      items: state => state.categories,
      selectedStack: state => state.selectedStack,
      showChildrenFor: state => state.showChildrenFor,
      selectedID: state => state.searchParams.category_id,
      categoriesLevelOneIDs: state => state.categoriesLevelOneIDs,
    }),
    isSelected() {
      if (!this.item.id) {
        return !this.selectedID
      } else {
        return this.selectedID === this.item.id
      }
    },
    shouldShowShrinkButton() {  // hide if any of these matches.
    // different logic for different states: for each select/not-select => there are expanding/not expanding
    // Don't simplify the if/else here because it's much harder to change.
      if (!this.item.id) return false;

      if (this.item.parent_id) {               // means it's not level 1
        if (this.items[this.item.parent_id].children && this.items[this.item.parent_id].children.length <= 1) {
          // there is no siblings in direct parent
          return false
        }
      } else if (this.categoriesLevelOneIDs.length <= 1) { // level 1 && there's only 1 level 1
        return false
      }

      if (!this.selectedID) {               // not select cat
        if (!this.showChildrenFor) {              // when NOT expanding
          return false
        } else {                                  // when expanding
          if (!this.isInStack(this.item)) {
            return false
          }
        }
      } else {                              // Select Cat
        if (!this.showChildrenFor) {              // when NOT expanding
          if (this.selectedStack.length <= 1) {  // when showing level 1 , hide icon
            if (this.item.level === 1) {
              return false
            }
          } else {
            if (this.item.level >= this.items[this.selectedID].level) {
              return false
            }
          }
        } else {                                  // when expanding
          if (this.item.level > this.items[this.showChildrenFor].level) {
            return false
          }
        }
      }

      return true;
    },
    shouldShowExpandButton() {
      return this.item.id
          && !this.item.is_deepest
          && this.item.id !== this.showChildrenFor
          && !this.isInParents(this.item)
    },
    shouldShow() {
      const lastItemId = this.selectedStack[this.selectedStack.length - 1]
      if (!this.item.id) { // for "All" category
        return true;
      } else if (this.selectedStack.length === 0) { // if no cat is selected, show all level 1
        return this.item.level === 1
      } else if (this.selectedStack.length > 0) {
        if (!this.showChildrenFor) { // when not expand to show children => show last in stack, level 1 in stack & direct parent & siblings
          return this.item.id === lastItemId  // last in stack
              || this.item.parent_id === this.items[lastItemId].parent_id // last in stack & siblings
              || (this.item.level === 1 && this.isInStack(this.item)) // level 1  in stack
              || this.item.id === this.items[lastItemId].parent_id  // direct parent
        } else { // when expanded, show: level 1 in stack, all expanded children, and direct parent
          return (this.item.level === 1 && this.isInStack(this.item))  // level 1 && in stack
              || (this.item.parent_id === this.showChildrenFor)  //  all children of selected to show
              || (this.item.id === this.showChildrenFor)  // direct parent
        }
      }
      return false;
    },
  },
  methods: {
    click() {
      this.$store.commit('Search/setSelectedCategoryID', this.item.id)
      this.$emit('select', this.item.id)
    },
    shrink() {
      this.$store.commit('Search/setShowChildrenFor', this.item.parent_id)
      this.$store.commit('Search/setSelectedStack', this.item.parent_id)
    },
    expand() {
      this.$store.commit('Search/setSelectedStack', this.item.id)
      this.$store.commit('Search/setShowChildrenFor', this.item.id)
    },
    isInStack(item) {
      return this.selectedStack[item.level - 1] === item.id
    },
    isInParents(item) { // in stack and not last
      return this.isInStack(item) && this.selectedStack[this.selectedStack.length-1] !== item.id
    },
    hasChildren(item) {
      return item.children && item.children.length > 0;
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.item-line {
  cursor: pointer;
  padding-left: 12px;
  position: relative;
}

.item-line--not-padded {
  margin-left: -12px;
}

.item-line__shrink-icon {
  position: absolute;
  left: 0;
  width: 24px;
}

.item-line__shrink-icon,
.item-line__expand-icon {
  font-size: 13px;
  line-height: 24px;
}

.item-line__expand-icon {
  margin-left: 4px;
  width: 14px;
}
.item-line__content {
  display: inline-flex;
  width: 100%;
  font-weight: 400;
}
.item-line__text {
  width: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.item-line__content--selected {
  color: #000000;
}

</style>
