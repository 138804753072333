<template>
  <section class="promotion-feed-cards">
    <SearchProducts />
    <QuickSignIn v-if="!isLoggedIn"/>
    <FindPeople />
  </section>
</template>

<script>
  import SearchProducts from 'rs/components/common/feed_cards_logged_out/search_products'
  import QuickSignIn from 'rs/components/common/feed_cards_logged_out/quick_sign_in'
  import FindPeople from 'rs/components/common/feed_cards_logged_out/find_people'

  export default {
    components: { SearchProducts, QuickSignIn, FindPeople },
    
  computed: {
    isLoggedIn() {
      return window.isReviewerLoggedIn;
    },
  },
  }
</script>

<style lang="scss" scoped>

  .promotion-feed-cards {
    margin-top: 20px;
  }
  
</style>
