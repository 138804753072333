<template>
  <ExternalLink v-if='shopData.custom_domain && shopData.external_url' class="link-card__wrapper" :href="shopData.external_url">
    <div class="link-card__link-wrapper">
      <div class="link-card__link">{{ shopData.custom_domain }}</div>
      <div class="link-card__description">Visit store</div>
    </div>
    <div class="link-card__button-wrapper">
      <iconify class='link-card__icon' icon="mi:chevron-right" width="28" height="28"/>
    </div>
  </ExternalLink>
</template>
<script>
import ExternalLink from "rs/components/common/external_link";
import { storeName } from "rs/store/store_utils/shared.js";

export default {
  components: {ExternalLink},
  computed: {
    storeName() {
      return storeName(this.$route)
    },
    shopData() {
      return this.$store.getters[this.storeName + '/shopData'] || {}
    },
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.link-card__wrapper {
  display: flex;
  background-color: white;
  border: $newBorderStyle;
  border-radius: 12px;
  min-width: 300px;
  font-size: 16px;
  line-height: 24px;
  padding: 20px 20px 20px 20px;
  @include respond-to(notmobile) {
    max-width: 300px;
  }
  text-decoration: none;
}

.link-card__wrapper:hover {
  background: linear-gradient(180deg, #339999 0%, #2D8396 100%),
  linear-gradient(0deg, #E7E7E7, #E7E7E7);

  .link-card__description {
    color: #E2E2E2;
  }

  .link-card__link,
  .link-card__icon {
    color: white;
  }
}

.link-card__link-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 16px;
  line-height: 24px;
  min-width: 0;
}

.link-card__link {
  color: black;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link-card__description {
  color: #555555;
}

.link-card__button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 80px;
}

</style>
