<template>
  <div class="category-wrapper" :class="{'category-wrapper--hidden' : level === 4}">
    <div class="category-line-wrapper"
                 :class="{
                    'category-line-wrapper--direct-child' : isDirectChild(category, parentCategory),
                    'category-line-wrapper--normal'       : level !== 2,
                    'category-line-wrapper--last-child'   : isLastChild,
                 }"
                 >
      <router-link class="category-line__name"
                   :to="linkToParams(category)"
                   :class="{
                      'category-line__name--direct-child' : isDirectChild(category, parentCategory),
                      'category-line__name--normal'       : level !== 2
                   }">
        {{ name }}
      </router-link>
      <router-link v-if="!deepest" class="material-icons category-line__name-arrow" :to="linkToParams(category)">arrow_forward_ios</router-link>
    </div>
    <CategoryChildren
        v-if="!deepest"
        :linkToParams="linkToParams"
        :categories="getChildren()"
    />
  </div>
</template>
<script>
import {mapState} from 'vuex'
import CategoryChildren from 'rs/components/pages/categories/_category_children'
import {categoriesSortFunctionByName} from 'rs/store/store_utils/search_utils';

export default {
  props: ['category', 'linkToParams', 'isLastChild'],
  components: {CategoryChildren},
  computed: {
    ...mapState('Category', {
      categoriesMap: state => state.categoriesMap,
      parentCategory: state => state.category,
    }),
    name() {
      return this.category.name
    },
    deepest() {
      return this.category.is_deepest
    },
    level() {
      return this.category.level
    },
  },
  methods: {
    getChildren() {
      const children = this.categoriesMap[this.category.id].children
      if (!children) {
        return []
      }
      const result = children.map(id => this.categoriesMap[id])
      result.sort(categoriesSortFunctionByName)
      return result
    },
    isDirectChild(toCheck, base) {
      if (toCheck.level) {
        return toCheck.level === base.level + 1
      }
      return false
    },
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.category-wrapper {
  @include respond-to(notmobile) {
    border-bottom: 1px solid $lightGreenColor;

    &:last-child {
      border-bottom: none;
    }
  }
}

.category-wrapper--hidden {
  @include respond-to(notmobile) {
    display: none;
  }
}

.category-line-wrapper {
  display: flex;
  justify-content: space-between;
  line-height: 24px;
  color: $primaryFontColor;
  padding: 8px 0;
  text-decoration: none;

  @include respond-to(notmobile) {
    justify-content: flex-start;
  }
}

.category-line-wrapper--direct-child {
  padding: 20px 0;
  color: $newBrandColor;
  width: fit-content;

  @include respond-to(mobile) {
    width: 100%;
    border-bottom: 1px solid $lightGreenColor;
  }
}
.category-line-wrapper--last-child {
  border-bottom: none;
}

.category-line-wrapper--normal {
  @include respond-to(mobile) {
    color: $primaryFontColor;
    border-bottom: none;
    padding: 8px 0;
  }
}

.category-line__name {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-decoration: underline;
}

.category-line__name--direct-child {
  font-weight: 700;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  @include respond-to(notmobile) {
    font-size: 20px;
  }
}

.category-line__name--normal {
  @include respond-to(mobile) {
    font-weight: 400;
    text-decoration: underline;
    color: $primaryFontColor;
  }
}

.category-line__name-arrow {
  line-height: 24px;
  font-size: 16px;
  margin-left: 8px;
  color: $newBrandColor;
  text-decoration: none;

  @include respond-to(notmobile) {
    display: none;
  }
}

</style>
