<template functional>
  <svg  v-on="data.listeners" :class="data.class" v-bind="data.attrs" xmlns="http://www.w3.org/2000/svg" :width="props.size" :height="props.size" :viewBox="`0 0 ${props.size} ${props.size}`" fill="none">
    <g filter="url(#filter0_d_1288_18018)">
      <mask id="mask0_1288_18018" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="11" y="10" width="64"
            height="64">
        <circle cx="43" cy="42" r="32" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask0_1288_18018)">
        <path d="M75 10H11V74H75V10Z" fill="url(#paint0_linear_1288_18018)"/>
        <path
          d="M42.2 23.1992L61 33.1992L61.4 33.9992L61.6667 35.332L71.8 69.1992L31.8 76.7992L25 53.9992L27.4 33.1992L42.2 23.1992Z"
          fill="url(#paint1_radial_1288_18018)"/>
      </g>
    </g>
    <path
      d="M28.2796 34.0863L42.9996 43.3839L57.7196 34.0863L42.9996 24.7888L28.2796 34.0863ZM61.3996 34.0863V52.6814C61.3996 53.6677 61.0119 54.6137 60.3218 55.3111C59.6316 56.0086 58.6956 56.4004 57.7196 56.4004H28.2796C27.3036 56.4004 26.3676 56.0086 25.6775 55.3111C24.9873 54.6137 24.5996 53.6677 24.5996 52.6814V34.0863C24.5996 32.7289 25.3172 31.5574 26.3844 30.9066L42.9996 20.4004L59.6148 30.9066C60.682 31.5574 61.3996 32.7289 61.3996 34.0863Z"
      fill="white"/>
    <defs>
      <filter id="filter0_d_1288_18018" x="0.808236" y="0.827412" width="84.3835" height="84.3835"
              filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                       result="hardAlpha"/>
        <feOffset dy="1.01918"/>
        <feGaussianBlur stdDeviation="5.09588"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1288_18018"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1288_18018" result="shape"/>
      </filter>
      <linearGradient id="paint0_linear_1288_18018" x1="43" y1="10" x2="43" y2="74" gradientUnits="userSpaceOnUse">
        <stop stop-color="#339999"/>
        <stop offset="1" stop-color="#2D8396"/>
      </linearGradient>
      <radialGradient id="paint1_radial_1288_18018" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(51.1434 46.9235) rotate(106.124) scale(55.249 39.4636)">
        <stop stop-color="#395F5F"/>
        <stop offset="1" stop-color="#278888" stop-opacity="0"/>
      </radialGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    size: {
      type: Number,
      default: 80,
    }
  }
}
</script>
