<template>
  <div class='reviewer-event'>
      <FeedCardReviewerHeader :feedEvent="feedEvent" :reviewer="feedEvent.event_owner" :review="feedEvent.event_item" :description="headerDescription" :descriptionHtml="descriptionHtml"/>
      <component :is="displayBody" v-bind="bodyProps"></component>
      <component :is="displayFooter" :feedEvent="feedEvent" v-bind="footerProps"></component>
  </div>
</template>

<script>
import FeedCardReviewerHeader from 'rs/components/common/feed_cards/_reviewer_header'
import SingleButtonFooter from 'rs/components/common/feed_cards/footers/_single_button_footer'
import ReviewerAddInterestBody from 'rs/components/common/feed_cards/bodies/_reviewer_add_interest'
import ReviewerUpdateYoutubeBody from 'rs/components/common/feed_cards/bodies/_reviewer_update_youtube'
import ReviewerAddMerchBody from 'rs/components/common/feed_cards/bodies/_reviewer_add_merch'
import ReviewerAddInstagramBody from 'rs/components/common/feed_cards/bodies/_reviewer_add_instagram'

const FEED_EVENT_TYPE_DESCRIPTIONS = {
  reviewer_update_interest: 'Added interests to profile.',
  reviewer_update_youtube_channel: 'Added Youtube channel to profile.',
  reviewer_adds_merch: 'Added new merch.',
  reviewer_adds_instagram: 'Added new Instagram feed.', // not used, replace with custom descriptionHtml
}

export default {
  components: { FeedCardReviewerHeader },
  props: ['feedEvent'],

  computed: {
    headerDescription() {
       return FEED_EVENT_TYPE_DESCRIPTIONS[this.feedEvent.event_type] || ''
    },
    descriptionHtml() {
      if (this.feedEvent.event_type === 'reviewer_adds_instagram') {
        return `
          Added <a target="_blank" rel='nofollow noopener' href="https://www.instagram.com/${this.feedEvent.event_owner.instagram_handle}"/>
          ${this.feedEvent.event_owner.instagram_handle}</a> Instagram to profile.`
      } else return null
    },
    displayBody(){
      switch(this.feedEvent.event_type){
        case 'reviewer_update_interest':
          return ReviewerAddInterestBody
        case 'reviewer_update_youtube_channel':
          return ReviewerUpdateYoutubeBody
        case 'reviewer_adds_merch':
          return ReviewerAddMerchBody
        case 'reviewer_adds_instagram':
          return ReviewerAddInstagramBody
      }
    },
    bodyProps(){
      switch(this.displayBody){
        case ReviewerAddInterestBody:
          return { categories: this.feedEvent.event_owner.categories }
        case ReviewerUpdateYoutubeBody:
          return { channel_url: `https://youtube.com/channel/${this.feedEvent.event_owner.yt_channel_id}`}
        case ReviewerAddMerchBody:
        case ReviewerAddInstagramBody:
          return { feedEvent: this.feedEvent }
      }
    },
    displayFooter(){
      const type = this.feedEvent.event_type
      switch(type){
        case 'reviewer_update_interest':
        case 'reviewer_update_youtube_channel':
          return SingleButtonFooter
      }
    },
    footerProps(){
      switch(this.feedEvent.event_type){ // cases: double & single buttons but with different props
        case 'reviewer_update_interest':
          return { divider: true, text: 'View Profile', href: `/profile/${this.feedEvent.event_owner.encoded_id}` }
        case 'reviewer_update_youtube_channel':
          return { text: 'View Latest Videos', href: `https://youtube.com/channel/${this.feedEvent.event_owner.yt_channel_id}`}
      }
    },
  },
}
</script>
