<template>
  <div>
    <div class="message__heading" v-if="heading">
      <iconify :icon="heading.icon" height="24"/>
      <div :class="heading.styleClasses">{{ heading.text }}</div>
    </div>
    <div v-else>
      {{ message.text }}
    </div>
    <ProposalCard v-if="message.proposal" :proposal="message.proposal" :canRespond="canRespond"/>
  </div>
</template>
<script>
import {getAutoMessageHeadings} from 'shared/vue_components/arbitration/helpers'
import ProposalCard from 'shared/vue_components/arbitration/components/_proposal_card.vue'

export default {
  props: ['message', 'senderPoV'],
  components: {ProposalCard},
  inject: ['userParticipantId', 'dispute'],
  computed: {
    heading() {
      return getAutoMessageHeadings(this.message.text, this.message.sender.participant_role, this.dispute.user_role, this.message.sender.name)
    },
    canRespond() {
      return this.userParticipantId !== this.message.sender.participant_id && this.dispute.user_role !== 'arbitrator'
    },
  }
}
</script>

<style lang="scss" scoped>
.message__heading {
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
  line-height: 24px;
  & > * { // for icon not being squeezed if text is long
    min-width: 24px;
  }
}
</style>
