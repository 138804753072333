import axios from 'shared/utils/axios'

const RS_TRACKING_KEY = '_rs_referrer'

const _getUniqueId = () => (Math.random() * 1e16).toString(36).substring(0, 10)

const _getRsCookie = cookieName => {
  const name = cookieName + "=";
  const cookiesArray = document.cookie.split(';');
  cookiesArray.forEach(cookie => {
    cookie = cookie.trim()
    if (cookie.indexOf(cookieName) == 0) {
      return cookie.substring(name.length, cookie.length)
    }
  })
  return null
}

const _shortenReferrer = () => {
  let referrer = document.referrer || '';
  referrer = referrer.replace(/^https{0,1}:\/\//, '')
  return referrer.split('/')[0]
}

const _isInternalReferral = (internalPath) => internalPath || (document.referrer.length > 0 && document.referrer.indexOf(window.location.origin) === 0);

const _getCookieData = () => {
  const trackingData = localStorage.getItem(RS_TRACKING_KEY)
  if (trackingData && trackingData.length > 0) {
    return JSON.parse(trackingData)
  }
}

const _shortenPath = (newPath= null) => {
  if (!newPath) {
    return window.location.pathname.replace('/reviews/', '').replace('collections/', '').replace('products/', '')
  } else {
    return newPath.replace('/reviews/', '').replace('collections/', '').replace('products/', '')
  }
}

const _setupNewTracking = (existingId, firstReferrer) => {
  const rsTrackingData = {
    created_at: Date.now(),
    referrer: _shortenReferrer(),
    id: existingId || _getUniqueId(),
    journey: [ _shortenPath() ],
    landing_page_params: window.location.search,
    first_referrer: firstReferrer === undefined ? _shortenReferrer() : firstReferrer
  }
  localStorage.setItem(RS_TRACKING_KEY, JSON.stringify(rsTrackingData))
}

function _storeReviewerJourney(newPath = null) {
  if (_getRsCookie('jdgm_platform_cookie_bar_acceptance') === 'false') return;

  const trackingData = _getCookieData();

  let existingId, firstReferrer;
  if (trackingData) {
    existingId = trackingData.id;
    firstReferrer = trackingData.first_referrer;
  }
  if (trackingData && _isInternalReferral(newPath)) {
    trackingData.journey.push(_shortenPath(newPath))
    localStorage.setItem(RS_TRACKING_KEY, JSON.stringify(trackingData))
  } else {
    _setupNewTracking(existingId, firstReferrer)
  }
}

function _recordClick(clickData){
  axios.post('/reviews/click', clickData);
}


const state = {
  pageviewId: _getUniqueId(),
}

const mutations = {}

const actions = {
  setupClickTracking({dispatch}, payload){
    _storeReviewerJourney();
  },
  trackExternalClick({commit, state, dispatch}, payload) {
    const referralURL = new URL(payload.href);
    referralURL.search = referralURL.search + '&jdgm_ref_id=' + state.pageviewId;
    const clickData = {
      shop_id: payload.shopId,
      tracking_data: _getCookieData(),
      referral: referralURL.toString(),
      referral_page: _shortenPath(),
    }
    if (payload.productId && payload.productId !== payload.shopId ) {
      clickData.product_id = payload.productId
    }

    if (_getRsCookie('jdgm_platform_cookie_bar_acceptance') !== 'false'){
      clickData['pageview_id'] = state.pageviewId;
    }
    _recordClick(clickData)
  },
  storeReviewerJourney({}, payload) {
    _storeReviewerJourney(payload)
  }
}


export default {
  state: state,
  mutations: mutations,
  actions: actions,

  namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
}
