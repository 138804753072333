<template>
  <WidgetWrapper v-if="hasAnyValue">
    <template v-slot:header>
      Contact
      <TooltipPopover v-bind="tooltipProps" v-if="confirmed">
        <template #icon><VerifiedBadge space/></template>
      </TooltipPopover>
    </template>
    <template v-slot:body>
      <div class="contact-card__row" v-if="email">
        <iconify class="contact-card__icon" icon="material-symbols:mail"/>
        <ExternalLink class="contact-card__item" :href="'mailto:' + email">{{ email }}</ExternalLink>
      </div>
      <div class="contact-card__row" v-if="phones.length > 0">
        <iconify class="contact-card__icon" icon="ic:baseline-phone"/>
        <div class="contact-card__item">{{ phones.join(', ') }}</div>
      </div>
      <div class="contact-card__row" v-if="responseTime">
        <iconify class="contact-card__icon" icon="material-symbols:speed"/>
        <div class="contact-card__item">Store aims to reply in less than {{ responseTime }}</div>
      </div>
      <div class="d-flex-column gap-3 mt-2">
        <ExternalLink v-if="contactPageUrl" class="btn pf-primary-button" :href="contactPageUrl">More Contact
          Details</ExternalLink>
        <ExternalLink v-if="whatsappUrl" class="btn contact-card__whatsapp-button" :href="whatsappUrl">
          <iconify icon="ic:baseline-whatsapp" color="white" width="24" height="24"/>
          Chat on WhatsApp
        </ExternalLink>
      </div>
    </template>
  </WidgetWrapper>
</template>
<script>
import WidgetWrapper from 'rs/components/common/wrappers/widget_wrapper'
import VerifiedBadge from 'rs/components/common/verified_badge'
import TooltipPopover from 'rs/components/common/tooltip_popover';
import ExternalLink from 'rs/components/common/external_link';
import { storeName } from "rs/store/store_utils/shared.js";
import {shopResponseTime} from 'rs/components/pages/shop/helpers'

export default {
  components: {WidgetWrapper, VerifiedBadge, TooltipPopover, ExternalLink},
  computed: {
    storeName() {
      return storeName(this.$route)
    },
    shopData() {
      return this.$store.getters[this.storeName + '/shopData']
    },
    confirmed() {
      return this.shopData && this.shopData.confirmed_contact
    },
    tooltipProps() {
      return {
        show: true,
        name: 'contact',
        tooltipArg: this.confirmed
      }
    },
    contactInfo() {
      return this.shopData && this.shopData.contact_info
    },
    email() {
      return this.contactInfo && this.contactInfo.email
    },
    phones() {
      return this.contactInfo && this.contactInfo.phones || []
    },
    responseTime() { return shopResponseTime(this.contactInfo) },
    contactPageUrl() {
      return this.contactInfo && this.contactInfo.contact_page_url
    },
    whatsappUrl() {
      return this.contactInfo && this.contactInfo.whatsapp_url
    },
    hasAnyValue() {
      return this.email || this.phones.length > 0 || this.responseTime || this.contactPageUrl || this.whatsappUrl
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.contact-card__icon {
  width: 18px;
  height: 18px;
  margin-top: 4px;
  color: $oldBrandColor;
}

.contact-card__body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contact-card__row {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
}

.contact-card__item {
  flex: 1
}

.contact-card__whatsapp-button.contact-card__whatsapp-button {
  border-radius: 25px;
  background: linear-gradient(65deg, #338E2D 0%, #50A74A 100%);
  height: 40px;
  color: white;
  padding: 6px 20px;
  border: transparent;
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 8px;

  &:hover, &:focus, &:active {
    color: white;
    border: transparent;
    outline: none;
    background: linear-gradient(65deg, #338E2D 0%, #50A74A 100%);
  }
}
</style>
