<template>
  <MatchMedia v-slot="{mobile}">
    <section class="product-reviews" v-if="isLoadingResult">
      <LoadingSpinner class="product-reviews__loading-spinner"/>
    </section>
    <section class="product-reviews" v-else>
      <section class="product-reviews__reviews" v-if="reviews.length > 0">
        <div class="product-reviews__review-card" v-for="(review, idx) in reviews">
          <ReviewCard :review="review" :shouldHideReviewer=false :imageLargeSize=true
                      @openGallery="(v) => openGallery({review, ...v})">
          <div class="review-card__product-line" v-if="productLinks[idx]" slot="product">{{productLinks[idx].decoration.prefix}}
            <component v-bind="productLinks[idx].linkObj" v-html="review.product_title"></component>
            {{productLinks[idx].decoration.suffix}}
          </div>
          </ReviewCard>
        </div>
        <Pagination class="product-reviews__pagination" :currentPage="widgetData.current_page"
                    :minItem="mobile ? 3 : 8" :displayRange="mobile ? 1 : 3"
                    :totalPage="widgetData.total_pages" @onChange="handlePageChange"
        />
      </section>
      <section class="product-reviews__reviews" v-else>
        <p class="product-reviews__reviews-no-reviews-text"> No reviews found </p>
      </section>
    </section>
  </MatchMedia>
</template>
<script>
import ReviewCard from 'rs/components/common/review';
import Pagination from 'rs/components/common/pagination';
import LoadingSpinner from 'rs/components/common/loading_spinner';
import {MatchMedia} from "shared/vue_media_query/MatchMedia";

export default {
  props: ['widgetData', 'isLoadingResult', 'listFor'],
  // listFor = 'Product' || 'Shop' (storeName)
  // this component is used interchangeably in Product/Shop page, and render reviews of both Product, Shop on each page.
  components: {ReviewCard, Pagination, LoadingSpinner, MatchMedia},
  computed: {
    reviews() {
      return this.widgetData && this.widgetData.reviews && this.widgetData.reviews || []
    },
    productLinks() {
      return this.reviews.map(review => {
        if (review.encoded_product_id === review.encoded_shop_id) return null; // shop review, don't show shop link
        if (this.listFor === 'Product' && review.encoded_product_id === this.$store.getters['Product/encodedProductId']) return null; // same product

        const object = {
          decoration: {
            prefix: this.$route.name === this.$routes.shop.name ? 'About ' : 'About product ',
            suffix: ' (out of store)'
          },
          linkObj: {
            is: 'span'
          }
        }
        if (review.product_in_store) {
          object.linkObj = {
            is: 'router-link',
            to: `/reviews/${this.$route.params.shop_slug}${review.product_url}`
          }
          delete object.decoration.suffix
        }
        return object
      })
    },
  },
  methods: {
    handlePageChange(pageNum) {
      this.$emit('onPageChange', pageNum);
    },
    openGallery(payload) {
      this.$store.dispatch('Gallery/openGallery', payload)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.product-reviews {
  display: flex;
  flex-direction: column;
}

.product-reviews__divider {
  height: 1px;
  background-color: $feedCardDividerColor;
}

.product-reviews__reviews {
  gap: 40px;
  justify-content: flex-start;
}

.product-reviews__review-card {
  padding: 16px 28px;
  margin-left: -28px;
  margin-right: -28px;
  border-bottom: 1px solid #E0F2F2;
  @include respond-to(notdesktop) {
    padding: 16px 12px;
    margin-left: -12px;
    margin-right: -12px;
  }
}
.review-card__product-line {
  margin: 8px 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  @include respond-to(mobile) {
      display: -webkit-box;
      -webkit-line-clamp: 2;
  }
}

.product-reviews__pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  color: $brandColor;
}

.product-reviews__reviews-no-reviews-text {
  text-align: center;
}

.product-reviews__loading-spinner {
  width: 100px;
  height: 100px;
  margin-top: 24px;
  align-self: center;
}
</style>
<style lang="scss">
@import '~PlatformStyles/abstract/variables_new';
.product-reviews__review-card {
  .star {
    color: $brandColor;
  }
}
</style>
