<template>
  <section class="marketplace-footer__wrapper">
    <div class="marketplace-footer__container--top">
      <div class="marketplace-footer__container-wrapper">
        <router-link to="/reviews">
          <img class="marketplace-footer__logo" :src="logoUrl" alt="Judge.me logo">
        </router-link>
        <div class="marketplace-footer__socials">
          <a v-for="socialUrl in socialUrls" :class="'marketplace-footer__socials--'+socialUrl"
            :href="'https://www.'+socialUrl+'.com/judgedotme'" :key="socialUrl"></a>
        </div>
        <div class="marketplace-footer__back-to-top" @click="scrollToTop">Back to top</div>
      </div>
    </div>
    <div class="marketplace-footer__container--bottom">
      <div class="marketplace-footer__container-wrapper">
        <p>© 2023 | All Rights Reserved  | Judge.me Company Limited</p>
        <div class="marketplace-footer__links">
          <a v-for="(page, index) in brandingPages" :href="page.path" class="marketplace-footer__link"
            v-bind:key="index" v-html="page.text"></a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

const brandingPages = [
  {path: "/terms", text: "Terms"},
  {path: "/privacy", text: "Privacy"},
  {path: "/authenticity", text: "Authenticity"},
  {path: "/compliance", text: "Compliance"},
  {path: "/content-policy", text: "Content Policy"},
]

export default {
  data() {
    return {
    logoUrl: 'https://pub-images.judge.me/judgeme/marketplace/logo'
    }
  },
  computed: {
    brandingPages(){
      return brandingPages
    },
    socialUrls(){
      return ["facebook", "twitter", "instagram"]
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
          top: 0,
          behavior: "smooth"
      });
    }
  }
}

</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

  .marketplace-footer__wrapper {
    width: 100vw;
    color: white;
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }
  .marketplace-footer__container--top {
    padding: 16px;
    background: #48AAAA;
    height: 80px;
  }
  .marketplace-footer__container--bottom {
    background: #053C3C;
    height: 64px;
    padding: 24px;
    color: #C1E6E6;
    font-weight: 200;
    font-size: 16px;
    line-height: 18px;
    @include respond-to(notdesktop) {
      display: flex;
      height: 110px;
      align-items: center;
    }
    .marketplace-footer__container-wrapper {
      @include respond-to(notdesktop) {
        flex-direction: column;
        gap: 10px;
      }
      p {
        margin: 0;
        @include respond-to(mobile) {
          max-width: 343px;
          text-align: center;
        }
      }
    }
  }

  .marketplace-footer__logo {
    width: 50px;
  }

  .marketplace-footer__back-to-top {
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    text-decoration: underline;
    cursor: pointer;
  }

  .marketplace-footer__socials {
    color: white;
    width: 50%;
    display: flex;
    font-size: 30px;
    gap: 20px;
    justify-content: center;
  }
  .marketplace-footer__socials--facebook {
    background-image: url("https://pub-images.judge.me/judgeme/marketplace/homepage/facebook");
    background-size: 20px;
    width: 20px;
    height: 20px;
  }

  .marketplace-footer__socials--twitter {
    background-image: url("https://pub-images.judge.me/judgeme/marketplace/homepage/twitter");
    background-size: 20px;
    width: 20px;
    height: 20px;
  }

  .marketplace-footer__socials--instagram {
    background-image: url("https://pub-images.judge.me/judgeme/marketplace/homepage/instagram");
    background-size: 20px;
    width: 20px;
    height: 20px;
  }

  .marketplace-footer__container-wrapper {
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }

  .marketplace-footer__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  a.marketplace-footer__link {
    text-decoration: none;
    color: #C1E6E6;
  }
  a.marketplace-footer__link:not(:last-child)::after {
    content: "|";
    padding: 6px;
  }
</style>
