<template>
  <router-link class="collection-card" :to="cardData.link">
    <img class="cc-image" :src="cardData.image_url"/>
    <div class="cc-title">{{ cardData.title }}</div>
  </router-link>
</template>
<script>
export default {
  props: ['cardData']
}
</script>
<style lang="scss" scoped>
@import "~PlatformStyles/abstract/variables_new";
@import "~PlatformStyles/abstract/mixins";

.collection-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #E0E0E0;
  border-radius: 8px;

  &:hover {
    box-shadow: 0 4px 14px #AACBCB;
    opacity: 1;
  }
}

.cc-image {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  width: 100%;
  height: 140px;

  @include respond-to(desktop) {
    height: 148px;
  }
}

a.collection-card {
  text-decoration: none;
}

.collection-card > .cc-title {
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primaryFontColor;
  background-color: white;
  border: transparent;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;

  height: 56px;
  @include respond-to(desktop) {
    height: 52px;
  }
}
</style>
