<template>
  <div class="profile-review-card">
    <EditorHeader v-if='editable' class="mt-5" :published="review.published" :deleted="review.deleted" :readonly="review.readonly"
                  @edit="onEdit" @delete="onDelete" @togglePublish="onTogglePublish" @restore="onRestore"/>
    <div v-if="isShopReview" class="mt-5">About Store <component v-bind="shopLinkProps">{{ review.shop_name }}</component></div>
    <div class="prc__header">
      <StarRating :rating='review.rating' class='prc__rating'/>
      <div class='prc__timestamp'>{{ createdAtRelative }}</div>
    </div>

    <ReviewImages v-if='showMedia' :media='combinedMedia' :encodedProductId="review.encoded_product_id" largeSize="1"
                  class="prc__gallery"
                  @openGallery="openGallery"/>
    <div class="prc__title" v-html="reviewTitle"></div>
    <div v-html='review.body_html' class='prc__body' :class="{ 'prc__body--show-all' : shouldShowAllBody }"
         ref='reviewBodyEle'></div>
    <span class='prc__body-read-more' @click='showAll'>Read More</span>
    <ReviewReaction :review="review" class="prc__thumbs-reaction"/>
    <ReviewShopReply v-if="review.reply_content" :review="review" />
    <div class="prc__footer" v-if="!isShopReview">
      <component v-bind="productLinkProps">
        <img class="prc__product-image" :src="productImageUrl"/>
      </component>
      <div class="prc__product-details">
        <component class="prc__product-title"  v-bind="productLinkProps" >
          {{ review.product_title }}
        </component>
        <div class="prc__product-metrics">
          <SimpleRating :rating="review.product_rating"/>
          <div v-if="review.product_reviews_count > 0" class="prc__product-reviews-count">
            Based on {{ $sharedUtils.pluralizeWithSeparator(review.product_reviews_count, 'review') }}
          </div>
        </div>
        <div class="prc__product-price">
          <div>Sold by
            <component v-bind="shopLinkProps">{{ review.shop_name }}</component>
          </div>
          <div class="align-self-center w-100">
            <template v-if="review.product_in_store">
              <span v-if="shouldRenderPriceFromText" class="rs-weight-400">From </span>
              <span class="rs-weight-700">{{ price }}</span>
            </template>
            <template v-else>
              <span class="rs-weight-400">Product not available</span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StarRating from 'rs/components/common/star_rating'
import {customFormat} from 'shared/utils/timeago';
import ReviewReaction from 'rs/components/common/review_reaction'
import ReviewImages from 'rs/components/common/review_images'
import EditorHeader from 'rs/components/pages/private_profile/_editor_header'
import {enrichMediaObject} from 'rs/store/shared/gallery_utils';
import SimpleRating from 'rs/components/common/simple_rating';
import {NO_PRODUCT_IMAGE} from "shared/constants/links.js";
import ReviewShopReply from 'rs/components/common/review_shop_reply'

import { mapGetters } from 'vuex';


export default {
  components: {StarRating, ReviewReaction, ReviewImages, SimpleRating, EditorHeader, ReviewShopReply},
  props: {
    card: { type: Object, required: true },
    editable: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters('SharedCurrentReviewer', ['currency', 'exchangeRates']),
    storeName() { return this.$store.getters['Profile/storeName']; },
    review() {
      const updatedReviews = this.$store.getters[this.storeName + '/updatedReviews']
      if (updatedReviews) {
        return updatedReviews(this.card.public_id) || this.card
      }
      return this.card
    },
    productLinkProps() {
      if (this.review.product_internal_path)
        return {
          is: 'router-link',
          to: this.review.product_internal_path,
        }
      if (this.review.product_url)
        return {
          is: 'a',
          href: this.review.product_url,
          target: '_blank'
        }
      return { is: 'span' }
    },
    isShopReview() {
      return this.review.review_type ==='shop-review';
    },
    shopLinkProps() {
      return this.review.shop_internal_path ? {
        is: 'router-link',
        to: this.review.shop_internal_path,
      } : {
        is: 'a',
        href: this.review.shop_url,
        target: '_blank'
      }
    },
    reviewTitle() { return this.review.title_html },
    reviewer() {
      return this.$store.getters[this.storeName + '/profileData']
    },
    productImageUrl() {
      return  this.review.product_image_url || NO_PRODUCT_IMAGE
    },
    shouldRenderPriceFromText() {
      return this.review.product_highest_price && this.review.product_highest_price !== this.review_product_displayed_price
    },
    price() {
      const conversion = this.$sharedUtils.convertCurrency(this.currency, this.review.product_currency, this.review.product_lowest_price, this.exchangeRates);
      if (conversion !== null) { //  cannot convert
        return conversion
      } else {
        return this.review.product_displayed_price
      }
    },
    showMedia() {
      return !!((this.review.pictures_urls && this.review.pictures_urls.length)
        || (this.review.video_external_ids && this.review.video_external_ids.length)
        || (this.review.media_platform_hosted_video_infos && this.review.media_platform_hosted_video_infos.length));
    },
    combinedMedia() {
      return enrichMediaObject(this.review)
    },
    createdAtRelative() {
      return customFormat(this.review.created_at)()
    },
  },
  data() {
    return {
      shouldShowAllBody: false,
    }
  },
  methods: {
    onEdit() {
      window.open(`/profile/reviews/${this.review.public_id}/edit`, "_blank");
    },
    async onRestore() {
      const {value} = await this.$swal.fire({
        title: 'Are you sure to restore this review?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#088484',
        confirmButtonText: 'Yes',
        cancelButtonColor: '#d33',
      })
      if (!value) return;
      const ok = await this.$store.dispatch(this.storeName + '/restoreReview', this.review);
      if (ok) {
        this.$alertSuccess('Review has been restored successfully');
      } else {
        this.$alertError('Failed to restore your review');
      }
    },
    async onDelete() {
      const {value} = await this.$swal.fire({
        title: 'Delete confirmation',
        text: "Are you sure you want to delete review from store’s website? You can restore your review within the next 14 days if you change your mind.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#088484',
        confirmButtonText: 'Yes',
        cancelButtonColor: '#d33',
      })
      if (!value) return;
      const ok = await this.$store.dispatch(this.storeName + '/deleteReview', this.review);
      if (ok) {
        this.$alertSuccess('Review has been deleted successfully');
      } else {
        this.$alertError('Failed to delete review');
      }
    },
    async onTogglePublish() {
      const toBePublished = !this.review.published;
      const ok = await this.$store.dispatch(this.storeName + '/togglePublishReview', this.review);
      if (ok) {
        this.$alertSuccess(`Your review has been ${toBePublished ? 'published' : 'unpublished'}, it could take a few moments to be effective`);
      } else {
        this.$alertError('Failed to publish your review');
      }
    },
    openGallery(v) {
      this.$store.dispatch('Gallery/openGallery', {
        ...v,
        review: this.review,
        encodedId: this.reviewer.encoded_id,
        sourceType: this.storeName
      })
    },
    showAll() {
      this.shouldShowAllBody = true
    },
  },
  mounted() {
    if (this.$refs.reviewBodyEle.scrollHeight > this.$refs.reviewBodyEle.clientHeight) {
      this.$refs.reviewBodyEle.className = 'prc__body prc__body--need-show-more'
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import 'rs/components/uncommon/profile/card_styles.scss';

.prc__gallery { //override child
  @include respond-to(desktop) {
    margin-top: 0; //override child
  }
}
</style>
