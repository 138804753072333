<template>
  <img class='lazy-img' :class='className' :alt='altText' loading='lazy'
       @error="handleError" v-bind="refererPolicy"
       :srcset="(urls.normal || urls.retina) + ', ' + urls.retina + ' 2x'"  >

</template>

<script>
  import {replaceRefererPolicyIfAliImages} from "shared/utils/aliexpress_image";

  export default {
    props: ['className', 'altText', 'urls'],
    computed: {
      refererPolicy() { // handle images from aliexpress cdn
        return replaceRefererPolicyIfAliImages(this.urls.normal, this.urls.retina)
      },
      async handleError(e) {
        try {
          await fetch(this.urls.normal || this.urls.retina, {referrerPolicy: 'no-referrer'})
        } catch {
          e.target.srcset = '' // clear srcset to prevent browser from executing onerror again
          this.$emit('error')
        }
      },
    },
  }
</script>
