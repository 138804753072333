<template>
  <a class="file-wrapper" target="_blank" :href="file.url">
    <svg-icon :icon="'file_' + file.type" :key="file.url"></svg-icon>
  </a>
</template>
<script>
export default {
  props: {
    file: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.file-wrapper {
  border-radius: 4px;
  border: 1px solid #E7E7E7;
  background: #FFF;
  padding: 4px 8px;
  display: flex;
  width: fit-content;
}
</style>
