export const reviewSourcesMap = {
  invite: {
    label: 'Review collected from invite',
    tooltip: "Customers are invited by merchants to share their feedback and opinions about a product or service through various channels such as email, SMS notifications, and push notifications, customers are encouraged to provide reviews based on their experiences. This method allows businesses to gather valuable insights and opinions directly from customers that bought a product."
  },
  organic: {
    label: 'Review collected organically',
    tooltip: "These type of reviews are obtained naturally through various channels, such as the web, all-reviews pages, and review sites. The \"web-verified\" feature ensures the credibility of these reviews by verifying the authenticity of the source. This approach allows businesses to obtain genuine customer opinions."
  },
  migrated: {
    label: 'Review migrated from another store',
    tooltip: "This review was transferred or imported from a previous or different online store. When a store changes platforms or moves to a new system, this functionality allows businesses to bring along the existing reviews from their previous store to maintain a comprehensive feedback history."
  },
  unverified_source: {
    label: 'Unverified review source',
    tooltip: "Refers to customer reviews that have not been verified or authenticated by a recognized source."
  },
  unverified_provider: {
    label: 'Review collected by another review provider',
    tooltip: "This type of review refers to the process of gathering customer reviews from external platforms or review providers other than Judge.me Product Reviews. Judge.me is an app that facilitates the collection and display of customer reviews on an e-commerce website. However, it recognizes that businesses may also use other review providers."
  },
  verified_provider: {
    label: 'Review collected by a verified review provider',
    tooltip: "It means that the review has undergone a verification process on another review provider to ensure its authenticity and reliability. "
  },
  aliexpress: {
    label: 'Review migrated from Aliexpress',
    tooltip: ''
  },
}

export const reviewSources = {
  all_sources: 'All review sources',
  organic: 'Collected organically',
  invite: 'Collected from invite',
  migrated: 'Migrated from another store',
  aliexpress: 'Migrated from Aliexpress',
  verified_provider: 'Collected by verified review provider',
  unverified_provider: 'Collected by another review provider',
  unverified_source: 'Unverified source',
}
