import {pipe} from 'shared/utils/js_core'
import { 
  PROPOSAL_TYPES,
  MESSAGE_HEADINGS,
  OPPOSED_PROPOSAL_TYPES,
  RESOLUTION_TYPES_FOR_REVIEWER,
  RESOLUTION_TYPES_FOR_SHOP,
 } from 'shared/vue_components/arbitration/constants.js'


function getAutoMessageHeadings(text, senderRole, viewerRole, name) {
  const messageObject = getMessageObject(text)
  const key = senderRole + '_' + viewerRole
  return messageObject ? {
    text: pipe(
      getMessageFunc(key),
      getFinalMessage(name)
    )(messageObject),
    icon: messageObject.icon,
    styleClasses: messageObject.styleClasses,
  } : null
}


const getMessageObject = (text) => MESSAGE_HEADINGS[text]
const getMessageFunc = perspective => messageObject => messageObject ? messageObject[perspective] : null
const getFinalMessage = name => messageFunc => messageFunc ? messageFunc(name) : null


const roleToPrefix = role => role === 'buyer' ? 'reviewer' : 'shop'
const getProposalType = (text) => PROPOSAL_TYPES[text]

const getProposalOptions = (role = 'buyer', previousProposalType = '') => {
  const prefix = roleToPrefix(role)
  // use opposedPreviousType to remove previous proposal type from result
  const opposedPreviousType = OPPOSED_PROPOSAL_TYPES[previousProposalType]

    const result = []
	for (const key in PROPOSAL_TYPES) {
		if (key.startsWith(prefix) && key !== opposedPreviousType) {
			result.push({
				value: key,
				text: PROPOSAL_TYPES[key],
			})
		}
	}
  return result
}


const iconNameForDispute = statusText => {
  if (statusText.startsWith('New')) return 'bell'
  if (statusText.startsWith('Wait') || statusText.startsWith('No Reply')) return 'clock'
  if (statusText.startsWith('Agreement not')) return 'check_scratch'
  return 'check'
}

const disputeIsClosedOrResolved = dispute => {
  return dispute.status.startsWith('resolved') || dispute.status.startsWith('no_reply') || dispute.status.startsWith('closed')
}

const getResolutionTypes = (role = 'reviewer')  => {
  const options = role === 'reviewer' ? RESOLUTION_TYPES_FOR_REVIEWER : RESOLUTION_TYPES_FOR_SHOP
  return Object.keys(options).map(key => ({
    value: key,
    text: options[key],
  }))
}


export {
  getAutoMessageHeadings,
  getProposalType,
  getProposalOptions,
  iconNameForDispute,
  disputeIsClosedOrResolved,
  getResolutionTypes,
}
