<template functional>
  <svg v-on="data.listeners" :class="data.class" v-bind="data.attrs" xmlns="http://www.w3.org/2000/svg" width="20"
       height="20" viewBox="0 0 20 20" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M2 10C2 5.5816 5.5816 2 10 2C14.4184 2 18 5.5816 18 10C18 14.4184 14.4184 18 10 18C5.5816 18 2 14.4184 2 10ZM10.8 6C10.8 5.78783 10.7157 5.58434 10.5657 5.43431C10.4157 5.28429 10.2122 5.2 10 5.2C9.78783 5.2 9.58434 5.28429 9.43431 5.43431C9.28429 5.58434 9.2 5.78783 9.2 6V9.0112C9.20002 9.45684 9.32411 9.89368 9.55837 10.2728C9.79264 10.6519 10.1278 10.9583 10.5264 11.1576L12.8424 12.3152C12.9367 12.3661 13.0403 12.3974 13.1469 12.4075C13.2536 12.4176 13.3612 12.4061 13.4633 12.3738C13.5655 12.3415 13.6601 12.289 13.7416 12.2195C13.8231 12.1499 13.8897 12.0647 13.9377 11.9688C13.9856 11.873 14.0137 11.7685 14.0205 11.6616C14.0273 11.5547 14.0125 11.4475 13.9771 11.3464C13.9416 11.2453 13.8862 11.1523 13.8142 11.073C13.7421 10.9937 13.6549 10.9297 13.5576 10.8848L11.2424 9.7264C11.1095 9.66001 10.9978 9.55794 10.9196 9.43162C10.8415 9.3053 10.8001 9.15973 10.8 9.0112V6Z"
          :fill="props.color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#088484',
    }
  }
}
</script>
