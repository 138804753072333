import axios from 'shared/utils/axios'

const _extractFolloweeIds = (reviewers) => {
  const encodedIds = reviewers.map((reviewer) => { return reviewer.encoded_id })
  return { 'Reviewer': encodedIds }
}

const state = {
  searchParams: {
    sort_by: null,
    filter_by: null,
    page: 1,
  },
  reviewers: [],
  isLoadingReviewers: true,
  hasMoreReviewers: false,
}

const getters = {
}

const mutations = {
  setSearchParams (state, newSearchParams) { state.searchParams = newSearchParams },
  clearReviewers (state) { state.reviewers = [] },
  appendReviewers (state, payload) {
    state.reviewers = state.reviewers.concat(payload.reviewers)
    state.hasMoreReviewers = (payload.per_page && payload.reviewers.length >= payload.per_page)
  },
  setIsLoadingReviewers: (state, isLoading) => state.isLoadingReviewers = isLoading,
}

const actions = {
  makeNewSearchReviewers (context, payload) {
    context.commit('clearReviewers')
    context.dispatch('searchReviewers', { ...payload, page: 1 })
  },

  searchReviewers (context, payload) {
    context.commit('setIsLoadingReviewers', true)
    context.commit('setSearchParams', payload)

    axios.get('/people.json', {
      params: payload,
    }).then(function (response) {
      context.commit('appendReviewers', response.data)
      context.commit('setIsLoadingReviewers', false)
      const followeeIdsToCheck = _extractFolloweeIds(response.data.reviewers)
      context.dispatch('SharedFollow/fetchFolloweeIds', followeeIdsToCheck, { root: true })
    })
  },
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,

  namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
}
