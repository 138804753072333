<template>
  <div class="categories-page-wrapper">
    <component v-bind="componentProps"/>
  </div>
</template>
<script>
import CategoryView from 'rs/components/pages/categories/index_page/_category_view'

export default {
  computed: {
    componentProps() {
      return {is: CategoryView}
    }
  },
  created() {
    this.$store.dispatch('Meta/getMeta')
  }
}
</script>
<style lang="scss" scoped>
.categories-page-wrapper {
  margin-top: -12px;
}
</style>
