<template>
  <section class="profile-reviews-list">
    <LoadingSpinner v-if="isLoading"/>
    <template v-else>
      <slot></slot>
    </template>
    <MatchMedia v-slot="{mobile}">
      <Pagination v-if="!!totalPages" class="prs__pagination" :currentPage="currentPage"
                  :minItem="mobile ? 3 : 8" :displayRange="mobile ? 1 : 3"
                  :totalPage="totalPages" @onChange="e => $emit('pageChange', e)"/>
    </MatchMedia>
  </section>
</template>
<script>
import Pagination from 'rs/components/common/pagination';
import LoadingSpinner from 'rs/components/common/loading_spinner';
import {MatchMedia} from "shared/vue_media_query/MatchMedia";

export default {
  components: {LoadingSpinner, MatchMedia, Pagination},
  props: ['totalPages', 'currentPage', 'isLoading']
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.loading-spinner {
  margin: 144px auto;
  width: 72px;
  height: 72px;
}
.profile-reviews-list {
  border-top: solid 1px $feedCardDividerColor;
}

.prs__pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  color: $brandColor;
}
</style>
