<template>
  <div class="recommendation-card-header">
    <div v-if="item.card_focus == 'review'" class="recommendation-card-header-review">
      <ReviewerHeader v-if="item.reviewer" :reviewer="item.reviewer" :created_at="item.created_at" :verified_buyer="item.verified_buyer" />
    </div>
    <div v-else class="recommendation-card-header-product">
      <div v-if="isShopReview" >
        <p class="recommendation-card__shop-name">{{ item.product_title }}</p>
        <p class="recommendation-card__review-title">{{ item.review_title }}</p>
      </div>
      <div v-else>
        <p class="recommendation-card__product-title" >{{ item.product_title }}</p>
        <p class="recommendation-card__product-price">{{ itemPrice }}</p>
      </div>
    </div>
    <StarRating :rating='item.rating' class='recommendation-card__review-rating' />
    <router-link v-if="productPath" data-open-modal="false" class="btn recommendation-card__review-view-product-btn" :to="productPath">View Product</router-link>
  </div>
</template>

<script>
  import ReviewerHeader from 'rs/components/common/recommendation/recommendation_card_reviewer_header.vue';
  import StarRating from 'rs/components/common/star_rating.vue';

  export default {
    components: { ReviewerHeader, StarRating },
    props: ['item', 'productPath', 'itemPrice'],
    computed: {
      isShopReview() { return this.item.product_handle === 'judgeme-shop-reviews' },
    },
  }
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';
.recommendation-card-header-product {
  color: $primaryFontColor;
  .recommendation-card__review-title {display: none;}
}
.recommendation-card__product-title, .recommendation-card__shop-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @include respond-to(notmobile) {padding-right: 40px}
}
.recommendation-card__shop-name {
  font-weight: bold;
  color: $brandColor;
  &::before {content: 'About '}
  &::after {content: ' Store '}
  &::before, &::after {color: $primaryFontColor}

}
.recommendation-card__product-price, .recommendation-card__review-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.recommendation-card__review-rating{text-align: left; margin-top: 8px;}
.recommendation-card__review-view-product-btn {
  background-color: white;
  color: $brandColor;
  margin-top: 14px;
  font-weight: 800;
  font-size: 16px;
  width: 100%;
}

.recommendation-card__product, .recommendation-card__review {
  @include respond-to(notmobile) {
   .recommendation-card__review-view-product-btn {
      margin: 16px 24px 8px 0;
      position: absolute;
      bottom: 12px;
      width: -webkit-fill-available;
    }
  }
}

.recommendation-card-modal__wrapper {
  .recommendation-card__review-view-product-btn {
    max-width: 300px;
  }
}

</style>
