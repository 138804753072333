<template>
  <div class="shipping-country" v-if="countryCode">
    <span>Shipped from </span>
    <CountryFlag
        v-if="!hasBrokenFlag"
        @error="hasBrokenFlag = true"
        class="shipping-country__flag" :alt="'country icon for ' + countryCode"
        :countryCode="countryCode"
    />
    <span v-else>{{ countryCode }}</span>
  </div>
</template>
<script>
import CountryFlag from 'rs/components/common/country_flag';

export default {
  components: {CountryFlag},
  props: ['countryCode'],
  data(){
    return {
      hasBrokenFlag: false,
    }
  }
}
</script>
<style lang="scss" scoped>
.shipping-country {
  height: inherit;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.shipping-country__flag {
  margin-left: 4px;
  height: 60%;
}
</style>
