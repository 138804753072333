<template functional>
  <svg v-on="data.listeners" :class="data.class" v-bind="data.attrs" xmlns="http://www.w3.org/2000/svg" width="16"
       height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_2030_12639)">
      <path
        d="M12.0003 3.99992H10.667C10.667 2.52659 9.47366 1.33325 8.00033 1.33325C6.52699 1.33325 5.33366 2.52659 5.33366 3.99992H4.00033C3.26699 3.99992 2.66699 4.59992 2.66699 5.33325V13.3333C2.66699 14.0666 3.26699 14.6666 4.00033 14.6666H12.0003C12.7337 14.6666 13.3337 14.0666 13.3337 13.3333V5.33325C13.3337 4.59992 12.7337 3.99992 12.0003 3.99992ZM6.66699 6.66658C6.66699 7.03325 6.36699 7.33325 6.00033 7.33325C5.63366 7.33325 5.33366 7.03325 5.33366 6.66658V5.33325H6.66699V6.66658ZM8.00033 2.66659C8.73366 2.66659 9.33366 3.26659 9.33366 3.99992H6.66699C6.66699 3.26659 7.26699 2.66659 8.00033 2.66659ZM10.667 6.66658C10.667 7.03325 10.367 7.33325 10.0003 7.33325C9.63366 7.33325 9.33366 7.03325 9.33366 6.66658V5.33325H10.667V6.66658Z"
        :fill="props.color"/>
    </g>
    <defs>
      <clipPath id="clip0_2030_12639">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#088484',
    }
  }
}
</script>
