<!--
 Note: Call this component with 2 options:
 - use predefined data with arg: name
 - or use customized label + tooltip with args: label & tooltip
-->
<template>
  <MatchMedia v-slot="{mobile}">
    <template v-if="mobile">
      <div @click="show">
        <slot name="icon">
          <CircleIcon :icon="icon" class="tooltip-popover-icon" color="#A0B1C2"/>
        </slot>
      </div>
      <MobileBottomModal v-if="showTooltip" @closeModal="close">
        <template v-slot:header>
          <slot name="label">
            {{ computedLabel }}
          </slot>
        </template>
        <template v-slot:content>
          <slot name="tooltip">
            {{ computedTooltip }}
          </slot>
        </template>
      </MobileBottomModal>
    </template>
    <template v-else>
      <v-popover v-bind="popoverProps">
        <slot name="icon">
          <CircleIcon :icon="icon" class="tooltip-popover-icon" color="#A0B1C2"/>
        </slot>
        <template #popover>
          <div>
            <slot name="label"><p class="tooltip-popover-label">{{ computedLabel }}</p></slot>
            <slot name="tooltip"><p class="tooltip-popover-content">{{ computedTooltip }}</p></slot>
          </div>
        </template>
      </v-popover>
    </template>
  </MatchMedia>
</template>

<script>
import MobileBottomModal from 'rs/components/layouts/mobile_bottom_modal'
import CircleIcon from 'rs/components/common/icons/custom_circle_icon';
import {MatchMedia} from "shared/vue_media_query/MatchMedia";
import tooltipData from 'shared/constants/tooltips_data.js'
export default {
  components: {MatchMedia, MobileBottomModal, CircleIcon},
  props: {
    tooltip: {
      type: String,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    icon: {
      type: String,
      default: 'info',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltipArg: {
      type: [String, Object],
    },
  },
  computed: {
    computedLabel() {
      if (this.label) return this.label
      if (!this.name) return null
      return tooltipData[this.name] ? tooltipData[this.name].label : null
    },
    computedTooltip() {
      if (this.tooltip) return this.tooltip
      if (!this.name) return null
      const {tooltip} = tooltipData[this.name]
      if (!tooltip) return null
      if (typeof tooltip === 'function') return tooltip(this.tooltipArg)
      return tooltip
    }
  },
  data() {
    return {
      showTooltip: false,
      popoverProps: {
        disabled: this.disabled,
        offset: 4,
        trigger: 'hover',
        placement: 'bottom',
        popoverBaseClass: 'tooltip-popover',
        popoverArrowClass: 'tooltip-popover-arrow',
        popoverInnerClass: '',
        handleResize: true,
      },
    }
  },
  methods: {
    show() {
      if (this.disabled) return
      this.showTooltip = true
    },
    close() {
      this.showTooltip = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.review-source__tooltip-wrapper {
  display: flex;
  color: $secondaryFontColor;
}

.tooltip-popover-label {
  font-size: 15px;
  font-weight: 700;
}

.tooltip-popover-content {
  font-size: 14px;
  font-weight: 400;
}
</style>

<style lang="scss">
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.tooltip-popover {
  width: 504px;
  height: fit-content;
  background: white;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.25));
  padding: 24px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: $primaryFontColor;
  left: 100px !important;
}

.tooltip-popover[x-placement="bottom"] {
  margin-top: 10px;

  .tooltip-popover-arrow {
    top: -15px;
  }
}

.tooltip-popover[x-placement="top"] {
  margin-bottom: 10px;
}

.v-popover > .trigger {
  cursor: pointer;
  display: flex !important;
}

.tooltip-popover-arrow {
  width: 50px;
  height: 50px;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  @include respond-to(desktop) {
    left: 25% !important;
  }
  @include respond-to(tablet) {
    left: 100px !important;
  }

  &::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    background: white;
    transform: translateX(-50%) translateY(50%) rotate(45deg);
    top: -5px;
    left: 50%;
  }
}

.tooltip-popover-inner {
  color: $brandColor;
  font-weight: 400;
  font-size: 12px;
}
.tooltip-popover-icon {
  cursor: pointer;
  border-color: #ECF1F6;
  background: #ECF1F6;
}
</style>
