<template>
  <section class="filter-sidebar">
    <div class="filter-sidebar__wrapper">
      <div class="filter-sidebar__item-wrapper">
        <div class="filter-sidebar__item-filter">
          <LocaleSetting />
        </div>
      </div>

      <div class="filter-sidebar__header">
        Filter by
        <span v-if="didAnyFilterChange" class='filter-sidebar__header--clear' @click="clearFilter('all', true)">Clear all filters</span>
      </div>
      <div class="filter-sidebar__divider"></div>

      <div v-if="enableCategoryFilter" class="filter-sidebar__item-wrapper">
        <div class="filter-sidebar__item-title">Categories</div>
        <div class="filter-sidebar__item-filter">
          <Categories />
        </div>
      </div>

      <div class="filter-sidebar__item-wrapper">
        <div class="filter-sidebar__item-title">Overall rating</div>
        <div class="filter-sidebar__item-filter">
          <StarRating :defaultRating='searchParams.min_rating' @change="e=> updateFilter({min_rating: e}, true)" class="filters-sidebar__star"/>
          {{ renderStarText }}
        </div>
      </div>

      <div class="filter-sidebar__item-wrapper">
        <div class="filter-sidebar__item-title">
          Reviews
          <span v-if="searchParams.reviews_count" class='filter-sidebar__item-title-clear' @click="clearFilter('reviews_count', true)">Clear</span>
        </div>
        <div class="filter-sidebar__item-filter">
          <ReviewsFilter @change="e=> updateFilter({reviews_count: e}, true)" :activeValue="searchParams.reviews_count"/>
        </div>
      </div>

      <div class="filter-sidebar__item-wrapper">
        <div class="filter-sidebar__item-title">
          Price
          <span v-if="searchParams.min_price || searchParams.max_price" class='filter-sidebar__item-title-clear' @click="clearFilter('price', true)">Clear</span>
        </div>
        <div class="filter-sidebar__item-filter">
          <PriceFilter
             @submit="applyFilter"
             @change="value => updateFilter({ min_price: value[0], max_price: value[1]})"
             :min="searchParams.min_price" :max="searchParams.max_price"/>
        </div>
        <button type='button' class="filter-sidebar__item-filter-button" @click="applyFilter">Go</button>
      </div>

      <div class="filter-sidebar__item-wrapper">
        <div class="filter-sidebar__item-title">
          <span class="filter-sidebar__item-title-tooltip-wrapper" @click="showTooltip('transparency')" @mouseenter="showTooltip('transparency')"  @mouseleave="closeTooltip('transparency')">
            Transparency
            <span class="filter-sidebar__item-title-icon svg-sprite filter-sidebar__item-title-icon--stars">

            </span>
            <Tooltip v-if="tooltips.transparency" class="filter-sidebar__item-title-tooltip"
                     @close="closeTooltip('transparency')"
                     text="Percentage of verified reviews that are published"
            />
          </span>
        </div>
        <div class="filter-sidebar__item-filter filter-sidebar__item-filter-slider">
          <SingleSlider
              @change="e => searchParams.min_transparency = e"
              :class="[updateSliderTooltipPosition(searchParams.min_transparency)]"
              :defaultValue="searchParams.min_transparency" :min="0" :max="100"/>
        </div>
        <button type='button' class="filter-sidebar__item-filter-button" @click="applyFilter">Go</button>
      </div>

      <div class="filter-sidebar__item-wrapper">
        <div class="filter-sidebar__item-title">
          <span class="filter-sidebar__item-title-tooltip-wrapper"   @click="showTooltip('authenticity')" @mouseenter="showTooltip('authenticity')"  @mouseleave="closeTooltip('authenticity')">
            Authenticity
            <span class="filter-sidebar__item-title-icon svg-sprite filter-sidebar__item-title-icon--check">
            </span>
            <Tooltip v-if="tooltips.authenticity" class="filter-sidebar__item-title-tooltip"
                     @close="closeTooltip('authenticity')"
                     text="Percentage of published reviews that are verified"
            />
          </span>
        </div>
        <div class="filter-sidebar__item-filter">
          <SingleSlider
              @change="e => searchParams.min_authenticity = e"
              :class="[updateSliderTooltipPosition(searchParams.min_authenticity)]"
              :defaultValue="searchParams.min_authenticity" :min="0" :max="100"/>
        </div>
        <button type='button' class="filter-sidebar__item-filter-button" @click="applyFilter">Go</button>
      </div>

    </div>
  </section>
</template>

<script>
  import PriceFilter from 'rs/components/pages/search/_filter_price'
  import ReviewsFilter from 'rs/components/pages/search/_filter_reviews';
  import SingleSlider from 'rs/components/pages/search/_filter_range_slider_single'
  import Tooltip from 'rs/components/common/tooltip_v2'
  import StarRating from 'rs/components/common/star_rating_chooser';
  import Categories from 'rs/components/pages/search/sidebar_categories';
  import LocaleSetting from 'rs/components/pages/search/_locale_setting';


  import {mapState} from "vuex";
  export default {
    components: {
      Tooltip,
      StarRating,
      LocaleSetting,
      ReviewsFilter,
      PriceFilter,
      SingleSlider,
      Categories,
    },
    props: [
        'loading',
        'clearFilter',
        'updateFilter',
        'applyFilter',
        'didAnyFilterChange',
        'enableCategoryFilter',
        'updateSliderTooltipPosition',
    ],
    computed: {
      ...mapState('Search', ['searchParams']),
      renderStarText(){
        switch(this.searchParams.min_rating) {
          case 0:
            return `All ratings`
          case 5:
            return `Only ${ this.$sharedUtils.pluralize(this.searchParams.min_rating, 'star') }`
          default:
            return `At least ${ this.$sharedUtils.pluralize(this.searchParams.min_rating, 'star') }`
        }
      }
    },
    data(){
      return {
        tooltips: { transparency: false, authenticity: false },
      }
    },
    methods: {
      showTooltip(field){
        this.tooltips[field] = true;
      },
      closeTooltip(field){
        this.tooltips[field] = false;
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .filter-sidebar__wrapper {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 24px;
  }

  .filter-sidebar__header {
    margin-top: 20px;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
  }
  .filter-sidebar__header--clear {
    font-size: 14px;
    color: $newBrandColor;
    text-decoration: underline;
    cursor: pointer;
  }

  .filter-sidebar__divider {
    height: 1px;
    background-color: $borderColor;
  }

  .filter-sidebar__item-wrapper {
    &:last-child {
      margin-bottom: 20px;
    }
  }

  .filter-sidebar__item-title {
    font-weight: 700;
    color: #333333;
    margin: 20px 0 12px 0;
    display: flex;
    justify-content: space-between;
  }

  .filter-sidebar__item-title-clear {
    font-weight: 400;
    font-size: 14px;
    color: $newBrandColor;
    text-decoration: underline;
    cursor: pointer;
  }
  .filter-sidebar__item-title-icon {
    display: inline-block;
    margin-left: 4px;
    height: 20px;
    width: 24px;
    vertical-align: middle;
  }

  .filter-sidebar__item-title-icon--stars {
    background-position: -700px -200px;
  }
  .filter-sidebar__item-title-icon--check {
    background-position: -800px -200px;
  }

  .filter-sidebar__item-filter {
    color: $secondaryFontColor;
  }

  .filter-sidebar__slider--right,
  .filter-sidebar__slider--left {
    margin-top: 28px;
  }

  .filter-sidebar__item-filter-button {
    background-color: $lightGreenColor;
    border-radius: 4px;
    border: none;
    padding: 8px 16px;
    margin-top: 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;

    &:disabled {
      background: rgba(51, 51, 51, 0.2);
    }
  }

  .filter-sidebar__item-slider--left,
  .filter-sidebar__item-slider--right {
    width: 100%;
    margin-top: 30px;
  }

  .filter-sidebar__item-title-tooltip {
    font-size: 14px;
    font-weight: 400;
    width: 162px;
    top: -10px;
    right: -66px; // 162 -  30 / 2
    transform: translateY(-100%);
    z-index: 1;
  }
  .filter-sidebar__item-title-tooltip-wrapper {
    position: relative;
    cursor: pointer;
  }
</style>

<style lang="scss">
  @import '~PlatformStyles/abstract/variables_new';

  .filters-sidebar__star {
    .star {
      color: $newBrandColor;
      font-size: 24px;
    }
  }

  .filter-sidebar__slider--left,
  .filter-sidebar__slider--right {
    .vue-slider-dot {
      z-index: 1;
    }
    .vue-slider-dot-tooltip-inner {
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
      padding: 0 6px;
      min-width: 20px;
      text-align: center;
      color: #fff;
      margin-bottom: -7px;
      border-radius: 2px;
      border-color: #333333;
      background-color: #333333;
    }

    .vue-slider-dot-tooltip-inner-top::after {
      border-style: none;
    }

    .vue-slider-rail {
      background-color: $borderColor;
      border-radius: 5px;
    }
    .vue-slider-process {
      border-radius: 5px;
    }
    .custom-dot {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      background-color: $newBrandColor;
      transition: all .3s;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1px;
      cursor: pointer;
    }

    .slider-custom-dot {
      height: 6px;
      border: 1px solid rgba(255, 255, 255, 0.3);
    }

    .vue-slider-dot-tooltip-top {
      top: -10px;
      left: 50%;
      --webkit-transform: translate(0%, -100%);
      transform: translate(0%, -100%);
    }
  }

  .filter-sidebar__slider--right {
    .vue-slider-dot-tooltip-top {
      top: -10px;
      left: 50%;
      --webkit-transform: translate(-100%, -100%);
      transform: translate(-100%, -100%);
    }
  }

</style>
