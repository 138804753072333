<template>
  <div class="dispute-page-wrapper">
    <section class="dispute-page__nav">
      <div class="new-has-max-width dispute-page__nav-container">
        <router-link class="btn pf-primary-button" :to="goBackPath">Go Back</router-link>
      </div>
    </section>
    <div class="dispute-page__container">
      <loading-spinner v-if="isLoading"/>
      <div class="dispute-wrapper" v-else>
        <div class="dispute-wrapper__container">
          <div class="dispute-wrapper__heading">Your messages</div>
          <div class="dispute-wrapper__description">Please use our messaging system so we can keep track of all your
            conversations with the store ensuring transparent conversations for enhanced accountability and efficient
            support.
          </div>
          <Dispute class='dispute-wrapper__inner' v-if="dispute" :dispute="dispute" @cancel="goBack"/>
          <div v-else class="mt-5 rs-font-16 rs-weight-700 d-flex-column align-center gap-4">
            <div>Dispute not found</div>
            <router-link class="btn pf-primary-button" :to="goBackPath">Go to all disputes page</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Dispute from 'shared/vue_components/arbitration/dispute.vue'
import actions from 'rs/components/pages/private_profile/arbitration_actions.js'

export default {
  components: {Dispute},
  data() {
    return {
      dispute: null,
      isLoading: false,
    }
  },
  provide: {
    arbitrationContext: actions
  },
  computed: {
    goBackPath() {
      return this.$store.getters['SharedCurrentReviewer/currentReviewer'] ? {
        path: '/' + this.$store.getters['SharedCurrentReviewer/currentReviewer'].encoded_id,
        query: {...this.$route.query, tab: 3}
      } : { path: this.$routes.privateProfile.path }
    },
  },
  methods: {
    goBack() {
      this.$router.push(this.goBackPath)
    },
    fetchDispute() {
      this.isLoading = true;
      actions.fetchDispute(this.$route.params.disputeId).then(dispute => {
        this.dispute = dispute
        this.isLoading = false;
      })
    },
  },
  created() {
    this.fetchDispute()
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
.dispute-page-wrapper {
  width: 100vw;
  transform: translateX(-50%);
  margin-top: -12px;
  margin-left: 50%;
}

.dispute-page__nav {
  position: relative;
  width: 100%;
  background: linear-gradient(270deg, #C1E6E6 5.9%, #D1F3F3 97.43%);
  margin-bottom: 20px;
  height: 72px;
}

.dispute-page__nav-container {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dispute-page__container {
  max-width: 712px;
  margin: 20px auto 80px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}


.loading-spinner {
  margin: 100px;
  width: 100px;
  height: 100px;
}

.dispute-wrapper {
  max-width: 988px;
  width: 100%;
  background-color: white;
}

.dispute-wrapper__container {
  margin: 0 auto 32px auto;
  padding: 20px;
  max-width: 656px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include respond-to(mobile) {
    padding: 20px 12px;;
  }
}

.dispute-wrapper__heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.dispute-wrapper__description {
  font-size: 14px;
  margin-top: 16px;
  font-weight: 400;
}

.dispute-wrapper__inner {
  margin-top: 28px;
  width: 100%;
}
</style>
