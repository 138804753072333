<template>
  <div class="dispute">
    <div class="dispute__body">
      <ProposalModal v-if="proposalModal" @close="() => proposalModal = false" :dispute="dispute"
                     :proposal="proposal"/>
      <ResolutionModal v-if="resolutionModal" @close="() => resolutionModal = false" :dispute="dispute"/>
      <MarkResolvedModal v-if="markResolvedModal" @close="accepted => confirmResolve(dispute.id, accepted)"/>
      <div class="dispute__created-at">Created on {{ createdAt }}</div>
      <div class="dispute__info" v-if="sourceData">
        <strong>Related to: </strong>
        <a target="_blank" :href="sourceData.url">
          <span v-if="sourceData.type==='Order'" v-text="`${sourceData.type} #${sourceData.id}`"/>
          <span v-else v-text="sourceData.id"/>
        </a>
      </div>
      <div class="dispute__info"><strong>Issue type: </strong>{{ dispute.type }}</div>
      <div class="dispute__info"><strong>Issue description: </strong>{{ dispute.description }}</div>
      <div class="dispute__info d-flex align-center gap-2" v-if="files"><strong>Info uploaded: </strong>
        <File v-for="(file, idx) in files" :key="`${dispute.id}_${idx}`" :file="file"/>
      </div>
      <div class="dispute__info"><strong>Desired outcome: </strong>{{ dispute.outcome_type }} <span
        v-if="dispute.outcome_description"> - {{ dispute.outcome_description }}</span></div>
      <div class="dispute__message-list">
        <Message v-for="(message, idx) in messages" :key="'message_'+idx" :message="message" :senderPoV="senderPoV(message)"/>
        <ResolutionCard v-for="(message, idx) in messages" :key="'resolution_'+idx" v-if="!isArbitrator" :message="message" :proposal="resolvedProposal"/>
      </div>
      <template v-if="allowChat">
        <template v-if="showingChatBox">
          <div class="dispute__divider"></div>
          <ChatBox @cancel="cancelChat"/>
        </template>
        <div v-else class="dispute__reply-button" @click="toggleReply">Reply</div>
      </template>
    </div>
    <div class="dispute__footer" v-if="dispute.can_mark_resolved">
      <div class="dispute__mark-resolve-wrapper">
        <input type="checkbox" :id="'mark-resolved-for-' + dispute.id" @click="e => markResolved(e, dispute)"
               :disabled="disputeIsClosedOrResolved(dispute)"
               :checked="disputeIsClosedOrResolved(dispute)"/>
        <label :for="'mark-resolved-for-'+dispute.id">Mark issue as resolved</label>
      </div>
      <div v-if="canRequestArbitrator && dispute.in_program && isBuyer" class="dispute__request-arbitration-wrapper">
        <button class="btn pf-text-button" v-if="canRequestArbitratorIn <= 0" @click="requestArbitrator">Request arbitration
        </button>
        <div v-else>Arbitration available in {{ canRequestArbitratorIn }} days</div>
      </div>

    </div>
  </div>

</template>
<script>
import Message from 'shared/vue_components/arbitration/components/_message.vue'
import ResolutionCard from 'shared/vue_components/arbitration/components/_resolution_proposal_card.vue'
import ChatBox from 'shared/vue_components/arbitration/components/_chat_box.vue'
import File from 'shared/vue_components/arbitration/components/_dispute_file.vue'
import ProposalModal from 'shared/vue_components/arbitration/components/_proposal_modal.vue'
import ResolutionModal from 'shared/vue_components/arbitration/components/_resolution_modal.vue'
import MarkResolvedModal from 'shared/vue_components/arbitration/components/_mark_resolved_modal.vue'
import {disputeIsClosedOrResolved, getFileIcons} from 'shared/vue_components/arbitration/helpers'
import {customFormat} from "shared/utils/timeago";
import {computed} from 'vue'

export default {
  components: {Message, ChatBox, ProposalModal, ResolutionCard, MarkResolvedModal, ResolutionModal, File},
  props: {
    dispute: {
      type: Object,
      required: true
    },
    fullVersion: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      proposalModal: false,
      resolutionModal: false,
      markResolvedModal: false,
      proposal: null,
      replyToggler: false,
    }
  },
  inject: ['actions'],
  provide() {
    return {
      userParticipantId: this.dispute.user_participant_id,
      counterPropose: this.counterPropose,
      dispute: computed(() => this.dispute),
      disputeActions: [ // set the booleans to enable the actions base on dispute.user_role
        {
          name: 'Add files',
          action: (dispute) => {
            document.getElementById('file_upload_' + dispute.id).click()
          },
          buyer: true,
          seller: true,
          arbitrator: false,
        },
        {
          name: 'Propose resolution',
          action: this.toggleProposalModal,
          seller: !this.didSellerPropose,
          buyer: false,
        },
        {
          name: 'Deliver resolution',
          action: this.toggleResolutionModal,
          seller: false,
          buyer: false,
          arbitrator: !this.disputeHasResolution,
        }
      ],
    }
  },
  computed: {
    createdAt() {
      return customFormat(this.dispute.created_at)('datetime')
    },
    sourceData() {
      if (!this.dispute.source) return null;
      const { type, id, url } = this.dispute.source
      return { type, id, url }
    },
    isArbitrator() {
      return this.dispute.user_role === 'arbitrator'
    },
    isBuyer(){
      return this.dispute.user_role === 'buyer'
    },
    allowChat() {
      return !!this.dispute.allow_chat
    },
    messages() {
      return this.fullVersion ? this.dispute.messages : this.dispute.messages.slice(-5)
    },
    files() {
      return this.dispute.files ? getFileIcons(this.dispute.files) : []
    },
    resolvedProposal() {
      const messageWithProposal = this.dispute.messages.find(message => message.proposal && (['accepted', 'rejected'].includes(message.proposal.status)))
      return messageWithProposal && messageWithProposal.proposal
    },
    didSellerPropose() {
      return this.dispute.messages.some(message => !!message.proposal && message.sender.participant_role === 'seller')
    },
    disputeHasResolution() {
      return !!this.dispute.resolution
    },
    showingChatBox() {
      return this.fullVersion || this.replyToggler
    },
    canRequestArbitrator(){
      return !!this.dispute.can_request_arbitrator_at
    },
    canRequestArbitratorIn() {
      if (!this.dispute.can_request_arbitrator_at) return 0
      const diff = new Date(this.dispute.can_request_arbitrator_at) - new Date()
      return Math.ceil(diff / (1000 * 60 * 60 * 24))
    }
  },
  methods: {
    toggleProposalModal() {
      this.proposalModal = !this.proposalModal
    },
    toggleResolutionModal() {
      this.resolutionModal = !this.resolutionModal
    },
    toggleReply() {
      this.replyToggler = !this.replyToggler
    },
    cancelChat() {
      this.toggleReply()
      this.$emit('cancel')
    },
    counterPropose(proposal) {
      this.proposal = proposal
      this.toggleProposalModal()
    },
    senderPoV(message) {
      if (message.sender.participant_role === this.dispute.user_role) return 'first'
      if (message.sender.participant_role === 'arbitrator') return 'third'
      return 'second'
    },
    disputeIsClosedOrResolved(dispute) {
      return disputeIsClosedOrResolved(dispute)
    },
    confirmResolve(disputeId, accepted) {
      this.markResolvedModal = false;
      if (!accepted) return;
      if (!disputeId) return;
      this.actions.updateDispute({
        disputeId: disputeId,
        new_dispute: true,
        actionType: 'resolve'
      })
    },
    markResolved(e, dispute) {
      if (disputeIsClosedOrResolved(dispute)) return;
      e.target.checked = false;
      this.markResolvedModal = true;
    },
    requestArbitrator() {
      this.actions.requestArbitrator({disputeId: this.dispute.id, new_dispute: true})
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.dispute__body {
  padding: 20px;
  font-weight: 400;
  border-radius: 12px;
  color: $primaryFontColor;
  background-color: #F1FAFA;
}

.dispute__created-at {
  font-size: 14px;
  color: $secondaryFontColor;
}

.dispute__info {
  margin-top: 12px;
}

.dispute__message-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dispute__divider {
  background: #E4E4E4;
  height: 1px;
  margin: 20px 0;
}

.dispute__reply-button {
  margin-top: 12px;
  text-decoration: underline;
  color: $brandColor;
  cursor: pointer;
  width: fit-content;
}

.dispute__footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dispute__mark-resolve-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;

  input {
    width: 18px;
    height: 18px;
    margin: 0;
    accent-color: $brandColor;
  }

  label {
    font-weight: 400;
  }
}

.dispute__request-arbitration-wrapper {
  color: $brandColor;
  font-weight: 400;
}
</style>
