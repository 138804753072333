<template>
  <div class='feed-card-header'>
    <div class='feed-card-header__img-wrapper'>
      <router-link :to="shopLink">
        <img v-if="shop.logo_url" :src="shop.logo_url" class="feed-card-header__img" alt='Store logo' loading='lazy'>
        <div v-else class='feed-card-header__img-wrapper material-icons'>store</div>
      </router-link>
    </div>

    <div class='feed-card-header__info-wrapper'>
      <router-link class='feed-card-header__title' :to="shopLink">{{shop.name}}</router-link>
      <div v-if="shop.number_of_reviews > 0" class="feed-card-header__ratings-reviews-wrapper">
        <StarRating :rating='shop.average_rating' />
        <div class="feed-card-header__number-of-reviews">
          {{ $sharedUtils.pluralize(shop.number_of_reviews, 'Review') }}
        </div>
      </div>
      <div v-if='!shouldHideTimestamp' class='feed-card-header__timestamp'>
        {{ createdAtRelative }}
      </div>
    </div>

    <p v-if='description' class='feed-card-header__description'>
      {{ description }}
    </p>
    <div class='feed-card-header__divider'>
    </div>
  </div>
</template>

<script>
  import {customFormat} from 'shared/utils/timeago';
  import StarRating from 'rs/components/common/star_rating';

  export default {
    props: ['feedEvent', 'shop', 'description', 'shouldHideTimestamp'],
    components: { StarRating },
    computed: {
      createdAtRelative() {
        return customFormat(this.feedEvent.event_created_at)()
      },
      shopLink(){
        return { name: this.$routes.shop.name, params: { shop_slug: this.shop.review_site_slug || this.feedEvent.event_item.shop_slug } }
      }
    },
  }
</script>

<style lang="scss">
  .feed-card-header__ratings-reviews-wrapper {
    .star {
      font-size: 13px;
    }
  }
</style>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .feed-card-header__img-wrapper {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-right: 8px;
    border-radius: 5px;
    border: $borderStyle;
    vertical-align: top;

    &.material-icons {
      line-height: 60px;
      font-size: 42px;
      text-align: center;
      background: $backgroundColor;
      color: $secondaryFontColor;
    }
  }

  .feed-card-header__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .feed-card-header__info-wrapper {
    display: inline-block;
    width: calc(100% - 60px - 8px);
    vertical-align: top;
  }

  .feed-card-header__title {
    display: block;
    font-weight: 600;
    font-size: 16px;
    @include text-elipsis;
    white-space: break-spaces;
    text-decoration: none;
    color: $primaryFontColor;
  }

  .feed-card-header__ratings-reviews-wrapper {
    display: flex;
    gap: 0 4px;
    align-items: baseline;
  }

  .feed-card-header__number-of-reviews {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: $primaryFontColor;
  }

  .feed-card-header__timestamp {
    font-size: 12px;
    color: $secondaryFontColor;
  }

  .feed-card-header__description {
    margin-top: 12px;
    font-size: 16px;
    color: $secondaryFontColor;
  }

  .feed-card-header__divider {
    height: 1px;
    width: calc(100% + 16px + 16px);
    margin-left: -16px; // Padding of the card
    margin-top: 20px;
    margin-bottom: 20px;
    background: $feedCardDividerColor;
  }
</style>
