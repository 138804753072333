import countryMap from 'shared/constants/country_list_map';
import sharedUtils from 'shared/utils/utils'

export const normalizeAddress = address => {
  return {
    isBillingAddress: !!address.billing_address,
    addressLine1: address.address_line_1,
    addressLine2: address.address_line_2,
    city: address.city,
    postcode: address.postcode,
    countryCode: address.country,
    countryName: countryMap[address.country]
  }
}

export const verifiedFields = shopData => {
  const fields = {
    'confirmed_contact': 'Contact details',
    'confirmed_addresses': 'Address',
  } // these fields are serialized from Public Shop Profile
  if (!shopData) return null;

  const verifiedFields = []
  Object.keys(fields).forEach(key => {
    if (shopData[key]) verifiedFields.push(fields[key])
  })
  return verifiedFields.length > 0 ? verifiedFields : null
}

export const shopResponseTime = contactInfo => {
  try {
    if (!contactInfo) return null;
    const str = contactInfo.response_time
    if (!str) return null;
    const [value, unit] = str.split(' ');
    if (!value || !unit) return null;
    return sharedUtils.pluralize(parseInt(value), unit)
  } catch (e) {
    return null
  }
}
