<template>
  <div class='star-rating' :aria-label="ratingFloat.toFixed(2) + ' star'">
    <div class="star-rating-chooser-wrapper" ref="chooser" @mouseenter="handleHover" @mouseleave="handleMouseOut" @click="handleClick">
      <span v-for="(className, index) in starClasses" :ref="'star'+index"
            v-bind:key="index" class='star' :class='"star--" + className'></span>
    </div>
  </div>
</template>

<script>
import { times } from 'lodash'

const roundHalf = (num) => { return Math.round(num * 2) / 2 };

export default {
  props: ['defaultRating'],
  data(){
    return {
      rating: this.defaultRating,
    }
  },

  methods: {
    handleHover(){
      this.$el.addEventListener('mousemove', this.mouseMove, false);
    },
    handleMouseOut(){
      this.rating = this.defaultRating;
      this.$el.removeEventListener('mousemove', this.mouseMove, false);
    },
    mouseMove(e) {
      const refWidth = this.$refs.chooser.getBoundingClientRect().width;
      const offsetX = this.$refs.chooser.getBoundingClientRect().x;
      let newRating = roundHalf((e.clientX -offsetX) / 0.2 / refWidth)
      if (newRating > 5) {
        newRating = 5
      }
      if (newRating !== this.rating) {
        this.rating = newRating
      }
    },
    handleClick(){
      if (this.rating !== this.defaultRating) {
        this.$emit('change', this.rating)
      }
    },
  },
  beforeDestroy() {
    this.$el.removeEventListener('mousemove', this.mouseMove, false);
  },
  computed: {
    ratingFloat() { return parseFloat(this.rating) },
    starClasses() {
      const vote = roundHalf(this.ratingFloat)
      const fullStarsCount = Math.floor(vote)
      const haveHalfStar = vote % 1 == 0.5
      let starCountLeft = 5
      const starClasses = []

      // Adapt the logic to calculate stars from app/assets/javascripts/shared/stars_builder.js.coffee
      times(fullStarsCount, (i) => {
        starClasses.push('on')
        starCountLeft--
      })

      if (haveHalfStar) {
        starClasses.push('half')
        starCountLeft--
      }

      times(starCountLeft, (i) => {
        starClasses.push('off')
      })

      return starClasses
    },
  },
  watch: {
    'defaultRating'(v){
        this.rating = v;  // handle mobile case, when reset value (changing states only)
    }
  }
}
</script>

<style lang="scss" scoped>
  .star-rating-chooser-wrapper {
    width: fit-content;
    cursor: pointer;
  }
.star-rating {
  font-size: 0; // To remove the empty space below the stars
}

</style>
