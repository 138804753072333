<template>
  <div v-if="!featuredReviewData"></div>
  <section v-else class="home-reviews-section" :style="plainBackground">
    <Modal v-if="reviewModalData" :data="reviewModalData" @closeModal="toggleReviewModal(null)"/>
    <img v-if="validBackgroundImage && backgroundImage" class="hrs-background-image" :src="backgroundImage"
         alt="collection background" @error="validBackgroundImage = false"/>
    <div class="hrs-wrapper has-max-width">
      <h1 class="hrs-title">{{ featuredReviewData.external_title }}</h1>
      <div class="hrs-cards-list">
        <swiper class="hrs__cards-wrapper"
                ref="reviewsSwiper"
                :options="swiperOptions"
                :mousewheel="true">
          <swiper-slide v-for="(card, index) in featuredReviewData.items" :key="index">
            <Card :card="card" :key="`review-card-${index}`" @bodyClick="toggleReviewModal(card)" />
          </swiper-slide>
        </swiper>
        <div class="swiper-button-next hrs__swiper-button-next"></div>
        <div class="swiper-button-prev hrs__swiper-button-prev"></div>
        <div class="swiper-pagination hrs__swiper-pagination"></div>
      </div>
    </div>
  </section>
</template>
<script>
import Card from 'rs/components/pages/reviews/_review_card';
import Modal from 'rs/components/pages/reviews/_review_modal';

export default {
  components: {Card, Modal},
  inject: ['mediaQueries'],
  data() {
    return {
      reviewModalData: null,
      validBackgroundImage: true,
    }
  },
  computed: {
    swiperOptions() {
      return {
        autoUpdate: true,
        autoDestroy: true,
        breakpoints: {
          '767': {
            slidesPerView: 2.1,
            slidesPerGroup: 2,
          },
          '890': {
            slidesPerView: 2.3,
            slidesPerGroup: 2,
          },
          '990': {
            slidesPerView: 3.3,
            slidesPerGroup: 3,
          },
          '1180': {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 24,
          }
        },
        slidesPerView: 1.1,
        slidesPerGroup: 1,
        spaceBetween: 16,
        deleteInstanceOnDestroy: true,
        cleanupStylesOnDestroy: true,
        navigation: {
          nextEl: ".hrs__swiper-button-next",
          prevEl: ".hrs__swiper-button-prev"
        },
        pagination: {
          el: ".hrs__swiper-pagination",
          type: 'fraction',
          formatFractionCurrent: number => number,
          formatFractionTotal: number => number,
          renderFraction: (current, total) => {
            return ('<span class="' + current + '"> </span>' +
                ' / ' +
                '<span class="' + total + '"> </span>')
          },
        }
      }
    },
    swiper() {
      return this.$refs.reviewsSwiper.$swiper
    },
    featuredReviewData() {
      return this.$store.getters['Home/featuredReviewsData']
    },
    hasBackground() {
      return (this.featuredReviewData && this.featuredReviewData.background)
    },
    plainBackground() {
      return (this.hasBackground && this.featuredReviewData.background.startsWith('#') && {
        backgroundColor: this.featuredReviewData.background
      })
    },
    backgroundImage() {
      return (this.hasBackground && !this.plainBackground && this.featuredReviewData.background)
    },
  },
  methods: {
    toggleReviewModal(card = null) {
      this.reviewModalData = card;
    },
  },
}
</script>
<style lang="scss" scoped>
@import "~PlatformStyles/abstract/mixins";
@import '~PlatformStyles/abstract/variables_new';


.home-reviews-section {
  position: relative;
  left: calc(-50vw + 50%);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  margin-bottom: 40px; // for pagination buttons
}

.hrs-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 12px;
  padding: 44px 0 0 0;
}

.hrs-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  object-fit: cover;
  backdrop-filter: blur(5px);
}

.hrs-title {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.hrs-cards-list {
  width: 100%;
  display: flex;
}

.hrs__cards-wrapper {
  overflow: hidden;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  background: transparent;
  @include respond-to(desktop) {
    padding: 20px 4px; // allow card's box shadow to appear
  }
  @include respond-to(notdesktop) {
    padding: 20px 16px; // allow card's box shadow to appear
  }
}

.hrs__swiper-button-next, .hrs__swiper-button-prev {
  background: #333333;
  border-radius: 50%;
  color: white;
  font-weight: 700;
  z-index: 1;
  height: 40px;
  width: 40px;
  left: auto;
  top: auto;
  bottom: -36px;
}

.hrs__swiper-button-next {
  right: 10px;
}

.hrs__swiper-button-prev {
  right: 62px; // 40 size + 10 from other icon <right> + 12 space
}

.hrs__swiper-button-next::after,
.hrs__swiper-button-prev::after {
  font-size: 18px;
}

.hrs__swiper-button-next::after {
  margin-left: 4px;
}

.hrs__swiper-button-prev::after {
  margin-left: -4px
}

.hrs__swiper-pagination {
  z-index: 1;
  right: 114px;
  left: auto;
  top: auto;
  bottom: -36px;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-self: center;
  vertical-align: middle;
  align-items: center;
}

</style>
