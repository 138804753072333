<template>
  <div class='star-rating' :aria-label="ratingFloat.toFixed(2) + ' star'">
    <span v-for="(className, index) in starClasses" v-bind:key="index" class='star' :class='"star--" + className'></span>
  </div>
</template>

<script>
  import { times } from 'lodash'

  const roundHalf = (num) => { return Math.round(num * 2) / 2 };

  export default {
    props: ['rating'],

    computed: {
      ratingFloat() { return parseFloat(this.rating) },
      starClasses() {
        const vote = roundHalf(this.ratingFloat)
        const fullStarsCount = Math.floor(vote)
        const haveHalfStar = vote % 1 == 0.5
        let starCountLeft = 5
        const starClasses = []

        // Adapt the logic to calculate stars from app/assets/javascripts/shared/stars_builder.js.coffee
        times(fullStarsCount, (i) => {
          starClasses.push('on')
          starCountLeft--
        })

        if (haveHalfStar) {
          starClasses.push('half')
          starCountLeft--
        }

        times(starCountLeft, (i) => {
          starClasses.push('off')
        })

        return starClasses
      },
    }
  }
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';

  .star-rating {
    font-size: 14px; // To remove the empty space below the stars
    .star {
      color: $newBrandColor;
    }
  }
</style>
