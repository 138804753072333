<template>
  <div class='feed-cards-filters'>
    <div class='feed-cards-filters__title'>Events you see:</div>
    <div v-for="(filterData, filterKey) in filters" class='feed-cards-filters__wrapper' v-bind:key="filterKey">
      <div class='feed-cards-filter'>
        <input v-model="filters[filterKey].value" type="checkbox" v-bind:name="filterKey" v-bind:id="filterKey" v-bind:key="filterKey">
        <label v-bind:for="filterKey">{{ filterData.label }}</label>
      </div>
    </div>

    <div class='feed-cards-filters__btn-wrapper'>
      <div class='feed-cards-filters__btn btn' @click="refreshFeedEvents">
        <!-- TODO: change copy to 'Explore Feeds' after mvp -->
        Update
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      refreshFeedEvents: function() {
        this.$router.replace({
          query: {
            ...this.$route.query,
            user_events: this.filters.user_events.value,
            store_events: this.filters.store_events.value,
            product_events: this.filters.product_events.value,
          },
        })
      },
    },

    data () {
      return {
        filters: {
          'user_events': { label: 'User Events', value: (this.$route.query.user_events != 'false') },
          'store_events': { label: 'Store Events', value: (this.$route.query.store_events != 'false') },
          'product_events': { label: 'Product Events', value: (this.$route.query.product_events != 'false') },
        }
      }
    },

    watch: {
      // Watch if URL changes these params, we will update data of this component accordingly.
      '$route.query.user_events': function(val) { this.filters.user_events.value = val; },
      '$route.query.store_events': function(val) { this.filters.store_events.value = val; },
      '$route.query.product_events': function(val) { this.filters.product_events.value = val; },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .feed-cards-filters {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    font-size: 16px;
  }

  .feed-cards-filters__title {
    color: $secondaryFontColor;
    margin-bottom: 12px;
  }

  .feed-cards-filters__wrapper {
    color: $brandColor;
    display: inline-block;
    width: 50%;
  }

  .feed-cards-filter {
    > input {
      vertical-align: middle;
      margin: 0;
      width: 16px;
      height: 16px;
      border: none;
    }

    > label {
      padding: 12px 0 12px 4px;
      vertical-align: middle;
    }
  }

  .feed-cards-filter > input:checked {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    font-family: system-ui;

    &::before {
      content: '\2713';
      display: block;
      text-align: center;
      font-size: 12px;
    }

    &:checked {
      color: white;
      background: $brandColor;
    }
  }

  .feed-cards-filters__btn-wrapper {
    border-top: $borderStyle;
    margin-top: 8px;
    padding-top: 16px;
  }

  .feed-cards-filters .feed-cards-filters__btn {
    width: 100%;
  }

  .feed-cards-filters__btn:hover {
    background: $brandColor;
    color: white;
  }

  @include respond-to(notmobile) {
    .feed-cards-filters__title {
      display: inline-block;
      width: 24%;
      padding-right: 1%;
    }

    .feed-cards-filters__wrapper {
      width: 24%;
      padding-right: 1%;
    }

    .feed-cards-filter {
      > label {
        padding: 0 0 0 4px;
      }
    }
  }
</style>
