<template>
  <div class="order-card">
    <div class="d-flex-column gap-1">
      <div class="oc__heading">Order Number</div>
      <div>{{ card.name }}</div>
    </div>
    <div class="oc__group-wrapper" v-for="item in card.line_items" :key="item.public_id">
      <div class="oc__group-wrapper--left">
        <div class="oc__flex-item--small oc__heading">Item</div>
        <div class="oc__flex-item--large oc__heading">&nbsp;</div>
        <img :src="item.product_image_url" data-value class="oc__image oc__flex-item--small"/>
        <div class="oc__flex-item--large d-flex align-center" data-value>
          <component class="oc__name" v-bind="productLinkComponent(item)">{{ item.product_title }}</component>
        </div>
        <component data-cta v-bind="productLinkComponent(item, true)">
          {{ item.review_public_id ? 'Edit your review' : 'Write a Review' }}
        </component>
      </div>
      <div class="oc__group-wrapper--right">
        <div class="oc__heading">Qty</div>
        <div class="d-flex align-center" data-value>{{ item.quantity }}</div>
      </div>
    </div>
    <div class="oc__group-wrapper">
      <div class="oc__group-wrapper--left">
        <div class="oc__flex-item--small oc__heading">Store</div>
        <div class="oc__flex-item--large oc__heading">&nbsp;</div>
        <!-- for next phase -->
        <img :src="card.shop_image_url" class="oc__image oc__image--shop oc__flex-item--small" data-value/>
        <div class="oc__flex-item--large d-flex align-center" data-value>
          <router-link v-if="card.shop_use_review_site" class="oc__name" :to="card.shop_internal_path">{{
              card.shop_name
            }}
          </router-link>
          <ExternalLink v-else class="oc__name" :href="card.shop_url" :text="card.shop_name"/>
        </div>
      </div>
    </div>
    <div class="d-flex-column gap-1">
      <div class="oc__heading">{{ createdAt.label }}</div>
      <div>{{ createdAt.value }}</div>
    </div>
  </div>
</template>
<script>
import ExternalLink from 'rs/components/common/external_link'

export default {
  components: {ExternalLink},
  props: ['card'],
  computed: {
    createdAt() {
      return this.card.placed_at
        ? {label: 'Order Date', value: this.card.placed_at}
        : {label: 'Processed on', value: this.card.fulfilled_at}
    }
  },
  methods: {
    productLinkComponent(item, writeReview = false) {
      if (!writeReview) { // product Link (not write review link)
        if (item.product_in_store && this.card.shop_use_review_site) {
          return {
            is: 'a',
            target: '_blank',
            href: item.product_internal_path,
          }
        }
        return {
          is: 'a',
          target: '_blank',
          href: item.product_url,
        }
      }

      if (item.review_public_id) {
        return {
          class: 'btn pf-primary-button',
          is: 'a',
          href: `/profile/reviews/${item.review_public_id}/edit`,
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      }

      if (item.product_reviewable && item.product_in_store && this.card.shop_use_review_site) {
        return {
          class: "btn pf-secondary-button",
          is: 'a',
          href: item.product_internal_path + "?action=write-review",
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      }

      return {
        class: "btn pf-secondary-button",
        is: 'a',
        href: this.$router.resolve({
          name: this.$routes.writeReview.children[1].name,
          query: {line_item_public_id: item.public_id}
        }).href,
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    }
  }
}

</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.order-card {
  border-bottom: 1px solid $feedCardDividerColor;
  width: auto;
  margin: 0;
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.oc__image {
  height: 48px;
  width: 48px;
  object-fit: cover;
}

.oc__image--shop {
  border-radius: 50%;
  background-image: url("https://pub-images.judge.me/judgeme/product/no-image.png");
  background-size: 48px;
}

.oc__group-wrapper {
  display: flex;
  @include respond-to(mobile) {
    flex-direction: column;
    row-gap: 20px;
  }
  width: 100%;

  [data-value] {
    color: $primaryFontColor;
    height: 48px;
  }

  [data-cta] {
    margin-top: 12px;
    width: 220px;
    height: 40px;
  }

  .oc__name {
    color: $brandColor;
  }
}

.oc__group-wrapper--left {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 4px;
  flex: 0 1 80%;
}

.oc__group-wrapper--right {
  display: flex;
  flex-direction: column;
  flex: 0 1 20%;
}

.oc__flex-item--large {
  flex: 0 1 calc(100% - 50px - 16px);
}

.oc__flex-item--small {
  flex: 0 1 48px;
}

.oc__heading {
  color: $secondaryFontColor;
  height: 24px;
}

</style>
