<template>
  <div class="dispute-form__wrapper">
    <div class="df__section-wrapper">
      <div class="df__section">
        <h2 class="df__text">{{ pageData.formHeader }}</h2>
        <p class="df__text">{{ pageData.formSubHeader }}</p>
        <Dropdown>
          <select
            v-model="selectedDisputeTypeIdx"
            class="df__dropdown"
            :class="{'df__invalid-field': validated && !submittedFields.disputeType}">
            <option v-for="(option, idx) in mainDisputeTypeOptions" :value="idx">{{ option.text }}</option>
          </select>
        </Dropdown>
        <div v-if="subDisputeTypeOptions && typeof subDisputeTypeOptions == 'object'"
             class="df__radio-field"
             :class="{'df__invalid-field': validated && !submittedFields.disputeType}">
          <div v-for="(option, idx) in subDisputeTypeOptions" class="df__radio-option">
            <input
              type="radio"
              :id="'dispute-type-'+idx"
              :value="option.value"
              v-model="formInputs.disputeType"
            />
            <label :for="'dispute-type-'+idx" class="df__radio-label">{{ option.text }}</label>
          </div>
        </div>
        <div class="df__textarea-field">
          <textarea v-model="formInputs.description"
                    :maxlength="2000"
                    class="df__textarea"
                    :class="{'df__invalid-field': validated && !submittedFields.description}"
                    cols="30" rows="10" :placeholder="pageData.descriptionPlaceholder"/>
        </div>
        <Dropdown>
          <select
            v-model="outcomeTypeIdx"
            class="df__dropdown"
            :class="{'df__invalid-field': validated && !submittedFields.outcomeType}">
            <option v-for="(option, idx) in outcomeTypeOptions" :value="idx">{{ option.text }}</option>
          </select>
        </Dropdown>
        <div v-if="requireOutcomeDescription">
          <textarea v-model="formInputs.outcomeDescription"
                    :maxlength="2000"
                    class="df__textarea"
                    :class="{'df__invalid-field': !submittedFields.outcomeDescription}"
                    cols="30" rows="10" :placeholder="pageData.outcomeDescriptionPlaceholder"/>
          <div class="df__warning-box">
            <CircleIcon icon="info" class="df__warning-icon"/>
            <div>{{ pageData.otherOutcomeWarning }}</div>
          </div>
        </div>
        <p class="df__text">{{ pageData.uploadHeader }}</p>
        <input id="inputFile" ref="inputFile" type="file" multiple class="hidden" :accept="allowedMimeTypes.join(',')"
               @change="e => inputFileHandler(e.target)"/>
        <div class="df__upload-wrapper">
          <div class="df__upload-box" ref="uploadBox">
            <iconify icon="bx:bx-upload" height="40" color="#637381"/>
          </div>
          <div class="df__preview-box" v-for="(uniqName, idx) in inputFileNames" :key="idx+uniqName">
            <div class="df__preview-overlay">
              <div v-if="progressHash[uniqName] === 100" class="df__preview-finished-wrapper">
                <iconify v-if="progressHash[uniqName] === 100" icon="gg:check-o" class="df__preview-finished"/>
              </div>
              <RatingProgressBar
                v-else
                class="df__preview-progress"
                :activePercentage="progressHash[uniqName]"/>
              <div class="d-flex-center df__remove-button" @click="removeFile(uniqName)">
                <iconify icon="mdi:trash" height="16" class="df__remove-icon"/>
              </div>
            </div>
            <img v-if="originalFileFromHash(uniqName).type.startsWith('image/')"
                 class="df__preview-item" :src="createImageFromFile(originalFileFromHash(uniqName))"/>
            <svg-icon class="df__preview-item" v-else :icon="iconFromUrl(originalFileFromHash(uniqName).name)"/>
          </div>
        </div>
        <div class="d-flex-column">
          <label class='align-self-center rs-font-14' for="inputFile">{{ pageData.uploadTextLine1 }}</label>
          <label class='align-self-center rs-font-14' for="inputFile">{{ pageData.uploadTextLine2 }}</label>
          <label class='align-self-center rs-font-14' for="inputFile">{{ pageData.uploadTextLine3 }}</label>
        </div>
      </div>
    </div>
    <div class="df__section-wrapper">
      <div class="df__section">
        <h2 class="df__text">{{ pageData.orderHeader }}</h2>
        <p class="df__text">{{ pageData.orderSubHeader }}</p>
        <h4 class="df__text">Order info</h4>
        <Dropdown>
          <select
            v-model="sourceIdx"
            class="df__dropdown"
            :class="{'df__invalid-field': validated && !submittedFields.sourceId}">
            <option v-for="(option, idx) in orderOptions" :value="sourceIdx">
              <span>Order No. {{ option.external_id }} on {{ option.placed_at }}</span>
            </option>
          </select>
        </Dropdown>
      </div>
    </div>
    <div class="df__terms-wrapper">
      <div class="df__terms-field">
        <input type="checkbox" v-model="acceptTerms" id="accept-terms"
               :class="{'df__invalid-field': validated && !acceptTerms}">
        <div>
          <label for="accept-terms">
            {{ pageData.agreeText }}<a :href="pageData.termLink" target="_blank">{{ pageData.termText }}</a>
          </label>
          <p class="df__text--sub mt-2">{{ pageData.termSubText }}</p>
        </div>
      </div>
    </div>
    <div class="df__cta-buttons">
      <button class="btn pf-primary-button df__btn-goback" @click="goback">Go Back</button>
      <button class="btn pf-secondary-button df__btn-submit"
              :disabled="!submittable || isSubmitting" @click="submitDispute">
        <LoadingDots v-if="isSubmitting" color="white"/>
        <template v-else>Submit Issue</template>
      </button>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import Dropdown from 'rs/components/common/dropdown'
import CircleIcon from 'rs/components/common/icons/custom_circle_icon';
import {customFormat} from 'shared/utils/timeago'
import RatingProgressBar from 'rs/components/common/rating_progress_bar';
import LoadingDots from 'rs/components/common/loading_dots';
import {LINKS} from 'shared/vue_components/arbitration/constants'
import {
  allowedFileTypes, 
  iconFromUrl,
  allowedMimeTypes,
} from 'shared/vue_components/arbitration/helpers'

const FILE_LIMIT = 10
const FILE_SIZE = 5 // MBytes

const pageData = {
  formHeader: 'Issue registration form',
  formSubHeader: 'Please take a moment to provide us with the necessary details about the issue you’ve encountered with this store.',
  descriptionPlaceholder: 'To help seller to resolve your issue faster please provide thorough description of the problem and your desired solution. Additionally, sharing the steps you’ve already taken and their outcomes can be helpful. If applicable, include a link to the breached merchant’s terms.',
  descriptionWarning: 'Please explain above what the issue was.',
  outcomeDescriptionPlaceholder: 'PLease let us know your preferred outcome',
  otherOutcomeWarning: 'Please explain above what your desired outcome is.',
  uploadHeader: 'Add photos, screenshots or any other files or documents to support you issue. (Optional)',
  uploadTextLine1: `Accepted formats: ${allowedFileTypes.join(', ').toUpperCase()}. Max size: ${FILE_SIZE}MB`,
  uploadTextLine2: 'Please note you can also add it later, if needed.',
  uploadTextLine3: `Max ${FILE_LIMIT} files.`,
  orderHeader: 'Please confirm the order where your issue occurred',
  orderSubHeader: 'Please check if the information below contains correct details about the order in which your issue relates.',
  agreeText: 'I have read and agree to the ',
  termText: 'Terms and Conditions.',
  termLink: LINKS.terms,
  termSubText: 'By checking this box, you confirm your understanding of our Terms and Conditions governing our services. You also acknowledge the authority of an impartial third-party arbitrator, whose decisions both parties must follow.',
}

function validateFiles(existingFilesCount, files) {
  return (existingFilesCount + files.length  <= FILE_LIMIT) &&
    files.every(file => allowedMimeTypes.includes(file.type)) &&
    files.every(file => file.size <= FILE_SIZE * 1024 * 1024)
}

export default {
  components: {Dropdown, CircleIcon, RatingProgressBar, LoadingDots},
  inject: ['actions'],
  computed: {
    ...mapGetters('Arbitration', ['disputeFormData', 'disputeTypes', 'outcomeTypes', 'orders']),
    pageData: () => pageData,
    allowedMimeTypes: () => allowedMimeTypes,
    mainDisputeTypeOptions() {
      return [{text: 'What is your issue related to?', value: ''}].concat(this.disputeTypes)
    },
    subDisputeTypeOptions() {
      return this.mainDisputeTypeOptions[this.selectedDisputeTypeIdx].value
    },
    outcomeTypeOptions() {
      return [{text: 'Select preferred outcome', value: ''}].concat(this.outcomeTypes)
    },
    orderOptions() {
      return this.orders.map(order => ({
        id: order.id,
        external_id: order.external_id,
        placed_at: order.placed_at ? customFormat(order.placed_at)('datetime') : 'Unknown'
      }))
    },
    submittedFields() {
      return {
        disputeType: this.formInputs.disputeType,
        description: this.formInputs.description.trim(),
        outcomeType: this.formInputs.outcomeType,
        outcomeDescription: this.formInputs.outcomeDescription.trim(),
        sourceId: this.orders[this.sourceIdx].id,
        files: Object.values(this.uploadedFilesHash).map(hash => hash.key)
      }
    },
    submittable() {
      if (this.isUploading || !this.acceptTerms) return false
      return Object.keys(this.submittedFields).every(key => {
        if (key === 'outcomeDescription') {
          return this.requireOutcomeDescription ? this.submittedFields[key] !== '' : true
        } else {
          return this.submittedFields[key] !== ''
        }
      })
    },
  },
  data() {
    return {
      requireOutcomeDescription: false,
      sourceIdx: 0,
      outcomeTypeIdx: 0,
      selectedDisputeTypeIdx: 0,
      validated: false,
      acceptTerms: false,
      isUploading: false,
      isSubmitting: false,
      formInputs: {
        disputeType: '',
        description: '',
        outcomeType: '',
        outcomeDescription: '',
        sourceId: '',
      },
      inputFilesHash: {},
      inputFileNames: [],
      uploadedFilesHash: {},
      progressHash: {},
    }
  },
  methods: {
    iconFromUrl,
    originalFileFromHash(uniqName) {
      return this.inputFilesHash[uniqName]
    },
    createImageFromFile(file) {
      return URL.createObjectURL(file)
    },
    validateForm() {
      this.validated = true;
      return this.submittable;
    },
    goback() {
      const {shop_slug, product_handle} = this.$route.params;
      this.$router.push({
        name: product_handle ? this.$routes.product.name : this.$routes.shop.name,
        params: {
          shop_slug,
          product_handle
        }
      })
    },
    submitDispute() {
      if (!this.validateForm()) return;
      this.isSubmitting = true;
      this.$store.dispatch('Arbitration/postDispute', this.submittedFields).then(() => {
        this.isSubmitting = false;
      })
    },
    async inputFileHandler(el) {
      const files = Array.from(el.files)

      if (!validateFiles(this.inputFileNames.length, files)) {
        alert(pageData.uploadTextLine1 + ' ' + pageData.uploadTextLine3);
        this.clearHtmlInput()
        return
      }
      const presignedData = await this.actions.getPresignedData(0)
      if (!presignedData) return;

      // start Upload
      this.isUploading = true;
      const progressHash = {}
      const inputFileNames = []
      const inputFilesHash = {}

      files.forEach(file => {
        const uniqName = `${file.name}__${file.size}__${Date.now()}` // create uniqName per file
        file.uniqName = uniqName; // attach uniqName to use independently
        file.callback = this.updateProgress.bind(this, uniqName) // attach upload callback per file
        inputFileNames.push(uniqName)
        progressHash[uniqName] = 0; // set progress to 0
        inputFilesHash[uniqName] = file
      });
      // use separate objects to keep Vue rerender when a key is updated
      this.progressHash = {...this.progressHash, ...progressHash}
      this.inputFileNames = [...this.inputFileNames, ...inputFileNames]
      this.inputFilesHash = {...this.inputFilesHash, ...inputFilesHash}
      const requests = files.map(file => this.actions
        .uploadFile(presignedData, file, file.callback)
        .then(this.updateUploadedFilesHash)
        .catch(() => this.removeFile(file.uniqName))
      )
      await Promise.all(requests)
      this.isUploading = false;
    },
    updateProgress(uniqName, progressEvent) {
      this.progressHash = {
        ...this.progressHash,
        [uniqName]: Math.round((100 * progressEvent.loaded) / progressEvent.total)
      }
    },
    updateUploadedFilesHash(uploadedFile) {
      // mutate object to store data only, no need to rerender.
      this.uploadedFilesHash[uploadedFile.uniqName] = uploadedFile
    },
    removeFile(uniqName) {
      delete this.progressHash[uniqName]
      delete this.inputFilesHash[uniqName]
      delete this.uploadedFilesHash[uniqName]
      this.inputFileNames = this.inputFileNames.filter(name => name !== uniqName)
      this.clearHtmlInput()
    },
    clearHtmlInput() {
      this.$refs.inputFile.files = (new DataTransfer()).files // clear html input files
    }
  },
  watch: {
    subDisputeTypeOptions(val) {
      this.formInputs.disputeType = typeof val === 'string' ? val : '';
    },
    outcomeTypeIdx(idx) {
      this.formInputs.outcomeType = this.outcomeTypeOptions[idx].value;
      this.requireOutcomeDescription = !!this.outcomeTypeOptions[idx].require_outcome_description;
    },
  },
  mounted() {
    this.$refs.uploadBox.addEventListener('dragover', e => e.preventDefault())
    this.$refs.uploadBox.addEventListener('dragenter', e => e.preventDefault())
    this.$refs.uploadBox.addEventListener('click', e => this.$refs.inputFile.click())
    this.$refs.uploadBox.addEventListener('drop', (evt) => {
      const inputEl = this.$refs.inputFile
      const dT = new DataTransfer();
      for (let i = 0; i < evt.dataTransfer.items.length; i++) {
        dT.items.add(evt.dataTransfer.files[0]);
      }
      inputEl.files = dT.files;
      this.inputFileHandler(inputEl)
      evt.preventDefault();
    })
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.dispute-form__wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: $primaryFontColor;
  font-weight: 400;
}

.df__section-wrapper {
  border-radius: 12px;
  border: 1px solid $newBorderColor;
  background: white;
  padding: 0 12px;
}

.df__text {
  text-align: center;
}

.df__text--sub {
  font-size: 14px;
  text-align: justify;
}

h2.df__text {
  font-weight: 700;
}

h4.df__text {
  font-weight: 700;
}

.df__section {
  width: 100%;
  max-width: 540px;
  margin: 44px auto 60px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  & > * {
    width: 100%;
  }
}

.df__invalid-field.df__invalid-field {
  border: 1px solid $dangerColor;
}

.df__dropdown {
  border-radius: 40px;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  background: transparent;
}

::v-deep .jdgm-select-arrow {
  right: 12px;
  top: 12px;
  color: $brandColor;
}

.df__textarea {
  border-radius: 20px;
  resize: vertical;
}

.df__radio-field {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px;
}

.df__radio-option {
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;

  input {
    width: 20px;
    height: 20px;
    margin: 0;
    accent-color: $brandColor;
  }

  label {
    font-weight: 400;
  }
}

.df__dropdown, .df__textarea {
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #E4E4E4;

  &:hover, &:focus {
    border: 1px solid $brandColor;
    outline: 1px solid $brandColor;
  }
}

.df__terms-field {
  display: flex;

  input {
    color: $primaryFontColor; // force black text for ios
    margin: 0;
    width: 18px;
    height: 18px;
    accent-color: $brandColor;
  }

  label {
    font-weight: 400;
  }

  div {
    margin-left: 8px;
  }
}

.df__cta-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.df__btn-goback {
  width: 178px;
  height: 50px;
}

.df__btn-submit {
  width: 343px;
  height: 50px;
}

.df__btn-submit[disabled] {
  opacity: 0.3;
  background-color: $brandColor;
  color: white;
}

.df__warning-box {
  border-radius: 5px;
  border: 1px solid $dangerColor;
  background: rgba(242, 132, 125, 0.20);
  display: flex;
  gap: 12px;
  padding: 8px 16px;
}

.df__warning-icon {
  border-color: $dangerColor;
  background: $dangerColor;
}

.df__upload-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
}

.df__upload-box {
  border: $borderStyle;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.df__preview-box {
  width: auto;
  height: auto;
  position: relative;
}

.df__preview-item {
  width: 100px;
  height: 100px;
  background: white;
  object-fit: cover;
}

.df__preview-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;

  &:hover {
    background: rgba(211, 211, 211, 0.9);
  }
}

.df__remove-button {
  position: absolute;
  top: 4px;
  right: 4px;
  background: white;
  cursor: pointer;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

  .df__remove-icon {
    color: $secondaryFontColor;
  }
}

.df__remove-button:hover {
  background: $brandColor;

  .df__remove-icon {
    color: white;
  }
}

.df__preview-progress {
  width: 80px;
  height: 16px;
}

.df__preview-finished-wrapper {
  background: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  -moz-animation: fadeAnimation 8s normal;
  -webkit-animation: fadeAnimation 8s normal;
  -o-animation: fadeAnimation 8s normal;
  animation: fadeAnimation 8s normal;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes fadeAnimation {
  from {
    background: rgba(211, 211, 211, 0.9);
  }
  to {
    background: transparent;
  }
}

@-webkit-keyframes fadeAnimation {
  from {
    background: rgba(211, 211, 211, 0.9);
  }
  to {
    background: transparent;
  }
}

.df__preview-finished {
  width: 80px;
  height: 80px;
  color: white;
  -moz-animation: flyUp 1s 3s ease;
  -webkit-animation: flyUp 1s 3s ease;
  -o-animation: flyUp 1s 3s ease;
  animation: flyUp 1s 3s ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes flyUp {
  to {
    transform: translateY(-200%);
  }
}

@-webkit-keyframes flyUp {
  to {
    transform: translateY(-200%);
  }
}
</style>
