<template functional>
  <div class="payment-method-icon payment-sprite payment-sprite-icon" :class="'payment-icon--' + props.slug"></div>
</template>
<script>
export default {
  props: {
    slug: {
      type: String,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
.payment-method-icon {
  height: 48px;
  width: 70px;
}
</style>
