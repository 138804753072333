<template>
  <TabWrapper>
    <MatchMedia v-slot="{mobile}">
      <h1 class="tab-info__title">
        Overview
        <TooltipPopover :name="tooltipsData.shopOverview.name">
          <template #icon><CircleIcon class="tab-info__overview-icon"/></template>
        </TooltipPopover>
      </h1>
      <div class="tab-info__feature-row">
        <iconify icon="ic:round-access-time-filled" class="tab-info__feature-icon"></iconify>
        <div class="w-100">Has collected reviews with Judge.me since {{ featureSince }}</div>
      </div>
      <TooltipPopover class="tab-info__feature-row" :name="tooltipsData.featureAskFromBuyers.name" v-if="featureActivelyAsk">
        <template #icon>
          <div class="tab-info__feature-row--inner">
            <iconify icon="ic:round-check-circle" class="tab-info__feature-icon"></iconify>
            <div class="w-100">Actively asks for reviews from buyers.</div>
          </div>
        </template>
      </TooltipPopover>

      <TooltipPopover class="tab-info__feature-row" :name="tooltipsData.featureAwesome.name" v-if="featureAwesome">
        <template #icon>
          <div class="tab-info__feature-row--inner">
            <iconify icon="tabler:shield-filled" class="tab-info__feature-icon"></iconify>
            <div class="w-100">Uses advanced features to collect and display reviews</div>
          </div>
        </template>
      </TooltipPopover>

      <WidgetSocial v-if="mobile" class="mt-4"/>
      <PolicyCard class="mt-4" policyName="refund_policy"/>
      <PolicyCard class="mt-4" policyName="shipping_policy"/>
      <PaymentMethodsCard class="mt-4" />
      <WidgetContact v-if="mobile" class="mt-4"/>
      <WidgetDispute v-if="mobile" class="mt-4"/>
      <AddressCard class="mt-4"/>
      <WidgetMedal :medals="medals" v-if="mobile && medals && medals.length > 0" class="mt-3"/>
    </MatchMedia>
  </TabWrapper>
</template>

<script>
import CircleIcon from 'rs/components/common/icons/custom_circle_icon'
import AddressCard from 'rs/components/pages/shop/tabs/_card_address'
import PolicyCard from 'rs/components/pages/shop/tabs/_card_policy'
import PaymentMethodsCard from 'rs/components/pages/shop/tabs/_card_payment_methods'
import TabWrapper from 'rs/components/pages/shop/tabs/_tab_wrapper';
import WidgetMedal from 'rs/components/pages/shop/widgets/_widget_medal';
import WidgetLink from 'rs/components/pages/shop/widgets/_widget_link';
import WidgetContact from 'rs/components/pages/shop/widgets/_widget_contact';
import WidgetSocial from 'rs/components/pages/shop/widgets/_widget_social';
import WidgetDispute from 'rs/components/pages/shop/widgets/_widget_dispute';
import {MatchMedia} from 'shared/vue_media_query/MatchMedia';
import TooltipPopover from 'rs/components/common/tooltip_popover';
import tooltipsData from 'shared/constants/tooltips_data.js'
import {storeName} from "rs/store/store_utils/shared";

export default {
  components: {
    MatchMedia, CircleIcon, TabWrapper, PaymentMethodsCard, AddressCard,
    WidgetMedal, WidgetLink, WidgetContact, WidgetSocial, PolicyCard, TooltipPopover,
    WidgetDispute,
  },
  computed: {
    tooltipsData: () => tooltipsData,
    storeName() {
      return storeName(this.$route)
    },
    shopData() {
      return this.$store.getters[this.storeName + '/shopData']
    },
    featureSince() {
      const since = this.shopData.features && this.shopData.features.collected_reviews_since
      const formatter = new Intl.DateTimeFormat('en-GB', {month: 'long', year: 'numeric'});
      return formatter.format(new Date(since))
    },
    medals() {
      return this.$store.getters['Shop/medals']
    },
    featureActivelyAsk() {
      return this.shopData.features && this.shopData.features.actively_ask_for_reviews
    },
    featureAwesome() {
      return this.shopData.features && this.shopData.features.advanced_features
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.tab-info {
  color: $primaryFontColor;
  font-size: 16px;
  font-weight: 400;
  padding: 28px 16px;
  @include respond-to(notmobile) {
    padding: 20px 28px;
  }
}

.tab-info__title {
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.tab-info__feature-row {
  width: 100%;
  border-radius: 24px;
  padding: 12px 20px;
  border: 1px solid #CBEDFA;
  background: linear-gradient(180deg, #F5F9FD 0%, #F8F9FA 100%);
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;

  &:nth-of-type(1) {
    margin-top: 12px;
  }
}

.tab-info__feature-row--inner {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tab-info__feature-icon {
  color: $oldBrandColor;
  width: 18px;
  height: 18px;
  @include respond-to(notdesktop) {
    align-self: flex-start;
    margin-top: 2px;
  }
}

.tab-info__overview-icon {
  height: 16px;
  width: 16px;
}
</style>
