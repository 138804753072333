import axios from 'shared/utils/axios'
import {getParamByISO, getParamByParam} from 'shared/constants/currency_map';
import {getExchangeRates, setExchangeRates, getLocale, setLocale} from 'shared/utils/local_storage';
import {fetchHomeData, CAROUSEL_TYPES} from 'rs/store/store_utils/home_utils'

const state = {
  currentReviewer: null,
  currentCountry: null,
  filterSidebarOpen: false,
  currency: '*',
  savedCurrency: '*',
  exchangeRates: null,
  categoriesData: null,
}
const getters = {
  filterSidebarOpen: state => state.filterSidebarOpen,
  currentReviewer: state => state.currentReviewer,
  currency: state => state.currency,
  currencySymbol: state => getParamByParam('currency', state.currency, 'symbol') || '$',
  exchangeRates: state => state.exchangeRates,
  didChangeCurrency: state => state.savedCurrency !== state.currency,
  allCurrencies: state => Object.keys(state.exchangeRates),
  categoriesData({categoriesData}) {
    if (!categoriesData) return ({ items: [] })
    return {
      heading: categoriesData.external_title,
      description: categoriesData.external_description,
      items: categoriesData.items
    }
  },
}

const mutations = {
  setReviewer: (state, newData) => {
    state.currentReviewer = newData.currentReviewer
    if (newData.currentReviewer && (newData.currentReviewer.avatar_image_url || newData.currentReviewer.avatar_retina_image_url)) {
      state.currentReviewer.avatar_image_url = {
        normal: newData.currentReviewer.avatar_image_url,
        retina: newData.currentReviewer.avatar_retina_image_url,
      }
    }
    state.currentCountry = newData.currentCountry
    localStorage.setItem('country', newData.currentCountry)
  },
  setCurrentCountry: (state, newData) => {
    state.currentCountry = newData
    localStorage.setItem('country', newData)
  },
  setExchangeRates: (state, exchangeRates) => {
    state.exchangeRates = exchangeRates
  },
  toggleFilterSidebar: (state, bool) => {
    if (bool) { // toggle lock scroll on background.
      document.body.classList.add('bm-overlay');
      document.body.classList.add('should-lock-scroll');
    } else {
      document.body.classList.remove('bm-overlay');
      document.body.classList.remove('should-lock-scroll');
    }
    state.filterSidebarOpen = bool;
  },
  setCurrency: (state, currency) => { state.currency = currency },
  saveFilterCurrency: (state) => { state.savedCurrency = state.currency },
  restoreFilterCurrency: (state) => {state.currency = state.savedCurrency},
  setCategoriesData: (state, categoriesData) => { state.categoriesData = categoriesData },
}

const actions = {
  fetchCurrentReviewer(context, payload) {
    if (payload.currency) {
      payload.currency = payload.currency.toUpperCase()
    }
    if (payload.country) {
      payload.country = payload.country.toUpperCase()
    }

    const params = {}
    let exchangeRates = getExchangeRates();
    if (!exchangeRates) {
      params.exchange_rates = true
    }

    let currency = null
    let currentCountry = null
    let currentReviewer = null
    let respondedExchangeRates = null
    axios.get('/me.json', {params})
      .then(function (response) {
        currentCountry = payload.country || response.data.current_country || getLocale('country')       // if logged in: prioritize url > database > ip address > storage when first load.
        currentReviewer = response.data
        respondedExchangeRates = response.data.exchange_rates
        currency = payload.currency || response.data.currency || getLocale('currency')                   // if logged in: prioritize url > database > ip address when first load.
      })
      .catch(err => { // not log in
        currentCountry = payload.country || getLocale('country') || err.response.data.current_country   // if not logged in: prioritize url > storage > ip address when first load.
        respondedExchangeRates = err.response.data.exchange_rates
        currency = payload.currency || getLocale('currency') || err.response.data.currency              // if not logged in: prioritize url > storage > ip address on first load.
      })
      .finally(()=>{
        context.commit('setReviewer', { currentReviewer, currentCountry,})
        if (!exchangeRates) {
          setExchangeRates(respondedExchangeRates)
          exchangeRates = respondedExchangeRates
        }
        context.dispatch('setupData', {currentCountry, exchangeRates, currency})
      })
  },

  setupData({commit, dispatch}, {currency, currentCountry, exchangeRates}){
    commit('setExchangeRates', exchangeRates)

    if (!currency) {
      currency = getParamByISO(currentCountry, 'currency')
    }
    currency = exchangeRates[currency] ? currency : '*'

    setLocale({currency, country: currentCountry})
    dispatch('getCategoriesData');
    dispatch('Meganav/getMeganavData', null, {root:true});
    commit('setCurrency', currency);
    commit('setCurrentCountry', currentCountry)
    commit('Search/setDefaultFilters', { country: currentCountry, currency: currency }, {root: true})           // to be used to reset filters to default only on first load.
  },

  updateCurrency({commit, dispatch}, currency) {
    setLocale({currency})
    commit('setCurrency', currency);
    dispatch('updateRsReviewer', {rs_currency: currency});
  },

  updateCurrentCountry({commit, dispatch}, country) {
    setLocale({country})
    commit('setCurrentCountry', country)
    dispatch('updateRsReviewer', {rs_country: country});
  },

  // updateRsReviewer updates backend only if country/currency (values gotten from both URL query and inside App actions) change/differ from backend (currentReviewer)
  // send '*' if value is '*'
  updateRsReviewer({state}, params) {
    if (!state.currentReviewer) return;
    if (Object.keys(params).length === 0) return;

    axios.put(`/profile/reviewers/0`, { // id doesn't matter here, randomly use 0;
      reviewer: params
    })
  },

  async getCategoriesData(context){
    const res =  await fetchHomeData(CAROUSEL_TYPES.category)
    context.commit('setCategoriesData', res)
  },
}


export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,

  namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
}
