<template>
  <section class="tab-listing" ref="component">
    <div class="tab-listing__menu">
      <div class="tab-listing__header">
        <div class="tab-listing__tab-button" @click="changeTab(idx)"
             :class="{ 'tab-listing__tab-button--active': activeTabIndex === idx }"
             v-for="(tab,idx) in tabs" :key="idx" v-if="!tab.hidden">
          <svg-icon v-if="tab.icon" class="tab-listing__tab-icon" :icon="tab.icon"
                    :color="iconColor(idx)"/>
          <span class="tab-listing__tab-button-text">{{ tab.text }}</span>
        </div>
      </div>
    </div>
    <slot name="content"></slot>
  </section>
</template>
<script>
import {storeName} from "rs/store/store_utils/shared.js";

export default {
  props: ['tabs', 'activeTabIndex'],
  computed: {
    storeName() {
      return storeName(this.$route)
    },
  },
  methods: {
    changeTab(idx) {
      this.$emit('changeTab', idx)
    },
    iconColor(idx){
      return this.activeTabIndex === idx ? 'white' : '#088484'
    }
  },
  mounted() {
    // register component to handle scroll globally
    this.$store.dispatch(this.storeName + '/registerTabListingComponent', this.$refs.component)
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.tab-listing {
  width: 100%;
  background: white;
  border-radius: 0;
  padding: 0 0 24px 0;
  overflow: hidden;
  @include respond-to(desktop) {
    border-radius: 12px;
    border: $newBorderStyle;
  }
}

.tab-listing__menu {
  padding: 20px 16px 0 16px;
  @include respond-to(desktop) {
    padding-right: 28px;
    padding-left: 28px;
  }
  @include respond-to(mobile) {
    padding-bottom: 20px;
    background: #F0F5F5;
  }
}

.tab-listing__header {
  width: 100%;
  overflow-x: auto;
  display: flex;
  gap: 12px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }

  @include respond-to(mobile) {
    left: calc(-50vw + 50%);
    margin-left: -16px;
    width: 100vw;
    padding: 0 16px;
  }
}

.tab-listing__tab-button {
  display: flex;
  min-width: max-content;
  padding: 8px 14px;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #E8E8E8;
  border-radius: 10px;
  background: white;
  color: $primaryFontColor;
  cursor: pointer;
}

.tab-listing__tab-button--active {
  background: linear-gradient(180deg, #339999 0%, #2D8396 100%);
  color: white;
}

.tab-listing__tab-button-text {
  align-self: center;
  margin-left: 4px;
}

.tab-listing__tab-icon {
  align-self: center;
}
</style>
