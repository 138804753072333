<template>
  <div v-if="!products" class="homepage-products homepage-products--center">
    <LoadingSpinner class="homepage-products__spinner"/>
  </div>
  <section v-else class="homepage-products">
    <div class="homepage-products__header">
      <div class="homepage-products__heading">
        {{ heading }}
      </div>
      <div v-if="description" class="homepage-products__description">
        {{ description }}
      </div>
    </div>
    <div class="homepage-products__cards">
      <swiper class="homepage-products__cards-wrapper"
              ref="productsSwiper"
              :options="swiperOptions"
              :mousewheel="true">
        <swiper-slide v-for="(searchResult, index) in products" :key="index">
          <ProductSearchCard class='homepage-products__product-card'
                             :searchResult="searchResult"
                             :disableTracking="true"
          />
        </swiper-slide>
      </swiper>
      <div class="swiper-button-next homepage-products__swiper-button-next"></div>
      <div class="swiper-button-prev homepage-products__swiper-button-prev"></div>
      <div class="swiper-pagination homepage-products__swiper-pagination"></div>
    </div>
  </section>
</template>

<script>
import LoadingSpinner from 'rs/components/common/loading_spinner'
import ProductSearchCard from 'rs/components/common/product_search_card_v2'

export default {
  components: {ProductSearchCard, LoadingSpinner},
  inject: ['mediaQueries'],
  computed: {
    swiperOptions()  {
      return {
        freeMode: this.mediaQueries.mobile,
        autoUpdate: true,
        autoDestroy: true,
        spaceBetween: 12,
        breakpoints: {
          '767': { // big screen mobile, show 2 cards with navigate buttons
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          '890': {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 20,
          },
          '1180': {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 20,
          }
        },
        slidesPerView: 2.1,
        slidesPerGroup: 1,
        deleteInstanceOnDestroy: true,
        cleanupStylesOnDestroy: true,
        navigation: {
          nextEl: ".homepage-products__swiper-button-next",
          prevEl: ".homepage-products__swiper-button-prev"
        },
        pagination: {
          el: ".homepage-products__swiper-pagination",
          type: 'fraction',
          formatFractionCurrent: number => number,
          formatFractionTotal: number => number,
          renderFraction: (current, total) =>  ('<span class="' + current + '"> </span>' +
              ' / ' +
              '<span class="' + total + '"> </span>'),
        }
      }
    },
    swiper() {
      return this.$refs.productsSwiper.$swiper
    },
    heading() { return this.$store.getters['Home/transparencyHeading'] || 'Full Transparency Products' } ,
    description() { return this.$store.getters['Home/transparencyDescription'] },
    products() { return this.$store.getters['Home/transparencyProducts'] },
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.homepage-products {
  padding-top: 40px;
  padding-bottom: 60px;
  @include respond-to(notdesktop) {
    padding-top: 60px;
    padding-bottom: 80px;
  }
}
.homepage-products--center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepage-products__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
  margin: 0 20px;
}
.homepage-products__heading {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.homepage-products__description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.homepage-products__cards {
  width: 100%;
  position: relative;
  margin: 32px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include respond-to(notdesktop) {
    margin: 28px -12px 28px 0;
    width: calc(100% + 12px);
  }
}
.homepage-products__cards-wrapper {
  padding: 5px 4px;  // allow card's box shadow to appear
  overflow: hidden;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  @include respond-to(desktop) {
    margin: 0 80px;
  }
}
.homepage-products__product-card {
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  background-color: #FFFFFF;
  text-align: center;
  min-width: 162px;
  min-height: 400px;

  @include respond-to(desktop) {
    min-height: 444px;
    min-width: 232px;
  }
}

.homepage-products__swiper-button-next, .homepage-products__swiper-button-prev {
  background: #333333;
  border-radius: 50%;
  color: white;
  font-weight: 700;
  z-index: 1;
  height: 40px;
  width: 40px;

  @include respond-to(notdesktop) {
    left: auto;
    top: auto;
    bottom: -50px;
  }

  @include respond-to(desktop) {
    height: 60px;
    width: 60px;
  }
}
.homepage-products__swiper-button-next {
  @include respond-to(notdesktop) {
    right: 10px;
  }
}
.homepage-products__swiper-button-prev {
  @include respond-to(notdesktop) {
    right: 62px;  // 40 size + 10 from other icon <right> + 12 space
  }
}
.homepage-products__swiper-button-next::after,
.homepage-products__swiper-button-prev::after {
  font-size: 27px;
  @include respond-to(notdesktop) {
    font-size: 18px;
  }
}
.homepage-products__swiper-button-next::after {
  margin-left: 4px;
}
.homepage-products__swiper-button-prev::after {
  margin-left: -4px
}
.homepage-products__swiper-pagination {
  @include respond-to(desktop) { 
    display: none;
  }
  z-index: 1;
  right: 114px;
  left: auto;
  top: auto;
  bottom: -50px;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-self: center;
  vertical-align: middle;
  align-items: center;
}
.homepage-products__spinner {
  margin: 20px;
  height: 100px;
  width: 100px;
}
</style>
