<template>
  <modal-wrapper @close="handleClose">
    <div class="homepage-review-modal">
      <span class="material-icons hrm__close-button" @click="handleClose" data-closable="true">close</span>
      <img class="hrm__image" :src="reviewFirstImage">
      <div class="d-flex-column hrm__right-side">
        <ReviewerHeader v-bind='reviewerHeaderProps'/>
        <StarRating :rating='data.review_rating' class='mt-2'/>
        <button class="btn pf-primary-button mt-3 hrm__product-button" v-bind="productLinkProps">View Product</button>
        <div v-html='data.review_body_html' class="mt-4 hrm__review-body"></div>
      </div>
    </div>
  </modal-wrapper>
</template>
<script>
import ReviewerHeader from 'rs/components/common/recommendation/recommendation_card_reviewer_header';
import StarRating from 'rs/components/common/star_rating'
import {productProps} from 'shared/utils/component_link_utils';
import { reviewerHeaderProps, reviewFirstImage } from "rs/components/pages/reviews/helpers";

export default {
  props: ['data'],
  components: {ReviewerHeader, StarRating},
  computed: {
    productLinkProps() {
      return productProps(this.$routes, this.data.product_internal_path)
    },
    reviewerHeaderProps() {
      return reviewerHeaderProps(this.data)
    },
    reviewFirstImage() {
      return reviewFirstImage(this.data)
    },
  },
  methods: {
    handleClose(e) {
      e.target.dataset.closable === 'true' && this.$emit('closeModal')
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.homepage-review-modal {
  overflow-y: auto;
  width: 100%;
  max-width: 724px;
  font-size: 16px;
  display: flex;
  gap: 18px;
  height: auto;
  max-height: 80dvh;
  padding: 20px 16px 28px 16px;
  margin: 0 auto;
  top: 100px;
  @include respond-to(mobile) {
    flex-direction: column;
    margin: 0 16px;
  }
}

.hrm__image {
  object-fit: contain;
  align-self: flex-start;

  @include respond-to(notmobile) {
    width: 300px;
    height: auto;
    max-height: 374px;
    min-width: 300px;
  }
  @include respond-to(mobile) {
    margin-top: -40px; // 16 + 24
    max-width: calc(100% - 30px); // offset for close button
    height: 275px;
    object-position: left;
  }
}

.hrm__close-button {
  top: 0;
  cursor: pointer;
  font-weight: bolder;

  @include respond-to(mobile) {
    position: sticky;
    text-align: right;
    width: 100%;
  }
  @include respond-to(notmobile) {
    position: absolute;
    right: 0;
    margin: 16px 16px 0 0;
  }
}

.hrm__product-button {
  width: 304px;
  @include respond-to(mobile) {
    width: 100%;
  }
}

.hrm__review-body {
  font-weight: 400;
  word-break: break-word;
  &::-webkit-scrollbar {
    border-radius: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(136, 136, 136, 0.49);
  }

  &::-webkit-scrollbar-thumb:hover {
    border-radius: 4px;
    background: #555;
  }
}
</style>
