<template>
  <WidgetWrapper v-if="enabled">
    <template v-slot:body>
      <div class="store-dispute-widget">
        <div class="sdw__header">
          <div class="rs-weight-700">File an issue</div>
          <iconify icon="clarity:bubble-exclamation-solid" height='24' class="sdw__card-icon"/>
        </div>
        <div>Report an issue about an experience you had with this store.</div>
        <component v-bind="linkParams" class="btn pf-primary-button w-100">File Issue</component>
      </div>
    </template>
  </WidgetWrapper>
</template>
<script>
import WidgetWrapper from 'rs/components/common/wrappers/widget_wrapper'

export default {
  components: {WidgetWrapper},
  inject: ['shopData'],
  computed: {
    enabled() {
      return this.shopData && this.shopData.complaints_enabled
    },
    linkParams() {
      return this.$store.getters['SharedCurrentReviewer/currentReviewer']
        ? {
          is: 'router-link',
          to: {
            path: this.$route.path + '/disputes/new',
            query: this.$route.query
          }
        } : {
          is: 'a',
          href: '/login',
        }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';

.store-dispute-widget {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 16px;

  * {
    width: 100%;
  }
}

.sdw__header {
  position: relative;
}

.sdw__card-icon {
  position: absolute;
  width: fit-content;
  top: 0;
  right: 0;
  color: $brandColor;
}
</style>
