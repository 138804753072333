<template>
  <div class="modal-wrapper" @mousedown="e => $emit('close', e)" data-closable="true" v-bind="$attrs">
    <slot></slot>
  </div>
</template>
<script>
export default {
  mounted() {
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  }
}
</script>
