<template>
  <section class="people-card">
    <div class="people-card__body">
      <div class="people-card__body__info">
        <a :href="reviewer.profile_path" class='people-card__avatar-wrapper'>
          <LazyImage v-if="reviewer.avatar_image_url" :className="'people-card__avatar-img'" :alt="'Reviewer avatar'" :urls='reviewer.avatar_image_url' />
          <div v-else class='people-card__avatar-no-img svg-sprite'></div>
        </a>
        <HeartBtn class='people-card__heart-btn' :followeeClass='"Reviewer"' :followeeEncodedId='reviewer.encoded_id' />
        <a :href="reviewer.profile_path" class='people-card__name' >
          {{ reviewer.name }}
        </a>
        <SocialProfileLinks :twitter_url='reviewer.twitter_url' :instagram_url='reviewer.instagram_url'
                            :tiktok_url='reviewer.tiktok_url' :youtube_url='reviewer.youtube_url'
                            :direct_url='reviewer.personal_url'  />

        <p v-if='reviewer.description' class='people-card__description'>{{ reviewer.description }}</p>
      </div>

      <div class='people-card__body__stats'>
        <div class='people-card__followers-count'>
          <span class='people-card__followers-count-label'>Judge.me Followers</span>
          <span class='people-card__followers-count-number'>{{ reviewer.followers_count }}</span>
        </div>
        <div class='people-card__followers-count'>
          <span class='people-card__followers-count-label'>Reviews</span>
          <span class='people-card__followers-count-number'>{{ reviewer.published_recommendations_count }}</span>
        </div>
      </div>

    </div>
    <div class='people-card__footer'>
      <Category v-if="reviewer.categories.length > 0" :categories='reviewer.categories' />
      <div class="people-card__footer-empty-interest" v-else>
        <p> This person didn’t add interests yet... </p>
      </div>
    </div>
  </section>
</template>

<script>
  import LazyImage from 'rs/components/common/lazy_image'
  import HeartBtn from 'rs/components/common/heart_btn'
  import SocialProfileLinks from 'rs/components/common/social_profile_links'
  import Category from 'rs/components/common/people_card_category'

  export default {
    components: { HeartBtn, LazyImage, SocialProfileLinks, Category },
    props: ['reviewer'],
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .people-card {
    position: relative;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    vertical-align: top;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .people-card__body {
      padding: 8px 8px 0 8px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .people-card__footer {
      min-height: 80px;
      width: 100%;
      background-color: $backgroundColorLight;
      border-radius: 0 0 10px 10px;
    }

    .people-card__footer-empty-interest {
      height: 100%;
      margin: 0 10% 0 10%;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      color: $brandColor;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .people-card__body__stats{
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    justify-content: space-between;
    padding: 0 22px;
    align-items: center;

    @include respond-to(desktop) {
      flex-direction: row;
      row-gap: 0;
    }
  }

  .people-card__avatar-wrapper {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;

    @include respond-to(desktop) {
      width: 64px;
      height: 64px;
    }
  }

  .people-card__avatar-img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .people-card__avatar-no-img.svg-sprite {
    background-position: -600px -100px;
    height: 100px;

    @include respond-to(notdesktop) { background-position: -600px -200px; }
  }

  .people-card__heart-btn {
    float: right;
  }

  .people-card__name {
    color: $primaryFontColor;
    display: block;
    padding: 0 22px;
    margin-top: 45px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    @include text-elipsis();
    @include respond-to(desktop) {
      margin-top: 52px;
    }
  }

  .people-card__description {
    color: $secondaryFontColor;
    margin: 20px 0 0 0;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    line-height: 16px;
    text-align: center;
    @include respond-to(mobile) { @include multiline-ellipsis(5) }
    @include respond-to(notmobile) { @include multiline-ellipsis(3) }
  }

  .people-card__followers-count {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $brandColor;

    .people-card__followers-count-label {
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
    }

    .people-card__followers-count-number {
      font-weight: 800;
      font-size: 20px;
      line-height: 27px;
    }
  }

</style>
