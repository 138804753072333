<template>
  <modal-wrapper @close="closeModal">
    <div class="subscribe-modal-container">
      <iconify icon="iconamoon:close" width="28" class="sm__close-icon" @click="closeModal"
               data-closable="true"/>
      <div class="sm__inner-container">
        <MailOpenIcon icon='mail_open' :size='80' class="sm__header-icon"/>
        <div class="sm__text--header">Subscribe to our newsletter</div>
        <div class="sm__text--description">Join our subscribers list to get the latest news, updates and seasonal
          product collections directly in your inbox.
        </div>
        <input class="sm__input" :value="email" disabled>
        <button class="btn pf-secondary-button" @click="subscribe(true)">Subscribe</button>
        <button class="btn pf-primary-button" @click="subscribe(false)">No, don't show this again</button>
      </div>
    </div>
  </modal-wrapper>
</template>
<script>
const storeName = 'PrivateProfile';
import MailOpenIcon from 'rs/components/common/icons/mail_open';
export default {
  components: {MailOpenIcon},
  computed: {
    email() {
      const profileData = this.$store.getters[storeName+ '/profileData']
      return profileData && profileData.email
    },
  },
  methods: {
    closeModal(e) {
      e.target.dataset.closable === 'true' && this.$store.dispatch(storeName + '/closeSubscribeModal')
    },
    subscribe(bool = false) {
      this.$store.dispatch(storeName + '/subscribe', bool)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.subscribe-modal-container {
  padding: 20px 16px 36px 16px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  position: sticky;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  top: 200px;
  @include respond-to(mobile) {
    margin: 0 16px;
  }
}

.sm__close-icon {
  color: $brandColor;
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
  @include respond-to(mobile) {
    right: 16px;
  }
}

.sm__inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-weight: 400;
  width: 360px;
  color: $primaryFontColor;
  @include respond-to(mobile) {
    width: 100%;
  }

  > * {
    width: 100%;
  }
}

.sm__header-icon {
  width: 80px;
  height: 80px;
  @include respond-to(mobile) {
    width: 64px;
    height: 64px;
  }
}

.sm__text--header {
  text-align: center;
  font-weight: 700;
  line-height: 24px;
  font-size: 20px;
  @include respond-to(mobile) {
    font-size: 16px;
  }
}

.sm__text--description {
  font-size: 14px;
  line-height: 20px;
}

.sm__input {
  margin-top: 4px;
  border-radius: 8px;
  color: $secondaryFontColor;
  font-size: 14px;
  border: 1px solid #CDCCCC;
  height: 36px;
}
</style>
