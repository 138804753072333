import {
  fetchDispute,
  createChat,
  postResolution,
} from 'shared/vue_components/arbitration/base_actions.js'

export default {
  createChat: createChat((disputeId) => `/arbitrator/${disputeId}/chat`),
  fetchDispute: fetchDispute((disputeId) => `/arbitrator/${disputeId}`),
  postResolution: postResolution(disputeId => `/arbitrator/${disputeId}/create_resolution`),
}
