<template>
  <TabListingWrapper :tabs="tabs" :activeTabIndex="tabIndex" @changeTab="onChangeTab">
    <template v-slot:content>
      <component :is="tabs[tabIndex].component" :storeName="tabs[tabIndex].storeName" :key="tabIndex"/>
    </template>
  </TabListingWrapper>
</template>
<script>
import TabReviews from 'rs/components/pages/shop/tabs/_tab_reviews'
import TabProducts from 'rs/components/pages/shop/tabs/_tab_products'
import TabStoreInfo from 'rs/components/pages/shop/tabs/_tab_store_info'
import TabListingWrapper from 'rs/components/pages/shop/sections/_tab_listing_wrapper'

export default {
  components: {TabListingWrapper},
  computed: {
    tabIndex() {
      return this.$store.getters['Shop/tabIndex']
    },
    tabs() {
      return [
        {
          text: 'All store reviews',
          icon: 'star',
          component: TabReviews,
          storeName: 'Shop'
        },
        {
          text: 'Store info',
          icon: 'store',
          component: TabStoreInfo
        },
        {
          text: 'Products',
          icon: 'bag',
          component: TabProducts
        },
      ]
    },
  },
  methods: {
    onChangeTab(idx) {
      this.$store.dispatch('Shop/changeTab', idx)
    },
  },
}
</script>
