<template>
  <div>
    <div class="proposal__description-box" v-if="proposal && isNotUpdateReviewProposal">{{ description }}</div>
    <template v-if="canRespond">
      <div class="rs-weight-700 mt-4"> Do you accept this resolution?</div>
      <div class="proposal__actions-buttons">
        <button class="btn pf-secondary-button accept-button" @click="mainAction.func" :disabled="!isPendingProposal">
          {{ mainAction.text }}
        </button>
        <button class="btn pf-text-button" @click="subAction.func" :disabled="!isPendingProposal">
          {{ subAction.text }}
        </button>
      </div>
    </template>
  </div>
</template>
<script>
import {getProposalType} from 'shared/vue_components/arbitration/helpers'

export default {
  props: ['proposal', 'canRespond'],
  inject: ['actions', 'counterPropose', 'dispute'],
  computed: {
    isNotUpdateReviewProposal() { // special proposal type
      if (!this.proposal) return true
      return this.proposal.proposal_type !== 'shop_request_update_review'
    },
    isPendingProposal() {
      return this.proposal.status === 'pending'
    },
    description() {
      if (this.proposal) {
        return getProposalType(this.proposal.proposal_type) + ' - ' + this.proposal.description
      } else return null
    },
    mainAction() {
      return {
        func: this.respondToProposal.bind(null, true),
        text: this.proposal.status === 'accepted' ? 'Accepted' : 'Accept'
      }
    },
    subAction() {
      return {
        func: this.dispute.user_role === 'seller' ? this.respondToProposal.bind(null, false) : this.counterPropose.bind(null, this.proposal),
        text: this.dispute.user_role === 'seller' ? 'Refuse Resolution' : 'Suggest Another Resolution'
      }
    }
  },
  methods: {
    respondToProposal(accepted = true) {
      this.actions.respondProposal({
        accepted,
        disputeId: this.dispute.id,
        new_dispute: true,
      }).then(this.actions.renderDispute)
    },
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';

.proposal__description-box {
  margin-top: 12px;
  border-radius: 8px;
  background: #E5FAFF;
  padding: 12px;
  width: 100%;
}

.proposal__actions-buttons {
  width: 100%;
  display: flex;
  margin-top: 12px;
  flex-wrap: wrap;

  @include respond-to(mobile) {
    justify-content: center;
    gap: 8px;
  }

  .accept-button {
    width: 240px;
    height: 50px;
  }
}
</style>
