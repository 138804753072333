<template>
  <div class="categories-heading" :class="[!description && 'categories-heading--short']">
    <div class="categories-heading__wrapper has-max-width">
      <div class="categories-heading__title">{{ title }}</div>
      <div v-if="description" class="categories-heading__description"> {{ description }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["title", "description", "backgroundUrl"],
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.categories-heading {
  @include respond-to(notmobile) {
    width: 100vw;
    translate: -50%;
    margin-left: 50%;
    height: 220px;
  }

  height: 148px;
  margin: -10px -12px 0 -12px;
  background: url('https://pub-images.judge.me/judgeme/platform/categories/categories-page-default-background.png');
  background-size: cover;
}

.categories-heading--short {
  @include respond-to(notmobile) {
    height: 148px;
  }
  height: 112px;
}
.categories-heading__wrapper {
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.categories-heading__title {
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  @include respond-to(notmobile) {
    font-size: 48px;
    line-height: 72px;
  }

  &::first-letter {
    text-transform: capitalize;
  }
}

.categories-heading__description {
  margin-top: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  @include respond-to(notmobile) {
    margin-top: 20px;
    font-size: 20px;
    line-height: 30px;
  }
}
</style>
