import { render, staticRenderFns } from "./promotion_feed_cards.vue?vue&type=template&id=2cd3ec6d&scoped=true&"
import script from "./promotion_feed_cards.vue?vue&type=script&lang=js&"
export * from "./promotion_feed_cards.vue?vue&type=script&lang=js&"
import style0 from "./promotion_feed_cards.vue?vue&type=style&index=0&id=2cd3ec6d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cd3ec6d",
  null
  
)

export default component.exports