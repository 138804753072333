<template>
  <div class="arbitrator-dispute__list">
    <div class="arbitrator-dispute__card" v-for="dispute in disputes">
      <CollapsibleItem>
        <Dispute :key="dispute.id"
                 @cancel="toggleDisputeVisibility(dispute.id)"
                 :dispute="dispute"
                 :hidden="!showingList[dispute.id]"
                 :fullVersion="false">
          <template #header="{scopedDispute}">
            <div class="arbitrator-dispute__title">
              <router-link class="arbitrator-dispute__link"
                           :to="fullDisputePath(scopedDispute.id)">
                {{ linkText(scopedDispute) }}
              </router-link>
              <svg-icon class="arbitrator-dispute__status-icon" :icon="iconName(scopedDispute.status_text)"
                        color="black"/>
              <div class="arbitrator-dispute__status-text">{{ scopedDispute.status_text }}</div>
              <button @click="toggleDisputeVisibility(scopedDispute.id)"
                      class="btn pf-text-button arbitrator-dispute__toggle-icon">
                <iconify :icon="`ci:chevron-${showingList[scopedDispute.id] ? 'up' : 'down'}`" height='24'></iconify>
              </button>
            </div>
          </template>
        </Dispute>
      </CollapsibleItem>
    </div>
  </div>
</template>
<script>
import Dispute from 'shared/vue_components/arbitration/dispute.vue'
import CollapsibleItem from "rs/components/common/collapsible_item";
import {iconNameForDispute} from 'shared/vue_components/arbitration/helpers'

export default {
  components: {CollapsibleItem, Dispute},
  props: ['disputes'],
  data: () => ({
    showingList: {},
  }),
  methods: {
    iconName: iconNameForDispute,
    linkText(dispute) {
      let formattedDisputeType
      try {
        formattedDisputeType = dispute.type.split(' - ')[0].replace(' related', '')
      } catch {
        formattedDisputeType = 'Unclassified Issue'
      }
      return `Issue - ${dispute.id} - ${formattedDisputeType} - raised by ${dispute.creator_name}`
    },
    fullDisputePath(disputeId) {
      return {
        name: this.$routes.arbitratorDispute.name,
        query: {redesign: 1},
        params: {disputeId},
      }
    },
    toggleDisputeVisibility(id) {
      this.showingList = {...this.showingList, [id]: !this.showingList[id]}
    },
    isVisible(id) {
      return !!this.showingList[id]
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.arbitrator-dispute__list {
  font-weight: 400;
}

.arbitrator-dispute__title {
  display: flex;
  gap: 4px;
}

.arbitrator-dispute__link {
  color: $brandColor;
}

.arbitrator-dispute__card {
  padding: 30px 28px;
  border-top: solid 1px $feedCardDividerColor;
}

.arbitrator-dispute__toggle-icon {
  margin-left: auto;
  height: fit-content;
  padding: 0;
  vertical-align: middle;
  color: $brandColor;
}
</style>
<style lang="scss">
.arbitrator-dispute__card {
  .enter-active,
  .leave-active {
    overflow: hidden;
    transition: height .2s linear;
  }

  .dispute {
    margin-top: 20px;
  }
}
</style>
