"use strict";
/**
 * @file vue-awesome-swiper
 * @module SwiperSlideComponent
 * @author Surmon <https://github.com/surmon-china>
 */
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var constants_1 = require("rs/plugins/swiper/constants");
exports.default = vue_1.default.extend({
  name: constants_1.CoreNames.SwiperSlideComponent,
  computed: {
    slideClass: function () {
      var _a, _b;
      return ((_b = (_a = this.$parent) === null || _a === void 0 ? void 0 : _a.swiperOptions) === null || _b === void 0 ? void 0 : _b.slideClass) || constants_1.DEFAULT_CLASSES.slideClass;
    }
  },
  methods: {
    update: function () {
      var _a;
      var parent = this.$parent;

      if (parent[constants_1.ComponentPropNames.AutoUpdate]) {
        (_a = parent === null || parent === void 0 ? void 0 : parent.swiperInstance) === null || _a === void 0 ? void 0 : _a.update();
      }
    }
  },
  mounted: function () {
    this.update();
  },
  updated: function () {
    this.update();
  },
  render: function (createElement) {
    return createElement('div', {
      class: this.slideClass
    }, this.$slots.default);
  }
});