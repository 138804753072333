import {pipe} from 'shared/utils/js_core';

function _idToEmbeddedUrl(id) {
  if (!id) return null;
  return `https://www.youtube.com/embed/${id}?autoplay=0&mute=1`
}

function _extractYoutubeId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length == 11) && match[2] || null
}

const _embedYoutubeUrl = pipe(_extractYoutubeId, _idToEmbeddedUrl)

export const youtubeHelper = {
  extractId: _extractYoutubeId,
  embedId: _idToEmbeddedUrl,
  embedYoutubeUrl: _embedYoutubeUrl,
}
