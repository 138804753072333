<template>
  <WidgetWrapper>
    <template v-slot:header>
      <div class="judgeme-promise__header">
        <div class="judgeme-promise__header-text">{{ header }}</div>
        <div class="svg-sprite pf-sprite-icon pf-sprite-icon--small j-icon"></div>
      </div>
    </template>
    <template v-slot:body>
      <div class="judgeme-promise__item-wrapper"
           v-for="(item,idx) in items" :key="idx">
        <div class="judgeme-promise__title" @click="toggle(idx)">
          {{ item.title }}
          <i class="material-icons judgeme-promise__arrow">{{ isShow(idx) ? 'expand_less' : 'expand_more' }}</i>
        </div>
        <CollapsibleItem>
          <div v-show="isShow(idx)">
            <!-- empty line -->
            <p class="judgeme-promise__description"></p>
            <p class="judgeme-promise__description">{{ item.description }}</p>
          </div>
        </CollapsibleItem>
      </div>
      <router-link class="btn pf-primary-button btn-how-it-works" :to="$routes.howItWorks.path">How It Works
      </router-link>
    </template>
  </WidgetWrapper>
</template>
<script>
import CollapsibleItem from "rs/components/common/collapsible_item";
import WidgetWrapper from 'rs/components/common/wrappers/widget_wrapper'

export default {
  components: {CollapsibleItem, WidgetWrapper},
  computed: {
    header: () => "The Judge.me promise",
    items: () => [
      {
        title: "We show real reviews by real people",
        description: "Our system prevents spam reviews from being displayed."
      },
      {
        title: "We show you which reviews are verified",
        description: "Trusted reviews are marked with the verified label."
      },
      {
        title: "We give you the full picture of the products you buy",
        description: "Real order data lets us authenticate all reviews independently."
      },
      {
        title: "We recognise stores that commit to transparency",
        description: "Our medals celebrate stores that are open with their customers."
      },
      {
        title: "We help you find products you can trust",
        description: "Stores and products are ranked by trustworthiness, saving you time and effort."
      }
    ]
  },
  data: () => ({
    showingIdx: null,
  }),
  methods: {
    toggle(idx) {
      if (this.showingIdx === idx) {
        this.showingIdx = null;
      } else {
        this.showingIdx = idx
      }
    },
    isShow(idx) {
      return this.showingIdx === idx
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';

.judgeme-promise__header {
  width: 100%;
  font-size: 16px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.judgeme-promise__item-wrapper {
  padding: 12px;
  border-radius: 8px;
  background-color: #F0F5F5;
  border: $newBorderStyle;
  font-size: 14px;
}

.judgeme-promise__description {
  margin-top: 20px;
  font-weight: 400;
}

.judgeme-promise__title {
  position: relative;
  padding-right: 28px;
  font-weight: 700;
  cursor: pointer;
}

.judgeme-promise__arrow {
  position: absolute;
  top: 2px;
  right: 0;
}

.btn-how-it-works {
  margin: 8px 0 12px 0;
}
</style>
<style lang="scss">
.judgeme-promise__item-wrapper {
  .enter-active,
  .leave-active {
    overflow: hidden;
    transition: height .3s linear;
  }
}
</style>
