<template>
  <MatchMedia v-slot="{desktop}">
    <div class="profile-review-card profile-recommendation-card">
      <img v-if='!isVideo' class="prec__image" :src="recommendation.media_url || recommendation.product_image_url" :alt="`image for recommendation of product ${recommendation.product_title}`"/>
      <div v-else class="video-container">
        <iframe :src="youtubeSrc"></iframe>
      </div>
      <div class="prec__title">{{recommendation.title}}</div>
      <div class='prc__body prec__body' :class="{ 'prc__body--show-all' : shouldShowAllBody }"
           ref='reviewBodyEle'>
        {{recommendation.body}}
      </div>
      <span class='prc__body-read-more' @click='showAll'>Read More</span>
      <div class="prec__divider"></div>
      <div class="prec__product-wrapper">
        <img v-if="recommendation.media_url"
          class="prec__product-image" :src="recommendation.product_image_url" :alt="`image of product ${recommendation.product_title}`"/>
        <div class="prec__product-detail-wrapper">
          <div class="rs-weight-700">{{recommendation.product_title}}</div>
          <div class="prc__product-metrics prec__product-metrics" v-if="recommendation.rating_count">
            <SimpleRating :rating="recommendation.rating_count.average_rating"/>
            <div v-if="recommendation.rating_count.number_of_reviews > 0" class="prc__product-reviews-count">
              Based on {{ $sharedUtils.pluralizeWithSeparator(recommendation.rating_count.number_of_reviews, 'review') }}
            </div>
          </div>
          <div class="prec__product-price">{{price}}</div>
          <div class="prec__promo-code" v-if="recommendation.product_promo_code">
            <span>Coupon</span>
            <span>{{ recommendation.product_promo_code }}</span>
            <span @click="onCopy" class="material-icons">content_copy</span>
            <span class="tooltiptext recommendation__tooltiptext" ref="tooltip" data-showtooltip="false">Copied</span>
          </div>
          <ExternalLink v-if="desktop" :href="recommendation.product_url_with_utm_params" class="prec__product-button btn pf-secondary-button">
            Shop Product
          </ExternalLink>
        </div>
      </div>
      <ExternalLink v-if="!desktop" :href="recommendation.product_url_with_utm_params" class="prec__product-button btn pf-secondary-button">
        Shop Product
      </ExternalLink>
    </div>
  </MatchMedia>
</template>
<script>
import StarRating from 'rs/components/common/star_rating'
import ExternalLink from 'rs/components/common/external_link'
import {customFormat} from 'shared/utils/timeago';
import ReviewReaction from 'rs/components/common/review_reaction'
import SimpleRating from 'rs/components/common/simple_rating';
import {MatchMedia} from "shared/vue_media_query/MatchMedia";
import {mapGetters} from "vuex";
import 'rs/components/uncommon/profile/card_styles.scss'

export default {
  components: {MatchMedia, StarRating, ReviewReaction, SimpleRating, ExternalLink},
  props: ['recommendation'],
  computed: {
    ...mapGetters('SharedCurrentReviewer', ['currency', 'exchangeRates']),
    storeName(){
      return this.$store.getters['Profile/storeName']
    },
    isVideo() {
      return this.recommendation.media_type === 'yt_video'
    },
    youtubeSrc() {
      return `https://www.youtube.com/embed/${this.recommendation.media_url}?autoplay=0&mute=1`
    },
    price() {
      const conversion = this.$sharedUtils.convertCurrency(this.currency, this.recommendation.product_currency, this.recommendation.product_price, this.exchangeRates);
      if (conversion !== null) { //  cannot convert
        return conversion
      } else {
        return this.recommendation.product_price
      }
    },
    createdAtRelative() {
      return customFormat(this.recommendation.created_at)()
    },
  },
  data() {
    return {
      shouldShowAllBody: false,
    }
  },
  methods: {
    onCopy(e){
      navigator.clipboard.writeText(this.recommendation.product_promo_code);
      this.$refs.tooltip.dataset['showtooltip'] = true;

      setTimeout(() => {
        this.$refs.tooltip.dataset['showtooltip'] = false;
      }, 1000);
    },
    showAll() {
      this.shouldShowAllBody = true
    },
  },
  mounted() {
    if (this.$refs.reviewBodyEle.scrollHeight > this.$refs.reviewBodyEle.clientHeight) {
      this.$refs.reviewBodyEle.className = 'prc__body prc__body--need-show-more'
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';
$borderRadius: 4px;
.profile-recommendation-card {
  color: $primaryFontColor;
  border-radius: 8px;
  border: 1px solid;
  border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  padding: 20px;
  overflow-wrap: break-word;

  margin: 20px 28px;
  @include respond-to(notdesktop) {
    margin: 20px 16px;
  }
}
.prec__image {
  object-fit: cover;
  border-radius: 5px;
  height: auto;
  max-width: 100%;
  max-height: 500px;

  @include respond-to(notdesktop) {
    width: 100%;
    max-height: 700px;
  }
}
.prec__title {
  margin-top: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}

.prec__body {
  white-space: pre-line;
}
.prec__divider {
  margin-top: 16px;
  height: 1px;
  background-color: #e0e0e0;
}
.prec__product-wrapper {
  display: flex;
  gap: 12px;
}
.prec__product-image {
  height: 90px;
  width: 90px;
  object-fit: cover;
  @include respond-to(desktop) {
    height: 176px;
    width: 176px;
  }
  border: $borderStyle;
  border-radius: 10px;
}

.prec__product-detail-wrapper {
  display: flex;
  flex-direction: column;
}
.prec__product-price {
  font-size: 20px;
  font-weight: 700;
  margin-top: 4px;
}
.prec__promo-code {
  position: relative;
  margin-top: 8px;
  height: 24px;
  max-width: 300px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  span {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 8px;
  }
  span:nth-child(1) {
    border-radius: $borderRadius 0 0 $borderRadius;
    color: white;
    background: $brandColor;
    width: 68px;
  }
  span:nth-child(2) {
    color: $brandColor;
    background: $lightGreenColor;
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    width: 120px;
    line-height: 24px;
    display:inline-block;
    @include respond-to(mobile){
      max-width: 100px;
    }
  }
  span:nth-child(3) {
    cursor: pointer;
    border-radius: 0 $borderRadius $borderRadius 0;
    font-size: 16px;
    color: #637381;
    background: $lightGreenColor;
  }
}
.prec__product-metrics {
  @include respond-to(notdesktop) {
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
  }
}
.prec__product-button {
  margin-top: 20px;
  padding: 8px 40px;
  font-weight: 800;
  @include respond-to(desktop) {
    width: 200px;
  }
}

.recommendation__tooltiptext {
  right: 0;
}

.video-container {
  width: 100%;
}
.video-container iframe {
  aspect-ratio: 16 / 9;
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 5px;
}
</style>
