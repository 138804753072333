<template functional>
  <svg v-on="data.listeners" :class="data.class" v-bind="data.attrs" xmlns="http://www.w3.org/2000/svg" width="16"
       height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M2.6594 3.5C2.44323 3.5 2.26191 3.42825 2.11542 3.28475C1.96945 3.14075 1.89646 2.9625 1.89646 2.75C1.89646 2.5375 1.96945 2.35925 2.11542 2.21525C2.26191 2.07175 2.44323 2 2.6594 2H13.3406C13.5568 2 13.7378 2.07175 13.8838 2.21525C14.0303 2.35925 14.1035 2.5375 14.1035 2.75C14.1035 2.9625 14.0303 3.14075 13.8838 3.28475C13.7378 3.42825 13.5568 3.5 13.3406 3.5H2.6594ZM2.6594 14C2.44323 14 2.26191 13.928 2.11542 13.784C1.96945 13.6405 1.89646 13.4625 1.89646 13.25V9.5H1.76294C1.52134 9.5 1.32425 9.40925 1.17166 9.22775C1.01907 9.04675 0.968211 8.8375 1.01907 8.6L1.78202 4.85C1.82016 4.675 1.90917 4.53125 2.04905 4.41875C2.18892 4.30625 2.34787 4.25 2.52589 4.25H13.4741C13.6521 4.25 13.8111 4.30625 13.951 4.41875C14.0908 4.53125 14.1798 4.675 14.218 4.85L14.9809 8.6C15.0318 8.8375 14.9809 9.04675 14.8283 9.22775C14.6758 9.40925 14.4787 9.5 14.2371 9.5H14.1035V13.25C14.1035 13.4625 14.0303 13.6405 13.8838 13.784C13.7378 13.928 13.5568 14 13.3406 14C13.1244 14 12.9434 13.928 12.7974 13.784C12.6509 13.6405 12.5777 13.4625 12.5777 13.25V9.5H9.52589V13.25C9.52589 13.4625 9.4529 13.6405 9.30692 13.784C9.16044 13.928 8.97911 14 8.76294 14H2.6594ZM3.42234 12.5H8V9.5H3.42234V12.5Z"
      :fill="props.color"/>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#088484',
    }
  }
}
</script>
