<template>
  <div class="reward-card">
    <div class="review-card__item">
      <div class="review-card__item-label">Coupon code:</div>
      <div class="review-card__code-box" @click="onCopy">
        {{ card.code }}
        <span class="tooltiptext" ref="tooltip" data-showtooltip="false">Copied</span>
      </div>
    </div>
    <div class="review-card__item">
      <div class="review-card__item-label">Received date:</div>
      <div class="review-card__item-text">{{ createdAtRelative }}</div>
    </div>
    <div class="review-card__item">
      <div class="review-card__item-label">For review:</div>
      <div class="review-card__item-text">{{ card.review_title }}</div>
    </div>
    <div class="review-card__item" v-if="card.shop">
      <div class="review-card__item-label">Shop:</div>
      <router-link v-if="card.shop.use_review_site" class="review-card__item-text" :to="card.shop.internal_path">{{ card.shop.name }}</router-link>
      <ExternalLink v-else class="review-card__item-text" :href="card.shop.external_url" :text="card.shop.name"/>
    </div>
    <div class="review-card__item" v-if="card.shop.use_review_site">
      <div class="review-card__item-label mobile-only">&nbsp;</div>
      <router-link class="btn pf-primary-button review-card__button" :to="allProductsRoute">Find products</router-link>
    </div>
  </div>
</template>
<script>
import ExternalLink from 'rs/components/common/external_link'
import {customFormat} from 'shared/utils/timeago';

export default {
  components: {ExternalLink},
  props: ['card'],
  computed: {
    createdAtRelative() {
      return customFormat(this.card.created_at)()
    },
    allProductsRoute() {
      return {
        name: this.$routes.search.name,
        query: {
          shop_domain: this.card.shop.domain,
          platform: this.card.shop.platform,
          search_in_shop: true,
          min_transparency: 0,
        }
      }
    }
  },
  methods: {
    onCopy(e) {
      navigator.clipboard.writeText(this.card.code);
      this.$refs.tooltip.dataset['showtooltip'] = true;
      setTimeout(() => {
        this.$refs.tooltip.dataset['showtooltip'] = false;
      }, 1000);
    },
  }
}

</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.reward-card {
  border: 1px solid $newBorderColor;
  border-radius: 8px;
  width: auto;
  margin: 20px;
  padding: 20px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  @include respond-to(notmobile) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.review-card__item {
  flex: 0 1 50%;
  display: flex;
  align-items: center;

  &:nth-of-type(3) {
    flex: 0 1 100%;
  }

  &:nth-of-type(1) {
    margin-bottom: 8px;
  }

}

.review-card__item-label {
  color: #637381;
  font-weight: 600;
  font-size: 14px;
  min-width: 110px;
}

.review-card__code-box {
  padding: 12px 18px;
  border: 1px dashed $brandColor;
  color: $brandColor;
  font-weight: 400;
  cursor: pointer;
  position: relative;
}

.review-card__button {
  width: 160px;
}

.mobile-only {
  @include respond-to(notmobile) {
    display: none;
  }
}
</style>
