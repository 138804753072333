<template functional>
  <div :class="['circle-check-wrapper', data.staticClass]">
    <iconify :icon="props.whichIcon(props.icon)" :style="`color: ${props.color};`"/>
  </div>
</template>
<script>
const ICONS = {
  check: 'ic:round-check',
  info: 'ant-design:info-outlined',
  pin: 'bi:pin-angle-fill',
}
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    },
    icon: {
      type: String,
      default: 'check'
    },
    whichIcon: {
      type: Function,
      default: (v) => ICONS[v]
    }
  },
}
</script>
<style lang="scss">
.circle-check-wrapper {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: solid 1px #00AB6D;
  background: linear-gradient(180deg, #24C88D 0%, #00AB6D 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}
</style>
