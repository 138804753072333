<template>
  <div class='feed-card__unimplemented'>{{ feedEvent.event_type }}</div>
</template>
<script>
export default {
  props: ['feedEvent'],
}
</script>
<style lang="scss" scoped>
.feed-card__unimplemented {
  color: red;
}
</style>