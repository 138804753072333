<template>
  <transition name="modal" class="gallery">
    <div class="gallery__mask">
      <div class="gallery__wrapper" @click="close">
        <div v-if="errorMessage" class="gallery__error" @click.stop="">
            <div class="gallery__error-message">{{ errorMessage }}</div>
            <div class="btn btn-primary gallery__error-button material-icons-round" @click="close">close</div>
        </div>
        <div v-else class="gallery__container" @click.stop="">
          <div class="gallery__button material-icons-round" @click="close">close</div>
          <Carousel :media="allMedia" :currentMediaId="currentMediaId" :hasMore="this.hasMore" @loadMore="loadMore" />
          <Product v-if="review.id === 'product'" :product="review"/>
          <Review v-else :review="review" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { mapState } from 'vuex'
  import Carousel from 'rs/components/common/gallery/gallery_carousel'
  import Review from 'rs/components/common/gallery/gallery_review'
  import Product from 'rs/components/common/gallery/gallery_product'

  export default  {
    components: { Carousel, Review, Product },
    computed: {
      ...mapState("Gallery", {
      reviewMedia: state => state.media,
      currentMediaId: state => state.currentMediaId,
      review: state => state.selectedReview,
      errorMessage: state => state.errorMessage,
      page: state => state.page,
      totalPages: state => state.totalPages,
      loading: state => state.loading,
      hasMore: state => state.totalPages > state.page,
      }),
      allMedia() {
        if (this.$route.name !== this.$routes.product.name) return this.reviewMedia
        const productGalleryData = this.$store.getters['Product/galleryData']
        return productGalleryData ? [productGalleryData, ...this.reviewMedia] : this.reviewMedia
      },
    },
    mounted: function() {
      document.body.classList.add("modal-open");
    },
    methods: {
      close() {
        this.$store.dispatch('Gallery/closeGallery')
        document.body.classList.remove("modal-open");
      },
      loadMore(){
        if (this.totalPages > this.page && !this.loading) {
          this.$store.dispatch('Gallery/loadMore')
        }
      }
    }
  }

</script>
<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .gallery__mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: table;
    transition: opacity 0.3s ease;
  }

  .gallery__wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond-to(notdesktop) {
      background: white;
    }
  }

  .gallery__container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-size: $primaryFontSize;
    overflow-y: scroll;

    @include respond-to(desktop) {
      max-width: 1324px;
      max-height: 844px;
      width: 100%;
      height: 100%;
      flex-direction: row;
      overflow-y: hidden;
    }
  }

  .gallery__error {
    position: absolute;
    background-color: #ffffff;
    height: 50%;
    width: 80%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;

    @include respond-to(notmobile) { width: 600px; height: 400px; }
  }

  .gallery__error-message { text-align: center; }
  .gallery__error-button { padding: 12px; border-radius: 50%; }

  .gallery__button {
    position: absolute;
    z-index: 6;
    right: 0;
    background-color: transparent;
    color: $borderColor;
    line-height: 40px;
    width: 36px;
    cursor: pointer;

    @include respond-to(desktop) { color:$textSecondaryColor; }
  }
</style>
