import axios from 'shared/utils/axios'
import utils from 'shared/utils/utils';
import {productParams} from 'shared/utils/request_params'
import {mapMediaFromReviews} from "rs/store/shared/gallery_utils";
import {setState} from 'rs/store/store_utils/shared'
import {addProductSnippet, addBreadCrumbSnippet} from 'shared/utils/json_ld_snippet'
import {getMedalInfo} from 'rs/store/store_utils/product_utils'
import { reviewSources as importedReviewSources} from 'shared/constants/review_sources.js';
import {
  applyFilter,
  clearFilter,
  removeFilter,
  applySort,
  loadReviewsOnPageChange,
  trackChange
} from "rs/store/store_utils/filter_sort_utils";

const defaultQueries = {
  review_source: 'all_sources',
  verified_only: false,
  filter_rating: []
}
const reviewTypes = {}
const reviewSources = importedReviewSources;

const defaultModals = {
  modalWrapper: false,
  showRecommendationModal: false,
};


const defaultState = {
  modals: {...defaultModals},
  isLoadingWidget: true,
  isLoadingResult: false,
  isLoadingQuestionsData: false,
  shopData: null,
  productData: null,
  categoriesData: [],
  widgetData: null,
  widgetSummary: null,
  media: [],
  widgetQuestionData: {total_pages: 0},
  queries: {search: '', sort_by: 'pictures_first', ...defaultQueries},
  questionsHash: {},
  previousSearch: '',
  productNotFound: false,
  // tab listing states
  tabIndex: 0,
  tabListingComponent: null, // use for scrolling anchor
}
const state = {
  ...defaultState
}

const getters = {
  modals: ({modals}) => modals,
  external_url: ({productData}) => productData ? productData.external_url : '',
  encodedProductId: ({productData}) => productData ? productData.encoded_id : '',
  widgetData: ({widgetData}) => widgetData,
  widgetSummary: ({widgetSummary}) => widgetSummary,
  shopData: ({shopData}) => shopData,
  productData: ({productData}) => productData,
  categoriesData: ({categoriesData}) => categoriesData,
  metrics: ({widgetData}) => widgetData && widgetData.product_metrics,
  changes: trackChange(defaultQueries, reviewTypes, reviewSources),
  didSearch(state) {
    return !!state.previousSearch
  },
  didFilter(state) {
    return state.queries.filter_rating.length > 0 ||
      state.queries.review_source !== defaultFilterQueries.review_source
  },
  galleryData(state) {
    if (!state.productData.image_url && !state.productData.retina_image_url) return null;
    return {
      id: 'product',
      review_uuid: 'product',
      dataType: 'picture',
      compact: state.productData.image_url || state.productData.retina_image_url,
      huge: state.productData.retina_image_url || state.productData.image_url,
      title: state.productData.title,
      description: state.productData.description,
      external_url: state.productData.external_url,
      average_rating: state.widgetData.average_rating,
      number_of_reviews: state.widgetData.number_of_reviews,
    }
  },
  reviewTypes: () => reviewTypes,
  reviewSources: () => reviewSources,
  verifiedOnly: ({queries}) => queries.verified_only,
  queries: state => state.queries,
  previousSearch: state => state.previousSearch,
  shopEnabledWebReview: state => state.shopData && !state.shopData.settings.disable_web_reviews,
  shopEnabledQuestions: state => state.shopData && state.shopData.settings.enable_question_anwser,
  productNotFound: ({productNotFound}) => productNotFound,
  hasProductReviews: ({widgetData}) => widgetData && widgetData.number_of_reviews > 0,
  hasQuestions: (state, getters) => getters.shopEnabledQuestions && // shop enable QnA
    state.widgetQuestionData.total_pages > 0, // there is QnA record
  widgetQuestionData: ({widgetQuestionData}) => widgetQuestionData,
  isLoadingQuestionsData: ({isLoadingQuestionsData}) => isLoadingQuestionsData,
  isLoadingWidget: ({isLoadingWidget}) => isLoadingWidget,
  tabIndex: ({tabIndex}) => tabIndex,
  media: ({media}) => media,
  medals: (state, getters) => {
    if (!getters.metrics) return []
    const tranMedal = getMedalInfo('tran', getters.metrics.transparency_score * 100)
    const authMedal = getMedalInfo('auth', getters.metrics.authenticity_score * 100)
    const result = []
    if (tranMedal) result.push({
      title: tranMedal.name + ' Transparent Product',
      description: 'Published ' + tranMedal.threshold + '% of verified reviews received in total',
      tier: tranMedal.name.toLowerCase(),
      type: 'tran'
    })
    if (authMedal) result.push({
      title: authMedal.name + ' Authentic Product',
      description: 'At least ' + authMedal.threshold + '% of published reviews are verified reviews',
      tier: authMedal.name.toLowerCase(),
      type: 'auth'
    })
    return result
  }
}

const mutations = {
  setState: (state, [name, value]) => state[name] = value, // use for simple mutated state
  clearPageData(state) {
    Object.keys(state).forEach(key => {
      state[key] = defaultState[key]
    })
  },
  setPageData(state, payload) {
    state.productData = payload.product_data
    state.shopData = payload.shop_data
    state.metaData = payload.meta_data
    state.categoriesData = payload.categories_data
  },
  setWidgetData(state, widgetData) {
    state.widgetData = {...state.widgetData, ...widgetData}
    state.isLoadingResult = false;
  },
  setMedia(state, widgetDataWithMediaParams) {
    state.media = mapMediaFromReviews(widgetDataWithMediaParams.reviews, [])
  },
  setSearchQuery(state, search) {
    state.queries = {...state.queries, search}
  },
  setSortQuery(state, query) {
    state.queries = {...state.queries, ...query}
  },
  setFilterQueries(state, filterQueries) {
    state.queries = {...state.queries, ...filterQueries}
  },
}


const actions = {
  async initPage({state, dispatch}, payload) {
    // optimize speed (no need to reload data when user goback or revisit this same product consecutively)
    if (state.productData && state.productData.handle === payload.product_handle) {
      dispatch('Meta/setMeta', state.metaData, {root: true})
    } else {
      dispatch('loadPageData', payload)
    }
  },
  loadPageData(context, payload) {
    context.commit('clearPageData')

    axios.get(`/reviews/${payload.shop_slug}/products/${payload.product_handle}`, {
      params: {format: 'json'},
    }).then(function (response) {
      const responseData = response.data
      context.dispatch('Meta/setMeta', responseData.meta_data, {root: true})
      context.commit('setPageData', responseData)
      context.dispatch('loadWidgetData', {
        platform: responseData.shop_data.platform,
        shopDomain: responseData.shop_data.domain,
        publicToken: responseData.shop_data.public_token,
        productExternalId: responseData.product_data.external_id,
        productMetrics: true,
        addHeaderCounts: true,
      })
      context.dispatch('loadQuestions')

      const followeeIdsToCheck = {
        Shop: [responseData.shop_data.encoded_id],
        Product: [responseData.product_data.encoded_id]
      }
      context.dispatch('SharedFollow/fetchFolloweeIds', followeeIdsToCheck, {root: true})
    }).catch(error => {
        setState(context.commit, 'productNotFound')(true)
      }
    )
  },
  loadReviews({state, dispatch, commit}, payload) {
    setState(commit, 'isLoadingResult')(true)
    const params = {
      shopDomain: state.productData.shop_domain,
      platform: state.productData.platform,
      publicToken: state.shopData.public_token,
      productExternalId: state.productData.external_id,
      sort_by: state.queries.sort_by,
      sort_dir: state.queries.sort_dir,
      search: state.queries.search || null,
      filter_rating: state.queries.filter_rating,
      reviewSource: state.queries.review_source,
      ...payload,
    }
    dispatch('loadWidgetData', params);
  },
  async loadQuestions(context, payload = {}) {
    setState(context.commit, 'isLoadingQuestionsData')(true)
    payload.page = payload.page || 1
    try {
      const res = await axios.get('/api/questions/questions_for_widget', {
        params: {
          json_request: true,
          shop_domain: context.state.productData.shop_domain,
          api_token: context.state.shopData.public_token,
          platform: context.state.productData.platform,
          product_id: context.state.productData.external_id,
          sort_by: 'created_at',
          sort_dir: 'desc',
          per_page: 3,
          ...payload,
        }
      })
      setState(context.commit, 'widgetQuestionData')(res.data)
    } catch (error) {
      console.log(error)
    }
    setState(context.commit, 'isLoadingQuestionsData')(false)
  },
  searchReviews({dispatch, state, commit}) {
    setState(commit, 'previousSearch', state.queries.search)
    dispatch('loadReviews', {
      page: 1,
      search: state.queries.search || null,
    })
  },
  applyFilter(context, filterQueries) {
    applyFilter(context, filterQueries)()
  },
  clearFilter(context) {
    clearFilter(context)(defaultQueries)
  },
  removeFilter(context, filter) { // filter = { type: xxx, value: yyy}
    removeFilter(context, filter)(defaultQueries)
  },
  applySort(context, payload) {
    applySort(context, payload)()
  },
  loadReviewsOnPageChange(context, payload) {
    loadReviewsOnPageChange(context, payload)()
  },

  loadWidgetData(context, payload) {
    axios.get('/api/v1/widgets/product_review', {
      params: productParams(payload),
    }).then(function (response) {
      const responseData = response.data
      context.commit('setWidgetData', responseData)
      context.commit('setMedia', responseData)
      if (payload.addHeaderCounts) {
        const {
          number_of_questions,
          number_of_reviews,
          average_rating,
          rating_histogram_data,
          product_metrics
        } = responseData
        setState(context.commit, 'widgetSummary')({
          number_of_questions,
          number_of_reviews,
          average_rating,
          rating_histogram_data,
          product_metrics,
        })
      }
      setState(context.commit, 'isLoadingWidget')(false)
      addProductSnippet({...context.state.productData, ...responseData})
      context.dispatch('getPathList').then(addBreadCrumbSnippet)
    })
  },
  getPathList({state}) {
    return [
      {
        path: state.shopData.internal_path,
        name: state.shopData.name
      },
      {
        path: state.productData.internal_path,
        name: state.productData.title
      },
    ]
  },
  trackExternalClick({state, dispatch}) {
    dispatch('SharedClickTracking/trackExternalClick', {
      href: state.productData.external_url,
      shopId: state.shopData.encoded_id,
      productId: state.productData.encoded_id
    }, {root: true})
  },
  changeTab({commit, dispatch, state}, payload = 0) {
    setState(commit, 'tabIndex')(payload);
    if (state.tabListingComponent) {
      utils.scrollToDistanceWithHeaderOffset(state.tabListingComponent.getBoundingClientRect().top, {
        smooth: false,
        useOffset: true
      })
    }
  },
  registerTabListingComponent({commit}, component) {
    setState(commit, 'tabListingComponent')(component)
  },
  toggleModal({commit, state}, [modalName, bool]) {
    if (bool === undefined || bool === null) {
      bool = !state.modals[modalName]
    }
    commit('setModals', [modalName, bool]);
    commit('setModals', ['modalWrapper', bool]);
    if (bool) {
      document.body.classList.add('should-lock-scroll');
    } else {
      document.body.classList.remove('should-lock-scroll');
    }
  },
}

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,

  namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
}
