import axios from 'shared/utils/axios';
import routes from 'rs/plugins/routes';

export const setState = (commit, state) => data => {
  commit('setState', [state, data])
  return data
}
export const setMeta = dispatch => meta => dispatch('Meta/setMeta', meta, {root: true})

const getRespondedData = obj => obj.hasOwnProperty('data') ? obj.data : obj

export const fetchRequest = (cache = null) => (dispatchSetMeta = null) => async (queryObject) => {
  if (cache) {
    const cachedData = cache.getCachedResult(queryObject);
    if (cachedData) return getRespondedData(cachedData);
  }
  // if no cache provided and no cached Result
  const {requestUrl, params} = queryObject;
  try {
    const {data} = await axios.get(requestUrl, {params: {format: 'json', ...params}})
    if (cache) cache.cacheResult(queryObject, data);
    if (dispatchSetMeta && typeof dispatchSetMeta === 'function') dispatchSetMeta(data.meta_data);
    return getRespondedData(data)
  } catch (e) {
    console.log(e)
    return {}
  }
}

export const storeName = route => {
  switch (route.name) {
    case routes.product.name:
      return 'Product';
    case routes.shop.name:
      return 'Shop';
  }
}
