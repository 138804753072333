<template>
  <div v-if="currentReviewer && currentReviewer.encoded_id === profileData.encoded_id" class="profile-page-nav">
    <div class="new-has-max-width nav-container">
      <a v-if="profileData.has_apps" :href="`/${currentReviewer.encoded_id}/?old_version=1&tab=my_applications`" class="btn pf-primary-button">My apps</a>
      <a v-if="profileData.has_stores" :href="`/${currentReviewer.encoded_id}/?old_version=1&tab=stores`" class="btn pf-primary-button">My stores</a>
      <router-link v-if="public" :to="`/${currentReviewer.encoded_id}`" class="btn pf-secondary-button">Edit View
      </router-link>
      <router-link v-else :to="`/profile/${currentReviewer.encoded_id}`" class="btn pf-secondary-button">Public View
      </router-link>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  props: {
    public: {type: Boolean, default: true},
  },
  computed: {
    ...mapGetters('SharedCurrentReviewer', ['currentReviewer']),
    storeName() {
      return this.$store.getters['Profile/storeName']
    },
    profileData() {
      return this.$store.getters[this.storeName + '/profileData']
    },
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.profile-page-nav {
  position: relative;
  left: calc(-50vw + 50%);
  width: 100vw;
  background: linear-gradient(270deg, #C1E6E6 5.9%, #A0DADA 97.43%);
  height: 56px;
  @include respond-to(notmobile) {
    height: 72px;
  }
}

.nav-container {
  height: 100%;
  padding: 0 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}
</style>
