import axios from 'axios'

const appAxios = axios.create()

// To track if there are pending requests (for wait_for_ajax in test)
//   See https://gist.github.com/qcam/11489603?permalink_comment_id=3162047#gistcomment-3162047
window.pendingRequestsCount = 0

appAxios.interceptors.request.use(config => {
  pendingRequestsCount++

  const csrfDom = document.querySelector('meta[name="csrf-token"]')
  const token = csrfDom && csrfDom.getAttribute('content') // Note: CSRF is disabled in test, so there is no `csrfDom` in test
  config.headers.post['X-CSRF-Token'] = token
  config.headers.put['X-CSRF-Token'] = token
  config.headers.delete['X-CSRF-Token'] = token

  return config
})

appAxios.interceptors.response.use(
  response => {
    pendingRequestsCount--
    return response
  },
  error => {
    pendingRequestsCount--
    return Promise.reject(error)
  }
)

// Temporarily add header `X-JUDGEME-REDESIGN` to all axios requests.
// This can be removed after we fully switch to the new design.
appAxios.interceptors.request.use(config => {
  config.headers.get['X-JUDGEME-REDESIGN'] = true
  config.headers.post['X-JUDGEME-REDESIGN'] = true
  config.headers.put['X-JUDGEME-REDESIGN'] = true
  config.headers.delete['X-JUDGEME-REDESIGN'] = true
  return config
})

export default appAxios
