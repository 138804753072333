<template>
    <VueSlider :value="defaultValue"
               :height="6"
               :interval="5"
               :process="process"
               :tooltip-formatter="formatter"
               :tooltip="'always'"
               contained="true"
               @change="handleChange"
    >
      <template v-slot:dot="{value: defaultValue}">
        <div :class="['custom-dot']">
          <div class="slider-custom-dot"></div>
          <div class="slider-custom-dot"></div>
        </div>
      </template>
    </VueSlider>
</template>

<script>
  import VueSlider from 'vue-slider-component';

  export default {
    props: ['defaultValue', 'min', 'max'],
    components: { VueSlider },
    data() {
      return {
        formatter: (value) => value == 0 ? 'All levels' : value+'%',
        process: dotsPos => [
            [100, dotsPos[0], {backgroundColor: '#088484'}]
        ]
      }
    },
    methods: {
      handleChange(e){
        this.$emit('change', e)
      }
    }
  }
</script>
