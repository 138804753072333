<template>
  <div>
    <div v-if="divider" class="feed-card__footer-divider"></div>
    <div class="feed-card__footer" >
      <ExternalLink
          class='feed-card__footer-link btn btn--brand'
          :href='href'
          :text='text'
      />
    </div>
  </div>
</template>
<script>
  import ExternalLink from 'rs/components/common/external_link'

  export default {
    components: { ExternalLink },
    props: ['text', 'href', 'divider'],
  }
</script>
<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .feed-card__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond-to(notmobile) {
      justify-content: flex-start;
    }
  }
  .feed-card__footer-link {
    width: 100%;
    @include respond-to(notmobile) { width: 260px; }
  }
  .feed-card__footer-divider {
    margin: 0 -16px 16px -16px;
    height: 1px;
    background-color: $feedCardDividerColor;
  }
</style>