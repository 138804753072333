<!--
 - Only Track search click if user click on links to product and shop page
 - Only track GA Product click if user click on product link
 - Only track GA Shop click if user click on shop link
 - Don't track anything if user click on heart button OR toggle transparency tooltip (on mobile)
 - use <a> or <router-link> to help crawling viable. https://developers.google.com/search/docs/crawling-indexing/links-crawlable
-->

<template>
  <router-link class="product-search-card"
               @click.native.capture.prevent="handleProductClick"
               @click.native.middle.capture.prevent="handleProductClick"
               event='' :to="productLink">
    <div class="product-search-card__inner">
      <div class="product-search-card__product-image">
        <div class="product-search-card__product-image-wrapper" :class="{ 'no-logo': !validImage }">
          <img v-if="validImage" @error="validImage=false" v-bind:src="searchResult.image_url" class="product-image" v-bind:alt="'Image for ' + searchResult.title" loading="lazy">
          <div v-else class="product-image-no-logo-wrapper">
            <div class="product-image-no-logo material-icons">wallpaper</div>
          </div>
        </div>
        <HeartBtn v-if="searchResult.encoded_product_id" class='product-search-card__heart-btn' :followeeClass='"Product"' :followeeEncodedId='searchResult.encoded_product_id' ref='heart'/>
      </div>
      <div class="product-search-card__shop-name--overlay">
        <router-link ref="shopLink" :to="shopLink" class="product-search-card__shop-name"
                     @click.native.prevent="handleShopClick" event=""
                     @click.native.middle.capture.prevent="handleProductClick"
        >{{ shop.name }}</router-link>
      </div>
      <div class="product-search-card__product-details" >
        <p ref="productLink" class="product-search-card__title">{{ searchResult.title }}</p>
        <div class="product-search-card__review-badge">
          <div class="product-search-card__review-badge-rating-section" v-if="average_rating > 0">
            <span class="product-search-card__review-badge--rating">{{ $sharedUtils.roundNumberToStringWithDecimalsSuffix(average_rating, 2) }}</span>
            <span class="product-search-card__review-badge--small">/5</span>
            <img :src="require('images/platform/green_star.svg')" alt="review star icon"/>
          </div>
          <div class="product-search-card__review-badge-rating-section" v-else>
            No reviews yet
          </div>
          <div v-if="number_of_reviews > 0" class="product-search-card__review-badge-reviews-count-section">
            Based on {{ $sharedUtils.pluralizeWithSeparator(number_of_reviews, 'review') }}
          </div>
          <div v-else class="product-search-card__link-text">
            Be the first to write a review
          </div>
        </div>
      </div>

    <div v-if="number_of_reviews > 0" class="product-search-card__metrics-section" @click="showTooltip" @mouseenter="showTooltip"  @mouseleave="closeTooltip">
      <Tooltip v-if="tooltipIsShow" class="product-search-card__tooltip"
               @close="closeTooltip"
               text="Percentage of verified reviews that are published"
      />
      <span v-if="searchResult.product_metrics && searchResult.product_metrics.transparency_score >=0" ref="transparency">
        {{ Math.round(searchResult.product_metrics.transparency_score * 10000)/100 }}% Transparent
      </span>
    </div>
    <div v-else class="product-search-card__metrics-section"></div>
    <div class="product-search-card__footer-placeholder"> </div>
    <div class="product-search-card__footer">
      <p class="product-search-card__price">
        <span v-if="shouldRenderPriceFromText" class="product-search-card__price-section--from">From </span>
        {{ price }}
      </p>
      <ShippingCountry class='product-search-card__shipping-country' :countryCode="searchResult.country"/>
    </div>
    </div>
  </router-link>
</template>

<script>
  import axios from 'shared/utils/axios'
  import HeartBtn from 'rs/components/common/heart_btn'
  import Tooltip from 'rs/components/common/tooltip_v2'
  import ShippingCountry from 'rs/components/common/shipping_country';
  import {mapState} from "vuex";
  import debounce from 'lodash/debounce';
  import { EVENT_CATEGORIES, EVENT_NAMES, EVENT_LABELS} from "shared/constants/google_analytics";

  export default {
    components: { HeartBtn, Tooltip, ShippingCountry },
    props: ['searchResult', 'doneSearchSha1', 'index', 'disableTracking'],
    data() {
      return {
        tooltipIsShow: false,
        validImage: this.searchResult.image_url
      }
    },
    computed: {
      redesign(){ return this.$route.query.redesign },
      ...mapState('Search', ['didTrackIndexGA']),
      ...mapState('SharedCurrentReviewer', ['currency', 'exchangeRates']),
      shop(){
        return { slug: this.searchResult.review_site_slug, name: this.searchResult.shop_name };
      },
      price(){
        const conversion = this.$sharedUtils.convertCurrency(this.currency, this.searchResult.base_currency, this.searchResult.lowest_price, this.exchangeRates);
        if (conversion !== null) { //  cannot convert
          return conversion
        } else {
          return this.searchResult.displayed_price
        }
      },
      shouldRenderPriceFromText: function () {
        return this.searchResult.displayed_highest_price && this.searchResult.displayed_highest_price !== this.searchResult.displayed_price
      },
      number_of_reviews: function () {
        return !this.searchResult.widget_published_reviews_count ? this.searchResult.number_of_reviews : this.searchResult.widget_published_reviews_count;
        // Fallback to normal average count when the product is not grouped
      },
      average_rating: function () {
        return !this.searchResult.widget_published_reviews_rating ? this.searchResult.average_rating : this.searchResult.widget_published_reviews_rating;
        // Fallback to normal average rating when the product is not grouped
      },
      productLink() {
        return {name: 'PageProduct', params: {shop_slug: this.shop.slug, product_handle: this.searchResult.handle}}
      },
      shopLink() {
        return {name: 'PageShop', params: {shop_slug: this.shop.slug}}
      }
    },
    methods: {
      trackSearchClick() {
        axios.post('/done_searches/click', { uuid: this.doneSearchSha1 })
      },
      trackProductClickGA(){
        if (this.didTrackIndexGA) return;

        this.$gtag.event(EVENT_NAMES.productCardClick, {
          'event_category': EVENT_CATEGORIES.viewContent,
          'event_label': EVENT_LABELS.searchResultClickWithIndex + (this.index + 1),
          })
        this.$store.commit('Search/setDidTrackIndexGA', true)
      },
      trackProductClick(){
        if (this.disableTracking) return;
        this.trackSearchClick()
        this.trackProductClickGA()
      },
      trackShopClick() {
        if (this.disableTracking) return;
        this.trackSearchClick()
        this.trackShopClickGA()
      },
      trackShopClickGA(){
        if (this.didTrackIndexGA) return;

        this.$gtag.event(EVENT_NAMES.storeNameClick, {
          'event_category': EVENT_CATEGORIES.viewContent,
          'event_label': EVENT_LABELS.searchResultClickWithIndex + (this.index + 1),
        })
        this.$store.commit('Search/setDidTrackIndexGA', true)
      },
      showTooltip(){
        window.showingProductTooltip = true
        this.tooltipIsShow = true
      },
      debouncedToggleWindowVar: debounce(function(){window.showingProductTooltip = false}, 100),
      closeTooltip(){
        this.tooltipIsShow = false
        this.debouncedToggleWindowVar()
      },
      openLink(e, link){
        if (e.metaKey || e.ctrlKey || e.button === 1) {
          window.open(this.$router.resolve(link).href);
        } else {
          this.$router.push(link)
        }
      },
      handleProductClick(e){
        if (
            window.showingProductTooltip        // if tooltips is showing  (for mobile only, tapping on other space will)
            || (this.$refs.heart && this.$refs.heart.$el === e.target) // or heart icon link
            || e.target === this.$refs.shopLink.$el  // or clicking on shop link
            || e.target === this.$refs.transparency  // or clicking on transparency section
        ) { return; }
        this.trackProductClick();
        this.openLink(e, this.productLink)
      },
      handleShopClick(e){
        if (window.showingProductTooltip) return;
        this.trackShopClick();
        this.openLink(e, this.shopLink)
      },
    },
    mounted(){
      if (this.index === 0) { // save position Y of first product card to handle scrolling on Search Header.
        window.firstCardBottomY = this.$el.getBoundingClientRect().bottom
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .product-search-card {
    cursor: pointer;
    text-decoration: none;
    color: $primaryFontColor;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .product-search-card__inner {
    border-radius: 10px;
    border: 1px solid #E7E7E7;
    box-shadow: 0 2px 6px #E4E4E4;
    position: relative;
    height: 100%;

    &:hover {
      @include respond-to(desktop) {
        border: 1px solid $newBrandColor;
        box-shadow: 0 1px 10px #C1E6E6;
      }
    }
  }

  .product-search-card__product-image-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    overflow: hidden;

    &.no-logo {
      background: linear-gradient(180deg, #A1DFDF 0%, #88C6D3 100%);
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
    }
  }

  .product-search-card__product-image {
    position: relative;
    top: 0;
    height: 0;
    width: 100%;
    padding-bottom: 100%;
  }

  .product-image {
    @include middle-align-child;
    object-fit: cover;
    width: 100%;
    height: auto;
    max-height: 100%;
  }

  .product-image-no-logo-wrapper {
    background: #C1E6E6;
    border-radius: 50%;
    width: 60%;
    height: 60%;
    position: absolute;
    z-index: 0;
  }

  .product-image-no-logo {
    @include middle-align-child;
    font-size: 54px;
    color: #8AC4D1;
  }

  .product-search-card__heart-btn {
    position: absolute;
    right: 4px;
    top: 4px;
    cursor: pointer;
  }

  .product-search-card__product-details {
    padding: 4px 6px;
    display: flex;
    gap: 4px;
    flex-direction: column;
    align-items: center;
  }
  .product-search-card__shop-name--overlay {
    position: relative;
    width: 100%;
    height: 24px;
    margin-top: 4px;
  }
  .product-search-card__shop-name {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0 8px;
    text-decoration: none;
    line-height: 22px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .product-search-card__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: $primaryFontColor;
    text-decoration: none;
  }

  .product-search-card__review-badge {
    margin-top: 6px;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
  }

  .product-search-card__review-badge-rating-section {
    line-height: 32px;
  }

  .product-search-card__review-badge--rating {
    font-size: 32px;
    font-weight: 800;
  }

  .product-search-card__review-badge--small {
    font-size: 10px;
    margin-left: 2px;
    margin-right: 5px;
  }

  .product-search-card__review-badge-reviews-count-section {
    line-height: 18px;
  }

  .product-search-card__metrics-section {
    margin-bottom: 20px;
    line-height: 24px;
    min-height: 4px;
    color: $secondaryFontColor;
    width: 100%;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    cursor: default;
  }
  .product-search-card__tooltip {
    top: -10px;
    transform: translateY(-100%);
    text-align: left;
    width: 162px;
    right: calc((100% - 162px)/2);
  }

  .product-search-card__footer-placeholder {
    height: 50px; // lineheight 22 * 2 (lines) + marginbot(16)
  }
  .product-search-card__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: auto;
    margin-bottom: 16px;
  }
  .product-search-card__price {
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 16px;
    line-height: 22px;
  }

  .product-search-card__price-section--from {
    font-weight: 400;
  }
  .product-search-card__link-text {
    color: $brandColor;
    text-decoration: underline;
    cursor: pointer;
  }

  .product-search-card__shipping-country {
    color: $primaryFontColor;
    font-size: 14px;
    height: 20px;
    margin-top: 4px;
  }
</style>
