<template>
  <section class="recommendations-wrapper" v-if="enabled">
    <div class="recommendations_container has-max-width" v-if="renderedCards.length > 2">
      <div class="recommendations__title">Recommended by reviewers</div>
      <swiper class="recommendations_swiper"
              ref="recommendationSwiper"
              :options="recommendationSwiperOptions"
              :mousewheel="false"
              @click="toggleModal"
      >
        <swiper-slide v-for="(card, index) in renderedCards" :key="index">
          <FeaturedCard :item="card"/>
        </swiper-slide>
      </swiper>
      <div class="recommendations-swiper_wrapper">
        <div class="swiper-pagination" slot="pagination"></div>
        <span class="slides-count">/ {{ renderedCards.length }}</span>
        <div class="recommendations-swiper swiper-button-prev" slot="button-prev"></div>
        <div class="recommendations-swiper swiper-button-next" slot="button-next"></div>
      </div>
    </div>
  </section>
</template>

<script>
import FeaturedCard from 'rs/components/common/recommendation/recommendation_card';

export default {
  components: {FeaturedCard},
  computed: {
    renderedCards() {
      return this.$store.getters['Shop/recommendations'] || []
    },
    enabled() {
      return this.$store.getters['Shop/shouldShowRecommendations'] && this.renderedCards.length > 0;
    },
    swiper() {
      return this.$refs.recommendationSwiper.$swiper
    },
  },
  methods: {
    toggleModal(e) {
      if (e.target.dataset.openModal === 'false') {
        return null
      }
      const index = this.$refs.recommendationSwiper.$swiper.clickedSlide.dataset.swiperSlideIndex * 1 // get the actual click slide by data attribute
      this.$store.commit('Shop/setSelectedRecommendation', this.renderedCards[index])
      this.$store.dispatch('Shop/toggleModal', ['showRecommendationModal']);
    },
  },
  data() {
    return {
      recommendationSwiperOptions: {
        autoUpdate: true,
        autoDestroy: true,
        spaceBetween: 12,
        breakpoints: {
          '370': {  // not enough room for super small mobile screen, show 1.1 card
            slidesPerView: 1,
          },
          '767': { // big screen mobile, show 2 cards with navigate buttons
            slidesPerView: 2,
            spaceBetween: 20,
          },
          '890': {
            slidesPerView: 4,
            spaceBetween: 10,
            autoplay: false,
          },
        },
        slidesPerView: "auto",
        loop: true,
        deleteInstanceOnDestroy: true,
        cleanupStylesOnDestroy: true,
        navigation: {
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next"
        },
        slidesPerColumnFill: 'row',
        direction: 'horizontal',
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: function (index) {
            return `<span class="recommendations-swiper-dot swiper-pagination-bullet">${index + 1}</span>`;
          },
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        },
        speed: 800,
      }
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

$mobilePadding: 16px;
$desktopPadding: 20px;

.recommendations-wrapper {
  translate: -50%;
  margin-left: 50%;
  width: 100vw;
  background: white;
  padding: 40px 0;
  margin-bottom: 20px;
}

.recommendations-swiper.swiper-button-next, .recommendations-swiper.swiper-button-prev {
  top: auto;
  position: relative;
  z-index: 1;
  margin-top: -15px;
  background: #333333;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.recommendations-swiper.swiper-button-next::after,
.recommendations-swiper.swiper-button-prev::after {
  font-size: 13px;
  font-weight: bold;
  color: white;
}

.recommendations-swiper_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 30px;
}

.recommendations_container {
  width: 100%;
}

.recommendations_swiper {
  overflow: hidden;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
}

.recommendations__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  vertical-align: middle;
  padding-bottom: $desktopPadding;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
  position: relative;
  margin-top: -10px;
  margin-right: 4px;
  z-index: 1;
}

.slides-count {
  margin-top: -10px;
  margin-right: 10px;
}

.recommendations-swiper.swiper-button-prev {
  margin-top: -15px;
  margin-right: 40px;
}

</style>

<style lang="scss">

.recommendations-swiper-dot {
  display: none !important;
}

span.recommendations-swiper-dot.swiper-pagination-bullet.swiper-pagination-bullet-active {
  display: inline-block !important;
  background: none;
}
</style>
