<template>
  <div class='shop-medal-event'>
    <FeedCardShopHeader v-bind:feedEvent="feedEvent" v-bind:shop="shop" :description="headerDescription" />
    <div class='feed-card-body'>
      <Medal v-bind:item="{...medalData, tier: medalData.tier_value}" />
      <div class='feed-card-body__medal_info'>
        <strong class='feed-card-body__medal-title'>{{ medalData.title }}</strong>
        <p class='feed-card-body__medal-description'>{{ medalData.description }}</p>

        <router-link v-bind:to="'/reviews/' + shop.review_site_slug" class='feed-card-body__cta btn btn--brand'>
          View Shop Reviews
        </router-link>
        <ExternalLink @click="trackExternalClick" class='feed-card-body__secondary-btn' :href='shop.shop_url' text='Visit Shop'/>
      </div>
    </div>
  </div>
</template>

<script>
  import FeedCardShopHeader from 'rs/components/common/feed_cards/_shop_header'
  import Medal from 'rs/components/common/_medal'
  import ExternalLink from 'rs/components/common/external_link'

  export default {
    components: { FeedCardShopHeader, Medal, ExternalLink },
    props: ['feedEvent'],

    computed: {
      headerDescription() {
        if (this.feedEvent.event_type === 'shop_update_medal') {
          return 'Has an update on a medal.'
        } else if (this.feedEvent.event_type == 'shop_get_new_medal') {
          return 'Has a new medal.'
        }
      },
      shop() { return this.feedEvent.event_owner },
      medalData() { return this.feedEvent.event_item },
    },
    methods: {
      trackExternalClick() {
        this.$store.dispatch('SharedClickTracking/trackExternalClick', {
          href: this.shop.shop_url,
          shopId: this.shop.encoded_id,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .medal {
    display: block;
  }
  .feed-card-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include respond-to(desktop) {
      flex-direction: row;
      gap: 16px;
    }
  }

  .feed-card-body__medal-title {
    display: block;
    margin: 0 0 20px 0;
    font-weight: bold;
    line-height: 1;
  }

  .feed-card-body__medal-description {
    color: $secondaryFontColor;
    margin-bottom: 20px;
  }

  .feed-card-body__cta {
    width: 100%;
    margin-bottom: 20px;
  }

  .feed-card-body__secondary-btn {
    display: inline-block;
    text-align: center;
    width: 100%;
  }


  @include respond-to(notmobile) {
    .medal {
      vertical-align: top;
      display: inline-block;
    }

    .feed-card-body__medal_info {
      vertical-align: top;
      display: inline-block;
    }

    .feed-card-body__medal-title {
      margin-top: 0;
    }

    .feed-card-body__cta {
      width: auto;
      margin-bottom: 0;
    }

    .feed-card-body__secondary-btn {
      width: auto;
      margin-left: 20px;
    }
  }
</style>
<style lang="scss">
  .feed-card-body {
    .medal,
    .medal__img {
      height: 100px;
      width: 100px;
    }
    .medal__value {
      font-size: 22px;
      line-height: 28px;
    }
    .medal__value--long {
      font-size: 20px;
    }
  }
</style>
