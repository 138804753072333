<!-- search_box > search_dropdown : only for Reviews page when user is not logged in -->
<template>
  <div class='search-card'>
    <div class='search-card__layout'
         :class="{
                  'search-card__layout--expand': showAutocompleteDropdown,
                  'search-card__layout--transparent': transparentBackground
                 }">
      <div class="dropdown">

        <!-- Dropdown Input -->
        <div class="input-box"
             :class="{'input-box--expand': showAutocompleteDropdown}"
        >
          <input class="dropdown-input"
                 id="search-input"
                 autocomplete="off"
                 @focus="showDropdown"
                 @blur="exit"
                 @keyup="keyMonitor"
                 :value="searchString"
                 @input="evt => searchString = evt.target.value"
                 @keyup.up="e => selectWithArrowKey(e, -1)"
                 @keyup.down="e => selectWithArrowKey(e, 1)"
                 :placeholder="placeholder || defaultPlaceholder"/>
          <label for="search-input" v-if="!isSearching" class="material-icons input_glass_icon"> search </label>
          <LoadingSpinner v-if='isSearching'/>

        </div>
        <!-- Dropdown Menu -->
        <div v-if="showAutocompleteDropdown" class="dropdown-content"
             :class="{'dropdown-content--expand': showAutocompleteDropdown}">
          <div v-show="suggestions.length >0">
            <div
                class="dropdown-item"
                :class="{'dropdown-item--highlight': highlighted(index) }"
                @mousedown="navigateToSearchPage"
                v-for="({text: suggestion, id: categoryId}, index) in suggestions"
                :key="index"
                @mouseover="hoverSelect(index, suggestion, categoryId)"
            >
              <span class="dropdown-item__text">
                <template v-if="categoryId && bMatchTermWithCategory(suggestion)">
                  <TextHighlight
                      :queries="completedSearchString.split(' ')"
                      :highlightComponent="HighLight"
                  >
                    {{ suggestion }}
                  </TextHighlight>
                </template>

                <template v-else>
                  <TextHighlight
                      :queries="completedSearchString.split(' ')"
                      :highlightComponent="HighLight"
                  >
                    {{ categoryId ? completedSearchString : suggestion }}
                  </TextHighlight>
                  <span v-if="categoryId" class="search-navbar__suggestion-category">in {{ suggestion }}</span>
                </template>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from 'rs/components/common/loading_spinner'
import TextHighlight from 'rs/components/common/highlight';
import HighLight from 'rs/components/layouts/highlight'
import {sanitizeParams} from 'rs/store/store_utils/search_utils';
import {debounce} from "lodash-es";
import axios from 'shared/utils/axios'
import defaultAxios from 'axios'

let cancel
const CancelToken = defaultAxios.CancelToken;
const searchHistory = {}

export default {
  props: ['placeholder', 'transparentBackground'],
  components: {LoadingSpinner, TextHighlight},
  data() {
    return {
      HighLight: HighLight,
      idx: 0,
      selected: null,
      willShowDropdown: false,
      searchString: '',
      completedSearchString: "",
      instantResults: {},
      isSearching: false,
      defaultPlaceholder: "Search product"
    }
  },

  computed: {
    showAutocompleteDropdown() {
      return this.willShowDropdown && this.doneSearches;
    },
    doneSearches() {
      return this.instantResults.suggestions && this.instantResults.suggestions.length > 0;
    },
    suggestions() {
      return this.instantResults.suggestions ? [...this.instantResults.suggestions] : [];
    },
  },
  methods: {
    highlighted(index) {
      return this.selected && this.selected.index === index
    },
    bMatchTermWithCategory(categoryText) {
      try {
        if (categoryText.length === 0) return false;
        const aText = this.completedSearchString.split(' ');
        const aCategoryText = categoryText.split(' ');
        let counter = 0;
        for (let i = 0; i < aText.length; i++) {
          for (let j = 0; j < aCategoryText.length; j++) {
            if (aCategoryText[j].startsWith(aText[i])) {
              counter++;
              j = aCategoryText.length; // break inner loop;
            }
          }
        }
        return counter === aText.length
      } catch {
        return false
      }
    },
    hoverSelect(idx, suggestionText, categoryId) {
      this.selected = {id: categoryId, text: suggestionText, index: idx};
      this.idx = idx;
    },
    handleSearch: debounce(function (keyword) {
      if (keyword.length > 0) {
        cancel && cancel()
        this.fetchInstantSearch({
          q: keyword,
        })
      } else {
        this.instantResults = {suggestions: []}
      }
    }, 200),

    async fetchInstantSearch(params) {
      this.isSearching = true;
      this.idx = 0;
      this.selected = null;
      const data = {
        suggestions: []
      }
      try {
        if (searchHistory[params.q]) {
          data.suggestions = [...searchHistory[params.q]];  // save value instead of ref
        } else {
          const res = await axios.get('/instant_search', {
            params,
            cancelToken: new CancelToken(c => cancel = c)
          })
          searchHistory[params.q] = res.data.suggestions;
          data.suggestions = [...res.data.suggestions]; // use value instead of ref because searchHistory[params.q] shares same ref
        }

        if (data.suggestions.length > 0) {
          let matchStringIndex = -1;
          data.suggestions.forEach((s, idx) => {
            // start checking whether there is a match (with search term) in suggestions
            if (s.length > 0 && s.length === params.q.length && s == params.q ) {
              matchStringIndex = idx;
            }
            data.suggestions[idx] = {
              id: s.id,
              text: s.text || s
            }
          });

          // remove the suggestion matched with search term
          if (matchStringIndex > -1) {
            data.suggestions.splice(matchStringIndex,1)
          }
          // unshift original string to response
          data.suggestions.unshift({
            id: undefined,
            text: params.q
          })
        }

        this.instantResults = data;
        this.completedSearchString = params.q;
      } catch (error) {}
      this.isSearching = false;
    },

    selectWithArrowKey(event, step) {
      event.preventDefault()
      let idx
      if (this.idx + step < 0) {
        idx = this.suggestions.length - 1
      } else if (this.idx + step > this.suggestions.length - 1) {
        idx = 0
      } else {
        idx = this.idx + step
      }
      this.idx = idx

      if (idx < this.suggestions.length) {
        this.selected = {index: idx, text: this.suggestions[idx].text, id: this.suggestions[idx].id}
      }
    },
    navigateToSearchPage(query) {
      if (this.searchString.length !== 0) {
        const query = sanitizeParams({
          ...this.$route.query,
          category_id: (this.selected && this.selected.id) ? this.selected.id : null,
          q: this.selected ? this.selected.text : this.searchString,
        })
        this.$router.push({
          name: 'PageSearch',
          query
        })
      }
    },
    navigateOnClick() {
      this.navigateToSearchPage({...this.$route.query, q: this.instantResults.suggestions[this.selected.index]})
    },
    showDropdown() {
      this.willShowDropdown = true;
    },
    exit() {
      this.idx = -1;
      this.selected = null;
      this.willShowDropdown = false;
    },
    keyMonitor: function (event) {
      if (event.key === 'Enter') {
        event.preventDefault()
        this.navigateToSearchPage()
        event.target.blur()
      }
    },
  },
  watch: {
    willShowDropdown(bool){
      this.$emit('searchFocused', bool)
    },
    instantResults() {
      this.isSearching = false;
    },
    searchString: function (newValue, oldValue) {
      if (newValue.trim() == oldValue.trim()) return;

      this.handleSearch(newValue)
      if (newValue.length > 0 && !this.willShowDropdown) {
        this.willShowDropdown = true
      } else if (newValue.length === 0) {
        this.willShowDropdown = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

$_height: 76px;
$_desktopHeight: 112px;

.search-card {
  position: relative;
  display: block;
  width: 100%;
  min-height: $_height;

  @include respond-to(desktop) {
    min-height: $_desktopHeight;
  }
}

.search-card__layout {
  display: block;
  background-color: white;
  padding: 12px 16px;
  border-radius: 129px;
  height: $_height;
  min-width: 100%;
  max-width: 100%;
  border: 1px solid $borderColor;
  box-shadow: 0 20px 20px rgba(181, 181, 181, 0.25);

  @include respond-to(notmobile) {
    border-radius: 129px;
    padding: 26px 48px;
    height: $_desktopHeight;
    margin: 0 -40px; // To make the search box exceed the width of the parent element
    min-width: calc(100% + 80px);
  }
}
.search-card__layout--transparent {
  background: transparent;
  border: none;
  box-shadow: none;
}

.search-card__layout--expand {
  position: absolute;
  z-index: 4;
  height: auto;
  border-radius: 35px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  @include respond-to(notmobile) {
    border-radius: 50px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.dropdown {
  position: relative;
  display: block;
  margin: auto;

  .input-box {
    display: flex;
    height: 50px;
    background: white;
    border: 1px solid $brandColor;
    border-radius: 35px;
    box-sizing: border-box;
    padding-right: 15px;
    @include respond-to(notmobile) {
      height: 60px;
    }

    .dropdown-input {
      display: block;
      width: 100%;
      padding: 20px;
      font-size: 16px;
      background-color: transparent;
      border: none;

      &:hover, &:focus {
        outline: none;
      }

      @include respond-to(mobile) {
        height: 50px;
      }
    }

    .input_glass_icon {
      margin: auto;
      color: $brandColor;
      cursor: pointer;
    }

    .loading-spinner {
      margin: auto;
      width: 28px;
      height: 28px;
    }
  }

  .input-box--expand {
    border-radius: 25px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.dropdown-content {
  background-color: #fff;
  width: 100%;
  border: 1px solid $brandColor;
  border-top: none;
  padding: 15px 0;
  box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
  overflow: auto;
  z-index: 4;

  .dropdown-item {
    padding: 0 15px;
    border: none;
    color: black;
    font-style: normal;
    font-size: 14px;
    line-height: 30px;
    text-decoration: none;
    display: block;
    cursor: pointer;

    > div {
      @include text-elipsis;
    }
  }

  .dropdown-item__text {
    font-weight: 700;
  }

  .dropdown-item--highlight {
    background-color: #E0F2F2
  }
}

.search-navbar__suggestion-category {
  color: $newBrandColor;
}

.dropdown-content--expand {
  height: fit-content;
}

</style>
