<template>
  <div class="recommendation-card">
    <div :class='"recommendation-card__" + item.card_focus'>
      <img ref="productImg" @error="setFallbackImg" :src=productImage :alt="'Image for ' + item.product_title" class="recommendation-card__review-product-image">
      <div class="recommendation-card__review-overlay"></div>
      <div class="recommendation-card__review-info" :class="{'header-with-button': productPath}">
        <RecommendationHeader :item="item" :productPath="productPath" :itemPrice="item.convertedPrice" class="recommendation-card__review-header"/>
        <div class="recommendation-card__review-quote">
          {{ formattedBody }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import RecommendationHeader from 'rs/components/common/recommendation/_recommendation_header';

  const CARD_QUOTE_LIMIT = 129

  export default {
    components: { RecommendationHeader },

    props: ['item'],

    computed: {
      shop() {
        return this.$store.getters["Shop/widgetData"]
      },
      formattedBody() {
        if (this.item.card_quote.length >= CARD_QUOTE_LIMIT) {
          return `${this.item.card_quote}...`
        } else {
          return this.item.card_quote
        }
      },
      productPath() {
        if (this.item.product_handle == 'judgeme-shop-reviews' ) {
          return null
        }
        else {
          return {name: this.$routes.product.name, params: {shop_slug: this.shop.shop_slug, product_handle: this.item.product_handle } }
        }
      },
      productImage() {
        return this.item.image_url ? this.item.image_url : 'https://pub-images.judge.me/judgeme/marketplace/no-img-fallback'
      }
    },

    methods: {
      setFallbackImg() {
        this.$refs.productImg.src = 'https://pub-images.judge.me/judgeme/marketplace/no-img-fallback'
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  $cardHeightMobile: 488px;
  $cardHeightDesktop: 344px;
  $cardHeightTablet: 528px;

  @include respond-to(desktop) {
    .recommendation-card__review:hover > .recommendation-card__review-overlay {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.87) 0%, white 78%);
    }
    .recommendation-card:hover {
      border: solid $brandColor 1px;
      box-shadow: 0px 0.855263px 8.55263px #C1E6E6;
    }
    .recommendation-card__review-overlay:hover ~ .recommendation-card__review-info, .recommendation-card__review-info:hover
    { display: block; padding-top: 18px; height: $cardHeightDesktop;}

    .recommendation-card__product:hover > .recommendation-card__product-overlay { background: linear-gradient(180deg, rgba(255, 255, 255, 0.87) 0%, white 78%); }

  }

  .recommendation-card {
    border: 1px solid $borderColor;
    box-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: white;
  }
  .recommendation-card__review, .recommendation-card__product {
    width: 100%;
    height: $cardHeightMobile;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    @include respond-to(desktop) {height: $cardHeightDesktop;}
    @include respond-to(tablet) {height: $cardHeightTablet;}
  }

  .recommendation-card__review-product-image {
    width: 100%;
    height: $cardHeightMobile;
    object-fit: cover;
    position: absolute;
    border-radius: 10px;
    text-indent: -999px;
    overflow: hidden;
    @include respond-to(tablet) {height: $cardHeightTablet;}
    @include respond-to(desktop) {height: $cardHeightDesktop;}
  }

  img.recommendation-card__review-product-image::after {
    text-indent: -999px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    background: url(https://pub-images.judge.me/judgeme/marketplace/no-img-fallback) no-repeat;
    background-size: cover;
    color: transparent;
    border-radius: 10px;
  }

  .recommendation-card__review-overlay {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.22) 33.5%, white 75%);

    @include respond-to(desktop) { background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 78%); }
    @include respond-to(tablet) { background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 50%); }
  }

  .recommendation-card__review-info {
    z-index: 0;
    margin-top: auto;
    text-align: center;
    overflow: hidden;
    border-radius: inherit;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 50%);
    padding: 0 12px;
    &:hover {
      overflow:visible;
    }
    &.header-with-button {
      @include respond-to(notmobile) {
        margin-bottom: 52px;
      }
    }
  }

  .recommendation-card__review-header {
    text-align: left;
    margin: 0 12px 16px 12px;
  }

  .recommendation-card__review-quote {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
    margin: 0px 16px 20px 16px;
    text-align: left;

    @include respond-to(desktop) {
      margin: 0 16px;
      text-align: left;
      height: 0;
      transition: height 250ms ease;
    }
  }

  .recommendation-card__product-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.22) 33.5%, white 75%);
    border-radius: inherit;

    @include respond-to(desktop) { background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 78%); }
  }
  .recommendation-card__product-rating {
    margin-top: 14px;
    margin-left: 16px;
    text-align: left;
  }

  .recommendation-card__product-view-product-btn {
    background-color: white;
    color: $brandColor;
    margin-top: 14px;
    font-weight: 800;
    font-size: 16px;
    width: 90%;
  }
</style>
