<template functional>
  <div class="new-dispute__notfound">
    <div class="notfound__heading">
      {{props.text}}
    </div>
    <div class="notfound__subtext">If you need assistance, please contact store directly.</div>
    <router-link class="btn pf-primary-button notfound__button" :to="props.goBackPath">
      Go Back
    </router-link>
  </div>
</template>
<script>
export default {
  props: ['goBackPath', 'text'],
};
</script>
<style lang="scss" scoped>
.new-dispute__notfound {
  width: 100%;
  max-width: 720px;
  margin: 28px auto auto auto;
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  background: white;
  padding: 44px 90px 40px 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.notfound__heading {
  font-weight: 700;
  font-size: 20px;
}
.notfound__subtext {
  margin-top: 12px;
}
.notfound__button {
  margin-top: 32px;
  width: 178px;
}
</style>
