<template>
  <img class='country-flag' :alt='altText' :src='currencyFlagSrc' loading='lazy' @error="$emit('error')">
</template>

<script>
export default {
  props: ['alt', 'currencyCode'],

  computed: {
    altText() { return this.alt || this.currencyCode },
    currencyFlagSrc() {
      if (!this.currencyCode) return null
      return 'https://judgeme-public-images.imgix.net/judgeme/currency_flags/' + this.currencyCode.toLowerCase() + '.png'
    },
  },
}
</script>
