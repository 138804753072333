<template>
  <div class="simple-product-card">
    <router-link class="product-search-card__product-image" :to="productPath">
      <div class="product-search-card__product-image-wrapper" :class="{ 'no-logo': !validImage }">
        <LazyImage
          :className="'product-image'"
          v-if="validImage" @error="validImage=false"
          :alt="'Image for ' + product.title"
          :urls='{ normal: product.image_url, retina: product.retina_image_url }'/>

        <div v-else class="product-image-no-logo-wrapper">
          <div class="product-image-no-logo material-icons">wallpaper</div>
        </div>
      </div>
    </router-link>

    <div class="simple-product-card__product-info">
      <router-link class="simple-product-card__product-name" :to="productPath">
        {{ product.title }}
      </router-link>
      <router-link class="simple-product-card__reviews-rating" :to="productPath">
        <SimpleRating class="simple-product-card__rating" :rating="product.average_rating"/>
        <div v-if="product.number_of_reviews > 0" class="simple-product-card__count">
          Based on {{ $sharedUtils.pluralizeWithSeparator(product.number_of_reviews, 'review') }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import SimpleRating from 'rs/components/common/simple_rating';
import LazyImage from 'rs/components/common/lazy_image';

export default {
  components: {SimpleRating, LazyImage},
  props: ['product'],
  data() {
    return {
      validImage: this.product.image_url
    }
  },
  computed: {
    productPath() {
      return `/reviews/${this.product.review_site_slug}/products/${this.product.handle}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.simple-product-card {
  padding: 16px;
  display: flex;
  column-gap: 12px;
  border-radius: 10px;
  border: 1px solid #E7E7E7;
}

.product-search-card__product-image-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;

  &.no-logo {
    background: linear-gradient(180deg, #A1DFDF 0%, #88C6D3 100%);
    border-radius: 5px;
  }
}

.product-search-card__product-image {
  position: relative;
  top: 0;
  height: 64px;
  width: 64px;
  min-width: 64px;
}

.product-image {
  @include middle-align-child;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.product-image-no-logo-wrapper {
  background: #C1E6E6;
  border-radius: 50%;
  width: 80%;
  height: 80%;
  position: absolute;
  z-index: 0;
}

.product-image-no-logo {
  @include middle-align-child;
  font-size: 32px;
  color: #8AC4D1;
}

.simple-product-card__product-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  color: $primaryFontColor;
}

.simple-product-card__product-name {
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
  color: $primaryFontColor;
  @include multiline-ellipsis(1)
}

.simple-product-card__reviews-rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: $primaryFontColor;
}

.simple-product-card__count {
  font-size: 14px;
  text-decoration: underline;
  color: $brandColor;
}

</style>
