<template>
  <div class="how-it-works-blocks__section">
    <div class="how-it-works-blocks__section__content-wrapper">
      <div class="how-it-works-blocks__section__content" v-for="(card, idx) in HowItWorksBlocks" v-bind:key="idx" :class="{ 'how-it-works-blocks-division': idx == 1 || idx == 4 }">
        <HowItWorks :heading="card.heading" :content="card.content" :blockUrl="card.blockUrl" :urlText="card.urlText" :blockUrlClass="card.blockUrlClass" :imageUrl="card.imageUrl" :blockIndex="idx" :urlVuePath="card.blockUrlVuePath"/>
        <div class="how-it-works-blocks__section__sub-content_wrapper" v-if="card.subContent">
          <div class="how-it-works-blocks__section__sub-content" v-for="subContentKey in Object.keys(card.subContent)" v-bind:key="subContentKey" >
            <div class="how-it-works-block__content--icon" v-html="card.subContent[subContentKey].icon"></div>
            <p class="how-it-works-block__content--paragraph" v-html="card.subContent[subContentKey].text"></p>
            <router-link class="how-it-works-block__content--link"
                :is="card.subContent[subContentKey].urlVuePath ? 'router-link' : 'a'"
                :to="card.subContent[subContentKey].linkTo"
                :href="card.subContent[subContentKey].linkTo"
            >
              {{ card.subContent[subContentKey].urlText }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import HowItWorks from "rs/components/pages/how_it_works/_how_it_works_block"

export default {
  components: {HowItWorks},
  data() {
    return {
      HowItWorksBlocks: [
        {
          heading: 'Search products',
          content: 'Our goal is to help you find products you can trust. These are the ones with high levels of Transparency and Authenticity according to our order data.',
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/how_it_works/img_01',
        },
        {
          heading: 'Why use the Judge.me Reviews platform?',
          subContent: {
            1: {
              icon: '🤩',
              text: 'We verify reviews independently using real order data. That means you see real reviews left by real people.',
              linkTo: '/trust',
              urlVuePath: true,
              urlText: "Find out how we're built for trust",
            },
            2: {
              icon: '🧐',
              text: 'Our Judge.me Medals let you know which products and stores you can Trust. Look out for the Diamond Transparency Medal, which we give to stores that have published all of their reviews.',
              linkTo: '/medals',
              urlVuePath: true,
              urlText: 'Learn more about Judge.me Medals',
            },
            3: {
              icon: '😌',
              text: 'Follow stores, products and people, and they’ll show up in your feed whenever they have updates.',
              linkTo: window.isReviewerLoggedIn ? '/feed' : '/login',
              urlVuePath: window.isReviewerLoggedIn ? true : false,
              urlText: window.isReviewerLoggedIn ? 'Manage what you’re following' : 'Sign in to start following'
            },
          },
        },
        {
          heading: 'Online shopping, done differently',
          content: "On the Judge.me Reviews platform, you can get real-time updates from your favourite shoppers, products and stores. You can also write reviews, rate items, and build wish lists by following products. It's the one-stop shop for people who love online shopping.",
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/how_it_works/heart_icon',
          urlText: 'Search Products',
          blockUrl: '/categories',
          blockUrlVuePath: true,
          blockUrlClass: 'btn btn-transparent',
        },
        {
          heading: 'Build a profile and connect with other shoppers',
          content: "Every review you write on a store using the Judge.me Product Reviews app will appear on your reviews profile. That way, you can manage your reviews in once place. <br /><br />  Just sign in using the email you used when you left your review, and we'll do the rest.",
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/how_it_works/img_02',
          urlText: window.isReviewerLoggedIn ? 'Go to Profile' : 'Sign in',
          blockUrl: window.isReviewerLoggedIn ? '/profile' : '/login',
          blockUrlClass: 'btn',
        },
        {
          heading: 'Get more from your reviews',
          content: "Make your reviews go further by adding affiliate links. You can quickly become an influencer by grouping your product recommendations together and inviting people to buy through your affiliate links. You can also add photos and videos to your reviews, and connect to YouTube, Instagram, and other social media.",
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/how_it_works/star_icon',
          blockUrlClass: '',
        },
        {
          heading: 'Check out the Trust Scores before you buy',
          content: "Need to know more before you buy? The Judge.me Reviews platform gives you the whole picture of each product and store.",
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/how_it_works/img_03_new',
          urlText: 'Find Stores',
          blockUrl: '/store_search',
          blockUrlVuePath: true,
          blockUrlClass: 'btn',
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.how-it-works-blocks__section {
  left: calc(-50vw + 50%);
  width: 100vw;
  min-height: 1400px;
  position: relative;
}

.how-it-works-blocks__section__heading {
  color: $newBrandColor;
  font-weight: 700;
  text-align: center;
  font-size: 32px;
  padding-top: 120px;
}

.how-it-works-blocks__section__content--heading, .how-it-works-blocks__section__sub-heading  {
  color: #333333;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  padding-bottom: 21px;
  @include respond-to(mobile) {
    font-size: 20px;
    line-height: 30px;
  }
}
.how-it-works-blocks__section__content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-width: 300px;
  @include respond-to(desktop) {
    min-height: 150px;
  }
}
.how-it-works-blocks__section__content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  margin: 0 auto;
  padding: 80px 0;
  @include respond-to(notdesktop) {
    flex-direction: column-reverse;
    gap: 28px;
    padding: 0px 16px;
    max-width: 100%;
  }
}

.how-it-works-blocks-division :first-child {
  .how-it-works-blocks__section__content {
    gap: 0px;
  }
}

a.how-it-works-block__content--link {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #088484;
  line-height: 40px;
}
.how-it-works-blocks__section__content.how-it-works-blocks-division {
  left: 0px;
  top: 0px;
  gap: 0px;
  background: radial-gradient(circle farthest-side at 120% 50%,
 #F4FBFD 25.8%, #C1E6E6 26%, #F4FBFD 11%, #F4FBFD 45.8%, #C1E6E6 46%, #F4FBFD 45%,
  #F4FBFD 65.8%, #C1E6E6 66%, #F4FBFD 10%, #F4FBFD 70%) right bottom,
  radial-gradient(circle 300px at left 0%,#E9F8F8 100%, #F4FBFD) left bottom;
  background-size: 50% 100%;
  background-repeat: no-repeat;
  border-radius: 20px;
  margin: 0px 100px 80px 100px;
  @include respond-to(mobile) {
    margin: 80px 20px;
    background: radial-gradient(circle at right, transparent 25.8%, #C1E6E6 26%, transparent 11%, transparent 45.8%, #C1E6E6 46%, transparent 45%, transparent 65.8%, #C1E6E6 66%, transparent 10%), url(https://pub-images.judge.me/judgeme/marketplace/how_it_works/corner-circle) 0px 0px no-repeat #f4fbfd;
    background-size: 100%, 300px;
  }

  border: $borderStyle;
  .how-it-works-block {
    margin: 0px 100px;
    text-align: center;
  }
}
.how-it-works-blocks__section-card__image {
  @include respond-to(notdesktop) {
    max-width: 300px;
    max-width: 100%;
  }
}

.how-it-works-blocks__section__content:first-child, .how-it-works-blocks__section__content:nth-child(4),  .how-it-works-blocks__section__content:nth-child(5) {
  .how-it-works-block {
    @include respond-to(desktop) {
      flex-direction: row-reverse;
    }
  }
}

.how-it-works-blocks__section__content:nth-child(3), .how-it-works-blocks__section__content:nth-child(5) {
  color: #fff !important;
  text-align: center;
  a {
    color: #fff !important;
  }
  .how-it-works-block {
    flex-direction: column-reverse;
    gap: 20px;
  }
}
.how-it-works-blocks__section__content:nth-child(5) {
  @include respond-to(notdesktop) {
    margin: 40px 0px;
    border-radius: 0;
  }
  margin: 0px 100px;
  background: url(https://pub-images.judge.me/judgeme/marketplace/how_it_works/rectangle);
  background-repeat: no-repeat;
  background-size: cover;
}

.how-it-works-blocks__section__content:nth-child(3) {
  background: url(https://pub-images.judge.me/judgeme/marketplace/how_it_works/empty_heart) left bottom no-repeat, linear-gradient(180deg, #2E6B97 -21.71%, #307A92 48.19%, #00483B 146.12%) no-repeat;
  background-size: auto, cover;
}

.how-it-works-blocks__section__content:first-child {
  @include respond-to(notmobile) {
    .how-it-works-block {
      flex-direction: row;
    }
  }
}

.how-it-works-blocks__section__cards-wrapper {
  margin-top: 12px;
  overflow: hidden;
  position: relative;
  width: 100%;
  -webkit-tap-highlight-color: transparent;

  @include respond-to(notmobile) {
    margin-top: 28px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    row-gap: 16px;
  }
}

  .how-it-works-blocks-division {
    flex-direction: column;
    padding: 60px 0px;
    @include respond-to(mobile) {
      padding: 30px 0;
    }
  }

  h1.how-it-works-heading {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    @include respond-to(mobile) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .how-it-works-blocks__section__sub-content_wrapper {
    @include respond-to(desktop) {
      display: flex;
      gap: 40px;
      padding: 0 60px;
    }
    @include respond-to(notdesktop) {
      margin-top: -24px;
    }
  }

  .how-it-works-blocks__section__sub-content {
      margin: 20px 0;
  }

  .how-it-works-block__content--icon {
    font-size: 40px;
  }
  .homepage-guarante__circles--wrapper {
    height: 800px;
    margin-top: -300px;
    background: radial-gradient(circle at 150% 20%, #F4FBFD 25.8%, #C1E6E6 26%, transparent 11%, transparent 45.8%, #C1E6E6 46%, transparent 45%, transparent 65.8%, #C1E6E6 66%, transparent 10%, transparent 70%);
    @include respond-to(desktop) {
      display: none;
    }
  }


</style>
