<template>
  <MatchMedia v-slot="{desktop}">
    <section class="product-view-header">
      <div class="has-desktop-max-width pvh__container">
        <div class="pvh__left">
          <div class="pvh__left-inner">
            <div class="pvh__all-category-text">All Categories</div>
            <Breadcrumb v-if="stack" :stack="stack" class="mb-3"/>
            <router-link v-if="viewAllLink && desktop" :to="viewAllLink" class="btn pf-primary-button cta__simple-page">View All
            </router-link>
          </div>
        </div>
        <div class="pvh__right">
          <Category v-for="(category,idx) in categories" :key="idx" :category="category"/>
        </div>
        <router-link v-if="viewAllLink && !desktop" :to="viewAllLink" class="btn pf-primary-button cta__simple-page">View All
        </router-link>
      </div>
    </section>
  </MatchMedia>
</template>
<script>
import Breadcrumb from "rs/components/common/breadcrumb"
import Category from 'rs/components/pages/categories/_root_category'
import {MatchMedia} from "shared/vue_media_query/MatchMedia";

export default {
  props: {
    categories: {type: Array, required: true},
    viewAllLink: {type: Object, default: null }
  },
  components: {MatchMedia, Category, Breadcrumb},
  computed: {
    stack() {
      return this.$route.name === this.$routes.categoryShow.name ? this.$store.getters['Category/stack']() : null;
    },
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.product-view-header {
  --category-icon-grid-size-mobile: 162px;
  --category-icon-grid-size-small-mobile: 142px;
  --category-icon-grid-size-desktop: 128px;
  @include respond-to(notmobile) {
    width: 100vw;
    translate: -50%;
    margin-left: 50%;
    height: 248px;
    background: url('https://pub-images.judge.me/judgeme/platform/categories/categories-page-default-background.png');
    background-size: cover;
  }
  background: url('https://pub-images.judge.me/judgeme/marketplace/categories-page-default-background-mobile.png');
  margin: 0 -12px;
  background-size: cover;
}

.pvh__container {
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column;
  padding: 0;
  @include respond-to(desktop) {
    flex-direction: row;
  }
}

.pvh__left,
.pvh__right {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
}

.pvh__left {
  @include respond-to(desktop) {
    width: 232px;
    min-width: 232px;
    margin-right: 20px;
  }
  width: 100%
}

.pvh__left-inner {
  @include respond-to(notdesktop) {
    margin: 28px 0;
    text-align: center;
    width: 100%;
  }
  @include respond-to(desktop) {
    margin: auto 0;
  }
}

.pvh__all-category-text {
  font-weight: 700;
  font-size: 24px;
  @include respond-to(desktop) {
    margin-bottom: 12px;
  }
}

.pvh__right {
  align-items: center;
  @include respond-to(notdesktop) {
    flex-wrap: wrap;
  }
}

.pvh__right > .category-card {
  justify-content: center;
  @include respond-to(notdesktop) {
    flex: 0 1 50%;
    height: auto;
  }
}

.cta__simple-page {
  width: 164px;
  height: 40px;
  font-size: 16px;
  @include respond-to(notdesktop) {
    width: 256px;
    margin-bottom: 40px;
  }
}

</style>
