<template>
  <section class="search-section">
    <CarouselSection />
    <div :class="{'search-section__full-page-mask': bDisplayMasking}"></div>
    <p class="search-section__title">{{ title }}</p>
    <p class="search-section__description">{{ description }}</p>
    <div class="search-section__search-box" :class="{'search-section__search-box--display-above': bDisplayMasking}">
      <div class="search-section__search-box-wrapper">
        <SearchDropdown placeholder="Search products" :transparentBackground="true" @searchFocused="handleFocusOnSearch"/>
      </div>
    </div>
  </section>
</template>
<script>
import SearchDropdown from 'rs/components/common/search_dropdown'
import CarouselSection from 'rs/components/pages/reviews/_carousel_section'

export default {
  components: { SearchDropdown, CarouselSection },
  computed: {
    homeMetrics(){ return this.$store.getters['Home/homeMetrics']},
    title: () => "Discover trusted products",
    description() { return `Read verified reviews from ${(this.homeMetrics.shop_metric_long && this.homeMetrics.shop_metric_long.toLocaleString('en-GB') ) || this.homeMetrics.shop_metric} stores and make informed buying decisions.` },
  },
  data() {
    return {
      bDisplayMasking: false,
    }
  },
  methods: {
    handleFocusOnSearch(bool) {
      this.bDisplayMasking = bool
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.search-section__full-page-mask {
  @include respond-to(mobile) {
    display: block;
    position: fixed;
    z-index: 2;   // overlay the header
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(4, 31, 31, 0.44);
  }
}

.search-section {
  margin: -4px -12px 0 -12px;
  padding: 40px 12px 0 12px;
  text-align: center;
  color: white;
  position: relative;
  height: 350px;
  @include respond-to(notmobile) {
    padding: 60px 12px 0 12px;
    height: 408px;
    width: 100vw;
    translate: -50%;
    margin-left: 50%;
  }
}
.search-section__title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 800;
  @include respond-to(notmobile) {
    font-size: 48px;
    line-height: 72px;
  }
}
.search-section__description{
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 12px 0 20px 0;
  @include respond-to(notmobile) {
    font-size: 20px;
    line-height: 30px;
  }
}

// toggle this class because if declare in  .search-section__search-box, by default, it will be on top of the header.
.search-section__search-box--display-above {
  z-index: 2;  // overlay the header
}

.search-section__search-box {
  position: relative;
  width: 100%;
  @include respond-to(notmobile) {
    display: flex;
    justify-content: center;
  }
}
.search-section__search-box-wrapper {
  background: rgba(255, 255, 255, 0.3);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 129px;
  text-align: left;
  @include respond-to(notmobile) {
    width: 720px;
    height: 112px;
    padding: 0 48px;
  }
}
</style>
<style lang="scss">
@import '~PlatformStyles/abstract/variables_new';
.search-section__search-box-wrapper .dropdown .input-box .input_glass_icon {
  color: $marketplaceSecondaryIconColor;
}
</style>
