<template functional>
  <svg v-on="data.listeners" :class="data.class" v-bind="data.attrs" xmlns="http://www.w3.org/2000/svg" width="16"
       height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M15.1111 13.3262C15.1111 13.9486 15.4725 14.4874 16 14.7514V14.9694C15.8524 14.9891 15.7018 15 15.5485 15C14.604 15 13.7521 14.6096 13.1494 13.9841C12.7854 14.0795 12.3992 14.1311 11.9997 14.1311C9.79039 14.1311 7.99978 12.5632 7.99978 10.6294C7.99978 8.69554 9.79039 7.12763 11.9997 7.12763C14.2089 7.12763 15.9996 8.69554 15.9996 10.6294C15.9996 11.3866 15.7249 12.0874 15.2587 12.6604C15.164 12.8631 15.1107 13.0889 15.1107 13.3266L15.1111 13.3262ZM3.20436 4.02507C2.28483 4.76088 1.77817 5.70723 1.77817 6.68991C1.77817 7.24099 1.93372 7.77589 2.23994 8.27883C2.55593 8.79752 3.03147 9.27157 3.61457 9.6502C4.03633 9.92377 4.31988 10.3606 4.39455 10.8526C4.41943 11.0168 4.43499 11.1822 4.44121 11.3468C4.54476 11.2619 4.64609 11.1713 4.7452 11.0737C5.08075 10.7432 5.53362 10.5607 6.00206 10.5607C6.07672 10.5607 6.15138 10.5655 6.22649 10.5747C6.5176 10.611 6.81448 10.6294 7.10958 10.6298V12.3807C6.7327 12.3807 6.36293 12.3571 6.00206 12.3115C4.47454 13.816 2.65148 14.0856 0.888864 14.1254V13.7573C1.84084 13.2981 2.66659 12.4612 2.66659 11.5053C2.66659 11.3717 2.65593 11.2409 2.63637 11.1126C1.02797 10.0695 0 8.4758 0 6.69034C0 3.54752 3.18347 1 7.11091 1C10.9784 1 14.1245 3.47049 14.2196 6.54677C13.649 6.2964 13.0272 6.14451 12.3828 6.10117C12.2228 5.33429 11.7521 4.61249 11.0184 4.02551C10.5277 3.63288 9.94995 3.32254 9.30152 3.1028C8.61132 2.86906 7.87445 2.75088 7.11136 2.75088C6.34827 2.75088 5.6114 2.8695 4.9212 3.1028C4.27233 3.32254 3.69456 3.63288 3.20436 4.02551V4.02507Z"
      :fill="props.color"/>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#088484',
    }
  }
}
</script>
