<template>
  <div class="product-review-card">
    <div class="prc__review">
      <img class="prc__review-image" :src="reviewFirstImage" @error="handleReviewImageError">
      <div class="prc__review-content-wrapper">
        <div class="d-flex-column gap-2 prc__review-content"
             @click.stop.capture="handleOpenModal" data-open-modal="true">
          <ReviewerHeader v-bind="reviewerHeaderProps"/>
          <StarRating :rating='card.review_rating'/>
          <div class="prc__review-body-section">
            <div v-html='card.review_body_html' class="mt-1 prc__review-body" @click.stop.capture="handleOpenModal"
                 data-open-modal="true">
            </div>
            <div class="prc__review-reaction">
              <span class="material-icons">thumb_up</span><span>{{ card.review_thumb_up }}</span>
              <span class="material-icons ml-3">thumb_down</span><span>{{ card.review_thumb_down }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <component class="d-flex gap-3 prc__product" v-bind="productProps">
      <img class="prc__product-image" :src="productImage" :alt="card.product_title + ' image'"/>
      <div class="prc__product-title">{{ card.product_title }}</div>
    </component>
  </div>
</template>
<script>

import StarRating from 'rs/components/common/star_rating'
import ReviewerHeader from 'rs/components/common/recommendation/recommendation_card_reviewer_header';
import {productProps} from 'shared/utils/component_link_utils';
import { reviewerHeaderProps, reviewFirstImage } from "rs/components/pages/reviews/helpers";
let didFallback = false;

export default {
  props: ['card'],
  inject: ['mediaQueries'],
  components: {ReviewerHeader, StarRating},
  computed: {
    reviewerHeaderProps() {
      return reviewerHeaderProps(this.card)
    },
    productProps() {
      return productProps(this.$routes, this.card.product_internal_path)
    },
    reviewFirstImage(){
      return reviewFirstImage(this.card)
    },
    productImage(){
      return this.card.product_image_url
    }
  },
  methods: {
    handleReviewImageError(e){
      if (didFallback) return;
      e.target.src = this.card.product_image_url
      didFallback = true;
    },
    handleOpenModal(e) {
      if ( e.target.dataset.openModal === 'true' || (e.target.parentElement && e.target.parentElement.dataset.openModal === 'true') ) {
        this.$emit('bodyClick')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

$desktopHeight: 426px;
$desktopWidth: 278px;
$mobileWidth: 341px;
$mobileHeight: 565px;
$defaultPadding: 16px;

$mobileReviewHeight: 464px;
$desktopReviewHeight: 326px;

.product-review-card {
  font-weight: 400;
  border: 1px solid #E7E7E7;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 16px;

  @include respond-to(desktop) {
    height: calc(#{$desktopHeight} + 2px);
    width: calc(#{$desktopWidth} + 2px);
  }

  .prc__review-body-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .prc__review-content {
    height: 100%;
    justify-content: flex-end;
  }
}

.product-review-card:hover {
  @include respond-to(desktop) {
    .prc__review-content-wrapper {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) -82.11%, #FFFFFF 80%);
    }
    .prc__review-content {
      justify-content: flex-start;
    }
    .prc__review-body-section {
      height: 100%;
    }
  }
}

.product-review-card:not(:hover) {
  @include respond-to(desktop) {
    .prc__review-reaction {
      display: none;
    }
    .prc__review-body {
      display: none;
    }
  }
}

.prc__review {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  height: $mobileReviewHeight;
  width: 100%;
  @include respond-to(desktop) {
    height: $desktopReviewHeight;
  }
}

.prc__review-image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prc__review-content-wrapper {
  position: absolute;
  padding: 32px $defaultPadding 12px $defaultPadding;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) -12.21%, #FFFFFF 75%);
  @include respond-to(notdesktop) {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -12.21%, #FFFFFF 60%);
  }
}

.prc__review-body {
  color: $primaryFontColor;
  display: -webkit-box;
  cursor: pointer;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @include respond-to(notdesktop) {
    -webkit-line-clamp: 3;
  }
}

.prc__review-reaction {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: default;
  gap: 10px;
  color: $brandColor;
  opacity: 0.5;
  font-size: 14px;
}

.prc__product {
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #E0F2F2;
  padding: $defaultPadding $defaultPadding 20px $defaultPadding;
  width: 100%;
  height: auto;
  display: flex;

  &:hover, &:focus, &:visited, &:not(.btn) {
    text-decoration: none;
  }
}

.prc__product-image {
  width: 64px;
  height: 64px;
  border-radius: 8px;
}

.prc__product-title {
  color: $primaryFontColor;
  display: -webkit-box;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  -webkit-line-clamp: 3;
  word-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
