const medalsInfo = {
  tran: [
    {name: 'Diamond', imgClass: 'tran-500', threshold: 100},
    {name: 'Platinum', imgClass: 'tran-400', threshold: 99},
    {name: 'Gold', imgClass: 'tran-300', threshold: 95},
    {name: 'Silver', imgClass: 'tran-200', threshold: 90},
    {name: 'Bronze', imgClass: 'tran-100', threshold: 80},
  ],
  auth: [
    {name: 'Diamond', imgClass: 'auth-500', threshold: 100},
    {name: 'Platinum', imgClass: 'auth-400', threshold: 99},
    {name: 'Gold', imgClass: 'auth-300', threshold: 95},
    {name: 'Silver', imgClass: 'auth-200', threshold: 90},
    {name: 'Bronze', imgClass: 'auth-100', threshold: 80},
  ],
}

export const getMedalInfo = (medalType, percentageValue) => {
  const roundedDownValue = Math.floor(percentageValue)
  const medals = medalsInfo[medalType]
  if (!medals) return null

  for (let i = 0; i < medals.length; i++) {
    if (roundedDownValue >= medals[i].threshold) {
      return medals[i]
    }
  }
  return null
}
