<template>
  <MatchMedia v-slot="{mobile, tablet, desktop}">
    <PageNotFound v-if="productNotFound"/>
    <section v-else class='product-page'>
      <Gallery v-if="galleryIsOpen"/>
      <Breadcrumb class='product-page__breadcrumb' :stack="stack" :linkForLastItem="true"/>
      <LoadingSpinner v-if='isLoadingWidget'/>
      <template v-else>
        <div class="product-page__container">
          <div class="product-page__container-main">
            <div ref='productCard' class='product-page__product-card'>
              <ProductInfo
                :productData='productData'
                :shopData='shopData'
                :reviewData='widgetSummary'
                @navigateToReviews="navigateToReviews"/>

              <div class="product-page__description">
                <div class="product-page__description-title" @click="readingDescription = !readingDescription">
                  Product details
                  <i class="material-icons product-page__description-accordion-icon">{{
                      readingDescription ? 'expand_less' : 'expand_more'
                    }}</i>
                </div>
                <CollapsibleItem>
                  <div v-show="readingDescription">
                    <!-- empty line -->
                    <p class="product-page__description-accordion-row"></p>
                    <p class="product-page__description-accordion-row">{{ unescapedDescription }}</p>
                    <div v-if="isLongDescription" class="product-page__description-accordion-row--last">
                      <div class='product-page__description-accordion-read-more'>
                        <ExternalLink @click="trackExternalClick" :href='productData.external_url'
                                      text="Read More in Store"/>
                      </div>
                    </div>
                  </div>
                </CollapsibleItem>
              </div>

              <Media v-if="media.length > 0" class="product-page__media-wrapper" :media="media"
                     ref="media" :visibleCount="mobile ? 4 : 7" :openGallery="openGallery"/>
              <div v-show="!showReviewForm && shopEnabledWebReview" ref="toggleReviewFormButtonGroup"
                   class="product-page__reviews-buttons-wrapper">
                <button class="btn pf-primary-button product-page__write-review-button" @click="toggleReviewForm">
                  Write a Product Review
                </button>
              </div>
              <!--  Toggle Form -->
              <div ref="reviewForm">
                <CollapsibleItem>
                  <div v-show='showReviewForm' class="product-page__review-form">
                    <ReviewForm
                      :resource="productData"
                      :resourceType="'Product'"
                      @closeForm="toggleReviewForm"
                      @doneSubmit="doneSubmitReview"
                    />
                  </div>
                </CollapsibleItem>
              </div>
            </div>

            <div class="product-page__widgets-wrapper" v-if="!hasProductReviews">
              <NoReviewsWidget class="product-page__no-review-widget" ref="reviewsWidget"
                               @toggleReviewForm="toggleReviewForm" :shopEnabledWebReview="shopEnabledWebReview"/>
            </div>

            <TabListing class="mt-5"/>
          </div>
          <div class="product-page__container-side" v-if="desktop">
            <SideContainer @navigateToReviews="navigateToReviews"/>
          </div>
        </div>
        <div class="product-page__widgets-wrapper pt-5 pb-5">
          <SectionTopProducts
            v-if="shopData && topReviewedProducts.length > 0"
            title="More from this store"
            :allProductsRoute="allProductsRoute"
            :products="parseTopProductsToProductSearchCards(topReviewedProducts, shopData)"/>
          <PromiseWidget v-if="mobile" class="product-page__promise-widget"/>
        </div>
      </template>
    </section>
  </MatchMedia>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import ProductInfo from 'rs/components/pages/product/sections/_section_product_info'
import PageNotFound from 'rs/components/common/page_not_found'
import SideContainer from 'rs/components/pages/product/_side_container';
import TabListing from 'rs/components/pages/product/sections/_section_tab_listing';

import Media from 'rs/components/common/media';
import Gallery from 'rs/components/common/gallery'
import Breadcrumb from 'rs/components/common/breadcrumb'
import ExternalLink from 'rs/components/common/external_link';
import PromiseWidget from 'rs/components/common/promise_widget';
import LoadingSpinner from 'rs/components/common/loading_spinner'
import CollapsibleItem from "rs/components/common/collapsible_item";

import ReviewForm from 'rs/components/uncommon/reviews/review_form';
import NoReviewsWidget from 'rs/components/uncommon/reviews/no_reviews_widget'
import SectionTopProducts from 'rs/components/pages/product/sections/_section_top_products';

import {removeSnippets} from "shared/utils/json_ld_snippet";
import {MatchMedia} from "shared/vue_media_query";
import {unescape} from "lodash";
import {parseTopProductsToProductSearchCards} from "rs/components/pages/product/helpers";
import {computed} from "vue";

export default {
  components: {
    MatchMedia,
    TabListing,
    CollapsibleItem,
    Breadcrumb,
    Gallery,
    LoadingSpinner,
    ProductInfo,
    NoReviewsWidget,
    Media,
    ReviewForm,
    SideContainer,
    PromiseWidget,
    ExternalLink,
    PageNotFound,
    SectionTopProducts,
  },
  provide() {
    return {shopData: computed(() => this.shopData)}
  },
  computed: {
    ...mapGetters('Product', [
      'widgetData', 'productData', 'shopData', 'widgetQuestionData', 'categoriesData',
      'media', 'widgetSummary', 'shopEnabledWebReview', 'productNotFound',
      'isLoadingWidget', 'didSearch', 'didFilter', 'hasProductReviews',
    ]),
    ...mapGetters('Shop', ['topReviewedProducts']),
    allProductsRoute() {
      return {
        text: 'View All Products',
        name: this.$routes.search.name,
        query: {
          shop_domain: this.shopData.domain,
          platform: this.shopData.platform,
          search_in_shop: true,
          min_transparency: 0,
          country: '*',
          sort_by: 'best_selling',
        }
      }
    },
    galleryIsOpen() {
      return this.$store.getters['Gallery/isOpen']
    },
    stack() {
      if (this.categoriesData.length === 0) return [];
      return this.categoriesData.map(category => ({
        name: category.name,
        path: `/categories/${category.handle}`,
      }))
    },
    isLongDescription() {
      return this.productData && this.productData.description && this.productData.description.length > 780;
    },
    shortDescription() {
      return this.productData && this.productData.description && this.productData.description.substring(0, 780);
    },
    unescapedDescription() {
      return unescape(this.isLongDescription ? (this.shortDescription + '...') : this.productData.description)
    },
  },
  data() {
    return {
      intervalID: '',
      scrolling: false,
      showReviewForm: false,
      readingDescription: false,
    }
  },
  methods: {
    parseTopProductsToProductSearchCards,
    toggleReviewForm() {
      this.showReviewForm = !this.showReviewForm
      // handle scroll
      if (this.showReviewForm) { // opening form
        const distance = this.$refs.reviewForm.getBoundingClientRect().top
        this.$nextTick(() => {
          this.$sharedUtils.scrollToDistanceWithHeaderOffset(distance || 0, {extraOffset: 60})
        })
      } else { // closing form
        this.$sharedUtils.scrollToDistanceWithHeaderOffset(0, {customOffset: 1})
      }
    },
    openGallery(id, review_uuid) {
      this.$store.dispatch('Gallery/openGallery', {
        forwardedMediaId: id,
        review_uuid: review_uuid,
        encodedId: this.productData.encoded_id,
        sourceType: 'product'
      })
    },
    smoothScrollOnProductCard() {
      const self = this;
      this.intervalID = setInterval(() => {
        if (self.scrolling) {
          self.scrolling = false;
          // do something with Scroll
        }
      }, 100)
    },
    scroll() {
      this.scrolling = true;
    },
    navigateToReviews() {
      this.$store.dispatch('Product/changeTab')
    },
    doneSubmitReview() {
      this.handleSmoothScroll(this.$refs.productCard.getBoundingClientRect().top)
    },
    handleSmoothScroll(refPos) {
      this.$nextTick(() => {
        this.$sharedUtils.scrollToDistanceWithHeaderOffset(refPos)
      })
    },
    trackExternalClick() {
      this.$store.dispatch('Product/trackExternalClick')
    },
  },
  mounted() {
    window.addEventListener('scroll', this.scroll);
  },
  beforeDestroy() {
    clearInterval(this.intervalID)
    window.removeEventListener('scroll', this.scroll);
    removeSnippets();
  },
  created() {
    this.$store.dispatch('Product/initPage', this.$route.params);
    this.$store.dispatch('Shop/loadPageData', this.$route.params);
    switch (this.$route.name) {
      case 'PageProduct':
        document.body.classList.add('mobile-white-background')
    }
  },
  watch: {
    isLoadingWidget(bool) {
      if (bool) return;
      if (this.$route.query.action === 'write-review') {
        this.$nextTick(() => {
          this.toggleReviewForm()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

$mobilePadding: 16px;
$desktopPadding: 28px;

.loading-spinner {
  margin: 144px auto;
  width: 72px;
  height: 72px;
}

.product-page {
  font-weight: 400;
  font-size: 16px;
  padding: 0;
  margin: -12px -12px 0 -12px;
  @include respond-to(desktop) {
    margin: -12px 0 -20px 0;
  }
}

.product-page__breadcrumb {
  width: fit-content;
  margin-top: 8px;
  @include respond-to(notdesktop) {
    margin: 16px 16px 0 16px;
  }
}

.product-page__container {
  display: flex;
  margin-top: 12px;
}


.product-page__container-main {
  width: 100%;
  @include respond-to(desktop) {
    margin-bottom: 40px;
  }
}

.product-page__product-card {
  margin: 0;
  width: 100%;
  padding: 0 16px;
  background-color: white;
  @include respond-to(desktop) {
    padding-bottom: 4px;
    border-radius: 12px;
    border: $newBorderStyle;
  }
}

.product-page__media-wrapper {
  margin-top: 20px;
  width: 100%;
  padding: 0;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  @include respond-to(desktop) {
    padding: 0 $desktopPadding;
  }
}

::v-deep .profile-card-thumbnails__child {
  @include respond-to(mobile) {
    width: 64px;
    height: 64px;
  }
}

.product-page__reviews-buttons-wrapper {
  margin: 24px 0 $desktopPadding 0;
  display: flex;
  gap: $mobilePadding;
  flex-direction: column;
  align-items: center;

  @include respond-to(desktop) {
    margin: 24px $desktopPadding $desktopPadding $desktopPadding;
    flex-direction: row;
    gap: 20px;
  }
}

.product-page__write-review-button {
  height: 40px;
  width: 238px;
  @include respond-to(desktop) {
    width: 208px;
    padding: 0;
  }
}

.product-page__description {
  flex: 1 1 100%;
  width: auto;
  line-height: 20px;
  text-align: justify;
  margin: 0;
  padding: 14px 0;
  border: solid 1px $feedCardDividerColor;
  border-left: none;
  border-right: none;

  @include respond-to(desktop) {
    margin: 0 $desktopPadding;
  }
}

.product-page__description-title {
  position: relative;
  padding-right: 28px;
  cursor: pointer;
}

.product-page__description-accordion-row {
  margin: 20px 0 10px 0;
  font-weight: 400;
}

.product-page__description-accordion-row--last {
  height: 30px;
  margin-top: -10px; // offset margin bottom for normal row
  position: relative;
}

.product-page__description-accordion-read-more {
  position: absolute;
  bottom: 0;
  font-size: 12px;
  color: $newBrandColor;
  width: 100%;
  height: 160px;
  background-image: linear-gradient(transparent, white);
  display: flex;
  align-items: flex-end;
}

.product-page__description-accordion-read-more > a {
  font-size: 16px;
}

.product-page__description-accordion-icon {
  position: absolute;
  right: 0;
  color: $brandColor;
}

.product-page__widgets-wrapper {
  background-color: #f0f5f5;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.product-page__promise-widget {
  margin: 16px 16px 28px 16px;
}

.product-page__no-review-widget {
  background-color: white;
  margin-bottom: 0;
  @include respond-to(desktop) {
    border: 1px solid $newBorderColor;
    border-radius: 12px;
  }
}
</style>

<style lang="scss">
.product-page__product-card {
  .enter-active {
    overflow: hidden;
    transition: height .3s linear;
  }

  .leave-active {
    overflow: hidden;
    transition: opacity .1s linear;
  }
}

.product-page__description {
  .enter-active,
  .leave-active {
    overflow: hidden;
    transition: height .3s linear;
  }
}
</style>
