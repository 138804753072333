"use strict";
/**
 * @file vue-awesome-swiper
 * @module event
 * @author Surmon <https://github.com/surmon-china>
 */
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.bindSwiperEvents = exports.handleClickSlideEvent = void 0;
var constants_1 = require("rs/plugins/swiper/constants");
var utils_1 = require("rs/plugins/swiper/utils");
var handleClickSlideEvent = function (swiper, event, emit) {
  var _a, _b, _c;
  if (swiper && !(swiper.destroyed)) {
    var eventPath = ((_a = event.composedPath) === null || _a === void 0 ? void 0 : _a.call(event)) || event.path;
    if ((event === null || event === void 0 ? void 0 : event.target) && eventPath) {
      var slides_1 = Array.from(swiper.slides);
      var paths = Array.from(eventPath);
      // Click slide || slide[children]
      if (slides_1.includes(event.target) || paths.some(function (item) { return slides_1.includes(item); })) {
        var clickedIndex = swiper.clickedIndex;
        var reallyIndex = Number((_c = (_b = swiper.clickedSlide) === null || _b === void 0 ? void 0 : _b.dataset) === null || _c === void 0 ? void 0 : _c.swiperSlideIndex);
        var reallyIndexValue = Number.isInteger(reallyIndex) ? reallyIndex : null;
        emit(constants_1.ComponentEvents.ClickSlide, clickedIndex, reallyIndexValue);
        emit((0, utils_1.kebabcase)(constants_1.ComponentEvents.ClickSlide), clickedIndex, reallyIndexValue);
      }
    }
  }
};
exports.handleClickSlideEvent = handleClickSlideEvent;
var bindSwiperEvents = function (swiper, emit) {
  constants_1.SWIPER_EVENTS.forEach(function (eventName) {
    swiper.on(eventName, function () {
      var args = [];
      for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
      }
      emit.apply(void 0, __spreadArray([eventName], args, false));
      var kebabcaseName = (0, utils_1.kebabcase)(eventName);
      if (kebabcaseName !== eventName) {
        emit.apply(void 0, __spreadArray([kebabcaseName], args, false));
      }
    });
  });
};
exports.bindSwiperEvents = bindSwiperEvents;
