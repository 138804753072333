"use strict";
/**
 * @file vue-awesome-swiper
 * @module directive
 * @author Surmon <https://github.com/surmon-china>
 */
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("rs/plugins/swiper/constants");
var event_1 = require("rs/plugins/swiper/event");
var utils_1 = require("rs/plugins/swiper/utils");
var INSTANCE_NAME_KEY = 'instanceName';
function getDirective(SwiperClass, globalOptions) {
  var getStandardisedOptionByAttrs = function (vnode, key) {
    var _a, _b, _c, _d;
    var value = (_b = (_a = vnode.data) === null || _a === void 0 ? void 0 : _a.attrs) === null || _b === void 0 ? void 0 : _b[key];
    return value !== undefined
      ? value
      : (_d = (_c = vnode.data) === null || _c === void 0 ? void 0 : _c.attrs) === null || _d === void 0 ? void 0 : _d[(0, utils_1.kebabcase)(key)];
  };
  // Get swiper instace name in directive
  var getSwiperInstanceName = function (element, binding, vnode) {
    return (binding.arg ||
      getStandardisedOptionByAttrs(vnode, INSTANCE_NAME_KEY) ||
      element.id ||
      constants_1.CoreNames.SwiperInstance);
  };
  var getSwiperInstance = function (element, binding, vnode) {
    var instanceName = getSwiperInstanceName(element, binding, vnode);
    return vnode.context[instanceName] || null;
  };
  var getSwipeOptions = function (binding) {
    return binding.value || globalOptions;
  };
  var getBooleanValueByInput = function (input) {
    return [true, undefined, null, ''].includes(input);
  };
  // Emit event in Vue directive
  var getEventEmiter = function (vnode) {
    var _a, _b;
    var handlers = ((_a = vnode.data) === null || _a === void 0 ? void 0 : _a.on) || ((_b = vnode.componentOptions) === null || _b === void 0 ? void 0 : _b.listeners);
    return function (name) {
      var _a;
      var args = [];
      for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
      }
      var handle = (_a = handlers) === null || _a === void 0 ? void 0 : _a[name];
      if (handle) {
        handle.fns.apply(handle, args);
      }
    };
  };
  return {
    // Init
    bind: function (element, binding, vnode) {
      // auto class name
      if (element.className.indexOf(constants_1.DEFAULT_CLASSES.containerClass) === -1) {
        element.className += ((element.className ? ' ' : '') + constants_1.DEFAULT_CLASSES.containerClass);
      }
      // bind click event
      element.addEventListener('click', function (event) {
        var emitEvent = getEventEmiter(vnode);
        var swiper = getSwiperInstance(element, binding, vnode);
        (0, event_1.handleClickSlideEvent)(swiper, event, emitEvent);
      });
    },
    // DOM inserted
    inserted: function (element, binding, vnode) {
      var context = vnode.context;
      var swiperOptions = getSwipeOptions(binding);
      var instanceName = getSwiperInstanceName(element, binding, vnode);
      var emitEvent = getEventEmiter(vnode);
      var vueContext = context;
      var swiper = vueContext === null || vueContext === void 0 ? void 0 : vueContext[instanceName];
      // Swiper will destroy but not delete instance, when used <keep-alive>
      if (!swiper || swiper.destroyed) {
        swiper = new SwiperClass(element, swiperOptions);
        vueContext[instanceName] = swiper;
        (0, event_1.bindSwiperEvents)(swiper, emitEvent);
        emitEvent(constants_1.ComponentEvents.Ready, swiper);
        // MARK: Reinstance when the nexttick with <keep-alive>
        // Vue.nextTick(instancing) | setTimeout(instancing)
      }
    },
    // On options changed or DOM updated
    componentUpdated: function (element, binding, vnode) {
      var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
      var autoUpdate = getStandardisedOptionByAttrs(vnode, constants_1.ComponentPropNames.AutoUpdate);
      if (getBooleanValueByInput(autoUpdate)) {
        var swiper = getSwiperInstance(element, binding, vnode);
        if (swiper) {
          var swiperOptions = getSwipeOptions(binding);
          var isLoop = swiperOptions.loop;
          if (isLoop) {
            ;
            (_b = (_a = swiper) === null || _a === void 0 ? void 0 : _a.loopDestroy) === null || _b === void 0 ? void 0 : _b.call(_a);
          }
          (_c = swiper === null || swiper === void 0 ? void 0 : swiper.update) === null || _c === void 0 ? void 0 : _c.call(swiper);
          (_e = (_d = swiper.navigation) === null || _d === void 0 ? void 0 : _d.update) === null || _e === void 0 ? void 0 : _e.call(_d);
          (_g = (_f = swiper.pagination) === null || _f === void 0 ? void 0 : _f.render) === null || _g === void 0 ? void 0 : _g.call(_f);
          (_j = (_h = swiper.pagination) === null || _h === void 0 ? void 0 : _h.update) === null || _j === void 0 ? void 0 : _j.call(_h);
          if (isLoop) {
            ;
            (_l = (_k = swiper) === null || _k === void 0 ? void 0 : _k.loopCreate) === null || _l === void 0 ? void 0 : _l.call(_k);
            (_m = swiper === null || swiper === void 0 ? void 0 : swiper.update) === null || _m === void 0 ? void 0 : _m.call(swiper);
          }
        }
      }
    },
    // Destroy this directive
    unbind: function (element, binding, vnode) {
      var _a;
      var autoDestroy = getStandardisedOptionByAttrs(vnode, constants_1.ComponentPropNames.AutoDestroy);
      if (getBooleanValueByInput(autoDestroy)) {
        var swiper = getSwiperInstance(element, binding, vnode);
        if (swiper && swiper.initialized) {
          (_a = swiper === null || swiper === void 0 ? void 0 : swiper.destroy) === null || _a === void 0 ? void 0 : _a.call(swiper, getBooleanValueByInput(getStandardisedOptionByAttrs(vnode, constants_1.ComponentPropNames.DeleteInstanceOnDestroy)), getBooleanValueByInput(getStandardisedOptionByAttrs(vnode, constants_1.ComponentPropNames.CleanupStylesOnDestroy)));
        }
      }
    }
  };
}
exports.default = getDirective;