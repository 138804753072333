<template>
  <img class='country-flag' :alt='altText' :src='countryFlagSrc' loading='lazy' @error="$emit('error')">
</template>

<script>
  export default {
    props: ['alt', 'countryCode'],

    computed: {
      altText() { return this.alt || this.countryCode },
      countryFlagSrc() {
        if (!this.countryCode) return null
        return 'https://judgeme-public-images.imgix.net/judgeme/flags/' + this.countryCode.toUpperCase() + '.svg'
      },
    },
  }
</script>
