var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-wrapper",class:{'category-wrapper--hidden' : _vm.level === 4}},[_c('div',{staticClass:"category-line-wrapper",class:{
                  'category-line-wrapper--direct-child' : _vm.isDirectChild(_vm.category, _vm.parentCategory),
                  'category-line-wrapper--normal'       : _vm.level !== 2,
                  'category-line-wrapper--last-child'   : _vm.isLastChild,
               }},[_c('router-link',{staticClass:"category-line__name",class:{
                    'category-line__name--direct-child' : _vm.isDirectChild(_vm.category, _vm.parentCategory),
                    'category-line__name--normal'       : _vm.level !== 2
                 },attrs:{"to":_vm.linkToParams(_vm.category)}},[_vm._v("\n      "+_vm._s(_vm.name)+"\n    ")]),(!_vm.deepest)?_c('router-link',{staticClass:"material-icons category-line__name-arrow",attrs:{"to":_vm.linkToParams(_vm.category)}},[_vm._v("arrow_forward_ios")]):_vm._e()],1),(!_vm.deepest)?_c('CategoryChildren',{attrs:{"linkToParams":_vm.linkToParams,"categories":_vm.getChildren()}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }