<template>
  <div class="search-screen-categories">
    <ItemLine :item="{ id: null, name: 'All' }" :change="change" />
    <div v-for="itemID in categoriesLevelOneIDs">
      <ItemLine :item="categories[itemID]" :index="itemID" :change="change" />
    </div>
  </div>
</template>

<script>
import ItemLine from 'rs/components/pages/search/_screen_item_line';
import {mapState} from "vuex";

export default {
  components: {ItemLine},
  props: ['change'],
  computed: {
    ...mapState('Search', ['categories', 'categoriesLevelOneIDs']),
  },
}
</script>
<style lang="scss">
  .search-screen-categories {
    margin-bottom: 20px;
  }
</style>