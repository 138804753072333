
import { AVAILABLE_FILE_ICONS, SUPPORTED_FILE_TYPES } from "shared/vue_components/arbitration/constants.js";
import {pipe} from 'shared/utils/js_core'

const getFileIcons = files => {
  const mutatedFiles = files.map(file => {
    let fileType;
    if (file.type) {
      fileType = file.type.split('/').slice(-1)[0]
    } else if (file.url) {
      fileType = extensionFromUrl(file.url)
    }
    if (!fileType) return null
    fileType = AVAILABLE_FILE_ICONS[fileType.toLowerCase()]
    if (!fileType) return null
    return {
      url: file.url,
      size: file.size,
      type: fileType,
    }
  })
  return mutatedFiles.filter(file => !!file)
}


const extensionFromUrl = url => url.split('.').slice(-1)[0]
const iconFromExtension = extension => 'file_' + AVAILABLE_FILE_ICONS[extension]
const iconFromUrl = pipe(extensionFromUrl, iconFromExtension)
const allowedFileTypes = Object.keys(SUPPORTED_FILE_TYPES)
const allowedMimeTypes = Object.values(SUPPORTED_FILE_TYPES)

export {
  getFileIcons,
  allowedFileTypes,
  allowedMimeTypes,
  extensionFromUrl,
  iconFromExtension,
  iconFromUrl,
}
