import {setNewMeta} from 'shared/utils/router_utils';
import router from 'rs/plugins/router'
import routes from 'rs/plugins/routes'
import axios from 'shared/utils/axios';

const NOT_FETCH_PAGES = {
  [routes.categoryIndex.name]: true // categoryIndex page reuses data from search.js > fetchAllRootCategories() which is automatically executed in the navbar, therefore no need to fetch again
}

const defaultState = {
  defaultMetaData: null,
  pageMetas: {}  // for static pages only (do not include product, shop, categories)
}

const mutations = {
  setState: (state, [stateName, payload]) => state[stateName] = payload,
  setPageMetas: (state, [pageName, payload]) => state.pageMetas[pageName] = payload
}

const actions = {
  initMeta({commit}){
    const { page_title, page_description, shop_metric, total_verified_reviews } = window.app_meta
    commit('setState', ['defaultMetaData', { page_title, page_description, shop_metric, total_verified_reviews }])
  },
  setMeta ({ state }, allData) {
    setNewMeta(allData || defaultState);
  },
  async getMeta({commit, state, dispatch}) {
    if (state.pageMetas[router.currentRoute.name]) {  // don't fetch again if already visited.
      return dispatch('setMeta', state.pageMetas[router.currentRoute.name])
    }
    if (NOT_FETCH_PAGES[router.currentRoute.name]) return;

    const resp = await axios.get(router.currentRoute.path, { params: {format: 'json'} })
    commit('setPageMetas', [router.currentRoute.name, resp.data.meta_data])
    dispatch('setMeta', resp.data.meta_data);
  }
}

export default {
  namespaced: true,
  state: {...defaultState},
  actions,
  mutations,
}
