import {pipe} from 'shared/utils/js_core'

function setTitle(meta) {
  const title = meta && meta.page_title
  if (title) document.title = title;
  return meta
}
function setDescription(meta) {
  const description = meta && meta.page_description
  if (!description) return meta;
  let el =  document.querySelector('meta[name="description"]')
  if (el) el.setAttribute('content', description);
  else {
    el = document.createElement('meta')
    el.setAttribute('name', 'description')
    el.setAttribute('content', description)
    document.head.appendChild(el)
  }
  return meta
}

const setNewMeta = pipe(setTitle, setDescription)


export {
  setNewMeta
}
