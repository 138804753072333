<template>
  <section class='rs-store-search-page'>
    <div v-if="!isNewSearch" class='search-results'>
      <div class='search-results__title'>Find Stores</div>
      <p class='search-results__text-secondary'>Find a specific store or brand you're looking for.</p>
      <form @submit.prevent="handleSubmit" class="search-results__search_form">
        <label class="search-results__search-field-glass-icon material-icons" data-text="search" for='search'> </label>
        <input
            type="search"
            :value="$route.query.q"
            id="search"
            class="search-results__search-field" placeholder="Search for stores"/>
      </form>

    <div v-if="searchState">
      <div class='search-results__title'>All Results</div>
      <ShippingCountryDropdown
          v-on:selected="selectShippingCountryHandler"
          :selected="currentCountry"
      />
      <div v-bind:class="{ 'search-query-empty': (searchParams.q == '' || !searchParams.q) }" class='search-results__summary'>
        <p v-if="searchResults.results_total_count == null" >
          Searching for "{{ searchParams.q }}"
        </p>
        <p v-else>
          {{ searchResults.results_total_count.toLocaleString('en-US') }} results found for "{{ searchParams.q }}"
        </p>
      </div>

      <NoSearchResults v-if='searchResults.results_total_count <= 0'/>
      <div v-else>
        <div class='search-results__list'>
          <ShopSearchCard v-for="(searchResult, index) in searchResults.shop_results"
                          v-bind:searchResult="searchResult"
                          v-bind:key="index"/>
        </div>
      </div>
    </div>
    <div v-else>
      <div class='search-results__title'>Featured Stores</div>
      <p class='search-results__subtitle'>Popular stores right now</p>
      <div class='search-results__list'>
        <ShopSearchCard v-for="(searchResult, index) in searchResults.shop_results"
                        v-bind:searchResult="searchResult"
                        v-bind:key="index"/>
      </div>
    </div>

    </div>

    <LoadingSpinner v-if='isLoadingSearchResults'/>
    <div v-else-if='hasMoreSearchResults' class='search-results__load-more btn btn--text-bg' @click='fetchMoreResults'>
      Load More
    </div>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import LoadingSpinner from 'rs/components/common/loading_spinner'
  import NoSearchResults from 'rs/components/common/no_search_results'
  import ShopSearchCard from 'rs/components/common/shop_search_card'
  import ShippingCountryDropdown from 'rs/components/common/shipping_country_dropdown';

  export default {
    components: {
      LoadingSpinner,
      NoSearchResults,
      ShopSearchCard,
      ShippingCountryDropdown,
    },

    computed: {
      ...mapState('ShopSearch', [
        'searchParams',
        'searchResults',
        'isLoadingSearchResults',
        'hasMoreSearchResults',
        'isNewSearch'
      ]),
      ...mapState('SharedCurrentReviewer', ['currentCountry']),
    },

    data(){
      return {
        searchState: !!this.$route.query.q
      }
    },
    mounted(){
      this.makeNewSearch(this.$route.query.country || this.currentCountry || localStorage.getItem('country'))
    },
    methods: {
      fetchMoreResults: function() {
        this.$store.dispatch('ShopSearch/searchShops', {
          q: this.searchParams.q,
          country: this.currentCountry,
          page: this.searchParams.page + 1,
        })
      },
      makeNewSearch(val){
        this.$store.dispatch('ShopSearch/makeNewSearch', { q: this.$route.query.q, country: val })
      },
      selectShippingCountryHandler(code){
        this.$store.dispatch('SharedCurrentReviewer/updateCurrentCountry', code)
        this.$router.replace({
          query: {
            q: this.$route.query.q,
            page: 1,
            country: code,
          }
        })
      },
      handleSubmit(e){
        this.$router.push({name: 'PageShopSearch', query: { ...this.$route.query, q: e.target.search.value, page: 1 }  })
      }
    },
    watch: {
      '$route.query.q': function(val) {
        this.$store.dispatch('ShopSearch/makeNewSearch', { q: val, country: this.currentCountry })
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .loading-spinner {
    margin: 24px auto;
    width: 72px;
    height: 72px;
  }
  .search-results__text-secondary {
    font-size: 16px;
    color: $secondaryFontColor;
  }
  .search-results__search_form {
    margin-top: 20px;
    position: relative;
    width: 100%;
    max-width: 652px;

    .search-results__search-field {
      width: 100%;
      border: 1px solid #E4E4E4;
      border-radius: 40px;
      height: 50px;
      &:focus {
        outline: none;
      }
    }
    .search-results__search-field-glass-icon {
      position: absolute;
      z-index: 2;
      right: 0;
      margin: 12px 12px 12px 0;
      height: 24px;
      width: 24px;
      color: $marketplaceSecondaryIconColor;
    }
  }

  .search-results--no-results {
    .search-results__summary {
      display: inline-block;
    }
  }
  .search-results__title {
    font-size: 24px;
    line-height: 40px;
    font-weight: 600;
    margin-top: 20px;
  }
  
  .search-results__subtitle {
    font-size: 16px;
    color: $brandColor;
  }

  .search-results__summary {
    font-size: 16px;
    color: $brandColor;
    margin: 12px 0 20px 0;
  }

  .shipping-country {
    display: block;

    @include respond-to(desktop) {
      float: right;
    }
  }

  .search-query-empty {
    visibility: hidden;
  }

  .search-results__list {
    margin-top: 20px;
    display: flex; // To make the cards have the same height
    flex-wrap: wrap;
  }

  .shop-search-card {
    display: inline-block;
    margin-bottom: 20px;
    margin-right: 20px;

    @include respond-to(mobile) {
      width: calc(50% - 10px);
      &:nth-of-type(2n) { margin-right: 0; }
    }

    @include respond-to(desktop) {
      width: calc(25% - 15px);
      &:nth-of-type(4n) { margin-right: 0; }
    }

    @include respond-to(tablet) {
      width: calc(33.3% - 13.3px);
      &:nth-of-type(3n) { margin-right: 0; }
    }
  }

  .search-results__load-more {
    display: block;
    width: 200px;
    margin: 20px auto;
  }
</style>
