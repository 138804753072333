<template>
  <div class='review-event'>
    <FeedCardShopHeader :feedEvent="feedEvent" :shop="feedEvent.event_owner" :description="headerDescription" v-if="this.feedEvent.event_owner.class == 'Shop'" />
    <FeedCardProductHeader :feedEvent="feedEvent" :product="feedEvent.event_owner" :description="headerDescription" v-else-if="this.feedEvent.event_owner.class == 'Product'" />
    <div v-else-if="this.feedEvent.event_owner.class == 'Reviewer'">
      <FeedCardReviewerHeader :feedEvent="feedEvent" :reviewer="feedEvent.event_owner" :review="feedEvent.event_item" :description="headerDescription" />
      <FeedCardProductHeader :feedEvent="feedEvent" :product="feedEvent.event_owner.product" :shouldHideTimestamp='true' v-if="feedEvent.event_owner.product" />
      <FeedCardShopHeader :feedEvent="feedEvent" :shop="feedEvent.event_owner.shop" :shouldHideTimestamp='true' v-if="feedEvent.event_owner.shop" />
    </div>

    <component :is="displayBody" v-bind="bodyProps"></component>
    <component :is="displayFooter" :feedEvent="feedEvent" v-bind="footerProps.props" v-on="footerProps.events"></component>
  </div>
</template>

<script>
  import FeedCardShopHeader from 'rs/components/common/feed_cards/_shop_header'
  import FeedCardProductHeader from 'rs/components/common/feed_cards/_product_header'
  import FeedCardReviewerHeader from 'rs/components/common/feed_cards/_reviewer_header'
  import DoubleButtonFooter from 'rs/components/common/feed_cards/footers/_double_button_footer'
  import ReviewBody from 'rs/components/common/review'


  const FEED_EVENT_TYPE_DESCRIPTIONS = {
    shop_publish_shop_review: 'Has a new review.',
    prod_have_published_review: 'Has a new review.',
    reviewer_write_product_review: 'Wrote a new review.',
    reviewer_write_shop_review: 'Wrote a new review.',
  }

  export default {
    components: { FeedCardShopHeader, FeedCardProductHeader, FeedCardReviewerHeader },
    props: ['feedEvent'],
    computed: {
      headerDescription() {
        return FEED_EVENT_TYPE_DESCRIPTIONS[this.feedEvent.event_type] || ''
      },
      displayBody(){
        switch(this.feedEvent.event_type){
          case 'shop_publish_shop_review':
          case 'prod_have_published_review':
          case 'reviewer_write_product_review':
          case 'reviewer_write_shop_review':
            return ReviewBody
        }
      },
      bodyProps(){
        switch(this.displayBody){
          default:
            return { review: this.feedEvent.event_item, shouldHideReviewer: this.feedEvent.event_owner.class === 'Reviewer' } //  props For old Review component
        }
      },
      displayFooter(){
        const type = this.feedEvent.event_type
        switch(type){
          case 'prod_have_published_review':
          case 'shop_publish_shop_review':
          case 'reviewer_write_product_review':
          case 'reviewer_write_shop_review':
            if (this.feedEvent.event_item.product_in_store) {
              return DoubleButtonFooter
            }
        }
      },
      footerProps(){
        const clickFunc = this.trackExternalClick.bind(this, {
          href: this.feedEvent.event_item.product_url_with_utm,
          shopId: this.feedEvent.event_item.encoded_shop_id,
          productId: this.feedEvent.event_item.encoded_product_id,
        })
        const result = {
          events: {
            click: clickFunc
          }
        }
        switch(this.feedEvent.event_type){ // cases: double & single buttons but with different props
          case 'reviewer_write_product_review':
            return {
              props: {
                divider: true,
                primaryButtonText: 'View Product',
                primaryButtonHref: this.feedEvent.event_item.product_url_with_utm,
                secondaryButtonText: 'View All Reviews',
                secondaryButtonHref: '/reviews/' + this.feedEvent.event_item.shop_slug + '/products/' + this.feedEvent.event_owner.product.product_handle
              },
              ...result
            }
          case 'prod_have_published_review':
            return  {
              props: {
                divider: true,
                primaryButtonText: 'Shop Product',
                primaryButtonHref: this.feedEvent.event_item.product_url_with_utm,
                secondaryButtonText: 'Read More Reviews',
                secondaryButtonHref: '/reviews/' + this.feedEvent.event_item.shop_slug + '/products/' + this.feedEvent.event_owner.product_handle
              },
              ...result
            }
          case 'shop_publish_shop_review':
          case 'reviewer_write_shop_review':
            return {
              props: {
                divider: true,
                primaryButtonText: 'View Shop',
                primaryButtonHref: this.feedEvent.event_item.product_url_with_utm,
                secondaryButtonText: 'View All Reviews',
                secondaryButtonHref: '/reviews/' + this.feedEvent.event_item.shop_slug
              },
              ...result
            }
        }
      },
    },
    methods: {
      trackExternalClick({href, shopId, productId}) {
        this.$store.dispatch('SharedClickTracking/trackExternalClick', {href, shopId, productId})
      },
    },
  }
</script>
