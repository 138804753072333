<template>
  <LoadingSpinner v-if='!doneLoadProfile'/>
  <PagePublic v-else-if="profileData.encoded_id"/>
  <PageNotFound v-else/>
</template>
<script>
import LoadingSpinner from 'rs/components/common/loading_spinner';
import PageNotFound from 'rs/components/common/page_not_found';
import PagePublic from 'rs/components/pages/public_profile/main'
const storeName = 'PublicProfile'

export default {
  components: {PagePublic, LoadingSpinner, PageNotFound},
  computed: {
    profileData() {
      return this.$store.getters[storeName + '/profileData'];
    },
    doneLoadProfile() {
      return this.$store.getters[storeName + '/doneLoadProfile'];
    },
  },
  created() {
    this.$store.dispatch(storeName + '/initPage', this.$route.params.slug);
  }
}
</script>
<style scoped>
.loading-spinner {
  margin: 144px auto;
  width: 72px;
  height: 72px;
}
</style>
