<template>
  <section class="profile-card-thumbnails">
    <div
        v-for="item in media.slice(0,visibleCount)" v-bind:key="item.id"
        @click="() => openGallery(item.id, item.review_uuid)"
    >
      <LazyImage
          v-if="item.dataType === 'picture'"
          class="profile-card-thumbnails__child"
          :alt="'profile card thumbnail picture'"
          :urls='{ normal: item.compact, retina: item.compact }' />

      <div v-else class="profile-card__transparent-layer">
        <Video
            class="profile-card-thumbnails__child"
            :className="['media-player__thumbnail']"
            :dataType="item.dataType"
            :mediaPlatformName="item.media_platform_name"
            :mediaPlatformUrl="item.media_platform_url"
            :source="item.source"
            :videoId="item.videoId"
            :option="null" />
      </div>
    </div>
    <div v-if="hasMore" class="profile-card-thumbnails__child-has-more d-flex-center" @click="() => openGallery(null)">
      <div>See</div>
      <div>more</div>
    </div>
  </section>
</template>
<script>
  import LazyImage from 'rs/components/common/lazy_image'
  import Video from 'rs/components/common/review_video'

  export default {
    components: { LazyImage, Video },
    props: {
      media: {type: Array, required: true},
      openGallery: {type: Function, required: true},
      visibleCount: {type: Number, default: 7},
    },
    computed: {
      hasMore: function() {
        return this.media.length > this.visibleCount
      },
    }
  }
</script>
<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .profile-card-thumbnails {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 6px;
    width: 100%;
    height: 100%;
  }
  .profile-card-thumbnails__child {
    cursor: pointer;
    width: 72px;
    height: 72px;
    object-fit: cover;
    border: none;
    border-radius: 5px;
  }
  .profile-card__transparent-layer {
    cursor: pointer;
  }
  .profile-card-thumbnails__child-has-more {
    margin-left: 14px;
    font-size: 14px;
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: flex-start;
    color: $secondaryFontColor;
    cursor: pointer;
    text-decoration: underline;
  }
</style>
<style lang="scss">
.media-player__thumbnail > div:first-child {
  height: 100%;
  padding: 0 !important;
  iframe {
    border-radius: 5px;
  }
}
</style>
