<template>
  <div class="trust-block">
    <div class="trust-block__content-left">
      <h3 v-if="blockIndex < 2" class="trust-block__sub-heading">{{heading}}</h3>
      <h2 v-else class="trust-block__content--heading" v-html="heading"></h2>
        <p class="trust-block__content--paragraph" v-html="content"></p>
        <router-link v-if="blockUrl"
              :class="blockUrlClass"
                :is="urlVuePath ? 'router-link' : 'a'"
                :to="blockUrl"
                :href="blockUrl"
      >
        {{ urlText }}
      </router-link>
        <TrustAccordion v-if="heading == 'Trust Scores'" />
    </div>
    <div class="trust-block__content-right" v-bind:class="fixedImage">
      <img class="trust-block-card__image" :src="imageUrl" alt="trust image"/>
    </div>
  </div>
</template>

<script>
import TrustAccordion from 'rs/components/pages/trust/_trust_accordion'

export default {
  components: {TrustAccordion},
  props: ['heading', 'content', 'imageUrl', 'urlText', 'blockUrl', 'blockUrlClass', 'blockIndex', 'urlVuePath'],
  computed: {
    fixedImage: function() {
      return this.heading == 'Trust Scores' ? 'trust-scores-img' : '';
    }
  }
}

</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';

  .trust-block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    width: 100vw;
    margin: 0 auto;
    padding: 0px 16px;
    @include respond-to(notdesktop) {
      flex-direction: column-reverse;
      gap: 28px;
      padding: 30px 20px;
      max-width: 100%;
    }
    .btn {
      min-width: 220px;
      font-size: 16px;
    }
    a:not(.btn) {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .btn.btn-transparent {
    color: white ;
    border: 2px solid;
  }
  .btn.btn-transparent:hover {
    color: $brandColor;
    background: white;
  }

  .trust-block__content--heading  {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 21px;
    @include respond-to(mobile) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  .trust-block__sub-heading  {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 21px;
    @include respond-to(mobile) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .trust-block__content--paragraph {
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  img.trust-block-card__image {
    display: block;
    width: 100%;
    height: auto;
    max-width: 400px;
    padding: 40px 0px;
  }

  .trust-block__content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .trust-block__content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    max-width: 90%;
    margin: 10px auto;
    @include respond-to(notdesktop) {
      flex-direction: column-reverse;
      gap: 28px;
      margin-bottom: 60px;
      padding-left: 32px;
      max-width: 100%;
    }
  }

  .trust-block__content-left, .trust-block__content-right {
    max-width: 500px;
    position: relative;
  }

  .trust-blocks__section__content:first-child .trust-block {
    .trust-block__content-left {
      max-width: 450px;
    }
  }


  .trust-blocks__section__content:last-child .trust-block {
    @include respond-to(notmobile) {
      .trust-block__content-left {
        max-width: 45%;
      }
      .trust-block__content-right {
        max-width: 300px;
      }
    }
  }


  .trust-block__cards-wrapper {
    margin-top: 12px;
    overflow: hidden;
    position: relative;
    width: 100%;
    -webkit-tap-highlight-color: transparent;

    @include respond-to(notmobile) {
      margin-top: 28px;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      row-gap: 16px;
    }
  }

  .trust-blocks__section__content:last-of-type .trust-block .trust-block__content-right{
      @include respond-to(notdesktop) {
        width: 100%;
        .trust-block-card__image {
          max-width: 200px;
          padding: 20px 0px;
        }
      }
    }

  .trust-block__content-right.trust-scores-img {
    @include respond-to(notmobile) {
      height: 500px;
      display: flex;
      align-self: baseline;
    }
    @include respond-to(notdesktop) {
      margin: 0 auto;
    }
  }

</style>
