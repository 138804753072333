<!-- Custom slider component using vue built-in Transition Group:
  - For sliding items, pass childComponent as component from parent.
  - For navigation, there is a default navigation element.
  - Styles and effects can be overridden from parent component by selecting the correct nested selector.
  - Can replace arrow icons with your choice by using slots
  - Please don't change in here as it could break other components.
  - Still in improvement, TODO:
    - Indicator (dot)
    - Need help with transition effect
-->
<template>
  <section class="custom-slider">
    <div v-if='willShowNavigation' class="custom-slider__navigation-button custom-slider__navigation-button-backward">
      <button @click="updateCursor(-1)" :disabled='willDisableBackButton'>
        <slot name="backButton"><span class="material-icons custom-slider__navigation-button-icon">arrow_back_ios</span></slot>
      </button>
    </div>
    <div v-if='willShowNavigation' class="custom-slider__navigation-button custom-slider__navigation-button-forward">
      <button @click="updateCursor(1)" :disabled='willDisableForwardButton'>
        <slot name="nextButton"><span class="material-icons custom-slider__navigation-button-icon">arrow_forward_ios</span></slot>
      </button>
    </div>

    <transition-group tag="div" class="custom-slider__visible-section" :name="slideBack ? 'custom-slider__slideback' : 'custom-slider__slide'" ref="slideList">
      <div v-for="idx in indexes.slice(0, visibleCount)" :key="idx" class="custom-slider__visible-single-item">
        <component :is="childComponent" :item='items[idx]' :itemProps="itemProps" class="custom-slider__visible-single-item-wrapper" />
        <slot name="caption">
          <div v-if="caption" class="custom-slider__caption">{{caption}}</div>
        </slot>
      </div>
    </transition-group>
  </section>
</template>

<script>
  export default {
    props: {
      items: { default: [] },
      itemProps: { type: Object, default: function() { return {}} },
      visibleCount: { default: 1 },
      itemPerSlide: { default: 1 },
      useCustomItemSlot: { default: false },
      useCustomArrow: { default: false },
      infiniteSlide: { default: true },
      autoScroll: { default: false },
      hideNavigation: { default: false },
      childComponent: { default: null },
      navigationComponent: { default: null },
      caption: { default: "" },
      swipeAble: { default: true },
    },
    data(){
      return {
        cursor: 0,
        slideBack: false,
        autoSlidePaused: false,
        indexes: this.items.map((el,idx) => idx),
        prevTouchX: null,
      }
    },
    computed: {
      willShowNavigation(){
        return  !this.hideNavigation
      },
      willDisableBackButton() {
        if (!this.useCustomArrow) {
          return !this.infiniteSlide && this.cursor === 0;
        }
        return false // temp, need improvement for custom navigation buttons
      },
      willDisableForwardButton() {
        if (!this.useCustomArrow) {
          return !this.infiniteSlide && this.items.length -  this.cursor <= this.visibleCount
        }
        return false // temp, need improvement for custom navigation buttons
      }
    },
    methods: {
      updateCursor(step) {
        const perSlide = this.itemPerSlide
        let cursor = this.cursor
        if (step > 0) { // forward
          cursor += perSlide
          this.slideBack = false;
        } else { // backward
          cursor -= perSlide
          this.slideBack = true;
        }
        this.updateIndexes(cursor)
      },

      updateIndexes(cursor){
        const remainder = cursor % this.items.length
        const newCur = remainder < 0 ? remainder + this.items.length : remainder;
        const newIndexes = []
        for (let i = newCur; i < this.items.length  + newCur; i++) {
          newIndexes.push(i < 0 ? this.items.length + i : i % this.items.length)
        };
        this.cursor = newCur
        this.indexes = [...newIndexes];
        this.$emit('afterChange', { currentIndex: newCur, currentShowingIndexes: newIndexes.slice(0, this.visibleCount) })  // callback for custom navigation component to handle disable button
      },

      autoScrollHandler() {
        this.intervalid1 = setInterval(function(){
          if (!this.autoSlidePaused) {
            this.updateCursor(1)
          }
        }.bind(this), 3000);
      },

      slideListHoverHandler(event) {
        if (event.type === 'mouseenter' && !this.autoSlidePaused) {
          this.autoSlidePaused = true;
        } else if (event.type === 'mouseleave' && this.autoSlidePaused) {
          this.autoSlidePaused = false;
        }
      },
      touchSwipe(e){
        if (e.type === 'touchstart') {
          this.prevTouchX = e.changedTouches[0].screenX;
        } else {
          const marginToSwipeInPixel = 40
          if (this.prevTouchX > e.changedTouches[0].screenX + marginToSwipeInPixel) {
           this.updateCursor(1)
          } else if (this.prevTouchX + marginToSwipeInPixel < e.changedTouches[0].screenX) {
           this.updateCursor(-1)
          }
        }
      },
    },
    mounted(){
      if (this.swipeAble) {
        this.$refs.slideList.$el.addEventListener('touchstart', this.touchSwipe, true);
        this.$refs.slideList.$el.addEventListener('touchend', this.touchSwipe, true);
      }
      this.$refs.slideList.$el.addEventListener('mouseenter', this.slideListHoverHandler);
      this.$refs.slideList.$el.addEventListener('mouseleave', this.slideListHoverHandler);

      if (this.autoScroll) {
        this.autoScrollHandler();
      }
      this.$emit('afterChange', { currentIndex: this.cursor, currentShowingIndexes: this.indexes.slice(0, this.visibleCount) })  // callback for custom navigation component to handle disable button
    },
    watch: {
      autoScroll(v) {
        if (v) {
          this.autoScrollHandler();
        } else {
          clearInterval(this.intervalid1)
        }
      },
    },
    beforeDestroy() {
      clearInterval(this.intervalid1)
      if (this.swipeAble) {
        this.$refs.slideList.$el.removeEventListener('touchstart', this.touchSwipe, true);
        this.$refs.slideList.$el.removeEventListener('touchend', this.touchSwipe, true);
      }
      this.$refs.slideList.$el.removeEventListener('mouseenter', this.slideListHoverHandler)
      this.$refs.slideList.$el.removeEventListener('mouseleave', this.slideListHoverHandler)
    }
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .custom-slider {
    display: block;
    position: relative;
  }
  .custom-slider__visible-section {
    overflow: hidden;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .custom-slider__navigation-button {
    height: 100%;
    -webkit-tap-highlight-color: transparent;  //remove hightlight when focus click on mobile
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 60px;
    background: transparent;
    padding: 0;
    margin: 0;
  }
  .custom-slider__navigation-button button {
    padding: 0;
    cursor: pointer;
    border: none;
    background: transparent;
    color: $brandColor;
    &:disabled {
      color: gray;
      cursor: not-allowed;
    }
  }

  .custom-slider__navigation-button-backward {
    left: 0;
    top: 0;
    justify-content: flex-start;
  }
  .custom-slider__navigation-button-forward {
    right: 0;
    top: 0;
    justify-content: flex-end;
  }

  .custom-slider__visible-single-item {
    height: 100%;
    width: auto;
    margin: 0 10px;
    transition: all .25s ease;
  }

  .custom-slider__visible-single-item-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // Below are styles for default effect
  // Don't change them here, override from parent component instead
  .custom-slider__slide-enter-active {
    opacity: 0;
    transform: translate(100%, 0);
    transition: all 0.1s linear;
  }

  .custom-slider__slide-leave-active {
    opacity: 0;
    transform: translate(-100%, 0);
    transition: all 0.2s linear;
  }

  .custom-slider__slide-enter {
    opacity: 0;
    transform: translate(100%, 0);
    transition: all 0.1s linear;
  }

  .custom-slider__slide-leave-to {
    opacity: 0;
    transform: translate(-100%, 0);
    transition: all 0.1s linear;
  }

  .custom-slider__slideback-enter-active {
    opacity: 0;
    transform: translate(-100%, 0);
    transition: all 0.1s linear;
  }

  .custom-slider__slideback-leave-active  {
    opacity: 0;
    transform: translate(100%, 0);
    transition: all 0.1s linear;
  }

  .custom-slider__slideback-enter {
    opacity: 0;
    transform: translate(-100%, 0);
    transition: all 0.1s linear;
  }

  .custom-slider__slideback-leave-to {
    opacity: 0;
    transform: translate(100%, 0);
    transition: all 0.1s linear;
  }

</style>
