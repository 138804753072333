import {setState} from 'rs/store/store_utils/shared'

const defaultState = {
  currentStoreName: '',
  shouldScrollToReviews: false,
}
const state = {...defaultState}

const getters = {
  storeName: state => state.currentStoreName,
  shouldScrollToReviews: state => state.shouldScrollToReviews,
  reviewerData: (state, getters, rootState, rootGetters) => {
    return rootGetters[getters.storeName + '/profileData']
  }
}

const mutations = {
  SET_STORE_NAME: (state, storeName) => {
    state.currentStoreName = storeName
  },
  setState(state, [name, value]) {
    state[name] = value
  },
}
const actions = {
  setStoreName({commit}, storeName) {
    commit('SET_STORE_NAME', storeName)
  },
  shouldScrollToReviews(context, bool) {
    setState(context.commit, 'shouldScrollToReviews')(bool)
  }
}

export default {state, getters, mutations, actions, namespaced: true}
