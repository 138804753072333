import axios from 'shared/utils/axios';
import {getLocale} from 'shared/utils/local_storage';
import router from 'rs/plugins/router'

export const CAROUSEL_TYPES = {
  product: '/reviews/carousels.json?q=product',
  category: '/reviews/carousels.json?q=category',
  collection: '/reviews/carousels.json?q=collection',
  featured_review: '/reviews/carousels.json?q=featured_review',
}
export function fetchHomeDataWithCountry(country) {
  return async function(url) {
    try {
      const res = await axios.get(url, {params: {country: country, redesign: router.currentRoute.query.redesign} });
      return res.data;
    } catch {
      return null;
    }
  }
}
// add this function to memoize getLocale
export async function fetchHomeData(url) {
  return await fetchHomeDataWithCountry(getLocale('country') || 'US')(url)
}
