import { render, staticRenderFns } from "./_file_message.vue?vue&type=template&id=4c370752&scoped=true&"
import script from "./_file_message.vue?vue&type=script&lang=js&"
export * from "./_file_message.vue?vue&type=script&lang=js&"
import style0 from "./_file_message.vue?vue&type=style&index=0&id=4c370752&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c370752",
  null
  
)

export default component.exports