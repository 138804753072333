import router from 'rs/plugins/router'
import axios from 'shared/utils/axios'

const _extractFolloweeIdsFromFeedEvents = (feedEvents) => {
  const newFollowees = feedEvents.map((feedEvent) => {
    return { encoded_id: feedEvent.event_owner.encoded_id, class: feedEvent.event_owner.class };
  })
  const followeeIdsToCheck = newFollowees.reduce((result, newFollowee) => {
    result[newFollowee.class] = result[newFollowee.class] || []
    result[newFollowee.class].push(newFollowee.encoded_id)
    return result
  }, {})
  return followeeIdsToCheck
}

const _castBooleanStr = (boolStr) => {
  // This assumes default value of the boolean as `true`
  return (typeof boolStr == 'boolean') ? boolStr : boolStr != 'false'
}


const state = {
  instantResults: { shop_suggestions: [], product_suggestions: [] },
  homeMetrics: {
    total_verified_reviews: '...',
    shop_metric: '...',
    line_item_metric: '...',
  },
  feedEvents: [],
  isLoadingFeedEvents: true,
  hasMoreFeedEvents: false,
  currentReviewerUnreviewedProducts: [],
}

const mutations = {
  updateHomeMetrics: (state, newHomeMetrics) => state.homeMetrics = newHomeMetrics,
  clearFeedEvents: (state) => { state.feedEvents = [] },
  appendFeedEvents: (state, payload) => {
    state.feedEvents = state.feedEvents.concat(payload.feed_events)
    state.hasMoreFeedEvents = (payload.per_page && payload.feed_events.length >= payload.per_page)
  },
  clearInstantResults: (state) => state.instantResults = { shop_suggestions: [], product_suggestions: [] },
  setInstantResults: (state, payload) => state.instantResults = payload,
  setIsLoadingFeedEvents: (state, isLoading) => state.isLoadingFeedEvents = isLoading,
  setcurrentReviewerUnreviewedProducts: (state, payload) => state.currentReviewerUnreviewedProducts = payload,
}

const actions = {
  makeInstantSearch (context, payload) {
    axios.get('/instant_search', {
      params: payload,
    }).then(function (response) {
      context.commit('setInstantResults', response.data)
    })
  },
  fetchFeedEvents (context, payload) {
    context.commit('setIsLoadingFeedEvents', true)

    const params = {
      max_created_at: payload.maxCreatedAt,
      user_events: _castBooleanStr(router.currentRoute.query.user_events),
      store_events: _castBooleanStr(router.currentRoute.query.store_events),
      product_events: _castBooleanStr(router.currentRoute.query.product_events),
    }
    axios.get('/feed_events.json', {
      params: params,
    }).then(function (response) {
      context.commit('appendFeedEvents', response.data)
      context.commit('setIsLoadingFeedEvents', false)
      const followeeIdsToCheck = _extractFolloweeIdsFromFeedEvents(response.data.feed_events)
      context.dispatch('SharedFollow/fetchFolloweeIds', followeeIdsToCheck, { root: true })
      context.commit('setcurrentReviewerUnreviewedProducts', response.data.current_reviewer_unreviewed_products)
    })
  },
}

export default {
  state: state,
  mutations: mutations,
  actions: actions,

  namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
}
