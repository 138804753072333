<template>
  <MatchMedia v-slot="{ mobile, tablet, desktop}">
    <div :class="{'main-header-wrapper-with-search-nav': bShouldRenderSearchNav}">
      <div class="has-100vw main-header" ref="mainHeader" id="mainHeader">
        <div class="main-header__container new-has-max-width">
          <router-link to="/reviews" class="main-header__brand">
            <img class="main-header__brand-logo" :src="require('images/logo-judgeme.svg')" alt="Judge.me"/>
            <div class="main-header__brand-text-wrapper">
              <span class="main-header__brand-text">Reviews</span>
            </div>
          </router-link>
          <template v-if="desktop">
            <div v-if='bShouldRenderSearchNav' class="main-header__search">
              <SubHeader :divider="false" :showCategory="bShouldShowCategoryOnSearchNav" />
            </div>
            <div class="main-header__right-section">
              <WriteReviewButtons />
              <div ref='dropdownMenu' class="main-header__menu-button">
                <div class="main-header__menu-button"  @click="toggleNavMenu">
                  <div class="main-header__menu-button-dots" v-for="n in 9"></div>
                </div>
                <Transition name="menu-dropdown">
                  <div class="main-header__dropdown-menu-wrapper"
                       :class="{'main-header__dropdown-menu-wrapper--with-banner': bShouldRenderBannerNav}"
                       v-show="bDropdownOpen"
                       v-closable="{
                   exclude: ['dropdownMenu', 'currencyMenu'],
                   handler: 'closeNavMenu'
                   }">
                    <div @click.stop="closeNavMenu" v-for="menu in aDesktopDropdownMenu"
                         class="main-header__dropdown-menu-item">
                      <router-link :is="menu.nonVue ? 'a' : 'router-link'" :to="menu.path" :href="menu.path">{{ menu.text }}</router-link>
                    </div>

                    <div ref='currencyMenu' class="main-header__dropdown-menu-currency">
                      <div>Show prices in</div>
                      <CurrencyDropdown showFlag @afterChange="closeNavMenu"/>
                    </div>

                  </div>
                </Transition>
              </div>
              <div v-if="reviewer" class="main-header__user" @click="toggleUserMenu" ref='userDropdownMenu'>
                <Avatar class="main-header__user-profile__avatar"
                        :avatar_image_url="reviewer.avatar_image_url"
                        :reviewer_initial='reviewer.name[0].toUpperCase()'
                />
                <Transition name="menu-dropdown">
                  <div class="main-header__user-dropdown-menu-wrapper"
                       :class="{'main-header__user-dropdown-menu-wrapper--with-banner': bShouldRenderBannerNav}"
                       v-show="bUserDropdownOpen"
                       v-closable="{
                   exclude: ['userDropdownMenu'],
                   handler: 'closeUserMenu'
                   }">
                    <div @click.stop="closeUserMenu" v-for="menu in aDesktopUserMenu"
                         class="main-header__dropdown-menu-item">
                      <router-link :is="menu.nonVue ? 'a' : 'router-link'" :to="menu.path" :href="menu.path">{{ menu.text }}</router-link>
                    </div>
                  </div>
                </Transition>
              </div>
              <a v-else class="btn pf-secondary-button main-header__user-sign-in" href="/login">Sign In</a>
            </div>
          </template>
          <MobileMenu class="main-header__mobile-menu-button"
                      v-else
                      :menuItems="aMobileDropdownMenu"
                      :userMenuItems="aMobileUserMenu" @expandMenu="expandUserMenu"/>
        </div>
        <template v-if="desktop">
          <Meganav v-if="bShouldRenderBannerNav"/>
        </template>
        <template v-else>
          <div v-if="bShouldRenderSearchNav" id="searchHeader">
            <SubHeader ref="subHeaderWrapper" :setWrapperHeight="setWrapperHeight"/>
          </div>
        </template>
      </div>
    </div>
  </MatchMedia>
</template>

<script>
import MobileMenu from 'rs/components/layouts/menus/animations/slide';
import Meganav from "rs/components/layouts/meganav/main";
import SubHeader from 'rs/components/layouts/sub_header'
import Avatar from 'rs/components/common/user_avatar';
import CurrencyDropdown from 'rs/components/common/currency_dropdown';
import WriteReviewButtons from 'rs/components/layouts/redesign/_write_review_buttons';
import { MatchMedia } from "shared/vue_media_query";
import { isTruthy, chainChecks } from 'shared/utils/checker'
import {mapState} from 'vuex'

const DEFAULT_MEDIA_OPTIONS = {mobile: true, desktop: true}
const DEFAULT_OPTIONS = {...DEFAULT_MEDIA_OPTIONS, reviewer: true, guest: true}
const DROPDOWN_MENU_ITEMS = [
  {...DEFAULT_OPTIONS, path: "/reviews",          text: "Home"},
  {...DEFAULT_OPTIONS, path: "/categories",       text: "Product categories"},
  {...DEFAULT_OPTIONS, path: "/store_search",     text: "Find stores"},
  {...DEFAULT_OPTIONS, path: "/people",           text: "Find people"},
  {...DEFAULT_OPTIONS, path: "/write-review",     text: "Write a review", desktop: false, guest: false, redesign: true},
  {...DEFAULT_OPTIONS, path: "/how-it-works",     text: "How it works" },
  {...DEFAULT_OPTIONS, path: "/",                 text: "For Businesses", nonVue: true, desktop: false},
]
const userMenu = [
  {...DEFAULT_MEDIA_OPTIONS, path: "/profile",    text: "My Profile", nonVue: true},
  {...DEFAULT_MEDIA_OPTIONS, path: "/following",  text: "Following"},
  {...DEFAULT_MEDIA_OPTIONS, path: "/feed",       text: "Feed"},
  {...DEFAULT_MEDIA_OPTIONS, path: "/logout",     text: "Sign out", nonVue: true},
]

const allowRedesign = isRedesign => e => isRedesign || !isTruthy('redesign')(e)

export default {
  components: {
    MobileMenu, SubHeader, Avatar, CurrencyDropdown,
    MatchMedia, WriteReviewButtons, Meganav
  },
  data() {
    return {
      bMobileUserMenuExpanded: false,
      bDropdownOpen: false,
      bUserDropdownOpen: false,
    }
  },
  computed: {
    ...mapState("SharedCurrentReviewer", {
      reviewer: state => state.currentReviewer,
    }),
    mediaScreen(){
      return this.$store.getters['Global/screen']
    },
    categoryLinks(){
      return this.$store.getters['Meganav/categoryLinks']
    },
    hPagesWithoutSearch() {
      return {
        [this.$routes.home.name]: true,
        [this.$routes.feed.name]: !window.isReviewerLoggedIn,
      }
    },
    hPagesWithoutBannerNav(){
      return {
        [this.$routes.search.name]: true,
        [this.$routes.categoryIndex.name]: true,
        [this.$routes.categoryShow.name]: true,
      }
    },
    hPagesWithCategory() {
      return {
        'PageSearch': true,
      }
    },
    bShouldShowCategoryOnSearchNav() {
      return !!this.hPagesWithCategory[this.$route.name]
    },
    bShouldRenderBannerNav() {
      return !this.hPagesWithoutBannerNav[this.$route.name] && this.categoryLinks.length > 0
    },
    bShouldRenderSearchNav() {
      return !this.hPagesWithoutSearch[this.$route.name]
    },
    aDesktopDropdownMenu() {
      return DROPDOWN_MENU_ITEMS.filter(e => e.desktop)
    },
    aMobileDropdownMenu() {
      return DROPDOWN_MENU_ITEMS.filter(e => chainChecks(e)(
        isTruthy('mobile'),
        isTruthy(this.reviewer ? 'reviewer' : 'guest'),
        allowRedesign(this.$route.query.redesign)
        ),
      )
    },
    aDesktopUserMenu() {
      return userMenu.filter(e => e.desktop)
    },
    aMobileUserMenu() {
      return this.bMobileUserMenuExpanded ? userMenu.filter(e => e.mobile) : []
    },
  },
  methods: {
    toggleNavMenu() {
      this.bDropdownOpen = !this.bDropdownOpen
    },
    toggleUserMenu() {
      this.bUserDropdownOpen = !this.bUserDropdownOpen
    },
    closeNavMenu() {
      this.bDropdownOpen = false
    },
    closeUserMenu() {
      this.bUserDropdownOpen = false
    },
    expandUserMenu(isExpanded) {
      this.bMobileUserMenuExpanded = isExpanded
    },
    setWrapperHeight(height){
      if (!this.$refs.subHeaderWrapper) return;
      this.$refs.subHeaderWrapper.$el.style.height = height + 'px';
    },
    toggleSearchNavbar(val){
      if (!val) {
        document.querySelector('.rs-app__main').classList.add('rs-app__main--no-search')
      } else {
        document.querySelector('.rs-app__main').classList.remove('rs-app__main--no-search')
      }
    }
  },
  watch: {
    bShouldRenderBannerNav(val){
      this.$emit('toggleDynamicBanner', val)
    },
    bShouldRenderSearchNav(val){
      if (this.mediaScreen.desktop) return;
      this.toggleSearchNavbar(val)
    },
  },
  mounted(){
    this.toggleSearchNavbar(this.bShouldRenderSearchNav);
    this.$emit('toggleDynamicBanner', this.bShouldRenderBannerNav)
  }
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';


.main-header-wrapper-with-search-nav {
  margin-bottom: 12px; // header box shadow
}

.main-header {
  position: fixed;
  top: 0;
  background: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  z-index: 2; // has-100vw  has z-index: 2, and will override vue elements, use this z-index to override everything else;
}


.main-header__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 0 12px;
  height: $marketplaceHeaderHeightMobile;
  @include respond-to(notmobile) {
    height: $marketplaceHeaderHeightDesktop;
  }
}

.main-header__brand {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
}

.main-header__brand-logo {
  height: -webkit-fill-available;
  @include respond-to(mobile) {
    max-height: 42px;
  }
}

.main-header__brand-text-wrapper {
  background: linear-gradient(180deg, #339999 0%, #2D8396 100%);
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 2px 6px;
}

.main-header__brand-text {
  color: white;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}

.main-header__search {
  max-width: 574px;
  width: 100%;
}

.main-header__mobile-menu-button {
  position: relative;
}

.main-header__right-section {
  position: relative;
  display: flex;
  align-items: center;
  gap: 28px;
}

.main-header__menu-button {
  position: relative;
  width: 16px;
  height: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  cursor: pointer;
}

.main-header__menu-button-dots {
  flex-grow: 0;
  flex-shrink: 0;
  height: 4px;
  width: 4px;
  background-color: $newBrandColor;
}

.main-header__dropdown-menu-wrapper {
  position: absolute;
  background: white;
  width: 270px;
  translate: calc(-100% + 16px);
  top: 62px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}
.main-header__dropdown-menu-wrapper--with-banner {
  top: 42px;
  z-index: 1;
}

.main-header__user-dropdown-menu-wrapper {
  position: absolute;
  background: white;
  width: 270px;
  translate: calc(-100% + 50px);
  top: 80px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}
.main-header__user-dropdown-menu-wrapper--with-banner {
  top: 60px;
  z-index: 1;
}

.main-header__dropdown-menu-item {
  border-bottom: 1px solid $feedCardDividerColor;
  &:hover {
    background-color: $backgroundColor;
  }
  &:first-child {
    border-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
.main-header__dropdown-menu-currency {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;
  height: auto;
  width: 100%;
  color: $primaryFontColor;
  font-size: 14px;
  font-weight: 400;
  gap: 12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.main-header__dropdown-menu-item > * {
  width: 100%;
  height: 72px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: black;
  text-decoration: none;
}

.main-header__user {
  position: relative;
}

.main-header__user-profile__avatar {
  height: 48px;
  width: 48px;
  line-height: 48px;
  border-radius: 50%;
  cursor: pointer;
  border: solid 2px transparent;
  &:hover {
    border: solid 2px $newBrandColor;
    box-shadow: 0px 1px 10px 1px rgba(51, 153, 153, 0.4);
  }
}

.main-header__user-sign-in {
  width: 88px;
  background: $newBrandColor;
  color: white;
  padding: 10px 4px;
}

.menu-dropdown-enter-active,
.menu-dropdown-leave-active {
  transition: all 0.2s ease-out;
}

.menu-dropdown-enter-from,
.menu-dropdown-leave-to {
  opacity: 0;
}

</style>

