<template>
  <CardWrapper title="Address" :showIcon="shopData.confirmed_addresses" v-if="addresses.length > 0"
               icon="badge" showTooltip :tooltipProps="tooltipProps">
    <template v-slot:body>
      <div class="store-address">
        <AddressDetail v-for="(address,idx) in addresses.slice(0,2)"
                       :address="normalizeAddress(address)" :key="idx"
                       :showTitle="hasBillingAddress"/>
      </div>
      <div v-if="addresses.length > 2" class="store-address__button" @click="toggleAddressModal(true)">
        View All Address
      </div>
      <AddressModal v-if="addressModal" :addresses="addresses" @close="toggleAddressModal(false)"/>
    </template>
  </CardWrapper>
</template>
<script>
import CardWrapper from 'rs/components/pages/shop/tabs/_card_wrapper'
import AddressModal from 'rs/components/pages/shop/others/_address_modal';
import AddressDetail from 'rs/components/pages/shop/others/_address_detail';
import {storeName} from "rs/store/store_utils/shared";
import {normalizeAddress} from 'rs/components/pages/shop/helpers.js';


export default {
  components: {CardWrapper, AddressDetail, AddressModal},
  computed: {
    storeName() {
      return storeName(this.$route)
    },
    shopData() {
      return this.$store.getters[this.storeName + '/shopData']
    },
    addresses() {
      return this.shopData.addresses || []
    },
    hasBillingAddress() {
      return this.addresses.length > 0 && this.addresses[0].billing_address
    },
    tooltipProps() {
      return {
        show: true,
        name: 'address',
        tooltipArg: this.shopData.confirmed_addresses,
      }
    }
  },
  data() {
    return {
      addressModal: false,
    }
  },
  methods: {
    toggleAddressModal(bool) {
      this.addressModal = bool
    },
    normalizeAddress,
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.store-address {
  display: flex;
  @include respond-to(mobile) {
    flex-direction: column;
  }
  gap: 16px;
}

.store-address__button {
  color: $newBrandColor;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    opacity: 0.85;
  }
}
</style>
