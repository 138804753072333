import { render, staticRenderFns } from "./reviews_list.vue?vue&type=template&id=60d82875&scoped=true&"
import script from "./reviews_list.vue?vue&type=script&lang=js&"
export * from "./reviews_list.vue?vue&type=script&lang=js&"
import style0 from "./reviews_list.vue?vue&type=style&index=0&id=60d82875&prod&lang=scss&scoped=true&"
import style1 from "./reviews_list.vue?vue&type=style&index=1&id=60d82875&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60d82875",
  null
  
)

export default component.exports