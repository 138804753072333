<template>
  <section class="metrics-widget" v-if="widgetData && parseFloat(widgetData.average_rating) > 0">
    <div class="metrics-widget__column">
      <div class="d-flex gap-2 align-end">
        <span class="metrics-widget__rating">{{
            $sharedUtils.roundNumberToStringWithDecimalsSuffix(parseFloat(widgetData.average_rating), 2)
          }}</span>
        <span>/5</span>
        <img class="metrics-widget__star-image" :src="require('images/platform/green_star.svg')"
             alt="review star icon"/>
        <TooltipPopover class="align-self-center" :name="tooltipsData.averageRating.name" />
      </div>

      <div v-if="widgetData.number_of_reviews > 0" class="metrics-widget__description">
        Based on
        <span class="metrics-widget__description-link" @click="navigateToReviews">
          {{ $sharedUtils.pluralizeWithSeparator(widgetData.number_of_reviews, reviewCountText) }}
        </span>
        <CircleIcon icon='check' class="metrics-widget__check-icon"/>
        <TooltipPopover :name="tooltipsData.authenticity.name" v-if="verifiedText">
          <template #icon><VerifiedBadge class='pointer' :text="verifiedText"/></template>
        </TooltipPopover>
      </div>
    </div>
    <div class="metrics-widget__column">
      <div
        v-for="(ratingDetail,idx) in widgetData.rating_histogram_data" :key="idx"
        class="metrics-widget__rating-detail-line"
        @click="filterByRating(ratingDetail.rating)"
      >
        <div class="metrics-widget__rating-detail-line-title">
          {{ ratingDetail.rating }}
          <img class="metrics-widget__rating-detail-line-star" :src="require('images/platform/green_star.svg')"
               alt="review star icon"/>
        </div>
        <RatingProgressBar
          class="metrics-widget__rating-detail-line-bar"
          :activePercentage="ratingDetail.percentage" :rating="ratingDetail.rating"/>
        <div class="metrics-widget__rating-detail-line-count">
          <AnimatedNumber :endAmount="ratingDetail.frequency"/>
        </div>
      </div>
    </div>

  </section>
</template>
<script>
import RatingProgressBar from 'rs/components/common/rating_progress_bar';
import CircleIcon from 'rs/components/common/icons/custom_circle_icon'
import VerifiedBadge from 'rs/components/common/verified_badge'
import AnimatedNumber from 'rs/components/common/_animated_number'
import TooltipPopover from 'rs/components/common/tooltip_popover';
import tooltipsData from 'shared/constants/tooltips_data.js'
export default {
  components: {RatingProgressBar, CircleIcon, VerifiedBadge, AnimatedNumber, TooltipPopover},
  props: ['storeName'], // can be either Shop or Product on Product page
  computed: {
    tooltipsData: () => tooltipsData,
    widgetData() {
      return this.$store.getters[this.storeName + '/widgetData']
    },
    metrics() {
      return this.$store.getters[this.storeName + '/metrics']
    },
    reviewCountText() {
      return ({
        Shop: 'review',
        Product: 'product review',
      })[this.storeName]
    },
    verifiedText() {
      if (!this.metrics) return null
      if (!this.metrics.authenticity_score) return '0% Verified'
      return this.$sharedUtils.roundNumber(this.metrics.authenticity_score * 100) + '% Verified'
    }
  },
  methods: {
    navigateToReviews() {
      this.$store.dispatch(this.storeName + '/changeTab')
    },
    filterByRating(rating) {
      this.navigateToReviews();
      this.$store.dispatch(`${this.storeName}/applyFilter`, {filter_rating: [rating]})
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.metrics-widget {
  font-size: 14px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  @include respond-to(mobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.metrics-widget__column {
  flex: 1;
  align-self: center;
  @include respond-to(mobile) {
    align-self: flex-start;
  }
}

.metrics-widget__rating {
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
}

.metrics-widget__star-image {
  height: 40px;
  margin-left: 8px;
  align-self: center;
}

.metrics-widget__description {
  margin-bottom: 20px;
  display: flex;
  gap: 4px;
  align-items: center;
  line-height: 20px;
}

.metrics-widget__description-link {
  color: $newBrandColor;
  text-decoration: underline;
  cursor: pointer;
}

.metrics-widget__rating-detail-line {
  margin-bottom: 4px;
  width: 100%;
  height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.metrics-widget__rating-detail-line-title {
  line-height: 18px;
  width: 36px;
}

.metrics-widget__rating-detail-line-star {
  height: 12px;
}

.metrics-widget__rating-detail-line-bar {
  height: 14px;
  width: 162px;
  cursor: pointer;
}

.metrics-widget__rating-detail-line-count {
  color: $secondaryFontColor;
  margin-left: 12px;
}

.metrics-widget__check-icon {
  height: 16px;
  width: 16px;
}
</style>
