<template>
  <div>
    <div v-if="divider" class="feed-card__footer-divider"></div>
    <div class="feed-card__footer">
      <ExternalLink
          class='feed-card__footer-link-primary btn btn--brand'
          @click="$emit('click')"
          :href='primaryButtonHref'
          :text='primaryButtonText'
      />
      <router-link
          v-bind:to='secondaryButtonHref'
          class='feed-card__footer-link-secondary'
      > {{ secondaryButtonText }}
      </router-link>
    </div>
  </div>
</template>
<script>
  import ExternalLink from 'rs/components/common/external_link'

  export default {
    components: { ExternalLink },
    props: ['divider', 'primaryButtonText', 'primaryButtonHref', 'secondaryButtonText', 'secondaryButtonHref'],
  }
</script>
<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .feed-card__footer {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 20px;
    justify-content: center;
    align-items: center;
    @include respond-to(notmobile) {
      justify-content: flex-start;
      align-items: center;
    }
  }
  .feed-card__footer-link-primary {
    width: 100%;
    @include respond-to(notmobile) { width: 260px }
  }
  .feed-card__footer-link-secondary {
    font-weight: 400;
    font-size: 16px;
  }
  .feed-card__footer-divider {
    margin: 0 -16px 16px -16px;
    height: 1px;
    background-color: $feedCardDividerColor;
  }

</style>
