<template>
  <div class="youtube-player-container">
    <div v-if="!loaded">
      Loading video
    </div>
    <div v-else-if="loaded && !videoUrl">
      User has no video
    </div>
    <iframe v-else
            :id="'player_'+ videoUrl " type="text/html" class="media-player"
            :src="'https://www.youtube.com/embed/'+ videoUrl + '?enablejsapi=1&showinfo=0&controls=0&autohide=1'"
            border="0" frameborder="0"  allowfullscreen>
    </iframe>
  </div>
</template>
<script>
  import axios from 'axios';

  export default {
    props: ['channel_url'],
    data(){
      return {
        loaded: false,
        videoUrl: "",
      }
    },
    methods:{
      async fetchPlaylist(){
        try {
          const data = await axios.get(`https://www.googleapis.com/youtube/v3/search?key=${window.VUE_CONSTANTS.youtube_key}&channelId=${this.channel_url.split('channel/')[1]}&part=snippet,id&order=date&maxResults=5`);
          return data.data.items[0].id.videoId;
        } catch {
          return '';
        }
      }
    },
    async mounted(){
      this.videoUrl =  await this.fetchPlaylist();
      this.loaded = true;
    }
  }
</script>

<style lang="scss" scoped>
  .youtube-player-container {
    margin-bottom: 16px;
  }
</style>

<style lang="scss">
  @import '~PlatformStyles/abstract/mixins';
  .youtube-player-container {
    iframe {
      width: 100%;
      height: 167px;
      @include respond-to(notmobile) {
        height: 322px;
      }
    }
  }
</style>