<template>
  <LoadingSpinner class="category-page__loading-spinner" v-if="categories.length < 1"/>
  <div v-else class="category-index-page">
    <Heading :title="title" :description="description" />
    <div class="category-index-page__list-wrapper">
      <RootCategory class="category-index-page__category-card" v-for="(category, idx) in categories" :key="idx" :category="category"/>
    </div>
  </div>
</template>

<script>
import Heading from "rs/components/common/heading_section"
import RootCategory from "rs/components/pages/categories/_root_category"
import LoadingSpinner from "rs/components/common/loading_spinner"

import {mapState} from 'vuex'
export default {
  components: { Heading, RootCategory, LoadingSpinner },
  computed: {
    ...mapState('Search', {
      categoriesFromAppInit: state => state.rootCategories,
    }),
    categories(){
      return this.categoriesFromAppInit.slice(1)
    },
    title: () => "Browse all categories",
    description: () => "Explore by category and find the products you want.",
  },
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.category-page__loading-spinner {
  margin: 200px auto 0 auto;
  width: 100px;
  height: 100px;
}
.category-index-page__list-wrapper {
  --category-icon-grid-size-mobile: 90px;
  --category-icon-grid-size-small-mobile: 90px;
  --category-icon-grid-size-desktop: 180px;
  --category-font-size-mobile: 13px;

  margin: 40px 0 60px 0;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;

  @include respond-to(notmobile) {
    row-gap: 32px;
    margin: 48px 0 120px 0;
  }
}

.category-index-page__category-card {
  width: 124px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 49%;
  @include respond-to(mobile) {
    flex: 0 0 45%;
    gap: 8px;
  }

  @include respond-to(tablet) {
    flex: 0 0 30%;
    gap: 12px;
  }

  @include respond-to(desktop) {
    width: 180px;
    flex: 0 0 23%;
    gap: 16px;
  }
}
</style>
