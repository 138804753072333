<template functional>
  <svg v-on="data.listeners" :class="data.class" v-bind="data.attrs" xmlns="http://www.w3.org/2000/svg" width="20"
       height="20" viewBox="0 0 20 20" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM14.4565 7.89261C14.7369 7.61223 14.7369 7.15765 14.4565 6.87727C14.1762 6.5969 13.7216 6.5969 13.4412 6.87727L8.20529 12.1132L6.55911 10.467C6.27874 10.1866 5.82415 10.1866 5.54378 10.467C5.2634 10.7474 5.2634 11.202 5.54378 11.4824L8.20529 14.1439L14.4565 7.89261Z"
          :fill="props.color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#088484',
    }
  }
}
</script>
