"use strict";
/**
 * @file vue-awesome-swiper
 * @module constants
 * @author Surmon <https://github.com/surmon-china>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SWIPER_EVENTS = exports.ComponentPropNames = exports.ComponentEvents = exports.DEFAULT_CLASSES = exports.CoreNames = void 0;
var CoreNames;
(function (CoreNames) {
  CoreNames["SwiperComponent"] = "Swiper";
  CoreNames["SwiperSlideComponent"] = "SwiperSlide";
  CoreNames["SwiperDirective"] = "swiper";
  CoreNames["SwiperInstance"] = "$swiper";
})(CoreNames = exports.CoreNames || (exports.CoreNames = {}));
exports.DEFAULT_CLASSES = Object.freeze({
  containerClass: 'swiper-container',
  wrapperClass: 'swiper-wrapper',
  slideClass: 'swiper-slide'
});
var ComponentEvents;
(function (ComponentEvents) {
  ComponentEvents["Ready"] = "ready";
  ComponentEvents["ClickSlide"] = "clickSlide";
})(ComponentEvents = exports.ComponentEvents || (exports.ComponentEvents = {}));
var ComponentPropNames;
(function (ComponentPropNames) {
  ComponentPropNames["AutoUpdate"] = "autoUpdate";
  ComponentPropNames["AutoDestroy"] = "autoDestroy";
  ComponentPropNames["DeleteInstanceOnDestroy"] = "deleteInstanceOnDestroy";
  ComponentPropNames["CleanupStylesOnDestroy"] = "cleanupStylesOnDestroy";
})(ComponentPropNames = exports.ComponentPropNames || (exports.ComponentPropNames = {}));
// https://swiperjs.com/api/#events
exports.SWIPER_EVENTS = [
  'init',
  'beforeDestroy',
  'slideChange',
  'slideChangeTransitionStart',
  'slideChangeTransitionEnd',
  'slideNextTransitionStart',
  'slideNextTransitionEnd',
  'slidePrevTransitionStart',
  'slidePrevTransitionEnd',
  'transitionStart',
  'transitionEnd',
  'touchStart',
  'touchMove',
  'touchMoveOpposite',
  'sliderMove',
  'touchEnd',
  'click',
  'tap',
  'doubleTap',
  'imagesReady',
  'progress',
  'reachBeginning',
  'reachEnd',
  'fromEdge',
  'setTranslate',
  'setTransition',
  'resize',
  'observerUpdate',
  'beforeLoopFix',
  'loopFix'
];
