<template>
  <section class="tooltip-modal">
      {{text}}
      <div class="tooltip-modal__arrow">
      </div>
  </section>
</template>

<script>
  import debounce from 'lodash/debounce';

  export default {
    props: ['text'],
    methods: {
      handleScroll(event) {
        window.removeEventListener('scroll', this.handleDebouncedScroll);
        this.$emit('close')
      }
    },
    mounted(){
      this.$el.focus();
      this.handleDebouncedScroll = debounce(this.handleScroll, 100);
      window.addEventListener('scroll', this.handleDebouncedScroll);
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleDebouncedScroll);
    }
  }
</script>

<style lang="scss" scoped>
  .tooltip-modal {
    color: #088484;
    border-radius: 5px;
    position: absolute;
    background: #E0F2F2;
    width: 100%;
    padding: 16px 16px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    -webkit-tap-highlight-color: transparent;
    &:focus {
      outline: none;
    }
  }

  .tooltip-modal__arrow {
    background:
        linear-gradient(to top right   ,#E0F2F2 49.5%, transparent 50%) top right,
        linear-gradient(to top left    ,#E0F2F2 49.5%, transparent 50%) top left,
        linear-gradient(to bottom right,#E0F2F2 49.5%, transparent 50%) bottom right,
        linear-gradient(to bottom left ,#E0F2F2 49.5%, transparent 50%) bottom left;
    background-size:50% 50%;
    background-repeat: no-repeat;

    position: absolute;
    z-index: -1;
    width:50px;
    display:inline-block;
    top: calc(100% - 16px);
    left: calc(50% - 25px);
  }

  /* 2*sin(120/2)*h = w
     h = w / (sin(60)*2)
     h = w * 0.57736
  */
  .tooltip-modal__arrow:before {
    content:"";
    display:block;
    padding-top:57.73%;
  }
</style>
