var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MatchMedia',{scopedSlots:_vm._u([{key:"default",fn:function({ mobile, tablet, desktop}){return [_c('div',{class:{'main-header-wrapper-with-search-nav': _vm.bShouldRenderSearchNav}},[_c('div',{ref:"mainHeader",staticClass:"has-100vw main-header",attrs:{"id":"mainHeader"}},[_c('div',{staticClass:"main-header__container new-has-max-width"},[_c('router-link',{staticClass:"main-header__brand",attrs:{"to":"/reviews"}},[_c('img',{staticClass:"main-header__brand-logo",attrs:{"src":require('images/logo-judgeme.svg'),"alt":"Judge.me"}}),_c('div',{staticClass:"main-header__brand-text-wrapper"},[_c('span',{staticClass:"main-header__brand-text"},[_vm._v("Reviews")])])]),(desktop)?[(_vm.bShouldRenderSearchNav)?_c('div',{staticClass:"main-header__search"},[_c('SubHeader',{attrs:{"divider":false,"showCategory":_vm.bShouldShowCategoryOnSearchNav}})],1):_vm._e(),_c('div',{staticClass:"main-header__right-section"},[_c('WriteReviewButtons'),_c('div',{ref:"dropdownMenu",staticClass:"main-header__menu-button"},[_c('div',{staticClass:"main-header__menu-button",on:{"click":_vm.toggleNavMenu}},_vm._l((9),function(n){return _c('div',{staticClass:"main-header__menu-button-dots"})}),0),_c('Transition',{attrs:{"name":"menu-dropdown"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.bDropdownOpen),expression:"bDropdownOpen"},{name:"closable",rawName:"v-closable",value:({
                 exclude: ['dropdownMenu', 'currencyMenu'],
                 handler: 'closeNavMenu'
                 }),expression:"{\n                 exclude: ['dropdownMenu', 'currencyMenu'],\n                 handler: 'closeNavMenu'\n                 }"}],staticClass:"main-header__dropdown-menu-wrapper",class:{'main-header__dropdown-menu-wrapper--with-banner': _vm.bShouldRenderBannerNav}},[_vm._l((_vm.aDesktopDropdownMenu),function(menu){return _c('div',{staticClass:"main-header__dropdown-menu-item",on:{"click":function($event){$event.stopPropagation();return _vm.closeNavMenu.apply(null, arguments)}}},[_c(menu.nonVue ? 'a' : 'router-link',{tag:"router-link",attrs:{"to":menu.path,"href":menu.path}},[_vm._v(_vm._s(menu.text))])],1)}),_c('div',{ref:"currencyMenu",staticClass:"main-header__dropdown-menu-currency"},[_c('div',[_vm._v("Show prices in")]),_c('CurrencyDropdown',{attrs:{"showFlag":""},on:{"afterChange":_vm.closeNavMenu}})],1)],2)])],1),(_vm.reviewer)?_c('div',{ref:"userDropdownMenu",staticClass:"main-header__user",on:{"click":_vm.toggleUserMenu}},[_c('Avatar',{staticClass:"main-header__user-profile__avatar",attrs:{"avatar_image_url":_vm.reviewer.avatar_image_url,"reviewer_initial":_vm.reviewer.name[0].toUpperCase()}}),_c('Transition',{attrs:{"name":"menu-dropdown"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.bUserDropdownOpen),expression:"bUserDropdownOpen"},{name:"closable",rawName:"v-closable",value:({
                 exclude: ['userDropdownMenu'],
                 handler: 'closeUserMenu'
                 }),expression:"{\n                 exclude: ['userDropdownMenu'],\n                 handler: 'closeUserMenu'\n                 }"}],staticClass:"main-header__user-dropdown-menu-wrapper",class:{'main-header__user-dropdown-menu-wrapper--with-banner': _vm.bShouldRenderBannerNav}},_vm._l((_vm.aDesktopUserMenu),function(menu){return _c('div',{staticClass:"main-header__dropdown-menu-item",on:{"click":function($event){$event.stopPropagation();return _vm.closeUserMenu.apply(null, arguments)}}},[_c(menu.nonVue ? 'a' : 'router-link',{tag:"router-link",attrs:{"to":menu.path,"href":menu.path}},[_vm._v(_vm._s(menu.text))])],1)}),0)])],1):_c('a',{staticClass:"btn pf-secondary-button main-header__user-sign-in",attrs:{"href":"/login"}},[_vm._v("Sign In")])],1)]:_c('MobileMenu',{staticClass:"main-header__mobile-menu-button",attrs:{"menuItems":_vm.aMobileDropdownMenu,"userMenuItems":_vm.aMobileUserMenu},on:{"expandMenu":_vm.expandUserMenu}})],2),(desktop)?[(_vm.bShouldRenderBannerNav)?_c('Meganav'):_vm._e()]:[(_vm.bShouldRenderSearchNav)?_c('div',{attrs:{"id":"searchHeader"}},[_c('SubHeader',{ref:"subHeaderWrapper",attrs:{"setWrapperHeight":_vm.setWrapperHeight}})],1):_vm._e()]],2)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }