/* Handle upload for Vue components */

import originalAxios from "axios";
import axios from 'shared/utils/axios';
import {pipe, pipeAsync} from 'shared/utils/js_core';


const IMAGE_MAX_SIZE = 10 * 1024 * 1024 // Byte (10MB)
const VIDEO_MAX_SIZE = 100 * 1024 * 1024 // Byte (100MB)
const presignUrl = fileType => `/api/v1/${fileType}/presigned_data`
const ALLOWED_IMAGES = "image/gif,image/jpeg,image/jpg,image/png,image/webp,"
const ALLOWED_VIDEOS = "video/mov,video/mp4,video/x-m4v,video/*"

export async function getThumbnailForVideo(videoUrl, dimensions = { width: 100, height: 100}) {
  const video = document.createElement("video");
  const canvas = document.createElement("canvas");
  video.style.display = "none";
  canvas.style.display = "none";
  canvas.width = dimensions.width;
  canvas.height = dimensions.height;

  // Trigger video load
  await new Promise((resolve, reject) => {
    video.addEventListener("loadedmetadata", () => {
      // Seek the video to approx. first second (0.01% of the the video)
      video.currentTime = video.duration * 0.01;
    });
    video.addEventListener("seeked", () => resolve());
    video.src = videoUrl;
  });
  // Draw the thumbnail
  const hRatio = canvas.width  / video.videoWidth    ;
  const vRatio =  canvas.height / video.videoHeight  ;
  const ratio  = Math.min ( hRatio, vRatio );
  var centerShift_x = ( canvas.width - video.videoWidth*ratio ) / 2;
  var centerShift_y = ( canvas.height - video.videoHeight*ratio ) / 2;
  canvas
    .getContext("2d")
    .drawImage(video, 0, 0, video.videoWidth, video.videoHeight,
      0, 0, canvas.width, canvas.height);
  const imageUrl = canvas.toDataURL("image/png");
  return imageUrl;
}

export function removeSpecialCharactersFileName (s) {
  const result = s.replace(/[^a-z0-9-_.]/ig, '')
  const items = result.split(".")
  const fileType = items.pop()
  if (items.join().length === 0) {
    return encodeURIComponent(Date.now() + "." + fileType)
  }
  return encodeURIComponent(Date.now()  + '-' + result)
}

export const isImage = file => ALLOWED_IMAGES.split(',').includes(file.type.toLowerCase());
export const isVideo = file => ALLOWED_VIDEOS.split(',').includes(file.type.toLowerCase());
// this will assume input file is either image or video
export const isOverSize = file => file.type.startsWith('image') ? file.size > IMAGE_MAX_SIZE : file.size > VIDEO_MAX_SIZE

// need file to determine the path to send request for presign data
export async function getPresignedData(file, params = {}) {
  let fileTypeUrl
  if (!file) return null;
  if (file.type.startsWith('image')) fileTypeUrl = 'pictures'
  else if (file.type.startsWith('video')) fileTypeUrl = 'videos'
  else return null

  const data = await axios.get(presignUrl(fileTypeUrl), {params})
  return data.data
}

export function prepareFormData(presignedData) {
  return function (file) {
    if (!file || !presignedData || !presignedData.fields) return null;
    const formData = new FormData();
    Object.keys(presignedData.fields).forEach(key => {
      formData.append(key, presignedData.fields[key])
    })
    formData.append('key', presignedData['key_prefix'] + removeSpecialCharactersFileName(file.name))
    formData.append('file', file)
    return [presignedData['url'], formData]
  }
}

export function uploadCloud(onUploadProgress) {
  return function ([url, formData]) {
    return originalAxios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  }
}

export function getFileDataFromXml(xml) {
  const parser = new DOMParser()
  const dom = parser.parseFromString(xml, "text/xml")
  return {
    url: dom.getElementsByTagName("Location")[0].innerHTML,
    key: dom.getElementsByTagName("Key")[0].innerHTML
  }
}

export const mediaUploader = (presignData, callback) => pipeAsync(
  prepareFormData(presignData),
  uploadCloud(callback),
  response => response.data,
  getFileDataFromXml,
)
export const generateImageFromImgEle = element => {
  const img = new Image()
  img.src = element.src
  return img
}
export const ratioOfImage = (file) => {
  const width = file.width
  const height = file.height
  return width / height
}

export const imageRatioFromElement = pipe(generateImageFromImgEle, ratioOfImage)
export const adjustObjectFitForImgEle = el => {
  const imageRatio = imageRatioFromElement(el.target)
  el.target.style.objectFit = imageRatio >= 1 ? "contain" : 'cover'
}
