<template>
  <div class='reviewer-adds-instagram'>
    <div class="reviewer-adds-instagram__post-wrapper">
      <div v-for="(post, index) in instagramPosts" :key="index" class="reviewer-adds-instagram__post">
        <img v-if="post.media_type == 'IMAGE' || post.media_type == 'CAROUSEL_ALBUM'" class="reviewer-adds-instagram__post-image" :src=post.media_url
             v-bind:class="{ 'even-post-count': evenPostCountLayout }">
        <video v-else controls class="reviewer-adds-instagram__post-video" :src=post.media_url :poster=post.thumbnail_url
               v-bind:class="{ 'even-post-count': evenPostCountLayout }">
        </video>
      </div>
    </div>
    <router-link v-bind:to="feedEvent.event_owner.public_slug ? ('/' + feedEvent.event_owner.public_slug) :  `/profile/${feedEvent.event_owner.encoded_id}#profile-insta-section`"
                 class='reviewer-adds-instagram__view-link btn btn--brand' target="_blank">
      View on Profile
    </router-link>
  </div>
</template>

<script>
  export default {
    props: ['feedEvent'],
    data() {
      return {
        instagramPosts: this.feedEvent.event_owner.instagram_posts,
      }
    },

    computed: {
      evenPostCountLayout() {
        return this.instagramPosts ? this.instagramPosts.length % 2 === 0 : false
      },
    },
  }
</script>

<style lang="scss">
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  $small-post-size: 150px;
  $large-post-size: 310px;

  .reviewer-adds-instagram__post-wrapper {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    justify-items: center;
    align-items: center;

    @include respond-to(mobile) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0 5px;
    }
  }
  // Enlarge first image/video on mobile only
  .reviewer-adds-instagram__post > .reviewer-adds-instagram__post-image,
  .reviewer-adds-instagram__post > .reviewer-adds-instagram__post-video {
    @include respond-to(mobile) {
      width: $large-post-size;
      height: $large-post-size;
    }
  }
  // Reduce all but first images/videos on mobile only
  .reviewer-adds-instagram__post:not(:first-child) > .reviewer-adds-instagram__post-image,
  .reviewer-adds-instagram__post:not(:first-child) > .reviewer-adds-instagram__post-video {
    @include respond-to(mobile) {
      width: $small-post-size;
      height: $small-post-size;
    }
  }
  // When post count is even (usually 2 or 4), enlarge last image/video
  .reviewer-adds-instagram__post:last-child > .reviewer-adds-instagram__post-image.even-post-count,
  .reviewer-adds-instagram__post:last-child > .reviewer-adds-instagram__post-video.even-post-count {
    @include respond-to(mobile) {
      width: $large-post-size;
      height: $large-post-size;
    }
  }

  .reviewer-adds-instagram__post-image,
  .reviewer-adds-instagram__post-video {
    width: 110px;
    height: 110px;
    border-radius: 5px;
    object-fit: cover;
  }

  .reviewer-adds-instagram__view-link {
    width: 100%;
    margin-bottom: 16px;
    margin-top: 10px;
    @include respond-to(notmobile) { width: 50%; }
  }
</style>
