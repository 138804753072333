import router from 'rs/plugins/router'
import routes from 'rs/plugins/routes'
import axios from 'shared/utils/axios'
import {omitBy, isNil} from 'lodash'
import {
  didChange,
  sanitizeParams,
  isSearchInShop,
  sanitizeParamsForRequest,
  arrToHashAndPopulateChildren,
  parseFiltersFromQueries,
  categoriesSortFunctionByName,
  getRootCategory,
  isSameFilters,
  SearchCache,
  extractFolloweeIdsSearchResults,
} from 'rs/store/store_utils/search_utils';


let previousSearchRequestParams = {}
const searchCache = new SearchCache({}) // {[allValuesFromQueryObject]: { sha: ..., results: [] } }

const isCategoryRoute = (routeName) => routeName === routes.categoryIndex.name || routeName === routes.categoryShow.name

const defaultFilters = {
  min_rating: 0,
  min_price: null,
  max_price: null,
  min_transparency: 80,
  min_authenticity: 0,
  category_id: null,
  country: "*",
  store_country: "*",
  sort_by: null,
  reviews_count: 0,
  category_search: false,
}
const state = {
  searchParams: {
    q: '',
    page: 1,
    ...defaultFilters,
  },
  savedSearchParams: {},
  savedSelectedStack: [],
  previousCategory: null,
  searchResults: {},
  pageResults: {}, // { sha: ..., results: [] }
  tempSelectedCategoryId: null,

  isLoadingSearchResults: true,
  isNewSearch: true,
  didTrackIndexGA: false,
  shippingCountries: [],
  homeMetrics: [],

  // for filtering categories
  categories: {},  // cats map from search results
  categoriesLevelOneIDs: [],  // level 1 ids from search results
  rootCategories: [{id: null, name: "All Categories"}], // [{}] all level 1 cats
  genderCategory: null, // special category (level 1)
  selectedStack: [],
  navbarSelectedCategoryId: null,

  defaultFilters: {...defaultFilters},
  //for categories desktop
  showChildrenFor: null,
}

const getters = {
  currency(state, getters, rootState, rootGetters) {
    return rootGetters['SharedCurrentReviewer/currency']
  },
  currentPageResults(state) {
    return state.pageResults
  },
  rootCategories: state => state.rootCategories,
  didAnyFilterChange(state) {
    return didChange(state.defaultFilters, router.currentRoute.query)
  }
}

const mutations = {
  setSearchParams(state, newSearchParams) { // setFilters
    state.searchParams = parseFiltersFromQueries({...state.searchParams, ...newSearchParams})
  },
  setTempSelectedCategoryId(state, id) {
    state.tempSelectedCategoryId = id;
  },
  clearSearchResults(state) {
    state.tempSelectedCategoryId = null;
    state.searchResults = {}
  },
  setIsNewSearch: (state, payload) => state.isNewSearch = payload,
  appendSearchResults(state, {data: newSearchResults, query}) {
    if (newSearchResults.category) {
      state.previousCategory = newSearchResults.category
    }
    state.searchResults.category = newSearchResults.category
    state.searchResults.current_page = newSearchResults.current_page
    state.searchResults.rounded_results_total_count = newSearchResults.rounded_results_total_count || 0
    state.searchResults.search_in_shop_name = newSearchResults.search_in_shop_name
    const newResult = {
      done_search_sha1: newSearchResults.done_search_sha1,
      results: newSearchResults.search_results
    }
    searchCache.cacheResult(query, newResult)
    state.pageResults = newResult
    state.searchResults.total_pages = Math.ceil(newSearchResults.rounded_results_total_count / newSearchResults.per_page)
  },
  setCachedResult: (state, queryObject) => {
    state.pageResults = searchCache.getCachedResult(queryObject)
    state.searchResults.current_page = queryObject.page
  },
  setIsLoadingSearchResults: (state, isLoading) => state.isLoadingSearchResults = isLoading,
  setMetricsState: (state, metrics) => state.homeMetrics = metrics,
  setDidTrackIndexGA(state, bool) {
    state.didTrackIndexGA = bool
  },
  setShippingCountries(state, newShippingCountries) {
    state.shippingCountries = newShippingCountries
  },
  setCategories: (state, data) => {
    state.categories = data;
  },
  setCategoriesLevelOneIDs(state, data) {
    state.categoriesLevelOneIDs = data
  },
  setRootCategories(state, aCategories) {
    const genderIndex = aCategories.findIndex(e => e.name.toLowerCase() === 'gender')
    if (genderIndex !== -1) {
      state.genderCategory = aCategories[genderIndex]
      state.rootCategories = [...aCategories.slice(0, genderIndex), ...aCategories.slice(genderIndex+1)]
    } else {
      state.rootCategories = aCategories
    }
  },
  setSelectedCategoryID: (state, id) => {
    state.searchParams.category_id = id; // for desktop only
  },
  setNavbarSelectedCategoryId(state, id) {
    state.navbarSelectedCategoryId = id
  },
  setSelectedStack: (state, id) => {
    const item = state.categories[id];
    if (!id || !item || !item.id) {
      state.selectedStack = [];
      return
    }
    const newArr = []

    newArr.unshift(item.id);
    let parentId = item.parent_id
    while (parentId) {
      newArr.unshift(parentId);
      parentId = (state.categories[parentId] && state.categories[parentId].parent_id) || null
    }
    state.selectedStack = newArr
  },

  setDefaultFilters(state, data) {
    const newFilters = {...state.defaultFilters, ...data}
    if (newFilters.currency === undefined || newFilters.currency === null) { // default value = '*' => guard clause to not allow override by mistake.
      delete newFilters.currency
    }
    state.defaultFilters = newFilters
  },

  setShowChildrenFor(state, catID) {
    const item = state.categories[catID]
    if (!catID || !item || !item.id) {  // guard clause for null ID (all cats), id not match cats
      state.showChildrenFor = null;
    } else {
      state.showChildrenFor = item.is_deepest ? item.parent_id : catID
    }
  },

  saveFiltersStatus(state) {
    state.savedSearchParams = {...state.searchParams};
    state.savedSelectedStack = [...state.selectedStack];
  },
  restoreFiltersStatus(state) {
    state.searchParams = {...state.savedSearchParams};
    state.selectedStack = [...state.savedSelectedStack];
  }
}


const actions = {
  initPage({commit, state, dispatch, getters}, queries) {
    queries = parseFiltersFromQueries(queries, state.defaultFilters);
    const tempQueries = sanitizeParamsForRequest({...queries, currency: getters.currency});
    if (isSameFilters(tempQueries, previousSearchRequestParams) && searchCache.hasResult(tempQueries)) {
      commit('setCachedResult', tempQueries)
      return;
    }
    // don't need to update categories if it's category pages
    if (!isCategoryRoute(router.currentRoute.name)) {
      dispatch('handleCategoryFetch', queries)
    }

    if (!queries.country) {
      queries.country = state.defaultFilters.country
    }
    dispatch('makeNewSearch', queries);
    commit('setDidTrackIndexGA', false)
  },
  handleCategoryFetch({commit, state, dispatch}, queries) {
    // only fetch category again if
    if (window.lastSearchTerm !== queries.q  // user do new search
      || state.selectedStack.length === 0  // there is no current category stack
      || (window.lastSearchTerm === queries.q && state.selectedStack[0] != getRootCategory(queries.category_id, state.categories))// current level-1 category not in map categories
    ) {
      // fetch categories from search Q, then update selected on searchbar
      dispatch('fetchCategories', {
        q: queries.q,
        shop_domain: queries.shop_domain,
        search_in_shop: queries.search_in_shop,
        platform: queries.platform,
        category_id: queries.category_id
      })
        .then((categories) => {
          commit('setNavbarSelectedCategoryId', getRootCategory(queries.category_id, categories) || queries.category_id) // update on searchbar but wait for async
          commit('setSelectedStack', queries.category_id)
          commit('setShowChildrenFor', queries.category_id)
        })
      window.lastSearchTerm = queries.q
    } else {
      commit('setSelectedStack', queries.category_id)
      commit('setShowChildrenFor', queries.category_id)
      commit('setNavbarSelectedCategoryId', getRootCategory(queries.category_id, state.categories) || queries.category_id);
    }
  },
  navigateToSearchPageWithQuery({state, commit}, queries) {
    const copy = {...state.searchParams, ...queries, page: 1}
    commit('setSearchParams', copy)
    if (copy.q === '' && !isSearchInShop(copy) && !isCategoryRoute(router.currentRoute.name)) {
      router.push({name: routes.categoryIndex.name})
    } else {
      router.push({
        path: router.currentRoute.path,
        query: sanitizeParams(copy)
      })
    }
    searchCache.cacheParam(['from', null])
  },
  resetFilters({state, commit, dispatch}, name) {
    if (name === 'all') {
      commit('setSearchParams', state.defaultFilters);
      dispatch('resetCategory');
    } else if (name === 'price') {
      commit('setSearchParams', {
        min_price: state.defaultFilters.min_price,
        max_price: state.defaultFilters.max_price,
      })
    } else {
      commit('setSearchParams', {[name]: state.defaultFilters[name]});
    }
  },

  resetCategory({state, commit, dispatch}) {
    commit('setSelectedStack');
    dispatch('fetchCategories', {q: state.searchParams.q});
  },

  makeNewSearch(context, payload) {
    context.commit('clearSearchResults')
    context.commit('setIsNewSearch', true)
    context.dispatch('searchProducts', payload
    ).then(() => {
      context.commit('setIsNewSearch', false)
    })
  },

  searchProducts(context, payload) {
    context.commit('setIsLoadingSearchResults', true)
    const specialParams = omitBy({ // Special params from old design, to determine whether we are searching within a shop or not
      shop_domain: router.currentRoute.query.shop_domain,
      platform: router.currentRoute.query.platform,
      search_in_shop: router.currentRoute.query.search_in_shop,
    }, isNil)
    const newSearchParams = {...specialParams, ...payload}
    context.commit('setSearchParams', newSearchParams)
    const requestParams = sanitizeParamsForRequest({...newSearchParams, currency: context.getters.currency});
    previousSearchRequestParams = requestParams;

    axios.get('/search.json', {
      params: requestParams,
    }).then(function (response) {
      context.dispatch('Meta/setMeta', response.data.meta_data, {root: true})
      context.commit('appendSearchResults', {data: response.data.data, query: requestParams})
      context.commit('setIsLoadingSearchResults', false)
      const followeeIdsToCheck = extractFolloweeIdsSearchResults(response.data.data.search_results)
      context.dispatch('SharedFollow/fetchFolloweeIds', followeeIdsToCheck, {root: true})
      context.commit('setMetricsState', response.data.metrics)
      context.commit('setDefaultFilters', {country: payload.country})  // country is special, new value always persists site-wise, other params are search-wise.
    })
  },

  fetchShippingCountries({commit}) {
    commit('setShippingCountries', [])
    axios.get('/shipping_countries.json'
    ).then(function (response) {
      let data = response.data.shipping_countries.map(country => {
        return {code: country[1], name: country[0]}
      })
      commit('setShippingCountries', data)
    })
  },
  async fetchCategories({commit, state}, params) {
    params.q = params.q || ''
    let categories = []
    const isAllProductSearch = !isSearchInShop(params) && !params.q && !params.category_id
    if (isAllProductSearch) {                        // when /search for all products
      categories = state.rootCategories.slice(1)     // reuse list of level 1 categories only (already fetched for navbar)
    } else {                                         // get list of categories (1) match with q OR (2) match with shop's products
      try {
        const res = await axios.get('/categories.json', {params});
        categories = res.data.product_categories;
      } catch {
      }
      categories.sort(categoriesSortFunctionByName)
    }

    const categoryMap = arrToHashAndPopulateChildren(categories)

    const categoriesLevelOneIDs = categories.reduce((accu, v) => {
      if (v.level === 1) {
        accu.push(v.id)
      }
      return accu
    }, []);

    commit('setCategories', categoryMap)
    commit('setCategoriesLevelOneIDs', categoriesLevelOneIDs);
    const categoryIdFromRespondedMap = params.category_id && categoryMap[params.category_id] ? parseInt(params.category_id) : null
    commit('setShowChildrenFor', categoryIdFromRespondedMap);
    commit('setSelectedStack', categoryIdFromRespondedMap);

    return categoryMap;
  },

  async fetchAllRootCategories({commit}) {  // call once when fist load App
    let categories = []
    try {
      const res = await axios.get('/categories.json', {params: {level: 1}});
      categories = res.data.product_categories;
      commit('Meta/setPageMetas', [routes.categoryIndex.name, res.data.meta_data], {root: true})
    } catch {
    }

    categories.unshift({name: "All Categories"})
    commit('setRootCategories', categories)
  },
  async toggleFilterSidebar({commit, dispatch}, bool) {
    if (bool) {
      document.body.classList.add('should-lock-scroll')
    } else {
      document.body.classList.remove('should-lock-scroll')
    }
    await (async function (bool) {
      if (bool) await dispatch('saveFilters')
      else await dispatch('restoreFilters')
    })(bool)

    commit('SharedCurrentReviewer/toggleFilterSidebar', bool, {root: true})
  },
  fetchWithFilters({commit, state, dispatch, getters}, newParams) {
    commit('SharedCurrentReviewer/toggleFilterSidebar', false, {root: true})
    if (newParams && newParams.country) {  // also update backend global state country & rs_country setting in backend.
      dispatch('SharedCurrentReviewer/updateCurrentCountry', newParams.country, {root: true})
    }
    router.push({
      path: router.currentRoute.path,
      query: sanitizeParams({...state.searchParams, ...newParams, page: 1, currency: getters.currency}),
    })
  },
  updateFilter({commit, dispatch}, {params, bRefetch}) {
    commit('setSearchParams', params)
    if (bRefetch) {
      dispatch('fetchWithFilters', params)
    }
  },
  async clearFilter({commit, dispatch}, {name, bRefetch}) {
    await dispatch('resetFilters', name)
    if (bRefetch) {
      dispatch('fetchWithFilters')
    }

  },
  saveFilters({commit}) {
    commit('SharedCurrentReviewer/saveFilterCurrency', null, {root: true})
    commit('saveFiltersStatus')
  },
  restoreFilters({commit}) {
    commit('SharedCurrentReviewer/restoreFilterCurrency', null, {root: true})
    commit('restoreFiltersStatus')
  },
  cacheParam(_, payload) { // payload = [key, value]
    searchCache.cacheParam(payload)
  },
  getCachedParam(_, payload) {
    return searchCache.getCachedParam(payload)
  }
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,

  namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
}
