<!-- Usage:
    Option 1: Fully customize modal content by using slot:main
    Option 2:
        - Header with text or component, close button will always available but can override styles
        - Content can be component or text.
-->
<template>
  <modal-wrapper @close="handleClose">
    <div class="mobile-modal-container">
      <template v-if="hasMainSlot">
        <slot name="main"></slot>
      </template>
      <template v-else>
        <div class="mobile-modal__header">
          <slot name="header"></slot>
          <span class="material-icons mobile-modal__close-icon"
                @click="handleClose"
                data-closable="true">close</span>
        </div>
        <div class="mobile-modal__divider"></div>
        <div class="mobile-modal__content">
          <slot name="content"></slot>
        </div>
      </template>
    </div>
  </modal-wrapper>
</template>
<script>

export default {
  methods: {
    handleClose(e) {
      e.target.dataset.closable === 'true' && this.$emit('closeModal')
    }
  },
  computed: {
    hasMainSlot() {
      return !!this.$slots.main
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.mobile-modal-container {
  overflow-y: auto;
  height: fit-content;
  min-height: 50dvh;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0px 0px;
  color: $primaryFontColor;
  top: 0;
  margin: auto 0 0 0;
}

.mobile-modal__close-icon {
  font-size: 24px;
  align-self: center;
}

.mobile-modal__header,
.mobile-modal__content {
  padding: 20px;
}

.mobile-modal__header {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
}

.mobile-modal__content {
  padding-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.mobile-modal__divider {
  width: 100%;
  height: 1px;
  background-color: $newBorderColor;
}
</style>
