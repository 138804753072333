<template functional>
  <div class='verified-badge'
       :class="{
          'old-badge': props.oldColor,
          'ml-2': props.space,
          ...$options.methods.computedClass(data.staticClass)
       }">
    {{ props.text }}
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Verified'
    },
    space: {
      type: Boolean,
      default: true,
    },
    oldColor: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    computedClass(classProps) {
      return classProps ? classProps.split(' ').reduce((classObj, string) => ({ ...classObj, [string]: true }), {}) : null
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
.verified-badge {
  display: inline-block;
  color: white;
  padding: 0 8px;
  font-size: 10px;
  border-radius: 5px;
  line-height: 18px;
  vertical-align: middle;
  font-weight: 600;
  border: 1px solid #029C65;
  background: linear-gradient(180deg, #24C88D 0%, #00AB6D 100%);
}

.old-badge {
  font-weight: 400;
  border: none;
  background: $brandColor;
}
</style>
