<template>
  <section class="profile-reviews-section">
    <div class="prs__padding-sides mb-3">
      <div class="prs__title" @click="openPopover" ref='popoverWrapper'>
        <div>{{ listName }}</div>
        <div class="prs__list-select-button" v-if="!!reviewsData.totalCount">
          <i class="material-icons prs__expand-button">{{ popOverOpen ? 'expand_less' : 'expand_more' }}</i>
          <Popover v-if="popOverOpen" @close="closePopover"
                   @click="onSwitchSelection"
                   :position="positionProps"
                   v-closable="{
                      exclude: ['popoverWrapper'],
                      handler: 'closePopover'
                   }">
            <div class="prs__list-selection">
              <p v-if="showingIndex !== idx" v-for="(selection,idx) in selections">
                {{ selection }}
              </p>
            </div>
          </Popover>
        </div>
      </div>
    </div>
    <FilterSort class="prs__padding-sides mt-5 mb-5" :storeName="storeName" v-if="showingReviews"/>
    <CardsList v-if="!showingReviews" :storeName="storeName" ref="component" @pageChange="onPageChangeRecommendation"
               :totalPages="recommendationsData.totalPages" :currentPage="recommendationsData.currentPage">
      <div v-if="recommendationsData.recommendations.length === 0" class="d-flex-center mt-5 mb-5 rs-weight-600">No record</div>
      <RecommendationCard v-else v-for="(card, idx) in recommendationsData.recommendations" :recommendation="card" :key="idx" class="prs__padding-sides" />
    </CardsList>
    <CardsList v-else :totalPages="reviewsData.totalPages" ref="component" @pageChange="onPageChange"
               :currentPage="reviewsData.currentPage" :isLoading="!doneLoadMoreReviews">
      <div v-if="reviewsData.reviews.length === 0" class="d-flex-center mt-5 mb-5 rs-weight-600">No record</div>
      <ReviewCard v-else v-for="(card, idx) in reviewsData.reviews" :card="card" :key="idx" class="prs__padding-sides" />
    </CardsList>
  </section>
</template>
<script>
import FilterSort from 'rs/components/uncommon/reviews/reviews_filter_sort';
import CardsList from 'rs/components/uncommon/profile/_cards_list'
import ReviewCard from 'rs/components/uncommon/profile/_review_card'
import RecommendationCard from 'rs/components/uncommon/profile/_recommendation_card'
import Popover from 'rs/components/common/popover'

const LIST = ['reviews', 'recommendations'] // code is set to toggle between 2 selections for bigger clickbox (ux). Need to update code to use 3 or more.

export default {
  components: {FilterSort, CardsList, Popover, ReviewCard, RecommendationCard},
  computed: {
    showingReviews() {
      return this.showingIndex === 0
    },
    storeName() {
      return this.$store.getters['Profile/storeName']
    },
    recommendationsData() {
      return this.$store.getters[this.storeName + '/recommendationPaginated']
    },
    reviewsData() {
      return this.$store.getters[this.storeName + '/reviewsData']
    },
    doneLoadMoreReviews() {
      return this.$store.getters[this.storeName + '/doneLoadMoreReviews']
    },
    selections() {
      return LIST
    },
    listName() {
      return LIST[this.showingIndex]
    },
    shouldScrollToReviews() {
      return this.$store.getters['Profile/shouldScrollToReviews']
    }
  },
  data() {
    return {
      popOverOpen: false,
      showingIndex: 0,
      positionProps: {},
    }
  },
  methods: {
    onSwitchSelection() {
      this.closePopover();
      this.$nextTick(() => this.showingIndex = this.showingIndex ? 0 : 1)
    },
    onPageChangeRecommendation(page) { // handle pagination on frontend
      this.$sharedUtils.scrollToDistanceWithHeaderOffset(this.$refs.component.$el.getBoundingClientRect().top, {smooth: false})
      this.$store.dispatch(`${this.storeName}/paginateRecommendation`, page)
    },
    onPageChange(page) {
      // instant scroll up to top of list => show spinner => show result
      // otherwise the actions complete too fast window will not scroll in time and will stay at bottom (happen when you're at bottom).
      this.$sharedUtils.scrollToDistanceWithHeaderOffset(this.$refs.component.$el.getBoundingClientRect().top, {smooth: false})
      this.$nextTick(() => {
        this.$store.dispatch(`${this.storeName}/loadReviewsOnPageChange`, {page})
      })
    },
    openPopover(e) {
      if (!this.reviewsData.totalCount) return;
      const rect = e.target.getBoundingClientRect();
      this.positionProps = {
        x: rect.left,
        y: rect.bottom + 4,
      }
      this.popOverOpen = true;
    },
    closePopover() {
      this.popOverOpen = false;
    },
  },
  mounted() {
    try {
      if (this.reviewsData.totalCount === 0 && this.recommendationsData.totalCount > 0) {
        this.showingIndex = 1
      }
    } catch {}
  },
  watch: {
    shouldScrollToReviews(bool) {
      if (!bool) return;
      this.$store.dispatch('Profile/shouldScrollToReviews', false)
      this.showingIndex = 0
      this.$sharedUtils.scrollToDistanceWithHeaderOffset(this.$refs.component.$el.getBoundingClientRect().top)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.profile-reviews-section {
  width: 100%;
  background: white;
  border-radius: 0;
  padding: 24px 0;
  @include respond-to(desktop) {
    border-radius: 12px;
    border: $newBorderStyle;
  }
}

.prs__padding-sides {
  padding-right: 16px;
  padding-left: 16px;
  @include respond-to(desktop) {
    padding-right: 28px;
    padding-left: 28px;
  }
}

.prs__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  gap: 8px;
  cursor: pointer;
  text-transform: capitalize;
}

.prs__list-select-button {
  position: relative;

  .prs__list-selection {
    width: 200px;

    p {
      font-size: 16px;
      text-transform: capitalize;
    }
  }
}

.prs__expand-button {
  color: $brandColor;
  font-size: 28px;
  vertical-align: middle;
}
</style>
