import Vue from 'vue';
import Vuex from 'vuex';

import SharedFollow from 'rs/store/shared/follow'
import SharedCurrentReviewer from 'rs/store/shared/current_reviewer'
import Meganav from 'rs/store/shared/meganav'
import Reviews from 'rs/store/reviews'
import Search from 'rs/store/search'
import ShopSearch from 'rs/store/shop_search'
import People from 'rs/store/people'
import Following from 'rs/store/following'
import RsFollowToolTips from 'rs/plugins/rs_follow_tooltips'
import Gallery from 'rs/store/shared/gallery'
import ClickTracking from 'rs/store/shared/click_tracking'
import Product from 'rs/store/product'
import Shop from 'rs/store/shop'
import Category from 'rs/store/categories'
import Home from 'rs/store/home'
import Global from 'rs/store/shared/global'
import Meta from 'rs/store/shared/meta'
import WriteReview from 'rs/store/write_review'
import PublicProfile from 'rs/store/public_profile'
import PrivateProfile from 'rs/store/private_profile'
import Profile from 'rs/store/profile'
import Arbitration from 'rs/store/arbitration'
import Notification from 'rs/store/notification'
import Arbitrator from 'rs/store/arbitrator'

Vue.use(Vuex)
Vue.use(RsFollowToolTips)

export default new Vuex.Store({
  modules: {
    SharedFollow,
    Profile,
    PublicProfile,
    PrivateProfile,
    SharedCurrentReviewer,
    Meganav,
    SharedClickTracking: ClickTracking,
    Reviews,
    Search,
    ShopSearch,
    People,
    Following,
    Gallery,
    Product,
    Shop,
    Category,
    Home,
    WriteReview,
    Global,
    Meta,
    Arbitration,
    Notification,
    Arbitrator,
  },
})
