import utils from 'shared/utils/utils';

export const applyFilter = ({dispatch, state, commit}, filterQueries) => () => {
  commit('setFilterQueries', filterQueries);
  dispatch('loadReviews', {
    page: 1,
    ...filterQueries
  })
}

export const clearFilter = ({dispatch, state, commit}) => defaultFilterQueries => {
  commit('setFilterQueries', defaultFilterQueries)
  dispatch('loadReviews', {
    page: 1,
  })
}

export const removeFilter = ({dispatch, state, commit}, filter) => defaultFilterQueries => { // filter = { type: xxx, value: yyy}
  const newQuery = {}
  switch (filter.type) {
    case 'filter_rating':
      newQuery.filter_rating = state.queries.filter_rating.filter(rating => rating !== filter.value)
      break
    case 'review_type':
      newQuery.review_type = defaultFilterQueries.review_type
      break
    case 'review_source':
      newQuery.review_source = defaultFilterQueries.review_source
      break
    case 'verified_only':
      newQuery.verified_only = defaultFilterQueries.verified_only
      break
    case 'search':
      newQuery.search = null
  }
  dispatch('applyFilter', newQuery)
}

export const applySort = ({dispatch, state, commit}, sort) => () => {
  commit('setSortQuery', sort)
  dispatch('loadReviews', {
    page: 1,
    sort_by: sort.sort_by,
    sort_dir: sort.sort_dir
  })
}

export const loadReviewsOnPageChange = ({dispatch, state, commit}, {page}) => () => {
  let search = state.previousSearch
  if (state.queries.search != state.previousSearch) {
    page = 1
    search = state.queries.search
  }
  dispatch('loadReviews', {...state.queries, page, search})
}

export const trackChange = (defaultFilterQueries, reviewTypes, reviewSources) => ({queries}) => {
  const changes = []
  if (queries.filter_rating.length > 0) {
    queries.filter_rating.forEach(rating => changes.push({
      text: utils.pluralize(rating, 'star'),
      value: rating,
      type: 'filter_rating'
    }))
  }
  if (queries.review_type !== defaultFilterQueries.review_type) {
    changes.push({
      text: reviewTypes[queries.review_type],
      value: queries.review_type,
      type: 'review_type'
    })
  }
  if (reviewSources && queries.review_source !== defaultFilterQueries.review_source) {
    changes.push({
      text: reviewSources[queries.review_source],
      value: queries.review_source,
      type:'review_source'
    })
  }
  if (queries.verified_only) changes.push({
    text: 'Verified reviews only',
    value: queries.verified_only,
    type:'verified_only'
  })
  return changes
}
