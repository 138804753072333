
export default {
  goToUrl: (url) => {
    if (window.shopifySessionToken) {
      const sessionTokenParam = new URLSearchParams({ session_token: window.shopifySessionToken }).toString()
      if (url.indexOf('?') >= 0) {
        const urlParts = url.split(/\?(.+)/) // Split the first occurence of `?`
        url = urlParts[0] + '?' + sessionTokenParam + '&' + urlParts[1]
      } else {
        url = url + '?' + sessionTokenParam;
      }
    }

    if (window.appBridgeStartLoading) window.appBridgeStartLoading()
    window.location.href = url
  },

  reloadPage: () => {
    if (window.shopifySessionToken) {
      const queryString = new URLSearchParams(window.location.search)
      queryString.set('session_token', window.shopifySessionToken)
      window.location.search = queryString.toString()
    } else {
      window.location.reload()
    }
  },

  pluralize: (count, noun, suffix = 's') => `${count || 0} ${noun}${count !== 1 ? suffix : ''}`,
  pluralizeWithSeparator(count, noun, suffix = 's') {
    return `${this.numberWithCommas(count)} ${noun}${count !== 1 ? suffix : ''}`
  },
  buildUrlQueryString: (path, queryObj ={}) =>  path + '?' + new URLSearchParams(queryObj).toString(),
  screenDetector: (width) => {
    if (width < 767) {
      return 'mobile'
    } else if (width < 991) {
      return 'tablet'
    } else {
      return 'desktop'
    }
  },
  roundNumber(num, decimalPlace = 0) {
    const multiplier = 10**decimalPlace
    return Math.round((num + Number.EPSILON) * multiplier) / multiplier
  },
  roundNumberToStringWithDecimalsSuffix(num, decimalPlaceToShow) {
    const numStr = this.roundNumber(num, decimalPlaceToShow).toString();
    const d = numStr.indexOf('.');
    if (d < 0) {
      let suffix = '0'.repeat(decimalPlaceToShow)
      return `${numStr}${suffix === '' ? '' : ('.'+suffix)}`
    } else {
      const remainder = num * 10**decimalPlaceToShow % 10**decimalPlaceToShow
      return `${numStr.slice(0, d)}.${this.roundNumber(remainder,0)}`
    }

  },
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  capitalizeFirstChar(str) {
    return str[0].toUpperCase() + str.slice(1,str.length)
  },
  scrollToDistanceWithHeaderOffset(y, options = {}) {
    window.autoScroll = true;
    setTimeout(() => { window.autoScroll = false; }, 500);

    const mainHeaderEl = document.querySelector('#mainHeader');
    const mainHeaderHeight = mainHeaderEl ? mainHeaderEl.offsetHeight : 120;
    const shadowHeight =  window.media && window.media.mobile ? 0 : 10
    const useOffset = options.hasOwnProperty('useOffset') ? options.useOffset : true;
    const customOffset = options.hasOwnProperty('customOffset') ? options.customOffset : 0;
    const extraOffset = options.hasOwnProperty('extraOffset') ? options.extraOffset : 0;
    const smooth = options.hasOwnProperty('smooth') ? options.smooth : true;

    const offset =  mainHeaderHeight + shadowHeight + extraOffset
    let distance = y + window.scrollY - (useOffset ? offset : 0)
    window.scroll({
      top: customOffset ? customOffset : distance,
      behavior: smooth ? 'smooth' : 'instant'
    });
  },
  convertCurrency(targetCurrency, sourceCurrency, amount, ratesMap, maxDecimals = 2) { // receive targetCurrencyCode, sourceCurrencyCode, amountToConvert, rates hash; do some calculation and return result, if something is not right, return null.
    const convertedAmount = this.convertCurrencyValue(targetCurrency, sourceCurrency, amount, ratesMap, maxDecimals)
    if (convertedAmount === null) return null;
    const currencyFormatter = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: targetCurrency,
      maximumFractionDigits: maxDecimals,
    });
    return currencyFormatter.format(convertedAmount);
  },
  convertCurrencyValue(targetCurrency, sourceCurrency, amount, ratesMap, maxDecimals = 2){
    if (!ratesMap || !targetCurrency || targetCurrency === '*') return null;
    const newAmount = parseFloat(amount);
    if (isNaN(newAmount)) return null;
    if (!ratesMap.hasOwnProperty(sourceCurrency) || !ratesMap.hasOwnProperty(targetCurrency)) return null;
    const sourceTargetRatio = ratesMap[sourceCurrency] / ratesMap[targetCurrency];
    return sourceTargetRatio * newAmount;
  },
  compactNumber(num) {
    return Intl.NumberFormat('en', {notation: 'compact'}).format(num);
  }
}
