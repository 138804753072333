const ROUTER_LINK_TAG = 'router-link'
const ANCHOR_TAG = 'a'
const DIV_TAG = 'div'

export function productProps(routes, product_internal_path, defaultElement = DIV_TAG) {
  if (!product_internal_path)
    return {
      is: defaultElement
    }
  return routes.product ? {
    is: ROUTER_LINK_TAG,
    to: product_internal_path,
  } : {
    is: ANCHOR_TAG,
    href: product_internal_path,
    target: '_blank'
  }
}


export function reviewerLinkProps(routes, profile_path, defaultElement = DIV_TAG) {
  if (!profile_path) return { is: defaultElement }

  return routes.reviewerPublicProfile ? { // this is going to be the name of the new Vue public profile page
    is: ROUTER_LINK_TAG,
    to: '/profile/' + profile_path,  // temporary, will use Vue router object when we have this page defined.
  } : {
    is: ANCHOR_TAG,
    href: '/profile/' + profile_path,
  }
}

export function categoryLinkProps(routes, categoryHandle, defaultElement = DIV_TAG) {
  if (!categoryHandle || !routes.categoryShow) return { is: defaultElement }
  return {
    is: ROUTER_LINK_TAG,
    to: {
      name: routes.categoryShow.name,
      params: {
        category_slug: categoryHandle,
      },
    },
  }
}
