<template>
  <section class="categories-section">
    <h2 class="cs__heading">{{ heading }}</h2>
    <div class="cs__card-list" :class="{'cs__card-list--empty': items.length === 0}">
      <Category v-for="(category,idx) in items" :key="idx"
                textNoWrap=1 :category="category"/>
    </div>
    <router-link :to="{name: 'PageCategoryIndex'}" class="btn pf-secondary-button cs__see-all">View All
    </router-link>
  </section>
</template>

<script>
import Category from 'rs/components/pages/categories/_root_category'

export default {
  components: {Category},
  inject: ['mediaQueries'],
  computed: {
    categoriesData(){ return this.$store.getters['SharedCurrentReviewer/categoriesData']},
    categories() {
      if (this.categoriesData && this.categoriesData.items) {
        return this.categoriesData.items || []
      } else { // fallback to root categories in case backend doesn't return data
        const cats = this.$store.getters['Search/rootCategories']
        return (cats && cats.length > 1) ? cats.slice(1, cats.length) : null
      }
    },
    items() {
      return this.categories && this.categories.slice(0, this.mediaQueries.mobile ? 6 : 12) || []
    },
    heading() {
      return "Explore millions of products with reviews by category"
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

$iconGridSizeMobile: 162px;
$iconGridSizeDesktop: 172px;
$cardSizeMobile: 202px; // 202 = 162 + 20 + 20
$cardSizeDesktop: 212px; // 212 = 172 + 20 + 20

.categories-section {
  --category-icon-grid-size-mobile: 162px;
  --category-icon-grid-size-small-mobile: 142px;
  --category-icon-grid-size-desktop: 120px;

  margin: 12px 0 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond-to(notmobile) {
    margin: 62px 0 60px 0;
  }
}

.cs__heading {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}

.cs__card-list {
  margin: 32px 0 36px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  row-gap: 12px;

  @include respond-to(mobile) {
    padding: 0 calc((100% - (#{$cardSizeMobile} * 2)) / 2);
  }
  @include respond-to(tablet) {
    padding: 0 calc((100% - (#{$cardSizeDesktop} * 3)) / 2);
  }
  @include respond-to(desktop) {
    padding: 0 calc((100% - (#{$cardSizeDesktop} * 6)) / 2);
    justify-content: space-between;
  }
}
.cs__card-list--empty {
  margin: 20px;
}

.cs__card-list > * {
  width: $iconGridSizeMobile;
  max-width: $iconGridSizeMobile;
  flex: 0 0 $cardSizeMobile;

  @include respond-to(notmobile) {
    width: $iconGridSizeDesktop;
    max-width: $iconGridSizeDesktop;
    flex: 0 0 $cardSizeDesktop;
  }
}

.cs__see-all {
  width: 280px;
}
</style>
