// For simple + static tooltips label and text
import {customFormat} from 'shared/utils/timeago';
const formatDate = date => customFormat(date)('datetime')

export default {
  review_verified: {
    name: 'review_verified',
    label: 'Verified independently based on order data',
    tooltip: 'Verified badge is assigned when there is an order associated with the review. Verification is based on order history obtained directly from e-commerce platforms or by store providing evidence of order history.'
  },
  review_pinned: {
    name:'review_pinned',
    label: 'Pinned review',
    tooltip: 'A store has the option to highlight particular reviews by pinning them, ensuring they appear first.'
  },
  transparency: {
    name: 'transparency',
    label: 'Reviews published',
    tooltip: 'Common reasons why reviews are not published: spam, offensive or inappropriate content, things outside the seller’s control (e.g. shipping). Review publication settings are managed solely by the store. We recommend stores to work with reviewers to resolve inappropriate content issues and aim to publish all their reviews.'
  },
  averageRating: {
    name: 'averageRating',
    label: 'Average reviews rating',
    tooltip: 'The rating score is determined by the ongoing average of all store reviews published over time.'
  },
  authenticity: {
    name: 'authenticity',
    label: 'Percentage of published reviews that are verified',
    tooltip: 'Judge.me verifies reviews independently based on order history obtained directly from e-commerce platforms or by store providing evidence of order history.'
  },
  shopOverview: {
    name:'shopOverview',
    label: 'Overview',
    tooltip: 'Overview provided by Judge.me, an independent third party that powers reviews for online stores.'
  },
  featureAskFromBuyers: {
    name: 'featureAskFromBuyers',
    label: 'Actively asks for reviews from buyers',
    tooltip: 'We encourage stores to actively ask for feedback from its buyers. This store enabled review invitations via email, push notifications or SMS.'
  },
  featureAwesome: {
    name: 'featureAwesome',
    label: 'Uses advanced features to collect and display reviews',
    tooltip: 'Most of the functionality for reviews collection and display is free for businesses, but Judge.me offers a paid plan that allows stores to use advanced features, such as customizing how reviews look on their page, adding Q&A functionality and sharing reviews outside their store (e.g. via Google Shopping and third-party apps).'
  },
  refundPolicy: {
    name:'refundPolicy',
    label: 'Return & Refund policy',
    tooltip: (dateString) => 'Store confirmed details on ' + formatDate(dateString) + '.'
  },
  paymentMethods: {
    name:'paymentMethods',
    label: 'Payment methods',
    tooltip: (dateString) => 'Payment methods were confirmed by store on ' + formatDate(dateString) + '.'
  },
  shippingPolicy: {
    name:'shippingPolicy',
    label: 'Shipping policy',
    tooltip: (dateString) => 'Store confirmed details on ' + formatDate(dateString) + '.'
  },
  address: {
    name: 'address',
    label: 'Verified Address',
    tooltip: (dateString) => 'Address was confirmed by store on ' + formatDate(dateString) + '.'
  },
  contact: {
    name: 'contact',
    label: 'Verified Contact',
    tooltip: (dateString) => 'Contact was verified on ' + formatDate(dateString) + '.'
  },
  storeMedal: {
    name:'storeMedal',
    label: 'Store Medals',
    tooltip: 'Medals are awarded by Judge.me, an independent third party that powers reviews for online stores.'
  },
  social: {
    name:'social',
    label: 'Socials',
    tooltip: (dateString) => 'Contact details were confirmed on ' + formatDate(dateString) + '.'
  }
}
