import { render, staticRenderFns } from "./review_images.vue?vue&type=template&id=573d5730&scoped=true&"
import script from "./review_images.vue?vue&type=script&lang=js&"
export * from "./review_images.vue?vue&type=script&lang=js&"
import style0 from "./review_images.vue?vue&type=style&index=0&id=573d5730&prod&lang=scss&scoped=true&"
import style1 from "./review_images.vue?vue&type=style&index=1&id=573d5730&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "573d5730",
  null
  
)

export default component.exports