/* This component will be registered globally
- Usage: can pass through vue props, native listeners, data attributes, class
  e.g: <svg-icon icon="bag" @click='handleclick' class="ml-3" color="red"/>
 */
import bag from 'shared/vue_components/svg_icons/svg_bag.vue';
import qna from 'shared/vue_components/svg_icons/svg_qna.vue';
import star from 'shared/vue_components/svg_icons/svg_star.vue';
import store from 'shared/vue_components/svg_icons/svg_store.vue';
import clock from 'shared/vue_components/svg_icons/svg_clock.vue';
import check from 'shared/vue_components/svg_icons/svg_check.vue';
import check_scratch from 'shared/vue_components/svg_icons/svg_check_scratch.vue';
import bell from 'shared/vue_components/svg_icons/svg_bell.vue';
import file_doc from 'shared/vue_components/svg_icons/svg_file_doc.vue';
import file_jpeg from 'shared/vue_components/svg_icons/svg_file_jpeg.vue';
import file_pdf from 'shared/vue_components/svg_icons/svg_file_pdf.vue';
import file_png from 'shared/vue_components/svg_icons/svg_file_png.vue';
import file_tiff from 'shared/vue_components/svg_icons/svg_file_tiff.vue';
import shield_check from 'shared/vue_components/svg_icons/svg_shield_check.vue';

const icons = {
  bag,
  qna,
  star,
  store,
  clock,
  check,
  check_scratch,
  bell,
  file_doc,
  file_jpeg,
  file_pdf,
  file_png,
  file_tiff,
  shield_check
}
export default {
  functional: true,
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#088484',
    }
  },

  render: function (createElement, object) {
    return createElement(icons[object.props.icon],
      {
        props: object.props,
        attrs: object.data.attrs,
        listeners: object.listeners,
        class: object.data.staticClass
      }, object.children)
  }
}
