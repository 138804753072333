<!--
  This is a modified version of this lib https://www.npmjs.com/package/vue-text-highlight
  Refer to its readme for usage, or read the source code below.
-->
<template>
  <span>
  <template v-for="({text, isHighlighted, highlightIndex}) in highlights">
    <template v-if="!isHighlighted">{{ text }}</template>
    <component v-else
               :is="highlightComponent"
               :class="['text__highlight', highlightClass]"
               :style="highlightStyle"
               :key="highlightIndex"
               :index="highlightIndex"
               :text="text"
    >{{ text }}</component>
  </template>
    </span>
</template>
<script>
import highlightChunks from 'rs/components/common/highlight/utils/highlightChunks';

const classAndStyleTypes = [Object, Array, String];
export default {
  name: 'text-highlight',
  props: {
    queries: [Array, String, RegExp],
    caseSensitive: {type: Boolean, default: false},
    diacriticsSensitive: Boolean,
    wholeWordMatch: Boolean,
    highlightStyle: classAndStyleTypes,
    highlightClass: classAndStyleTypes,
    highlightComponent: {
      type: [String, Object],
      default: 'span',
    },
  },
  data() {
    return {
      text: '',
    };
  },
  /**
   * Unless `h` is given as parameter, testing (Jest) will yield error:
   *    TypeError: unknown: Duplicate declaration "h"
   *    (This is an error on an internal node. Probably an internal error)
   *
   * Seems babel-plugin-transform-vue-jsx make strange behaivor.
   *
   * Related issue: https://github.com/storybooks/storybook/issues/2727
   */
  /* eslint-disable-next-line no-unused-vars */

  beforeMount() {
    this.setTextFromSlot();
  },
  beforeUpdate() {
    this.setTextFromSlot();
  },
  methods: {
    setTextFromSlot() {
      const defaultSlot = this.$slots.default;
      if (!defaultSlot) this.text = '';
      else if (defaultSlot[0].tag !== undefined && process.env.NODE_ENV !== 'production') {
        /* eslint-disable-next-line no-console */
        console.warn('children of <text-highlight> must be a plain string.');
        this.text = '';
      } else {
        this.text = defaultSlot[0].text;
      }
    },
  },
  computed: {
    attributes() {
      return {
        props: this.$attrs,
        on: this.$listeners,
      };
    },
    highlights() {
      const {
        text,
        queries,
        caseSensitive,
        diacriticsSensitive,
        wholeWordMatch,
      } = this;
      return highlightChunks(text, queries, {caseSensitive, diacriticsSensitive, wholeWordMatch});
    },
  },
};
</script>

<style lang="scss">
.text__highlight {
  background: rgb(255, 204, 0);
  border-radius: 3px;
}
</style>