<!--
Remember to set these css var when consuming this component
  .parent-class {
    --category-icon-grid-size-mobile: 162px;
    --category-icon-grid-size-small-mobile: 142px;
    --category-icon-grid-size-desktop: 120px;
    --category-font-size-mobile // optional
  }
-->
<template>
  <component class="category-card" v-bind="linkProps">
    <img class='category-card__icon' :src="imageUrl" @error="onErrorImage" />
    <div :class="['category-card__name', textNoWrap && 'category-card__name--nowrap']">{{ category.name }}</div>
  </component>
</template>

<script>
import {categoryLinkProps} from 'shared/utils/component_link_utils';

const CATEGORY_IMAGE_URL_PREFIX = 'https://pub-images.judge.me/judgeme/marketplace/categories/' // + cat.handle
let didFallback = false;
export default {
  props: ['category', 'textNoWrap'],
  computed: {
    imageUrl() {
      return this.category.image_url || `${CATEGORY_IMAGE_URL_PREFIX + this.category.handle}.png`
    },
    linkProps(){ return categoryLinkProps(this.$routes, this.category.handle) }
  },
  methods: {
    onErrorImage(e){
      if (didFallback) return;
      e.target.src = `${CATEGORY_IMAGE_URL_PREFIX + this.category.handle}.png`
      didFallback = true;
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.category-card {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 12px;
  &:hover {
    .category-card__icon {
      box-shadow: 0 4px 14px #AACBCB;
    }
    .category-card__name {
      text-decoration: underline;
    }
  }
}
.category-card__name {
  height: 48px;
  min-height: 48px;

  @include respond-to(mobile) {
    font-size: var(--category-font-size-mobile);
  }

  @media only screen and (max-width: 380px) {
    padding: 0 12px;
  }
}
.category-card__name--nowrap {
  width: 100%;
  @include text-elipsis;
}

a.category-card {
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: $primaryFontColor;
}

.category-card__icon {
  border-radius: 50%;
  width: var(--category-icon-grid-size-mobile);
  height: var(--category-icon-grid-size-mobile);
  min-width: var(--category-icon-grid-size-mobile);
  min-height: var(--category-icon-grid-size-mobile);

  @include respond-to(notmobile) {
    min-width: var(--category-icon-grid-size-desktop);
    min-height: var(--category-icon-grid-size-desktop);
    width: var(--category-icon-grid-size-desktop);
    height: var(--category-icon-grid-size-desktop);
  }
  @media only screen and (max-width: 380px) {
    min-width: var(--category-icon-grid-size-small-mobile);
    min-height: var(--category-icon-grid-size-small-mobile);
    width: var(--category-icon-grid-size-small-mobile);
    height: var(--category-icon-grid-size-small-mobile);
  }
}

</style>
