export const EVENT_CATEGORIES = {
  viewContent: 'View Content',
}

export const EVENT_NAMES = {
  productCardClick: 'Product Card Click',
  storeNameClick: 'Store Name Click',
  pageView: 'page_view', // don't change this, this is default name from GA, not our custom name
}

export const EVENT_LABELS = {
  searchResultClickWithIndex: 'search_result_click_index_'
}