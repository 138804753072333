<template>
  <div class="people-sort-by">
    <span class="people-sort-by__label">sorted by</span>
    <select @change="selectedSorts($event)" name="sort-by" id="people-sort-by__select" v-model="selectedSortKey" v-bind:key="selectedSort">
      <option value="rs_recommendations_count">Popular</option>
      <option value="created_at">Most Recent</option>
    </select>
    <i class="material-icons people-sort-by__arrow">expand_more</i>
  </div>
</template>

<script>
  export default {
    props: ['selectedSort'],

    data() {
      return {
        selectedSortKey: this.selectedSort || 'rs_recommendations_count'
      }
    },

    methods: {
      selectedSorts(sort) {
        this.$emit('changed', sort.target.value)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .people-sort-by {
    position: relative;
  }

  .people-sort-by__label {
    margin-right: 8px;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    color: $secondaryFontColor;
  }

  #people-sort-by__select {
    border: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: $primaryFontColor;
    padding: 4px 30px 4px 6px;
    width: 130px;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    
    &:focus-visible {
      outline: none;
    }

    &::-ms-expand { // for IE only
      display: none;
    }
  }

  .people-sort-by__arrow {
    position: relative;
    right: 30px;
    top: 8px;
    color: $brandColor;
    pointer-events: none;
  }
</style>
