<template>
  <div class="country-dropdown">
    <div class="country-dropdown__wrapper">
      <CountryFlag v-if="showFlag && defaultCountry && defaultCountry !== '*'" class="country-dropdown__flag" :alt="'country flag for '+countriesMap[defaultCountry]" :countryCode="defaultCountry" />
      <select
          class="country-dropdown__select"
          :class="{'country-dropdown__select-padding-flag': showFlag && defaultCountry && defaultCountry !== '*'}"
          :value="defaultCountry" @change="handleChange">
        <option v-for="country in countries.slice(0, topCountriesCount)" :value="country">
          {{countriesMap[country]}}
        </option>
        <optgroup label="_________">
          <option v-for="country in countries.slice(topCountriesCount)" :value="country">
            {{ countriesMap[country] }}
          </option>
        </optgroup>
      </select>
      <span class="country-dropdown__select-arrow material-icons">expand_more</span>
    </div>
  </div>
</template>

<script>
  import shippingCountries from 'shared/constants/country_list_map'
  import CountryFlag from 'rs/components/common/country_flag'

  export default {
    components: {CountryFlag},
    props: ['defaultCountry', 'showFlag'],
    computed: {
      topCountriesCount: () => 12 // check for number of top countries in: country_list_map.js
    },
    data(){
      return {
        countriesMap: shippingCountries,
        countries: Object.keys(shippingCountries),
      }
    },
    methods: {
      handleChange(e){
        this.$emit('change', e.target.value)
      }
    },
  }
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';

  .country-dropdown {
    height: 100%;
    width: 100%;
  }
  .country-dropdown__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-grow: 1;
    height: 100%;
    width: 100%;
    padding: 0 30px 0 12px;
    border: 1px solid #CDCCCC;
    border-radius: 8px;
    &:focus-within {
      border-color: #CDCCCC;
    }
  }
  .country-dropdown__flag {
    height: 40%;
  }
  .country-dropdown__select {
    cursor: pointer;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: $primaryFontColor;
    background-color: transparent;
    padding-right: 40px;
  }
  .country-dropdown__select-padding-flag {
    padding: 0 40px 0 28px;
  }
  .country-dropdown__select-arrow {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: -1;
  }
</style>
