'use strict'

import docReady from 'rs/plugins/document_ready.js'
import { getCookie, setCookie } from 'rs/plugins/cookie.js'

docReady(function() {
  const COOKIE_BAR_KEY = 'jdgm_platform_cookie_bar_acceptance'

  if (document.querySelector('.jm-cookie-bar')) {
    document.querySelector('.jm-cookie-bar__button').addEventListener('click', function(event) {
      setCookie(COOKIE_BAR_KEY, true, 365)
      document.querySelector('.jm-cookie-bar').classList.add('hidden')
    })
  }
})
