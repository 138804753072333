import { render, staticRenderFns } from "./verified_badge.vue?vue&type=template&id=bb803732&scoped=true&functional=true&"
import script from "./verified_badge.vue?vue&type=script&lang=js&"
export * from "./verified_badge.vue?vue&type=script&lang=js&"
import style0 from "./verified_badge.vue?vue&type=style&index=0&id=bb803732&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "bb803732",
  null
  
)

export default component.exports