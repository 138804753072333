<template>
  <modal-wrapper @close="handleClose">
    <div class="proposal-modal">
      <iconify icon="iconamoon:close" width="28" class="proposal-modal__close-icon" @click="handleClose"
               data-closable="true"/>
      <div class="proposal-modal__container">
        <h2 class="proposal-form__text">Propose resolution</h2>
        <p class="proposal-form__text">{{ subText }}</p>
        <Dropdown>
          <select
            v-model="formInputs.proposalType"
            class="proposal-form__dropdown"
            :class="{'proposal-form__invalid-field': validated && !submittedFields.proposalType}">
            <option v-for="({text, value}, idx) in proposalTypeOptions" :value="value">{{ text }}</option>
          </select>
        </Dropdown>
        <div class="proposal-form__textarea-field">
          <textarea v-model="formInputs.description"
                    :maxlength="2000"
                    class="proposal-form__textarea"
                    :class="{'proposal-form__invalid-field': validated && !submittedFields.description}"
                    cols="30" rows="10" placeholder="Provide us with as much information as possible about your issue"/>
        </div>

        <div class="proposal-form__cta-buttons">
          <button class="btn pf-secondary-button proposal-form__btn-propose" @click="submitProposal">Propose</button>
          <button class="btn pf-text-button proposal-form__btn-cancel" @click="handleClose" data-closable="true">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </modal-wrapper>
</template>
<script>
import Dropdown from 'rs/components/common/dropdown'
import {getProposalOptions} from 'shared/vue_components/arbitration/helpers'

export default {
  props: ['dispute', 'proposal'],
  inject: ['actions'],
  components: {Dropdown},
  data() {
    return {
      validated: false,
      formInputs: {
        proposalType: '',
        description: '',
      },
    }
  },
  computed: {
    subText() {
      return this.proposal ?
        'Please select your proposed resolution to the store' :
        'Please make sure you’ve gathered and analysed all the necessary information before you propose a resolution.'
    },
    proposalTypeOptions() {
      const options = getProposalOptions(this.dispute.user_role, this.proposal && this.proposal.proposal_type)
      return [{text: 'Select resolution', value: ''}].concat(options)
    },
    submittedFields() {
      return {
        proposalType: this.formInputs.proposalType,
        description: this.formInputs.description.trim(),
      }
    },
    submittable() {
      return Object.keys(this.submittedFields).every(key => {
        return this.submittedFields[key] !== ''
      })
    },
  },
  methods: {
    handleClose(e) {
      e.target.dataset.closable === 'true' && this.$emit('close')
    },
    validateForm() {
      this.validated = true;
      return this.submittable;
    },
    submitProposal() {
      if (!this.validateForm()) return;
      this.actions.createProposal(
        {
          ...this.submittedFields,
          disputeId: this.dispute.id,
          new_dispute: true,
        })
        .then((newDispute) => {
          this.actions.renderDispute(newDispute)
          this.$emit('close')
        })
    },
  },
}

</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';
.modal-wrapper {
  height: auto; // override default styles
}
.proposal-modal {
  padding: 40px 16px;
  max-width: 768px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.proposal-modal__close-icon {
  color: $brandColor;
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
  @include respond-to(mobile) {
    right: 16px;
  }
  z-index: 3;
}

.proposal-modal__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-weight: 400;
  max-width: 540px;
  width: 100%;
  color: $primaryFontColor;

  > * {
    width: 100%;
  }
}

.proposal-form__text {
  text-align: center;
  color: $primaryFontColor;
}

.proposal-form__text--sub {
  font-size: 14px;
}

h2.proposal-form__text {
  font-weight: 700;
}

h4.proposal-form__text {
  font-weight: 700;
}


.proposal-form__invalid-field.proposal-form__invalid-field {
  border: 1px solid red;
}

.proposal-form__dropdown {
  border-radius: 40px;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  background: transparent;
}


.proposal-form__textarea {
  border-radius: 20px;
  resize: vertical;
}

.proposal-form__dropdown, .proposal-form__textarea {
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #E4E4E4;

  &:hover, &:focus {
    border: 1px solid $brandColor;
    outline: 1px solid $brandColor;
  }
}

::v-deep .jdgm-select-arrow {
  right: 12px;
  top: 12px;
  color: $brandColor;
}

.proposal-form__cta-buttons {
  height: 50px;
  @include respond-to(mobile) {
    text-align: center;
  }
}

.proposal-form__btn-propose {
  width: 312px;
}
</style>
