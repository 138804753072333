<template>
  <div class="people-filter-by">
    <span class="people-filter-by__label">Show me</span>
    <select @change="selectedFilters($event)" name="filter_by" id="people-filter-by__select" v-model="selectedFilterKey" v-bind:key="selectedFilter">
      <option value="everyone">Everyone</option>
      <option value="im_following">Everyone I Follow</option>
    </select>
    <i class="material-icons people-filter-by__arrow">expand_more</i>
  </div>
</template>

<script>
  export default {
    props: ['selectedFilter'],

    data() {
      return {
        selectedFilterKey: this.selectedFilter || 'everyone'
      }
    },

    computed: {
      isLoggedIn() {
        return window.isReviewerLoggedIn;
      },
    },

    methods: {
      selectedFilters(event) {
        this.$emit('changed', event.target.value)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .people-filter-by {
    position: relative;
    margin: 10px 0;
  }

  .people-filter-by__label {
    margin-right: 8px;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    color: $secondaryFontColor;
  }

  #people-filter-by__select {
    color: $primaryFontColor;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    border: none;
    padding: 4px 30px 4px 10px;
    width: 190px;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;

    &:focus-visible {
      outline: none;
    }

    &::-ms-expand { // for IE only
      display: none;
    }
  }

  .people-filter-by__arrow {
    position: relative;
    right: 30px;
    top: 8px;
    color: $brandColor;
    pointer-events: none;
  }

</style>
