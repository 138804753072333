<template>
  <section class='review-form' ref="reviewForm" id="reviewForm">
    <VueRecaptcha
        ref="recaptcha"
        :loadRecaptchaScript="true"
        badge="bottomright"
        size="invisible"
        @verify="e => captchaCallback(e)"
        :sitekey="googleCaptchaKey" />
    <form class="review-form__form" @submit="submitReview" v-if="!submissionSuccess" :class="{ 'review-form__submitting': isSubmitting }">
      <div class="review-form__header" v-if="!hideFormHeading">Write a review</div>
      <div class="review-form__field">
        <div class="review-form__field-header">Rating</div>
        <StarRatingChooser  :activeColor="'#339999'" :showRating="false" :roundedCorners="true"
                            :borderColor="'#339999'" :borderWidth="2" :inactiveColor="'white'" :starSize="28"
                            name="rating" class="review-form__stars-rating"
                            v-model="formInput.rating" @rating-selected="setRating"/>
      </div>

      <div class="review-form__field">
        <div class="review-form__field-header">Review Title <span v-show="formInput.title.length > 0">({{ formInput.titleMax - formInput.title.length }})</span></div>
        <input v-model="formInput.title"
               :maxlength="formInput.titleMax"
               type="text" name="title" class="review-form__form-input-text border-round"
               id="review-title" placeholder="Give your review a title" />
      </div>

      <div class="review-form__field">
        <div class="review-form__field-header">Review  <span v-show="formInput.body.length > 0">({{ formInput.bodyMax - formInput.body.length }})</span></div>
        <p class="review-form__field-errors" v-if="didRunValidation && formErrors.body">Please write some review</p>
        <textarea v-model="formInput.body"
                  :maxlength="formInput.bodyMax"
                  class="review-form__form-input-text review-form__form-input-textarea border-less-round"
                  name="body" id="review-body" cols="30" rows="10" placeholder="Write your review here">
        </textarea>
      </div>

      <div v-if="resource.settings.allow_images || resource.settings.allow_videos" class="review-form__field">
        <label class="review-form__field-header"> Picture/Video (Optional) </label>
        <div class="review-form__field-upload-container">
          <UploadField
              class="review-form__field-dropzone"
              ref="uploadField"
              :refName="'s3Upload'"
              :options="dropzoneOptions"
              :useCustomSlot=true
              @sending="dropZoneSending"
              @filesAdded="dropZoneFilesAdded"
              @success="dropZoneSuccess"
              @removeFile="dropZoneRemoveFile"
              @thumbnail="dropzoneThumbnail"
          >
            <div id='clickable-upload-icon' class="review-form__field-upload-box" :class="{hide: (this.dropzoneVars.imageRemainder + this.dropzoneVars.videoRemainder) <= 0} ">
              <div class="review-form__field-upload-icon"></div>
            </div>
          </UploadField>
        </div>
      </div>

      <div class="review-form__field">
        <input v-if="reviewMediaEnabled" v-model="formInput.youtube"
               class="review-form__form-input-text border-round"
               type="url" name="youtube" id="youtube-url" placeholder="YouTube URL (optional)"  />
      </div>

      <!--  custom form -->
      <div v-for="el in questions" class="review-form__field">
        <div class="review-form__field-header review-form__field-custom-header">{{el.question}}</div>
        <div  v-if="el.question_type === 'single'" class="review-form__field">
          <p class="review-form__field-errors" v-if="didRunValidation && cfErrors[el.id]">Please select one option</p>
          <div class='review-form__custom-form-input' v-for="(option, key) in el.cf_options" :key="key">
            <input type="radio" :id="`${el.id}-${option.id}`" :value="option.title" v-model="formInput.cf_answers[el.id]"/>
            <label class="review-form__custom-label" :for="`${el.id}-${option.id}`">{{ option.title }}</label>
          </div>
        </div>


        <div  v-else-if="el.question_type === 'slider'" class="review-form__field">
          <p class="review-form__field-errors" v-if="didRunValidation && cfErrors[el.id]">Please select one option</p>
          <input class="review-form__custom-form-slider-input" type="range" :id="`${el.id}`"
                 @change="onChangeSlider" :name="el.id" min="0" max="100">
          <div class="review-form__custom-form-slider-labels">
            <label class="review-form__custom-form-slider-label-left" :for="el.id">{{ el.cf_options[0].title }}</label>
            <label class="review-form__custom-form-slider-label-right" :for="el.id">{{ el.cf_options[1].title }}</label>
          </div>
        </div>

        <div class="review-form__field review-form__field-scale" v-else-if="el.question_type === 'scale'" >
          <p class="review-form__field-errors" v-if="didRunValidation && cfErrors[el.id]">Please select one option</p>
          <div class='review-form__custom-form-input review-form__custom-form-input--scale' v-for="(option, idx) in el.cf_options" :key="idx">
            <input type="radio" :id="`${el.id}-${option.id}`"
                   :value="`${idx + 1}/${el.cf_options.length}`" v-model="formInput.cf_answers[el.id]"/>
            <div class="review-form__custom-form-scale-bar">
              <div class="review-form__custom-form-scale-bar active" :style="{ width: `${idx*100/(el.cf_options.length-1)}%` }"></div>
            </div>
            <label class="review-form__custom-label review-form__custom-label--scale" :for="`${el.id}-${option.id}`">{{ option.title }}</label>
          </div>
        </div >

        <div  v-else-if="el.question_type === 'multiple'" class="review-form__field">
          <p class="review-form__field-errors" v-if="didRunValidation && cfErrors[el.id]">Please select at least one option</p>
          <div class='review-form__custom-form-input' v-for="(option, key) in el.cf_options" :key="key">
            <input type="checkbox" :id="`${el.id}-${option.id}`"
                   :value="option.title" v-model="formInput.cf_answers[el.id]">
            <label class="review-form__custom-label" :for="`${el.id}-${option.id}`">{{ option.title }}</label>
          </div>
        </div>

        <div  v-else-if="el.question_type === 'text'" class="review-form__field">
          <p class="review-form__field-errors" v-if="didRunValidation && cfErrors[el.id]">Please answer the question</p>
          <input v-model="formInput.cf_answers[el.id]"
                 :maxlength="100"
                 type="text" class="review-form__form-input-text border-round"
                 :id="el.id" placeholder="Write your answer here" />
        </div>

      </div>
      <!-- end custom form -->

      <div class="review-form__field">
        <div class="review-form__field-header">Name (displayed publicly like
          <select class="review-form__custom-form-sample-name" v-model="formInput.reviewerNameFormat">
            <option value="">John Smith</option>
            <option value="last_initial">John S.</option>
            <option value="all_initials">J.S</option>
            <option value="anonymous">Anonymous</option>
          </select>)</div>
        <p class="review-form__field-errors" v-if="didRunValidation && formErrors.name">Please enter your display name</p>
        <input v-model="formInput.name" v-if="!isReviewerLoggedIn"
               :maxlength="formInput.titleMax"
               type="text" name="name" class="review-form__form-input-text border-round"
               placeholder="Enter your name (public)" />
      </div>

      <div class="review-form__field" v-if="!isReviewerLoggedIn">
        <div class="review-form__field-header">Email</div>
        <p class="review-form__field-errors" v-if="didRunValidation && formErrors.email">Please enter your email</p>
        <input v-model="formInput.email" required
               type="email" name="email" class="review-form__form-input-text border-round"
               placeholder="Enter your email (private)" />
      </div>

      <div class="review-form__footer">
        <div class="review-form__field review-form__field--published" v-if="isReviewerLoggedIn">
          <div class="review-form__form-input-checkbox" @click="formInput.published = !formInput.published"
               :class="[formInput.published && 'review-form__form-input-checkbox--checked']">
            <span class="review-form__form-input-checked-icon material-icons" v-show="formInput.published">check</span>
          </div>
          <label class="review-form__custom-label" for="published">Publish to your profile</label>
        </div>
        <div class="review-form__buttons">
          <div @click="$emit('closeForm')" class='btn pf-primary-button review-form__cta-buttons review-form__cancel-review'>Cancel Review</div>
          <input type="submit" :disabled="isSubmitting" class='btn pf-secondary-button review-form__cta-buttons review-form__submit-review' value="Submit Review">
        </div>
      </div>

    </form>
    <LoadingSpinner v-if='isSubmitting'/>
    <div class="review-form__submitted" v-if="submissionSuccess">
      <p class="review-form__submitted-notification material-icons"> check_circle</p>
      <p class="review-form__submitted-notification"> Review Submitted! </p>
      <slot name="success">
          <p>Thank you! Please refresh the page in a few moments to see your review. You can remove or edit your review on your profile page.</p>
      </slot>
    </div>
  </section>
</template>

<script>
  import originalAxios from 'axios'
  import axios from 'shared/utils/axios';
  import VueRecaptcha  from 'vue-recaptcha';
  import StarRatingChooser from 'vue-star-rating'
  import LoadingSpinner from 'rs/components/common/loading_spinner'
  import UploadField from 'rs/components/pages/product/dropzone_upload'
  import { getThumbnailForVideo, removeSpecialCharactersFileName }  from 'shared/utils/upload_media_files';

  const IMAGE_MAX_SIZE = 10 * 1024 * 1024 // Byte
  const VIDEO_MAX_SIZE = 100 * 1024 * 1024 // Byte
  const GUEST_PUBLIC_REVIEW_API = '/api/v1/reviews'
  const REVIEWER_PUBLIC_REVIEW_API = '/profile/reviews/public_resource'
  const LINE_ITEM_REVIEW_API = '/profile/reviews'

  export default {
    components: { VueRecaptcha, LoadingSpinner, StarRatingChooser, UploadField },
    props: {
      resource: { default: null },
      resourceType: {default: 'Product'},
      lineItemId: { default: 0 },
      hideFormHeading: { default: false, type: Boolean },
    },
    data() {
      return {
        isReviewerLoggedIn: window.isReviewerLoggedIn,
        googleCaptchaKey: window.VUE_CONSTANTS.google_recaptcha_key,
        formInput: {
          rating: 5,
          title: '',
          titleMax: 100,
          body: '',
          bodyMax: 5000,
          youtube: '',
          email: '',
          name: '',
          cf_answers: {},
          media_keys:[],
          reviewerNameFormat: '',
          published: true,
        },
        cfErrors: { s3Upload: false }, // { keyID: bool }
        formErrors: {
          body: true,
          name: !window.isReviewerLoggedIn,    // no need to check if logged_in
          email: !window.isReviewerLoggedIn,    // no need to check if logged_in
        },
        submissionSuccess: false,
        isSubmitting: false,
        didRunValidation: false,
        reviewMediaEnabled: this.resource.settings.allow_images,
        questions: this.resource.cf_questions,
        dropzoneOptions: {
          url: files => files[0].formData.url,
          thumbnailWidth: 100,
          thumbnailHeight: 100,
          parallelUploads: 3, // upload 3 files at a time.
          autoProcessQueue: false,
          addRemoveLinks: true,
          autoQueue: true,
          acceptedFiles: this.acceptedFiles(),
          clickable: '#clickable-upload-icon'
        },
        dropzoneVars: {
          imageRemainder: 5,
          videoRemainder: 1,
          imagePresignData: null,
          videoPresignData: null,
        }
      }
    },
    computed: {
      productParams(){
        if (this.resourceType !== 'Product') return {};
        return {
          id: this.resource.external_id,
          handle: this.resource.handle,
        }
      },
      lineItemParams(){
        return this.lineItemId ? { line_item_public_id: this.lineItemId } : {}
      },
      shopParams() {
        if (this.resourceType === 'Product') {
          return {
            platform: this.resource.platform,
            shop_domain: this.resource.shop_domain,
            url: this.resource.shop_domain
          }
        }
        return {
          platform: this.resource.platform,
          shop_domain: this.resource.domain,
          url: this.resource.domain
        }
      },
    },
    created(){
      this.resource.cf_questions.forEach(e => {
        if(e.question_type === 'multiple') {
          this.formInput.cf_answers[e.id] = []
        }
        this.cfErrors[e.id] = e.required;
      })
    },
    methods: {
      acceptedFiles(){
        const images = "image/gif,image/jpeg,image/jpg,image/png,image/webp,"
        const videos = "video/mov,video/mp4,video/x-m4v,video/*"
        return (this.resource.settings.allow_images ? images : "") + (this.resource.settings.allow_videos ? videos : "")
      },
      // customize for video, add video icon and video thumbnail (https://github.com/heimrichhannot/dropzone/blob/947ee35d231adb698f65b077b30144b2b7c099d7/src/dropzone.coffee#L414)
      async dropzoneThumbnail(file, dataUrl) {
        if (file.previewElement) {
          file.previewElement.classList.remove("dz-file-preview");
          // use for loop (instead of .forEach()) to run async
          for (let thumbnailElement of file.previewElement.querySelectorAll(
              "[data-dz-thumbnail]"
          )) {
            thumbnailElement.alt = file.name;
            if (file.type.startsWith('image')) {
              thumbnailElement.src = dataUrl;
            } else {
              const fileUrl = URL.createObjectURL(file);
              thumbnailElement.src = await getThumbnailForVideo(fileUrl);
              const videoIconNode = document.createElement('div')
              videoIconNode.classList.add('jdm-video-icon')
              file.previewElement.appendChild(videoIconNode)
            }
          }

          return setTimeout(
              () => file.previewElement.classList.add("dz-image-preview"),
              1
          );
        }
      },
      dropZoneRemoveFile(file, error, xhr){
        this.formInput.media_keys = this.formInput.media_keys.filter(el => el != file.key)
        // After remove file,  increase the remainder
        if (file.type.startsWith('image')) {
          this.dropzoneVars.imageRemainder += 1
        } else {
          this.dropzoneVars.videoRemainder += 1
        }
        this.$refs.uploadField.$refs.s3Upload.setOption('clickable', '#clickable-upload-icon')
      },

      dropZoneSuccess(file, response){
        this.formInput.media_keys = [...this.formInput.media_keys, file.key]
      },

      dropZoneSending(file, xhr, formData) {
        Object.keys(file.formData.fields).forEach(key => {
          formData.append(key, file.formData.fields[key])
        })
        const key = file.formData.key_prefix + removeSpecialCharactersFileName(file.name) // save key name for other hooks
        file.formData.key = key;
        file.key = key;
        // why `file.key` ? => Vue cannot detect deeply nested change of `key` in `file.formData.key`
        // for files which user selects in same batch in other hooks
        // issue: values of `file.formData.key` for files in same batch are the same.
        formData.append('key', file.formData.key)
      },

      // Func dropZoneFilesAdded works with adding both single/multiple files.
      // Func dropZoneSending does not support async => need to attach presignData to each file here before sending
      // files here is object { '0': file, '1': file, length: 2 }
      async dropZoneFilesAdded(files){
        let didAlertImage, didAlertVideo, didAlertSize;  // check alert
        let firstImage, firstVideo; // temp var for first valid file of each type
        const addedFiles = Array.from(files)

        addedFiles.forEach(file => {
          // reduce counter first because removeFile() is async
          if (file.type.startsWith('image'))
            this.dropzoneVars.imageRemainder -= 1;
          else
            this.dropzoneVars.videoRemainder -= 1;

          // check oversize
          if (this.isOverSize(file.type, file.size)) {
            if (!didAlertSize) {
              alert("Sorry, you can upload only images < 10MB and videos < 100MB")
              didAlertSize = true
            }
            file.willRemove = true;
          }

          // check image count
          else if (file.type.startsWith('image') && this.dropzoneVars.imageRemainder < 0) {
            if (!didAlertImage) {
              alert("Sorry, you can upload up to 5 pictures for one review")
              didAlertImage = true
            }
            file.willRemove = true;
          }

          // check video count
          else if (file.type.startsWith('video') && this.dropzoneVars.videoRemainder < 0) {
            if (!didAlertVideo) {
              alert("Sorry, you can upload only 1 video for one review")
              didAlertVideo = true
            }
            file.willRemove = true;
          }
          // Remove file if marked as isRemoved
          // Else Find first available file of each type to get presigned metadata
          if (!file.willRemove) {
            if (!firstImage && file.type.startsWith('image'))
              firstImage = file
            if (!firstVideo && file.type.startsWith('video'))
              firstVideo = file
          }

          // add thumbnail for valid video
          if (!file.willRemove && file.type.startsWith('video')) {
            this.$refs.uploadField.$refs.s3Upload.$emit('vdropzone-thumbnail', file)
          }
        })

        // get presigned meta data before upload  (only get once for each type of file)
        const [imagePresignData, videoPresignData] = await Promise.all([this.getPresignMeta(firstImage), this.getPresignMeta(firstVideo)]);
        this.dropzoneVars = { ...this.dropzoneVars, imagePresignData, videoPresignData } // async. Store PresignData as states to avoid unnecessary fetch

        // hack to fix case: drag n drop:
        // - event hook drag-drop doesn't invoke
        // - removeFile() seems to not remove file (even though it does increment the remainder
        // - solution: remove file here instead of inside above loop
        addedFiles.forEach(file =>{
          if (file.willRemove) {
            this.$refs.uploadField.$refs.s3Upload.removeFile(file)
          } else {
            file.formData = file.type.startsWith('image') ? imagePresignData : videoPresignData
          }
        })
        this.$refs.uploadField.$refs.s3Upload.processQueue()
      },

      async getPresignMeta(file){ // fetch presign data for each type of file, if already exists in state, use state.
        if (!file) return null
        if (file.type.startsWith('image') && this.dropzoneVars.imagePresignData) return this.dropzoneVars.imagePresignData
        if (file.type.startsWith('video') && this.dropzoneVars.videoPresignData) return this.dropzoneVars.videoPresignData

        const presignUrl = `/api/v1/${file.type.startsWith('image') ? 'pictures' : 'videos'}/presigned_data`
        const data =  await originalAxios.get(presignUrl, { params: this.shopParams })
        return data.data
      },

      isOverSize: (type, size) => type.startsWith('image') ? size > IMAGE_MAX_SIZE : size > VIDEO_MAX_SIZE,


      onChangeSlider(e){
        this.formInput.cf_answers[e.target.name] = e.target.value;
      },

      setRating($event) {
        this.rating = $event
      },

      validateCF(){
        // validate multiple
        this.didRunValidation = true;
        this.resource.cf_questions.forEach(e => {
          if (e.question_type === 'multiple') {
            if (this.formInput.cf_answers[e.id][0] && this.formInput.cf_answers[e.id][0].length > 0) {
              this.cfErrors[e.id] = false
            }
          } else {
            if (this.formInput.cf_answers[e.id] !== undefined && this.formInput.cf_answers[e.id] !== null && this.formInput.cf_answers[e.id] !== "") { //  explicit, avoid using !! since there could be answer with value '0'
              this.cfErrors[e.id] = false
            }
          }
        })
        this.cfErrors = {...this.cfErrors } // force Vue to re-render error text after user click submit
        Object.keys(this.formErrors).forEach(e => {
          if (this.formInput[e] !== '') {
            this.formErrors[e] = false
          }
        })
        this.formErrors = {...this.formErrors } // force Vue to re-render error text after user click submit
        return Object.keys(this.cfErrors).every(e=> !this.cfErrors[e]) && Object.keys(this.formErrors).every(e=> !this.formErrors[e])
      },

      submitReview(e) {
        e.preventDefault();
        if (this.validateCF()) {
          this.$refs.recaptcha.execute();
        } else {  // scroll up to form
          this.$refs.reviewForm.scrollIntoView({block: 'start', behavior: 'smooth' })
        }
      },

      captchaCallback(key){
        this.sendRequest(key)
      },

      parseCfAnswers(answers){
        return this.questions.reduce((result, el) => {
          if (el.id in answers) {
            if (el.question_type === 'multiple') {
              const val = answers[el.id].join(", ")
              result[el.id] = { cf_question_id: el.id, value: [val] }
            } else if (el.question_type === 'slider') {
              result[el.id] = { cf_question_id: el.id, slider_text: el.cf_options.map(opt => opt.title), value: answers[el.id] }
            } else {
              result[el.id] = { cf_question_id: el.id, value: answers[el.id] }
            }
          }
          return result
        }, {})
      },

      parseUploadedMediaKeys(mediaKeys){ // split mediaKeys array to 2 hashes picture_keys and video_keys
        return mediaKeys.reduce((acc, el) => {
          if (el.startsWith('photos')) {
            return { ...acc, picture_keys: { ...acc.picture_keys, [Object.keys(acc.picture_keys).length]: el }}
          } else {
            return { ...acc, video_keys: { ...acc.video_keys, [Object.keys(acc.video_keys).length]: el }}
          }
        }, { picture_keys: {}, video_keys: {} })
      },

      prepareReviewData() {
        return {
          ...this.shopParams,
          ...this.parseUploadedMediaKeys(this.formInput.media_keys),
          ...this.productParams,
          ...this.lineItemParams,
          source: 'review-site',
          rating: String(this.formInput.rating),
          title: this.formInput.title,
          body: this.formInput.body,
          name: this.formInput.name,
          email: this.formInput.email,
          reviewer_name_format: this.formInput.reviewerNameFormat,
          rs_published: this.isReviewerLoggedIn && this.formInput.published,
          cf_answers: this.parseCfAnswers(this.formInput.cf_answers),
          media_platform_videos: this.getVideoId(this.formInput.youtube) ? {
            0: {
              platform: "yt",
              url: this.getVideoId(this.formInput.youtube),
            }
          } : null,
        }
      },

      sendRequest(captchaKey) {
        const reviewData = { ...this.prepareReviewData(), recaptcha_token: captchaKey }
        this.isSubmitting = true
        const path = this.lineItemId ? LINE_ITEM_REVIEW_API : (window.isReviewerLoggedIn ? REVIEWER_PUBLIC_REVIEW_API : GUEST_PUBLIC_REVIEW_API)

        axios
            .post(path, reviewData)
            .then(() => {
              this.submissionSuccess = true
            })
            .catch((error)=>{
              console.log(error)
              this.submissionSuccess = false
            })
        this.isSubmitting = false
        this.$emit('doneSubmit')
      },

      isURLValid(inputUrl) {
        return this.isLongUrl(inputUrl) || this.isShortUrl(inputUrl);
      },

      getVideoId(url) {
        if (!url) {
          return;
        }
        if (this.isLongUrl(url)) {
          return this.idFromLongVideo(url);
        } else if (this.isShortUrl(url)) {
          let parts = url.split('?')[0].split('/');
          return parts.slice(- 1).pop();
        }
      },

      isLongUrl(url) {
        return (/https:\/\/(www\.|m.)*youtube\.com\/watch\?v=.+/).test(url);
      },

      isShortUrl(url) {
        return (/^https:\/\/youtu.be\/.*$/).test(url);
      },

      idFromLongVideo(url) {
        const params = new URL(url).searchParams;
        return params.get('v') || null;
      },
    },
    watch: {
      "formInput.reviewerNameFormat"(v) {
        if (v === "anonymous") {
          this.formInput.published = false;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  $desktopMinInputWidth: 540px;

  .review-form {
    padding: 32px 0 32px 0;
    display: flex;
    justify-content: center;
  }
  .review-form__header {
    font-size: 20px;
    font-weight: $fontBold;
  }
  .review-form__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 26px;
    width: 100%;
    min-width: 100%;
    @include respond-to(notmobile) {
      min-width: $desktopMinInputWidth;
    }
  }
  .review-form__field {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    @include respond-to(notmobile) {
      width: fit-content;
    }
  }
  .review-form__field-errors {
    text-align: center;
    color: red;
  }
  .review-form__field-scale {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  .review-form__field-header {
    font-weight: $fontNormal;
    text-align: center;
    margin-bottom: 12px;
    &:first-letter {
      text-transform: capitalize;
    }
  }
  .review-form__field-custom-header {
    color: $brandColor;
    font-weight: $fontBold;
  }
  .review-form__custom-label:first-letter {
    text-transform: capitalize;
  }
  .review-form__custom-label--scale {
    @include respond-to(mobile) {
      flex: 1 1 auto;
    }
  }
  .review-form__stars-rating {
    justify-content: center;
  }
  .review-form__form-input-text {
    min-height: 50px;
    width: 100%;
    min-width: 100%;
    @include respond-to(notmobile) {
      min-width: $desktopMinInputWidth;
    }
    resize: none;
    border: 1px solid $borderColor;
    padding-left: 24px;
    &:focus {
      outline-color: $brandColor;
    }
  }
  .review-form__form-input-textarea {
    padding-top: 24px;
    width: 100%;
    min-width: 100%;

    @include respond-to(notmobile) {
      min-width: $desktopMinInputWidth;
    }
  }
  .border-round {
    border-radius: 40px;
  }
  .border-less-round {
    border-radius: 20px;
  }
  .review-form__custom-form-input {
    gap: 12px;
    line-height: 22px;
    display: flex;
    align-items: center;
    input[type='radio'],input[type='checkbox'] {
      margin: 0;
      accent-color: $brandColor;
    }
  }

  .review-form__custom-form-input--scale {
    row-gap: 4px;
    @include respond-to(mobile) {
      flex-wrap: wrap;
    }
  }
  .review-form__custom-form-sample-name,
  .review-form__custom-form-sample-name:focus,
  .review-form__custom-form-sample-name:active {
    border: none;
    outline: none;
    color: $brandColor;
    text-decoration: underline;

    -webkit-appearance: menulist;
    /*webkit browsers */
    -moz-appearance: menulist;
  }
  .review-form__buttons {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
    max-width: 320px;
    @include respond-to(notmobile) {
      max-width: 100%;
      width: $desktopMinInputWidth;
      height: 50px;
      flex-direction: row;
      gap: 16px;
    }

    @include respond-to(mobile) {
      align-self: center;
    }
  }
  .review-form__cta-buttons {
    width: 100%;
    font-size: 16px;
  }
  .review-form__cancel-review {
    @include respond-to(notmobile) {
      max-width: 180px;
    }
  }
  .review-form__submit-review {
    @include respond-to(notmobile) {
      max-width: 344px;
    }
  }
  .review-form__submitted-notification {
    font-size: 24px;
    font-weight: bold;
  }

  .review-form__custom-form-slider-input {
    padding: 0;
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 5px;
    background: #D6EBEB;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;

    min-width: 100%;
    @include respond-to(notmobile) {
      min-width: $desktopMinInputWidth;
    }
  }
  /*-webkit- Chrome, Opera, Safari, Edge*/
  .review-form__custom-form-slider-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #D6EBEB;
    cursor: pointer;
  }
  /*-moz-*/
  .review-form__custom-form-slider-input::-moz-range-thumb {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #D6EBEB;
    cursor: pointer;
  }
  .review-form__custom-form-slider-labels {
    width: 100%;
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
  }
  .review-form__custom-form-scale-bar {
    background: #D6EBEB;
    border-radius: 2px;
    height: 12px;
    width: 270px;
    &.active {
      background: $brandColor;
    }

    @include respond-to(mobile) {
      flex: 1 1 90%;
    }
  }
  .review-form__submitted {
    display: flex;
    color: $brandColor;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;
  }
  .review-form__field-upload-container {
    position: relative;
    width: 100%;
    height: fit-content;
    border-radius: 20px;
  }
  .review-form__field-upload-box  {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    height: 100px;
    width: 100px;
    border: solid 1px $borderColor;
  }
  .review-form__field-upload-box .hide {
    display: none
  }
  .review-form__field-upload-icon {
    font-family: "JudgemeIcons";
    color: $textSecondaryColor;
    font-size: 50px;
    position: absolute;
    opacity: 0.5;
    &:before {
      content: "\e023";
    }
  }
  .review-form__submitting { display: none; }

  .review-form__field-dropzone {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border: none;
    height: fit-content;
    padding: 0;
    gap: 8px;
    background: transparent;

    .review-form__field-upload-text {
      opacity: 0.5;
    }
  }

  .review-form__footer {
    display: flex;
    flex-direction: column;
    @include respond-to(mobile) {
      width: 100%;
    }
  }
  .review-form__field--published {
    width: 100%;
    border-top: 1px solid #E0E0E0;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 18px 0 22px 0;
    @include respond-to(mobile) {
      justify-content: center;
    }

    label {
      font-size: 14px;
      font-weight: 400;
      color: $primaryFontColor;
    }
  }

  .review-form__form-input-checkbox {
    border-radius: 5px;
    border: 1px solid $newBrandColor;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .review-form__form-input-checkbox--checked {
    background-color: $newBrandColor;
  }

  .review-form__form-input-checked-icon {
    font-size: 18px;
    color: white
  }
</style>

<style lang="scss">
  @import '~PlatformStyles/abstract/variables_new';

  .review-form__field-dropzone.vue-dropzone.dz-started .dz-message { display: block; margin: 0 } // disable default behavior (hide upload icon) when there is file added
  .review-form__field-dropzone.vue-dropzone .dz-preview { position: relative; margin: 0; width: 100px; }
  .review-form__field-dropzone.vue-dropzone .dz-preview .dz-image,
  .review-form__field-dropzone.vue-dropzone .dz-preview.dz-file-preview .dz-image { width: 100px; height: 100px; border-radius: 0; }
  .review-form__field-dropzone.vue-dropzone .dz-details { display: none; }
  .review-form__field-dropzone.vue-dropzone .dz-preview .dz-remove { // hack to hide default text and display trash-bin icon
    visibility: hidden;
    z-index: 1;
    opacity: 1;
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 2px;
    font-family: "JudgemeIcons";
    cursor: pointer;
    border-radius: 20px;

    &:after  {
      border-radius: 20px;
      position: absolute;
      z-index: 1;
      top: 2px;
      right: 2px;
      background: white;
      color: gray;
      font-size: 16px;
      line-height: 16px;
      padding: 4px 4px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
      visibility: visible;
      content: "\e021";
    }

    &:hover:after {
      visibility: visible;
      content: "\e021";
      color: white;
      background: $brandColor;
    }
  };

  .review-form__field-dropzone.vue-dropzone .dz-preview .jdm-video-icon {
    font-family: "JudgemeIcons";
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #333333;
    opacity: 0.85;
    color: white;
    height: 20px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    border-top-right-radius: 5px;
    &:after {
      content: '\e013';
    }
  }
</style>
