import {setState, setMeta} from 'rs/store/store_utils/shared'
import axios from 'shared/utils/axios';
import {curry} from 'lodash'
import {pipeAsync} from "shared/utils/js_core";

const defaultState = {
  unReviewedLineItems: [],
  reviewedLineItems: [],
  lineItems: [],
  productData: null,
  lineItemId: null,
  isLoading: true,
}

const state = {...defaultState}

const mutations = {
  setState: (state, [name, value]) => state[name] = value,
  clearAllStates: (state) => {
    Object.keys(defaultState).forEach(key => {
      state[key] = defaultState[key]
    })
  }
}

const getters = {
  unReviewedLineItems: state => state.unReviewedLineItems,
  lineItemId: state => state.lineItemId,
  isLoading: state => !!state.isLoading,
  productData: state => state.productData,
}

const actions = {
  async fetchOrders({commit, dispatch}) {
    commit('clearAllStates')
    await pipeAsync(
      _fetchOrders(setMeta(dispatch)),
      _lineItems,
      setState(commit, 'lineItems'),
      _breakDownLineItems,
      hash => {
        setState(commit, 'unReviewedLineItems')(hash.unreviewed);
        setState(commit, 'reviewedLineItems')(hash.reviewed);
      },
      setState(commit, 'isLoading')
    )()
  },

  async prepareReviewForm({state, commit, dispatch}, lineItemPublicId) {
    setState(commit, 'productData')(null)
    await pipeAsync(
      _fetchReviewForm(setMeta(dispatch)),
      setState(commit, 'productData'),
      () => setState(commit, 'lineItemId')(lineItemPublicId),
    )(lineItemPublicId)
  },

}

const _fetchReviewForm = dispatchSetMeta => async lineItemPublicId => {
  try {
    const res = await axios.get('/write-review/new.json', {params: {line_item_public_id: lineItemPublicId}})
    dispatchSetMeta(res.data.meta_data)
    return res.data.product_data
  } catch (err) {
    throw err
  }
}

const _fetchOrders = dispatchSetMeta => async () => {
  try {
    const res = await axios.get('/write-review.json?resource=orders')
    dispatchSetMeta(res.data.meta_data)
    return res.data.data
  } catch (err) {
    return []
  }
}

const _lineItems = orders => orders.reduce(_extractLineItemsFromOrders, [])
const _extractLineItemsFromOrders = (result, order) => order.line_items.reduce(curry(_enrichLineItems)(order), result)
const _breakDownLineItems = allItems => allItems.reduce(_filterLineItems, {reviewed: [], unreviewed: []})
const _enrichLineItems = (order, accumulatedItemsPerOrder, item) => {
  return accumulatedItemsPerOrder.concat({
    productImageUrl: item.product_image_url,
    productTitle: item.product_title,
    placedAt: order.placed_at,
    productPath: item.product_internal_path,
    inStore: item.product_in_store,
    reviewable: item.product_reviewable,
    publicId: item.public_id,
    reviewPublicId: item.review_public_id,
  })
}
const _filterLineItems = (acc, item) => {
  if (item.reviewPublicId)
    acc.reviewed.push(item)
  else
    acc.unreviewed.push(item)
  return acc
}


export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,

  namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
}
