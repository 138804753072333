<template functional>
  <div class="meganav__body-column" v-if="props.isImage" :class="[props.border && 'meganav__body-column--border']">
    <slot></slot>
  </div>
  <div class="meganav__body-column" v-else>
    <div class="meganav__cell" v-for="(level3, idx) in props.categories" v-if="props.checker(idx)">
      <router-link :to="props.categoryPath(level3)">{{ level3.name }}</router-link>
      <div class="meganav__cell--child" v-for="level4 in level3.children">
        <router-link :to="props.categoryPath(level4)">{{ level4.name }}</router-link>
      </div>
    </div>
    <div class="meganav__cell">
      <router-link v-if="props.categoryPath(props.parentCategory)" :to="props.categoryPath(props.parentCategory, true)">
        All {{ props.parentCategory.name }}
        <iconify icon="ph:arrow-right-bold" class="meganav__cell-chevron-icon"/>
      </router-link>
    </div>
  </div>
</template>
<script>

export default {
  functional: true,
  props: ['isImage', 'categories', 'categoryPath', 'checker', 'parentCategory', 'border']
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.meganav__body-column {
  flex: 0 1 33.3%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-wrap: wrap;
  padding: 20px 20px 40px 20px;
}
.meganav__body-column--border {
  border-left: 1px solid #CFE1E1;
}
.meganav__cell {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 700;
}

.meganav__cell-chevron-icon {
  margin-left: 12px;
  height: 16px;
  width: 16px;
  vertical-align: middle;
  color: $brandColor;
}

.meganav__cell--child {
  margin-left: 14px;
  font-weight: 400;
}

.meganav__cell > a,
.meganav__cell--child > a {
  color: $primaryFontColor;
  text-decoration: none;

  &:hover {
    color: $brandColor;
  }
}

</style>
