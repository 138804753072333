<template>
  <TooltipPopover label="Verified company details" v-if="verifiedFields">
    <template #icon v-if="$slots.icon">
      <slot name="icon"></slot>
    </template>
    <template #tooltip>
      <div class="shop-verified__tooltip-content">
        <p>As a trusted third-party website, we understand the importance of ensuring the credibility and legitimacy of
          the companies listed on our platform.</p>
        <p>To get the verified company badge, a store needs to confirm any of the following info:</p>
        <div class="shop-verified__fields-wrapper">
          <div class="d-flex gap-1" v-for="(item) in verifiedFields">
            <CircleIcon/>
            {{ item }}
          </div>
        </div>
      </div>
    </template>
  </TooltipPopover>
</template>
<script>
import CircleIcon from 'rs/components/common/icons/custom_circle_icon';
import TooltipPopover from 'rs/components/common/tooltip_popover'

export default {
  components: {CircleIcon, TooltipPopover},
  props: ['label', 'tooltip', 'verifiedFields'],
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';

.shop-verified__tooltip-content {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @include respond-to(desktop) {
    margin-top: 12px;
  }
}

.shop-verified__fields-wrapper {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 8px;
  @include respond-to(desktop) {
    flex-direction: row;
  }
}
</style>
