<template>
  <div class='review-images'>
    <div v-if="mainPicUrls">
      <div v-if="mainPicUrls.dataType === 'picture'" @click="openGallery(mainPicUrls.id, mainPicUrls.review_uuid)">
        <div class='review-images__picture review-images__picture--main' :class="{ 'review-images__main-large-size': largeSize }">
          <LazyImage
              :className="'review-images__picture-img'"
              :alt="'User picture'"
              :urls='{ normal: mainPicUrls.huge, retina: mainPicUrls.huge }' />
        </div>
      </div>

      <div v-else class="review-images__picture review-images__picture--main" :class="{ 'review-images__main-large-size': largeSize }" @click="openGallery(mainPicUrls.id, mainPicUrls.review_uuid)" >
        <div class="transparent-layer main"></div>
        <Video
            :className="['media-player', mainPicUrls.source === 'vimeo' ? 'media-player__vimeo': '']"
            :dataType="mainPicUrls.dataType"
            :mediaPlatformName="mainPicUrls.media_platform_name"
            :mediaPlatformUrl="mainPicUrls.media_platform_url"
            :source="mainPicUrls.source"
            :videoId="mainPicUrls.videoId"
            :option="null" />
      </div>

      <div v-if='media.length > 1' class='review-images__thumbnails-container' :class="{ 'review-images__thumbnails-container-large': largeSize}">
        <div class='review-images__picture' :class="{ 'review-images__picture-large': largeSize}" v-for="(urls, index) in media.slice(0,5)" v-bind:key="index" @click="openGallery(urls.id, urls.review_uuid)">
          <div v-if="index !== selectedIndex && urls.dataType !== 'picture'">
            <div class="transparent-layer thumbnail"></div>
            <Video
                :className="{ 'media-player__thumbnail': true, 'review-images__picture-image-large': largeSize }"
                :dataType="urls.dataType"
                :mediaPlatformName="urls.media_platform_name"
                :mediaPlatformUrl="urls.media_platform_url"
                :source="urls.source"
                :videoId="urls.videoId"
                :option="null" />
          </div>
          <LazyImage
              v-if="index !== selectedIndex && urls.dataType === 'picture'"
              :className="{'review-images__picture-img': true, 'review-images__picture-image-large': largeSize }"
              :alt="'User picture'"
              :urls='{ normal: urls.compact, retina: urls.compact }' />
        </div>
        <div v-if="media.length > 5" class="review-images__see-more" @click="openGallery(media[5].id, media[5].review_uuid)"> See more</div>
      </div>
    </div>
  </div>
</template>

<script>
  import LazyImage from 'rs/components/common/lazy_image'
  import Video from 'rs/components/common/review_video'

  export default {
    components: { LazyImage, Video },
    props: ['media', 'encodedProductId', 'id', 'largeSize'],

    data() {
      return {
        selectedIndex: 0,
      }
    },

    computed: {
      mainPicUrls() {
        return this.media.length > 0 ? this.media[this.selectedIndex] : null
      },
    },
    methods: {
      openGallery(id, review_uuid) {
        this.$emit('openGallery', {
          forwardedMediaId: id,
          review_uuid: review_uuid,
          encodedId: this.encodedProductId,
          sourceType: 'product'
        })
      },
    }
  }
</script>


<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  $_picThumbnailHeightSmall: 48px;
  $_picThumbnailWidthSmall: 48px;
  $_picThumbnailHeightLarge: 64px;
  $_picThumbnailWidthLarge: 64px;

  $_picMainHeightSmall: 200px;
  $_picMainWidthSmall: 312px;
  $_picMainHeightLarge: 300px;
  $_picMainWidthLarge: 600px;
  $_picMainHeightLargeDesktop: 380px;


  .transparent-layer {
    position: absolute;
    border-radius: 5px;
    top: 0;
    left: 0;
    background:rgba(255,255,255, 0);
  }

  .transparent-layer.main {
    width: 100%;
    height: 100%;
  }

  .review-images {
    margin-top: 12px;
  }

  .review-video {
    border-radius: 5px;
  }

  .media-player {
    border-radius: 5px;
  }

  .review-images__picture-img {
    display: block;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
    margin: 0;
    padding: 0;
  }

  .review-images__thumbnails-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 4px;
    height: $_picThumbnailHeightSmall;
    margin-top: 8px;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .review-images__thumbnails-container-large {
    height: $_picThumbnailHeightSmall;
    @include respond-to(desktop) {
      height: $_picThumbnailHeightLarge;
    }
  }

  // Styles for the main big image
  .review-images__picture--main {
    position: relative;
    display: block;
    max-width: $_picMainWidthSmall;
    height: $_picMainHeightSmall;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    .review-images__picture-img {
      width: auto;
      max-width: 100%;
    }

    @include respond-to(notmobile) {
      width: 100%;
      max-height: $_picMainHeightSmall;
    }
  }

  .review-images__main-large-size {
    height: $_picMainHeightLarge;
    max-height: $_picMainHeightLarge;
    max-width: $_picMainWidthLarge;
    @include respond-to(desktop) {
      height: $_picMainHeightLargeDesktop;
      max-height: $_picMainHeightLargeDesktop;
    }
  }

  .review-images__thumbnails-large-size {
    height: $_picThumbnailHeightSmall;
    width: $_picThumbnailWidthSmall;
    @include respond-to(desktop) {
      height: $_picThumbnailHeightLarge;
      width: $_picThumbnailWidthLarge;
    }
  }

  // Styles for the below thumbnail images
  .review-images__picture:not(.review-images__picture--main) {
    position: relative;
    height: $_picThumbnailHeightSmall;
    cursor: pointer;
    .review-images__picture-img {
      width: $_picThumbnailWidthSmall;
    }
    .review-images__picture-image-large {
      height: $_picThumbnailHeightSmall;
      width: $_picThumbnailWidthSmall;
      @include respond-to(desktop) {
        height: $_picThumbnailHeightLarge;
        width: $_picThumbnailWidthLarge;
      }
    }
  }

  .review-images__see-more {
    margin-left: 10px;
    width: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-decoration: underline;
    cursor: pointer;
    font-size: 13px;
    color: $secondaryFontColor;
  }

  .review-images__rating {
    margin: 16px 0 12px 0;
  }

  .review-images__title {
    font-weight: bold;
  }

  .review-images__body {
    margin-top: 12px;
  }
</style>
<style lang="scss">
@import '~PlatformStyles/abstract/mixins';
$_picMainHeightLarge: 300px;
$_picMainWidthLarge: 600px;
$_picMainHeightLargeDesktop: 380px;

.media-player__vimeo {
  @include respond-to(notdesktop) {
    height: $_picMainHeightLarge;
    width: $_picMainWidthLarge;
  }
  @include respond-to(desktop) {
    height: $_picMainHeightLargeDesktop;
    width: $_picMainWidthLarge;
  }
}
.media-player__vimeo > div:first-child{
  iframe {
    border-radius: 5px;
    height: $_picMainHeightLarge  !important;
    width: $_picMainWidthLarge;

    @include respond-to(desktop) {
      height: $_picMainHeightLargeDesktop !important;
      width: $_picMainWidthLarge;
    }
  }
}
</style>
