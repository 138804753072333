<template>
  <section class="profile-favorite-widget">
    <div class="pfw__header">
      <div class="pfw__header-text">{{ favouriteWidgetData.headerText }}</div>
      <div class="pfw__header-icon svg-sprite pf-sprite-icon white-heart-icon"></div>
    </div>
    <div class="pfw__item-wrapper">
      <div class="svg-sprite pf-sprite-icon pf-sprite-icon--small user-pin-icon"></div>
      <div class="pfw__title">{{favouriteWidgetData.reviewerText}}</div>
    </div>
    <div class="pfw__item-wrapper">
      <span class="svg-sprite pf-sprite-icon pf-sprite-icon--small bag-icon"></span>
      <div class="pfw__title">{{favouriteWidgetData.productText}}</div>
    </div>
    <div class="pfw__item-wrapper">
      <span class="svg-sprite pf-sprite-icon pf-sprite-icon--small store-icon"></span>
      <div class="pfw__title">{{favouriteWidgetData.storeText}}</div>
    </div>
    <router-link v-if="storeName === 'PrivateProfile'" class="btn pf-primary-button main-button" :to=$routes.following.path>Manage</router-link>
  </section>
</template>
<script>
export default {
  computed: {
    storeName() {
      return this.$store.getters['Profile/storeName']
    },
    favouriteWidgetData() {
      return this.$store.getters[this.storeName + '/favouriteWidgetData'];
    },
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';

.profile-favorite-widget {
  background-color: white;
  border: $newBorderStyle;
  border-radius: 12px;
  min-width: 300px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.pfw__header {
  font-size: 16px;
  margin-bottom: 8px;
  display: flex;
  gap: 8px;
  justify-content: space-between;
}
.pfw__header-text {
  width: 100%;
}
.pfw__header-icon {
  flex: 0 1 28px;
}
.pfw__item-wrapper {
  display: flex;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  background-color: #F0F5F5;
  border: $newBorderStyle;
}
.pfw__title {
  font-size: 14px;
  font-weight: 600;
  color: $primaryFontColor;
}
.main-button {
  margin: 8px 0;
}
</style>
