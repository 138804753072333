<template>
  <section class="product-info">
    <div class="product-info__image-wrapper" :class="{'product-info__image-wrapper--no-image': !validImage}">
      <img @click="openGallery" class="product-info__image" v-if="validImage" @error="validImage=false" v-bind:src="validImage" :alt="`${productData.title} main image`" />
      <div v-else class="product-info__no-logo-wrapper">
        <div class="product-info__no-logo material-icons">wallpaper</div>
      </div>
      <HeartBtn class='product-info__heart-icon' :followeeClass="'Product'" :followeeEncodedId="productData.encoded_id" />
    </div>
    <div class="product-info__data">
      <ExternalLink @click="trackExternalClick" class='product-info__title' :href='productData.external_url' :text="productData.title"/>
      <div class="product-info__rating" @click="$emit('navigateToReviews')">
        <StarRating :rating='reviewData.average_rating'/>
        <div>{{$sharedUtils.pluralize(reviewData.number_of_reviews, 'review')}}</div>
      </div>
      <div v-if="displayPriceRange">
        <p class="product-info__price">From {{ productData.lowest_price }} - {{ productData.highest_price }}</p>
        <p v-if="hasValidConvertedPriceRange" class="product-info__price--converted product-info__price--converted-new-line">(Approx. {{ convertedLowestPrice }} - {{ convertedHighestPrice }})</p>
      </div>
      <div v-else>
        <p class="product-info__price">
          {{ productData.price }}
          <span v-if="hasValidConvertedPrice" class="product-info__price--converted">(Approx. {{ convertedPrice }})</span>
        </p>
      </div>
      <ExternalLink @click="trackExternalClick" class='btn pf-secondary-button product-info__shop-product-button' :href='productData.external_url' :text="'View Product in Store'"/>
      <p class="product-info__shop-country">Shipped from
        <CountryFlag :alt="'country flag for '+ countryCode" :countryCode="countryCode" class="product-info__shop-country-flag"/>
        by <router-link class="product-info__shop-name" :to="shopData.internal_path">{{shopData.name}}</router-link>
        <ShopVerifiedTooltip :verifiedFields="verifiedFields">
          <template #icon><CircleIcon icon="check" /></template>
        </ShopVerifiedTooltip>
      </p>
    </div>
  </section>
</template>

<script>
  import StarRating from 'rs/components/common/star_rating'
  import ExternalLink from 'rs/components/common/external_link'
  import HeartBtn from 'rs/components/common/heart_btn'
  import CountryFlag from 'rs/components/common/country_flag'
  import ShopVerifiedTooltip from 'rs/components/pages/shop/others/_shop_verified_tooltip';
  import CircleIcon from 'rs/components/common/icons/custom_circle_icon';
  import {verifiedFields} from "rs/components/pages/shop/helpers.js";
  import {mapState} from "vuex";

  export default {
    props: ['productData', 'reviewData', 'shopData'],
    components: { StarRating, ExternalLink, HeartBtn, CountryFlag, ShopVerifiedTooltip, CircleIcon },
    computed: {
      ...mapState('SharedCurrentReviewer', ['currency', 'exchangeRates']),
      displayPriceRange(){
        return (this.productData.lowest_price !== this.productData.highest_price) && !!this.productData.highest_price
      },
      verifiedFields() {
        return verifiedFields(this.shopData)
      },
      countryCode() {
        return this.shopData.country
      },
      convertedLowestPrice() {
        return this.convertPrice(this.productData.raw_lowest_price)
      },
      convertedHighestPrice() {
        return this.convertPrice(this.productData.raw_highest_price)
      },
      hasValidConvertedPriceRange(){
        return this.currency !== this.productData.currency && this.convertedLowestPrice !== null && this.convertedHighestPrice !== null;
      },
      convertedPrice() {
        return this.convertPrice(this.productData.raw_price)
      },
      hasValidConvertedPrice() {
        return this.currency !== this.productData.currency && this.convertedPrice !== null;
      }
    },
    data() {
      return {
        validImage: this.productData.image_url,
      }
    },
    methods: {
      convertPrice(price) {
        return this.$sharedUtils.convertCurrency(this.currency, this.productData.currency, price, this.exchangeRates)
      },
      openGallery() {
        this.$store.dispatch('Gallery/openGallery', {
          forwardedMediaId: 'product',
          encodedId: this.productData.encoded_id,
          sourceType: 'product'
        })
      },
      trackExternalClick(){
        this.$store.dispatch('Product/trackExternalClick')
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  $baseHeight: 208px;
  .product-info {
    height: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @include respond-to(desktop) {
      padding: 30px 30px 16px 30px;
      flex-direction: row;
    }
  }
  .product-info__image-wrapper {
    border-radius: 10px;
    position: relative;
    width: 100%;
    text-align: center;
    @include respond-to(desktop) {
      height: $baseHeight;
      width: $baseHeight;
    }
  }
  .product-info__image-wrapper--no-image {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    background: linear-gradient(180deg, #A1DFDF 0%, #88C6D3 100%);
  }
  .product-info__image {
    border-radius: 10px;
    height: 100%;
    max-height: 390px;
    width: 100%;
    max-width: 390px;
    object-fit: cover;
    cursor: pointer;
    @include respond-to(desktop) {
      width: $baseHeight;
    }
  }
  .product-info__no-logo-wrapper {
    background: #C1E6E6;
    border-radius: 50%;
    width: 80%;
    height: 80%;
    position: absolute;
    z-index: 0;
  }
  .product-info__no-logo {
    @include middle-align-child;
    font-size: 60px;
    color: #8AC4D1;
  }
  .product-info__heart-icon {
    position: absolute;
    top: 8px;
    right: 8px;

    @include respond-to(notmobile) {
      top: 16px;
      right: 16px;
    }
  }
  .product-info__data {
    margin: 14px 0 20px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
    height: 100%;
    row-gap: 8px;
    @include respond-to(desktop) {
      margin: 0 0 0 20px;
      justify-content: flex-start;
    }
  }
  .product-info__shop-name {
    font-size: 14px;
    color: $newBrandColor;
  }
  .product-info__title {
    font-weight: $fontBold;
    width: 100%;
    font-size: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-decoration: none;
    color: $primaryFontColor;
  }
  .product-info__rating {
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
  }

  .product-info__price {
    font-weight: 700;
    color: $primaryFontColor;
    font-size: 14px;
  }
  .product-info__price--converted {
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    color: $secondaryFontColor;
  }
  .product-info__price--converted-new-line {
    margin-top: -8px;
  }
  .product-info__shop-country {
    margin-top: 4px;
    font-size: 14px;
    display: flex;
    gap: 4px;
    align-items: center;
  }
  .product-info__shop-country-flag {
    height: 12px;
    margin: 0 8px;
  }

  .product-info__shop-product-button {
    margin-top: 4px;
    align-self: center;
    height: 50px;
    line-height: 1.8;
    width: 100%;
    background-color: $newBrandColor;
    border: solid 2px $newBrandColor;
    @include respond-to(desktop) {
      width: 260px;
      align-self: flex-start;
      margin-top: auto;
      height: 40px;
      line-height: 1;
    }
  }
</style>
