<template>
  <modal-wrapper @close="closeModal">
    <div class="address-modal-container">
      <iconify icon="iconamoon:close" width="28" class="am__close-icon" @click="closeModal"
               data-closable="true"/>
      <div class="am__inner-container">
        <div class="am__text--header">All store addresses</div>
        <div class="am__addresses-wrapper">
          <AddressDetail v-for="(address, index) in addresses" class="am__address-box" :key="index" :address="normalizeAddress(address)" :showTitle="showTitle(index)"/>
        </div>
      </div>
      <div class="am__close-footer">
        <button class="btn pf-secondary-button" @click="closeModal" data-closable="true">Close</button>
      </div>
    </div>
  </modal-wrapper>
</template>
<script>
import AddressDetail from 'rs/components/pages/shop/others/_address_detail'
import {normalizeAddress} from 'rs/components/pages/shop/helpers.js'

export default {
  components: {AddressDetail},
  props: ['addresses'],

  methods: {
    showTitle(idx) {
      return idx === 0 && this.addresses[idx].billing_address
    },
    closeModal(e) {
      e.target.dataset.closable === 'true' && this.$emit('close')
    },
    normalizeAddress,
  },
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.address-modal-container {
  padding: 20px 16px 0 16px;
}

.am__close-icon {
  color: $brandColor;
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
  @include respond-to(mobile) {
    right: 16px;
  }
}

.am__inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-weight: 400;
  width: 360px;
  color: $primaryFontColor;
  @include respond-to(mobile) {
    width: 100%;
  }

  > * {
    width: 100%;
  }
}

.am__header-icon {
  width: 80px;
  height: 80px;
  @include respond-to(mobile) {
    width: 64px;
    height: 64px;
  }
}

.am__text--header {
  text-align: center;
  font-weight: 700;
  line-height: 24px;
  font-size: 20px;
  @include respond-to(mobile) {
    font-size: 16px;
  }
}

.am__addresses-wrapper {
  width: fit-content;
  max-width: 400px;
  height: 260px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px 0;
}

.am__addresses-wrapper::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

::v-deep .am__address-box {
  min-width: 311px;
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid #E0F2F2;
  background: #F7FCFC;
}

.am__close-footer {
  display: flex;
  border-top: 1px solid #E0F2F2;
  justify-content: center;
  align-items: center;
  padding: 18px 0 24px 0;
  width: calc(100% + 32px);

  & > button {
    width: 260px;
  }
}
</style>
