<template>
  <section class="homepage-carousel">
    <div class="image-slider">
      <transition-group name="fade" tag="div">
        <div
          class="image-slider-wrapper"
          v-for="number in [currentNumber]"
          :key="number"
        >
          <img class="image-slider-item" :src="currentImage" />
          <div
            class="image-slider-nav"
            v-for="(image, idx) in images"
            :key="idx"
            :class="'image-slider-nav' + idx"
          >
            <span class="current-slide" :class="{ active: activeSlide == idx }"
              >.</span
            >
          </div>
        </div>
      </transition-group>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      images: [
        "https://pub-images.judge.me/judgeme/marketplace/homepage/carousel-1",
        "https://pub-images.judge.me/judgeme/marketplace/homepage/carousel-2",
        "https://pub-images.judge.me/judgeme/marketplace/homepage/carousel-3",
        "https://pub-images.judge.me/judgeme/marketplace/homepage/carousel-4",
        "https://pub-images.judge.me/judgeme/marketplace/homepage/carousel-5",
      ],
      currentNumber: 0,
      timer: null,
    };
  },
  mounted() {
    this.startRotation();
  },
  methods: {
    startRotation() {
      this.timer = setInterval(this.next, 4000);
    },
    next() {
      this.currentNumber += 1;
    },
  },
  computed: {
    currentImage() {
      return this.images[Math.abs(this.currentNumber) % this.images.length];
    },
    activeSlide() {
      return Math.abs(this.currentNumber) % this.images.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~PlatformStyles/abstract/mixins";
.image-slider {
  position: relative;
  display: block;
  height: 440px;
  display: block;
  width: 100vw;
  position: absolute;
  z-index: -1;
  top: -16px;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  @include respond-to(mobile) {
    height: 320px;
    margin-top: -50px;
  }
  .image-slider-nav {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    z-index: 2;
    font-size: 100px;
    bottom: -70px;
    font-family: '-webkit-body';
  }
  .image-slider-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    @include respond-to(mobile) {
      top: 68px;
    }
  }
  .image-slider-item {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.8s ease;
  overflow: hidden;
  visibility: visible;
  opacity: 1;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  visibility: hidden;
}

.image-slider-nav.image-slider-nav0 {
  margin-left: -40px;
}
.image-slider-nav.image-slider-nav1 {
  margin-left: -20px;
}
.image-slider-nav.image-slider-nav2 {
}
.image-slider-nav.image-slider-nav3 {
  margin-left: 20px;
}
.image-slider-nav.image-slider-nav4 {
  margin-left: 40px;
}

.current-slide {
  opacity: 0.5;
}
.current-slide.active {
  opacity: 1;
}
</style>
