<template>
  <WidgetWrapper v-if="hasContent">
    <component v-bind="componentProps" :hasWrapper=true @hasContent="setHasContent"/>
  </WidgetWrapper>
</template>
<script>
import WidgetWrapper from 'rs/components/common/wrappers/widget_wrapper'
import PolicyCard from 'rs/components/pages/shop/tabs/_card_policy'
import PaymentMethodsCard from 'rs/components/pages/shop/tabs/_card_payment_methods'

export default {
  components: {WidgetWrapper},
  props: ['widgetType'],
  data() {
    return {
      hasContent: true,
    }
  },
  methods: {
    setHasContent(hasContent) {
      this.hasContent = hasContent
    },
  },
  computed: {
    componentProps() {
      switch (this.widgetType) {
        case 'shipping_policy':
        case 'refund_policy':
          return {
            is: PolicyCard,
            policyName: this.widgetType,
          }
        case 'payment_methods':
          return {
            is: PaymentMethodsCard,
          }
        default:
          return {is: 'div'}
      }
    },
  }
}
</script>
