<template>
  <div>
    <div ref="sideNav" class="bm-menu" :class="{'bm-menu-open': isSideBarOpen}" @click="closeMenu">
      <nav ref="bmItemList" class="bm-item-list">

        <div class="bm-item bm-user-item">
          <UserProfile @expanded="handleUserMenuExpand"/>
        </div>
        <div v-for="item in userMenuItems" :key="item.text" class="bm-item bm-user-item"
             :class="{'bm-item--highlight': currentPath(item.path)}" @click="closeMenu">
          <router-link :is="item.nonVue ? 'a' : 'router-link'" :to="{path: item.path}" :href="item.path">{{ item.text }}</router-link>
        </div>

        <div v-for="item in menuItems" :key="item.text" class="bm-item"
             :class="{'bm-item--highlight': currentPath(item.path)}" @click="closeMenu">
          <router-link :is="item.nonVue ? 'a' : 'router-link'" :to="{path: item.path}" :href="item.path">{{ item.text }}</router-link>
        </div>

        <div class="bm-item-currency">
          <div class="bm-item-currency__title">Show prices in</div>
          <CurrencyDropdown class="bm-item-currency__dropdown" showFlag @afterChange="closeMenu"/>
        </div>

      </nav>
    </div>

    <div v-if="!isSideBarOpen" ref="bmBurgerButton" class="bm-burger-button" @click.stop="openMenu"
         :class="{ hidden: !burgerIcon }">
      <span class="bm-burger-bars line-style" :style="{top:20 * (index * 2) + '%'}" v-for="(x, index) in 3"
            :key="index"></span>
    </div>
    <div v-else ref="bmCloseButton" class="bm-burger-button cross-style" @click="closeMenu" :class="{ hidden: !crossIcon }">
      <span class="bm-cross"></span>
      <span class="bm-cross bm-cross-rotate"></span>
    </div>

  </div>
</template>

<script>
import UserProfile from 'rs/components/layouts/menus/user_profile';
import CurrencyDropdown from 'rs/components/common/currency_dropdown';

export default {
  components: {UserProfile, CurrencyDropdown},
  data() {
    return {
      isSideBarOpen: false,
    };
  },
  props: {
    menuItems: {
      type: Array,
      default: []
    },
    userMenuItems: {
      type: Array,
      default: []
    },
    isOpen: {
      type: Boolean,
      required: false
    },
    noOverlay: {
      type: Boolean,
      required: false
    },
    onStateChange: {
      type: Function,
      required: false
    },
    burgerIcon: {
      type: Boolean,
      required: false,
      default: true
    },
    crossIcon: {
      type: Boolean,
      required: false,
      default: true
    },
    disableOutsideClick: {
      type: Boolean,
      required: false,
      default: false
    },
    closeOnNavigation: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    currentPath(path) {
      if (path === '/') return;
      return this.$route.matched.some(route => route.path.includes(path)) // check subroutes
    },
    openMenu() {
      this.$emit('openMenu');
      this.isSideBarOpen = true;
      if (!this.noOverlay) {
        document.body.classList.add('bm-overlay');
        document.body.classList.add('should-lock-scroll');
      }
    },
    closeMenu(e) {
      // close mobile menu when clicking on empty space, closebutton, or links
      if (!e || (this.$refs.sideNav === e.target) || (this.$refs.bmCloseButton === e.target.parentElement) || (e.target.tagName === 'A') ) {
        this.$emit('closeMenu');
        this.isSideBarOpen = false;
        document.body.classList.remove('bm-overlay');
        document.body.classList.remove('should-lock-scroll');
      }
    },
    documentClick(e) {
      let element = this.$refs.bmBurgerButton;
      let target = null;
      if (e && e.target) {
        target = e.target;
      }
      if (
          element &&
          element !== target &&
          !element.contains(target) &&
          !this.hasClass(target, 'bm-menu') &&
          this.isSideBarOpen &&
          !this.disableOutsideClick
      ) {
        this.closeMenu();
      } else if (
          element &&
          this.hasClass(target, 'bm-menu') &&
          this.isSideBarOpen &&
          this.closeOnNavigation
      ) {
        this.closeMenu();
      }
    },
    hasClass(element, className) {
      do {
        if (element.classList && element.classList.contains(className)) {
          return true;
        }
        element = element.parentNode;
      } while (element);
      return false;
    },
    handleUserMenuExpand(isExpanded){
      this.$emit('expandMenu', isExpanded)
    },
  },
  created: function () {
    document.addEventListener('click', this.documentClick);
  },
  destroyed: function () {
    document.removeEventListener('click', this.documentClick);
  },
  watch: {
    isOpen: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        this.$nextTick(() => {
          if (!oldValue && newValue) {
            this.openMenu();
          }
          if (oldValue && !newValue) {
            this.closeMenu();
          }
        });
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

$_header_height: 70px;
.bm-burger-button {
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  right: 12px;
  width: 21px;
  top: 3px;
  height: 15px;
  cursor: pointer;
  transform: translateY(-9px);
}

.bm-burger-button.hidden {
  display: none;
}

.bm-burger-bars {
  background-color: $newBrandColor;
}

.line-style {
  position: absolute;
  border-radius: 1px;
  height: 20%;
  left: 0;
  right: 0;
}

.cross-style {
  position: absolute;
  top: 0;
  right: 4px;
  cursor: pointer;
}

.bm-cross {
  background: $newBrandColor;
  position: absolute;
  width: 3px;
  height: 20px;
  transform: rotate(-45deg)
}

.bm-cross-rotate {
  transform: rotate(45deg)
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross-button.hidden {
  display: none;
}

.bm-menu {
  width: 0; /* 0 width - change this with JavaScript */
  height: 100dvh;
  position: fixed; /* Stay in place */
  z-index: 10; /* Stay on top */
  top: calc(#{$_header_height} - 1px);
  border-top: solid 1px $feedCardDividerColor;
  left: auto;
  right: 0;
  background: white;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.25s; /*0.5 second transition effect to slide in the sidenav*/
}

.bm-menu-open {
  height: 100dvh;
  width: calc(100% + 1px);
}

.bm-item-list {
  @include respond-to(mobile) {
    margin-bottom: 120px;
  }
}

.bm-item {
  height: 74px;
  line-height: 74px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px $feedCardDividerColor;

  &:last-child {
    border-bottom: none;
  }
}
.bm-user-item {
  background-color: #F4FAFA;
}
.bm-user-item > a {
  padding-left: 36px;
}
.bm-item > * {
  text-decoration: none;
  padding: 0 16px;
  font-weight: 700;
  font-size: 16px;
  color: black;
  width: 100%;
  border-top: solid 1px $feedCardDividerColor;
}
.bm-item--highlight {
  display: none;
}
.bm-item-currency {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: solid 1px #E0F2F2;
  padding: 24px 16px;
  width: 100%;
  color: $primaryFontColor;
  font-size: 14px;
  font-weight: 400;
  gap: 12px;
}

</style>

<style lang="scss">
.bm-item-currency__dropdown {
  .currency-dropdown__select {
    z-index: 1;
  }
  .currency-dropdown__select-arrow {
    z-index: 0;
  }
}

</style>
