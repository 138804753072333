<template>
  <div class="message-wrapper" :class="['message-wrapper--'+senderPoV]">
    <div class="message-container">
      <div class="message-box"
           :class="[
             'message-box__color--'+ message.sender.participant_role,
             'message-box--' + message.sender.participant_role,
             message.proposal ? 'message-box--proposal' : '']">
        <FileMessage v-if="isFilesMessage" :message="message" :senderPoV="senderPoV"/>
        <ArbitratorResolutionMessage v-else-if="isArbitratorResolutionMessage" :message="message"/>
        <SystemMessage v-else-if="message.is_system" :message="message" :senderPoV="senderPoV"
                       @proposalCallback="updateProposalCallback"/>
        <template v-else>
          <div class="message__name">{{ replierText }}</div>
          <div>{{ message.text }}</div>
        </template>
      </div>
      <div class="message__sent">{{ sentAt }}</div>
    </div>
  </div>
</template>
<script>
import {customFormat} from 'shared/utils/timeago';
import SystemMessage from 'shared/vue_components/arbitration/components/_system_message.vue'
import FileMessage from 'shared/vue_components/arbitration/components/_file_message.vue'
import ArbitratorResolutionMessage from 'shared/vue_components/arbitration/components/_resolution_arbitrator_card.vue'

export default {
  props: ['senderPoV', 'message'],
  // senderPoV is message's sender point of view, it is either 'first' (current user), 'second' (opposed user), 'third' (arbitrator)
  components: {SystemMessage, FileMessage, ArbitratorResolutionMessage},
  inject: ['dispute'],
  computed: {
    sentAt() {
      const convertedTime = customFormat(this.message.created_at)()
      if (convertedTime.endsWith('ago'))
        return 'Sent ' + convertedTime
      return 'Sent on ' + convertedTime
    },
    replierText() {
      switch (this.senderPoV) {
        case 'first':
          return 'Your reply'
        case 'second':
          return this.message.sender.participant_role === 'buyer' ? 'Buyer\'s reply' : this.message.sender.name + '\'s reply'
        default:
          return this.message.sender.name + ' - Arbitrator'
      }
    },
    isFilesMessage() {
      return this.message.is_system && this.message.files
    },
    isArbitratorResolutionMessage() {
      return this.message.sender.participant_role === 'arbitrator' && this.message.text == 'create_resolution' && this.dispute.resolution
    }
  },
  methods: {
    updateProposalCallback(accepted) {
      if (accepted) {
        this.message.proposal.status = ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

$buyerColor: #CEF7F7;
$sellerColor: #CEEFF7;
$arbitratorColor: #CEF7D0;

.message-wrapper {
  width: 100%;
  color: $primaryFontColor;

  .message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }

  .message-box {
    padding: 12px 16px;
    border-radius: 12px;
    max-width: 540px;
    width: 100%;
  }

  .message-box--proposal {
    padding: 24px;
  }


  .message__sent {
    align-self: flex-end;
    color: $secondaryFontColor;
    font-size: 12px;
  }
}

.message-wrapper--first {
  .message-container {
    align-items: flex-end;
  }

  .message-box {
    border-bottom-right-radius: 0;
  }
}

.message-wrapper--second {
  .message-container {
    align-items: flex-start;
  }

  .message-box {
    border-bottom-left-radius: 0;
  }

  .message__sent {
    align-self: flex-start;
  }
}

.message-wrapper--third {
  .message-box {
    width: 100%;
  }
  .message-box--arbitrator {
    max-width: 100%;
  }
}

.message-box__color--buyer {
  background-color: $buyerColor;
}

.message-box__color--seller {
  background-color: $sellerColor;
}

.message-box__color--arbitrator {
  background-color: $arbitratorColor;
}

::v-deep .message__name {
  font-weight: 700;
  line-height: 24px;

  &:first-letter {
    text-transform: capitalize;
  }
}

</style>
