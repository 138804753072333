
export const productParams = (payload) => {
  return {
      platform: payload.platform,
      shop_domain: payload.shopDomain,
      api_token: payload.publicToken,
      external_id: payload.productExternalId,
      add_header_counts: payload.addHeaderCounts,
      page: payload.page || 1,
      filter_rating: payload.filter_rating,
      sort_by: payload.sort_by || 'pictures_first',
      sort_dir: payload.sort_dir,
      product_metrics: payload.productMetrics,
      json_request: 'true',
      per_page: 10,
      search: payload.search,
      review_type: 'product-reviews',
      review_source: payload.reviewSource || 'all_sources',
      verified_only: payload.verified_only || false,
  }
}

export const shopParams = payload => ({
  platform: payload.platform,
  shop_domain: payload.shopDomain,
  api_token: payload.publicToken,
  add_header_counts: payload.addHeaderCounts,
  page: payload.page || 1,
  filter_rating: payload.filter_rating,
  sort_by: payload.sort_by || 'pictures_first',
  sort_dir: payload.sort_dir,
  json_request: 'true',
  per_page: 10,
  search: payload.search,
  review_type: payload.reviewType || 'all-reviews',
  review_source: payload.reviewSource || 'all_sources',
  verified_only: payload.verified_only || false,
})
