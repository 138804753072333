<template>
  <div class="arbitrator-show-page">
    <div class="arbitrator__container">
      <div class="arbitrator__card-section arbitrator__dashboard-card">
        <div class="arbitrator__dashboard-card-header">{{ data.dashboardHeader }}</div>
        <div>{{ data.dashboardBody }}</div>
      </div>
      <loading-spinner v-if="!currentReviewer"/>
      <template v-else>
        <div class="arbitrator__card-section">
          <div>Arbitrator: <strong>{{ currentReviewer.name }}</strong></div>
        </div>
        <div class="arbitrator__card-section arbitrator__dispute-card">
          <div class="arbitrator__dispute-card-header">{{data.disputeSectionHeader}}</div>
          <div class="arbitrator__dispute-card-description">{{data.disputeSectionDescription}}</div>
          <div class="arbitrator__dispute-list">
            <DisputeList :disputes="disputes"/>
          </div>
        </div>
      </template>

    </div>
  </div>
</template>
<script>
import DisputeList from 'rs/components/pages/arbitrator/_dispute_list.vue'
import actions from 'rs/components/pages/arbitrator/arbitrator_actions.js'
const data = {
  dashboardHeader: 'Arbitrator dashboard',
  dashboardBody: 'Please use our messaging system so we can keep track of all your conversations with the store and buyer, ensuring transparent conversations for enhanced accountability and efficient support.',
  disputeSectionHeader: 'All disputes assigned to me',
  disputeSectionDescription: 'Reply, manage and check the status of open disputes assigned to you.',
}
export default {
  components: {DisputeList},
  provide: {arbitrationContext: actions},
  computed: {
    data: () => data,
    currentReviewer() {
      return this.$store.getters['SharedCurrentReviewer/currentReviewer']
    },
    disputes() {
      return this.$store.getters['Arbitrator/disputes']
    },
    currentPage() {
      return this.$store.getters['Arbitrator/page']
    },
    totalDisputes() {
      return this.$store.getters['Arbitrator/total']
    },
  },
  created() {
    this.$store.dispatch('Arbitrator/initPageIndex', {page: this.$route.query.page})
  }
}

</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';

.arbitrator-show-page {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  color: $primaryFontColor;
}

.arbitrator__container {
  margin: 40px auto;
  width: 715px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}


.arbitrator__card-section {
  border: 1px solid #E7E7E7;
  border-radius: 12px;
  width: 100%;
  padding: 28px;
  background: white;
}

.arbitrator__dashboard-card {
  display: flex;
  padding: 44px 64px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
}

.arbitrator__dashboard-card-header {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5em;
}
.arbitrator__dispute-card {
  padding-left: 0;
  padding-right: 0;
}
.arbitrator__dispute-card-header {
  padding: 0 28px;
  font-weight: 700;
  font-size: 20px;
}
.arbitrator__dispute-card-description {
  padding: 0 28px;
  margin: 12px 0 24px 0;
}
</style>
