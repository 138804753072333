<template>
  <div class="gallery-review">
    <div class='gallery-review__header'>
      <UserAvatar :avatar_image_url="reviewer.avatar_image_url" :reviewer_initial='reviewer.reviewer_initial'
                  :gravatar_hash='reviewer.gravatar_hash' :verified_buyer='reviewer.verified_buyer' :class="'gallery-review__header-avatar'" />
      <div class='gallery-review__reviewer-info'>
        <div class="gallery-review__review-info">
          <StarRating :rating='review.rating' class='gallery-review__rating' />
          <div class='gallery-review__timestamp'>
            {{ createdAtRelative }}
          </div>
        </div>
        <div>
          <a class='gallery-review__reviewer-name' :href='reviewer.reviewer_public_slug'>{{ reviewer.reviewer_name }}</a>
          <VerifiedBadge v-if='review.verified_purchase' />
          <div class='gallery-review__location'>
            {{ reviewer.location_country }}
            <CountryFlag v-if='reviewer.location_country_code' class='gallery-review__location-flag' :alt='reviewer.location_country' :countryCode='reviewer.location_country_code' />
          </div>
        </div>
      </div>
    </div>
    <div class="gallery-review__item">
      <p>
        Review of <component v-bind="productLinkObj">{{ productLinkObj.text }}</component>
      </p>
    </div>

    <strong class='gallery-review__title' v-if="review.title_html" v-html="review.title_html"></strong>
    <strong class='gallery-review__title' v-else v-html="reviewTitle"></strong>
    <div v-html='review.body_html' class='gallery-review__body'></div>
  </div>
</template>

<script>
  import ExternalLink from 'rs/components/common/external_link'
  import UserAvatar from 'rs/components/common/user_avatar'
  import StarRating from 'rs/components/common/star_rating'
  import VerifiedBadge from 'rs/components/common/verified_badge'
  import CountryFlag from 'rs/components/common/country_flag'
  import {unescape} from 'lodash'
  import {customFormat} from 'shared/utils/timeago';

  export default {
    components: {
      ExternalLink,
      UserAvatar,
      StarRating,
      VerifiedBadge,
      CountryFlag,
    },
    props: ['review'],
    computed: {
      createdAtRelative() { return customFormat(this.review.created_at)() },
      isLoggedIn() { return window.isReviewerLoggedIn },
      reviewTitle() {
        return unescape(this.review.title)
      },
      productLinkObj() {
        return {
          href: this.review.product_url_with_utm,
          text: this.review.product_in_store ? this.review.product_title : `${this.review.product_title} (out of store)`,
          is: this.review.product_in_store ? 'external-link' : 'span'
        }
      },
      reviewer() {
        const reviewer = this.$store.getters['Profile/reviewerData']
        const isProfilePage = this.$route.name === this.$routes.publicProfile.name || this.$route.name === this.$routes.privateProfile.name
        const {
          reviewer_public_slug,
          avatar_image_url,
          gravatar_hash,
          reviewer_initial,
          verified_buyer,
          reviewer_name,
          location_country,
          location_country_code
        } = this.review
        if (reviewer && isProfilePage) {
          return {
            avatar_image_url: reviewer.avatar_image_url,
            reviewer_initial: reviewer.name.toUpperCase(),
            verified_buyer: reviewer.verified_purchase,
            reviewer_name: reviewer.name,
          }
        } else return {
          reviewer_public_slug,
          avatar_image_url,
          gravatar_hash,
          reviewer_initial,
          verified_buyer,
          reviewer_name,
          location_country,
          location_country_code
        }
      }
    },
  }
</script>
<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  $_lightColor: #E0E0E0;
  $_containerWidthDesktop: 400px;
  $_containerHeight: calc(100vh - 452px);

  .gallery-review {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 16px;
    background-color: white;
    font-size: $primaryFontSize;
    font-weight: 400;
    @include respond-to(desktop) {
      width: 30%;
      max-width: $_containerWidthDesktop;
      height: 100%;
    }
  }

  .gallery-review__header {
    width: 100%;
    display: flex;
    column-gap: 8px;
    margin: 8px 0 8px 0;
  }

  .gallery-review__header-avatar {
    width: 56px; // avatar will have width 47.0x , dunno why 9px is cut off
    height: 48px;
    line-height: 48px;
  }

  .gallery-review__reviewer-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 4px;
  }

  .gallery-review__review-info {
    display: flex;
    column-gap: 4px;
    margin-bottom: -4px;
  }

  .gallery-review__item {
    margin: 8px 0 8px 0;
    display: flex;
    flex-wrap: wrap;
  }

  .gallery-review__reviewer-name {
    vertical-align: middle;
  }

  .gallery-review__location {
    font-size: 12px;
    color: $secondaryFontColor;
    margin-top: 4px;
  }

  .gallery-review__location-flag {
    display: inline-block;
    height: 8px;
    max-width: 12px;
    vertical-align: middle;
  }

  .gallery-review__timestamp {
    float: right;
    color: $secondaryFontColor;
  }

  .gallery-review__rating {
    margin: 0 0 8px 0;
  }

  .gallery-review__title {
    font-weight: bold;
    margin-top: 8px;
  }

  .gallery-review__body {
    margin: 12px -12px 0 0;
    padding-right: 4px;
    @include respond-to(desktop) {
      overflow-y: auto;
    }
  }

  ::-webkit-scrollbar {
    border-radius: 4px;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(136, 136, 136, 0.49);
  }

  ::-webkit-scrollbar-thumb:hover {
    border-radius: 4px;
    background: #555;
  }

</style>
