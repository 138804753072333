<template>
  <div class="material-icons d-flex-center round-button" @click="e => $emit('click', e)">{{ icon }}</div>
</template>
<script>
export default {
  props: ['icon']
}
</script>
<style scoped>
.round-button {
  border: transparent;
  background: white;
  cursor: pointer;
  border-radius: 50%;
  margin: auto;
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.round-button:hover {
  background: buttonface;
}

.round-button:active {
  border: 2px solid buttonface;
  background: buttonface;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}
</style>
