import axios from 'shared/utils/axios';
import {extractFolloweeIdsSearchResults} from 'rs/store/store_utils/search_utils'
import {fetchHomeData, CAROUSEL_TYPES} from 'rs/store/store_utils/home_utils'
import { setState } from 'rs/store/store_utils/shared'

const state = {
  transparencyCarousel: {},
  homeMetrics: {
    total_verified_reviews: '...',
    shop_metric: '...',
    line_item_metric: '...',
    review_metric: '...',
    verified_buyer_metric: '...'
  },
  collectionsData: null,
  featuredReviewsData: null,
}

const getters = {
  homeMetrics: state => state.homeMetrics,
  collectionsData: state => state.collectionsData,
  featuredReviewsData: state => state.featuredReviewsData,
  transparencyCarousel: state => state.transparencyCarousel,
  transparencyProducts: state => state.transparencyCarousel.items,
  transparencyHeading: state => state.transparencyCarousel.external_title,
  transparencyDescription: state => state.transparencyCarousel.external_description,
}

const mutations = {
  setState: (state, [key, data]) => state[key] = data
}

const actions = {
  async getFullTransparentProducts({commit, dispatch}) {
    const data = await fetchHomeData(CAROUSEL_TYPES.product)
    setState(commit, 'transparencyCarousel')(data)
    const followeeIdsToCheck = extractFolloweeIdsSearchResults(data.items)
    dispatch('SharedFollow/fetchFolloweeIds', followeeIdsToCheck, {root: true})
  },

  async getHomeMetrics({commit}) {
    const res = await axios.get('/reviews.json');
    setState(commit, 'homeMetrics')(res.data.metrics);
  },

  getCollections({commit, state}) {
    if (state.collectionsData) return;
    fetchHomeData(CAROUSEL_TYPES.collection).then(setState(commit, 'collectionsData'))
  },
  
  getFeaturedReviews({commit, state}) {
    if (state.featuredReviewsData) return;
    fetchHomeData(CAROUSEL_TYPES.featured_review).then(setState(commit, 'featuredReviewsData'))
  }
}


export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,

  namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
}
