<template>
  <div class="sidebar-categories">
    <ItemLine
        :item="{ id: null, name: 'All' }"
        @select="handleSelect"
        class="sidebar-categories__item--top-level">
    </ItemLine>
    <div v-for="(itemID,idx) in categoriesLevelOneIDs">
      <ItemLine
          class="sidebar-categories__item"
          :item="categories[itemID]"
          :key="itemID"
          @select="handleSelect"
      >
      </ItemLine>
    </div>
  </div>
</template>

<script>
import ItemLine from 'rs/components/pages/search/_sidebar_category_item_line';
import {mapState} from "vuex";

export default {
  components: {ItemLine},
  computed: {
    ...mapState('Search', ['categories', 'categoriesLevelOneIDs', 'searchParams']),
  },
  methods: {
    handleSelect() {
      this.$store.dispatch('Search/navigateToSearchPageWithQuery')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';

.sidebar-categories {
  color: $brandColor;
}

.sidebar-categories__item--top-level {
  padding-left: 0;
}
</style>
