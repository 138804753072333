<template functional>
  <div v-if="props.address" class="address-detail" :class="data.staticClass">
    <template v-if="props.showTitle">
      <div v-if="props.address.isBillingAddress" class="address-detail__subtitle">Billing address</div>
      <div v-else class="address-detail__subtitle address-detail__subtitle--placeholder">&nbsp;</div>
    </template>
    <div>{{ props.address.addressLine1 }}</div>
    <div>{{ props.address.addressLine2 }}</div>
    <div>{{ props.address.city }}</div>
    <div>
      {{ props.address.countryName }}
      <span v-if="props.address.postcode"> - {{ props.address.postcode }}</span>
    </div>
  </div>

</template>
<script>
export default {
  props: {
    address: {
      type: Object,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.address-detail {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.address-detail__subtitle {
  color: $secondaryFontColor;
  margin-bottom: 8px;
  @include respond-to(mobile) {
    font-size: 14px;
  }
}
.address-detail__subtitle--placeholder {
  @include respond-to(mobile) {
    display: none;
  }
}
</style>
