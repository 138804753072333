<template>
  <div class="search-navbar-wrapper">
    <hr class="search-navbar-divider" v-if="divider">
    <div class='search-navbar__transparent-layer' :class="{'search-navbar__transparent-layer--focused': inputFocus}">
      <div class="search-navbar">
        <div v-show="tabToShow === 'search'" class="search-navbar-form-wrapper">
          <Vselect
              v-if="shouldShowCategory"
              class="search-navbar__category-select" id="search_navbar"
              :reduce="cat => cat.id"
              @input="handleSelectCategory"
              :value="categoryObj"
              label="name"
              :searchable=false
              :options="rootCategories"
              :clearable=false
              @close="focusOnSearchInput"
          >

            <template #open-indicator="{ attributes }">
              <i class="material-icons search-navbar__category-select-arrow" v-bind="attributes">expand_more</i>
            </template>

            <template v-slot:option="option">
              <div class="search-navbar__category-select-prefix">
              <span v-if="option.id === navbarSelectedCategoryId"
                    class="material-icons search-navbar__category-select-check">check</span>
              </div>
              {{ option.name }}
            </template>

          </Vselect>
          <form class="search-navbar-form" @submit.prevent="1"
                :class="{
                'search-navbar__form--focused': inputFocus,
                'search-navbar__form--borderless': showAutocompleteDropdown,
                'search-navbar__form--full-border': !shouldShowCategory,
                'search-navbar__form--has-removable-pill': shouldSearchInShop
              }"
                accept-charset="UTF-8">
            <RemovablePill v-if="shouldSearchInShop"
                           class='search-navbar__form-removable-pill'
                           :item="{text: 'This store only'}" @remove="handleRemoveSearchInShop" />

            <label v-if='!isSearching && searchString.length > 0'
                   class="search-navbar-form__label search-navbar-form__label--cancel material-icons" data-text="cancel"
                   for="q" @click="handleCancel">cancel</label>
            <label v-else-if='!isSearching && searchString.length === 0' class="search-navbar-form__label material-icons"
                   data-text="search" for="q">search</label>
            <LoadingSpinner v-else class="search-navbar-form__label search-navbar__logo-loading"/>

            <input type="search" ref="searchInput"
                   @blur="handleOnBlur"
                   autocomplete="off"
                   @focus="() => { optionsShown = true;  inputFocus = true }"
                   @keydown="keyMonitor"
                   @keydown.up="e => selectWithArrowKey(e,-1)"
                   @keydown.down="e => selectWithArrowKey(e, 1)"
                   :value="searchString"
                   @input="evt => searchString = evt.target.value"
                   id="q"
                   name="q"
                   class="search-navbar__input"
                   :class="{ 'search-navbar__input--has-removable-pill': shouldSearchInShop }"
                   placeholder="Search for product"/>

            <div v-show="showAutocompleteDropdown" class="search-navbar__dropdown">
              <div v-show="suggestions.length > 0" @mouseleave="handleDropdownMouseLeave"
                   class="search-navbar__dropdown-block">
                <div
                    class="search-navbar__suggestion"
                    :class="{ 'search-navbar__suggestion--highlighted': highlighted(index) }"
                    @mousedown="navigateToSearchPage"
                    @mouseover="e => hoverSelect(e, index, suggestion, categoryId)"
                    v-for="({text: suggestion, id: categoryId}, index) in suggestions"
                    :key="index">
                <span class="search-navbar__dropdown-text">
                  <template v-if="categoryId && bMatchTermWithCategory(suggestion)">
                    <TextHighlight
                        :queries="completedSearchString.split(' ')"
                        :highlightComponent="HighLight"
                    >
                      {{ suggestion }}
                    </TextHighlight>
                  </template>

                  <template v-else>
                    <TextHighlight
                        :queries="completedSearchString.split(' ')"
                        :highlightComponent="HighLight"
                    >
                      {{ categoryId ? completedSearchString : suggestion }}
                    </TextHighlight>
                    <span v-if="categoryId" class="search-navbar__suggestion-category">in {{ suggestion }}</span>
                  </template>
                </span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div v-show="tabToShow === 'search-filter'" class="search-navbar-filter-sort">
          <FilterSort/>
        </div>
      </div>
      <div class="category-navbar" v-if="shouldShowCategoryHeader">
        <router-link to="/categories" class="category-navbar__link" ref="link" tabindex="-1">
          <span>Browse by category</span>
          <span class="material-icons">chevron_right</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from 'rs/components/common/loading_spinner'
import FilterSort from 'rs/components/common/filter_sort_header'
import RemovablePill from 'rs/components/common/removable_pill'
import TextHighlight from 'rs/components/common/highlight';
import HighLight from 'rs/components/layouts/highlight'
import {sanitizeParams} from 'rs/store/store_utils/search_utils';
import Vselect from "vue-select";
import defaultAxios from 'axios'
import {mapState} from 'vuex'
import {debounce} from 'lodash-es'
import axios from 'shared/utils/axios'

let cancel
const CancelToken = defaultAxios.CancelToken;
const searchHistory = {}

const SEARCH_BAR_HEIGHT = 70;
const CATEGORY_HEADER_HEIGHT = 56;
const DROPDOWN_PADDING = 20;

export default {
  props: {
    divider: {
      type: Boolean,
      default: true,
    },
    showCategory: {
      default: null,
    },
    setWrapperHeight: {
      default: null,
    }
  },
  components: {LoadingSpinner, FilterSort, Vselect, TextHighlight, RemovablePill},
  computed: {
    ...mapState('Search', ['searchParams', 'rootCategories', 'navbarSelectedCategoryId', 'tempSelectedCategoryId']),
    shouldShowCategory(){
      if (this.showCategory) return true;
      else if (this.showCategory === false) return false;
      else return this.$route.name === this.$routes.search.name;
    },
    mediaScreen(){
      return this.$store.getters['Global/screen']
    },
    isShopPage(){
      return this.$route.name === this.$routes.shop.name;
    },
    shouldSearchInShop(){
      return this.isShopPage && this.enabledSearchInShop
    },
    showAutocompleteDropdown() {
      return this.optionsShown && this.doneSearches;
    },
    suggestions() {
      return this.instantResults.suggestions ? [...this.instantResults.suggestions] : [];
    },
    doneSearches() {
      return this.instantResults.suggestions && this.instantResults.suggestions.length > 0;
    },
    categoryObj() {
      return this.rootCategories.find(e => e.id === this.navbarSelectedCategoryId) || this.rootCategories[0]
    },
    shouldShowCategoryHeader(){
      return this.inputFocus && this.suggestions.length <= 0
    }
  },
  data() {
    return {
      attributes: {
        'ref': 'openIndicator',
        'role': 'presentation',
        'class': 'vs__open-indicator',
      },
      HighLight: HighLight,
      scrolling: false,
      inputFocus: false,
      isSearching: false,
      optionsShown: false,
      searchString: this.$route.query.q || "",
      completedSearchString: "",
      instantResults: {},
      selected: null,
      idx: 0,
      lastScrollTop: 0,
      tabToShow: 'search', // ['search', 'search-filters']
      enabledSearchInShop:  this.$route.name === this.$routes.shop.name
    }
  },

  methods: {
    handleRemoveSearchInShop(){
      this.enabledSearchInShop = false;
    },
    handleSelectCategory(e) {
      this.$store.commit('Search/setNavbarSelectedCategoryId', e)
      this.$store.commit('Search/setTempSelectedCategoryId', e)
    },
    handleDropdownMouseLeave() {
      this.idx = 0;
      this.selected = null;
    },
    focusOnSearchInput() {
      this.$refs.searchInput.focus()
    },
    handleSearch: debounce(function (keyword) {
      if (keyword.length > 0) {
        cancel && cancel()
        this.fetchInstantSearch({
          q: keyword,
        })
      } else {
        this.instantResults = {suggestions: []}
      }
    }, 200),

    async fetchInstantSearch(params) {
      this.isSearching = true;
      this.idx = 0;
      this.selected = null;
      const data = {
        suggestions: []
      }
      try {
        if (searchHistory[params.q]) {
          data.suggestions = [...searchHistory[params.q]];  // save value instead of ref
        } else {
          const res = await axios.get('/instant_search', {
            params,
            cancelToken: new CancelToken(c => cancel = c)
          })
          searchHistory[params.q] = res.data.suggestions;
          data.suggestions = [...res.data.suggestions]; // use value instead of ref because searchHistory[params.q] shares same ref
        }
        if (data.suggestions.length > 0) {
          let matchStringIndex = -1;
          data.suggestions.forEach((s, idx) => {
            // start checking whether there is a match (with search term) in suggestions
            if (s.length > 0 && s.length === params.q.length && s == params.q ) {
              matchStringIndex = idx;
            }
            data.suggestions[idx] = {
              id: s.id,
              text: s.text || s
            }
          })
          // remove the suggestion matched with search term
          if (matchStringIndex > -1) {
            data.suggestions.splice(matchStringIndex,1)
          }
          // unshift original string to response
          data.suggestions.unshift({
            id: undefined,
            text: params.q
          })
        }

        this.instantResults = data;
        this.completedSearchString = params.q;
      } catch (error) {
      }
      this.isSearching = false;
    },
    handleCancel(){
      this.searchString = ''
      this.focusOnSearchInput()
    },
    keyMonitor: function (event) {
      if (event.key === 'Enter') {
        event.preventDefault()
        this.navigateToSearchPage()
        event.target.blur()
      }
    },
    async navigateToSearchPage() {
      let category_id = null;
      if (this.selected && this.selected.id) {
        category_id = this.selected.id;
      } else {
        category_id = this.tempSelectedCategoryId || null
      }
      if (this.searchString.length !== 0) {
        let query = sanitizeParams({
          ...this.$route.query,
          page: 1,
          category_id,
          // if submit with a suggestion - and suggestion is:
          // - a category suggestion => q = searchString (from user input)
          // - a text suggestion => q = this.selected.text (text suggestion)
          // else use whatever user types.
          q: this.selected ? (this.selected.id ? this.searchString : this.selected.text) : this.searchString,
        })
        if (this.shouldSearchInShop) {
          query = {
            ...query,
            min_transparency: 0,
            ...this.$store.getters['Shop/searchInShopParams'],
          }
        }

        // exception: when search again with reference from 'category' page, clear sort_by
        const fromRoute = await this.$store.dispatch('Search/getCachedParam', 'from')
        if (fromRoute === 'category') {
          delete query.sort_by
          this.$store.dispatch('Search/cacheParam',['from', null])
        }

        this.$router.push({
          query,
          name: 'PageSearch',
        })
        this.exit()
      }
    },
    hoverSelect(event, idx, suggestionText, categoryId) {
      event.stopPropagation()
      this.selected = {id: categoryId, text: suggestionText, index: idx};
      this.idx = idx;
    },
    selectWithArrowKey(event, step) {
      event.preventDefault()
      let idx
      if (this.idx + step < 0) {
        idx = this.suggestions.length - 1
      } else if (this.idx + step > this.suggestions.length - 1) {
        idx = 0
      } else {
        idx = this.idx + step
      }
      this.idx = idx

      if (idx < this.suggestions.length) {
        this.selected = {index: idx, text: this.suggestions[idx].text, id: this.suggestions[idx].id}
      }
    },
    highlighted(index) {
      return this.selected && this.selected.index === index
    },
    bMatchTermWithCategory(categoryText) {
      try {
        if (categoryText.length === 0) return false;
        const aText = this.completedSearchString.split(' ');
        const aCategoryText = categoryText.split(' ');
        let counter = 0;
        for (let i = 0; i < aText.length; i++) {
          for (let j = 0; j < aCategoryText.length; j++) {
            if (aCategoryText[j].startsWith(aText[i])) {
              counter++;
              j = aCategoryText.length; // break inner loop;
            }
          }
        }
        return counter === aText.length
      } catch {
        return false
      }
    },
    handleOnBlur(e){
      if (this.$refs.link && e.relatedTarget === this.$refs.link.$el) {
        this.exit()
        this.$nextTick(()=>{
          this.$router.push('/categories')
        })
      } else {
        this.exit()
      }
    },
    exit() {
      this.idx = 0;
      this.selected = null;
      this.inputFocus = false;
      this.optionsShown = false;
    },
    smoothScroll() {                                  // optimize: trigger when scrolling every 100ms
      const self = this;
      this.intervalID = setInterval(() => {
        if (self.scrolling) {
          self.scrolling = false;
          self.handleScroll()
        }
      }, 100)
    },
    scrollUpOtherPages() { // show search bar
      this.$el.style.display = 'block'
    },
    scrollDownOtherPages() { // hide search bar
      this.$el.style.display = 'none'
    },
    scrollOtherPages(currentScrollTop) {
      if (this.mediaScreen.mobile) {
        currentScrollTop < Math.max(window.headerHeight, this.lastScrollTop)
            ? this.scrollUpOtherPages()
            : this.scrollDownOtherPages()
      }
    },
    scrollSearchPage(currentScrollTop){
      if (this.mediaScreen.mobile) {
        if (currentScrollTop < this.lastScrollTop) {                // scrolling up
          if (this.tabToShow !== 'search') { this.tabToShow = 'search' }
        } else {                                                    // scrolling down
          if (currentScrollTop > window.firstCardBottomY - window.headerHeight) {  // scrolling down past first row of items
            if (this.tabToShow !== 'search-filter') {  this.tabToShow = 'search-filter'  }
          } else {                                                      // Not pass first row.
            if (this.tabToShow !== 'search') { this.tabToShow = 'search' }
          }
        }
      }
    },
    handleScroll() {
      const currentScrollTop = window.scrollY;
      // handle scroll by page:
      // 1. on other pages (not SearchPage):
      //   - Scroll up: show 'search' tab
      //   - Scroll down: hide the whole el.
      // 2. on PageSearch:
      //   - Scroll up: show  "search" tab
      //   - Scroll down:
      //      - not past first row of cards: show 'search' tab
      //      - past first row of cards: show 'filter' tab
      if (this.$route.name === this.$routes.search.name)  {
        this.scrollSearchPage(currentScrollTop)
      } else {
        if (!this.inputFocus) { this.scrollOtherPages(currentScrollTop) }
      }

      this.lastScrollTop = currentScrollTop;
    },
    scroll() {
      if (window.autoScroll) return;
      if (!this.scrolling) {
        this.scrolling = true
      }
    },
    toggleNavBackground(suggestionLength, focus) {
      if (!this.mediaScreen.mobile) return;
      if (!this.setWrapperHeight) return;
      if (!focus) { // showing nothing when not focus (search bar only)
        this.setWrapperHeight(SEARCH_BAR_HEIGHT)
      }
      else if (suggestionLength > 0) { // showing search bar + dropdown (with x items height)
        this.setWrapperHeight(SEARCH_BAR_HEIGHT + DROPDOWN_PADDING + suggestionLength * 32)
      } else if (focus && suggestionLength === 0) { // showing category button
        this.setWrapperHeight(SEARCH_BAR_HEIGHT + CATEGORY_HEADER_HEIGHT)
      }
    },
  },
  watch: {
    '$route.name'(newName){
      this.toggleNavBackground(this.suggestions.length, this.inputFocus)
      if (this.$el && this.$el.style) {
        this.$el.style.display = 'block';  // always show search bar after navigation (to solve case when search bar was hidden before navigation)
      }
      if (newName !== 'PageSearch') {
        this.tabToShow = 'search';        // always show 'search' tab when change routes
      }

      this.enabledSearchInShop = newName === this.$routes.shop.name  // toggle search in shop
    },
    'searchParams.q'(val) {
      this.searchString = val
    },
    searchString: function (newValue, oldValue) {
      if (newValue.trim() == oldValue.trim()) return;
      this.handleSearch(newValue)
      if (newValue.length > 0 && newValue !== this.searchParams.q && !this.optionsShown) {
        this.optionsShown = true
      } else if (newValue.length === 0) {
        this.optionsShown = false
      }
    },
    suggestions(newV){
      this.toggleNavBackground(newV.length, this.inputFocus)
    },
    inputFocus(newValue) {
      this.toggleNavBackground(this.suggestions.length, newValue)
      if (newValue) {
        document.body.classList.add('should-lock-scroll')
      } else {
        if (!this.searchString && this.searchParams.q) { // when user click remove (X) icon, and not do anything, and focus out, revert searchString to last q
          this.searchString = this.searchParams.q
        }
        document.body.classList.remove('should-lock-scroll')
      }
    },
    mediaScreen(s){
      this.toggleNavBackground(this.suggestions.length, this.inputFocus)
    },
  },
  mounted() {
    window.addEventListener('scroll', this.scroll)
    this.smoothScroll()
    if (this.searchString.length > 0) {
      this.fetchInstantSearch({q: this.searchString})
    }
  },
  destroy() {
    window.removeEventListener('scroll', this.scroll)
  },
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.search-navbar-wrapper {
  width: 100%;
}
.search-navbar__transparent-layer {
  background: transparent;
  height: fit-content;
}
.search-navbar__transparent-layer--focused {
  @include respond-to(notdesktop){
    width: 100%;
    min-height: 100vh;
    background: transparent;
  }
}

.search-navbar-divider {
  height: 1px;
  background-color: $feedCardDividerColor;
  margin: 0;
  border: none;
  position: fixed;
  width: 100vw;
  left: 0;
}

.search-navbar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100%;
  gap: 8px;

  @include respond-to(notdesktop) {
    flex-direction: column;
    padding: 0 12px;
  }
}

.search-navbar-form-wrapper {
  position: relative;
  width: 100%;
  height: 50px;
  max-width: 544px;
  display: flex;
}

.search-navbar-form {
  border: solid 1px $borderColor;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  max-width: 544px;
  border-radius: 0 25px 25px 0;

  @include respond-to(mobile) {
    border-radius: 25px;
  }
}
.search-navbar__form--has-removable-pill {
  display: flex;
  @include respond-to(notdesktop) {
    flex-wrap: wrap;
  }
}

.search-navbar__form--full-border {
  border-radius: 25px;
}
.search-navbar__form--full-border > .search-navbar__input {
  padding: 8px 50px 8px 20px;
}
.search-navbar__form--full-border > .search-navbar__input--has-removable-pill {
  padding: 0 36px 0 0;
  @include text-elipsis();
  @include respond-to(notdesktop) {
    flex: 1;
  }
}

.search-navbar__form--borderless {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: solid 1px $brandColor;
}

.search-navbar__form--focused {
  @include respond-to(mobile) {
    border: solid 1px $brandColor;
  }
}

.search-navbar__form-removable-pill {
  margin: 8px;
  font-size: 14px;
  width: fit-content;
}

.search-navbar-form__label {
  position: absolute;
  z-index: 2;
  right: 0;
  margin: 12px 12px 12px 0;
  height: 24px;
  width: 24px;
  color: $marketplaceSecondaryIconColor;
  cursor: pointer;
}

.search-navbar-form__label--cancel {
  color: $brandColor;
}

.search-navbar__logo-loading {
  position: absolute;
  z-index: 2;
  right: 0;
  margin: 6px 6px 6px 0;
  height: 38px;
  width: 38px;
}

.search-navbar__input {
  padding: 8px 50px 8px 12px;
  background-color: transparent;
  border: none;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 400;

  &:focus {
    outline: none;
  }

  @include respond-to(mobile) {
    padding-left: 20px;
  }
}

.search-navbar__dropdown {
  border: solid 1px $brandColor;
  border-top: none;
  padding: 8px 0 8px 0;
  background-color: white;
  position: relative;
  width: calc(100% + 2px);
  margin-left: -1px;
  margin-right: -1px;
  top: -1px;

  @include respond-to(notmobile) {
    top: 49px;
    position: absolute;
    z-index: 2;
    opacity: 1;
  }
}

.search-navbar__suggestion--highlighted {
  background-color: $feedCardDividerColor;
}

.search-navbar__dropdown-block {
  border-bottom: 0.5px solid $feedCardDividerColor;
  padding-bottom: 8px;

  &:last-child {
    border: transparent;
    padding-bottom: 0;
  }
}

.search-navbar__suggestion {
  @include text-elipsis();
  cursor: pointer;
}

.search-navbar__suggestion-category {
  color: $newBrandColor;
}

.search-navbar__dropdown-text {
  margin: 0 20px 0 20px;
  font-size: 14px;
  line-height: 32px;
  font-weight: 700;
}

.search-navbar-filter-sort {
  width: 100%;
  height: 36px;
}

.search-navbar__category-select-arrow {
  color: $brandColor
}

.search-navbar__category-select-prefix {
  width: 20px;
}

.search-navbar__category-select-check {
  font-size: 12px;
}

.category-navbar {
  font-weight: 700;
  font-size: 16px;
  height: 56px;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include respond-to(notmobile) {
    display: none;
  }
}
.category-navbar__link {
  color: $primaryFontColor;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
}
</style>

<style lang="scss">
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.search-navbar__category-select {
  @include respond-to(mobile) {
    display: none;
  }
  font-weight: 400;
  height: 50px;
  line-height: 40px;
  width: 232px;


  .vs__dropdown-toggle {
    padding-left: 10px;
    background-color: #F7F7F7;
    height: 100%;
    width: 232px;
    border-radius: 0; // remove small radius on the right from nowhere
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border: solid 1px $borderColor;
    border-right: transparent;
    font-weight: 600;
    font-size: 14px;

    .vs__selected {
      position: relative;
      margin-right: 0;
      padding: 0;
    }
  }

  .vs__dropdown-menu {
    border-radius: 5px;
    top: -4px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: max-content;
    overflow: auto;
    border: $newBorderStyle;
    max-height: 800px;
    background-color: #F7F7F7;
    font-weight: 400;
    font-size: 16px;
    left: -12px;
  }

  .vs__dropdown-menu .vs__dropdown-option--selected {
    color: black;
  }

  .vs__search {
    margin: 0;
    padding: 0;
    width: 0; // dont use display:none as it will not close dropdown on blur
  }

  .vs__dropdown-menu .vs__dropdown-option {
    color: $brandColor;
    display: flex;
    padding-right: 20px;
  }

  .vs__dropdown-menu .vs__dropdown-option--selected {
    color: black;
  }

  .vs__dropdown-menu .vs__dropdown-option--highlight {
    color: #FFFFFF;
  }
  .vs__fade-enter-active,
  .vs__fade-leave-active {
    transition: none;
  }

}


</style>
