import {
  fetchDispute,
  createChat,
  respondProposal,
  createProposal,
  updateDispute,
  postMessageFiles,
  uploadFile,
  getPresignedData,
  requestArbitrator,
} from 'shared/vue_components/arbitration/base_actions.js'

const URL_PREFIX = '/profile/disputes'

export default {
  createChat: createChat(disputeId => `${URL_PREFIX}/${disputeId}/chat`),
  fetchDispute: fetchDispute(disputeId => `${URL_PREFIX}/${disputeId}`),
  createProposal: createProposal(disputeId => `${URL_PREFIX}/${disputeId}/propose`),
  respondProposal: respondProposal(disputeId => `${URL_PREFIX}/${disputeId}/respond_to_proposal`),
  updateDispute: updateDispute(disputeId => `${URL_PREFIX}/${disputeId}`),
  postMessageFiles: postMessageFiles(disputeId => `${URL_PREFIX}/${disputeId}/upload`),
  getPresignedData: getPresignedData(disputeId => `${URL_PREFIX}/${disputeId}/presigned_data`),
  requestArbitrator: requestArbitrator((disputeId) => `${URL_PREFIX}/${disputeId}/request_arbitrator`),
  uploadFile,
}
