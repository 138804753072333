/* Vue Router hack
  A hack to update query string in the url without reloading the page (re-rendering other components)
  Also support goback/forward browser
  Vue Router doesn't support nested route with query string.
  add this function to Vue.prototype when creating Vue instance
  example usage: this.$updateQueryUrl({tab: 2})
*/
export function updateQueryUrl(query) {
  for (const key in query) {
    // Vue router query doesn't allow changing query object, hence updating key one by one.
    this.$route.query[key] = query[key]
  }
  const { href } = this.$router.resolve(this.$route);
  window.history.pushState({}, null, href)
}
