<template>
  <section class='people-page'>
    <h1>Find People</h1>
    <div v-if="isLoggedIn" class="people-page-filter-sort">
      <PeopleFilterBy @changed="selectedFilterHandler" :selectedFilter="selectedFilter"/>
      <PeopleSortBy @changed="selectedSortHandler" :selectedSort="selectedSort"/>
    </div>

    <NoSearchResults v-if='reviewers.length <= 0 && !isLoadingReviewers'/>
    <div v-else>
      <div class='people-list'>
        <PeopleCard v-for="(reviewer, index) in reviewers" v-bind:key="index" v-bind:reviewer="reviewer"/>
      </div>

      <LoadingSpinner v-if='isLoadingReviewers'/>
      <div v-else-if='hasMoreReviewers' class='people-list__load-more btn btn--text-bg' @click='fetchMoreResults'>
        Load More
      </div>
    </div>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import LoadingSpinner from 'rs/components/common/loading_spinner'
  import NoSearchResults from 'rs/components/common/no_search_results'
  import PeopleCard from 'rs/components/common/people_card'
  import PeopleFilterBy from 'rs/components/pages/people/people_filter_by'
  import PeopleSortBy from 'rs/components/pages/people/people_sort_by'

  export default {
    components: { LoadingSpinner, NoSearchResults, PeopleCard, PeopleFilterBy, PeopleSortBy },

    computed: mapState('People', {
      searchParams: 'searchParams',
      reviewers: 'reviewers',
      hasMoreReviewers: 'hasMoreReviewers',
      isLoadingReviewers: 'isLoadingReviewers',
      isLoggedIn() {
        return window.isReviewerLoggedIn;
      },
    }),

    data() {
      return {
        selectedFilter: this.$route.query.filter_by || "everyone",
        selectedSort: this.$route.query.sort_by || "rs_recommendations_count",
      }
    },

    methods: {
      fetchMoreResults: function() {
        this.$store.dispatch('People/searchReviewers', {
          ...this.searchParams,
          filter_by: this.selectedFilter,
          sort_by: this.selectedSort,
          page: this.searchParams.page + 1,
        })
      },

      selectedFilterHandler(filter) {
        this.selectedFilter = filter
        this.$router.replace({
          query: {
            page: 1,
            filter_by: filter,
            sort_by: this.$route.query.sort_by,
          }
        })
        this.$store.dispatch('People/makeNewSearchReviewers', { filter_by: filter, sort_by: this.$route.query.sort_by })
      },

      selectedSortHandler(sort) {
        this.selectedSort = sort
        this.$router.replace({
          query: {
            page: 1,
            filter_by: this.$route.query.filter_by,
            sort_by: sort,
          }
        })
        this.$store.dispatch('People/makeNewSearchReviewers', { filter_by: this.$route.query.filter_by, sort_by: sort })
      },
    },

    mounted () {
      this.$store.dispatch('People/makeNewSearchReviewers', { q: this.$route.query.q, filter_by: this.selectedFilter, sort_by: this.selectedSort })
    },

    watch: {
      '$route.query.q': function(val) {
        this.$store.dispatch('People/searchReviewers', { q: val, filter_by: this.selectedFilter, sort_by: this.selectedSort })
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  h1 {
    margin-top: 44px;
    line-height: 1;
  }

  h2 {
    margin-top: 28px;
  }

  .loading-spinner {
    margin: 24px auto;
    width: 72px;
    height: 72px;
  }

  .people-list {
    display: flex;
    flex-wrap: wrap;
  }

  .people-card {
    margin-top: 32px;
    margin-bottom: 12px;
    margin-right: 20px;

    @include respond-to(mobile) {
      width: calc(50% - 10px);
      &:nth-of-type(2n) { margin-right: 0; }
    }

    @include respond-to(desktop) {
      width: calc(25% - 15px);
      &:nth-of-type(4n) { margin-right: 0; }
    }

    @include respond-to(tablet) {
      width: calc(33.3% - 13.3px);
      &:nth-of-type(3n) { margin-right: 0; }
    }
  }

  .people-list__load-more {
    display: block;
    width: 200px;
    margin: 20px auto;
  }

  .people-page-filter-sort {
    @include respond-to(notmobile) {
      display: flex;
      align-items: center;
    }
  }
</style>
