<template>
  <MatchMedia v-slot="{mobile}" v-if="title || label || tooltip">
    <div class="d-flex-column gap-2">
      <div class="review-source__wrapper">
        <div class="review-source__label">{{ title || label }}</div>
        <TooltipPopover :label="label" :tooltip="tooltip">
          <template v-slot:icon>
            <CircleIcon icon='info' class='review-source__tooltip-icon' color="#9A9A9A"/>
          </template>
        </TooltipPopover>
      </div>
    </div>
  </MatchMedia>
</template>
<script>
import TooltipPopover from 'rs/components/common/tooltip_popover'
import MobileBottomModal from 'rs/components/layouts/mobile_bottom_modal'
import {MatchMedia} from "shared/vue_media_query/MatchMedia";
import CircleIcon from 'rs/components/common/icons/custom_circle_icon'

export default {
  components: {MatchMedia, TooltipPopover, MobileBottomModal, CircleIcon},
  props: ['title', 'label', 'tooltip'],
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.review-source__wrapper {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 4px;
}

.review-source__wrapper > .v-popover {
  display: flex;
  align-items: center;
}

.review-source__tooltip-icon {
  border-color: #E6E6E6;
  background: #E6E6E6;
}

.review-source__label {
  padding: 4px 8px;
  border-radius: 25px;
  border: 1px solid #E6E6E6;
  color: $secondaryFontColor;
  font-weight: 600;
  width: fit-content;
  font-size: 14px;
}
</style>
