<template>
  <div class="homepage__wrapper">
    <HeroSearch/>
    <CategorySection />
    <CollectionsSection />
    <FeaturedReviewsSection />
    <ProductsSection/>
    <GuaranteeSection/>
  </div>
</template>
<script>
import HeroSearch from 'rs/components/pages/reviews/_search_section'
import ProductsSection from 'rs/components/pages/reviews/_products_section'
import CollectionsSection from 'rs/components/pages/reviews/_collections_section'
import CategorySection from 'rs/components/pages/reviews/_category_section'
import FeaturedReviewsSection from 'rs/components/pages/reviews/_featured_reviews_section'
import GuaranteeSection from 'rs/components/pages/reviews/_guarantee_section'

export default {
  components: {HeroSearch, CategorySection, GuaranteeSection, ProductsSection, CollectionsSection, FeaturedReviewsSection},
  created(){
    this.$store.dispatch('Home/getHomeMetrics')
    this.$store.dispatch('Home/getCollections')
    this.$store.dispatch('Home/getFeaturedReviews')
    this.$store.dispatch('Home/getFullTransparentProducts')
  },
}
</script>
<style lang="scss">
.homepage__wrapper {
  margin-bottom: -20px;
}
</style>
