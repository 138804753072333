<template>
  <section class='shop-search-card'>
    <div class="shop-search-card-wrapper">
      <div class="shop-search-card__shop-image">
        <a v-bind:href="searchResult.href" class='' target="_blank" ref='nofollow noopener'>
          <div class="shop-search-card__shop-image-wrapper" :class="{ 'no-logo': !searchResult.logo }">
            <img v-if="searchResult.logo" v-bind:src="searchResult.logo" class="shop-image" alt="Shop logo">
            <div v-else class="shop-image-no-logo material-icons">store</div>
          </div>
        </a>
        <HeartBtn class='shop-search-card__heart-btn' :followeeClass='"Shop"' :followeeEncodedId='searchResult.encoded_shop_id' />
      </div>

      <div class="shop-search-card__shop-information">
        <h3 class="shop-search-card__title">{{ searchResult.shop_name }}</h3>
        <div class="shop-search-card__shop-information-products">
          {{$sharedUtils.pluralize(searchResult.products_count, 'PRODUCT', 'S')}}
        </div>

        <div class="shop-search-card__rating-and-reviews">
          <StarRating :rating='searchResult.average_rating' class='shop-search-card__rating' />
          <a v-if="searchResult.number_of_reviews < 1" class="shop-search-card__add-review-link"
              v-bind:href="'/reviews/' + searchResult.shop_domain + '/products/' + searchResult.handle">Add Review
          </a>
          <div v-else class="shop-search-card__review-number">
            {{$sharedUtils.pluralize(searchResult.number_of_reviews, 'Review')}}
          </div>
        </div>
      </div>

      <ShopResultMedals class="shop-search-card__shop-medals" :medals='searchResult.shop_medals'/>

      <div class="shop-search-card__links-wrapper">
        <a class="shop-search-card__view-reviews-btn btn btn--brand" v-bind:href="'/reviews/' + searchResult.review_site_slug" target="_blank">
          View Reviews
        </a>
        <ExternalLink :href="searchResult.href" class="shop-search-card__visit-store-link" :text="'Visit Store'"/>
      </div>
    </div>
  </section>
</template>

<script>
  import StarRating from 'rs/components/common/star_rating'
  import ExternalLink from 'rs/components/common/external_link'
  import HeartBtn from 'rs/components/common/heart_btn'
  import ShopResultMedals from 'rs/components/common/shop_result_medals'

  export default {
    components: { StarRating, ExternalLink, HeartBtn, ShopResultMedals },

    props: ['searchResult'],

    computed: {
      medalData() { return this.searchResult.shop_medals },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .shop-search-card {
    padding: 8px;
    border: $borderStyle;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    vertical-align: top;

    &:hover { box-shadow: none; }
  }

  // to push the button wrapper to the bottom. https://stackoverflow.com/a/31002364/1815779
  .shop-search-card-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .shop-search-card__shop-information {
    margin-bottom: auto; // to push the button wrapper to the bottom. https://stackoverflow.com/a/31002364/1815779
    text-align: center;
  }

  .shop-search-card__title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
  }

  .shop-search-card__heart-btn {
    position: absolute;
    right: 4px;
    top: 4px;
  }

  .shop-search-card__add-review-link {
    color: $primaryFontColor;
    text-decoration: underline;
  }

  .shop-search-card__medal-section {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    // TODO
  }

  .shop-search-card__shop-information-products {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: $secondaryFontColor;
    margin-bottom: 12px;

    @include respond-to(mobile) {
      margin-bottom: 16px;
    }
  }

  .shop-search-card__shop-image {
    position: relative;
    top: -1px; // To overlap, hide the top border
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    margin-bottom: 12px;
  }

  .shop-search-card__shop-image-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 5px;
    border: $borderStyle;
    overflow: hidden;

    &.no-logo {
      background: $backgroundColor;
    }
  }

  .shop-image {
    @include middle-align-child;
    object-fit: cover;
    width: 100%;
    height: auto;
    max-height: 100%;
  }

  .shop-image-no-logo {
    @include middle-align-child;
    font-size: 110px;
    color: $secondaryFontColor;
  }

  .shop-search-card__title {
    @include multiline-ellipsis(2);
  }

  .shop-search-card__rating-and-reviews {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include respond-to(desktop) {
      flex-direction: row;
    }
  }

  .shop-search-card__review-number {
    font-size: 14px;
    margin-top: 2px;
  }

  .shop-search-card__shop-medals {
    margin-top: 20px;
  }

  .shop-search-card__links-wrapper {
    align-self: flex-end; // to push the button wrapper to the bottom. https://stackoverflow.com/a/31002364/1815779
    width: 100%;
  }

  .shop-search-card__view-reviews-btn {
    display: block;
    margin-top: 24px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .shop-search-card__visit-store-link {
    display: block;
    margin-top: 12px;
    padding: 12px;
    text-align: center;
    line-height: 1;
  }
</style>
