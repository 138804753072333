<template>
  <div class="progress" :style="wrapperStyles">
    <div class="progress-background">
      <div class="progress-value" :style="barStyles"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activePercentage: {type: Number, required: true},
    rating: {type: Number, default: 5},
    baseColor: {type: String, default: 'rgba(51, 153, 153)'}, // new brand color in RGB
  },
  computed: {
    starColor() {
      return this.baseColor.replace(')', ', ' + this.rating / 5 + ')')
    },
    wrapperStyles() {
      return {
        '--complete-percentage': this.activePercentage + '%',
      }
    },
    barStyles() {
      return {
        'background': this.starColor,
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';

.progress {
  background: $borderColor;
  justify-content: flex-start;
  border-radius: 10px;
  align-items: center;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.progress-background {
  position: relative;
  border-radius: 10px;
  background: transparent;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.progress-value {
  position: absolute;
  animation: load 2s normal forwards;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: $newBrandColor;
  height: 100%;
  width: 0;
}

@keyframes load {
  from {
    width: 0;
  }
  to {
    width: var(--complete-percentage);
  }
}
</style>
