<template>
  <section class="shop-details">
    <img v-if="shop.banner" class='shop-details__banner' v-bind:src="shop.banner" v-bind:alt="shop.name + ' banner'" loading='lazy'>

    <img v-if="shop.logo_url" class='shop-details__logo' v-bind:src="shop.logo_url" v-bind:alt="shop.name + ' logo'" loading='lazy'>
    <div v-else class="shop-details__no-logo material-icons">store</div>

    <h3 class="shop-details__name">{{ shop.name }}</h3>

    <div class="shop-details__product-count">
      {{$sharedUtils.pluralize(shop.product_count, 'Product')}}
    </div>

    <p class="shop-details__description">{{ shop.description }}</p>

    <!-- TO DO: CATEGORIES -->

    <div class='shop-details__divider'>
    </div>

    <div class="shop-profile-completion">
      <div class="shop-profile-completion__header-wrapper">
        <div class="shop-profile-completion__header-question-label">
          <div class="shop-profile-completion__question-btn-wrapper">
            <span class="shop-profile-completion__question-btn material-icons">?</span>
          </div>
          <h3 class="shop-profile-completion__header-label">Profile completion</h3>
        </div>
        <h3 class="shop-profile-completion__header-percentage">100% complete</h3>
      </div>
      <div class="shop-profile-completion__progress-bar-wrapper">
        <span class="shop-profile-completion__progress-bar" style="width: 100%"></span>
      </div>
    </div>  

    <div class='shop-details__divider'>
    </div>

    <router-link v-bind:to="'/reviews/' + shop.review_site_slug" class='shop-details__cta btn btn--brand'>
      View Shop
    </router-link>
  </section>
</template>

<script>
  export default {
    props: [ 'shop' ],
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .shop-details {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .shop-details__banner {
    max-width: 100%;
    max-height: 200px;
    margin: auto;
    display: block;
  }

  .shop-details__logo {
    background-color: white;
    border-radius: 100px;
    border: $borderColor 1px solid;
    width: 64px;
    height: 64px;
    position: relative;
    bottom: 35px;
  }

  .shop-details__no-logo {
    background-color: white;
    border: $borderColor 1px solid;
    border-radius: 50%;
    position: relative;
    bottom: 30px;
    width: 60px;
    height: 60px;
    padding: 10px 0;
    font-size: 40px;
  }

  .shop-details__name {
    font-weight: 700;
    font-size: 16px;
    line-height: 13px;
    color: $primaryFontColor;
  }

  .shop-details__product-count {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $secondaryFontColor;
    padding: 8px 0;
  }

  .shop-details__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $primaryFontColor;
  }

  .shop-details__cta {
    @include respond-to(mobile) { width: 100%; }
    @include respond-to(tablet) { width: 50%; }
    @include respond-to(desktop) { float: left; }
  }

  .shop-details__divider {
    height: 1px;
    width: calc(100% + 16px + 16px);
    margin-left: -16px;
    margin-top: 20px;
    margin-bottom: 20px;
    background: $feedCardDividerColor;
  }

  .shop-profile-completion__header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .shop-profile-completion__header-question-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 8px;
  }

  .shop-profile-completion__header-label,
  .shop-profile-completion__header-percentage {
    color: $brandColor;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding-left: 8px;
  }

  .shop-profile-completion__question-btn-wrapper {
    color: $brandColor;
    cursor: pointer;

    &:hover { opacity: $hoverOpacity; }
  }

  .shop-profile-completion__question-btn {
    border-radius: 50%;
    border: 1px solid $brandColor;
    font-size: 14px;
    width: 20px;
    height: 20px;
    font-weight: 600;
  }

  .shop-profile-completion__progress-bar {
    display: block;
    height: 10px;
    border-radius: 20px;
    background-color: $brandColor;
    position: relative;
    overflow: hidden;
  }
</style>
