<template>
  <section class="category-show-page">
    <Heading :title="title"/>
    <Breadcrumb :stack="stack" class="csp__breadcrumb"/>
    <CategoryDirectChildren
      v-for="(cat, idx) in childCategories" :key="idx"
      :isLastChild="idx === childCategories.length - 1"
      :category="cat"
      :linkToParams="linkToParams"
    />
    <CategoryChildren
      v-if="category.level === 3"
      class="show-on-desktop-and-tablet-only"
      :categories="childCategories"
      :linkToParams="linkToParams"
    />
  </section>
</template>

<script>
import Heading from "rs/components/common/heading_section"
import CategoryDirectChildren from "rs/components/pages/categories/_category_direct_children"
import LoadingSpinner from "rs/components/common/loading_spinner"
import Breadcrumb from "rs/components/common/breadcrumb"
import CategoryChildren from 'rs/components/pages/categories/_category_children'
import {mapGetters} from 'vuex'

export default {
  components: {Heading, LoadingSpinner, Breadcrumb, CategoryDirectChildren, CategoryChildren},
  computed: {
    ...mapGetters('Category', ['category', 'title', 'childCategories', 'categoriesMap']),
    stack() {
      return this.$store.getters['Category/stack'](true)
    }
  },
  methods: {
    linkToParams(cat) {
      if (cat.is_deepest) return this.searchLinkParams(cat)
      return {
        name: 'PageCategoryShow',
        params: {category_slug: cat.handle}
      }
    },
    searchLinkParams(cat) {
      return {
        name: this.$routes.search.name,
        query: {q: cat.name.toLowerCase(), category_id: cat.id, sort_by: 'reviews_count', from: 'category'}
      }
    }
  },
  mounted() {
    if (!this.category) {
      this.$router.replace({name: 'PageCategoryIndex'})
    }
    if (this.category.level === 4 || !this.categoriesMap[this.category.id].children) {
      this.$router.replace(this.searchLinkParams(this.category))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.category-show-page {
  margin-bottom:60px;
  @include respond-to(notmobile) {
    margin-bottom: 80px;
  }
}
.csp__loading-spinner {
  margin: 200px auto 0 auto;
  width: 100px;
  height: 100px;
}
.csp__breadcrumb {
  margin-bottom: 24px;
  @include respond-to(mobile) {
    margin-bottom: 16px;
  }
}
</style>
