'use strict';

export const setCookie = (cookieName, cookieValue, exDays) => {
  const date = new Date();
  date.setTime(date.getTime() + (exDays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
}

export const getCookie = (cookieName) => {
  const name = cookieName + "=";
  const cookiesArray = document.cookie.split(';');

  for (let i in cookiesArray) {
    let cookie = cookiesArray[i].trim()
    if (cookie.indexOf(cookieName) == 0) {
      return cookie.substring(name.length, cookie.length)
    }
  }

  return null;
}
