<template>
  <section class="side-container">
    <MetricsWidget />
    <FollowWidget />
    <PromiseWidget v-if="storeName === 'PublicProfile'"/>
  </section>
</template>
<script>
import MetricsWidget from 'rs/components/uncommon/profile/_metrics_widget';
import PromiseWidget from 'rs/components/common/promise_widget';
import FollowWidget from 'rs/components/uncommon/profile/_follow_widget';
export default {
  components: {PromiseWidget, FollowWidget, MetricsWidget},
  props: ['storeName'],
}
</script>
<style lang="scss" scoped>
.side-container {
  padding: 20px 0 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
