<template>
  <div class="gallery-product">
    <div class="gallery-product__header">{{product.title}}</div>
    <div class="gallery-product__metrics">
      <StarRating :rating='product.average_rating' class='gallery-product__rating-stars' />
      <div>{{$sharedUtils.pluralize(product.number_of_reviews, 'review')}}</div>
    </div>
    <p class="gallery-product__description">
      {{ shortDescription  ? (shortDescription+'...') : product.description }}
      <ExternalLink v-if="isLongDescription" :href='product.external_url' text="Read More in Store"/>
    </p>
    <ExternalLink class="btn pf-secondary-button" :href='product.external_url' text="View Product in Store"/>
  </div>
</template>

<script>
import ExternalLink from 'rs/components/common/external_link'
import StarRating from 'rs/components/common/star_rating'

export default {
  components: {
    ExternalLink,
    StarRating,
  },
  props: ['product'],
  computed: {
    isLongDescription() {
      return this.product && this.product.description && this.product.description.length > 780;
    },
    shortDescription(){
      return this.product && this.product.description && this.product.description.substring(0, 780);
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

$_lightColor: #E0E0E0;
$_containerWidthDesktop: 400px;
$_containerHeight: calc(100vh - 452px);

.gallery-product {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  font-weight: 400;
  padding: 16px;
  background-color: white;
  font-size: 16px;

  @include respond-to(desktop) {
    width: 30%;
    max-width: $_containerWidthDesktop;
    height: 100%;
  }
}

.gallery-product__header {
  margin: 20px 0 8px 0;
  font-size: 24px;
  font-weight: 700;
  @include respond-to(notdesktop) {
    margin-top: 0;
  }
  @include multiline-ellipsis(2)
}

.gallery-product__metrics {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  gap: 8px;
}

.gallery-product__description {
  margin: 16px 0 20px 0;
}

::-webkit-scrollbar {
  border-radius: 4px;
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: rgba(136, 136, 136, 0.49);
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 4px;
  background: #555;
}

</style>
