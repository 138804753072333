<template>
 <section class="custom-form">
   <hr v-if="review.cf_answers.length > 0"/>
   <div class="custom-form__question-section">
     <div v-for="(ans,index) in review.cf_answers" v-bind:key="index">
       <h4 v-html="ans.question_title"></h4>
        <!-- different answers for questions type -->
       <div v-if="['text', 'radio', 'single', 'multiple'].includes(ans.question_type)" v-html="ans.value"></div>
       <div v-else-if="ans.question_type === 'scale'">
         <div class="custom-form__scale custom-form__scale-passive">
           <div class="custom-form__scale custom-form__scale-active" :style="{width: `${handleScaleValue(ans.value)}%`}"></div>
         </div>
       </div>
       <div v-else-if="ans.question_type === 'slider'">
         <input disabled class="custom-form__slider" type="range"
                :value="ans.value"  min="0" max="100">
         <div class="custom-form__slider-text-wrapper">
           <small class="custom-form__slider-label">{{ ans.lower_value }}</small>
           <small class="custom-form__slider-label">{{ ans.top_value }}</small>
         </div>
       </div>
     </div>
   </div>
 </section>
</template>
<script>

  export default {
    props: ['review'],
    methods: {
      handleScaleValue(val){
        const [active, total] = val.split('/')
        return active*100/total
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .custom-form {
    margin: 20px 0 0 0;

    h4 {
      margin-top: 15px;
      font-weight: 600;
      line-height: 22px;
      color: $brandColor;
    }

    hr {
      margin-top: 40px;
      border: none;
      border-top: 1px solid $feedCardDividerColor;
    }
  }

  .custom-form__question-section {
    margin: 20px 0;

    .rs-cf-bar {
      display: inline-block;
      width: 25px;
      height: 8px;
      margin: 2px 2px 0 0;
      background: $secondaryFontColor;
      opacity: 0.1;

      &.rs--filled {
        opacity: 1;
      }
    }
    .custom-form__scale {
      background: #D6EBEB;
      border-radius: 2px;
      height: 8px;
      width: 270px;
      margin-top: 8px;
    }
    .custom-form__scale-active {
      background: $brandColor;
    }
    .custom-form__slider {
      margin-top: 8px;
      padding: 0;
      width: 270px;
      height: 4px;
      border-radius: 5px;
      background: #D6EBEB;
      outline: none;
      opacity: 1;
      -webkit-appearance: none;
    }
    .custom-form__slider-text-wrapper {
      margin-top: 6px;
      width: 280px;
      display: flex;
      justify-content: space-between;
    }
    .custom-form__slider-label {
      color: $brandColor;
    }
    .custom-form__slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: $brandColor;
    }
    .custom-form__slider::-moz-range-thumb {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: $brandColor;
      cursor: pointer;
    }
  }

</style>
