import axios from 'shared/utils/axios'



const state = {
  init: false,
  reviewers: [],
  shops: [],
  products: []
}

const getters = {
}

const mutations = {
  setInit: (state, payload) => state.init = payload,
  setResult: (state, payload) => {
    state.reviewers = payload.reviewers
    state.shops = payload.shops
    state.products = payload.products
  }
}

const actions = {
  async fetchFollowings({commit}) {
    commit('setInit', false)
    try {
      const res = await axios.get('/following.json')
      const followeeIds = { Product: {}, Shop: {}, Reviewer: {} }
      res.data.products.forEach(item => followeeIds['Product'][item.encoded_product_id] = true)
      res.data.shops.forEach(item => followeeIds['Shop'][item.encoded_shop_id] = true)
      res.data.reviewers.forEach(product => followeeIds['Reviewer'][product.encoded_id] = true)

      commit('SharedFollow/updateFolloweeIds', followeeIds, { root: true})
      commit('setResult', res.data)
    } catch (error) {
      console.log(error)
    }
    commit('setInit', true)
  }
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,

  namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
}
