<template>
  <TabWrapper>
    <LoadingSpinner v-if="!metrics" />
    <MatchMedia v-else v-slot="{mobile}">
      <h1 class="tab-reviews__title">{{title}}</h1>
      <div class="tab-reviews__card mt-4" v-if="metrics">
        <div class="tab-reviews__card-row">
          <iconify icon="mdi:eye" class="brand-color" height="18"/>
          <RatingProgressBar
            class="metrics-widget__rating-detail-line-bar"
            baseColor="rgba(95, 190, 0)"
            :activePercentage="transparencyPercentage"/>
          <TooltipPopover class='metrics-widget__rating-tooltip' :name="tooltipsData.transparency.name" />
        </div>
        <div class="tab-reviews__card__text">{{transparencyLabel}}</div>
      </div>
      <div class="tab-reviews__card mt-4">
        <CardMetric :storeName="storeName"/>
      </div>
      <Search v-if="mobile" class="tab-reviews__search" @submit="handleSearchSubmit" @change="handleInputChange"/>
      <FilterSort class="mt-5 mb-5" :storeName="storeName">
        <Search v-if="!mobile" class="tab-reviews__search" @submit="handleSearchSubmit" @change="handleInputChange"/>
      </FilterSort>
      <Reviews :widgetData="widgetData"
               :isLoadingResult="isLoadingResult"
               :listFor="storeName"
               @onPageChange="handlePaginationChange"/>

    </MatchMedia>
  </TabWrapper>
</template>
<script>
import LoadingSpinner from 'rs/components/common/loading_spinner'
import CardMetric from 'rs/components/pages/shop/tabs/_card_metric'
import RatingProgressBar from 'rs/components/common/rating_progress_bar';
import FilterSort from 'rs/components/uncommon/reviews/reviews_filter_sort';
import Search from 'rs/components/uncommon/reviews/search'
import Reviews from 'rs/components/uncommon/reviews/reviews_list'
import {MatchMedia} from "shared/vue_media_query/MatchMedia";
import TabWrapper from 'rs/components/pages/shop/tabs/_tab_wrapper';
import TooltipPopover from 'rs/components/common/tooltip_popover';
import tooltipsData from 'shared/constants/tooltips_data.js'
export default {
  components: {
    MatchMedia, CardMetric, RatingProgressBar, FilterSort,
    Reviews, Search, TabWrapper, LoadingSpinner, TooltipPopover
  },
  props: {
    storeName: {
      type: String,
      required: true
    }
  },
  computed: {
    tooltipsData: () => tooltipsData,
    widgetData() {
      return this.$store.getters[this.storeName + '/widgetData']
    },
    title() {
      return ({
        Shop: 'Store reviews history',
        Product: 'Product reviews history',
      })[this.storeName]
    },
    isLoadingResult() {
      return this.$store.getters[this.storeName + '/isLoadingResult']
    },
    metrics() {
      return this.$store.getters[this.storeName + '/metrics']
    },
    transparencyPercentage() {
      return this.$sharedUtils.roundNumber(this.metrics.transparency_score * 100)
    },
    transparencyLabel() {
      const suffixText = ({
        Shop: 'in total',
        Product: 'for this product',
      })[this.storeName]
      return `Store published ${this.transparencyPercentage}% of verified reviews ` + suffixText
    },
  },
  methods: {
    handleSearchSubmit() {
      this.$store.dispatch(`${this.storeName}/searchReviews`)
    },
    handleInputChange(v) {
      this.$store.commit(`${this.storeName}/setSearchQuery`, v)
    },
    handlePaginationChange(page) {
      this.$store.dispatch(`${this.storeName}/loadReviewsOnPageChange`, {page})
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.loading-spinner {
  margin: 40px auto;
  width: 72px;
  height: 72px;
}

.tab-reviews__title {
  font-size: 16px;
  font-weight: 700;
}

.tab-reviews__card {
  padding: 16px;
  border-radius: 8px;
  background: var(--linear-trust-background, linear-gradient(180deg, #F5F9FD 0%, #F8F9FA 100%));
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.tab-reviews__card-row {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tab-reviews__card__text {
  font-size: 14px;
  font-weight: 400;
}

.metrics-widget__rating-detail-line-bar {
  height: 14px;
  width: 231px;
  cursor: pointer;
}

.tab-reviews__search {
  width: 312px;

  @include respond-to(notdesktop) {
    width: 100%;
    margin-top: 16px;
  }
}

.metrics-widget__rating-tooltip {
  height: 20px;
}
</style>
