<template>
  <div class="filter-sort">
    <div>
      <button type="button" class="filter-sort__filter-button" @click="toggleFilterSidebar(true)">
        Filter by
      </button>
      <router-link v-if='didAnyFilterChange' class="filter-sort__clear-button" :to="linkToParams()">Clear</router-link>
    </div>

    <Sort />
  </div>
</template>

<script>
import Sort from 'rs/components/common/search_sort'
import {sanitizeParams} from "rs/store/store_utils/search_utils";
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState("SharedCurrentReviewer", ['filterSidebarOpen']),
    ...mapState("Search", ['defaultFilters', 'searchParams']),
    didAnyFilterChange() {
      return this.$store.getters['Search/didAnyFilterChange']
    }
  },
  components: {Sort},
  methods: {
    linkToParams() {
      return {
        name: 'PageSearch',
        query: sanitizeParams({
          ...this.defaultFilters,
          q: this.searchParams.q
        })
      }
    },
    toggleFilterSidebar(bool) {
      this.$store.dispatch('Search/toggleFilterSidebar', bool)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.filter-sort {
  height: 36px;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  justify-content: space-between;
  @include respond-to(notmobile) {
    justify-content: flex-end;
  }
}

.filter-sort__filter-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: $primaryFontColor;
  cursor: pointer;
  font-weight: 600;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: $lightGreenColor;

  @include respond-to(notmobile) {
    display: none;
  }
}
.filter-sort__clear-button {
  color: $newBrandColor;
  margin-left: 8px;
}
</style>
