<template>
  <section class="no-search-result">
    <div class="no-search-result__title">No results match the filter criteria</div>
    <p v-if="hasFilters" class="no-search-result__summary">The active filters are hiding all products</p>
    <button
        v-if="hasFilters"
        type="button"
        @click="clearFilter('all', true)"
        class="no-search-result__button"
    >
      Reset Filters
    </button>
  </section>
</template>

<script>
  export default {
    props: ['hasFilters', 'clearFilter']
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/variables_new';
  @import '~PlatformStyles/abstract/mixins';

  .no-search-result {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    @include respond-to(notmobile){
      align-items: center;
    }
  }
  .no-search-result__title {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    @include respond-to(mobile) {
      font-size: 20px;
    }
  }
  .no-search-result__summary {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
  .no-search-result__button {
    margin-top: 12px;
    width: 208px;
    height: 40px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 800;
    background-color: #FFFFFF;
    border-radius: 25px;
    color: $brandColor;
    border: 2px solid $brandColor;
    cursor: pointer;
  }
</style>

