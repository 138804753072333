<template>
  <div class="filter-modal" ref="component">
    <div class="filter-modal__header">Filter by</div>
    <div class="filter-modal__close-button material-icons" @click="close">close</div>

    <!-- Source -->
    <Dropdown v-if="tempReviewSource" class="filter-modal__filter-dropdown-wrapper">
      <select @change="selectSource" class="filter-modal__filter-dropdown" :value="tempReviewSource">
        <option v-for="(label, key, index) in reviewSources" :value="key">
          {{ label }}
        </option>
      </select>
    </Dropdown>

    <!--  Verified -->
    <template v-if="verifiedOnly !== null">
      <div class="d-flex mt-5 pointer" @click="tempVerifiedOnly = !tempVerifiedOnly">
        <div class="filter-modal__filter-checkbox mt-1"
             :class="{'filter-modal__filter-checkbox--checked': tempVerifiedOnly}">
          <span class="filter-modal__filter-checked-icon material-icons" v-show="tempVerifiedOnly">check</span>
        </div>
        <div class="filter-modal__filter-stars">Show only verified reviews.</div>
      </div>
    </template>

    <!-- Ratings -->
    <div class="filter-modal__filter-label">Star rating</div>
    <div class="filter-modal__filter-stars-row" v-for="rating in templateRating" :key="rating"
         @click="toggleCheckbox(rating)">
      <div class="filter-modal__filter-checkbox"
           :class="[includeRating(rating) && 'filter-modal__filter-checkbox--checked']">
        <span class="filter-modal__filter-checked-icon material-icons" v-show="includeRating(rating)">check</span>
      </div>
      <StarsRow class="filter-modal__filter-stars" :rating="rating"/>
    </div>

    <!-- Review types -->
    <template v-if="tempReviewTypes">
      <div class="filter-modal__filter-label">Show reviews for:</div>
      <div class="filter-modal__filter-stars-row" @click="toggleReviewType(item)" v-for="item in Object.keys(reviewTypes).slice(1,3)" :key="item">
        <div class="filter-modal__filter-checkbox"
             :class="[checkedReviewType(item) && 'filter-modal__filter-checkbox--checked']">
          <span class="filter-modal__filter-checked-icon material-icons" v-show="checkedReviewType(item)">check</span>
        </div>
        <div class="filter-modal__filter-stars">{{reviewTypes[item]}}</div>
      </div>
    </template>
    <div class="filter-modal__cta-buttons">
      <button class="filter-modal__submit-button" type="button" @click="applyFilter" :disabled="disabledApplyFilter">Go</button>
      <button class="filter-modal__clear-button" type="button" @click="clearFilter">Clear Filter</button>
    </div>
  </div>
</template>
<script>
import StarsRow from 'rs/components/common/star_rating';
import Dropdown from 'rs/components/common/dropdown';
import {xor} from "lodash";

export default {
  components: {StarsRow, Dropdown},
  props: {
    filterQueries: { type: Object, required: true },
    reviewTypes: { type: Object, required: true },
    reviewSources: {type: Object, required: true },
    verifiedOnly: {type: Boolean, default: null },
    setPosition: {type: Function, default: () => {}},
  },
  data() {
    return {
      tempRatings: this.filterQueries.filter_rating,
      tempReviewTypes: (function(filterQueries, reviewTypes){
        const review_type = filterQueries.review_type
        // filterQueries.review_type is a string ('all-reviews' or 'A-reviews,B-reviews,C-reviews,...')
        // reviewTypes is a hash (check definition in Store)
        if (!review_type) return null
        const originalTypes = Object.keys(reviewTypes);
        return review_type === originalTypes[0]
          ? originalTypes.slice(1)
          : review_type.split(',')
      })(this.filterQueries, this.reviewTypes),
      tempReviewSource: this.filterQueries.review_source,
      tempVerifiedOnly: !!this.verifiedOnly,
    }
  },
  computed: {
    templateRating() {
      return [5, 4, 3, 2, 1]
    },
    disabledApplyFilter() {
      return this.tempReviewTypes && this.tempReviewTypes.length === 0
    }
  },
  methods: {
    includeRating(rating) {
      return this.tempRatings.includes(rating)
    },
    checkedReviewType(value){
      return this.tempReviewTypes.length === 2 || this.tempReviewTypes.includes(value)
    },
    toggleReviewType(item){
      this.tempReviewTypes = xor(this.tempReviewTypes, [item]);
    },
    close() {
      this.$emit('close')
    },
    clearFilter() {
      this.$emit('clearFilter')
    },
    toggleCheckbox(rating) {
      this.tempRatings = xor(this.tempRatings, [rating]);
    },
    applyFilter() {
      const params = {
        filter_rating: this.tempRatings,
        verified_only: this.tempVerifiedOnly,
      }
      if (this.tempReviewTypes) {
        params.review_type = this.tempReviewTypes.length === 1 ? this.tempReviewTypes[0] : 'all-reviews'
      }
      if (this.tempReviewSource) {
        params.review_source = this.tempReviewSource
      }
      this.$emit('applyFilter', params)
    },
    selectSource(e) {
      this.tempReviewSource = e.target.value
    }
  },
  mounted() {
    this.setPosition(this.$refs.component)
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';

.filter-modal {
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  position: absolute;
  top: 52px;
  left: 0;
  border-radius: 10px;
  background: white;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  width: 248px;
  z-index: 1;
}

.filter-modal__header {
  font-weight: 700;
}

.filter-modal__close-button {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

.filter-modal__filter-label {
  margin-top: 20px;
  margin-bottom: 16px;
}

.filter-modal__filter-stars-row {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
}

.filter-modal__cta-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.filter-modal__submit-button {
  width: 84px;
  font-size: 14px;
  padding: 10px 16px;
  background-color: $lightGreenColor;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
}

.filter-modal__clear-button {
  margin-left: 20px;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: $newBrandColor;
  border: none;
}

.filter-modal__filter-checkbox {
  border-radius: 5px;
  border: 1px solid $newBrandColor;
  margin-right: 12px;
  width: 18px;
  height: 18px;
}

.filter-modal__filter-checkbox--checked {
  background-color: $newBrandColor;
}

.filter-modal__filter-checked-icon {
  font-size: 16px;
  color: white
}
.filter-modal__filter-dropdown-wrapper {
  margin: 24px 0 -4px 0;
}
.filter-modal__filter-dropdown {
  height: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #CDCCCC;
  border-radius: 4px;
  color: $primaryFontColor;
  background: transparent;
  &:hover, &:focus, {
    border: 1px solid $newBrandColor;
    outline: 1px solid $newBrandColor;
  }
}
</style>
