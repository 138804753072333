<template>
  <div class="dispute-page">
    <div class="dispute-wrapper__heading">Your messages</div>
    <div class="dispute-wrapper__description">Please use our messaging system so we can keep track of all your
      conversations with the store ensuring transparent conversations for enhanced accountability and efficient support.
    </div>
    <loading-spinner v-if="!dispute"/>
    <div v-else class="dispute-wrapper">
      <div class="dispute-wrapper__container">
        <Dispute class='dispute-wrapper__inner' :dispute="dispute" @cancel="goBack"/>
      </div>
    </div>
  </div>
</template>
<script>
import Dispute from 'shared/vue_components/arbitration/dispute.vue'
import actions from 'rs/components/pages/arbitrator/arbitrator_actions.js'

export default {
  components: {Dispute},
  provide: {arbitrationContext: actions},
  data() {
    return {
      dispute: null
    }
  },
  methods: {
    goBack() {
      this.$router.push({name: this.$routes.arbitratorShow.name, query: {redesign: 1}})
    },
  },
  created() {
    actions.fetchDispute(this.$route.params.disputeId).then(dispute => this.dispute = dispute)
  },
}
</script>
<style lang="scss" scoped>
.dispute-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 712px;
  font-weight: 400;
  font-size: 16px;
  margin: 20px auto;
}

.loading-spinner {
  align-self: center;
  margin: 100px;
  width: 100px;
  height: 100px;
}

.dispute-wrapper {
  width: 100%;
  background-color: white;
  margin-top: 30px;
}

.dispute-wrapper__container {
  margin: 0 auto 32px auto;
  padding: 20px;
  max-width: 656px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dispute-wrapper__heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
}

.dispute-wrapper__description {
  margin-top: 16px;
  font-weight: 400;
}

.dispute-wrapper__inner {
  margin-top: 28px;
  width: 100%;
}
</style>
