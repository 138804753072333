<template>
  <div class="how-it-works_blocks__wrapper">
    <HeadingSection :title="title" :description="description" />
    <HowItWorksBlocks />
  </div>
</template>
<script>
import HeadingSection from 'rs/components/common/heading_section'
import HowItWorksBlocks from 'rs/components/pages/how_it_works/_how_it_works_blocks_section'

export default {
  components: {HeadingSection, HowItWorksBlocks},
  computed: {
    title: () => "How It Works",
    description: () => "Let's take a quick tour of what you can do on the Judge.me Reviews platform",
  },
  created(){
    this.$store.dispatch('Meta/getMeta')
  },
}
</script>
