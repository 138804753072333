<template>
  <div class="profile-interest-element">{{text}}</div>
</template>
<script>
export default {
  props: ['text']
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';
.profile-interest-element {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 600;
  color: $brandColor;
  border: 1px solid $lightGreenColor;
  border-radius: 12px;
  white-space: nowrap;
  overflow: hidden;
}
</style>
