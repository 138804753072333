<template>
  <div class="following-page-card">
    <HeartBtn class='material-icons right following-page-card__heart-btn' :followeeClass='itemClass' :followeeEncodedId='followeeEncodedId' />
    <div class='following-page-card__avatar'>
      <UserAvatar v-if="itemClass==='Reviewer'"
                  :avatar_image_url="item.avatar_image_url" :reviewer_initial='item.name[0]'
                  :gravatar_hash='item.gravatar_hash' :verified_buyer='item.verified_buyer' />
      <div v-else-if="itemClass==='Shop'" >
        <div v-if="item.logo">
          <img class="following-page-card__image" v-bind:src="item.logo" :alt="item.shop_name" loading='lazy'>
        </div>
        <div v-else class='material-icons' >
          store
        </div>
      </div>
      <img class="following-page-card__image" v-else-if="itemClass==='Product' && item.image_url" :src='item.image_url' :alt="item.title" />
      <div class='following-page-card__image material-icons' v-else-if="itemClass==='Product' && !item.image_url">
        inventory
      </div>
    </div>
    <div class='following-page-card__name'>
      <ExternalLink :href="itemLinkHandler.path" :text="itemLinkHandler.name"/>
    </div>
  </div>
</template>

<script>
  import HeartBtn from 'rs/components/common/heart_btn'
  import UserAvatar from 'rs/components/common/user_avatar'
  import ExternalLink from 'rs/components/common/external_link'

  export default {
    components: { HeartBtn, UserAvatar, ExternalLink },
    props: ['itemClass', 'item'],
    computed: {
      itemLinkHandler(){
        switch(this.itemClass){
          case 'Reviewer':
            return { name: this.item.name, path: this.item.profile_path }
          case 'Shop':
            return { name: this.item.shop_name, path: '/reviews/' + this.item.review_site_slug }
          case 'Product':
            return { name: this.item.title, path: '/reviews/' + this.item.review_site_slug + '/products/' + this.item.handle }
        }
      },
      followeeEncodedId(){
        switch(this.itemClass){
          case 'Reviewer':
            return this.item.encoded_id
          case 'Shop':
            return this.item.encoded_shop_id
          case 'Product':
            return this.item.encoded_product_id
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .following-page-card {
    display: flex;
    flex-direction: row;
    padding: 14px 16px;
    width: 100%;
    height: 58px;

  }
  .following-page-card__heart-btn {
    display: inline-block;
    width: 32px;
    height: 32px;
    min-width: 32px;
    text-align: center;
    line-height: 32px;
    color: $brandColor;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      opacity: $hoverOpacity;
    }
  }
  .following-page-card__avatar {
    margin: 0 0 0 10px;
  }
  .following-page-card__image {
    border: 1px solid #FBFBFB;
    border-radius: 5px;
    width: 36px;
    height: 36px;
    text-align: center;
  }
  .following-page-card__name {
    margin: 0 0 0 10px;
    display: flex;
    align-items: center;
    color: $brandColor;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    @include text-elipsis();
  }
</style>
