<template>
  <div class="shipping-country">
    <div class="shipping-country__label">
      Shipping to:
    </div>

    <div class="shipping-country__select">
      <select class="shipping-country__select-dropdown" v-model="selectedCountryCode" @change="onCountryChange">
        <option v-for="country in countryCodes" :value="country" :selected="selectedCountryCode === country">
          {{ shippingCountries[country] }}
        </option>
      </select>
      <i class="material-icons shipping-country__select__arrow">expand_more</i>
    </div>

    <div class="shipping-country__flag">
      <CountryFlag :alt='altFlag' :countryCode='selectedCountryCode' v-if="selectedCountryCode !== '*' " />
    </div>

  </div>

</template>

<script>
  import CountryFlag from 'rs/components/common/country_flag'
  import shippingCountries from 'shared/constants/country_list_map'

  export default {
    name: 'ShippingCountry',
    template: 'ShippingCountry',
    components: { CountryFlag },
    props: ['selected'],
    data(){
      return {
        shippingCountries: shippingCountries,
        countryCodes: Object.keys(shippingCountries),
        selectedCountryCode: this.selected || '*',
      }
    },
    computed: {
      altFlag(){
        return  this.shippingCountries[this.selected]
      },
    },
    methods: {
      onCountryChange(event) {
        this.$emit('selected', event.target.value);
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/variables_new';

  $_height: 28px;

  .shipping-country {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .shipping-country__label {
    display: inline-block;
    font-weight: 400;
    font-size: 16px;
    color: $brandColor;
    vertical-align: middle;
  }

  .shipping-country__select__arrow {
    color: $brandColor;
    z-index: -1
  }

  .shipping-country__select {
    position: relative;
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;

    i {
      position: absolute;
      right: 0;
      line-height: $_height;
    }

    select {
      width: 160px;
      height: $_height;
      padding-left: 5px;
      padding-right: 30px;
      font-size: 16px;
      border: 1px solid $brandColor;
      box-sizing: border-box;
      border-radius: 5px;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      background-color: transparent;
      cursor: pointer;

      &:focus{
        outline: none;
      }
    }
    select::-ms-expand { // for IE only
      display: none;
    }
  }


  .shipping-country__flag {
    display: inline-block;
    height: 18px;
    margin-left: 8px;
    vertical-align: middle;

    img {
      max-width: #{$_height * 1.5};
      height: 18px;
    }
  }
</style>
