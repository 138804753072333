<template>
  <section class="my-profile-collections" ref="component">
    <div class="mpc__padding-sides mpc__header--recommendation" v-if="recommendationsData.recommendations.length > 0">
      <button v-if="!recommendationView || recommendationView === 'public'" class="btn pf-primary-button rs-font-16"
              @click="recommendationView = 'edit'">Edit Recommendations
      </button>
      <button v-if="recommendationView === 'edit'" class="btn pf-secondary-button rs-font-16"
              @click="recommendationView = 'public'">Public View
      </button>
      <button v-if="recommendationView === 'edit'" class="btn pf-primary-button rs-font-16"
              @click="recommendationView = null">Go
        Back
      </button>
    </div>
    <CardsList v-if="recommendationView" class="no-border-top" @pageChange="onPageChangeRecommendation"
               :totalPages="recommendationsData.totalPages" :currentPage="recommendationsData.currentPage">
      <component v-for="(card, idx) in recommendationsData.recommendations" :recommendation="card" :key="card.id"
                 :is="recommendationComponent" class="mpc__padding-sides">
      </component>
    </CardsList>
    <template v-else>
      <div class="mpc__padding-sides mb-3">
        <div class="mpc__header">
          <div class="mpc__tab-button" @click="changeTab(idx)" :class="{ 'mpc__tab-button--active': activeTab === idx }"
               v-for="(tab,idx) in tabs" :key="idx">
            <iconify :icon="tab.icon" class="tab-icon" height="16"
                     :class="{ 'tab-icon--active': activeTab === idx }"></iconify>
            <span class="mpc__tab-button-text">{{ tab.text }}</span>
          </div>
        </div>
        <div class="mpc__tab-description">
          <!--   reviews   -->
          <template v-if="activeTab === 0">
            <p v-if="isReviewsTabAndHasReviews">Publish, edit and share reviews.</p>
            <template v-else>
              <p>You have 0 reviews.</p>
              <router-link to="/categories" class="btn pf-primary-button mt-4 mpc__description-button" no-margin>
                Discover Products
              </router-link>
            </template>
          </template>
          <!--   orders   -->
          <template v-else-if="activeTab === 1">
            <p>You have done {{ $sharedUtils.pluralize(ordersCount, 'order') }} on stores using the Judge.me Product
              Reviews app.</p>
            <p>Discover more products or write reviews to older orders.</p>
            <router-link to="/categories" class="btn pf-primary-button mt-4 mpc__description-button">Discover
              {{ ordersCount ? 'More' : '' }} Products
            </router-link>
          </template>
          <!--   rewards   -->
          <template v-else-if="activeTab === 2">
            <p>You have {{ $sharedUtils.pluralize(rewardsCount, 'reward') }} sent by stores.</p>
            <router-link to="/categories" class="btn pf-primary-button mt-4 mpc__description-button">Discover
              {{ rewardsCount ? 'More' : '' }} Products
            </router-link>
          </template>
        </div>
      </div>
      <FilterSort class="mpc__padding-sides mt-5 mb-5" :storeName="storeName" v-if="this.activeTab === 0"/>
      <CardsList v-if="(activeTab !== 0 || isReviewsTabAndHasReviews) && activeTab !== 3"
                 v-bind="cardsListCommonProps.props" v-on="cardsListCommonProps.listeners">
        <component class="mpc__padding-sides" v-for="(card, idx) in cardsListCommonProps.slotItems"
                   :is="cardsListCommonProps.slotComponent" :card="card" editable :key="card.public_id || idx">
        </component>
      </CardsList>
      <CardsList class="mt-5" v-else-if="activeTab === 3" v-bind="cardsListCommonProps.props"
                 v-on="cardsListCommonProps.listeners">
        <DisputeList :disputes="cardsListCommonProps.slotItems"/>
      </CardsList>
    </template>
  </section>
</template>
<script>
import FilterSort from 'rs/components/uncommon/reviews/reviews_filter_sort';
import CardsList from 'rs/components/uncommon/profile/_cards_list'
import DisputeList from 'rs/components/pages/private_profile/_dispute_list'
import ReviewCard from 'rs/components/uncommon/profile/_review_card'
import OrderCard from 'rs/components/pages/private_profile/_order_card'
import RewardCard from 'rs/components/pages/private_profile/_reward_card'
import RecommendationCard from 'rs/components/uncommon/profile/_recommendation_card'
import EditRecommendationCard from 'rs/components/pages/private_profile/_edit_recommendation_card'
const TABS = [
  {
    text: 'My reviews',
    icon: 'iconamoon:star-fill',
  },
  {
    text: 'My orders',
    icon: 'mdi:tag',
  },
  {
    text: 'My rewards',
    icon: 'mdi:smiley',
  },
  {
    text: 'My issues log',
    icon: 'clarity:bubble-exclamation-solid',
  }
]

const PAGE_CHANGE_ACTIONS = {
  order: '/loadOrders',
  reward: '/loadRewards',
  recommendation: '/paginateRecommendation',
  review: '/loadReviewsOnPageChange',
  dispute: '/loadDisputesOnPageChange',
}
export default {
  components: {
    FilterSort,
    CardsList,
    ReviewCard,
    RewardCard,
    OrderCard,
    RecommendationCard,
    EditRecommendationCard,
    DisputeList
  },
  computed: {
    storeName() {
      return this.$store.getters['Profile/storeName'];
    },
    tabs: () => TABS,
    profileData() {
      return this.$store.getters[this.storeName + '/profileData']
    },
    reviewsData() {
      return this.$store.getters[this.storeName + '/reviewsData']
    },
    doneLoadMoreReviews() {
      return this.$store.getters[this.storeName + '/doneLoadMoreReviews']
    },
    disputesCount() {
      return this.$store.getters[this.storeName + '/disputesCount']
    },
    disputesData() {
      return this.$store.getters[this.storeName + '/disputesData']
    },
    doneLoadDisputes() {
      return this.$store.getters[this.storeName + '/doneLoadDisputes']
    },
    doneLoadMoreDisputes() {
      return this.$store.getters[this.storeName + '/doneLoadMoreDisputes']
    },
    rewardsCount() {
      return this.$store.getters[this.storeName + '/rewardsCount']
    },
    rewardsData() {
      return this.$store.getters[this.storeName + '/rewardsData']
    },
    doneLoadRewards() {
      return this.$store.getters[this.storeName + '/doneLoadRewards']
    },
    doneLoadMoreRewards() {
      return this.$store.getters[this.storeName + '/doneLoadMoreRewards']
    },
    ordersCount() {
      return this.$store.getters[this.storeName + '/ordersCount']
    },
    doneLoadOrders() {
      return this.$store.getters[this.storeName + '/doneLoadOrders']
    },
    doneLoadMoreOrders() {
      return this.$store.getters[this.storeName + '/doneLoadMoreOrders']
    },
    ordersData() {
      return this.$store.getters[this.storeName + '/ordersData']
    },
    recommendationsData() {
      return this.$store.getters[this.storeName + '/recommendationPaginated']
    },
    recommendationComponent() {
      return this.recommendationView === 'public' ? RecommendationCard : EditRecommendationCard
    },
    isReviewsTabAndHasReviews() {
      return this.activeTab === 0 && !!this.reviewsData.totalCount
    },
    cardsListCommonProps() {
      switch (this.activeTab) {
        case 0:
          return {
            props: {
              totalPages: this.reviewsData.totalPages,
              currentPage: this.reviewsData.currentPage,
              isLoading: !this.doneLoadMoreReviews,
            },
            listeners: {
              pageChange: this.onPageChange.bind(this, 'review')
            },
            slotItems: this.reviewsData.reviews,
            slotComponent: ReviewCard,
          }
        case 1:
          return {
            props: {
              totalPages: this.ordersData.totalPages,
              currentPage: this.ordersData.currentPage,
              isLoading: !this.doneLoadMoreOrders,
            },
            listeners: {
              pageChange: this.onPageChange.bind(this, 'order')
            },
            slotItems: this.ordersData.orders,
            slotComponent: OrderCard,
          }
        case 2:
          return {
            props: {
              totalPages: this.rewardsData.totalPages,
              currentPage: this.rewardsData.currentPage,
              isLoading: !this.doneLoadMoreRewards,
            },
            listeners: {
              pageChange: this.onPageChange.bind(this, 'reward')
            },
            slotItems: this.rewardsData.rewards,
            slotComponent: RewardCard,
          }
        case 3:
          return {
            props: {
              totalPages: this.disputesData.totalPages,
              currentPage: this.disputesData.currentPage,
              isLoading: !this.doneLoadMoreDisputes,
            },
            listeners: {
              pageChange: this.onPageChange.bind(this, 'dispute')
            },
            slotItems: this.disputesData.disputes,
          }
        default:
          return {}
      }
    }
  },
  data() {
    return {
      activeTab: this.$route.query.tab ? parseInt(this.$route.query.tab) : 0,
      recommendationView: null,
    }
  },
  methods: {
    changeTab(idx) {
      this.activeTab = idx
      this.$updateQueryUrl({tab: idx}) // hack to update url without reloading page
      this.scrollToTop()
    },
    scrollToTop() {
      // instant scroll up to top of list => show spinner => show result
      // otherwise the actions complete too fast window will not scroll in time and will stay at bottom (happen when you're at bottom).
      this.$sharedUtils.scrollToDistanceWithHeaderOffset(this.$refs.component.getBoundingClientRect().top, {
        smooth: false,
        useOffset: true
      })
    },
    onPageChange(actionType, page) {
      this.scrollToTop()
      this.$nextTick(() => {
        this.$store.dispatch(this.storeName + PAGE_CHANGE_ACTIONS[actionType], {page})
      })
    },
    onPageChangeRecommendation(page) {
      this.onPageChange('recommendation', page)
    },
    fetchData(index) {
      if (index === 1 && this.ordersCount > 0 && !this.doneLoadOrders) {
        this.$store.dispatch(this.storeName + '/loadOrders')
      } else if (index === 2 && this.rewardsCount > 0 && !this.doneLoadRewards) {
        this.$store.dispatch(this.storeName + '/loadRewards')
      } else if (index === 3 && this.disputesCount > 0 && !this.doneLoadDisputes) {
        this.$store.dispatch(this.storeName + '/loadDisputes')
      }
    }
  },
  watch: {
    activeTab(index) {
      this.fetchData(index)
    }
  },
  created() {
    this.fetchData(this.activeTab)
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.my-profile-collections {
  width: 100%;
  background: white;
  border-radius: 0;
  padding: 0 0 24px 0;
  overflow: hidden;
  @include respond-to(desktop) {
    border-radius: 12px;
    border: $newBorderStyle;
  }
}

.mpc__padding-sides {
  padding-right: 16px;
  padding-left: 16px;
  @include respond-to(desktop) {
    padding-right: 28px;
    padding-left: 28px;
  }
}

.mpc__header {
  margin-top: 20px;
  width: 100%;
  overflow-x: auto;
  display: flex;
  gap: 12px;
  margin-bottom: 32px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }

  @include respond-to(mobile) {
    left: calc(-50vw + 50%);
    margin-left: -16px;
    width: 100vw;
    padding: 0 16px;
  }
}

.mpc__header--recommendation {
  background: #E0F2F2;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.mpc__tab-button {
  display: flex;
  min-width: max-content;
  padding: 8px 14px;
  font-weight: 700;
  font-size: 14px;
  border: 1px solid #E8E8E8;
  border-radius: 10px;
  background: white;
  color: $primaryFontColor;
  cursor: pointer;
}

.mpc__tab-button--active {
  background: linear-gradient(180deg, #339999 0%, #2D8396 100%);
  color: white;
}

.mpc__tab-button-text {
  align-self: center;
  margin-left: 4px;
}

.mpc__tab-description {
  font-size: 16px;
  font-weight: 400;
  color: $primaryFontColor;
}

.mpc__description-button {
  width: 248px;
  margin-bottom: 24px;

  &[no-margin] {
    margin-bottom: 0;
  }
}

.no-border-top {
  border-top: none;
}

.tab-icon {
  color: $brandColor;
  font-size: 16px;
  align-self: center;
}

.tab-icon--active {
  color: white;
}
</style>
