<template>
  <div class="notification-card" v-if="show && hasNotification">
    <div @click="show = false" class="notification-card__close-icon">
      <iconify icon="iconamoon:close-duotone" height="24"/>
    </div>
    <div class="notification-card__container">
      <div class="rs-weight-700 rs-font-20">Your issue has been registered!</div>
      <div class="mt-3">We’ve registered your issue and informed {{ notificationData.shopName }} about it. Click on "My
        Issues Log" to view the issue. <a :href="learnMoreUrl" target="_blank">Learn more</a></div>
      <div v-if="notificationData.shopResponseTime" class="notification-card__reply-time">
        <iconify icon="material-symbols:speed" height="20" color="#339999"/>
        <span>Store aims to reply in less than {{ notificationData.shopResponseTime }}.</span>
      </div>
      <div v-else class="notification-card__reply-time">
        We encourage stores to reply to issues within 5 business days, but please note that stores can have their own
        processing times.
      </div>
    </div>
  </div>
</template>
<script>
import {LINKS} from 'shared/vue_components/arbitration/constants'

export default {
  computed: {
    learnMoreUrl: () => LINKS.disputeIntro,
    pageNotification() {
      return this.$store.getters['Notification/getData'](this.$route.name);
    },
    notificationData() {
      return this.pageNotification && this.pageNotification.createdDispute;
    },
    hasNotification() {
      return this.pageNotification && Object.keys(this.pageNotification).length > 0
    }
  },
  data() {
    return {
      show: true
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';

.notification-card {
  width: 100%;
  border-radius: 10px;
  background: linear-gradient(180deg, #D0ECEC 0%, #BBDFF3 100%);
  padding: 30px;
  position: relative;
  @include respond-to(mobile) {
    margin-bottom: 20px;
  }
}

.notification-card__close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
.notification-card__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 400;
}
.notification-card__reply-time {
  display: flex;
  gap: 4px;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  margin-top: 28px;
}
</style>
