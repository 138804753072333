<!-- render review data source from /serializers/review_serializers/review_widget.rb -->
<template>
  <section class='review'>
    <div v-if='!shouldHideReviewer' class='review__header'>
      <UserAvatar :avatar_image_url="review.avatar_image_url" :reviewer_initial='review.reviewer_initial'
                  :gravatar_hash='review.gravatar_hash' :verified_buyer='review.verified_buyer' />

      <div class='review__reviewer-info' :class="{'vertical-middle': !review.location_country}">
        <component class='vertical-middle' v-bind="reviewerLink">{{ review.reviewer_name }}</component>
        <TooltipPopover class='d-inline-block' v-if='review.verified_purchase' :name="tooltipsData.review_verified.name">
          <template #icon><VerifiedBadge class="pointer"/></template>
        </TooltipPopover>
        <div class='review__location'>
          {{ review.location_country }}
          <CountryFlag v-if='review.location_country_code' class='review__location-flag' :alt='review.location_country' :countryCode='review.location_country_code' />
        </div>
      </div>

      <div class='review__timestamp'>
        <div class="review__pinned-icon" v-if="shouldShowPin && review.pinned" >
          <TooltipPopover icon="pin" :name="tooltipsData.review_pinned.name"/>
        </div>
        {{ createdAtRelative }}
      </div>
    </div>
    <slot name="product"></slot>

    <ReviewImages v-if='showMedia' :media='combinedMedia' :encodedProductId="review.encoded_product_id" :largeSize="imageLargeSize" @openGallery="openGallery"/>

    <StarRating :rating='review.rating' class='review__rating' />
    <strong class='review__title' v-html="review.title"></strong>

    <div v-html='review.body_html' class='review__body' :class="{ 'review__body--show-all' : shouldShowAllBody }" ref='reviewBodyEle'>
    </div>
    <span class='review__body-read-more' @click='showAll'>Read More</span>

    <ReviewShopReply v-if="review.reply_content" :review="review" />
    <CustomForm v-if="!hideCustomForm" :review='review'/>
    <div class="d-flex-column gap-1">
      <ReviewLegend v-if="reviewSource" :label="reviewSource.label" :tooltip="reviewSource.tooltip" />
      <ReviewLegend v-if="reviewCoupon" :title="reviewCoupon.title" :label="reviewCoupon.label" :tooltip="reviewCoupon.tooltip" />
    </div>
    <ReviewReaction :review="review" />

  </section>
</template>

<script>
  import UserAvatar from 'rs/components/common/user_avatar'
  import ReviewImages from 'rs/components/common/review_images'
  import ReviewLegend from 'rs/components/common/review_legend'
  import StarRating from 'rs/components/common/star_rating'
  import ReviewShopReply from 'rs/components/common/review_shop_reply'
  import ReviewReaction from 'rs/components/common/review_reaction'
  import VerifiedBadge from 'rs/components/common/verified_badge'
  import CountryFlag from 'rs/components/common/country_flag'
  import CustomForm from 'rs/components/common/review_custom_form'
  import TooltipPopover from 'rs/components/common/tooltip_popover';
  import {customFormat} from 'shared/utils/timeago';
  import {enrichMediaObject} from 'rs/store/shared/gallery_utils';
  import tooltipsData from 'shared/constants/tooltips_data.js'
  import {reviewSourcesMap} from 'shared/constants/review_sources.js'

  export default {
    components: {
        UserAvatar,
        ReviewImages,
        StarRating,
        ReviewShopReply,
        VerifiedBadge,
        CountryFlag,
        ReviewReaction,
		    CustomForm,
        ReviewLegend,
        TooltipPopover,
      },
    props: ['review', 'shouldHideReviewer', 'hideCustomForm', 'imageLargeSize'],
    data() {
      return {
        shouldShowAllBody: false,
      }
    },
    computed: {
      tooltipsData: () => tooltipsData,
      reviewSource() {
        return reviewSourcesMap[this.review.collected_source]
      },
      reviewCoupon() {
        return this.review.has_coupon ? {
          title: 'Review rewarded with a coupon',
          label: 'Review rewarded with discount on future purchases',
          tooltip: 'Merchants can encourage buyers to leave reviews by offering coupons that provide a small discount on their next purchase. The coupons can be granted under specific conditions, such as requiring a review to include a photo to qualify for the discount.'
        } : null
      },
      reviewerLink() {
        if (!this.review.reviewer_public_slug && !this.review.reviewer_public_id) {
          return {
            is: 'span',
          }
        }
        return {
          is: 'router-link',
          to: this.review.reviewer_public_slug ? ('/' + this.review.reviewer_public_slug) :  ('/profile/' + this.review.reviewer_public_id)
        }
      },
      shouldShowPin(){
        return this.$route.name === 'PageProduct'
      },
      createdAtRelative() { return customFormat(this.review.created_at)() },
      showMedia(){
        return !!((this.review.pictures_urls && this.review.pictures_urls.length)
            || (this.review.video_external_ids && this.review.video_external_ids.length)
            || (this.review.media_platform_hosted_video_infos && this.review.media_platform_hosted_video_infos.length));
      },
      combinedMedia() {
        return enrichMediaObject(this.review)
      },
    },

    methods: {
      showAll() {
        this.shouldShowAllBody = true
      },
      openGallery(v) {
        if (this.$listeners && this.$listeners.openGallery) {
          this.$emit('openGallery', v)
        } else {
          this.$store.dispatch('Gallery/openGallery', {review: this.review, ...v})
        }
      }
    },

    mounted() {
      if (this.$refs.reviewBodyEle.scrollHeight > this.$refs.reviewBodyEle.clientHeight) {
        this.$refs.reviewBodyEle.className = 'review__body review__body--need-show-more'
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';
  .review {
    padding-bottom: 12px;
  }
  .review__reviewer-info {
    display: inline-block;
    vertical-align: top;
    margin-left: 8px;
    line-height: 1;
    height: 48px;
  }

  .review__location {
    font-size: 12px;
    color: $secondaryFontColor;
    margin-top: 4px;
  }

  .review__location-flag {
    display: inline-block;
    height: 8px;
    max-width: 12px;
    vertical-align: middle;
  }

  .review__timestamp {
    position: relative;
    font-size: 12px;
    line-height: 16px;
    float: right;
    color: $secondaryFontColor;
  }

  .review__rating {
    margin: 16px 0 12px 0;
  }

  .review__title {
    font-weight: bold;
  }

  .review__body {
    margin-top: 4px;

    &:not(.review__body--show-all) {
      overflow: hidden;
      @include respond-to-ios {
        max-height: 12em;
        text-overflow: ellipsis;
      }
      @include respond-to-nonios {
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
      }
    }
  }

  .review__body-read-more {
    display: none;
  }
  .review__body--need-show-more + .review__body-read-more {
    display: inline;
    color: $brandColor;
    text-decoration: underline;
    cursor: pointer;
  }

  .review__thumbs-reaction {
    margin: 12px 0;
    text-align: right;
  }

  .review__pinned-icon {
    position: absolute;
    top: -2px;
    left: -28px;
  }
</style>

