<template>
  <section class="homepage-statics_section-wrapper">
    <StatsSection/>
    <section class="homepage-guarantee has-max-width">
      <p class="homepage-guarantee__heading">{{ heading }}</p>
      <h1 class="homepage-guarantee__sub-heading">{{ subHeading }}</h1>
      <div class="homepage-guarantee__circles--wrapper">
        <div class="homepage-guarantee__circles--background"></div>
      </div>
      <div class="homepage-guarantee__content-wrapper">
        <div class="homepage-guarantee__content" v-for="(card, idx) in guaranteeCards" v-bind:key="idx">
          <div class="homepage-guarantee__content-left">
            <h3 class="homepage-guarantee__content--heading">{{ card.heading }}</h3>
            <p>{{ card.content }}</p>
          </div>
          <div class="homepage-guarantee__content-right">
            <img class="homepage-guarantee-card__image" :src="card.imageUrl" alt="guarantee image"/>
          </div>
        </div>
      </div>
      <router-link :to="{name: 'PageHowItWorks'}" class="btn pf-secondary-button homepage-guarantee__how-it-works">How it
        Works
      </router-link>
    </section>
  </section>

</template>

<script>
import StatsSection from 'rs/components/pages/reviews/_stats_section'

export default {
  components: {StatsSection},
  data() {
    return {
      heading: 'OUR REVIEWS GUARANTEE:',
      subHeading: 'We give you the whole picture',
      guaranteeCards: [
        {
          heading: 'We verify reviews using real order data',
          content: 'We only mark reviews as verified if they’re submitted by customers with real orders, and we only verify imported reviews if they have proof of authenticity.',
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/homepage-guarantee2',
        },
        {
          heading: 'We use trust scores to guide your buying decision',
          content: 'Stores that don’t moderate reviews receive a maximum transparency score. The stores marked as fully authentic have published only verified reviews.',
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/homepage-guarantee3',
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.homepage-statics_section-wrapper {
  background-color: #F1F9F9;
  left: calc(-50vw + 50%);
  width: 100vw;
  padding-bottom: 100px;
  min-height: 1200px;
  overflow: hidden;
  position: relative;
  @include respond-to(notdesktop) {
    overflow: hidden;
    padding-bottom: 80px;
  }
}

.homepage-guarantee {
  padding: 0;
}

.homepage-guarantee__heading {
  line-height: 40px;
  color: $newBrandColor;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-top: 80px;
  @include respond-to(desktop) {
    margin-top: 100px;
  }
}

.homepage-guarantee__content--heading, .homepage-guarantee__sub-heading {
  color: #333333;
  font-weight: 700;
  font-size: 32px;
  padding-bottom: 21px;
  @include respond-to(notdesktop) {
    font-size: 20px;
  }
}

.homepage-guarantee__sub-heading {
  text-align: center;
}

img.homepage-guarantee-card__image {
  display: block;
  width: 100%;
  height: auto;
  @include respond-to(desktop) {
    width: 540px;
    height: 352px;
  }
}

.homepage-guarantee__content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.homepage-guarantee__content-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  margin: -124px -12px 24px -12px;

  @include respond-to(mobile) {
    margin: -144px 0 -24px 0;
    row-gap: 0;
  }
  @include respond-to(tablet) {
    margin: -70px 0 -24px 0;
  }
}

.homepage-guarantee__content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
  gap: 60px;

  @include respond-to(tablet) {
    gap: 40px;
  }
  @include respond-to(mobile) {
    flex-direction: column-reverse;
    gap: 24px;
    margin-bottom: 40px;
    padding: 0 16px;
    max-width: 100%;
  }
}

.homepage-guarantee__content > * {
  flex: 1 1 49%;
}

.homepage-guarantee__content:nth-child(1) {
  @include respond-to(notmobile) {
    flex-direction: row-reverse;
  }
}

.homepage-guarantee__circles--wrapper {
  height: 0;
  @include respond-to(desktop) {
    margin-top: 160px;
  }
  @include respond-to(tablet) {
    margin-top: 90px;
  }
  @include respond-to(mobile) {
    margin-top: 180px;
  }
}

.homepage-guarantee__circles--background {
  height: 490px;
  width: 490px;
  border-radius: 50%;
  margin: 0 auto;
  border: 1px solid #d0ecee;
  box-shadow: 0 0 0 90px #F1F9F9, // 490 + 91
  0 0 0 91px #d0ecec,
  0 0 0 211px #F1F9F9, // 91 + 120
  0 0 0 212px #d0ecec,
  0 0 0 298px #F1F9F9, // 210 + 88
  0 0 0 299px #d0ecec;

  @include respond-to(mobile) {
    margin: 0 calc((100vw - 490px) / 2);
  }
}

.homepage-guarantee__how-it-works {
  height: 48px;
  width: 300px;
  font-size: 16px;
  font-weight: 800;
  margin: 68px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @include respond-to(mobile) {
    width: 343px;
    margin: 12px auto;
  }
}

</style>
