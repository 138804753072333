import {pipeAsync} from "shared/utils/js_core";
import {setState, setMeta, fetchRequest} from 'rs/store/store_utils/shared'
import utils from 'shared/utils/utils'
import {SearchCache} from 'rs/store/store_utils/search_utils'
import { mapMediaFromReviews } from "rs/store/shared/gallery_utils";

import {
  applyFilter,
  clearFilter,
  removeFilter,
  applySort,
  loadReviewsOnPageChange,
  trackChange
} from "rs/store/store_utils/filter_sort_utils";

const getRequestUrl = slug => `/profile/${slug}`;
const cache = new SearchCache({}) // {[allValuesFromQueryObject]: { sha: ..., results: [] } }
const addFolloweeIds = context => hashData => {
  context.dispatch('SharedFollow/fetchFolloweeIds', { Reviewer: [hashData.basic_data.encoded_id] }, { root: true })
}
const fetchWithCache = fetchRequest(cache)
export const cacheRequestForGallery = (slug, page) => fetchWithCache()({
  requestUrl: getRequestUrl(slug),
  params: {...reviewsQueries, page},
});

const defaultQueries = {
  filter_rating: []
}
const reviewsQueries = {tab: 'reviews', search: '', sort_by: 'pictures_first', page: 1, ...defaultQueries}

const reviewTypes = {}

const defaultState = {
  slug: '',
  doneLoadProfile: false,
  doneLoadReviews: false,
  doneLoadMoreReviews: true,
  reviewsData: {},
  profileData: null,
  extraData: null,
  queries: reviewsQueries,
  recommendationsCurrentPage: 1,
  media: [],
  // cache previousSearchString to reset page number when search again
  previousSearch: '',
}
const state = {...defaultState}

const getters = {
  doneLoadProfile: state => state.doneLoadProfile,
  doneLoadReviews: state => state.doneLoadReviews,
  doneLoadMoreReviews: state => state.doneLoadMoreReviews,
  profileData: state => state.profileData,
  reviewerSlug: state => state.slug,
  recommendationPaginated: state => {
    const defaultValue = {
      total: 0,
      recommendations: [],
    }
    if (!state.extraData || !state.extraData.recommendations_data) {
      return defaultValue
    }
    const data = state.extraData.recommendations_data
    const perPage = 5
    const currentPage = state.recommendationsCurrentPage
    return {
      totalCount: data.items.length,
      totalPages: Math.ceil(data.total / perPage),
      recommendations: data.items.slice(perPage * (currentPage - 1), perPage * currentPage),
      currentPage,
    }
  },
  extraData: state => state.extraData,
  changes: trackChange(defaultQueries, reviewTypes),
  favouriteWidgetData: state => {
    const {
      shops_count,
      products_count,
      reviewers_count,
    } = state.extraData.followings_data
    return {
      totalCount: reviewers_count + products_count + shops_count,
      headerText: `Favourites`,
      reviewerText: utils.pluralize(reviewers_count || 0, 'reviewer'),
      productText: utils.pluralize(products_count || 0, 'product'),
      storeText: utils.pluralize(shops_count || 0, 'store'),
    }
  },
  reviewsData: state => {
    const {
      total_count,
      total_pages,
      current_page,
      reviews = []
    } = state.reviewsData
    return {
      totalCount: total_count || 0,
      totalPages: total_pages || 0,
      currentPage: current_page || 1,
      reviews,
    }
  },
  totalReviewsCount: state => state.extraData && state.extraData.reviews_data && state.extraData.reviews_data.total_reviews_count || 0,
  hasReviewOrRecommendation: (state, getters) => {
    return getters.reviewsData.totalCount + getters.recommendationPaginated.totalCount > 0
  },
  totalPublishedReviewsCount: (state, getters) => {
    return getters.reviewsData.totalCount
  },
  media: state => state.media,
}

const mutations = {
  setState(state, [name, value]) {
    state[name] = value
  },
  setFilterQueries(state, filterQueries) {
    state.queries = {...state.queries, ...filterQueries}
  },
  setSearchQuery(state, search) {
    state.queries = {...state.queries, search}
  },
  setSortQuery(state, query) {
    state.queries = {...state.queries, ...query}
  },
  setMedia (state, responseData) {
    state.media = mapMediaFromReviews(responseData.reviews, state.media)
  },
}

const actions = {
  async initPage(context, slug) {
    const {commit, dispatch, state} = context

    const requestUrl = getRequestUrl(slug)
    if (state.slug !== slug) {
      const profileData = await pipeAsync(
        fetchWithCache(setMeta(dispatch)),
        hash => {
          setState(commit, 'profileData')(hash.basic_data)
          setState(commit, 'extraData')(hash.extra_data)
          return hash
        },
        addFolloweeIds(context)
      )({requestUrl})
      if (state.profileData.encoded_id) {
        setState(commit, 'slug')(state.profileData.encoded_id)
        dispatch('loadReviews')
      }
    }
    await dispatch('Profile/setStoreName', 'PublicProfile', { root: true })
    setState(commit, 'doneLoadProfile')(true)
  },
  async inheritFromPrivateProfile(context, profileData) {
    const {commit, dispatch} = context
    addFolloweeIds(context)(profileData)
    setState(commit, 'profileData')(profileData.basic_data)
    setState(commit, 'extraData')(profileData.extra_data)
    setState(commit, 'slug')(profileData.basic_data.encoded_id)
    await dispatch('Profile/setStoreName', 'PublicProfile', { root: true })
    setState(commit, 'doneLoadProfile')(true)
    dispatch('loadReviews')
  },
  applyFilter(context, filterQueries) {
    applyFilter(context, filterQueries)()
  },
  clearFilter(context) {
    clearFilter(context)(defaultQueries)
  },
  removeFilter(context, filter) { // filter = { type: xxx, value: yyy}
    removeFilter(context, filter)(defaultQueries)
  },
  applySort(context, payload) {
    applySort(context, payload)()
  },
  loadReviewsOnPageChange(context, payload) {
    loadReviewsOnPageChange(context, payload)()
  },
  async loadReviews(context, payload) { // also called inside `loadReviewsOnPageChange` method
    setState(context.commit, 'doneLoadMoreReviews')(false)
    const {slug, queries} = context.state
    const requestParams = {
      requestUrl: getRequestUrl(slug),
      params: {...queries, ...payload}
    }

    await pipeAsync(
      fetchWithCache(),
      setState(context.commit, 'reviewsData'),
      data => context.commit('setMedia', data)
    )(requestParams)

    setState(context.commit, 'doneLoadReviews')(true)
    setState(context.commit, 'doneLoadMoreReviews')(true)
  },
  paginateRecommendation(context, page) {
    setState(context.commit, 'recommendationsCurrentPage')(page)
  },
}

export default {state, getters, mutations, actions, namespaced: true}
