<template>
  <section class="review-shop-reply">
    <div class='review-shop-reply__timestamp'>{{ createdAtRelative }}</div>
    <strong class="review-shop-reply__header">{{replierName}}</strong> replied:
    <div v-html='review.reply_content' class="review-shop-reply__body" :class="{ 'review-shop-reply__body--show-all' : shouldShowAllBody }" ref='reviewReplyBodyEle'></div>
    <span class='review-shop-reply__body-read-more' @click='showAll'>Read More</span>
  </section>
</template>

<script>
  import {customFormat} from 'shared/utils/timeago';

  export default {
    props: ['review'],
    computed: {
      replierName() {
        return this.review.shop_reply_name || this.review_shop_name || 'Shop'
      },
      createdAtRelative(){
        if (!this.review.reply_created_at) return null
        return customFormat(this.review.reply_created_at)()
      }
    },
    data() {
      return {
        shouldShowAllBody: false,
      }
    },
    methods: {
      showAll() {
        this.shouldShowAllBody = true
      },
    },
    mounted() {
      if (this.$refs.reviewReplyBodyEle.scrollHeight > this.$refs.reviewReplyBodyEle.clientHeight) {
        this.$refs.reviewReplyBodyEle.className = 'review-shop-reply__body review-shop-reply__body--need-show-more'
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';
  
  .review-shop-reply {
    background-color: #F1FAFA;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 12px 16px;
    margin-top: 16px;
  }

  .review-shop-reply__timestamp {
    color: $secondaryFontColor;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .review-shop-reply__header {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
  }

  .review-shop-reply__body {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-top: 6px;

    &:not(.review-shop-reply__body--show-all) {
      // Mutiline ellipsis
      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .review-shop-reply__body-read-more { display: none; }

  .review-shop-reply__body--need-show-more + .review-shop-reply__body-read-more {
    display: inline;
    color: $brandColor;
    text-decoration: underline;
    cursor: pointer;
  }
  .review-shop-reply__timestamp {
    font-size: 12px;
    color: $secondaryFontColor;
  }
</style>
