<template>
  <Main v-if="currentCountry" />
  <LoadingSpinner v-else />
</template>
<script>
  import Main from 'rs/components/pages/search/v2'
  import LoadingSpinner from 'rs/components/common/loading_spinner'
  import {mapState} from "vuex";

  export default {
    components: { Main, LoadingSpinner },
    computed: {
      ...mapState('SharedCurrentReviewer', ['currentCountry']),
    }
  }
</script>

<style scoped>
  .loading-spinner {
    margin: 24px auto;
    width: 72px;
    height: 72px;
  }
</style>
