<template>
  <div class="line-item-wrapper">
    <div class="li__image">
      <LazyImage
          v-if="validImage" @error="validImage=false"
          class="li__image"
          :alt="'Image for ' + item.productTitle"
          :urls='{ normal: productImageLink, retina: productImageLink }' />
      <img v-else class="li__image" :src="productImageLink" :alt="'Image for ' + item.productTitle" />
    </div>
    <div class="li__info-group">
      <div class="li__info-title">
        <p>{{item.productTitle}}</p>
      </div>
      <div class="li__info-group--child">
        <div class="li__info-date">
          <span>Order Date</span>
          <span>{{item.placedAt}}</span>
        </div>
        <component class="btn pf-primary-button li__write-button" v-bind="productLinkComponent">Write a Review</component>
      </div>
    </div>

  </div>
</template>
<script>
import LazyImage from 'rs/components/common/lazy_image';
import {NO_PRODUCT_IMAGE} from "shared/constants/links.js";

export default {
  props: ['item'],
  components: {LazyImage},
  data: () => ({
    validImage: true,
  }),
  computed: {
    productImageLink(){ return this.validImage ? this.item.productImageUrl : NO_PRODUCT_IMAGE},
    productLinkComponent() {
      if (this.item.reviewable && this.item.inStore) {
        return {
          is: 'router-link',
          to: {
            path: this.item.productPath,
            query: {action: 'write-review'}
          }
        }
      }
      return {
        is: 'router-link',
        to: {
          name: 'PageWriteReviewNew',
          query: {line_item_public_id: this.item.publicId}
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.line-item-wrapper {
  width: 100%;
  background: white;
  border: $newBorderStyle;
  border-radius: 10px;
  font-weight: 400;
  display: flex;
  gap: 16px;
  font-size: 14px;
  height: 96px;

  padding: 24px 48px 24px 20px;

  @include respond-to(mobile) {
    height: fit-content;
    padding: 24px 20px;
  }
}
.li__image {
  width: 48px;
  height: 48px;
  font-size: 48px;
}
.li__info-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  @include respond-to(mobile) {
    flex-direction: column;
  }
}
.li__info-group--child {
  display: flex;
  justify-content: space-between;
  gap: 45px;

  @include respond-to(notmobile) {
    align-items: center;
  }
  @include respond-to(mobile) {
    flex-direction: column;
    gap: 12px;
  }
}
.li__info-title {
  font-size: 16px;
  display: flex;
  align-items: center;
  & > p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  @include respond-to(mobile) {
    flex: 0 1 48px;
  }
}
.li__info-date {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 104px;
  & > span {
    line-height: 22px;
  }
  & > span:first-child {
    color: $secondaryFontColor;
  }
}

.li__write-button {
  height: 40px;
  width: 220px;
  font-size: 16px;
  @include respond-to(mobile) {
    width: 100%;
    max-width: 240px;
  }
}
</style>
