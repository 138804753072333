<template>
  <WidgetWrapper>
    <template>
      <div class="shop-card__header">
        <img v-if="shopLogo || validLogo" class="shop-card__logo" :src="shopLogo" @error="validLogo = false" @load="doneLoadLogo" alt="Shop logo">
        <div v-else class="shop-card__logo shop-info__logo--default material-icons">store</div>
        <div class="shop-card__shop-summary">
          <div class="shop-card__shop-name">{{ shopName }}</div>
          <div class="shop-card__shop-product-count">{{ productCount }}</div>
        </div>
      </div>
      <div class="shop-card__divider"></div>
      <div class="shop-card__description">{{ description }}</div>
      <router-link class="btn pf-primary-button mt-4 w-100" :to="shopLink">More Info</router-link>
    </template>
  </WidgetWrapper>
</template>

<script>
import WidgetWrapper from 'rs/components/common/wrappers/widget_wrapper'
import { adjustObjectFitForImgEle } from "shared/utils/upload_media_files";
import { storeName } from "rs/store/store_utils/shared.js";

export default {
  components: {
    WidgetWrapper
  },
  data() {
    return {
      validLogo: false
    }
  },
  methods: {
    doneLoadLogo(e) {
      this.validLogo = true;
      adjustObjectFitForImgEle(e);
    },
  },
  computed: {
    storeName() {
      return storeName(this.$route)
    },
    shopData() {
      return this.$store.getters[this.storeName + '/shopData']
    },
    shopLogo() {
      return this.shopData.logo
    },
    shopName() {
      return this.shopData.name
    },
    productCount() {
      return this.$sharedUtils.pluralize(this.shopData.products_count, 'product')
    },
    description() {
      return this.shopData.description
    },
    shopLink() {
      return {
        name: this.$routes.shop.name,
        params: {
          shop_slug: this.shopData.domain,
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.shop-card__header {
  display: flex;
  gap: 8px;
}

.shop-card__logo {
  border-radius: 4px;
  width: 64px;
  height: 64px;
  object-fit: cover;
}

.shop-info__logo--default {
  color: #8AC4D1;
  border: $newBorderStyle;
  background-color: $lightGreenColor;
  border-radius: 4px;
  font-size: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shop-card__shop-summary {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
}

.shop-card__shop-name {
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  color: $primaryFontColor;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.shop-card__shop-product-count {
  font-size: 14px;
  font-weight: 400;
  line-height: 1rem;
  color: $secondaryFontColor;
}

.shop-card__divider {
  width: 100%;
  height: 1px;
  background-color: $borderColor;
  margin-top: 12px;
}

.shop-card__description {
  font-size: 16px;
  color: $primaryFontColor;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 12px;
}
</style>
