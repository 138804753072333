"use strict";
/**
 * @file vue-awesome-swiper
 * @module SwiperComponent
 * @author Surmon <https://github.com/surmon-china>
 */
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var constants_1 = require("rs/plugins/swiper/constants");
var event_1 = require("rs/plugins/swiper/event");
var SlotNames;
(function (SlotNames) {
  SlotNames["ParallaxBg"] = "parallax-bg";
  SlotNames["Pagination"] = "pagination";
  SlotNames["Scrollbar"] = "scrollbar";
  SlotNames["PrevButton"] = "button-prev";
  SlotNames["NextButton"] = "button-next";
})(SlotNames || (SlotNames = {}));
function getSwiperComponent(SwiperClass) {
  var _a;
  return vue_1.default.extend({
    name: constants_1.CoreNames.SwiperComponent,
    props: (_a = {
      defaultOptions: {
        type: Object,
        required: false,
        default: function () { return ({}); }
      },
      // eslint-disable-next-line vue/require-default-prop
      options: {
        type: Object,
        required: false
      }
    },
      _a[constants_1.ComponentPropNames.AutoUpdate] = {
        type: Boolean,
        default: true
      },
      // https://github.com/surmon-china/vue-awesome-swiper/pull/550/files
      _a[constants_1.ComponentPropNames.AutoDestroy] = {
        type: Boolean,
        default: true
      },
      // https://github.com/surmon-china/vue-awesome-swiper/pull/388
      _a[constants_1.ComponentPropNames.DeleteInstanceOnDestroy] = {
        type: Boolean,
        required: false,
        default: true
      },
      _a[constants_1.ComponentPropNames.CleanupStylesOnDestroy] = {
        type: Boolean,
        required: false,
        default: true
      },
      _a),
    data: function () {
      var _a;
      return _a = {},
        _a[constants_1.CoreNames.SwiperInstance] = null,
        _a;
    },
    computed: {
      swiperInstance: {
        cache: false,
        set: function (swiper) {
          this[constants_1.CoreNames.SwiperInstance] = swiper;
        },
        get: function () {
          return this[constants_1.CoreNames.SwiperInstance];
        }
      },
      swiperOptions: function () {
        return this.options || this.defaultOptions;
      },
      wrapperClass: function () {
        return this.swiperOptions.wrapperClass || constants_1.DEFAULT_CLASSES.wrapperClass;
      }
    },
    methods: {
      // Feature: click event
      handleSwiperClick: function (event) {
        (0, event_1.handleClickSlideEvent)(this.swiperInstance, event, this.$emit.bind(this));
      },
      autoReLoopSwiper: function () {
        var _a, _b;
        if (this.swiperInstance && this.swiperOptions.loop) {
          // https://github.com/surmon-china/vue-awesome-swiper/issues/593
          // https://github.com/surmon-china/vue-awesome-swiper/issues/544
          // https://github.com/surmon-china/vue-awesome-swiper/pull/545/files
          var swiper = this.swiperInstance;
          (_a = swiper === null || swiper === void 0 ? void 0 : swiper.loopDestroy) === null || _a === void 0 ? void 0 : _a.call(swiper);
          (_b = swiper === null || swiper === void 0 ? void 0 : swiper.loopCreate) === null || _b === void 0 ? void 0 : _b.call(swiper);
        }
      },
      updateSwiper: function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (this[constants_1.ComponentPropNames.AutoUpdate] && this.swiperInstance) {
          this.autoReLoopSwiper();
          (_b = (_a = this.swiperInstance) === null || _a === void 0 ? void 0 : _a.update) === null || _b === void 0 ? void 0 : _b.call(_a);
          (_d = (_c = this.swiperInstance.navigation) === null || _c === void 0 ? void 0 : _c.update) === null || _d === void 0 ? void 0 : _d.call(_c);
          (_f = (_e = this.swiperInstance.pagination) === null || _e === void 0 ? void 0 : _e.render) === null || _f === void 0 ? void 0 : _f.call(_e);
          (_h = (_g = this.swiperInstance.pagination) === null || _g === void 0 ? void 0 : _g.update) === null || _h === void 0 ? void 0 : _h.call(_g);
        }
      },
      destroySwiper: function () {
        var _a, _b;
        if (this[constants_1.ComponentPropNames.AutoDestroy] && this.swiperInstance) {
          // https://github.com/surmon-china/vue-awesome-swiper/pull/341
          // https://github.com/surmon-china/vue-awesome-swiper/issues/340
          if (this.swiperInstance.initialized) {
            (_b = (_a = this.swiperInstance) === null || _a === void 0 ? void 0 : _a.destroy) === null || _b === void 0 ? void 0 : _b.call(_a, this[constants_1.ComponentPropNames.DeleteInstanceOnDestroy], this[constants_1.ComponentPropNames.CleanupStylesOnDestroy]);
          }
        }
      },
      initSwiper: function () {
        this.swiperInstance = new SwiperClass(this.$el, this.swiperOptions);
        (0, event_1.bindSwiperEvents)(this.swiperInstance, this.$emit.bind(this));
        this.$emit(constants_1.ComponentEvents.Ready, this.swiperInstance);
      }
    },
    mounted: function () {
      if (!this.swiperInstance) {
        this.initSwiper();
      }
    },
    // Update swiper when the parent component activated with `keep-alive`.
    activated: function () {
      this.updateSwiper();
    },
    updated: function () {
      this.updateSwiper();
    },
    beforeDestroy: function () {
      // https://github.com/surmon-china/vue-awesome-swiper/commit/2924a9d4d3d1cf51c0d46076410b1f804b2b8a43#diff-7f4e0261ac562c0f354cb91a1ca8864f
      this.$nextTick(this.destroySwiper);
    },
    render: function (createElement) {
      return createElement('div', {
        staticClass: constants_1.DEFAULT_CLASSES.containerClass,
        on: {
          click: this.handleSwiperClick
        }
      }, [
        this.$slots[SlotNames.ParallaxBg],
        createElement('div', {
          class: this.wrapperClass
        }, this.$slots.default),
        this.$slots[SlotNames.Pagination],
        this.$slots[SlotNames.PrevButton],
        this.$slots[SlotNames.NextButton],
        this.$slots[SlotNames.Scrollbar]
      ]);
    }
  });
}
exports.default = getSwiperComponent;
