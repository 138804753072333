<template>
  <MediaQueryProvider :queries="$options.queries" @change:mobile="onMediaChange" @change:desktop="onMediaChange" @change:tablet="onMediaChange">
    <section class='rs-app vue-footer' :class="{'rs-app--dynamic-banner': dynamicBanner}">
      <Header @toggleDynamicBanner="toggleDynamicBanner"/>
      <section class="rs-app__main">
        <router-view :key="$route.fullPath"/>
      </section>
      <Footer />
    </section>
  </MediaQueryProvider>
</template>

<script>
  import {EVENT_NAMES} from "shared/constants/google_analytics";
  import Header from 'rs/components/layouts/main_header';
  import Footer from 'rs/components/layouts/footer_redesign';
  import {MediaQueryProvider} from "shared/vue_media_query";
  import routes from 'rs/plugins/routes';

  const queries = { // marketplace app-wise media queries
    mobile: '(max-width: 767px)',
    tablet: '(min-width: 768px) and (max-width: 991px)',
    desktop: '(min-width: 992px)'
  };
  function whichMediaQuery(value) {
    for (let k in queries) {
      if (queries[k] === value) {
        return k;
      }
    }
    return null;
  }

  window.headerHeight = 70
  window.subHeaderHeight = 70;

  const NON_WHITE_BACKGROUND_PAGES = {
    [routes.feed.name]: true,
    [routes.people.name]: true,
    [routes.product.name]: true,
    [routes.shop.name]: true,
    [routes.following.name]: true,
    [routes.writeReview.name]: true,
    [routes.publicProfile.name]: true,
    [routes.privateProfile.name]: true,
    [routes.editProfile.name]: true,
    [routes.productNewDispute.name]: true,
    [routes.shopNewDispute.name]: true,
    [routes.reviewerArbitrationShow.name]: true,
  }

  export default {
    queries,
    components: {MediaQueryProvider, Header,Footer},
    data() {
      return {
        isReviewerLoggedIn: window.isReviewerLoggedIn,
        tempHeader: null,
        dynamicBanner: true,
      }
    },
    methods: {
      // callback for all screens mobile, tablet, desktop.
      // whenever screen size breaks a media breakpoint. this callback is triggered (meaning triggered twice)
      // `matches` is `false` for previous mediaQuery, and `true` for new mediaQuery.
      onMediaChange(event){
        if (event.matches) {
          const mediaQuery = whichMediaQuery(event.media)
          window.headerHeight = mediaQuery === 'mobile' ? 70 : 90
          if (mediaQuery) this.$store.dispatch('Global/setMedia', mediaQuery)  // sometimes component matcher is not enough, let's also use vuex to keep track of screen size
        }
      },
      initDeviceSize() {
        for (const key in this.$options.queries) {
          if (window.matchMedia(this.$options.queries[key]).matches) {
            this.$store.dispatch('Global/setMedia', key)
          }
        }
      },
      toggleWhiteBodyClass(from, to) {
        if (from.name === 'PageProduct' && to.name !== 'PageProduct') {
          document.body.classList.remove('mobile-white-background')
        }
        // toggle white-body class (override background color)
        if (NON_WHITE_BACKGROUND_PAGES[from.name]) {
          if (!NON_WHITE_BACKGROUND_PAGES[to.name]) {
            document.body.classList.add('white-background')
          }
        }
        if (NON_WHITE_BACKGROUND_PAGES[to.name]) {
          if (!NON_WHITE_BACKGROUND_PAGES[from.name]) {
            document.body.classList.remove('white-background')
          }
        }
      },
      trackInternalJourney(from, to) {
        if (to.path !== from.path) {
          this.$store.dispatch('SharedClickTracking/storeReviewerJourney', to.path)
        }
      },
      handleGoogleTagEvent() {
        // GA track screenview for SPA. need to update page_path first then manually trigger event page_view;
        this.$gtag.set('page_path', this.$route.fullPath);
        this.$gtag.event(EVENT_NAMES.pageView);
      },
      toggleDynamicBanner(bool) {
        this.dynamicBanner = bool;
      }
    },
    created() {
      const header = document.getElementsByTagName('header')[0]
      if (header) {
        this.tempHeader = header
        header.remove()
      }
      this.initDeviceSize()
      this.$store.dispatch('Meta/initMeta')
      this.$store.dispatch('SharedCurrentReviewer/fetchCurrentReviewer', {country: this.$route.query.country, currency: this.$route.query.currency})
      this.$store.dispatch('Search/fetchAllRootCategories')
      this.$store.dispatch('SharedClickTracking/setupClickTracking')
    },
    watch: {
      '$route': function (to, from) {
        this.toggleWhiteBodyClass(from, to)
        this.trackInternalJourney(from, to)
        this.handleGoogleTagEvent()
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .rs-app {
  }
  .rs-app__main {
    @include respond-to(tablet) {
      margin-top: calc(#{$marketplaceHeaderSearchHeight} + #{$marketplaceHeaderHeightDesktop});
    }
    @include respond-to(mobile) {
      margin-top: calc(#{$marketplaceHeaderSearchHeight} + #{$marketplaceHeaderHeightMobile});
    }
    @include respond-to(desktop) {
      margin-top: #{$marketplaceHeaderHeightDesktop};
    }
  }
  .rs-app__main--no-search {
    @include respond-to(notdesktop) {
      margin-top: $marketplaceHeaderHeightMobile;
    }
  }

  .vue-footer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .rs-app--dynamic-banner {
    @include respond-to(desktop) {
      margin-top: $marketplaceHeaderBannerHeight;
    }
  }
</style>
<!--Import css for external libs-->
<style lang="scss">
  @import "vue-select/dist/vue-select.css";
</style>
