<template>
  <CardWrapper v-if="paymentMethods.length > 0" :hasWrapper="hasWrapper"
               title="Payment methods" icon="check" showTooltip
               :showIcon="shopData.confirmed_payment_methods"
               :tooltipProps="tooltipProps">
    <template v-slot:body>
      <div class="tab-reviews__payment-methods">
        <PaymentMethodIcon v-for="method in paymentMethods" :slug="method" :key="method"/>
      </div>
    </template>
  </CardWrapper>
</template>

<script>
import CardWrapper from 'rs/components/pages/shop/tabs/_card_wrapper'
import PaymentMethodIcon from 'rs/components/common/icons/payment_method_icon'
import { storeName } from "rs/store/store_utils/shared.js";

export default {
  components: {CardWrapper, PaymentMethodIcon},
  props: ['hasWrapper'],
  computed: {
    storeName() { return storeName(this.$route)},
    shopData() { return this.$store.getters[this.storeName + '/shopData']},
    tooltipProps() {
      return {
        show: true,
        tooltipArg: this.shopData.confirmed_payment_methods,
        name: 'paymentMethods',
      }
    },
    paymentMethods() {
      if (this.shopData.payment_methods && this.shopData.payment_methods.length > 0) {
        return this.shopData.payment_methods
      } else {
        this.$emit('hasContent', false)
        return []
      }
    },
  }
}
</script>
<style lang="scss" scoped>

.tab-reviews__payment-methods {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
</style>
