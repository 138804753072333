<template>
  <div class='recommendation-card-reviewer-header'>
    <UserAvatar :avatar_image_url="reviewer.avatar_image_urls" :reviewer_initial='reviewer.initial || reviewer.name[0]'
                :gravatar_hash='reviewer.gravatar_hash' :verified_buyer='verified_buyer' :class="'recommendation-card-reviewer-header__avatar'" />
    <div class='recommendation-card-reviewer-header__info'>
      <a class='recommendation-card-reviewer-header__info-name' v-bind="reviewerLinkProps">{{ reviewer.name }}</a>
      <VerifiedBadge v-if='verified_purchase' />
      <div class='recommendation-card-reviewer-header__info-timestamp'>
        {{ createdAt }}
      </div>
    </div>
  </div>
</template>

<script>
  import UserAvatar from 'rs/components/common/user_avatar'
  import VerifiedBadge from 'rs/components/common/verified_badge'
  import {customFormat} from 'shared/utils/timeago';
  import {reviewerLinkProps} from 'shared/utils/component_link_utils';

  export default {
    components: { UserAvatar, VerifiedBadge },
    props: ['reviewer', 'verified_buyer', 'created_at', 'verified_purchase'],
    computed: {
      createdAt() { return customFormat(this.created_at)() },
      reviewerLinkProps() { return reviewerLinkProps(this.$routes, this.reviewer.profile_path, 'span') },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/variables_new';

  .recommendation-card-reviewer-header {
    display: flex;
  }
  .recommendation-card-reviewer-header__info {
    vertical-align: top;
    margin-left: 12px;
    line-height: 1;
  }

  .recommendation-card-reviewer-header__info-name {
    vertical-align: middle;
    font-weight: 400;
  }
  .recommendation-card__verified-icon.material-icons {
    position: relative;
    font-size: 10px;
    font-weight: 900;
    color: white;
    height: 14px;
    width: 14px;
    text-align: center;
    border-radius: 50%;
    left: 40px;
    top: 24px;
    line-height: 14px;
    background: $brandColor;
    z-index: 1;
}

  .recommendation-card-reviewer-header__info-timestamp {
    font-size: 12px;
    color: $secondaryFontColor;
    margin-top: 4px;
    text-align: left;
    font-weight: 400;
  }
</style>
