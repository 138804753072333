<template>
  <div class="d-flex gap-4">
    <router-link v-if="shouldShowWriteReviewButton" :to="{path: $routes.writeReview.path}" class="align-self-center rs-weight-400">Write a review</router-link>
    <ExternalLink href="/" text="For Businesses" class="btn pf-primary-button"/>
  </div>

</template>
<script>
import ExternalLink from 'rs/components/common/external_link';
export default {
  components: { ExternalLink },
  computed: {
    redesign() { return this.$route.query.redesign },
    shouldShowWriteReviewButton() {
      return this.redesign && this.$route.name === this.$routes.home.name && window.isReviewerLoggedIn;
    }
  }
}

</script>
