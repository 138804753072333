<template>
  <section class="breadcrumb">
    <div class="breadcrumb__row">
      <template v-for="(path, idx) in stack">
        <router-link :is="tagName(path, idx)"
                     :key='"breadcrumb-" +idx'
                     :to="path.path"
                     :href="path.path"
                     class="breadcrumb__link">
          {{path.name}}
        </router-link>
        <span v-if="!isLastItem(idx)" class="breadcrumb__divider">/</span>
      </template>
    </div>
  </section>

</template>
<script>
export default {
  name: 'Breadcrumb',
  props: ['stack', 'linkForLastItem'],
  methods: {
    isLastItem(idx){
      return idx === this.stack.length - 1
    },
    linkTag(path){ return path.nonVue ? 'a' : 'router-link'},
    tagName(path, idx) {
      if (!this.isLastItem(idx)) return this.linkTag(path)
      return this.linkForLastItem ? this.linkTag(path) : 'span'
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';

.breadcrumb {
  margin-top: 8px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
}
.breadcrumb__link {
  display: inline-block;
  text-decoration: none;
  &::first-letter {
    text-transform: capitalize;
  }
}
a.breadcrumb__link {
  color: $newBrandColor;
}
span.breadcrumb__link {
  color: $primaryFontColor;
}
.breadcrumb__divider {
  margin: 0 4px;
}
</style>
