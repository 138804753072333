<template>
  <div class="editor-header">
    <div v-if="deleted" class="editor-header__notification mb-3">
      <span class="material-icons">error</span>
      <div>This review was deleted and we will store it for 14 days. After this period, this review will be deleted completely and <span class="rs-weight-700">cannot be recovered.</span></div>
    </div>
    <button v-if="!deleted" :class="['btn', published ? 'pf-danger-button' : 'pf-secondary-button', 'editor-header__toggle-publish']" name="togglePublish" @click="click">{{ published ? 'Unpublish' : 'Publish'}}</button>
    <button v-else class="btn pf-secondary-button editor-header__toggle-publish" name="restore" @click="click">Restore Review</button>
    <div v-if="readonly" class="editor-header__notification mt-3">
      <span class="material-icons">error</span>
      <div>This review is a Shop app review and can’t be edited or deleted. You can still publish or unpublish it from your profile.</div>
    </div>
    <div class="editor-header__right-group" v-if="!deleted">
      <button v-if="!readonly" class="material-icons d-flex-center editor-header__round-button" name="edit" @click="click">edit</button>
      <button v-if="!readonly" class="material-icons d-flex-center editor-header__round-button" name="delete" @click="click">delete</button>
      <!-- share button -->
    </div>
  </div>
</template>
<script>
export default {
  emits: ['edit', 'share', 'delete', 'togglePublish'],
  props: ['published', 'deleted', 'readonly'],
  methods: {
    click(e) {
      this.$emit(e.target.name);
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
.editor-header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.editor-header__notification {
  flex: 1 1 100%;
  width: 100%;
  border: 1px solid #D15F5F;
  background: #FFF3F2;
  border-radius: 5px;
  padding: 8px 12px;
  display: flex;
  gap: 8px;
  .material-icons {
    color: #D15F5F;
  }
}
.editor-header__toggle-publish {
  width: 184px;
  height: 40px;
}
.editor-header__right-group {
  display: flex;
  gap: 8px;
}
.editor-header__round-button {
  color: $primaryFontColor;
  border: transparent;
  background: white;
  cursor: pointer;
  border-radius: 50%;
  margin: auto;
  width: 40px;
  aspect-ratio: 1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.editor-header__round-button:hover {
  background: buttonface;
}
.editor-header__round-button:active {
  border: 2px solid buttonface;
  background: buttonface;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}
</style>
