export function  reviewerHeaderProps(cardData) {
  return {
    reviewer: {
      avatar_image_urls: cardData.reviewer_image_urls,
      initial: cardData.reviewer_name[0],
      name: cardData.reviewer_name,
      gravatar_hash: cardData.reviewer_gravatar_hash,
      profile_path: cardData.reviewer_public_slug,
    },
    created_at: cardData.review_created_at,
    verified_buyer: cardData.review_verified_buyer,
    verified_purchase: cardData.review_verified_purchase
  }
}

export function reviewFirstImage(cardData) {
  return cardData.review_image_url || cardData.product_image_url
}
