import axios from 'shared/utils/axios';
import {
  mapMediaFromReviews,
  mapReviewsToHash,
  getSelectedReviewUUID,
} from "rs/store/shared/gallery_utils";
import {cacheRequestForGallery as publicProfileGalleryRequest} from 'rs/store/public_profile.js';
import {cacheRequestForGallery as privateProfileGalleryRequest} from 'rs/store/private_profile.js';

const defaultState = {
  reviewsHash: {},
  selectedReview: {},
  media: [],
  page: 1,
  totalPage: 1,
}
const state = {
  ...defaultState,
  encodedId: "",      // could be product or Shop encodedId
  currentMediaId: "",
  loading: false,
  isOpen: false,
  errorMessage: "",
  sourceType: "",
}

const getters = {
  isOpen: state => state.isOpen,
}

const mutations = {
  setEncodedId: (state, encodedId) => state.encodedId = encodedId,
  setErrorMessage: (state, message) => state.errorMessage = message,
  setSourceType: (state, sourceType) => state.sourceType = sourceType,
  updateResults: (state, {result, clearMedia}) => { // append results
    state.page = result.current_page ? result.current_page : 1;
    state.reviewsHash = mapReviewsToHash(result.reviews, state.reviewsHash);
    state.totalPages =  result.total_pages
    if (clearMedia) {
      state.media = mapMediaFromReviews(result.reviews, []);
    } else {
      state.media = mapMediaFromReviews(result.reviews, state.media);
    }
  },
  // prepend only one review when user open from an "out of preloaded review list".
  // used for open from review in product/store page
  prependSingleReview: (state, payload) => {
    const tempMedia = mapMediaFromReviews([payload.review], state.media)
    state.reviewsHash = mapReviewsToHash([payload.review], state.reviewsHash);
    state.media = [...tempMedia.slice(state.media.length, tempMedia.length), ...state.media]
  },
  setSingleReview: (state, payload) => {
    state.reviewsHash = mapReviewsToHash([payload.review], state.reviewsHash);
    state.media = mapMediaFromReviews([payload.review], [])
  },
  setLoading: (state, data) => state.loading = data,
  setIsOpen: (state, data) => state.isOpen = data,
  setSelectedReview: (state, payload) => {
    state.currentMediaId = payload.id
    state.selectedReview = payload.data
  },
}

const actions = {
  async openGallery (context, payload) {
    if (!payload.review) {
      payload.review = context.state.reviewsHash[payload.review_uuid]
    }
    openMediaWrapper(context, payload)(galleryDataHandler)
  },

  async loadMore({commit, state}){
    commit('setLoading', true);

    const [data, err] = await _fetchMediaData({encodedId: state.encodedId, sourceType: state.sourceType, page: state.page + 1 })
    if (!data){
      commit('setErrorMessage', 'Something went wrong, please try again later or contact our customer support');
    } else {
      if(data.reviews && data.reviews.length) {
        commit('setErrorMessage', '');
        commit('updateResults', {result: data})
      }
    }
    commit('setLoading', false);
  },

  closeGallery (context) {
    context.commit('setIsOpen', false)
  },

  switchMedia (context, targetMediaId) {
    context.dispatch('selectReview', targetMediaId)
  },
  selectReview({commit, state, rootGetters}, forwardedMediaId) {
    const galleryObject = {}
    if (forwardedMediaId === 'product') {
      galleryObject.id = forwardedMediaId
      galleryObject.data = rootGetters['Product/galleryData']
      if (galleryObject.data) {
        commit('setSelectedReview', galleryObject)
        return;
      }
    }
    const review_uuid = getSelectedReviewUUID(state.media, forwardedMediaId);
    if (review_uuid) {
      galleryObject.id = forwardedMediaId
      galleryObject.data = state.reviewsHash[review_uuid]
    } else { // case user clicks on a very old media in feeds which is older than recent 10 reviews, return first review in this case
      const  firstMedia = state.media[0]
      galleryObject.id = firstMedia.id
      galleryObject.data =  state.reviewsHash[firstMedia.review_uuid]
    }

    commit('setSelectedReview', galleryObject)
  }
}

const processGalleryData = async({commit}, payload) => {
  commit('setEncodedId', payload.encodedId);
  const review_uuid = payload.review_uuid

  let [data, err] = await _fetchMediaData({ encodedId: payload.encodedId, sourceType: payload.sourceType})
  if (err && err.response.status !== 404) { // unknown error
    commit('setErrorMessage', 'Something went wrong, please try again later or contact our customer support');
    return
  }
  // handle OOS product,
  commit('setErrorMessage', '');
  data = data || {reviews: []}
  if (review_uuid !== 'product')  {
    const review = data.reviews.find(review => review.uuid === review_uuid)
    if (!review && payload.review) {
      data.reviews.unshift(payload.review)
    }
  }
  commit('updateResults', {result: data, clearMedia: true})
  commit('setSourceType', payload.sourceType)
}

const _fetchMediaData =  async ({encodedId, sourceType, page= 1}) => {
  try {
    if (sourceType ==='PublicProfile') {  // use cache with params in public_profile store.
      const data = await publicProfileGalleryRequest(encodedId, page)
      return [data, null]
    }
    else if (sourceType ==='PrivateProfile') {
      const data = await privateProfileGalleryRequest(encodedId, page)
      return [data, null]
    }
    else {
      const urls = {
        product: `/products/${encodedId}/reviews_with_media?page=${page}`,
        shop: `/shops/${encodedId}/reviews_with_media?page=${page}`,
      }
      const res  = await axios.get(urls[sourceType]);
      return [res.data, null]
    }
  } catch (err) {
    return [null, err]
  }
}

// if different product, attempt to fetch & handle more logic
// if no product (encodedID) is provided => show only media of this review  (commit: 'setSingleReview')
// if same product (even review of oos product) & there's a review attached, push this media to first in gallery list,
// otherwise cb is null
const galleryDataHandler = async (context, payload) => {
  const {state, commit} = context;
  if (payload.encodedId && state.encodedId !== payload.encodedId || state.errorMessage) {
    await processGalleryData(context, payload)
  }
  if (!payload.review) return null
  commit(payload.encodedId ? 'prependSingleReview' : 'setSingleReview', { review: payload.review })
}

const openMediaWrapper = (context, payload) => async func => {
  const {commit, dispatch} = context;
  commit('setLoading', true);

  if (func && typeof func === 'function') await func(context, payload)

  await dispatch('selectReview', payload.forwardedMediaId)
  commit('setLoading', false);
  commit('setIsOpen', true);
}


export default { state, getters, mutations, actions, namespaced: true };
