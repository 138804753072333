<template>
  <section class="shop-info">
    <div class="shop-info__header-section" :class="{'shop-info__header-section--with-logo': widgetData.logo && !hasBrokenLogo}">
      <div class="shop-info__header-background">
        <img class="shop-info__banner" v-if="widgetData.banner && !hasBrokenBanner" :src="widgetData.banner" @error="hasBrokenBanner = true" alt="Shop banner" />
        <HeartBtn class='shop-info__heart-icon' :followeeClass="'Shop'" :followeeEncodedId="widgetData.encoded_id"/>
      </div>
      <div class="shop-info__logo" v-if="widgetData.logo && !hasBrokenLogo" >
        <ExternalLink @click="trackExternalClick" :href='widgetData.external_url' class="shop-info__logo--link">
          <img class="shop-info__logo-image" :src="widgetData.logo" alt="shop logo" @load="doneLoadLogo" @error="hasBrokenLogo = true"/>
        </ExternalLink>
        <CircleIcon v-if="verifiedFields" class="shop-info__logo-verified"/>
      </div>
    </div>
    <div class="shop-info__details-section">
      <div class="shop-info__shop-name-wrapper">
        <ExternalLink @click="trackExternalClick" class='shop-info__title'
                      :href='widgetData.external_url' :text="widgetData.name"/>
        <div class="ml-2 d-flex gap-3 shop-info__verified-badge" v-if="verifiedFields">
          <VerifiedBadge text="Verified company" class="" :space="false"/>
          <ShopVerifiedTooltip :verifiedFields="verifiedFields" />
        </div>
      </div>
      <div class="shop-info__info">
        <router-link :to="allProductsRoute" class="shop-info__all-products">
          <span>{{ $sharedUtils.pluralize(widgetData.products_count, 'Product', 's') }}</span>
        </router-link>
        <span class="shop-info__info--divider"> | </span>
        <span>{{ countryName }}</span>
        <CountryFlag :alt="'country flag for '+ countryName" :countryCode="countryCode" class="shop-info__flag"/>
      </div>
      <div class="shop-info__description">
        <template v-if="widgetData.description">{{ widgetData.description }}</template>
        <template v-else><strong>Oops!</strong> Looks like this store forgot to add a description. Let's hope their reviews speak for themselves!</template>
      </div>
    </div>
  </section>
</template>
<script>
import {mapGetters} from 'vuex';
import HeartBtn from 'rs/components/common/heart_btn'
import CountryFlag from 'rs/components/common/country_flag'
import ExternalLink from 'rs/components/common/external_link';
import VerifiedBadge from 'rs/components/common/verified_badge';
import CircleIcon from 'rs/components/common/icons/custom_circle_icon';
import ShopVerifiedTooltip from 'rs/components/pages/shop/others/_shop_verified_tooltip';
import { verifiedFields } from "rs/components/pages/shop/helpers.js";
import { adjustObjectFitForImgEle } from "shared/utils/upload_media_files";

export default {
  props: ['allProductsRoute'],
  components: {HeartBtn, CountryFlag, ExternalLink, VerifiedBadge, CircleIcon, ShopVerifiedTooltip},
  computed: {
    ...mapGetters('Shop', ['widgetData', 'countryCode', 'countryName']),
    verifiedFields() {
      return verifiedFields(this.widgetData)
    }
  },
  data() {
    return {
      hasBrokenLogo: false,
      hasBrokenBanner: false,
    }
  },
  methods: {
    trackExternalClick() {
      this.$store.dispatch('Shop/trackExternalClick')
    },
    doneLoadLogo: adjustObjectFitForImgEle,
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

$mobilePadding: 16px;
$desktopPadding: 28px;

.shop-info {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.shop-info__header-section {
  position: relative;
}
.shop-info__header-section--with-logo {
  height: 100px;
  @include respond-to(desktop) {
    height: 112px;
  }
}

.shop-info__header-background {
  position: relative;
  width: 100%;
  background: url('https://pub-images.judge.me/judgeme/platform/categories/categories-page-default-background.png');
  background-size: cover;
  height: 81px;
  @include respond-to(desktop) {
    height: 90px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
}

.shop-info__banner {
  height: inherit;
  width: 100%;
  object-fit: cover;
  @include respond-to(desktop) {
    height: 90px;
    border-top-left-radius: 11px;  // less 1px (vs wrapper) for border
    border-top-right-radius: 11px;
  }
}

.shop-info__heart-icon {
  position: absolute;
  top: 33%;
  right: $mobilePadding;
  @include respond-to(desktop) {
    right: $desktopPadding;
  }
}

.shop-info__logo {
  position: absolute;
  bottom: 0;
  left: $mobilePadding;
  height: 84px;
  width: 84px;
  @include respond-to(desktop) {
    left: $desktopPadding;
  }
}

.shop-info__logo-verified {
  position: absolute;
  top: -4px;
  right: -4px;
}

.shop-info__logo-image {
  height: 84px;
  width: 84px;
  border-radius: 5px;
  object-fit: cover;
  background-color: white;
}
.shop-info__logo--link:hover {
  opacity: 1;
}

.shop-info__details-section {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 12px $mobilePadding;
  @include respond-to(desktop) {
    padding: 12px $desktopPadding;
  }
}

.shop-info__shop-name-wrapper {
  display: flex;
  flex-wrap: wrap;
  @include respond-to(desktop) {
    gap: 12px;
  }
}

.shop-info__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-decoration: none;
  color: $primaryFontColor;
}

.shop-info__info {
  font-size: 14px;
  color: #7B7B7B;
  display: flex;
  align-items: center;
  gap: 8px;
}
.shop-info__info--divider {
  margin: 0 4px;
  color: $lightGreenColor;
}

.shop-info__description {
  margin: 8px 0 12px 0;
  color: $primaryFontColor;
}

.shop-info__flag {
  height: 10px;
}

.shop-info__all-products {
  text-decoration: none;
  color: #7B7B7B;
}

::v-deep .shop-info__verified-badge {
  height: 20px;
  align-self: center;
}

</style>
