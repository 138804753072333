<template functional>
  <svg v-on="data.listeners" :class="data.class" v-bind="data.attrs" xmlns="http://www.w3.org/2000/svg" width="20"
       height="20"
       viewBox="0 0 20 20" fill="none">
    <path
      d="M17.0751 17.0751L2.9251 2.92514C2.6001 2.60014 2.0751 2.60014 1.7501 2.92514C1.4251 3.25014 1.4251 3.77514 1.7501 4.10014L3.0501 5.40014C2.00843 6.96681 1.48343 8.90848 1.7251 10.9835C2.16676 14.7668 5.23343 17.8335 9.00843 18.2668C11.0834 18.5085 13.0168 17.9835 14.5918 16.9418L15.8918 18.2418C16.2168 18.5668 16.7418 18.5668 17.0668 18.2418C17.4001 17.9251 17.4001 17.4001 17.0751 17.0751ZM8.23343 13.2418L5.8751 10.8835C5.5501 10.5585 5.5501 10.0335 5.8751 9.70848C6.2001 9.38348 6.7251 9.38348 7.0501 9.70848L8.81676 11.4751L8.96676 11.3251L10.1418 12.5001L9.40843 13.2335C9.08343 13.5668 8.55843 13.5668 8.23343 13.2418ZM11.3251 8.96681L5.40843 3.05014C6.9751 2.00848 8.91676 1.48348 10.9918 1.72514C14.7751 2.16681 17.8418 5.23348 18.2751 9.00848C18.5168 11.0835 17.9918 13.0251 16.9501 14.5918L12.5001 10.1418L14.1168 8.52514C14.4418 8.20014 14.4418 7.67514 14.1168 7.35014C13.7918 7.02514 13.2668 7.02514 12.9418 7.35014L11.3251 8.96681Z"
      :fill="props.color"/>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#088484',
    }
  }
}
</script>
