<template>
  <TabListingWrapper :tabs="tabs" :activeTabIndex="tabIndex" @changeTab="onChangeTab">
    <template v-slot:content>
      <component :is="tabs[tabIndex].component" :storeName="tabs[tabIndex].storeName"
                 :key="tabIndex" v-if="!tabs[tabIndex].hidden"/>
    </template>
  </TabListingWrapper>
</template>
<script>
import TabReviews from 'rs/components/pages/shop/tabs/_tab_reviews'
import TabQna from 'rs/components/pages/product/tabs/_tab_qna'
import TabStoreInfo from 'rs/components/pages/product/tabs/_tab_store_info'
import TabListingWrapper from 'rs/components/pages/shop/sections/_tab_listing_wrapper'

function findClosestNonHiddenTab(tabs, tabIndex) {
  for (let i = tabIndex; i < tabs.length; i++) {
    if (!tabs[i].hidden) {
      return i
    }
  }
  return tabIndex ? tabIndex -1 : 0
}
export default {
  components: {TabListingWrapper, TabReviews, TabQna},
  inject: ['mediaQueries'],
  computed: {
    tabIndex() {
      return findClosestNonHiddenTab(this.tabs, this.$store.getters['Product/tabIndex'])
    },
    tabs() {
      return [
        {
          tabId: 'product-reviews',
          text: 'Product reviews',
          icon: 'star',
          component: TabReviews,
          storeName: 'Product',
          hidden: !this.$store.getters['Product/hasProductReviews']
        },
        {
          tabId: 'shop-reviews',
          text: 'All store reviews',
          icon: 'star',
          component: TabReviews,
          storeName: 'Shop'
        },
        {
          tabId: 'questions-answers',
          text: "Q&A's",
          icon: 'qna',
          component: TabQna,
          hidden: !this.$store.getters['Product/hasQuestions']
        },
        {
          text: 'Store info',
          icon: 'store',
          component: TabStoreInfo,
          hidden: this.mediaQueries.desktop
        },
      ]
    }
  },
  methods: {
    onChangeTab(idx) {
      this.$store.dispatch('Product/changeTab', idx)
    },
  },
}
</script>
