<template>
  <div class="rs-info-card" :class="[hasWrapper ? '' : 'rs-info-card__wrapper']">
    <h2 class="rs-info-card__title">
      {{ title }}
      <TooltipPopover v-if="showTooltip" v-bind="tooltipProps">
        <template #icon><component v-bind='iconComponent' class="rs-info-card__icon--check" /></template>
      </TooltipPopover>
      <component v-else-if="showIcon" v-bind='iconComponent' class="rs-info-card__icon--check" />
    </h2>
    <slot name="body"></slot>
  </div>
</template>
<script>
import CircleIcon from 'rs/components/common/icons/custom_circle_icon'
import VerifiedBadge from 'rs/components/common/verified_badge'
import TooltipPopover from 'rs/components/common/tooltip_popover';

export default {
  components: {CircleIcon, VerifiedBadge, TooltipPopover},
  props: ['title', 'showIcon', 'icon', 'hasWrapper', 'tooltipProps'],
  computed: {
    showTooltip() {
      return this.tooltipProps && this.tooltipProps.show && this.showIcon
    },
    iconComponent() {
      if (!this.icon || this.icon === 'check')
        return {
          is: CircleIcon,
          icon: 'check'
        }
      return {
        is: VerifiedBadge,
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.rs-info-card {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.rs-info-card__wrapper {
  border-radius: 10px;
  border: 1px solid #E7E7E7;
  padding: 20px;
}

.rs-info-card__title {
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.rs-info-card__icon--check {
  height: 16px;
  width: 16px;
}
</style>
