<template>
  <a class='external-link' target="_blank" ref='nofollow noopener' :href='url' @click="$emit('click')">
    <slot>{{ text }}</slot>
  </a>
</template>

<script>
 import {stripJavascriptPrefix} from 'shared/utils/urls'
  export default {
    props: ['href', 'text'],
    computed: {
      url () {
        return stripJavascriptPrefix(this.href)
      }
    }
  }
</script>
