<template>
  <LoadingSpinner v-if='!doneLoadProfile'/>
  <div v-else>
    <PagePrivate v-if="isPrivatePage"/>
    <PagePublic v-else/>
  </div>
</template>
<script>
import LoadingSpinner from 'rs/components/common/loading_spinner';
import PagePrivate from 'rs/components/pages/private_profile/main'
import PagePublic from 'rs/components/pages/public_profile/main'

export default {
  components: {PagePrivate, PagePublic, LoadingSpinner},
  computed: {
    doneLoadProfile() {
      return this.$store.getters['PrivateProfile/doneLoadProfile'];
    },
    isPrivatePage() {
      return this.$store.getters['PrivateProfile/reviewerSlug'] === this.$route.params.slug
    }
  },
  created() {
    this.$store.dispatch('PrivateProfile/initPage', this.$route.params.slug);
  }
}
</script>
<style scoped>
.loading-spinner {
  margin: 144px auto;
  width: 72px;
  height: 72px;
}
</style>
