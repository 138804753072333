<template>
  <CardWrapper :title="title" :hasWrapper="hasWrapper" :showIcon="confirmed"
               v-if="computedPolicy" icon="check" showTooltip :tooltipProps="tooltipProps">
    <template v-slot:body>
      <ExternalLink v-if="computedPolicy.link" :href="computedPolicy.link">Read Policy</ExternalLink>
      <div v-else>
        {{ computedPolicy.text }}
      </div>
    </template>
  </CardWrapper>
</template>
<script>
import CardWrapper from 'rs/components/pages/shop/tabs/_card_wrapper'
import ExternalLink from 'rs/components/common/external_link'
import {storeName} from "rs/store/store_utils/shared.js";

export default {
  components: {CardWrapper, ExternalLink},
  props: ['hasWrapper', 'policyName'],
  computed: {
    title() {
      return ({
        refund_policy: 'Return & Refund policy',
        shipping_policy: 'Shipping policy',
      })[this.policyName]
    },
    tooltipProps() {
      const name = {
        refund_policy: 'refundPolicy',
        shipping_policy: 'shippingPolicy',
      }[this.policyName]
      return {
        name,
        show: true,
        tooltipArg: this.confirmed,
      }
    },
    storeName() {
      return storeName(this.$route)
    },
    shopData() {
      return this.$store.getters[this.storeName + '/shopData']
    },
    confirmed() {
      return this.shopData['confirmed_' + this.policyName]
    },
    computedPolicy() {
      const policy = this.shopData[this.policyName]
      if (
        !policy ||
        (!policy.link && !policy.text)
      ) {
        this.$emit('hasContent', false)
        return null
      }
      return policy
    },
  },
  mounted() {
    if (!this.confirmed) {
      this.$emit('hasContent', false)  // to hide wrapper
    }
  }
}
</script>
