<template>
  <div class="navbar-user-profile" @click="toggle">
    <a v-if="!reviewer" class="btn navbar-user-profile__sign-in" ref='nofollow noopener' href="/login">Sign In</a>
    <div v-else class="navbar-user-profile__details">
      <div class="navbar-user-profile__avatar-wrapper">
        <Avatar v-if="reviewer" class="navbar-user-profile__avatar"
                :avatar_image_url="reviewer.avatar_image_url"
                :reviewer_initial='reviewer.name[0].toUpperCase()'
        />
        <span>My Account</span>
      </div>
      <i class="material-icons navbar-user-profile__expand-icon">{{ expanded ? 'expand_less' : 'expand_more' }}</i>
    </div>
  </div>
</template>
<script>
import Avatar from 'rs/components/common/user_avatar'
import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState('SharedCurrentReviewer', {
          reviewer: state => state.currentReviewer,
        },
    )
  },
  components: {Avatar},
  data() {
    return {
      expanded: false,
    }
  },
  methods: {
    toggle() {
      if (!this.reviewer) return;
      
      this.expanded = !this.expanded
      this.$emit('expanded', this.expanded)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';

.navbar-user-profile {
  margin-left: -4px;
  margin-right: -12px;
  width: calc(100% + 12px);
}

.navbar-user-profile__sign-in {
  border: solid 1px $newBrandColor;
  background: $newBrandColor;
  width: 100%;
  height: 40px;
  color: white;

  &:visited {
    color: white;
  }
}

.navbar-user-profile__details {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  color: $newBrandColor;
}

.navbar-user-profile__avatar-wrapper {
  display: flex;
  align-items: center;
}

.navbar-user-profile__avatar {
  height: 48px;
  width: 48px;
  line-height: 48px;
  margin-right: 12px;
}

.navbar-user-profile__expand-icon {
  font-size: 32px;
}

</style>