const LOCALE_KEY = 'marketplace_locale';
const EXCHANGE_RATES_KEY = 'exchangeRates'
export function setLocale(localeSettings){
  const currentLocale = getLocale();
  localStorage.setItem(LOCALE_KEY, JSON.stringify({...currentLocale, ...localeSettings}))
}

export function getLocale(param) {
  try {
    const locale = JSON.parse(localStorage.getItem(LOCALE_KEY))
    if (param) {
      return locale[param] || null
    }
    return locale
  } catch {
    return null
  }
}

export function setExchangeRates(exchangeRates) {
  return localStorage.setItem(EXCHANGE_RATES_KEY, JSON.stringify({
    createdAt: new Date(),
    data: exchangeRates
  }));
}

export function getExchangeRates() {
  try {
    const exchangeRates = JSON.parse(localStorage.getItem(EXCHANGE_RATES_KEY))
    if (exchangeRates && exchangeRates.createdAt) {
      const createdAt = new Date(exchangeRates.createdAt)
      const now = new Date()
      if ((Math.abs(now - createdAt) / 36e5) < 6) { // millisecond to hr and compare with 6 hr
        return exchangeRates.data
      }
      localStorage.removeItem(EXCHANGE_RATES_KEY)
    }
    return null
  } catch { // this shouldn't happen but just in case all above fail
    return null
  }
}
