<template>
  <button :class="classes" @click="click" type="button">
    <slot name="loading" v-if="loading">
      <LoadingSpinner/>
    </slot>
    <slot v-else></slot>
  </button>
</template>

<script>
import LoadingSpinner from 'rs/components/common/loading_spinner'

export default {
  components: {LoadingSpinner},
  props: ['loading', 'theme', 'disabled'],
  emits: ['click'],
  computed: {
    classes() {
      const classes = ['btn']
      if (this.disabled) classes.push('disabled')
      if (this.theme === 'danger') classes.push('pf-danger-button')
      else if (this.theme === 'secondary') classes.push('pf-secondary-button')
      else classes.push('pf-primary-button')
      return classes
    }
  },
  methods: {
    click(e) {
      if (this.disabled || this.loading) return;
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.pf-secondary-button > .loading-spinner {
  border-left: 3px solid white;
}

.loading-spinner {
  width: auto;
  margin: auto;
  aspect-ratio: 1;
}
</style>
