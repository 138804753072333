<template>
  <div class="gallery-carousel">
    <div class="gallery-carousel__main" ref="mainPicture">
      <div v-if="willShowSwitchButton(selectedIndex - 1)" class="gallery-carousel__main-slider gallery-carousel__main-left material-icons-round" @click="switchMedia(selectedIndex - 1)">arrow_back_ios</div>
      <div v-if="willShowSwitchButton(selectedIndex + 1)" class="gallery-carousel__main-slider gallery-carousel__main-right material-icons-round" @click="switchMedia(selectedIndex + 1)">arrow_forward_ios</div>
      <LazyImage
        v-if="currentMedia.dataType === 'picture'"
        :className="'gallery-carousel__main-image'"
        :alt="'User picture'"
        :urls='{ normal: currentMedia.huge, retina: currentMedia.huge }' />
      <Video
        v-else
        :key="`main-content-${currentMedia.videoId}`"
        :className="{'media-video--vimeo': currentMedia.source === 'vimeo'}"
        :dataType="currentMedia.dataType"
        :mediaPlatformName="currentMedia.media_platform_name"
        :mediaPlatformUrl="currentMedia.media_platform_url"
        :source="currentMedia.source"
        :videoId="currentMedia.videoId"
        :option="null" />
    </div>
    <div class="gallery-carousel__thumbnails">
      <div
        v-for="(item, index) in media" v-bind:key="item.id" :ref="item.id"
        class='gallery-carousel__thumbnails-child'
        @click="switchMedia(index)">
        <LazyImage
          v-if="item.dataType === 'picture'"
          :className="['gallery-carousel__thumbnails-child-image', highlighted(item.id)]"
          :alt="'User picture'"
          :urls='{ normal: item.compact, retina: item.compact }' />

        <div v-else>
          <div class="gallery-carousel__transparent-layer">
          </div>
          <Video
            :className="['media-player__thumbnail', highlighted(item.id), 'gallery-carousel__thumbnails-child-video' ]"
            :dataType="item.dataType"
            :mediaPlatformName="item.media_platform_name"
            :mediaPlatformUrl="item.media_platform_url"
            :source="item.source"
            :videoId="item.videoId"
            :option="null" />
        </div>
      </div>
      <div v-if="hasMore" class="gallery-carousel__thumbnails-child" @click="()=>this.$emit('loadMore')">
        <div class="gallery-carousel__thumbnails-child-has-more">Click to see more</div>
      </div>
    </div>
  </div>
</template>

<script>
  import LazyImage from 'rs/components/common/lazy_image'
  import Video from 'rs/components/common/review_video'

  let touchStartX = 0;
  let touchEndX = 0;
  const SWIPE_THRESHOLD = 100;
  const BOUNDARY_TO_LOAD_MORE = 5;

  export default {
    components: { LazyImage, Video },
    props: ['media', 'currentMediaId', 'hasMore'],
    computed: {
      currentMedia(){
        let idx = this.media.findIndex(e=>e.id === this.currentMediaId)
        this.selectedIndex = idx;
        return this.media[idx];
      },
    },
    data() {
      return {
        selectedIndex: null,
      }
    },
    methods: {
      highlighted(id){
        return (this.currentMediaId === id) && 'gallery-carousel__thumbnails-child-highlight'
      },
      switchMedia(idx) {
        if (idx >= this.media.length || idx < 0) return;
        this.$store.dispatch('Gallery/switchMedia', this.media[idx].id)
      },
      nextMedia(){
        this.switchMedia(this.selectedIndex + 1)
      },
      previousMedia(){
        this.switchMedia(this.selectedIndex - 1)
      },
      willShowSwitchButton(newIdx){
        return !(newIdx < 0 || newIdx >= this.media.length)
      },
      scrollToElement(currentMediaId) {
        const [el] = this.$refs[currentMediaId];
        if (el) {
          // need to add a bit of delay to support scroll RIGHT AFTER swipe
          // otherwise it will not scroll
          setTimeout(() => el.scrollIntoView({ behavior: "smooth", block: "end" }), 50)
        }
      },
      handleArrowPress(evt){
        if (evt.altKey || evt.ctrlKey || evt.metaKey || evt.shiftKey) return;
        if (evt.code === 'ArrowLeft') { // left arrow
          this.previousMedia()
        } else if (evt.code === 'ArrowRight') {
          this.nextMedia();
        }
      },
      handleSwitchMediaBySwipe() {
        if ((touchEndX+SWIPE_THRESHOLD) < touchStartX) this.nextMedia() // swipe left
        else if ((touchEndX-SWIPE_THRESHOLD) > touchStartX) this.previousMedia()  // swipe right
      },
      handleSwipeStart(e){
        touchStartX = e.changedTouches[0].screenX
      },
      handleSwipeEnd(e){
        touchEndX = e.changedTouches[0].screenX
        if (e.target.offsetParent === this.$refs.mainPicture) {  // only switch media when swipe in main Picture section
          this.handleSwitchMediaBySwipe()
        }
      }
    },
    watch: {
      currentMediaId(newId){
        this.scrollToElement(newId)
      },
      selectedIndex(newIdx){
        if (newIdx >= this.media.length - BOUNDARY_TO_LOAD_MORE && this.hasMore) {
          this.$emit('loadMore')
        }
      }
    },
    mounted() {
      this.scrollToElement(this.currentMediaId)
      document.addEventListener('keydown', this.handleArrowPress)
      document.addEventListener('touchstart', this.handleSwipeStart)
      document.addEventListener('touchend', this.handleSwipeEnd)
    },
    beforeDestroy() {
      document.removeEventListener('keydown', this.handleArrowPress)
      document.removeEventListener('touchstart', this.handleSwipeStart)
      document.removeEventListener('touchend', this.handleSwipeEnd)
    }
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  $_picThumbnailHeight: 74px;
  $_picThumbnailWidth: 102px;
  $_carouselHeight: 452px;
  $_mainSectionHeight: 346px;
  $_thumbnailsHeight: $_carouselHeight - $_mainSectionHeight;

  $_picThumbnailHeightDesktop: 94px;
  $_picThumbnailWidthDesktop: 132px;
  $_carouselWidthDesktop: 924px;
  $_carouselHeightDesktop: 844px;
  $_mainSectionWidthDesktop: $_carouselWidthDesktop;
  $_mainSectionHeightDesktop: 714px;
  $_thumbnailsHeightDesktop: $_carouselHeightDesktop - $_mainSectionHeightDesktop;

  .gallery-carousel {
    width: 100%;
    height: $_carouselHeight;
    background-color: black;

    @include respond-to(desktop) {
      position: relative;
      width: 70%;
      height: 100%;
      max-width: $_carouselWidthDesktop;
      max-height: $_carouselHeightDesktop;
    }
    @include respond-to(tablet) {
      width: 100%;
      height: $_carouselHeight;
    }
  }

  .gallery-carousel__main {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $_mainSectionHeight;

    @include respond-to(desktop) {
      width: 100%;
      height: 100%;
      max-height: $_mainSectionHeightDesktop;
    }
  }

  .gallery-carousel__main-image {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    object-fit: scale-down;
  }

  .gallery-carousel__main-slider {
    position: absolute;
    width: 36px;
    font-size: 36px;
    display: flex;
    align-items: center;
    z-index: 5;
    color: $borderColor;
    height: $_carouselHeight;
    cursor: pointer;

    @include respond-to(notmobile) {
      width: 60px;
      font-size: 60px;
    }
  }
  .gallery-carousel__main-left { left: 0; }
  .gallery-carousel__main-right { right: 0; }

  .gallery-carousel__thumbnails {
    height: $_thumbnailsHeight;
    width: 100%;
    display: flex;
    column-gap: 8px;
    align-items: center;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0 8px 0 8px;
    @include respond-to(desktop) { height: $_thumbnailsHeightDesktop; }
    }

  .gallery-carousel__thumbnails-child {
    cursor: pointer;
    position: relative;
    width: $_picThumbnailWidth;
    height: $_picThumbnailHeight;

    @include respond-to(desktop) {
      height: $_picThumbnailHeightDesktop;
      width: $_picThumbnailWidthDesktop;
    }
  }

  .gallery-carousel__thumbnails-child-highlight { border: solid 4px green; }
  .gallery-carousel__thumbnails-child-image {
    height: inherit;
    width: inherit;
    object-fit: cover;
  }
  .gallery-carousel__thumbnails-child-video {
    height: $_picThumbnailHeight;
    width: $_picThumbnailWidth;
    border-radius: 0;
    @include respond-to(desktop) {
      height: $_picThumbnailHeightDesktop;
      width: $_picThumbnailWidthDesktop;
    }
  }
  .gallery-carousel__thumbnails-child-has-more {
    height: $_picThumbnailHeight;
    width: $_picThumbnailWidth;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .gallery-carousel__transparent-layer {
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 4px;
    height: $_picThumbnailHeight;
    width: $_picThumbnailWidth;
    background:rgba(255,255,255, 0.1);

    @include respond-to(desktop) {
      height: $_picThumbnailHeightDesktop;
      width: $_picThumbnailWidthDesktop;
    }
  }
</style>

<style lang="scss">
@import '~PlatformStyles/abstract/mixins';
$_mainSectionHeight: 346px;
$_mainSectionHeightDesktop: 714px;

.media-video--vimeo {
  width: 100%;
}
.media-video--vimeo > div:first-child {
  height: $_mainSectionHeight !important;
  padding: 0 !important;
  @include respond-to(desktop) {
    height: $_mainSectionHeightDesktop !important;
  }
}
</style>
