<template>
  <div v-tooltip.top="followToolTip" class='heart-btn svg-sprite' :class="'heart-btn--' + this.followStatus + (this.shouldShow ? '' : ' hidden')"
        @click="toggleFollow">
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { get } from 'lodash'

  export default {
    components: {},

    data() {
      return {
        isHeartable: true, // Just to temporarily disable the Heart button to avoid abuse
        shouldShow: window.isReviewerLoggedIn,
      }
    },

    props: ['followeeClass', 'followeeEncodedId'],

    computed: {
      ...mapState('SharedFollow', {
        followeeIds: 'followeeIds',
      }),
      followStatus() {
        const followStatus = get(this.followeeIds, [this.followeeClass, this.followeeEncodedId])
        if (followStatus == undefined || followStatus == null) {
          return 'loading'
        } else {
          return followStatus ? 'followed' : 'not-followed'
        }
      },
      followToolTip() {
        return this.followStatus == 'followed' ? 'Unfollow' : 'Follow'
      }
    },

    methods: {
      toggleFollow(event) {
        if (this.followStatus == undefined || this.followStatus == null || !this.isHeartable) { // if we are still fetching followStatus from server, or when `isHeartable` is false
          return
        }
        this.$store.dispatch('SharedFollow/sendRequestToHeart', {
          isHearting: this.followStatus != 'followed',
          followeeClass: this.followeeClass,
          followeeEncodedId: this.followeeEncodedId,
        })

        const that = this;
        that.setIsHeartable(false)
        setTimeout(() => { that.setIsHeartable(true) }, 500)
      },

      setIsHeartable(newValue) { this.isHeartable = newValue },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/variables_new';

  .heart-btn {
    display: inline-block;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    color: $brandColor;
    border-radius: 50%;
    cursor: pointer;
    background-color: $lightBrandColor;

    &:hover {
      opacity: $hoverOpacity;
    }

    &:before {
      display: inline-block;
      font-size: 24px;
    }
  }
  .heart-btn--loading { background-position: -400px -100px; } // can be blank icon or not followed icon
  .heart-btn--followed { background-position: -300px -100px; }
  .heart-btn--not-followed { background-position: -400px -100px; }

</style>

<style lang="scss">
  // Styling for tooltips only (un-scoped)
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .rs-follow-tooltip {
    background: #DEF2F2;
    box-shadow: 0px 1px 4px rgba(146, 146, 146, 0.2);
    padding: 2px 7px;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 80px;
    text-align: center;
  }

  .rs-follow-tooltip-arrow {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #DEF2F2;
    pointer-events: none;
    position: absolute;
    right: 30px;
  }

  .rs-follow-tooltip-inner {
    color: $brandColor;
    font-weight: 400;
    font-size: 12px;
  }
</style>
