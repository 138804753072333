<template>
  <Transition name="modal">
    <div v-if="recommendation" class="recommendation-card-modal__mask" @click="handleClose">
      <div class="recommendation-card-modal__wrapper">
        <div class="recommendation-card-modal__container" @click.stop>
          <div class="recommendation-card__review-reviewer-image">
            <img :src=recommendation.image_url class="recommendation-card__review-product-image">
          </div>
          <div class="recommendation-card__review-content__container">
            <RecommendationHeader :item="recommendation" :productPath="productPath" :itemPrice="recommendation.convertedPrice" class="recommendation-card__review-header"/>
            <slot name="recommendation-card-modal__body">
              <p class="recommendation-card-modal__content">{{ recommendation.review_body }}</p>
              <span class="modal-default-button material-icons" @click="handleClose">
                close
              </span>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import RecommendationHeader from 'rs/components/common/recommendation/_recommendation_header';
import StarRating from 'rs/components/common/star_rating.vue'
import ExternalLink from 'rs/components/common/external_link.vue'

export default {
  components: { RecommendationHeader, StarRating, ExternalLink },
  computed: {
    shop() {return this.$store.getters["Shop/widgetData"]},
    recommendation() {return this.$store.getters['Shop/selectedRecommendation']},
    productPath() {
      if (this.recommendation.product_handle == 'judgeme-shop-reviews') {
        return null
      }
      else {
        return {name: this.$routes.product.name, params: {shop_slug: this.shop.shop_slug, product_handle: this.recommendation.product_handle } }
      }
    },
  },
  methods: {
    handleClose() {
      this.$store.dispatch('Shop/toggleModal', ['showRecommendationModal']);
      this.$store.commit('Shop/setSelectedRecommendation', null);
    },
  },
  mounted() {
    document.body.classList.add("modal-open");
  },
  beforeDestroy() {
    document.body.classList.remove("modal-open");
  },
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.recommendation-card-modal__mask {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.recommendation-card-modal__wrapper {
  display: table-cell;
  vertical-align: middle;

}

.recommendation-card__review-rating {
  padding-top: 12px;
}

.recommendation-card__review-content__container {
  @include respond-to(desktop) {overflow-y: auto;}
  &::-webkit-scrollbar {
    border-radius: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(136, 136, 136, 0.49);
  }

  &::-webkit-scrollbar-thumb:hover {
    border-radius: 4px;
    background: #555;
  }
}
.recommendation-card-modal__container {
  width: 343px;
  height: 560px;
  margin: 0 auto;
  color: $brandColor;
  font-size: 16px;
  padding: 20px 16px;
  border-radius: 10px;
  background-color: #fff;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  position: relative;
  text-align: left;
  @include respond-to(desktop) {
    width: 724px;
    max-height: 444px;
    padding: 20px 16px;
    display: flex;
    height: auto;
    column-gap: 20px;
  }

  @include respond-to(tablet) {
    padding: 30px 20px;
  }

  @include respond-to(notdesktop) {
    display: flex;
    flex-direction: column;
    width: 343px;
    height: auto;
    max-height: 80vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
      border-radius: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgba(136, 136, 136, 0.49);
    }

    &::-webkit-scrollbar-thumb:hover {
      border-radius: 4px;
      background: #555;
    }
  }
}
img.recommendation-card__review-product-image {
  max-width: 272px;
  max-height: 100%;
  background-image: url(https://pub-images.judge.me/judgeme/marketplace/no-img-fallback);
  border-radius: 10px;
  background-size: cover;
  text-indent: -999px;
  @include respond-to(notdesktop) {margin-bottom: 12px;}
}

.recommendation-card-reviewer-header {
  margin-left: -12px;
}

.verified-buyer-badge {height: fit-content; margin-left: -8px;}

.recommendation-card-modal__content {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
  color: $primaryFontColor;
  text-align: left;
  margin-top: 16px;
  margin-bottom: 28px;
  padding-right: 4px;
}

.modal-default-button {
  width: 40px;
  height: 40px;
  color: $primaryFontColor;
  font-size: 28px;
  font-weight: bolder;
  position: absolute;
  top: 0;
  right: 0;
  margin: 16px 16px 0 0;
  @include respond-to(notdesktop) {margin: 16px 4px 0 0;}
  cursor: pointer;
}

.modal-enter-from .recommendation-card-modal__container{
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.recommendation-card-modal__mask {
  .recommendation-card__review-reviewer-header {
    text-align: left;
    margin-left: -12px;
  }
  .recommendation-card__review-view-product-btn {
    width: 100%;
    margin-top: 14px;

    @include respond-to(notmobile) {
      max-width: 300px;
    }
  }
}
</style>
