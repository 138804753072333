<template>
  <section class="how-it-works-block__content__timeline">
    <ul>
      <li v-for="(info, index) in timeline_data" v-bind:key="index">
        <span v-if="info.icon" class="how-it-works-blocks__icon material-icons"> {{ info.icon }}</span>
        <span v-else class="how-it-works-blocks__icon"> {{ index + 1 }}</span>
        <div class="how-it-works-blocks__content">{{ info.content }}</div>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  props: ['timeline_data'],
}
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css?family=Montserrat');
  $font-stack: 'Montserrat', sans-serif;
  $font-color: #088484;
  $dark-blue: #2E4A62;
  $light-blue: #4e9bfa;

  @mixin clearfix {
    content: '';
    position: absolute;
    top: 0;
  }
  @mixin set-border($thickness, $color, $radius) {
    border: $thickness solid $color;
    border-radius: $radius;
  }
  *, *::before, *::after {
      margin: 0; padding: 0;
      box-sizing: border-box;
      font-family: $font-stack;
      color: $font-color;
  }
  .how-it-works-block__content__timeline {
    ul {
      position: relative;
      &::before {
        @include clearfix();
        height: 100%;
      }
    }
    li {
      position: relative;
      margin: 50px 35px;
      list-style: none;
      height: 24px;
      line-height: 24px;
      &>span {
        @include clearfix();
        left: -25px;
      }
      &:not(:last-child) >span::after {
        @include clearfix();
        height: 100%;
        left: 12px;
        top: 140%;
        border: 1px dashed;
      }
      div {
        &:nth-child(2) {
          font-size: 16px;
          line-height: 24px;
        }
        &:nth-child(3), &:nth-child(4) {
          font-size: 1em;
          font-style: italic;
          color: darken($font-color, 25%);
        }
      }
    }
  }
  span.how-it-works-blocks__icon {
    width: 29.27px;
    height: 29.27px;
    left: 225.37px;
    top: 563.37px;
    background: #C1E6E6;
    border: 1px solid #088484;
    border-radius: 50%;
    text-align: center;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .how-it-works-blocks__content {
    margin-left: 20px;
    padding-top: 2px;
  }

  .how-it-works-blocks__section__content:first-child .how-it-works-block__content__timeline li:nth-child(4) .how-it-works-blocks__content{
    width: 246px;
    background: linear-gradient(to left, #ffffff, transparent 84%, #fff, white 25%);
    margin-left: -30px;
    text-align: right;
    height: 40px;
  }
</style>
