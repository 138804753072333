"use strict";
/**
 * @file vue-awesome-swiper
 * @module exporter
 * @author Surmon <https://github.com/surmon-china>
 */
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("rs/plugins/swiper/constants");
var directive_1 = require("rs/plugins/swiper/directive");
var swiper_1 = require("rs/plugins/swiper/swiper");
var slide_1 = require("rs/plugins/swiper/slide");
var getInstaller = function (SwiperClass) {
  var install = function (Vue, globalOptions) {
    if (install.installed)
      return;
    var SwiperComponent = (0, swiper_1.default)(SwiperClass);
    if (globalOptions) {
      SwiperComponent.options.props.defaultOptions.default = function () { return globalOptions; };
    }
    Vue.component(constants_1.CoreNames.SwiperComponent, SwiperComponent);
    Vue.component(constants_1.CoreNames.SwiperSlideComponent, slide_1.default);
    Vue.directive(constants_1.CoreNames.SwiperDirective, (0, directive_1.default)(SwiperClass, globalOptions));
    install.installed = true;
  };
  return install;
};
function exporter(SwiperClass) {
  var _a;
  return _a = {
    version: 'PACKAGE_VERSION',
    install: getInstaller(SwiperClass),
    directive: (0, directive_1.default)(SwiperClass)
  },
    _a[constants_1.CoreNames.SwiperComponent] = (0, swiper_1.default)(SwiperClass),
    _a[constants_1.CoreNames.SwiperSlideComponent] = slide_1.default,
    _a;
}
exports.default = exporter;
