"use strict";
/**
 * @file vue-awesome-swiper
 * @module utils
 * @author Surmon <https://github.com/surmon-china>
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.kebabcase = void 0;
var kebabcase = function (string) {
  return string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase();
};
exports.kebabcase = kebabcase;
