<template>
  <div class="page-new-dispute">
    <LoadingSpinner v-if="!loaded"/>
    <NotFound v-else-if="error" :goBackPath="goBackPath" :text="error" />
    <DisputeForm v-else/>
  </div>
</template>
<script>
import DisputeForm from 'rs/components/pages/new_dispute/_dispute_form'
import LoadingSpinner from 'rs/components/common/loading_spinner'
import NotFound from 'rs/components/pages/new_dispute/_not_found.vue'
import actions from 'rs/components/pages/private_profile/arbitration_actions.js'
import { mapGetters } from 'vuex'
const ORDER_NOT_FOUND_TEXT = "We’re sorry but we couldn't find any orders associated with this store in our system."
const SHOP_NOT_ENABLED_TEXT = "We're sorry, this shop has not enabled Complaints feature yet."

export default {
  components: {DisputeForm, LoadingSpinner, NotFound},
  provide: {actions},
  data: () => ({
    loaded: false,
    errorCode: null,
  }),
  computed: {
    ...mapGetters('Arbitration', ['disputeFormData', 'orders']),
    goBackPath() {
      return {
        name: this.$routes.shop.name,
        params: {shop_slug: this.$route.params.shop_slug}
      }
    },
    orderNotFoundText() {
      return this.disputeFormData && this.orders.length === 0 ? ORDER_NOT_FOUND_TEXT : null
    },
    shopNotEnabledText() {
      return this.errorCode === 404 ? SHOP_NOT_ENABLED_TEXT : null
    },
    unknownErrorText() {
      return this.errorCode === 500 ? "Sorry, something went wrong." : null
    },
    error() {
      return this.orderNotFoundText || this. shopNotEnabledText || this.unknownErrorText
    }
   },
  mounted() {
    if (!window.isReviewerLoggedIn) {
      window.location.href = '/login'
      return;
    }
    this.$store.dispatch('Arbitration/initPageNewDispute', this.$route.params.shop_slug)
      .then(errorCode => {
        this.errorCode = errorCode;
        this.loaded = true;
      })
  }
}
</script>

<style lang="scss" scoped>
.loading-spinner {
  width: 100px;
  height: 100px;
  margin: 100px auto auto auto;
}

.page-new-dispute {
  margin: 0 auto;
  padding: 28px 0 100px 0;
  max-width: 720px;
}
</style>
