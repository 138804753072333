<template>
  <div class="simple-rating" v-if="rating > 0">
    <span class="simple-rating__number--rating">{{ $sharedUtils.roundNumberToStringWithDecimalsSuffix(rating, 2) }}</span>
    <span class="simple-rating__number--small">/5</span>
    <img :src="require('images/platform/green_star.svg')" alt="review star icon" class="simple-rating__star"/>
  </div>
</template>
<script>
export default {
  props: ['rating'],
}
</script>
<style lang="scss" scoped>
.simple-rating {
  line-height: 20px;
}
.simple-rating__number--rating {
  font-size: 20px;
  font-weight: 800;
}

.simple-rating__number--small {
  font-size: 12px;
  margin-left: 2px;
  margin-right: 5px;
}

.simple-rating__star {
  height: 16px;
}
</style>
