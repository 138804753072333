import VTooltip from 'v-tooltip'

const VTooltipOptions = {
  defaultInnerSelector: '.rs-follow-tooltip-inner',
  defaultTemplate: '<div class="rs-follow-tooltip" role="tooltip"><div class="rs-follow-tooltip-inner"></div><div class="rs-follow-tooltip-arrow"></div></div>',
  defaultArrowClass: 'rs-follow-tooltip-arrow rs-popover-arrow',
  disposeTimeout: 0,
  popover: {
    defaultBaseClass: 'rs-follow-tooltip rs-follow-popover',
    defaultInnerClass: 'rs-follow-tooltip-inner rs-follow-tooltip-popover-inner',
  }
}

export default {
  install(Vue) {
    Vue.use(VTooltip, VTooltipOptions)
  }
}
