<template>
  <div class="judgeme-medals__section">
    <div class="judgeme-medals__section__content-wrapper">
      <div class="judgeme-medals__section__content" v-for="(card, idx) in MedalsBlocks" v-bind:key="idx">
        <MedalsBlock :heading="card.heading" :description="card.description" :text="card.text" :medalsInfos="card.medalsInfos" :index="idx"/>
      </div>
    </div>
  </div>
</template>
<script>

import MedalsBlock from "rs/components/pages/medals/_medals_block"

export default {
  components: {MedalsBlock},
  data() {
    return {
      MedalsBlocks: [
        {
          heading: 'Transparency',
          description: 'We award Transparency medals to stores that display at least 80% of their verified reviews. Diamond medal stores display 100% of the total verified reviews they’ve received.',
          text: 'Published {{ threshold }} of verified reviews received in total.',
          medalsInfos: [
            {
              name: 'Diamond',
              imgClass: 'tran-500',
              threshold: '100%',
            },
            {
              name: 'Platinum',
              imgClass: 'tran-400',
              threshold: '99%',
            },
            {
              name: 'Gold',
              imgClass: 'tran-300',
              threshold: '95%',
            },
            {
              name: 'Silver',
              imgClass: 'tran-200',
              threshold: '90%',
            },
            {
              name: 'Bronze',
              imgClass: 'tran-100',
              threshold: '80%',
            },
          ],
        },
        {
          heading: 'Authenticity',
          description: 'We award Authenticity medals to stores when at least 80% of the reviews they show are verified. Diamond medals are awarded to stores when 100% of the reviews they show are verified.',
          text: '{{ threshold }} of published reviews are verified reviews',
          medalsInfos: [
            {
              name: 'Diamond',
              imgClass: 'auth-500',
              threshold: '100% ',
            },
            {
              name: 'Platinum',
              imgClass: 'auth-400',
              threshold: '99%',
            },
            {
              name: 'Gold',
              imgClass: 'auth-300',
              threshold: '95%',
            },
            {
              name: 'Silver',
              imgClass: 'auth-200',
              threshold: '90%',
            },
            {
              name: 'Bronze',
              imgClass: 'auth-100',
              threshold: '80%',
            },
          ],
        },
        {
          heading: 'Verified Reviews',
          description: 'We award Verified Reviews medals to stores that have received at least 10 verified reviews. Stores that have received at least 1,000 verified reviews are awarded the Diamond medal.',
          text: 'Received at least {{ threshold }} verified reviews.',
          medalsInfos: [
            {
              name: 'Diamond',
              imgClass: 'ver_rev-500',
              threshold: '1,000',
            },
            {
              name: 'Platinum',
              imgClass: 'ver_rev-400',
              threshold: '500',
            },
            {
              name: 'Gold',
              imgClass: 'ver_rev-300',
              threshold: '100',
            },
            {
              name: 'Silver',
              imgClass: 'ver_rev-200',
              threshold: '50',
            },
            {
              name: 'Bronze',
              imgClass: 'ver_rev-100',
              threshold: '10',
            },
          ],
        },
        {
          heading: 'Top Store',
          description: 'We award Top Store medals  to the stores that have published the most of verified reviews.',
          text: 'The {{ threshold }} stores have received and published the highest number of verified reviews.',
          medalsInfos: [
            {
              name: 'Top 10 Shops',
              imgClass: 'tops-500',
              threshold: '10',
            },
            {
              name: 'Top 25 Shops',
              imgClass: 'tops-400',
              threshold: '25',
            },
            {
              name: 'Top 50 Shops',
              imgClass: 'tops-300',
              threshold: '50',
            },
            {
              name: 'Top 100 Shops',
              imgClass: 'tops-200',
              threshold: '100',
            },
            {
              name: 'Top 250 Shops',
              imgClass: 'tops-100',
              threshold: '250',
            },
            {
              name: 'Top 1% Shops',
              imgClass: 'tops-90',
              threshold: '1%',
            },
            {
              name: 'Top 5% Shops',
              imgClass: 'tops-80',
              threshold: '5%',
            },
            {
              name: 'Top 10% Shops',
              imgClass: 'tops-70',
              threshold: '10%',
            },
          ],
        },
        {
            heading: 'Trending Store',
            description: 'We award Trending Store medals to those that have published the most reviews in the past 30 days.',
            text: 'The {{ threshold }} stores that have received and displayed the most verified reviews in the last 30 days',
            medalsInfos: [
              {
                name: 'Top 10 Trending Shops',
                imgClass: 'tops_trend-500',
                threshold: '10',
              },
              {
                name: 'Top 25 Trending Shops',
                imgClass: 'tops_trend-400',
                threshold: '25',
              },
              {
                name: 'Top 50 Trending Shops',
                imgClass: 'tops_trend-300',
                threshold: '50',
              },
              {
                name: 'Top 100 Trending Shops',
                imgClass: 'tops_trend-200',
                threshold: '100',
              },
              {
                name: 'Top 250 Trending Shops',
                imgClass: 'tops_trend-100',
                threshold: '250',
              },
              {
                name: 'Top 1% Trending Shops',
                imgClass: 'tops_trend-90',
                threshold: '1%',
              },
              {
                name: 'Top 5% Trending Shops',
                imgClass: 'tops_trend-80',
                threshold: '5%',
              },
              {
                name: 'Top 10% Trending Shops',
                imgClass: 'tops_trend-70',
                threshold: '10%',
              },
            ],
          },
          {
            heading: 'Monthly Record',
            description: "We award Monthly Record medals to stores that have published an all-time record of verified reviews within a calendar month. Stores get a Diamond medal if they've published an all-time high of 250 verified reviews or more in that time.",
            text: 'Published an all-time record of {{ threshold }} verified reviews or more in one calendar month.',
            medalsInfos: [
              {
                name: 'Diamond',
                imgClass: 'mon_rec-500',
                threshold: '250',
              },
              {
                name: 'Platinum',
                imgClass: 'mon_rec-400',
                threshold: '100',
              },
              {
                name: 'Gold',
                imgClass: 'mon_rec-300',
                threshold: '50',
              },
              {
                name: 'Silver',
                imgClass: 'mon_rec-200',
                threshold: '20',
              },
              {
                name: 'Bronze',
                imgClass: 'mon_rec-100',
                threshold: '10',
              },
            ],
          },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .judgeme-medals__section__content {
    margin: 40px auto;
  }

</style>

<style lang="scss">
@import '~PlatformStyles/abstract/mixins';
  .judgeme-medals__section__split-box h2.trust-block__content--heading {
    font-size: 24px;
    line-height: 36px;
    @include respond-to(notdesktop) {
      font-size: 20px;
      line-height: 30px;
    }
    .brandColor {
      color: $brandColor;
    }
  }

</style>
