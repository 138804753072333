import axios from 'shared/utils/axios'

const defaultState = {
  page: 1,
  total: 0,
  disputes: [],
}
const state = {
  ...defaultState,
  dispute: null,
}
const defaultParams = {
  format: 'json',
  redesign: true,
  total_results: false,
}

const getters = {
  disputes: state => state.disputes,
  page: state => state.page,
  total: state => state.total,
  dispute: state => state.dispute,
}

const mutations = {
  resetState: (state) => {
    for (const [key] in defaultState) {
      state[key] = defaultState[key]
    }
  },
  setState: (state, [name, value]) => state[name] = value, // use for simple mutated state
}

const actions = {
  async initPageIndex(context, payload) {
    try {
      const response = await axios.get('/arbitrator', {params: {...defaultParams, total_results: true, ...payload}})
      context.commit('setState', ['disputes', response.data.disputes])
      context.commit('setState', ['page', response.data.page])
      context.commit('setState', ['total', response.data.total_results])
    } catch (err) {
      console.log(err)
      context.commit('resetState')
    }
  },
}


export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
}
