<template>
  <div class='review-event'>
    <FeedCardShopHeader v-bind:feedEvent="feedEvent" v-bind:shop="feedEvent.event_owner" :description="headerDescription" v-if="this.feedEvent.event_owner.class == 'Shop'" />

    <div class='feed-card-body'>
      <ShopCustomised :shop='feedEvent.event_owner' />
    </div>
  </div>
</template>

<script>
  import FeedCardShopHeader from 'rs/components/common/feed_cards/_shop_header'
  import ShopCustomised from 'rs/components/common/shop_customised'

  export default {
    components: { FeedCardShopHeader, ShopCustomised },
    props: ['feedEvent'],

    computed: {
      headerDescription() {
        if (this.feedEvent.event_type == 'shop_finish_profile') {
          return 'Customised their profile!'
        }
      },
      shopDetails() { return this.feedEvent.event_owner },
    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

</style>
