import axios from 'shared/utils/axios'

const _extractFolloweeIdsSearchResults = (searchResults) => {
  const shopEncodedIds = searchResults.map((searchResult) => { return searchResult.encoded_shop_id })
  return { 'Shop': shopEncodedIds }
}

const state = {
  searchParams: { q: '', page: 1, per_page: 20 },
  searchResults: { shop_results: [] },
  isLoadingSearchResults: true,
  hasMoreSearchResults: false,
  homeMetrics: [],
  isNewSearch: true,
}

const getters = {
}

const mutations = {
  setSearchParams (state, newSearchParams) { state.searchParams = newSearchParams },
  setIsNewSearch: (state, payload) => state.isNewSearch = payload,
  clearSearchResults (state) { state.searchResults = { shop_results: [] } },
  appendSearchResults (state, newSearchResults) {
    state.searchResults.results_total_count = newSearchResults.results_total_count || 0
    state.searchResults.shop_results.push(...newSearchResults.shop_results)
    state.hasMoreSearchResults = (newSearchResults.shop_results.length >= newSearchResults.per_page)
  },
  setIsLoadingSearchResults: (state, isLoading) => state.isLoadingSearchResults = isLoading,
  setMetricsState: (state, metrics) => state.homeMetrics = metrics,
}

const actions = {
  makeNewSearch (context, payload) {
    context.commit('clearSearchResults')
    context.commit('setIsNewSearch', true)
    context.dispatch('searchShops', { ...payload, country: payload.country, page: 1 }
    ).then(() => {
      context.commit('setIsNewSearch', false)
    })
  },

  searchShops (context, payload) {
    context.commit('setIsLoadingSearchResults', true)
    context.commit('setSearchParams', payload)

    axios.get('/store_search.json', {
      params: payload,
    }).then(function (response) {
      context.dispatch('Meta/setMeta', response.data.meta_data, {root: true})
      context.commit('appendSearchResults', response.data.data)
      context.commit('setIsLoadingSearchResults', false)
      const followeeIdsToCheck = _extractFolloweeIdsSearchResults(response.data.data.shop_results)
      context.dispatch('SharedFollow/fetchFolloweeIds', followeeIdsToCheck, { root: true })
      context.commit('setMetricsState', response.data.metrics)
    })
  },
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,

  namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
}
