import axios from 'shared/utils/axios';
import {pipeAsync} from "shared/utils/js_core";
import {setState, setMeta, fetchRequest} from 'rs/store/store_utils/shared'
import utils from 'shared/utils/utils'
import {SearchCache} from 'rs/store/store_utils/search_utils'
import { mapMediaFromReviews } from "rs/store/shared/gallery_utils";
import {
  applyFilter,
  clearFilter,
  removeFilter,
  applySort,
  loadReviewsOnPageChange,
  trackChange
} from "rs/store/store_utils/filter_sort_utils";

const getRequestUrl = (slug) =>  `/${slug}`
const resCache = new SearchCache({}, true, 60) // {[allValuesFromQueryObject]: { sha: ..., results: [] } }
const fetchWithCache = fetchRequest(resCache)

const defaultQueries = {
  filter_rating: [],
  review_type: 'all-reviews'
}
const reviewsQueries = {tab: 'reviews', search: '', sort_by: 'pictures_first', page: 1, ...defaultQueries}
const PER_PAGE = 20; // default per_page. Beware backend can change this (not likely)

const reviewTypes = {
  'all-reviews': 'All reviews',
  'published': 'Published reviews',
  'unpublished': 'Unpublished reviews',
}
const defaultState = {
  slug: '',
  customPublicSlug: '',
  doneLoadProfile: false,
  doneLoadReviews: false,
  doneLoadMoreReviews: true,
  doneLoadRewards: false,
  doneLoadMoreRewards: true,
  doneLoadOrders: false,
  doneLoadMoreOrders: true,
  doneLoadDisputes: false,
  doneLoadMoreDisputes: true,
  reviewsData: null,
  profileData: null,
  extraData: null,
  queries: reviewsQueries,
  recommendationsCurrentPage: 1,

  // cache previousSearchString to reset page when search again
  previousSearch: '',
  // cache  { [review_id]: review }
  updatedReviews: {},

  rewardsData: null,
  rewardsCurrentPage: 1,
  rewardsQueries: {tab: 'rewards', page: 1},

  ordersData: null,
  ordersCurrentPage: 1,
  ordersQueries: {tab: 'orders', page: 1},

  disputesData: null,
  disputesCurrentPage: 1,
  disputesQueries: {tab: 'disputes', page: 1},

  media: [],
  showingSubscribeModal: false,
}
const dataAdaptedFromState = { // check in state/defaultstates
  'orders':   { namePrefix: 'orders', nameSuffix: 'Orders',  queriesName: 'ordersQueries' },
  'reviews':  { namePrefix: 'reviews', nameSuffix: 'Reviews', queriesName: 'queries' },
  'rewards':  { namePrefix: 'rewards', nameSuffix: 'Rewards', queriesName: 'rewardsQueries' },
  'disputes':  { namePrefix: 'disputes', nameSuffix: 'Disputes', queriesName: 'disputesQueries' },
}
const state = {...defaultState}

const getters = {
  showingSubscribeModal: state => state.showingSubscribeModal,
  doneLoadProfile: state => state.doneLoadProfile,
  doneLoadReviews: state => state.doneLoadReviews,
  doneLoadMoreReviews: state => state.doneLoadMoreReviews,
  doneLoadRewards: state => state.doneLoadRewards,
  doneLoadMoreRewards: state => state.doneLoadMoreRewards,
  doneLoadOrders: state => state.doneLoadOrders,
  doneLoadMoreOrders: state => state.doneLoadMoreOrders,
  doneLoadDisputes: state => state.doneLoadDisputes,
  doneLoadMoreDisputes: state => state.doneLoadMoreDisputes,
  updatedReviews: state => public_id => state.updatedReviews[public_id],
  rewardsCount: state => state.extraData ? state.extraData.rewards_data.total_count : 0,
  ordersCount: state => state.extraData ? state.extraData.orders_data.total_count : 0,
  disputesCount: state => state.extraData ? state.extraData.disputes_data.total_count : 0,
  profileData: state => state.profileData,
  reviewerSlug: state => state.slug,
  extraData: state => state.extraData,
  recommendationPaginated: state => {
    const defaultValue = {
      total: 0,
      recommendations: [],
    }
    if (!state.extraData || !state.extraData.recommendations_data) {
      return defaultValue
    }
    const data = state.extraData.recommendations_data
    const currentPage = state.recommendationsCurrentPage
    return {
      totalPages: Math.ceil(data.total / PER_PAGE),
      recommendations: data.items.slice(PER_PAGE * (currentPage - 1), PER_PAGE * currentPage),
      currentPage,
    }
  },
  reviewsData: state => {
    const {
      total_count,
      total_pages,
      current_page,
      reviews = []
    } = state.reviewsData
    return {
      totalCount: total_count || 0,
      totalPages: total_pages || 0,
      currentPage: current_page || 1,
      reviews,
    }
  },
  rewardsData: (state, getters) => normalizeData(state, getters, 'rewards'),
  ordersData: (state, getters) => normalizeData(state, getters, 'orders'),
  disputesData: (state, getters) => normalizeData(state, getters, 'disputes'),
  changes: trackChange(defaultQueries, reviewTypes),
  favouriteWidgetData: state => {
    const {
      shops_count = 0,
      products_count = 0,
      reviewers_count = 0,
    } = state.extraData.followings_data
    return {
      totalCount: reviewers_count + products_count + shops_count,
      headerText: `Favourites`,
      reviewerText: utils.pluralize(reviewers_count, 'reviewer'),
      productText: utils.pluralize(products_count, 'product'),
      storeText: utils.pluralize(shops_count, 'store'),
    }
  },
  totalReviewsCount: state => state.extraData && state.extraData.reviews_data && state.extraData.reviews_data.total_reviews_count || 0,

  reviewTypes: () => reviewTypes,
  media: state => state.media,
}

const mutations = {
  setState(state, [name, value]) {
    state[name] = value
  },
  setRecommendationData(state, data) {
    state.extraData.recommendations_data = data
  },
  setUpdatedReviews(state, review) {
    state.updatedReviews = {...state.updatedReviews, [review.public_id]: review};
  },
  setFilterQueries(state, filterQueries) {
    state.queries = {...state.queries, ...filterQueries}
  },
  setSearchQuery(state, search) {
    state.queries = {...state.queries, search}
  },
  setSortQuery(state, query) {
    state.queries = {...state.queries, ...query}
  },
  setMedia (state, responseData) {
    state.media = mapMediaFromReviews(responseData.reviews, state.media)
  },
}

const actions = {
  async initPage(context, unknownSlug) {
    const {commit, dispatch, state} = context

    const requestUrl = getRequestUrl(unknownSlug)

    if (state.slug === unknownSlug) {  // no need to request
      setState(context.commit, 'doneLoadProfile')(true)
      dispatch('Profile/setStoreName', 'PrivateProfile', {root: true})
      return;
    }

    const profileData = await fetchWithCache(setMeta(dispatch))({requestUrl})

    if (!profileData.basic_data) {
      // TODO: handle case: backend return no data
      // - wrong slug
      // - not authorized (private profile but not owner)
      window.location.href = '/login';
      return;
    }

    // valid request => can be public or private
    if (profileData.basic_data.encoded_id !== unknownSlug) { // public profile
      dispatch('PublicProfile/inheritFromPrivateProfile', profileData, {root: true})
    } else {  // private profile
      setState(commit, 'profileData')(profileData.basic_data)
      setState(commit, 'showingSubscribeModal')(profileData.basic_data && profileData.basic_data.subscribed_rs === null)
      setState(commit, 'extraData')(profileData.extra_data)
      setState(commit, 'slug')(profileData.basic_data.encoded_id)
      await dispatch('loadReviews')
      await dispatch('Profile/setStoreName', 'PrivateProfile', {root: true})
    }

    setState(context.commit, 'doneLoadProfile')(true)
  },
  applyFilter(context, filterQueries) {
    applyFilter(context, filterQueries)()
  },
  clearFilter(context) {
    clearFilter(context)(defaultQueries)
  },
  removeFilter(context, filter) { // filter = { type: xxx, value: yyy}
    removeFilter(context, filter)(defaultQueries)
  },
  applySort(context, payload) {
    applySort(context, payload)()
  },
  loadReviewsOnPageChange(context, payload) {
    loadReviewsOnPageChange(context, payload)()
  },
  loadDisputesOnPageChange(context, {page}) {
    context.dispatch('loadDisputes', {page})
  },
  paginateRecommendation({commit}, {page}) {
    setState(commit, 'recommendationsCurrentPage')(page)
  },
  async loadDataWithReactiveState({commit, state}, {namePrefix, nameSuffix, queriesName, params}) {
    setState(commit, 'doneLoadMore' + nameSuffix)(false)
    const {slug} = state
    const queries = state[queriesName]
    const requestUrl = getRequestUrl(slug)

    const response = await pipeAsync(
      fetchWithCache(),
      setState(commit, namePrefix + 'Data')
    )({requestUrl, params: {...queries, ...params}})

    setState(commit, 'doneLoad' + nameSuffix)(true)
    setState(commit, 'doneLoadMore' + nameSuffix)(true)
    return response
  },
  async loadReviews({commit, dispatch}, payload) { // also called inside `loadReviewsOnPageChange` method
    const responseData = await dispatch('loadDataWithReactiveState', { ...dataAdaptedFromState['reviews'], params: payload})
    commit('setMedia', responseData)
  },
  loadRewards({dispatch}, payload) {
    dispatch('loadDataWithReactiveState', { ...dataAdaptedFromState['rewards'], params: payload})
  },
  loadOrders({dispatch}, payload) {
    dispatch('loadDataWithReactiveState', { ...dataAdaptedFromState['orders'], params: payload})
  },
  loadDisputes({dispatch}, payload) {
    dispatch('loadDataWithReactiveState', { ...dataAdaptedFromState['disputes'], params: payload})
  },
  async deleteReview({commit}, review) {
    review = {...review, deleted: true}
    return await sendUpdateRequestAndCacheResult(commit, review)('delete', `profile/reviews/${review.public_id}`)
  },
  async restoreReview({commit}, review) {
    review = {...review, deleted: false}
    return await sendUpdateRequestAndCacheResult(commit, review)('put', `profile/reviews/${review.public_id}/restore`)
  },
  async togglePublishReview({commit}, review) {
    review = {...review, published: !review.published}
    return await sendUpdateRequestAndCacheResult(commit, review)('put', `profile/reviews/${review.public_id}/${review.published ? 'publish' : 'unpublish'}`)
  },
  // mutate recommendation in the list after Success
  async updateRecommendationOnSuccess({state, commit}, {data, published, deleted}) {
    let items = state.extraData.recommendations_data.items;
    const itemIndex = items.findIndex(item => item.id === data.id)

    if (itemIndex === -1) return;
    if (deleted) {                                                    // after deleted
      items = items.filter(item => item.id !== data.id)
    } else {                                                          // after updated
      let updatedItem = items[itemIndex]
      updatedItem = {...data}
      if (published !== undefined) updatedItem.published = published; // after toggled publish
      items[itemIndex] = updatedItem
    }

    commit('setRecommendationData', {...state.extraData.recommendations_data, items})
  },
  async fetchMyProfile({commit, state, dispatch}) {
    if (state.profileData) {
      return state.profileData
    }
    const profileData = await fetchWithCache()({requestUrl: '/profile/edit'})
    if (!profileData.basic_data) {
      // - not authorized
      window.location.href = '/login';
      return;
    }
    setState(commit, 'profileData')(profileData.basic_data)
    setState(commit, 'extraData')(profileData.extra_data)
    setState(commit, 'slug')(profileData.basic_data.encoded_id)
    await dispatch('loadReviews')
    await dispatch('Profile/setStoreName', 'PrivateProfile', {root: true})
    setState(commit, 'doneLoadProfile')(true)

    return state.profileData
  },
  closeSubscribeModal({commit}) {
    setState(commit, 'showingSubscribeModal')(false)
  },
  sendUpdateProfileRequest(context, data) {
    return axios.put('/profile/reviewers/0', {reviewer: data})
  },
  async subscribe({state, commit, dispatch}, bool) {
    try{
      const res = await dispatch('sendUpdateProfileRequest', {subscribed_rs: bool})
      setState(commit, 'profileData')({...state.profileData, subscribed_rs: bool})
    } catch (e) {
      console.log(e);
    } finally {
      dispatch('closeSubscribeModal')
    }
  }
}
const sendUpdateRequestAndCacheResult = (commit, review) => async (method, url, params = {})  => {
  const ok = await sendUpdateRequest(url, method, params)
  if (ok) commit('setUpdatedReviews', review) // cache updated value
  return ok;
}
async function sendUpdateRequest(url, method, params = {}) {
  try {
    await axios[method](url, params)
    return true
  } catch {
    return false
  }
}

function normalizeData(state, getters, name) {
  const countName = name + 'Count'
  const dataName = name + 'Data'
  let totalCount = 0
  let totalPages = 0
  let currentPage = 1
  let items = []
  if (state[dataName]) {
    totalCount = getters[countName]
    totalPages = Math.ceil(totalCount / PER_PAGE)
    currentPage = state[dataName].current_page
    items = state[dataName].items
  }
  return {totalCount, totalPages, currentPage, [name]: items}
}

export const cacheRequestForGallery = (slug,page) => fetchWithCache()({
  requestUrl: getRequestUrl(slug),
  params: {...reviewsQueries, page},
});

export default {state, getters, mutations, actions, namespaced: true}
