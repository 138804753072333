<!--
  Wrapper for _dispute.vue to:
  - be used independently in a list (index page)
  - be used in single page (show page)
-->
<template>
  <div>
    <div v-for="dispute in disputes">
      <slot name="header" :scopedDispute="dispute"></slot>
      <Dispute
        v-show="!hidden"
        v-on="$listeners"
        v-bind="$attrs"
        :key="dispute.id"
        :dispute="dispute"/>
    </div>
  </div>
</template>
<script>
import Dispute from 'shared/vue_components/arbitration/components/_dispute.vue'
export default {
  props: {
    dispute: {
      type: Object,
      required: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  components: {Dispute},
  inject: ['arbitrationContext'],
  data() {
    return {
      disputes: [this.dispute],
    }
  },
  provide() {
    return {
      actions: {
        ...this.arbitrationContext,
        fetchDispute: this.fetchDispute,
        updateDispute: this.updateDispute,
        renderDispute: this.renderDispute,
        requestArbitrator: this.requestArbitrator,
      }
    }
  },
  methods: {
    renderDispute(newDispute) {
      if (!newDispute) return;
      const index = this.disputes.findIndex(d => d.id === newDispute.id)
      if (index > -1) {
        this.disputes.splice(index, 1, newDispute)
      }
    },
    updateOrFetch(dispute) {
      if (!dispute) {
        this.fetchDispute(dispute)
      } else {
        this.renderDispute(dispute)
      }
    },
    fetchDispute(payload) {
      return this.arbitrationContext.fetchDispute(payload).then(this.renderDispute)
    },
    updateDispute(payload) {
      return this.arbitrationContext.updateDispute(payload).then(this.updateOrFetch)
    },
    requestArbitrator(payload) {
      return this.arbitrationContext.requestArbitrator(payload).then(this.updateOrFetch)
    },
  },
  watch: {
    dispute(newDispute) {
      this.disputes = [newDispute]
    },
  }
}
</script>
