<template>
  <div class="reviewer-event__body-categories">
    <Badge :categories="categories" :maxDisplayQuantity=20 :minDisplayQuantity=20 :styleObject="{ height: 'auto' }"/>
  </div>
</template>
<script>
  import Badge from "rs/components/common/people_card_category";
  export default {
    components: { Badge },
    props: ['categories']
  }
</script>

<style lang="scss" scoped>
  .reviewer-event__body-categories {
    margin-bottom: 20px;
  }
</style>
