<template>
  <div class="how-it-works-block">
    <div class="how-it-works-block__content-left">
      <h2 class="how-it-works-block__content--heading">{{heading}}</h2>
      <p class="how-it-works-block__content--paragraph" v-html="content" v-if="content"></p>
      <HowItWorksTimeline v-if="TimeLineBlocks[blockIndex]" :timeline_data="TimeLineBlocks[blockIndex]" />
      <MarketPlaceCircles  v-if="blockIndex == 0" :blockIndex="blockIndex"/>
      <router-link v-if="blockUrl"
              :class="blockUrlClass"
                :is="urlVuePath ? 'router-link' : 'a'"
                :to="blockUrl"
                :href="blockUrl"
      >
        {{ urlText }}
      </router-link>
    </div>
    <div class="how-it-works-block__content-right how-it-works-scores-img">
      <img v-if="imageUrl" class="how-it-works-block-card__image" :src="imageUrl" alt="how it works image"/>
      <MarketPlaceCircles  v-if="blockIndex == 3 || blockIndex == 5" :blockIndex="blockIndex"/>
    </div>
  </div>
</template>

<script>
import HowItWorksTimeline from "rs/components/pages/how_it_works/_how_it_works_timeline"
import MarketPlaceCircles from 'rs/components/pages/how_it_works/_how_it_works_circles'

export default {
  components: {HowItWorksTimeline, MarketPlaceCircles},
  props: ['heading', 'content', 'imageUrl', 'urlText', 'blockUrl', 'blockUrlClass', 'blockIndex', 'urlVuePath'],
  data() {
    return {
      TimeLineBlocks: {
       0:
        [
          {
            "content":"Search for the product you want",
          },
          {
            "content":"Use the Trust Filters to refine your results",
          },
          {
            "content":"Choose a product",
          },
          {
            "content":"Go to the store's website",
          },
          {
            "content": 'Complete your purchase!',
          }
        ],
        3:
        [
          {
            "content": "Build your profile",
            "icon": 'person'
          },
          {
            "content": "Manage your reviews",
            "icon": "auto_fix_normal"
          },
          {
            "content": "Add photos and videos to your reviews",
            "icon": "image"
          },
          {
            "content": "Publish to your profile and share",
            "icon": "share"
          },
          {
            "content": 'Add affiliate links to your reviews',
            "icon":"link"
          }
        ],
        5:
        [
          {
            "content": "Look for the Trust Box",
            "icon": "star"
          },
          {
            "content": "Check the store and product ratings ",
            "icon": "visibility"
          },
          {
            "content": "Study the Transparency and Authenticity scores",
            "icon": "local_library"
          },
          {
            "content": "Read some reviews",
            "icon": "format_align_left"
          },
          {
            "content": 'Safe shopping!',
            "icon": "local_mall"
          }
        ],
      }
    }
  }
}

</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';

  .how-it-works-block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    width: 100vw;
    margin: 0 auto;
    padding: 0px 16px;
    @include respond-to(notdesktop) {
      flex-direction: column-reverse;
      gap: 0px;
      padding: 28px 20px;
      max-width: 100%;
    }
    .btn { min-width: 220px; }
    a:not(.btn) {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .btn.btn-transparent {
    color: white ;
    border: 2px solid;
  }
  .btn.btn-transparent:hover {
    color: $brandColor;
    background: white;
  }

  .how-it-works-block__content--heading  {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    padding: 20px 0px;
    @include respond-to(mobile) {
      font-size: 24px;
      line-height: 36px;
    }
  }
  .how-it-works-blocks__section__content:nth-child(4) {
    h2.how-it-works-block__content--heading:before {
      background: url(https://pub-images.judge.me/judgeme/marketplace/build_your_trust/coming_soon_text) no-repeat;
      background-size: 120px 24px;
      display: inline-block;
      width: 120px;
      height: 24px;
      content: '';
      background-size: contain;
      margin-top: -21px;
      position: absolute;
      right: 0;
      margin-right: -5%;
    }
  }

  .how-it-works-block__sub-heading  {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 21px;
    @include respond-to(mobile) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  .how-it-works-block__content--paragraph {
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  img.how-it-works-block-card__image {
    display: block;
    width: 100%;
    height: auto;
    max-width: 400px;
    margin: 0 auto;
  }

  .how-it-works-block__content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .how-it-works-block__content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    max-width: 90%;
    margin: 0 auto;
    @include respond-to(notdesktop) {
      flex-direction: column-reverse;
      gap: 28px;
      margin-bottom: 60px;
      padding-left: 32px;
      max-width: 100%;
    }
  }

  .how-it-works-blocks__section__content:not(.how-it-works-blocks-division) {
    .how-it-works-block__content-left, .how-it-works-block__content-right {
      max-width: 500px;
      position: relative;
    }
  }

  .how-it-works-blocks__section__content:nth-child(3), .how-it-works-blocks__section__content:nth-child(5) {
    img {
      width: 100px;
    }
    .how-it-works-block__content-left, .how-it-works-block__content-right {
      max-width: 60%;
      @include respond-to(mobile) {
        max-width: 90%;
      }
    }
  }

  .how-it-works-block__cards-wrapper {
    margin-top: 12px;
    overflow: hidden;
    position: relative;
    width: 100%;
    -webkit-tap-highlight-color: transparent;

    @include respond-to(notmobile) {
      margin-top: 28px;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      row-gap: 16px;
    }
  }

  .how-it-works-blocks__section__content:last-of-type .how-it-works-block .how-it-works-block__content-right{
      @include respond-to(notdesktop) {
        width: 100%;
      }
    }

  .how-it-works-block__content-right.how-it-works-img {
    @include respond-to(notmobile) {
      height: 500px;
      align-self: baseline;
    }
    @include respond-to(notdesktop) {
      margin: 0 auto;
    }
  }

  .how-it-works-blocks__section__sub-content {
    @include respond-to(notdesktop) {
      margin: 20px 0;
    }
  }

</style>
