<template>
  <section class="product-view-body">
    <SearchPage :enableCategoryFilter="false" :query="query" />
  </section>
</template>

<script>
import SearchPage from 'rs/components/pages/search/v2';

export default {
  components: {
    SearchPage
  },
  props: ['query'],
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/variables_new';
@import '~PlatformStyles/abstract/mixins';

.product-view-body {
  padding: 24px 0;
  @include respond-to(desktop) {
    margin: 0 -12px;
  }
}
</style>
