<template>
  <MatchMedia v-slot="{mobile}" v-if="hasQuestions > 0">
    <section class="questions-widget" ref="component">
      <div class="questions-widget__header">
        <div class="questions-widget__title">Questions & Answers</div>
      </div>
      <div class="questions-widget__body">
        <section class="questions-widget-wrapper">
          <section class="questions-widget__questions">
            <template v-if='!isLoadingQuestionsData'>
              <div class="questions-widget__question-card" v-for="question in widgetQuestionData.questions">
                <QuestionCard :question="question"/>
              </div>
            </template>
            <LoadingSpinner v-else class="questions-widget__loading-spinner"/>
            <Pagination class="questions-widget__pagination" :currentPage="widgetQuestionData.current_page"
                        :minItem="mobile ? 3 : 8" :displayRange="mobile ? 1 : 3"
                        :totalPage="widgetQuestionData.total_pages" @onChange="handlePageChange"/>
          </section>
        </section>
      </div>
    </section>
  </MatchMedia>
</template>
<script>
import QuestionCard from 'rs/components/pages/product/others/_question_card';
import Pagination from 'rs/components/common/pagination';
import LoadingSpinner from 'rs/components/common/loading_spinner';
import {MatchMedia} from "shared/vue_media_query/MatchMedia";
import {mapGetters} from 'vuex';

export default {
  components: {QuestionCard, Pagination, LoadingSpinner, MatchMedia},
  computed: mapGetters('Product', ['widgetQuestionData', 'isLoadingQuestionsData', 'hasQuestions']),
  methods: {
    handlePageChange(pageNum) {
      this.$store.dispatch('Product/loadQuestions', {page: pageNum})
      this.$nextTick(() => {
        const y = this.$refs.component.getBoundingClientRect().top
        this.$sharedUtils.scrollToDistanceWithHeaderOffset(y)
      })
    },
  }
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.questions-widget {
  background: white;
  border-radius: 0;
  padding: 24px 16px;
  @include respond-to(desktop) {
    padding: 24px 28px;
    border-radius: 12px;
    border: $borderStyle;
  }
}

.questions-widget__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

.questions-widget__header {
  margin-bottom: 12px;
}


.questions-widget-wrapper {
  display: flex;
  flex-direction: column;
}

.questions-widget-spinner {
  margin-top: 20px;
  height: 60px;
  width: 60px;
  align-self: center;
}

.questions-widget__questions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.questions-widget__question-card {
  padding: 16px 28px;
  margin-left: -28px;
  margin-right: -28px;
  border-bottom: 1px solid #E0F2F2;
  @include respond-to(notdesktop) {
    padding: 16px 12px;
    margin-left: -12px;
    margin-right: -12px;
  }
}

.questions-widget__pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  color: $brandColor;
}

.questions-widget__loading-spinner {
  width: 100px;
  height: 100px;
  margin-top: 24px;
  align-self: center;
}

</style>
