import Vue from 'vue'
import App from 'rs/app.vue'
import routes from 'rs/plugins/routes'
import router from 'rs/plugins/router'
import axios from 'shared/utils/axios'
import sharedUtils from 'Javascript/shared/utils/utils'
import store from 'rs/store/root.js'

import 'rs/plugins/setup/cookie_bar'
import 'rs/plugins/setup/user_terms_modal'
import 'rs/plugins/click_outside_vue_directive'
import VueAwesomeSwiper from 'rs/plugins/swiper'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import SubHeader from 'rs/components/layouts/sub_header'
import ModalWrapper from 'rs/components/layouts/_modal_wrapper.vue'
import {Icon} from '@iconify/vue2';
import SvgIcon from 'shared/vue_components/svg_icons/index.js'
import LoadingSpinner from 'rs/components/common/loading_spinner.vue'
import { updateQueryUrl } from 'shared/utils/vue_router.js'

Vue.component('loading-spinner', LoadingSpinner)
Vue.component('modal-wrapper', ModalWrapper)
Vue.component('iconify', Icon)
Vue.component('svg-icon', SvgIcon)

Vue.use(VueAwesomeSwiper)
Vue.use(VueSweetalert2);
Vue.prototype.$alertError = (text, options = {}) => Vue.prototype.$swal.fire({ type: 'error', title: 'Error', text, ...options })
Vue.prototype.$alertSuccess = (text, options = {}) => Vue.prototype.$swal.fire({ type: 'success', title: 'Success', text, ...options })

Vue.prototype.$axios = axios
Vue.prototype.$sharedUtils = sharedUtils
Vue.prototype.$routes = routes
Vue.prototype.$updateQueryUrl = updateQueryUrl
const componentInstance = new Vue({ ...SubHeader, router, store })

window.mountSearchNavbar = function() {
  if (document.getElementById("search-navbar")) {
    componentInstance.$mount('#search-navbar')
  }
}
document.addEventListener('DOMContentLoaded', () => {
  window.isReviewerLoggedIn = !!document.querySelector('body.pf-body--loggedin')
  let app_meta = document.querySelector('#app_meta')
  if (app_meta) {
    window.app_meta = {}
    for (let key in app_meta.dataset) {
      window.app_meta[key] =  app_meta.dataset[key]
    }
    app_meta.remove()
  }
  const appContainer = document.querySelector('main')
  const el = appContainer.appendChild(document.createElement('RS'))

  new Vue({
    router: router,
    el: el,
    store: store,
    render: h => h(App)
  })
  window.mountSearchNavbar();
})
