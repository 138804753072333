import Vue from 'vue'
import VueRouter from 'vue-router'
import store from 'rs/store/root'
import routes from 'rs/plugins/routes'

import VueGtag from "vue-gtag";

const router =  new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    // add scroll behaviour when navigating
    // scrollBehaviour doesn't work as is from the Doc, need to modify it a bit.
    if (to.hash) { // scroll to # if exists
      Vue.nextTick(() => {
        document.getElementById(to.hash.substring(1)).scrollIntoView();
      })
      // Does not work but it's the vue way
      return {selector: to.hash, behavior: 'smooth'}
    }

    if (savedPosition) { // scroll to last position
      return savedPosition
    }
    // scroll top
    window.scrollTo({top: 0, behavior: 'smooth'});
    // Does not work but it's the vue way
    return {left: 0, top: 0}
  },
  routes: Object.values(routes),
});

// HACK to keep redesign flag without having to set redesign query in every router link
router.beforeEach(async(to, from, next) => {
  if (from.query.redesign && !to.query.redesign)   next({ name: to.name, params: to.params, query: {...to.query, redesign: 1}})
  else  next()
})

// HACK for closing search filters modal when use browser goback (mainly for mobile)
// There's noway to modify the browser native goback, and should never do so.
// We modify vue router instead, if user choose goback on Search page while modal is still opened, we don't navigate (by not calling next)
router.beforeEach((to, from, next)=>{
  // apply only from page search to any pages with filter modal is still active
  if (from.name === 'PageSearch' && store.getters['SharedCurrentReviewer/filterSidebarOpen']) {
    store.commit('SharedCurrentReviewer/toggleFilterSidebar', false)  // close modal manually & remove lock scroll (inside the action)
    const url = new URL(window.location.origin + '/search');          // build /search url
    Object.keys(from.query).forEach(key => {                          // build all queries from the original "from" page
      url.searchParams.set(key, from.query[key]);
    })

    history.pushState({}, '', url)                      // update URL address to state before it goback
    window.scrollTo({top: 0, behavior: 'smooth'});           // scroll to top, IDK why it scroll down to mid screen
  } else {
    next();
  }
})
// temporary cache from params in order for seach page to apply correct sorting.
router.beforeEach((to, from, next) => {
  if (from.name === routes.categoryShow.name && to.name === routes.search.name) {
    store.dispatch('Search/cacheParam', ['from', 'category'])
    delete to.query.from
  }
  next()
})


// Remove "search_in_shop" 'shop_domain' 'platform' from url queries
// since Store Search use currentRouter.query directly
router.beforeEach((to, _, next) => {
  if (to.name === routes.categoryShow.name || to.name === routes.categoryIndex.name) {
    delete to.query.shop_domain
    delete to.query.search_in_shop
    delete to.query.platform
    delete to.query.category_id  // will use id inside component
    to.query.category_search = "true"  // always apply 'category_search' flag in categories pages
  }
  next()
})

Vue.use(VueGtag, {
  config: { id: window.VUE_CONSTANTS.google_analytics_id},
  appName: 'Judge.me',
  pageTrackerScreenviewEnabled: true
}, router);

Vue.use(VueRouter)

export default router
