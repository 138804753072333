<template>
  <section class="page-not-found-wrapper">
    <div class="page-not-found-container">
      <img :src="'https://pub-images.judge.me/judgeme/marketplace/' + pageType + '-not-found-img'">
      <h1 class="page-not-found-heading">{{pageType}} Not Found</h1>
      <h5 class="page-not-found-sub-heading">The {{pageType}} you’re looking for does not seem to exist.</h5>
      <button @click="$router.go(-1)" class="btn pf-secondary-button btn-go-back">Go Back</button>
    </div>
  </section>
</template>

<script>
  export default {
    computed: {
      pageType() {
        let name;
        switch(this.$route.name) {
          case this.$routes.product.name:
            name = 'product';
            break;
          case this.$routes.shop.name:
            name ='store';
            break;
          default:
            name = 'page'
        }
        return name
      },
    },
    mounted(){
      document.body.classList.remove('mobile-white-background')
    }
  }
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
.page-not-found-wrapper {
  @include respond-to(notmobile) {
    display: flex;
    justify-content: space-between;
    margin: 80px 40px 40px 40px;
  }
}
.page-not-found-container {
  height: auto;
  @include respond-to(notmobile) { max-width: 100%; }
  margin: 0 auto;
  background: white;
  border-radius: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  img {
    max-width: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .page-not-found-heading {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 36px;
    text-transform: capitalize;
  }
  .page-not-found-sub-heading{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .btn.btn-go-back{
    width: 100%;
    max-width: 331px;
    margin: 12px 0 48px 0;
  }

  @include respond-to(notdesktop) {
    width: 80%;
    max-width: auto;
    padding: 20px;
    height: auto;
    margin-top: 36px;
    img {max-width: 100%;}
    .btn.btn-go-back{margin: 12px 0 40px 0; }
  }
}

</style>
