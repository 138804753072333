<template>
  <section class="homepage-stats has-max-width">
    <p class="homepage-stats__heading">{{ heading }}</p>
    <swiper class="homepage-stats__cards-wrapper"
            ref="statsSwiper"
            :options="swiperOptions"
            :mousewheel="true"
    >
      <swiper-slide v-for="(card, idx) in cards" :key="`stats-card-${idx}`">
        <StatsCard :imageUrl="card.imageUrl" :heading="card.heading" :value="card.value"
                   :unit="card.unit" :cardStyle="cardStyle"/>
      </swiper-slide>
      <div class="stats-swiper-pagination" slot="pagination"></div>
    </swiper>
  </section>
</template>

<script>
import StatsCard from "rs/components/pages/reviews/_stats_card"

export default {
  components: {StatsCard},
  computed: {
    homeMetrics(){ return this.$store.getters['Home/homeMetrics']},
    heading: () => 'In the last 30 days',
    cards() {
      return [
        {
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/homepage-illustration1-1x.png',
          heading: 'OUR',
          unit: 'verified reviews',
          value: this.formatMetric(this.homeMetrics.review_metric),
        },
        {
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/homepage-illustration2-1x.png',
          heading: 'HELPED',
          unit: 'shoppers',
          value: this.formatMetric(this.homeMetrics.verified_buyer_metric),
        },
        {
          imageUrl: 'https://pub-images.judge.me/judgeme/marketplace/homepage-illustration3-1x.png',
          heading: 'BUY FROM',
          unit: 'stores',
          value: this.formatMetric(this.homeMetrics.shop_metric),
        }
      ]
    },
    swiper() {
      return this.$refs.statsSwiper.$swiper
    },
    swiperOptions: () => ({
      autoUpdate: true,
      autoDestroy: true,
      deleteInstanceOnDestroy: true,
      cleanupStylesOnDestroy: true,
      breakpoints: {
        '767': { // big screen mobile, show 2 cards with navigate buttons
          slidesPerView: 1,
        },
        '890': {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      },
      pagination: {
        el: '.stats-swiper-pagination'
      },
    }),
    cardStyle() {
      return { backgroundColor: '#FFFFFF' }
    }
  },
  methods: {
    formatMetric(num) {
      if (num >= 1000000) {
        return this.$sharedUtils.roundNumberToStringWithDecimalsSuffix(num / 1000000, 1) + 'm'
      }
      return this.$sharedUtils.numberWithCommas(num)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

.homepage-stats {
  position: relative;
  left: calc(-50vw + 50%);
  width: 100vw;
  z-index: 0;
  overflow: hidden;
  margin-top: 40px;
  @include respond-to(notmobile) {
    margin-top: 52px;
  }
}

.homepage-stats__heading {
  line-height: 40px;
  color: $brandColor;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.homepage-stats__cards-wrapper {
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  margin-top: 16px;
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;
  @include respond-to(notmobile) {
    margin-top: 32px;
    row-gap: 16px;
    gap: 16px
  }
}

.stats-swiper-pagination {
  position: inherit;
  justify-content: center;
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  margin-top: 20px;
}

</style>
<style lang="scss">
.stats-swiper-pagination > .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}
</style>
