import { render, staticRenderFns } from "./_category_direct_children.vue?vue&type=template&id=5ebadb88&scoped=true&"
import script from "./_category_direct_children.vue?vue&type=script&lang=js&"
export * from "./_category_direct_children.vue?vue&type=script&lang=js&"
import style0 from "./_category_direct_children.vue?vue&type=style&index=0&id=5ebadb88&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ebadb88",
  null
  
)

export default component.exports