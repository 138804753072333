import { render, staticRenderFns } from "./_card_wrapper.vue?vue&type=template&id=04b00d17&scoped=true&"
import script from "./_card_wrapper.vue?vue&type=script&lang=js&"
export * from "./_card_wrapper.vue?vue&type=script&lang=js&"
import style0 from "./_card_wrapper.vue?vue&type=style&index=0&id=04b00d17&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b00d17",
  null
  
)

export default component.exports