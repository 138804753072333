<template>
  <MatchMedia v-slot="{desktop}">
    <LoadingSpinner v-if='!doneLoadProfile'/>
    <div v-else class="private-profile-page">
      <SubscribeModal v-if="showingSubscribeModal"/>
      <ProfilePageNav :public='false' :storeName="storeName"/>
      <NotificationCard class="mt-5"/>
      <div class="ppp__container">
        <Gallery v-if="isGalleryOpen"/>
        <div class="ppp__container-main">
          <div class="ppp__bio-card">
            <ProfileInfo :profileData="profileData"/>
          </div>
          <FollowWidget v-if="!desktop" :storeName="storeName" class="ppp__widget"/>
          <LoadingSpinner v-if="!doneLoadReviews"/>
          <Collections v-else/>
        </div>
        <Sidebar :storeName="storeName" v-if="desktop"/>
      </div>
    </div>
  </MatchMedia>
</template>
<script>
import Gallery from 'rs/components/common/gallery';
import Sidebar from 'rs/components/uncommon/profile/_sidebar';
import ProfilePageNav from 'rs/components/uncommon/profile/_profile_page_nav';
import ProfileInfo from 'rs/components/pages/public_profile/_profile_info';
import PromiseWidget from 'rs/components/common/promise_widget';
import LoadingSpinner from 'rs/components/common/loading_spinner';
import FollowWidget from 'rs/components/uncommon/profile/_follow_widget';
import Collections from 'rs/components/pages/private_profile/_collections';
import SubscribeModal from 'rs/components/pages/private_profile/subscribe_modal';
import NotificationCard from 'rs/components/pages/private_profile/_notification_card';
import {MatchMedia} from "shared/vue_media_query/MatchMedia";
import {setNewMeta} from 'shared/utils/router_utils';
import {mapGetters} from "vuex";
const storeName = 'PrivateProfile'

export default {
  components: {
    SubscribeModal,
    MatchMedia,
    LoadingSpinner,
    Sidebar,
    ProfileInfo,
    Collections,
    PromiseWidget,
    FollowWidget,
    Gallery,
    ProfilePageNav,
    NotificationCard,
  },
  computed: {
    storeName: () => storeName,
    ...mapGetters(storeName, ['profileData', 'doneLoadProfile', 'doneLoadReviews', 'showingSubscribeModal']),
    isGalleryOpen() {
      return this.$store.getters['Gallery/isOpen'];
    },
  },
  mounted() {
    // temp fix to scroll top
    // Tried all solutions but none worked. (in routes: beforeRouteEnter, in router guard: savedPosition={x:0, y:0}
    setTimeout(() => {
      window.scrollTo({top: 0})
    }, 200)
    setNewMeta({page_title: `${this.profileData.name} - your reviews on Judge.me`})
  },
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';

$mobilePadding: 16px;
.loading-spinner {
  margin: 144px auto;
  width: 72px;
  height: 72px;
}

.private-profile-page {
  padding: 0;
  margin: -14px -12px 0 -12px;
}

.ppp__container {
  display: flex;
}

.ppp__container-main {
  width: 100%;
  @include respond-to(desktop) {
    margin-bottom: 40px;
  }
}

.ppp__bio-card {
  width: 100%;
  background-color: white;
  @include respond-to(desktop) {
    margin: 20px 0 20px 0;
    border-radius: 12px;
    border: $newBorderStyle;
  }
}

.ppp__widget {
  margin: 20px $mobilePadding;
}
</style>
