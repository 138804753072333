<template>
  <section class='review-form-card'>
    <ProductHeader :product="product" :description="headerDescription"/>

    <form class="review-form-card__form" @submit="submitReview" v-if="!submissionSuccess" v-bind:class="{ 'review-form-submitting': isSubmitting }">
      <h3 class="review-form-card__form-header">Write a review</h3>

      <label class="review-form-card__form-rating" for="review-rating">Rating</label>
      <StarRatingChooser  :activeColor="'#F2A663'" :showRating="false" :roundedCorners="true" class="review-form-card__form-stars"
                          :borderColor="'#F2A663'" :borderWidth="2" :inactiveColor="'white'" :starSize="20" v-model="reviewRating" @rating-selected="setRating"/>

      <label class="review-form-card__form-title" for="review-title">Review Title</label>
      <input v-model="reviewTitle" :maxlength="this.reviewTitleMax" @input="setTitle($event)" type="text" name="review-title" id="review-title" placeholder="Give your review a title">

      <label class="review-form-card__form-title" for="review-body">Review</label>
      <textarea required v-model="reviewBody" :maxlength="this.reviewBodyMax" @input="setBody($event)" name="review-body" id="review-body" cols="30" rows="10" placeholder="Write your review here"></textarea>

      <input v-if="this.reviewVideosEnabled" v-model="reviewYoutube" type="url" name="youtube-url" id="youtube-url" placeholder="YouTube URL (optional)" @input="enterYoutubeUrl($event)" @change="enterYoutubeUrl($event)">

      <input type="submit" class='review-form-card__submit-review btn btn--brand' value="Submit Review">
    </form>
    <LoadingSpinner v-if='isSubmitting'/>
    <div class="review-form-card__submitted" v-if="submissionSuccess">
      <span class="review-form-card__submitted-tick-icon material-icons">
        check_circle
      </span>
      <p>Review Submitted!</p>
    </div>
  </section>
</template>

<script>
  import axios from 'shared/utils/axios';
  import ProductHeader from 'rs/components/common/feed_cards/_product_header'
  import LoadingSpinner from 'rs/components/common/loading_spinner'
  import StarRatingChooser from 'vue-star-rating'

  export default {
    components: { LoadingSpinner, StarRatingChooser, ProductHeader },

    props: ['product'],

    data() {
      return {
        headerDescription: "You've recently ordered this product. Please write a review about your experience.",
        reviewRating: 5,
        reviewTitle: '',
        reviewTitleMax: 100,
        reviewBody: '',
        reviewBodyMax: 5000,
        reviewYoutube: '',
        youtubeLongUrlRegex: /https:\/\/(www\.|m.)*youtube\.com\/watch\?v=.+/,
        youtubeShortUrlRegex: /^https:\/\/youtu.be\/.*$/,
        submissionSuccess: false,
        isSubmitting: false,
        reviewVideosEnabled: this.product.settings.allow_videos,
      }
    },

    methods: {
      setRating($event) {
        this.reviewRating = $event
      },
      setTitle($event) {
        this.reviewTitle = $event.target.value
      },
      setBody($event) {
        this.reviewBody = $event.target.value
      },

      submitReview(e) {
        e.preventDefault()
        this.sendRequest(this.prepareReviewData(e))
      },
      prepareReviewData(e) {
        const formData = Object.fromEntries(new FormData(e.target).entries())
        const reviewData = {
          line_item_public_id: this.product.line_item_public_id,
          product_title: this.product.title,
          handle: this.product.handle,
          shop: this.product.shop_domain, 
          url: this.product.shop_domain,
          reviewer_name_format: "",
          name: this.product.reviewer_name,
          email: this.product.reviewer_email,
          rating: String(this.reviewRating),
          title: formData["review-title"],
          body: formData["review-body"],
          media_platform_videos: this.getVideoId(formData["youtube-url"]) ? {
            0: {
              platform: "yt",
              url: this.getVideoId(formData["youtube-url"]),
            }
          } : null,
          source: this.product.source,
          platform: this.product.platform,
        }
        return reviewData
      },
      sendRequest(reviewData) {
        let self = this
        self.isSubmitting = true
        axios
        .post('/profile/reviews.json', reviewData)
        .then(function () {
          self.isSubmitting = false
          self.submissionSuccess = true
        })
        .catch(function (error) {
          self.isSubmitting = false
        })
      },

      enterYoutubeUrl(e) {
        this.isURLValid(e.target.value);
      },

      isURLValid(inputUrl) {
        return this.isLongUrl(inputUrl) || this.isShortUrl(inputUrl);
      },

      getVideoId(url) {
        let parts;
        if (!url) {
          return;
        }
        if (this.isLongUrl(url)) {
          return this.urlParamsAsHash(url).v;
        } else if (this.isShortUrl(url)) {
          parts = url.split('?')[0].split('/');
          return parts[parts.length - 1];
        }
      },

      isLongUrl(url) {
        return this.youtubeLongUrlRegex.test(url);
      },

      isShortUrl(url) {
        return this.youtubeShortUrlRegex.test(url);
      },

      urlParamsAsHash(url) {
        const resultHash = {}
        if (url === null && location.search.length <= 0 ) {
          return {}
        } else {
          const params = new URL(url).searchParams;
          for (let [key, value] of params) {
            resultHash[key] = value
          }
          return resultHash;
        }
      },

    },
  }
</script>

<style lang="scss" scoped>
  @import '~PlatformStyles/abstract/mixins';
  @import '~PlatformStyles/abstract/variables_new';

  .review-form-card {
    background: white;
    border-radius: 10px;
    padding: 16px;
    font-size: 16px;
    margin-top: 20px;
  }

  .review-form-card__form {
    display: grid;
    justify-items: center;
    @include respond-to(desktop) { margin: 0 auto; }

    label {
      font-weight: 300;
      font-size: 16px;
      line-height: 1;
      color: $primaryFontColor;
    }
  }

  .review-form-card__form-header {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
  }

  .review-form-card__form-rating,
  .review-form-card__form-title { margin-bottom: 14px; }

  .review-form-card__form-stars, .review-form-card__form-body,
  .review-form-card__form-header, input[name="review-title"],
  input[name="review-title"], textarea[name="review-body"] { margin-bottom: 25px; }

  input[name="review-title"],
  input[name="youtube-url"],
  textarea[name="review-body"] {
    background: white;
    width: 100%;
  }

  input[name="review-title"] {
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 40px;
    padding: 10px 20px;
  }

  textarea[name="review-body"] {
    height: 130px;
    border-radius: 20px;
    position: relative;
    padding: 10px 20px;

    &::placeholder {
      position: absolute;
      top: 10px;
      left: 20px;
    }
  }

  input[name="youtube-url"] {
    border-radius: 40px;
    padding: 10px 20px;
  }

  .review-form-card__submit-review {
    margin: 30px 0;
    width: 100%;
  }

  .review-form-card__form.review-form-submitting { display: none; }

  .loading-spinner {
    margin: 24px auto;
    width: 72px;
    height: 72px;
  }

  .review-form-card__submitted {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: $brandColor;
    padding: 20px;
  }

  .review-form-card__submitted-tick-icon { font-size: 40px; }
</style>
