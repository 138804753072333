<template>
  <div class='stats-card'>
    <div class="stats-card__wrapper" :style="cardStyle">
      <img class="stats-card__image" :src="imageUrl" alt="statistics image" />
      <div class="stats-card__heading">{{heading}}</div>
      <div class="stats-card__value">{{value}}</div>
      <div class="stats-card__unit">{{unit}}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['imageUrl', 'heading', 'value', 'unit', 'cardStyle']
}
</script>
<style lang="scss" scoped>
@import '~PlatformStyles/abstract/mixins';
@import '~PlatformStyles/abstract/variables_new';
.stats-card {
  width: 100%;
  display: flex;
  justify-content: center;
}
.stats-card__wrapper {
  background: rgba(249, 249, 249, 0.8);
  border: 1px solid #E7E7E7;
  border-radius: 10px;
  width: 260px;
  height: 319px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 16px;
  @include respond-to(notmobile) {
    width: 100%;
  }
}
.stats-card__heading {
  font-size: 12px;
  font-weight: 800;
  line-height: 20px;
  color: $secondaryFontColor;
}
.stats-card__value {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}
.stats-card__unit {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  @include respond-to(notmobile) {
    font-size: 20px;
    line-height: 30px;
  }
}
.stats-card__image {
  height: 153px;
}
</style>
